import { Box, Divider, MenuItem, MenuList, Typography } from "@mui/material";
import React, { memo } from "react";
import { ksaRelativeTime } from "../../constant/Utils";
import { useSelector } from "react-redux";
import { getBroadcastNotificationFromStore } from "../../stateManagement/reducers/BroadcastNotificationSlice";
import { NO_CAMPAIGN } from "../../constant/Constants";

const CampaignPanel = () => {
  const notifactionMessage = useSelector((state) =>
    getBroadcastNotificationFromStore(state)
  );
  return (
    <MenuList
      sx={{
        height: "208px",
        overflowY: "auto",
        scrollbarWidth: "thin"
      }}
    >
      {notifactionMessage && notifactionMessage.length > 0 ? (
        notifactionMessage.map((notification) => (
          <MenuItem
            key={notification.id}
            sx={{
              display: "block",
              cursor: "default"
            }}
            data-testid="menuItem"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                pl: 2
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "break-spaces",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical"
                  // "-webkit-box-orient": "vertical",
                }}
                align="left"
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontFamily: "BukraBold",
                    fontSize: "12px",
                    letterSpacing: "0px",
                    color: "#212121"
                  }}
                >
                  {notification.messageTitle}
                </Typography>{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontFamily: "BukraRegular",
                    fontSize: "12px",
                    letterSpacing: "0px",
                    color: "#212121"
                  }}
                >
                  {notification.message}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  fontFamily: "BukraRegular",
                  pt: 2,
                  color: "#959595",
                  letterSpacing: "0px",
                  opacity: 1
                }}
              >
                {ksaRelativeTime(notification.messageCreatedDateKSA)}
              </Typography>
            </Box>
            <Divider sx={{ mt: "4px !important" }} variant="fullWidth" />
          </MenuItem>
        ))
      ) : (
        <Typography
          sx={{
            fontSize: 12,
            whiteSpace: "break-spaces",
            pr: 0.5,
            fontFamily: "BukraRegular",
            letterSpacing: "0px",
            color: "#212121"
          }}
          data-testid="noContent"
          textAlign="center"
        >
          {NO_CAMPAIGN}
        </Typography>
      )}
    </MenuList>
  );
};

export default memo(CampaignPanel);
