import React, { useState } from "react";
import Box from "@mui/material/Box";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Divider, MenuItem, Select } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import searchIcon from "./../../assets/images/search.svg";
import {
  ALL,
  ALLOCATED,
  ALLOCATED_LABEL,
  ASSIGNED,
  ASSIGNED_LABEL,
  AVAILABLE_LABEL,
  FAULTY_LABEL,
  FAULTY_TAB,
  STOCK_TRANSFER,
  TRACKSTB
} from "../../constant/Constants";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StockTransferTable from "../stockTransferTable/StockTransferTable";
import TabPanel from "../tabPanel/TabPanel";
import { downloadInventoryStockReport } from "../../services/user.services";
import { useDispatch } from "react-redux";
import { setShowDateFilter } from "../../stateManagement/reducers/DateFilterSlice";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import DownloadReport from "../downloadReport/DownloadReport";
import AvailableFaultyStockTransferTable from "../availableFaultyStockTransferTable/AvailableFaultyStockTransferTable";
import { downloadStockTransferLabel } from "../../constant/Utils";

const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});
const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "30px",
          marginLeft: "1px",
          textTransform: "capitalize",
          color: "#5c6473",
          display: "flex",
          alignItems: "center",
          fontFamily: "BukraMedium"
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    }
  }
});

/**
 * Renders the Stock Transfer UI component.
 * @returns {component} the Stock Transfer UI component.
 */
const StockTransfer = () => {
  const [value, setValue] = useState(0);
  const [selectSearchItem, setSelectedSearchItem] =
    useState("hardwareModelName");
  const [search, setSearch] = useState("");
  const [tabStatus, setTabStatus] = useState(ALL);
  const dispatch = useDispatch();

  /**
   *@constant {arraay<object>} searchOption Initialize the search options for the stock transfer page table component.
   */
  const searchOption = [
    {
      value: "hardwareModelName",
      label: "Model"
    },
    {
      value: "hardwareMake",
      label: "Manufacture"
    },
    {
      value: "count",
      label: "Stock"
    }
  ];

  /**
   * Handles the search Input field
   * @function handleSearchRequest - Handle the Search input Change operation.
   * @param {object} event - Search input event object
   */
  const handleSearchRequest = (event) => {
    setSearch(event.target.value);
  };

  /**
   * Handles the tab change operation.
   * @function handleChange - Performs action on Tab change.
   * @param {event} event - event object
   * @param {number} newValue - table value
   */
  const handleChange = (event, newValue) => {
    let status = ALL;
    if (newValue === 1) {
      status = ALLOCATED;
      dispatch(setShowDateFilter(true));
    } else if (newValue === 2) {
      status = ASSIGNED;
      dispatch(setShowDateFilter(false));
    } else if (newValue === 3) {
      status = FAULTY_TAB;
      dispatch(setShowDateFilter(false));
    } else {
      dispatch(setShowDateFilter(true));
    }

    setTabStatus(status);
    setValue(newValue);
  };

  /**
   * Handles the Search category change operation.
   * @function handleSearchItemSelect - Handles the Search Category Change.
   * @param {object} event - Select option event object
   */
  const handleSearchItemSelect = (event) => {
    setSelectedSearchItem(event.target.value);
  };
  return (
    <Box
      data-testid="stockTransfer"
      sx={{
        width: "100%",
        mt: 2,
        background: "#FFFFFF 0% 0% no-repeat padding-box"
      }}
    >
      <Paper sx={{ width: "100%" }}>
        <Toolbar
          sx={{
            px: { sm: 3 },
            py: { xs: 2, md: 0 },
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            minHeight: { sm: "73px" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", md: "center" },
              flexDirection: { xs: "column", md: "row" },
              width: { xs: "100%", md: "auto" }
            }}
          >
            <Typography
              variant="h6"
              id="tableTitle"
              sx={{
                fontFamily: "BukraMedium",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#464E5F",
                mr: 2,
                mt: { xs: 1, md: 0 }
              }}
            >
              {STOCK_TRANSFER}
            </Typography>
            <Box
              data-testid="searchBox"
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                backgroundColor: "#f3f6f9",
                height: "40px",
                my: { xs: 1, lg: 0 },
                width: { xs: "100%", md: "auto" }
              }}
            >
              <img
                src={searchIcon}
                alt="search"
                style={{
                  height: "11px",
                  paddingLeft: "20px"
                }}
              ></img>
              <ThemeProvider theme={inputTheme}>
                <InputBase
                  autoComplete="off"
                  inputProps={{ "data-testid": "searchRequest" }}
                  sx={{ ml: 1, flex: 1, width: { md: "160px", xs: "100%" } }}
                  placeholder="Search"
                  onChange={(event) => handleSearchRequest(event)}
                />
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Select
                  inputProps={{ "data-testid": "selectCustomer" }}
                  disableUnderline={true}
                  id="filledoption"
                  sx={{
                    backgroundColor: "#7B7D801A",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    pl: 2,
                    pr: 2,
                    color: "#282828",
                    fontSize: "13px",
                    fontFamily: "BukraRegular",
                    letterSpacing: 0,
                    height: "40px",
                    ".MuiSelect-icon": {
                      px: 1
                    }
                  }}
                  value={selectSearchItem}
                  onChange={handleSearchItemSelect}
                  variant="standard"
                >
                  {searchOption.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        background: "#F6F6F6 0% 0% no-repeat padding-box",
                        ...muiMenuItemStyles
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </ThemeProvider>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { sm: "center" },
              flexDirection: { sm: "row", xs: "column" },
              width: { xs: "98%", md: "auto" },
              justifyContent: "flex-end"
            }}
          >
            <DownloadReport
              reportType={downloadInventoryStockReport}
              status={tabStatus}
              fileName={`${downloadStockTransferLabel(tabStatus)}`}
            ></DownloadReport>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }}></Divider>
            <Link to="trackstb" style={{ textDecoration: "none" }}>
              <Button
                type="submit"
                variant="text"
                sx={{
                  mt: { xs: 1, sm: 0 },
                  borderRadius: "20px",
                  width: { sm: "171px", xs: "100%" },
                  height: "40px",
                  fontSize: "15px",
                  textTransform: "none",
                  fontFamily: "BukraMedium",
                  textDecoration: "none",
                  background:
                    "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                  color: "#ffffff",
                  "&.Mui-disabled": {
                    background:
                      "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                    color: "#ffffff",
                    opacity: 0.5
                  }
                }}
              >
                {TRACKSTB}
              </Button>
            </Link>
          </Box>
        </Toolbar>
        <Divider></Divider>

        <ThemeProvider theme={theme}>
          <Tabs
            data-testid="stockTransferTab"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              minHeight: "45px",
              py: { xs: 1, lg: 0 },
              pl: { md: 3 },
              background: "#ECF1FA 0% 0% no-repeat padding-box"
            }}
          >
            <Tab
              label={ASSIGNED_LABEL}
              sx={{
                width: { xs: "fit-content" },
                fontSize: { xs: "12px", md: "14px" },
                pl: { lg: 4, sm: 2 },
                pr: { lg: 4, sm: 2 },
                "&.Mui-selected": {
                  background: "#398BE733 0% 0% no-repeat padding-box",
                  borderRadius: "17px",
                  color: "#0F57CB",
                  height: "30px"
                }
              }}
              data-testid="assignedTab"
            ></Tab>
            <Tab
              label={ALLOCATED_LABEL}
              sx={{
                width: { xs: "fit-content" },
                fontSize: { xs: "12px", md: "14px" },
                pl: { lg: 4, sm: 2 },
                pr: { lg: 4, sm: 2 },
                "&.Mui-selected": {
                  background: "#398BE733 0% 0% no-repeat padding-box",
                  borderRadius: "17px",
                  color: "#0F57CB",
                  height: "30px"
                }
              }}
              data-testid="allocatedTab"
            ></Tab>
            <Tab
              label={AVAILABLE_LABEL}
              sx={{
                width: { xs: "fit-content" },
                fontSize: { xs: "12px", md: "14px" },
                pl: { lg: 4, sm: 2 },
                pr: { lg: 4, sm: 2 },
                "&.Mui-selected": {
                  background: "#398BE733 0% 0% no-repeat padding-box",
                  borderRadius: "17px",
                  color: "#0F57CB",
                  height: "30px"
                }
              }}
              data-testid="availableTab"
            ></Tab>
            <Tab
              label={FAULTY_LABEL}
              sx={{
                width: { xs: "fit-content" },
                fontSize: { xs: "12px", md: "14px" },
                pl: { lg: 4, sm: 2 },
                pr: { lg: 4, sm: 2 },
                "&.Mui-selected": {
                  background: "#398BE733 0% 0% no-repeat padding-box",
                  borderRadius: "17px",
                  color: "#0F57CB",
                  height: "30px"
                }
              }}
              data-testid="faultyTab"
            ></Tab>
          </Tabs>
        </ThemeProvider>
        <TabPanel value={value} index={0}>
          <StockTransferTable
            selectSearchItem={selectSearchItem}
            tabStatus={tabStatus}
            search={search}
          ></StockTransferTable>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StockTransferTable
            selectSearchItem={selectSearchItem}
            tabStatus={tabStatus}
            search={search}
          ></StockTransferTable>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AvailableFaultyStockTransferTable
            selectSearchItem={selectSearchItem}
            tabStatus={tabStatus}
            search={search}
          ></AvailableFaultyStockTransferTable>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AvailableFaultyStockTransferTable
            selectSearchItem={selectSearchItem}
            tabStatus={tabStatus}
            search={search}
          ></AvailableFaultyStockTransferTable>
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default StockTransfer;
