import { Button, Typography, Paper, Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  NOTIFICATION_ENABLE_PATH,
  NOTIFICATION_NOT_ALLOW_MSG
} from "../../constant/Constants";
import { updatePushNotificationEnableStatus } from "../../stateManagement/reducers/PushNotificationSlice";

const PushNotificationEnablePopup = () => {
  const dispatch = useDispatch();

  const handleAllowClick = () => {
    dispatch(updatePushNotificationEnableStatus(false));
  };

  return (
    <Paper
      data-testid="PushNotificationEnablePopup"
      sx={{
        backgroundColor: "#f1f6ff",
        maxWidth: { xs: "100%", sm: "400px", md: "300px" },
        position: "fixed",
        top: { lg: "50px", xs: "70px" },
        right: { lg: "50px", xs: "5px" },
        display: "flex",
        flexDirection: "column",

        p: 1,
        transition: "width 2s, height 4s"
      }}
    >
      <Typography
        id="modal-modal-title"
        sx={{
          fontFamily: "BukraRegular",
          fontSize: "15px",
          color: "#212121",
          textAlign: "center",
          alignItems: "center"
        }}
      >
        {NOTIFICATION_NOT_ALLOW_MSG}
      </Typography>
      <Typography
        id="modal-modal-title"
        sx={{
          mt: 1,
          fontFamily: "BukraRegular",
          fontSize: "13px",
          color: "#212121",
          textAlign: "center",
          alignItems: "center"
        }}
      >
        {NOTIFICATION_ENABLE_PATH}
      </Typography>
      <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
        <Button
          data-testid="confirmButton"
          variant="outlined"
          sx={{
            background:
              "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
            color: "#FFFFFF",
            borderRadius: "20px",
            letterSpacing: "0px",
            fontFamily: "BukraMedium",
            fontSize: "12px",
            textTransform: "none",
            display: "flex",
            width: "fit-content"
          }}
          onClick={() => handleAllowClick()}
        >
          OK
        </Button>
      </Box>
    </Paper>
  );
};

export default PushNotificationEnablePopup;
