import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, Toolbar, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import mobileNumber from "./../../assets/images/mobile_number.svg";
import Edit from "./../../assets/images/noun_edit.svg";
import Lock from "./../../assets/images/noun_lock.svg";
import UnLock from "./../../assets/images/lock_open.svg";
import Email_Icon from "./../../assets/images/e_mail.svg";
import Passcode from "./../../assets/images/noun_passcode.svg";
import Reset from "./../../assets/images/noun_reset.svg";
import Address from "./../../assets/images/noun_address.svg";
import CompanyName from "./../../assets/images/company_name_icon.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import {
  ACTIVE,
  CREDIT_BALANCE,
  EDIT_PROFILE,
  PASSCODE_SENT_TO,
  PASSWORD_SENT_TO,
  RESET_PASSCODE,
  RESET_PASSWORD,
  SUCCESSFULLY,
  TOAST_ERROR,
  TOAST_SUCCESS,
  BLOCK_ACCOUNT,
  UNBLOCK_ACCOUNT,
  REGISTERED_MOBILE_NUMBER,
  SOMETHING_WENT_WRONG,
  CONFIRMATION_LOCK_USER_LABEL,
  CONFIRMATION_UNLOCK_USER_LABEL,
  CONFIRMATION_PASSCODE_LABEL,
  CONFIRMATION_PASSWORD_LABEL,
  KEY_BLOCK_USER,
  KEY_UNBLOCK_USER,
  KEY_RESET_PASSWORD_USER,
  KEY_RESET_PASSCODE_USER
} from "./../../constant/Constants";
import { useDispatch, useSelector } from "react-redux";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import {
  blockChildAccount,
  resetChildPasscode,
  resetChildPassword,
  unblockChildAccount
} from "../../services/user.services";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          marginTop: "4px !important",
          color: "#6a707c",
          backgroundColor: "transparent"
        }
      }
    }
  }
});

/**
 * Renders the Card Component containig the Details.
 * @param {object} profileDetails - Profile Details object passed to Profile card component as props.
 * @returns {component} the Profile card containing the details.
 */
const ProfileCard = ({ profileDetails }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [labelKey, setLabelKey] = useState("");
  const config = useSelector((state) => getConfigDetails(state));

  const dispatch = useDispatch();
  const {
    profileTittle = "Company Profile",
    profileName,
    profileNumber,
    profileEmail,
    profileAddress,
    profileCompany,
    image,
    isChildProfile = false,
    creditBalance,
    routeToEditProfile,
    profileStatus,
    channelPartnerCode,
    status,
    handleCallBackUpdateUserDetails
  } = profileDetails;
  const isActiveUser = status && status === ACTIVE ? true : false;

  /**
   * handles the Success toast message.
   * @function handleSucessToastMessage - Perfoms action to set the toas message for success scenario.
   * @param {string} message  - Message string.
   */
  const handleSucessToastMessage = (message) => {
    let toastDetails = {
      toastTitle: message,
      isToastOpen: true,
      toastStatus: TOAST_SUCCESS
    };
    dispatch(updateToastMessage(toastDetails));
  };

  /**
   * handles the Failure toast message.
   * @function handleFailureToastMessage - Perfoms action to set the toas message for Failure scenario.
   * @param {string} message  - Message string.
   */
  const handleFailureToastMessage = (message) => {
    let toastDetails = {
      toastTitle: `${message}`,
      isToastOpen: true,
      toastStatus: TOAST_ERROR,
      toastTextclr: "#FF2A2A",
      toastBgclr: "#FDE2E2  ",
      toastBorderclr: "#FF2A2A"
    };
    dispatch(updateToastMessage(toastDetails));
  };

  /**
   * Handles the operation to block account on click of the Account block icon.
   * @function handleBlockAccount - Performs action to Block account.
   */
  const handleBlockAccount = (reason) => {
    blockChildAccount(config.server, channelPartnerCode, reason).then((res) => {
      if (res && res.status === 200) {
        const sucessMessage = `${profileStatus} Account Blocked ${SUCCESSFULLY}.`;
        handleSucessToastMessage(sucessMessage);
        handleCallBackUpdateUserDetails();
      } else {
        const failureMessage =
          res && res.data && res.data.message
            ? res.data.message
            : SOMETHING_WENT_WRONG;
        handleFailureToastMessage(failureMessage);
      }
    });
  };

  /**
   * Handles the operation to unblock account on click of the Account unblock icon.
   * @function handleUnBlockAccount - Performs action to unblock account.
   */
  const handleUnBlockAccount = () => {
    unblockChildAccount(config.server, channelPartnerCode).then((res) => {
      if (res && res.status === 200) {
        const sucessMessage = `${profileStatus} Account Unblock ${SUCCESSFULLY}.`;
        handleSucessToastMessage(sucessMessage);
        handleCallBackUpdateUserDetails();
      } else {
        const failureMessage =
          res && res.data && res.data.message
            ? res.data.message
            : SOMETHING_WENT_WRONG;
        handleFailureToastMessage(failureMessage);
      }
    });
  };

  /**
   * Handles the Reset Password Operation onClick of the reset Password icon
   * @function handleResetPassword - Performs action to reset the password.
   */
  const handleResetPassword = () => {
    resetChildPassword(config.server, channelPartnerCode).then((response) => {
      if (response && response.status === 200) {
        let sucessMessage = `${PASSWORD_SENT_TO} ${profileStatus} ${REGISTERED_MOBILE_NUMBER}.`;
        handleSucessToastMessage(sucessMessage);
      } else {
        const failureResetPasswordMessage =
          response && response.data && response.data.message
            ? response.data.message
            : SOMETHING_WENT_WRONG;
        handleFailureToastMessage(failureResetPasswordMessage);
      }
    });
  };

  /**
   * Handles the Reset Passcode Operation onClick of the reset Passcode icon.
   * @function handleResetPasscode - Performs action to reset the Passcode.
   */
  const handleResetPasscode = () => {
    resetChildPasscode(config.server, channelPartnerCode).then((response) => {
      if (response && response.status === 200) {
        let sucessMessage = `${PASSCODE_SENT_TO} ${profileStatus} ${REGISTERED_MOBILE_NUMBER}`;
        handleSucessToastMessage(sucessMessage);
      } else {
        const failureResetPasscodeMessage =
          response && response.data && response.data.message
            ? response.data.message
            : SOMETHING_WENT_WRONG;
        handleFailureToastMessage(failureResetPasscodeMessage);
      }
    });
  };

  /**
   * Handles the  display of Popup UI for Account Block Confirmation
   * @function handleResetConfirmationPopup - Performs action to display the UI for the Account block confirmation.
   */

  const handleResetConfirmationPopup = (key) => {
    setShowConfirm(true);
    setLabelKey(key);
  };
  /**
   * Handles the close operation for Popup UI for Account Block Confirmation
   * @function handleAccountConfirm - Performs action to close the UI for the Account block confirmation.
   */
  const handleCloseConfirmationModal = () => {
    setShowConfirm(false);
    setLabelKey("");
  };

  /**
   * Handles the confirmation action to Block the Account.
   * @function handleConfirm - Performs action to Confirm the Block/unblock of user account.
   */
  const handleConfirm = (reason) => {
    switch (labelKey) {
      case KEY_BLOCK_USER:
        handleBlockAccount(reason);
        break;
      case KEY_UNBLOCK_USER:
        handleUnBlockAccount();
        break;
      case KEY_RESET_PASSWORD_USER:
        handleResetPassword();
        break;
      case KEY_RESET_PASSCODE_USER:
        handleResetPasscode();
        break;
      default:
        break;
    }
    setShowConfirm(false);
  };
  const getLabel = () => {
    switch (labelKey) {
      case KEY_BLOCK_USER:
        return CONFIRMATION_LOCK_USER_LABEL;
      case KEY_UNBLOCK_USER:
        return CONFIRMATION_UNLOCK_USER_LABEL;
      case KEY_RESET_PASSWORD_USER:
        return CONFIRMATION_PASSWORD_LABEL;
      case KEY_RESET_PASSCODE_USER:
        return CONFIRMATION_PASSCODE_LABEL;
      default:
        break;
    }
  };

  /**
   * Renders all the Icons of the profile card.
   * @function groupOfButtons - Displays the UI for all the Action Icons for the Profiles card.
   * @returns {component} UI for the action icons
   */
  const groupOfButtons = () => {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            pt: { lg: 0, xs: 1 },
            pr: 3,
            pl:1,
            display: "flex",
            alignItems: { lg: "center" },
            flexDirection: { xs: "column", lg: "row" }
          }}
        >
          {isActiveUser ? (
            <Tooltip title={BLOCK_ACCOUNT}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mt: { xs: 1, lg: 0 }
                }}
              >
                <Box
                  data-testid="accountLock"
                  sx={{
                    p: 1,
                    mr: { xs: 1.5, lg: 0 },
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #0F57CB",
                    borderRadius: "4px",
                    cursor: "pointer",
                    background: "#ffffff",
                    transition: "0.15s ease-in-out",
                    width: "fit-content",
                    "&:hover": {
                      transform: "scale3d(1.20, 1.20, 1)",
                      boxShadow: "2px 3px 2px  #dddddd"
                    }
                  }}
                  onClick={() => handleResetConfirmationPopup(KEY_BLOCK_USER)}
                >
                  <img src={Lock} alt="r"></img>
                </Box>
                <Typography
                  sx={{
                    display: {
                      xs: "block",
                      lg: "none",
                      fontSize: "11px",
                      paddingTop: "5px",
                      color: "#282828",
                      fontFamily: "BukraRegular",
                      textTransform: "capitalize"
                    }
                  }}
                >
                  {BLOCK_ACCOUNT}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title={UNBLOCK_ACCOUNT}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mt: { xs: 1, lg: 0 }
                }}
              >
                <Box
                  data-testid="accountunLock"
                  sx={{
                    p: 1,
                    mr: { xs: 1.5, lg: 0 },
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #0F57CB",
                    borderRadius: "4px",
                    cursor: "pointer",
                    background: "#ffffff",
                    transition: "0.15s ease-in-out",
                    width: "fit-content",
                    "&:hover": {
                      transform: "scale3d(1.20, 1.20, 1)",
                      boxShadow: "2px 3px 2px  #dddddd"
                    }
                  }}
                  onClick={() => handleResetConfirmationPopup(KEY_UNBLOCK_USER)}
                >
                  <img src={UnLock} alt="open"></img>
                </Box>
                <Typography
                  sx={{
                    display: {
                      xs: "block",
                      lg: "none",
                      fontSize: "11px",
                      paddingTop: "5px",
                      color: "#282828",
                      fontFamily: "BukraRegular",
                      textTransform: "capitalize"
                    }
                  }}
                >
                  {UNBLOCK_ACCOUNT}
                </Typography>
              </Box>
            </Tooltip>
          )}

          <Tooltip title={EDIT_PROFILE}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                mt: { xs: 1, lg: 0 }
              }}
            >
              <Box
                data-testid="edit"
                sx={{
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #0F57CB",
                  borderRadius: "4px",
                  cursor: "pointer",
                  mr: { xs: 1.5, lg: 0 },
                  mx: { lg: 1.5 },

                  height: "13px",
                  width: "13px",
                  background: "#ffffff",
                  transition: "0.15s ease-in-out",
                  "&:hover": {
                    transform: "scale3d(1.20, 1.20, 1)",
                    boxShadow: "2px 3px 2px  #dddddd"
                  }
                }}
              >
                <Link to={`${routeToEditProfile}/${channelPartnerCode}`}>
                  <img src={Edit} alt="r"></img>
                </Link>
              </Box>
              <Typography
                sx={{
                  display: {
                    xs: "block",
                    lg: "none",
                    fontSize: "11px",
                    paddingTop: "5px",
                    color: "#282828",
                    fontFamily: "BukraRegular",
                    textTransform: "capitalize"
                  }
                }}
              >
                {EDIT_PROFILE}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title={RESET_PASSWORD}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                mt: { xs: 1, lg: 0 }
              }}
            >
              <Box
                data-testid="resetPassword"
                sx={{
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #0F57CB",
                  borderRadius: "4px",
                  cursor: "pointer",
                  mr: 1.5,
                  background: "#ffffff",
                  transition: "0.15s ease-in-out",
                  width: "fit-content",
                  "&:hover": {
                    transform: "scale3d(1.20, 1.20, 1)",
                    boxShadow: "2px 3px 2px  #dddddd"
                  }
                }}
                onClick={() =>
                  handleResetConfirmationPopup(KEY_RESET_PASSWORD_USER)
                }
              >
                <img src={Reset} alt="r"></img>
              </Box>
              <Typography
                sx={{
                  display: {
                    xs: "block",
                    lg: "none",
                    fontSize: "11px",
                    paddingTop: "5px",
                    color: "#282828",
                    fontFamily: "BukraRegular",
                    textTransform: "capitalize"
                  }
                }}
              >
                {RESET_PASSWORD}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title={RESET_PASSCODE}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                mt: { xs: 1, lg: 0 }
              }}
            >
              <Box
                data-testid="resetPasscode"
                sx={{
                  p: 1,
                  mr: 1.5,
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #0F57CB",
                  borderRadius: "4px",
                  cursor: "pointer",
                  background: "#ffffff",
                  transition: "0.15s ease-in-out",
                  width: "fit-content",
                  "&:hover": {
                    transform: "scale3d(1.20, 1.20, 1)",
                    boxShadow: "2px 3px 2px  #dddddd"
                  }
                }}
                onClick={() =>
                  handleResetConfirmationPopup(KEY_RESET_PASSCODE_USER)
                }
              >
                <img src={Passcode} alt="r"></img>
              </Box>
              <Typography
                sx={{
                  display: {
                    xs: "block",
                    lg: "none",
                    fontSize: "11px",
                    paddingTop: "5px",
                    color: "#282828",
                    fontFamily: "BukraRegular",
                    textTransform: "capitalize"
                  }
                }}
              >
                {RESET_PASSCODE}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </ThemeProvider>
    );
  };

  return (
    <>
      <ConfirmationModal
        labelKey={labelKey}
        label={getLabel()}
        showConfirm={showConfirm}
        handleConfirm={handleConfirm}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
      ></ConfirmationModal>
      <Paper elevation={3} data-testid="profileCard">
        <Toolbar>
          <Typography
            sx={{
              pl: 1.4,
              fontFamily: "BukraMedium",
              color: " #464E5F",
              fontSize: "16px",
              letterSpacing: "0px"
            }}
          >
            {profileTittle}
          </Typography>
        </Toolbar>
        <Divider></Divider>
        <Box
          sx={{
            pr: { sm: 4.5, xs: 0 },
            pl: { sm: 4.5, xs: 0 },
            pt: { sm: 4, xs: 0 },
            pb: { sm: 3, xs: 0 },
            display: "flex",
            width: "inherit",
            minHeight: "140px"
          }}
        >
          <Box
            sx={{
              backgroundColor: "#d9ecfc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px"
            }}
          >
            <img
              src={image}
              height="61.89px"
              width="63.95px"
              alt="profile"
            ></img>
          </Box>
          <Box
            sx={{
              backgroundColor: "#f3f5fd",
              display: "flex",
              justifyContent: "space-between",
              p: 1,

              width: "100%"
            }}
          >
            <List sx={{ width: "inherit", pl: 1 }}>
              <ListItem
                sx={{
                  p: 0,
                  pb: 0.5,
                  width: "inherit",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", lg: "row" },
                  alignItems: { xs: "flex-start", lg: "center" }
                }}
              >
                <Typography
                  sx={{
                    color: "#464E5F",
                    fontFamily: "BukraMedium",
                    fontSize: "15px",
                    textTransform: "capitalize", wordWrap: "break-word",
                    lineBreak:"anywhere"
                  }}
                >
                  {profileName}
                </Typography>
                {isChildProfile && groupOfButtons()}
              </ListItem>
              {profileCompany && (
                <ListItem sx={{ p: 0 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={CompanyName}
                      alt="i"
                      height="14px"
                      width="14px"
                    ></img>
                    <ListItemText
                      sx={{
                        pl: 1, wordWrap: "break-word",
                        lineBreak:"anywhere"
                      }}
                      primaryTypographyProps={{
                        fontSize: 14,
                        color: "#95a0a9",
                        fontFamily: "BukraRegular"
                      }}
                    >
                      {profileCompany}
                    </ListItemText>
                  </Box>
                </ListItem>
              )}

              <ListItem
                sx={{
                  p: 0,
                  width: "fit-content",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "flex-start", sm: "center" }
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={mobileNumber}
                    height="12px"
                    width="12px"
                    alt="i"
                  ></img>
                  <ListItemText
                    sx={{
                      pl: 1
                    }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      color: "#95a0a9",
                      fontFamily: "BukraRegular",
                      width: "auto"
                    }}
                  >
                    {profileNumber}
                  </ListItemText>
                </Box>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    ml: 1,
                    mr: 1,
                    display: { xs: "none", sm: "block", borderLeftWidth: 1 }
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={Email_Icon}
                    height="11px"
                    width="14px"
                    alt="i"
                  ></img>
                  <ListItemText
                    sx={{
                      pl: 1,
                      lineBreak:"anywhere",
                      wordWrap: "break-word"
                    }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      color: "#95a0a9",
                      fontFamily: "BukraRegular"
                    }}
                  >
                    {profileEmail}
                  </ListItemText>
                </Box>
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={Address} height="16px" width="11px" alt="i"></img>
                  <ListItemText
                    sx={{
                      pl: 1,
                      lineBreak:"anywhere",
                      wordWrap: "break-word"
                    }}
                    primaryTypographyProps={{
                      fontSize: 14,
                      color: "#95a0a9",
                      fontFamily: "BukraRegular"
                    }}
                  >
                    {profileAddress}
                  </ListItemText>
                </Box>
              </ListItem>
              {creditBalance && (
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 14,
                      color: "#95a0a9",
                      fontFamily: "BukraRegular"
                    }}
                  >
                    {CREDIT_BALANCE}:{" "}
                    <b style={{ color: "#000000", fontFamily: "BukraMedium" }}>
                      {creditBalance}
                    </b>
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ProfileCard;
