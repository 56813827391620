import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

/**
 * Renders Toast component.
 * @param {{children:object}} props - Props passed to toast UI component.
 * @returns {component} Toast Component
 */
const ToastPortal = ({ children }) => {
  const portalRoot = document.getElementById("toastRoot");
  const toastContainer = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    portalRoot.appendChild(toastContainer);
    return () => {
      if (toastContainer) {
        toastContainer.remove();
      }
    };
  });

  return createPortal(children, portalRoot);
};

export default ToastPortal;
