import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";
import profileHeader from "./../../assets/images/profile_header.svg";
import { userSignOut } from "../../services/user.services";
import { profileMenuList } from "../../constant/ScopeMenus";
import { getUserScope } from "../../stateManagement/reducers/LoginSlice";
import { LOGGED_OUT, TOAST_SUCCESS } from "../../constant/Constants";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { setNotificationSource } from "../../stateManagement/reducers/NotificationSlice";
import { ThemeProvider } from "@mui/material";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";

/**
 * Renders the Profile Menu icon with the dropdown content in Navigation Headerbar.
 * @returns {component} the Profile Menu Icon with its dropdown content.
 */
export default function ProfileMenu() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuList, setMenuList] = useState([]);
  const fetchUserScopes = useSelector((state) => getUserScope(state));
  const open = Boolean(anchorEl);

  /**
   * handles the click operation on the profile menu Icon.
   * @function handleClick - Performs action on Profile menu icon click.
   * @param {object} event - object event on Profile menu icon click.
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the close opeation on Profile menu click.
   * @function handleClose - Peforms action to close the Profile menu items.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles the signout operation on the Signout click in the dropdown of profile Menu.
   * @function signOut-  Performs action to Signout the logged in user.
   */
  const signOut = () => {
    let toastDetails = {
      toastTitle: LOGGED_OUT,
      isToastOpen: true,
      toastStatus: TOAST_SUCCESS
    };
    dispatch(userSignOut());
    dispatch(updateToastMessage(toastDetails));
    dispatch(setNotificationSource([]));
    sessionStorage.removeItem("userToken");
    sessionStorage.removeItem("fcmToken");
  };

  /**
   * performs the click operation on ProfileMenu dropdown list elements.
   * @param {string} id - profile Menu label.
   * @returns the Click operation if id is not logout
   */
  const handleMenu = (id) => {
    if (id !== "logout") return;
    signOut();
  };

  /**
   * Preload the ProfileMenu dropdown list elements.
   */
  useEffect(() => {
    const profileList = profileMenuList.filter((element) =>
      fetchUserScopes.includes(element.scope)
    );
    setMenuList(profileList);
  }, [fetchUserScopes]);

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        data-testid="profileMenu"
      >
        <ThemeProvider theme={ToolTipThemeWithBG}>
          <Tooltip title="My Profile" arrow>
            <IconButton
              data-testid="accountIcon"
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 40, height: 40 }}
                src={profileHeader}
                alt="profile"
              ></Avatar>
            </IconButton>
          </Tooltip>
        </ThemeProvider>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: "140px",
            overflow: "visible",
            backgroundColor: "#f6f6f6",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            // pl: 2.5,
            // pr: 2.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: "#f6f6f6",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        sx={{
          ".MuiList-root": {
            p: 0
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menuList &&
          menuList.map((item, index) => {
            let route = "";
            switch (item.id) {
              case "profile":
                route = "dashboard/myprofile";
                break;
              case "masterDistributorProfile":
                route = "dashboard/masterdistributorprofile";
                break;
              case "subDistributorProfile":
                route = "dashboard/subdistributorprofile";
                break;
              case "companyProfile":
                route = "dashboard/companyprofile";
                break;
              case "changePassword":
                route = "changepassword";
                break;
              case "logout":
                route = "/login";
                break;
              default:
                route = "#";
                break;
            }
            return (
              <MenuItem
                key={`${item.title + index}`}
                data-testid="allMenus"
                divider={index === profileMenuList.length - 1 ? false : true}
                sx={{
                  py: 0,
                  px: 3,
                  "& :hover": {
                    transition: "0.15s ease-in-out",
                    transform: "scale(1.05 ,1.05)"
                  }
                }}
                onClick={() => handleMenu(item.id)}
              >
                <NavLink
                  className={"profileNavlink"}
                  style={({ isActive }) => ({
                    fontFamily: isActive ? "BukraMedium" : "BukraRegular",
                    color: "#212121",
                    textDecoration: "none",
                    width: "100%",
                    paddingBottom: "8px",
                    paddingTop: "8px",
                    fontSize: "11px"
                  })}
                  to={route}
                >
                  {item.title}
                </NavLink>
              </MenuItem>
            );
          })}
      </Menu>
    </React.Fragment>
  );
}
