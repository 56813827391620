import { getMessaging, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

let messaging;
if ("serviceWorker" in navigator && "PushManager" in window) {
  // MBC-Go Box config
  // const firebaseConfig = {
  //   apiKey: "AIzaSyAcU_uGSZMEekiHyfpMuXLqwUdq3qsVrSY",
  //   authDomain: "mbcgobx-94d07.firebaseapp.com",
  //   projectId: "mbcgobx-94d07",
  //   storageBucket: "mbcgobx-94d07.appspot.com",
  //   messagingSenderId: "890576395985",
  //   appId: "1:890576395985:web:e8fff2927453c469d2aff0",
  //   measurementId: "G-MVYNE2C5MD"
  // };
  const firebaseConfig = {
    apiKey: "AIzaSyCfwnPRNePznTA-zLfPzziBBEY3XyhnwrU",
    authDomain: "mbc-now-app.firebaseapp.com",
    projectId: "mbc-now-app",
    storageBucket: "mbc-now-app.appspot.com",
    messagingSenderId: "1027980990043",
    appId: "1:1027980990043:web:c25a678d53164b50da117e",
    measurementId: "G-RGEHEPMPHF"
  };
  // export const firebaseApp = initializeApp(firebaseConfig);
  // export const messaging = getMessaging(firebaseApp);
  const firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
}
export { messaging };

// export const getFCMToken = (setTokenFound) => {
//   return getToken(messaging, {
//     vapidKey: GENERATED_FC_MESSAGING_KEY,
//   })
//     .then((currentToken) => {
//       console.log("getFCMToken kumar", currentToken);
//       if (currentToken) {
//         localStorage.setItem("fcmToken",currentToken)
//         console.log("Kumar current token for client: ", currentToken);
//         setTokenFound(true);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log(
//           " kumar No registration token available. Request permission to generate one."
//         );
//         setTokenFound(true);
//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//       // catch error while creating client token
//     });
// };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
