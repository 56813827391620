import React, { memo } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertTitle, Typography } from "@mui/material";
import toastErrorIcon from "./../../../assets/images/toast_error_small.png";
import { TOAST_AWAIT, TOAST_SUCCESS } from "../../../constant/Constants";
import Toast_Succes_Icon from "./../../../assets/images/toast_success_icon.svg";
import Toast_Awaiting_Icon from "./../../../assets/images/toast_awaiting_icon.svg";

/**
 * Renders the React MUI Snackbars based on the Props passed.
 * @param {object} props - Prop elements passed to the custom Snackbar.
 * @returns {component} the React MUI Snackbars based on the Props passed.
 */
const CustomSnackbar = (props) => {
  /**
   * Initial custom Snackbar value.
   */
  const {
    message = "Something went wrong",
    borderClr = "#6ac380",
    bgClr = "#e6ffee",
    toastTextclr = "FF2A2A",
    toastSubTittle,
    showToast = false,
    showToastCallback,
    alertStatus = "success",
    alertSeverity
  } = props;

  /**
   * Handles the close functionality of the Snackbar.
   * @function handleClose - Performs the close operation on the Snackbar
   */
  const handleClose = () => {
    showToastCallback();
  };

  /**
   * Renders the Icon based on the Toast Status.
   * @function renderStatusIcons - Performs the Icon change operation based on the Toast Status.
   * @param {string} status - status of toast message.
   * @returns {component} the icon based on the Toast status.
   */
  const renderStatusIcons = (status) => {
    if (status === TOAST_SUCCESS) {
      return <img src={Toast_Succes_Icon} alt="success" height="15px"></img>;
    } else if (status === TOAST_AWAIT) {
      return <img src={Toast_Awaiting_Icon} alt="await" height="15px"></img>;
    } else {
      return <img src={toastErrorIcon} alt="error" height="15px"></img>;
    }
  };
  return (
    <Snackbar
      open={showToast}
      autoHideDuration={2000}
      onClose={handleClose}
      sx={{
        "&.MuiSnackbar-anchorOriginBottomLeft ": {
          top: "0px",
          position: "relative",
          left: "0px"
        }
      }}
      data-testid="snackbarComponent"
    >
      <Alert
        severity={alertSeverity}
        icon={renderStatusIcons(alertStatus)}
        sx={{
          width: "fit-content",
          backgroundColor: bgClr,
          fontFamily: "BukraMedium",
          borderRadius: "10px",
          fontSize: `12px`,
          border: `2px solid ${borderClr}`,
          pt: "0px",
          pb: "0px",
          letterSpacing: "0px",
          boxShadow:
            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
          ".MuiAlert-icon,.MuiAlert-action": {
            alignItems: "center",
            pt: 0,
            pb: 0,
            mr: 0
          }
        }}
      >
        <AlertTitle
          sx={{
            color: toastTextclr,
            fontFamily: "BukraMedium",
            letterSpacing: "0.4px",
            m: 0,
            fontSize: "12px",
            pl: 1
          }}
        >
          {message}
        </AlertTitle>
        {toastSubTittle && (
          <Typography
            sx={{
              fontSize: "10px",
              color: toastTextclr,
              fontFamily: "BukraRegular",
              letterSpacing: "0.33px",
              pl: 1
            }}
          >
            {toastSubTittle}
          </Typography>
        )}
      </Alert>
    </Snackbar>
  );
};

export default memo(CustomSnackbar);
