import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  currencyConvertToReadable,
  getComparator,
  getDDMMYYYYDateFormat,
  tableSorting,
  transferTypeLabel
} from "../../../constant/Utils";
import { createTheme, ThemeProvider, Tooltip, Typography } from "@mui/material";
import {
  NINE_GRID_DATE,
  NINE_GRID_TRANSFER,
  NO_DATA
} from "../../../constant/Constants";
import { useSelector } from "react-redux";
import CustomPagination from "../../customComponents/customPagination/CustomPagination";
import { getFilteredDateFromStore } from "../../../stateManagement/reducers/DateFilterSlice";
import { tableScrollStyles } from "../../../style/CustomStyles";
import userName_Icon from "./../../../assets/images/user_name.svg";
import BodyLoader from "../../loaders/bodyLoader/BodyLoader";

/**
 * Table headcells object for the Pending Transaction  table.
 * @constant {array<object>} - Table headers.
 */
const headCells = [
  {
    id: "fromUser",
    label: "From",
    key: "fromUser"
  },
  {
    id: "transferType",
    label: "Transfer",
    key: "transferType"
  },
  {
    id: "creditAmount",
    label: "Amount",
    key: "creditAmount"
  },
  {
    id: "modifiedDateTime",
    label: "Date",
    key: "modifiedDateTime"
  }
];
const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#e0f2fc 0% 0% no-repeat padding-box",
          boxShadow: "0px 8px 12px #00000029",
          border: "1px solid #d8dcdf"
        },
        arrow: {
          color: "#e0f2fc"
        }
      }
    }
  }
});

/**
 * Renders the Pending Transaction Table component.
 * @param {object} props - Props for the Pending Transaction Table
 * @returns {component} the Table UI for the Pending transactions.
 */
export default function PendingCreditsTransfersTable(props) {
  const { isFilterApply, tableBody, handleLoadMoreData, loader } = props;
  const { filterAppliedSelectedCheckBox } = isFilterApply;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDateTime");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [indexofFilterItem, setIndexofFilterItem] = useState([]);
  /**
   * Fetches the date range from the store.
   */
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );

  /**
   * Handles the Pending Transaction table sorting based on the selected table head cell.
   * @param {object} _event - Pointer Event.
   * @param {string} property - Ascending or descending order.
   * @function handleRequestSort - Handlle the Table sorting operation.
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedDateFilter.label]);
  useEffect(() => {
    const indexOfFilterCheckBox = filterAppliedSelectedCheckBox.map((obj) => {
      return headCells.findIndex((item) => item.label === obj);
    });
    setIndexofFilterItem(indexOfFilterCheckBox);
  }, [filterAppliedSelectedCheckBox]);

  const totalCount = Math.ceil(tableBody.length / pageSize);

  /**
   * Handle the Page change operation.
   * @param {event} _event - Pointer event.
   * @param {number} value - Total loaded data count.
   * @function handlePageChange - Handle the table Pagination
   */
  const handlePageChange = (_event, value) => {
    if (value === totalCount) {
      handleLoadMoreData();
    }
    setPage(value);
  };

  /**
   * Handles the Page size change operation.
   * @param {event} event - Pointer event.
   * @function handlePageSizeChange - Handles the Page size change.
   */
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  /**
   * Handles the Filter operation based on the Selected checkbox in the grid element.
   * @param {string} id - Filter label.
   * @param {number} index - Filter string.
   * @function getSelectedCheckBoxStatus - Handle the Filter Operation based on Checked grid elements.
   * @returns {boolean} true or false.
   */
  const getSelectedCheckBoxStatus = (id, index) => {
    switch (id) {
      case NINE_GRID_TRANSFER:
        return indexofFilterItem.includes(index);
      case NINE_GRID_DATE:
        return indexofFilterItem.includes(index);
      default:
        return true;
    }
  };
  /**
   * Renders the UI for the Sender name component.
   * @param {string} name - Name of sender.
   * @returns {component} the UI for the Sender Name
   */
  const renderToolTipName = (name, company) => {
    const senderName = name ? `${name} [${company}]` : "Admin";
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "20px",
          width: "fit-content"
        }}
      >
        <img src={userName_Icon} alt="ee"></img>{" "}
        <Typography
          sx={{
            pl: 1,
            color: "#181D25",
            textTransform: "capitalize",
            fontSize: "13px",
            fontFamily: "BukraRegular"
          }}
        >
          {senderName}
        </Typography>
      </Box>
    );
  };
  return (
    <Box
      sx={{ width: "100%", mt: 2 }}
      data-testid="pendingCreditsTransfersTable"
    >
      <TableContainer sx={{ ...tableScrollStyles }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {headCells.map(
                (headCell, index) =>
                  getSelectedCheckBoxStatus(headCell.label, index) && (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{ border: "none", px: 7 }}
                    >
                      <TableSortLabel
                        data-testid="sortLabel"
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleRequestSort("", headCell.id)}
                        sx={{
                          color: "#606060",
                          letterSpacing: "0px",
                          fontSize: "15px",
                          fontFamily: "BukraMedium"
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          {!loader && tableBody && tableBody.length > 0 && (
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {tableSorting(tableBody, getComparator(order, orderBy))
                .slice((page - 1) * pageSize, page * pageSize)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      key={labelId}
                      style={
                        index % 2
                          ? { background: "white" }
                          : { background: "#F7F9FC" }
                      }
                    >
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          px: 7
                        }}
                      >
                        <ThemeProvider theme={toolTipTheme}>
                          <Tooltip
                            arrow
                            placement="top"
                            title={renderToolTipName(
                              row.fromChannelPartnerName,
                              row.fromCompanyName
                            )}
                            sx={{
                              cursor: "pointer"
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#282828",
                                letterSpacing: "0px",
                                fontSize: "13px",
                                fontFamily: "BukraRegular"
                              }}
                            >
                              {row.fromChannelPartnerName}
                            </Typography>
                          </Tooltip>
                        </ThemeProvider>
                      </TableCell>
                      {filterAppliedSelectedCheckBox.includes(
                        NINE_GRID_TRANSFER
                      ) && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            px: 7
                          }}
                        >
                          {/* {row.transferType === DIRECT
                            ? "Direct Transfer"
                            : "Reverse Transfer"} */}
                          {transferTypeLabel(row.transferType)}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          px: 7
                        }}
                      >
                        {`${row.currency} 
                          ${currencyConvertToReadable(row.creditAmount)}`}
                      </TableCell>
                      {filterAppliedSelectedCheckBox.includes("Date") && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            px: 7
                          }}
                        >
                          {getDDMMYYYYDateFormat(row.modifiedDateTime)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!loader && tableBody && tableBody.length === 0 && (
        <Typography
          textAlign={"center"}
          sx={{
            mt: 3,
            pb: 3
          }}
        >
          {NO_DATA}
        </Typography>
      )}

      {loader && <BodyLoader />}

      {tableBody && tableBody.length > 0 && (
        <CustomPagination
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          totalCount={totalCount}
          page={page}
          handlePageChange={handlePageChange}
        ></CustomPagination>
      )}
    </Box>
  );
}
