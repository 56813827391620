import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {
  capitalizeFirstLetter,
  getBreadcrumbLabels
} from "../../../constant/Utils";

/**
 * Renders the Breadcrumbs based on the URL
 * @returns {component} The Breadcrumbs UI based on the URL
 */
const CustomBreadcrumbs = () => {
  const location = useLocation();
  const params = useParams();
  const pathnames = location.pathname.split("/").filter((x) => {
    //  To check if a url contain id params
    // if it is a id return empty else returning pathnames to Breadcrumbs
    if (params && params.id === x) {
      return "";
    } else {
      return x;
    }
  });
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator=">"
      sx={{
        whiteSpace: "break-spaces"
      }}
    >
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const finalValues = getBreadcrumbLabels(value);
        return last ? (
          <Typography
            sx={{
              color: "#000000",
              letterSpacing: "0.15px",
              fontFamily: "BukraMedium",
              fontSize: { xs: "13px", md: "15px" },
              opacity: 0.7
            }}
            key={to}
          >
            {capitalizeFirstLetter(finalValues)}
          </Typography>
        ) : (
          <Link underline="hover" to={to} key={to} component={RouterLink}>
            <Typography
              sx={{
                color: "#000000",
                letterSpacing: "0.15px",
                opacity: 0.7,
                fontFamily: "BukraRegular",
                fontSize: { xs: "13px", md: "15px" }
              }}
              key={to}
            >
              {capitalizeFirstLetter(finalValues)}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
