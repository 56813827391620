import React, { memo, useEffect, useState } from "react";
import {
  getChannelPartnerCode,
  getUserRoleType
} from "../../stateManagement/reducers/LoginSlice";
import { useSelector,useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getQuickImages } from "../../constant/ScopeMenus";
import { Box, Typography } from "@mui/material";
import { QUICKLINKS } from "../../constant/Constants";
import { getStartDateAndEndDate, setIntialLogHistory } from "../../constant/Utils";
import { getFilteredDate } from "../../stateManagement/reducers/DateFilterSlice";

/**
 * Renders the Most Visited Links UI in Wuick links.
 * @param {object} props - Props passed to Display the Most visited pages link in Quicklinks.
 * @returns {component} The quick links's Most visited links UI.
 */
const MostVisitedPages = (props) => {
  const { childPath } = props;
  const [visitedPages, setVisitedPages] = useState([]);
  const dispatch = useDispatch()

  /**
   * @constant {string} channelPartnerCode - Gets the channel partner code from the store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * @constant {number} roleType - Gets the User Role type based on the logged in user's channel partner code.
   */
  const roleType = useSelector((state) => getUserRoleType(state));

  /**
   * get the most visted pages from local store
   */
  const getVisitedPages = localStorage.getItem(`QL_${channelPartnerCode}`);

  useEffect(() => {
    if (!getVisitedPages && channelPartnerCode) {
      setIntialLogHistory(channelPartnerCode, roleType);
    }
  }, [getVisitedPages, channelPartnerCode, roleType]);

  useEffect(() => {
    const parseData = getVisitedPages && JSON.parse(getVisitedPages);
    const sortedElements =
      parseData && parseData.sort((a, b) => b.count - a.count);
    const filteredElements =
      sortedElements &&
      sortedElements.filter((item) => {
        return childPath ? !childPath.includes(item.path) : true;
      });

    setVisitedPages(filteredElements);
  }, [getVisitedPages, childPath]);

  const defaultFilterTime =()=>{
    dispatch(getFilteredDate(getStartDateAndEndDate()));
  }
  const renderRoutes = (quickItem) => {
    const quickDetails = getQuickImages(quickItem.path);
    const src = quickDetails && quickDetails.src ? quickDetails.src : "";
    const label = quickDetails && quickDetails.label ? quickDetails.label : "";
    return (
      <Box
        data-testid="quickLink"
        key={quickItem.path}
        onClick={()=>{
          defaultFilterTime()
        }}
        sx={{
          pr: 2,
          color: "#1155CC",
          display: "flex",
          alignItems: "center",
          fontSize: "13px",
          fontFamily: "BukraRegular",
          letterSpacing: "0px"
        }}
      >
        {src && <img src={src} alt={"alt"} />}
        <Link
          to={quickItem.path}
          style={{
            marginLeft: "5px",
            color: "#1155CC",
            fontFamily: "BukraRegular",
            fontSize: "13px",
            letterSpacing: "0px",
            width: "max-content"
          }}
        >
          {label}
        </Link>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: { md: "center" },
        py: { xs: 1, md: 0 }
      }}
      data-testid="mostVisitedPages"
    >
      {
        <Typography
          sx={{
            color: "#282828",
            fontFamily: "BukraRegular",
            fontSize: { md: "15px", xs: "13px" },
            letterSpacing: "0px",
            maxWidth: { xs: "200px" },
            width: { xs: "140px", sm: "90px", md: "auto" }
          }}
        >
          {QUICKLINKS}
        </Typography>
      }
      <Box
        sx={{
          px: { sm: 2, xs: 1 },
          display: "flex",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            width: { xs: 0 }
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: { xs: "unset" }
          },
          scrollbarWidth: "none"
        }}
      >
        {visitedPages &&
          visitedPages.slice(0, 3).map((item) => {
            return item.count > 0 && renderRoutes(item);
          })}
      </Box>
    </Box>
  );
};

export default memo(MostVisitedPages);
