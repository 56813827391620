import { Box } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MainContent from "../mainContent/MainContent";
import NavHeaderBar from "../navHeaderBar/NavHeaderBar";
import NavSideBar from "../navSideBar/NavSideBar";
import { SIDE_NAVBAR_WIDTH } from "../../constant/Constants";
import { useDispatch } from "react-redux";
import {
  getBroadcastNotification,
  getCountryDetails
} from "../../services/user.services";
import WelcomeMessage from "../welcomeMessage/WelcomeMessage";

/**
 * Renders the Page Layout for the Protect routes or the logged in user.
 * @returns {component} the Page Layout for the Protected routes or Logged in user
 */
const PageLayoutOne = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateTo = useNavigate();

  /**
   * Handle the sidebar navigation drawer toggle on smaller screens
   * @function handleDrawerToggle - Toggles the Drawer.
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  /**
   * Preloads the country Details based on the user IP.
   */
  useEffect(() => {
    dispatch(getCountryDetails());
    dispatch(getBroadcastNotification());
  }, [dispatch]);

  /**
   * Performs UI option based on the User URL path.
   */
  useEffect(() => {
    if (location.pathname === "/") {
      navigateTo("/dashboard");
    }
  }, [location, navigateTo, dispatch]);
  return (
    <Box
      data-testid="pageLayout"
      sx={{
        ml: { lg: `${SIDE_NAVBAR_WIDTH}px` }
      }}
    >
      <NavHeaderBar handleDrawerToggle={handleDrawerToggle}></NavHeaderBar>
      <NavSideBar
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      ></NavSideBar>
      <MainContent>
        <Outlet></Outlet>
      </MainContent>
      {sessionStorage.getItem("showInitialModal") === "true" ? (
        <WelcomeMessage></WelcomeMessage>
      ) : (
        ""
      )}
    </Box>
  );
};

export default memo(PageLayoutOne);
