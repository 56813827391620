import React, { memo, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Outlet, useLocation } from "react-router-dom";
import RenderChildComponent from "../../components/renderChildComponent/RenderChildComponent";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import ChildChannelPartnerList from "../../components/childChannelPartnerList/ChildChannelPartnerList";
import { LIST_OF_RETAILERS } from "../../constant/Constants";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { updatePageVisit } from "../../constant/Utils";
import { useSelector } from "react-redux";
import { urlPath } from "../../constant/ScopeMenus";

/**
 * Renders the Retailer Page UI.
 * @returns {component} Retailer Page UI component
 */
const Retailers = () => {
  const location = useLocation();
  const isListofRetailersPresent =
    location.pathname.split("/").includes("retaileronboarding") ||
    location.pathname.split("/").includes("retailerprofile") ||
    location.pathname.split("/").includes("editprofile") ||
    false;

  /**
   * @constant {string} channelPartnerCode - Channel Partner code from the store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * Updates the page visit info.
   */
  useEffect(() => {
    if (location.pathname === urlPath.retailer && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * @constant {array<string>} childPath - URL Paths.
   */
  const childPath = ["/retailers/retaileronboarding", "/retailers"];
  return (
    <div>
      <Stack spacing={2}>
        <Outlet />
        <RenderChildComponent isChildPresent={isListofRetailersPresent}>
          <Stack spacing={2}>
            <QuickLinks
              childPath={childPath}
              showCards={true}
              showFilter={true}
              showRevenueCard={true}
            ></QuickLinks>
            <ChildChannelPartnerList
              isRetailer={true}
              tittle={LIST_OF_RETAILERS}
              routeToProfile="/retailers/retailerprofile"
            ></ChildChannelPartnerList>
          </Stack>
        </RenderChildComponent>
      </Stack>
    </div>
  );
};

export default memo(Retailers);
