import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
  Tooltip,
  Box
} from "@mui/material";
import { subscriptionPackageTableHeader } from "../../constant/TableHeaderConstants";
import { ToolTipTheme } from "../../constant/themes/ToolTipTheme";
import {
  currencyConvertToReadable,
  getComparator,
  getDDMMYYYYDateFormat,
  getDurationLabel,
  tableSorting
} from "../../constant/Utils";
import BuyNow_Img from "./../../assets/images/buy_now_icon.svg";
import BuyNow_Disable_Img from "./../../assets/images/buy_now_icon_disable.svg";
import AddToCart_Img from "./../../assets/images/addcardDisable.svg";
// import activeIcon from "./../../assets/images/activetag.png";
// import expiredIcon from "./../../assets/images/expiredtag.png";
import {
  ACTION,
  ACTIVE_TILL,
  EXPIRED_ON,
  KEY_ACTIVE,
  KEY_CANCELED,
  KEY_EXPIRED
} from "../../constant/Constants";

// import AddToCart_Sucess_Img from "./../../assets/images/green_view_cart.svg";

const SubscriptionPackageListTable = (props) => {
  const {
    stbPlanList,
    countryCode,
    handleBuyNow,
    // handleRemoveSeletedItem,
    // handleAddItemsToCart,
    // isAddedtocart,
    isfromRenew
  } = props;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("duration");
  const [isRenewBuyNowDisabled, setIsRenewBuyNowDisabled] = useState(false);
  useEffect(() => {
    const hasActivePlan = stbPlanList.some(
      (plan) =>
        plan.activeForCustomer === KEY_ACTIVE ||
        plan.activeForCustomer === KEY_CANCELED
    );
    setIsRenewBuyNowDisabled(hasActivePlan);
    return () => {};
  }, [stbPlanList]);

  // const totalSelectedNewSubscriptionItems = useSelector((state) =>
  //   getSelectNewSubscriptionItems(state)
  // );
  // const totalSelectedRenewItems = useSelector((state) =>
  //   getSelectRenewItems(state)
  // );

  // const getSeletedItems = () => {
  //   return isfromRenew
  //     ? totalSelectedRenewItems
  //     : totalSelectedNewSubscriptionItems;
  // };
  /**
   * Handle the Sorting based on the Selected table head.
   * @function handleRequestSort - Handle the Acsending and Descending sorting operation.
   * @param {object} _event - Pointer event.
   * @param {string} property - Selected Sorting table header.
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * Handles the Image UI for the Pack addition or removal.
   * @function renderAddtoCartImage - Performs action to display the Image UI for the cart addition or removal.
   * @param {boolean} iscartadded - Pack is added or not status
   * @param {object} row - pack details
   * @returns {JSX} to display the image ui for the pack Addition or removal
   */
  // const renderAddtoCartImage = (iscartadded, row) => {
  //   return (
  //     <>
  //       {iscartadded ? (
  //         <Tooltip title="Remove from cart" className="tooltip_mweb">
  //           <Box
  //             sx={{
  //               display: "flex",
  //               flexDirection: { xs: "column" }
  //             }}
  //           >
  //             <img
  //               data-testid="removeCard"
  //               src={AddToCart_Sucess_Img}
  //               alt="add"
  //               style={{
  //                 cursor: "not-allowed"
  //               }}
  //               onClick={() => handleRemoveSeletedItem(row)}
  //             ></img>
  //             <Box
  //               sx={{
  //                 display: {
  //                   xs: "block",
  //                   lg: "none",
  //                   fontSize: "11px",
  //                   paddingTop: "5px"
  //                 }
  //               }}
  //             >
  //               Remove from cart
  //             </Box>
  //           </Box>
  //         </Tooltip>
  //       ) : (
  //         // <Tooltip title="Add to cart" className="tooltip_mweb">
  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: { xs: "column" }
  //           }}
  //         >
  //           <img
  //             data-testid="addCard"
  //             src={AddToCart_Img}
  //             alt="add"
  //             style={{
  //               cursor: "not-allowed"
  //             }}
  //             // onClick={() => handleAddItemsToCart(row)}
  //           ></img>{" "}
  //           {/* <Box
  //               sx={{
  //                 display: {
  //                   xs: "block",
  //                   lg: "none",
  //                   fontSize: "11px",
  //                   paddingTop: "5px"
  //                 }
  //               }}
  //             >
  //               Add to cart
  //             </Box> */}
  //         </Box>
  //         // </Tooltip>
  //       )}
  //     </>
  //   );
  // };

  /**
   * Handles the Image UI for the Pack addition or removal
   * @function renderBuyNowCartImage - Performs action to display the Image UI for the cart addition or removal
   * @param {boolean} iscartadded - Pack is added or not status
   * @param {object} row - pack details
   * @returns {JSX} to display the image ui for the pack Addition or removal
   */
  const renderBuyNowCartImage = (row) => {
    return (
      <>
        {isfromRenew &&
        row.activeForCustomer === KEY_EXPIRED &&
        isRenewBuyNowDisabled ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" }
            }}
          >
            <img
              data-testid="buynow"
              src={BuyNow_Disable_Img}
              alt="buy"
              style={{
                marginRight: "10px",
                cursor: "not-allowed"
              }}
            ></img>
          </Box>
        ) : (
          <Tooltip title="Buy Now" className="tooltip_mweb">
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" }
              }}
            >
              <img
                data-testid="buynow"
                src={BuyNow_Img}
                alt="buy"
                style={{
                  marginRight: "10px",
                  cursor: "pointer"
                }}
                onClick={() => handleBuyNow(row)}
              ></img>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    lg: "none",
                    fontSize: "11px",
                    paddingTop: "5px"
                  }
                }}
              >
                Buy Now
              </Box>
            </Box>
          </Tooltip>
        )}
      </>
    );
  };
  const renderPlanEndDate = (data) => {
    return data.prerenewalSubscriptionEndDateKSA
      ? getDDMMYYYYDateFormat(data.prerenewalSubscriptionEndDateKSA)
      : getDDMMYYYYDateFormat(data.subscriptionEndDateKSA);
  };

  const renderRenewSubscriptionStatus = (activeForCustomer, row) => {
    return activeForCustomer === KEY_ACTIVE ||
      activeForCustomer === KEY_CANCELED ? (
      <Box
        sx={{
          backgroundColor: "#36ab3b",
          color: "#FFFFFF",
          fontFamily: "BukraMedium",
          fontSize: "10px",
          borderRadius: "8px",
          width: "fit-content",
          p: "2px 7px"
        }}
      >
        {`${ACTIVE_TILL} ${renderPlanEndDate(row)}`}
        {/* <img src={activeIcon} alt="alt" height="14px" width="56px"></img> */}
      </Box>
    ) : (
      <Box
        sx={{
          backgroundColor: "#f03630",
          color: "#FFFFFF",
          fontFamily: "BukraMedium",
          fontSize: "10px",
          borderRadius: "8px",
          width: "fit-content",
          p: "2px 7px"
        }}
      >
        {`${EXPIRED_ON} ${getDDMMYYYYDateFormat(row.subscriptionEndDate)}`}

        {/* <img src={expiredIcon} alt="alt" height="14px" width="56px"></img> */}
      </Box>
    );
  };
  return (
    <TableContainer
      sx={{
        maxHeight: 260,
        "&::-webkit-scrollbar": {
          width: { xs: 0, lg: "5px" }
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: { xs: "unset", lg: "#b1b1b1" }
        },
        scrollbarWidth: "none"
      }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size="medium"
        data-testid="table"
      >
        <TableHead>
          <TableRow>
            {subscriptionPackageTableHeader.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding={"normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ borderBottom: "none", paddingLeft: 0 }}
              >
                <TableSortLabel
                  data-testid="sortLabel"
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => handleRequestSort("", headCell.id)}
                  sx={{
                    color: "#606060",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    fontFamily: "BukraMedium"
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell
              align="left"
              padding={"normal"}
              sx={{
                color: "#606060",
                letterSpacing: "0px",
                fontSize: "16px",
                fontFamily: "BukraMedium",
                borderBottom: "none",
                paddingLeft: 0
              }}
            >
              {ACTION}
            </TableCell>
          </TableRow>
        </TableHead>

        {stbPlanList && stbPlanList.length > 0 && (
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                   stbPlanList.slice().sort(getComparator(order, orderBy)) */}
            {tableSorting(stbPlanList, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <TableRow
                    data-testid="tablerow"
                    key={row.skuCode}
                    style={
                      index % 2
                        ? { background: "#F7F9FC" }
                        : { background: "white" }
                    }
                    sx={{
                      width: "50px",
                      paddingLeft: 0
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        border: "none",
                        color: "#282828",
                        letterSpacing: "0px",
                        fontSize: "14px",
                        fontFamily: "BukraRegular",
                        paddingLeft: 0,
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      {row.planName}
                      {isfromRenew
                        ? renderRenewSubscriptionStatus(
                            row.activeForCustomer,
                            row
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        border: "none",
                        color: "#282828",
                        letterSpacing: "0px",
                        fontSize: "14px",
                        fontFamily: "BukraRegular",
                        paddingLeft: 0
                      }}
                    >
                      {`${row.duration} ${getDurationLabel(row.period)}`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        border: "none",
                        color: "#282828",
                        letterSpacing: "0px",
                        fontSize: "14px",
                        fontFamily: "BukraRegular",
                        paddingLeft: 0
                      }}
                    >
                      {`${row.currencyCode} 
                      ${currencyConvertToReadable(
                        row.finalAmount,
                        countryCode
                      )}`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        border: "none",
                        color: "#282828",
                        letterSpacing: "0px",
                        fontSize: "14px",
                        fontFamily: "BukraRegular",
                        paddingLeft: 0
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: {
                            xs: "space-around",
                            lg: "flex-start"
                          }
                        }}
                        className="cartImage"
                      >
                        <ThemeProvider theme={ToolTipTheme}>
                          {renderBuyNowCartImage(row)}
                          {/* {renderAddtoCartImage(isAddedtocart(row), row)} */}
                          {/* <Tooltip title="Buy Now" className="tooltip_mweb">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: { xs: "column", lg: "row" }
                              }}
                            >
                              <img
                                data-testid="buynow"
                                src={BuyNow_Img}
                                alt="buy"
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer"
                                }}
                                onClick={() => handleBuyNow(row)}
                              ></img>
                              <Box
                                sx={{
                                  display: {
                                    xs: "block",
                                    lg: "none",
                                    fontSize: "11px",
                                    paddingTop: "5px"
                                  }
                                }}
                              >
                                Buy Now
                              </Box>
                            </Box>
                          </Tooltip> */}

                          <img
                            data-testid="addCard"
                            src={AddToCart_Img}
                            alt="add"
                            style={{
                              cursor: "not-allowed"
                            }}
                          ></img>
                        </ThemeProvider>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default SubscriptionPackageListTable;
