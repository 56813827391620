import React, { Fragment, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Divider, Tooltip } from "@mui/material";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import searchIcon from "./../../assets/images/search.svg";
import viewGrid from "./../../assets/images/view_grid.svg";
import userName_Icon from "./../../assets/images/user_name.svg";
import reverseTransfer from "./../../assets/images/transfer_red.svg";
import transferGreen from "./../../assets/images/transfer_green.svg";
import {
  VIEW_ALL,
  RESET,
  APPLY,
  NINE_GRID_TRANSACTION_ID,
  NINE_GRID_TYPE,
  NINE_GRID_DATE,
  SCOPES,
  LOADMOREDATE_LIMIT,
  RECENT_CREDITS_LIMIT_MSG,
  TOOLTIP_NINEGRID_IMG,
  TOOLTIP_RCT_VIEWALL,
  NO_DATA
} from "../../constant/Constants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import {
  awaitToastMessage,
  currencyConvertToReadable,
  getComparator,
  getDDMMYYYYDateFormat,
  getSearchItems,
  tableSorting,
  transferTypeLabel
} from "../../constant/Utils";
import { Link } from "react-router-dom";
import { getRecentCredits } from "../../services/user.services";
import CustomPagination from "../customComponents/customPagination/CustomPagination";
import { getUserScope } from "../../stateManagement/reducers/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { muiMenuItemStyles, tableScrollStyles } from "../../style/CustomStyles";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { ToolTipTheme } from "../../constant/themes/ToolTipTheme";
import "./RecentCreditsTransferTable.scss";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * @const {array<object>} headCells - Header Items
 */
const headCells = [
  {
    id: "type",
    numeric: true,
    disablePadding: false,
    label: NINE_GRID_TYPE
  },
  {
    id: "transferType",
    numeric: false,
    disablePadding: false,
    label: "Transfer"
  },
  {
    id: "fromUser",
    numeric: false,
    disablePadding: false,
    label: "From"
  },
  {
    id: "toUser",
    numeric: false,
    disablePadding: false,
    label: "To"
  },
  {
    id: "transactionId",
    numeric: false,
    disablePadding: false,
    label: NINE_GRID_TRANSACTION_ID
  },
  {
    id: "creditAmount",
    numeric: false,
    disablePadding: false,
    label: "Amount"
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Date"
  }
];

/**
 * @constant {array<object>} searchSelectOption - Search Options.
 */
const searchSelectOption = [
  {
    value: "creditAmount",
    label: "Amount"
  },
  {
    value: "transactionId",
    label: NINE_GRID_TRANSACTION_ID
  }
];

/**
 * @constant {array<string>} options - Grid options
 */
const options = [NINE_GRID_TYPE, NINE_GRID_TRANSACTION_ID, NINE_GRID_DATE];

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});
const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#e0f2fc 0% 0% no-repeat padding-box",
          boxShadow: "0px 8px 12px #00000029",
          border: "1px solid #d8dcdf"
        },
        arrow: {
          color: "#e0f2fc"
        }
      }
    }
  }
});

/**
 * Renders the Recent Credits Transfer Table.
 * @param {object} props - Props object passed to the recent credits transfer table.
 * @returns {component} the UI component for the Recent Credits transfer Table.
 */
const RecentCreditsTransferTable = (props) => {
  const { showViewAll, fromCredits, headerLabel, limit } = props;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [searchOption, setSearchOption] = useState("creditAmount");
  const [selectedCheckBox, setselectedCheckBox] = useState(options);
  const [isFilterApply, setIsFilterApply] = useState({
    filterAppliedSelectedCheckBox: selectedCheckBox
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tableHeadCells] = useState(headCells);

  const [recentCreditDetails, setRecentCreditDetails] = useState([]);
  const [fixedRecentCreditDetails, setFixedRecentCreditDetails] = useState([]);
  const fetchUserScopes = useSelector((state) => getUserScope(state));
  const [toCount, setToCount] = useState(50);
  const [totalRecord, setTotalRecord] = useState(LOADMOREDATE_LIMIT);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );
  const config = useSelector((state) => getConfigDetails(state));
  const { startDate, endDate } = selectedDateFilter;
  useEffect(() => {
    setPage(1);
  }, [selectedDateFilter.label]);

  /**
   * Preloads the Recents Credits Transfer data based on the Start date and end date selected.
   */
  useEffect(() => {
    if (startDate && endDate && config.server) {
      setLoader(true);
      getRecentCredits(config.server, startDate, endDate, 0, 10, limit)
        .then((res) => {
          if (res && res.status === 200 && res.data.status !== "NO_CONTENT") {
            let response = [];
            if (fromCredits) {
              response = res.data.data;
              setToCount(res.data.toCount);
              setTotalRecord(res.data.totalRecords);
            } else {
              response = res.data.data.slice(0, 50);
            }

            setRecentCreditDetails(response);
            setFixedRecentCreditDetails(response);
          } else {
            setRecentCreditDetails([]);
            setFixedRecentCreditDetails([]);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [fromCredits, startDate, endDate, limit, config.server]);

  useEffect(() => {
    let filteredRows = fixedRecentCreditDetails;
    if (search) {
      filteredRows = getSearchItems(
        fixedRecentCreditDetails,
        searchOption,
        search
      );
      setPage(1);
    }
    setRecentCreditDetails(filteredRows);
  }, [fixedRecentCreditDetails, searchOption, search]);

  /**
   * Performs the Ascending and descending Operation on Table Items based on the Head cell selected.
   * @function handleRequestSort - Performs the Ascending or descending sorting action.
   * @param {event} _event - event object
   * @param {string} property - search category
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * @const {number} totalCount - the total no. of rows per table.
   */
  const totalCount = Math.ceil(recentCreditDetails.length / pageSize);

  /**
   * Handles the page change Operation.
   * @function handlePageChange - Handles the table row change operation.
   */
  const handlePageChange = useCallback(
    (_event, value) => {
      if (
        value === totalCount &&
        totalRecord > LOADMOREDATE_LIMIT &&
        recentCreditDetails.length >= LOADMOREDATE_LIMIT
      ) {
        const toastMessage = RECENT_CREDITS_LIMIT_MSG;
        dispatch(updateToastMessage(awaitToastMessage(toastMessage)));
      }
      if (
        fromCredits &&
        value === totalCount &&
        totalRecord > recentCreditDetails.length &&
        recentCreditDetails.length < LOADMOREDATE_LIMIT &&
        config.server
      ) {
        getRecentCredits(
          config.server,
          startDate,
          endDate,
          toCount,
          toCount + 5,
          limit
        ).then((res) => {
          if (res && res.status === 200 && res.data.status !== "NO_CONTENT") {
            setToCount(res.data.toCount);
            setRecentCreditDetails([
              ...fixedRecentCreditDetails,
              ...res.data.data
            ]);
            setFixedRecentCreditDetails([
              ...fixedRecentCreditDetails,
              ...res.data.data
            ]);
          } else {
            setRecentCreditDetails([...fixedRecentCreditDetails]);
            setFixedRecentCreditDetails([...fixedRecentCreditDetails]);
          }
        });
      }
      setPage(value);
    },
    [
      dispatch,
      fixedRecentCreditDetails,
      fromCredits,
      totalCount,
      startDate,
      endDate,
      toCount,
      recentCreditDetails,
      totalRecord,
      limit,
      config.server
    ]
  );

  /**
   * Handles the Page size change operatioan.
   * @function handlePageSizeChange - Handles the display of no. of rows per page.
   */
  const handlePageSizeChange = useCallback((event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  }, []);

  /**
   * Sets the input value for the search operation.
   * @function handlerequestSearch - Performs action on change of search input text.
   * @param {object} event - event object for the search Input.
   */
  const handlerequestSearch = (event) => {
    setSearch(event.target.value.trim());
  };

  /**
   * Handles the Search Category select option change.
   * @function handleSelectChange - Handles the Search category change operation.
   * @param {object} event - Event object for search category.
   */
  const handleSelectChange = (event) => {
    setSearchOption(event.target.value);
  };

  /**
   * Handles the Operation on Grid icon click.
   * @function handleGridClick - Performs action on grid click.
   * @param {object} event - Grid click event object.
   */
  const handleGridClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the close operation on the grid elements.
   * @function handleCheckClose - Performs action on click of grid element close.
   */
  const handleCheckClose = () => {
    setselectedCheckBox(isFilterApply.filterAppliedSelectedCheckBox);
    setAnchorEl(null);
  };

  /**
   * Handles the reset operation on Checkbox element
   * @function handleResetCheckBox - Resets the Grid element's checkboxes.
   */
  const handleResetCheckBox = () => {
    setselectedCheckBox([]);
  };

  /**
   * Renders the UI for the Sender name component.
   * @param {string} name - Name of sender.
   * @returns {component} the UI for the Sender Name
   */
  const renderToolTipName = (name, company) => {
    const senderName = name ? `${name} [${company}]` : "Admin";
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "20px"
        }}
      >
        <img src={userName_Icon} alt="ee"></img>{" "}
        <Typography
          sx={{
            pl: 1,
            color: "#181D25",
            fontSize: "13px",
            fontFamily: "BukraRegular"
          }}
        >
          {senderName}
        </Typography>
      </Box>
    );
  };

  const renderToolTipNotes = (transferNotes) => {
    return transferNotes ? (
      <Typography
        sx={{
          px: 0.5,
          color: "#181D25",
          fontSize: "13px",
          fontFamily: "BukraRegular",
          wordBreak: "break-all"
        }}
      >
        {transferNotes}
      </Typography>
    ) : (
      ""
    );
  };

  /**
   * Handles the opearation of filteration of table based on the grid item selected,
   * @param {string} id  - label of the selected checkbox.
   * @returns the Credits transfer table UI based on the Selected checkbox clicked.
   */
  const getSelectedCheckBoxStatus = (id) => {
    switch (id) {
      case "id":
        return isFilterApply.filterAppliedSelectedCheckBox.includes(
          NINE_GRID_DATE
        );
      case "transactionId":
        return isFilterApply.filterAppliedSelectedCheckBox.includes(
          NINE_GRID_TRANSACTION_ID
        );
      case "type":
        return isFilterApply.filterAppliedSelectedCheckBox.includes(
          NINE_GRID_TYPE
        );
      default:
        return true;
    }
  };

  /**
   * apply filter based on the selected grid element.
   * @function handleApplyFilter - Applies the filter based on checkbox click elements.
   */
  const handleApplyFilter = () => {
    setIsFilterApply({
      filterAppliedSelectedCheckBox: selectedCheckBox
    });
    setAnchorEl(null);
  };

  /**
   * Handles the checkbox change operation.
   * @function handleCheckBoxChange - Handles the Change in checkboxed element.
   * @param {object} event - checkbox event object.
   */
  const handleCheckBoxChange = (event) => {
    const changedValue = event.target.value;
    const list = [...selectedCheckBox];
    const index = list.indexOf(changedValue);
    index === -1 ? list.push(changedValue) : list.splice(index, 1);
    setselectedCheckBox(list);
  };

  return (
    <Box data-testid="recentCreditsTransferTable" sx={{ width: "100%", mt: 2 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            display: "flex",
            alignItems: { xs: "flex-start", sm: "center" },
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            minHeight: { sm: "84px" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", md: "center" },
              flexDirection: { xs: "column", md: "row" },
              width: { xs: "100%", sm: "auto" }
            }}
          >
            <Typography
              variant="h6"
              id="tableTitle"
              data-testid="recentCreditTransfers"
              sx={{
                fontFamily: "BukraMedium",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#464E5F",
                mr: 2,
                my: { xs: 1, lg: 0 }
              }}
            >
              {headerLabel}
            </Typography>
            <Box
              data-testid="searchBox"
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                backgroundColor: "#f3f6f9",
                height: "40px",
                width: { xs: "100%", sm: "auto" },
                mb: { xs: 1, lg: 0 }
              }}
            >
              <img
                src={searchIcon}
                alt="search"
                style={{
                  height: "11px",
                  paddingLeft: "20px"
                }}
              ></img>
              <ThemeProvider theme={inputTheme}>
                <InputBase
                  autoComplete="off"
                  inputProps={{ "data-testid": "searchIcon" }}
                  value={search}
                  sx={{ ml: 1, flex: 1, width: { md: "160px", xs: "100%" } }}
                  placeholder="Search"
                  onChange={(event) => handlerequestSearch(event)}
                />
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Select
                  id="filledoption"
                  disableUnderline={true}
                  inputProps={{
                    "data-testid": "selectSearchInner"
                  }}
                  sx={{
                    backgroundColor: "#7B7D801A",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    fontSize: "13px",
                    pl: 2,
                    pr: 2,
                    color: "#282828",
                    fontFamily: "BukraRegular",
                    height: "40px",
                    ".MuiSelect-icon": {
                      px: 1
                    }
                  }}
                  value={searchOption}
                  onChange={handleSelectChange}
                  variant="standard"
                >
                  {searchSelectOption.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ ...muiMenuItemStyles }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </ThemeProvider>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              my: { xs: 1, lg: 0 }
            }}
          >
            {fetchUserScopes.includes(SCOPES.BUTTON_DIRECT_GRID) && (
              <ThemeProvider theme={ToolTipTheme}>
                <Tooltip title={TOOLTIP_NINEGRID_IMG} className="tooltip_mweb">
                  <Box sx={{ mr: 2 }}>
                    <img
                      data-testid="viewGrid"
                      src={viewGrid}
                      alt="grid"
                      height="36px"
                      onClick={handleGridClick}
                    ></img>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          lg: "none",
                          fontSize: "11px",
                          paddingTop: "5px"
                        }
                      }}
                    >
                      Columns
                    </Box>
                  </Box>
                </Tooltip>
              </ThemeProvider>
            )}

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCheckClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  minWidth: "150px",
                  overflow: "visible",
                  backgroundColor: "#f6f6f6",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  pl: 2.5,
                  pr: 2.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: { sm: 14 },
                    width: 10,
                    height: 10,
                    bgcolor: "#f6f6f6",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {options.map((option, index) => {
                return (
                  <Box key={`${option + index}`}>
                    <MenuItem
                      sx={{
                        p: 0,
                        fontSize: "13px",
                        fontFamily: "BukraRegular",
                        color: "#212121",
                        "&.Mui-selected": {
                          fontFamily: "BukraMedium",
                          backgroundColor: "inherit",
                          "&.Mui-selected:focus": {
                            backgroundColor: "inherit"
                          }
                        }
                      }}
                    >
                      <Checkbox
                        inputProps={{
                          "data-testid": "checkbox"
                        }}
                        value={option}
                        onChange={handleCheckBoxChange}
                        checked={selectedCheckBox.includes(option)}
                        sx={{ p: 0, height: "10px" }}
                        size="small"
                      />
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          color: "#212121",
                          letterSpacing: "0px",
                          pl: 1
                        }}
                      >
                        {option}
                      </Typography>
                    </MenuItem>
                    {index !== options.length - 1 && (
                      <Divider
                        sx={{
                          p: 0,
                          m: "0px",
                          ml: "30px"
                        }}
                      ></Divider>
                    )}
                  </Box>
                );
              })}
              <Box
                sx={{
                  pt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "10px",
                  color: "#0F57CB",
                  borderRadius: "10px"
                }}
              >
                <Button
                  data-testid="reset"
                  variant="outlined"
                  sx={{
                    p: "0px",
                    fontSize: "10px",
                    color: "#0F57CB",
                    borderRadius: "10px",
                    textTransform: "none"
                  }}
                  onClick={() => handleResetCheckBox()}
                >
                  {RESET}
                </Button>
                <Button
                  data-testid="filterApply"
                  variant="outlined"
                  sx={{
                    ml: 1,
                    p: "0px",
                    fontSize: "10px",
                    borderRadius: "10px",
                    textTransform: "none",
                    cursor: "pointer",
                    background:
                      "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                    color: "#FFFFFF"
                  }}
                  onClick={() => handleApplyFilter()}
                >
                  {APPLY}
                </Button>
              </Box>
            </Menu>
            {showViewAll && (
              <ThemeProvider theme={ToolTipTheme}>
                <Tooltip
                  title={TOOLTIP_RCT_VIEWALL}
                  classes={{ popper: "rc_viewall_tt" }}
                  sx={{
                    padding: "5px",
                    ".MuiTooltip-tooltip": {
                      padding: "5px"
                    },
                    "&.MuiTooltip-tooltip": {
                      padding: "5px"
                    }
                  }}
                >
                  <Link
                    to="/credits"
                    style={{
                      textDecoration: "none"
                    }}
                  >
                    <Typography
                      data-testid="viewAll"
                      sx={{
                        mr: 2,
                        fontFamily: "BukraMedium",
                        cursor: "pointer",
                        color: "#0F57CB",
                        fontSize: "15px",
                        ":hover": {
                          textDecoration: "underline"
                        }
                      }}
                    >
                      {VIEW_ALL}
                    </Typography>
                  </Link>
                </Tooltip>
              </ThemeProvider>
            )}
          </Box>
        </Toolbar>
        <Divider></Divider>

        <TableContainer
          data-testid="recentTable"
          sx={{
            ...tableScrollStyles
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead>
              <TableRow>
                {tableHeadCells.map((headCell, index) => (
                  <Fragment key={headCell.id}>
                    {index === 0
                      ? getSelectedCheckBoxStatus(headCell.id) && (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "center" : "left"}
                            padding={"normal"}
                            sortDirection={
                              orderBy === headCell.id ? order : false
                            }
                          >
                            <Typography
                              sx={{
                                color: "#606060",
                                letterSpacing: "0px",
                                fontSize: "15px",
                                fontFamily: "BukraMedium"
                              }}
                            >
                              {" "}
                              {headCell.label}
                            </Typography>
                          </TableCell>
                        )
                      : getSelectedCheckBoxStatus(headCell.id) && (
                          <TableCell key={headCell.id}>
                            <TableSortLabel
                              data-testid="sortLabel"
                              key={headCell.id}
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={() => handleRequestSort("", headCell.id)}
                              sx={{
                                color: "#606060",
                                letterSpacing: "0px",
                                fontSize: "15px",
                                fontFamily: "BukraMedium"
                              }}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        )}
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>

            {recentCreditDetails && recentCreditDetails.length > 0 && (
              <TableBody>
                {tableSorting(
                  recentCreditDetails,
                  getComparator(order, orderBy)
                )
                  .slice((page - 1) * pageSize, page * pageSize)
                  .map((row, index, arr) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={`${row.id}`}
                        style={
                          index % 2
                            ? { background: "#F7F9FC" }
                            : { background: "white" }
                        }
                      >
                        {isFilterApply.filterAppliedSelectedCheckBox.includes(
                          NINE_GRID_TYPE
                        ) && (
                          <TableCell
                            padding={"normal"}
                            component="th"
                            id={labelId}
                            align="center"
                            scope="row"
                            sx={{
                              border: "none"
                            }}
                          >
                            {row.creditType === "IN" ? (
                              <img src={transferGreen} alt="in"></img>
                            ) : (
                              <img src={reverseTransfer} alt="out"></img>
                            )}
                          </TableCell>
                        )}
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          {transferTypeLabel(row.transferType)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            border: "none"
                          }}
                        >
                          <ThemeProvider theme={toolTipTheme}>
                            <Tooltip
                              arrow
                              placement="top"
                              title={renderToolTipName(
                                row.fromUserChannelPartnerName,
                                row.fromUserCompany
                              )}
                              sx={{
                                cursor: "pointer"
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#282828",
                                  letterSpacing: "0px",
                                  fontSize: "13px",
                                  fontFamily: "BukraRegular"
                                }}
                              >
                                {row.fromUserChannelPartnerName}
                              </Typography>
                            </Tooltip>
                          </ThemeProvider>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          <ThemeProvider theme={toolTipTheme}>
                            <Tooltip
                              arrow
                              placement="top"
                              title={renderToolTipName(
                                row.toUserChannelPartnerName,
                                row.toUserCompany
                              )}
                              sx={{
                                cursor: "pointer"
                              }}
                            >
                              <Typography
                                sx={{
                                  border: "none",
                                  color: "#282828",
                                  letterSpacing: "0px",
                                  fontSize: "13px",
                                  fontFamily: "BukraRegular"
                                }}
                              >
                                {row.toUserChannelPartnerName}
                              </Typography>
                            </Tooltip>
                          </ThemeProvider>
                        </TableCell>
                        {isFilterApply.filterAppliedSelectedCheckBox.includes(
                          NINE_GRID_TRANSACTION_ID
                        ) && (
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            <ThemeProvider theme={toolTipTheme}>
                              <Tooltip
                                arrow
                                placement="top"
                                title={renderToolTipNotes(
                                  row.transferNotes || row.reverseTransferNotes
                                )}
                                sx={{
                                  cursor: "pointer"
                                }}
                              >
                                <Typography
                                  sx={{
                                    border: "none",
                                    color: "#282828",
                                    letterSpacing: "0px",
                                    fontSize: "13px",
                                    fontFamily: "BukraRegular",
                                    width: "fit-content"
                                  }}
                                >
                                  {" "}
                                  {row.transactionId}
                                </Typography>
                              </Tooltip>
                            </ThemeProvider>
                          </TableCell>
                        )}

                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          {`${row.currency} 
                          ${currencyConvertToReadable(row.creditAmount)}`}
                        </TableCell>
                        {isFilterApply.filterAppliedSelectedCheckBox.includes(
                          NINE_GRID_DATE
                        ) && (
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            {getDDMMYYYYDateFormat(row.modifiedDateTime)}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!loader && recentCreditDetails.length === 0 && (
          <Typography
            textAlign={"center"}
            sx={{
              mt: 3,
              pb: 3
            }}
          >
            {NO_DATA}
          </Typography>
        )}
        {loader && <BodyLoader />}
        {recentCreditDetails && recentCreditDetails.length > 0 && (
          <CustomPagination
            pageSize={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            page={page}
            handlePageChange={handlePageChange}
          ></CustomPagination>
        )}
      </Paper>
    </Box>
  );
};
export default RecentCreditsTransferTable;
