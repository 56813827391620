import React, { memo, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Outlet, useLocation } from "react-router-dom";
import WrapperComponent from "./../dashBoard/WrapperComponent";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import ChildChannelPartnerList from "../../components/childChannelPartnerList/ChildChannelPartnerList";
import { LIST_OF_SUB_DISTRIBUTOR } from "../../constant/Constants";
import { useSelector } from "react-redux";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { updatePageVisit } from "../../constant/Utils";

/**
 * Renders the Subdistributor Page UI component.
 * @returns {component} the Subdistributor Page UI component.
 */
const SubDistributor = () => {
  const location = useLocation();

  /**
   * @constant {string} channelPartnerCode - Gets the Channel Partner code from the store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * @constant {array<string>} - URL path.
   */
  const childPath = ["/subdistributor"];

  /**
   * Updates the Page visits info.
   */
  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  return (
    <Stack data-testid="subDistributorPage" spacing={2}>
      <Outlet />
      <WrapperComponent>
        <Stack spacing={2}>
          <QuickLinks
            childPath={childPath}
            showCards={true}
            showFilter={true}
            showRevenueCard={true}
          ></QuickLinks>
          <ChildChannelPartnerList
            isRetailer={false}
            tittle={LIST_OF_SUB_DISTRIBUTOR}
            routeToProfile="/subdistributor/subdistributorviewprofile"
          ></ChildChannelPartnerList>
        </Stack>
      </WrapperComponent>
    </Stack>
  );
};

export default memo(SubDistributor);
