import React, { memo, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Outlet, useLocation } from "react-router-dom";
import HardwareTransactionsTable from "../../components/hardwareTransactionsTable/HardwareTransactionsTable";
import RenderChildComponent from "../../components/renderChildComponent/RenderChildComponent";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { useSelector } from "react-redux";
import { updatePageVisit } from "../../constant/Utils";

/**
 * Renders the Hardware Page.
 * @returns {component} the Hardware Page component
 */
const Hardware = () => {
  const location = useLocation();
  const isHardwareAllocationandReplacement =
    location.pathname.split("/").includes("hardwareallocationandreplacement") ||
    location.pathname.split("/").includes("trackstb") ||
    false;

  /**
   * @constant {string} channelPartnerCode - Gets the logged in user Channel Partner code.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * Update the Page visit info.
   */
  useEffect(() => {
    if (location.pathname === "/hardware" && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * @constant {array<string>} childPath - URL Path.
   */
  const childPath = [
    "/hardware",
    "/hardware/hardwareallocationandreplacement",
    "/hardware/trackstb"
  ];
  return (
    <div>
      <Stack spacing={2}>
        <Outlet />
        <RenderChildComponent
          isChildPresent={isHardwareAllocationandReplacement}
        >
          <Stack spacing={2}>
            <QuickLinks
              childPath={childPath}
              showCards={false}
              showFilter={true}
              showInventoryCard={false}
            ></QuickLinks>
            <HardwareTransactionsTable></HardwareTransactionsTable>
          </Stack>
        </RenderChildComponent>
      </Stack>
    </div>
  );
};

export default memo(Hardware);
