import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { transactionNotificationTableHeader } from "../../constant/TableHeaderConstants";
import {
  currencyConvertToReadable,
  getComparator,
  getDDMMYYYYDateFormat,
  getReceiverTransactionStatus,
  getStartDateAndEndDate,
  getTransactionStatus,
  tableSorting
} from "../../constant/Utils";
import { tableScrollStyles } from "../../style/CustomStyles";
import CustomPagination from "../customComponents/customPagination/CustomPagination";

import { useSelector } from "react-redux";
import {
  ALL,
  NO_CONTENT,
  CREDIT_TRANSFER_KEY,
  PASSCODE_RESET_KEY,
  RESET_PASSCODE_SUCCESS,
  ACCOUNT_BLOCKED_KEY,
  ACCOUNT_UNBLOCKED_KEY,
  UNBLOCK_ACCOUNT_SUCCESS,
  BLOCK_ACCOUNT_SUCCESS,
  NO_DATA,
  REVERSE_CREDIT_KEY,
  CREDIT_KEY,
  DIRECT_KEY,
  REVERSE_DIRECT_KEY
} from "../../constant/Constants";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { getNotifications } from "../../services/user.services";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";

const TransactionDetailsTable = (props) => {
  const { selectedDateFilter, startDate, endDate } = props;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdDateTime");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loader, setLoader] = useState(false);
  const [toCount, setToCount] = useState(5);
  const [totalRecord, setTotalRecord] = useState(300);
  const [tableBodyDetails, setTableBodayDetails] = useState([]);
  const config = useSelector((state) => getConfigDetails(state));
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  const filteredData = useMemo(() => {
    if (selectedDateFilter === "custom_time" && startDate && endDate) {
      let customTime = {
        label: "custom_time",
        startDate: getDDMMYYYYDateFormat(startDate),
        endDate: getDDMMYYYYDateFormat(endDate)
      };
      return customTime;
    } else {
      return getStartDateAndEndDate(selectedDateFilter, selectedDateFilter);
    }
  }, [selectedDateFilter, endDate, startDate]);

  const getTransactionData = useCallback(
    (from, to) => {
      if (filteredData.endDate && filteredData.startDate && config.server) {
        setLoader(true);
        const payLoad = {
          type: ALL,
          fromCount: from,
          toCount: to,
          startDate: filteredData.startDate,
          endDate: filteredData.endDate
        };
        getNotifications(config.server, payLoad)
          .then((res) => {
            if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
              setToCount(res.data.toCount);
              setTotalRecord(res.data.totalRecords);
              setTableBodayDetails([...res.data.userNotifications]);
            } else {
              setTableBodayDetails([]);
            }
          })
          .catch(() => {
            setTableBodayDetails([]);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    },
    [filteredData.startDate, filteredData.endDate, config.server]
  );

  useEffect(() => {
    setPage(1);
    getTransactionData(0, 50);
  }, [startDate, endDate, filteredData, getTransactionData]);

  /**
   * @constant {number} totalCount - Total count.
   */
  const totalCount = Math.ceil(tableBodyDetails.length / pageSize);

  /**
   * Handle the Page Change based on the Page number Selected.
   * @function handlePageChange - Performs the Pagination change action.
   * @param {object} _event  - Pointer event
   * @param {number} value - Page number
   */
  const handlePageChange = (_event, value) => {
    setPage(value);
    if (value === totalCount && tableBodyDetails.length !== totalRecord) {
      const payLoad = {
        type: ALL,
        fromCount: toCount,
        toCount: toCount + 50,
        startDate: filteredData.startDate,
        endDate: filteredData.endDate
      };
      getNotifications(config.server, payLoad)
        .then((res) => {
          if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
            setToCount(res.data.toCount);
            setTotalRecord(res.data.totalRecords);
            setTableBodayDetails([
              ...tableBodyDetails,
              ...res.data.userNotifications
            ]);
          } else {
            setTableBodayDetails([...tableBodyDetails]);
          }
        })
        .catch(() => {
          setTableBodayDetails([]);
        });
    }
  };

  /**
   * Handle the page size change based on the Number selected
   * @handlePageSizeChange - Perforns action to change the no. of rows length per page table.
   * @param {object} event - Pointer event
   */
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  /**
   * Performs the Ascending and descending Operation on Table Items based on the Head cell selected.
   * @function handleRequestSort - Performs the Ascending or descending sorting action.
   * @param {event} _event - event object
   * @param {string} property - Sorting category
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const directAndreverserpendingNotification = (notification) => {
    const message = `${
      channelPartnerCode && channelPartnerCode === notification.fromUser
        ? `${notification.toUserChannelPartnerName} [${notification.toUserCompanyName}]`
        : `${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`
    } ${
      notification.transferType === DIRECT_KEY ||
      notification.transferType === CREDIT_KEY
        ? "has transferred "
        : "has initiated a reverse transfer of"
    } ${notification.currency} ${currencyConvertToReadable(
      notification.creditAmount
    )} ${
      getReceiverTransactionStatus(
        notification.transferType,
        notification.status
      ).completion
    }`;
    return message;
  };

  const reverserSucessandrejectNotificationMsg = (notification) => {
    const message = `Reverse transfer of ${
      notification.currency
    } ${currencyConvertToReadable(
      notification.creditAmount
    )} Credits initiated by 
    ${
      channelPartnerCode && channelPartnerCode === notification.fromUser
        ? `${notification.toUserChannelPartnerName} [${notification.toUserCompanyName}]`
        : `${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`
    }
    ${
      getReceiverTransactionStatus(
        notification.transferType,
        notification.status
      ).completion
    }`;
    return message;
  };

  const receiverMessage = (notification) => {
    return (
      <>
        {(notification.transferType === DIRECT_KEY ||
          notification.transferType === CREDIT_KEY) &&
          directAndreverserpendingNotification(notification)}
        {(notification.transferType === REVERSE_DIRECT_KEY ||
          notification.transferType === REVERSE_CREDIT_KEY) &&
          notification.status === "PENDING" &&
          directAndreverserpendingNotification(notification)}
        {(notification.transferType === REVERSE_DIRECT_KEY ||
          notification.transferType === REVERSE_CREDIT_KEY) &&
          notification.status === "SUCCESS" &&
          reverserSucessandrejectNotificationMsg(notification)}
        {(notification.transferType === REVERSE_DIRECT_KEY ||
          notification.transferType === REVERSE_CREDIT_KEY) &&
          notification.status === "REJECTED" &&
          reverserSucessandrejectNotificationMsg(notification)}
      </>
    );
  };

  const initiatorMessage = (notification) => {
    const message = `${
      notification.transferType === DIRECT_KEY ||
      notification.transferType === CREDIT_KEY
        ? "Your Credit Transfer"
        : "Your Reverse Transfer"
    }  of ${notification.currency} ${currencyConvertToReadable(
      notification.creditAmount
    )} ${
      channelPartnerCode && channelPartnerCode === notification.fromUser
        ? "to"
        : "from"
    } ${
      channelPartnerCode && channelPartnerCode === notification.fromUser
        ? `${notification.toUserChannelPartnerName} [${notification.toUserCompanyName}]`
        : `${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`
    } ${
      getTransactionStatus(notification.status, notification.transferType)
        .completion
    }`;

    return message;
  };
  const renderNotificationMsg = (notification) => {
    if (
      channelPartnerCode === notification.fromUser &&
      (notification.transferType === DIRECT_KEY ||
        notification.transferType === CREDIT_KEY)
    ) {
      return initiatorMessage(notification);
    } else if (
      channelPartnerCode === notification.toUser &&
      (notification.transferType === REVERSE_DIRECT_KEY ||
        notification.transferType === REVERSE_CREDIT_KEY)
    ) {
      return initiatorMessage(notification);
    } else {
      return receiverMessage(notification);
    }
  };

  return (
    <Box data-testid="notificationTransferTable">
      <TableContainer sx={{ ...tableScrollStyles }}>
        <Table aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow
              sx={{
                display: "flex"
              }}
            >
              {transactionNotificationTableHeader.map((headCell, index) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding={"normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    borderBottom: "none",
                    paddingLeft: { lg: 5, xs: 2 },
                    // width: "100px",
                    width: { xs: "26%", lg: "10%" }
                  }}
                >
                  {index === 0 ? (
                    <TableSortLabel
                      data-testid="sorting"
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort("", headCell.id)}
                      sx={{
                        color: "#606060",
                        letterSpacing: "0px",
                        fontSize: "15px",
                        fontFamily: "BukraMedium"
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <Typography
                      sx={{
                        color: "#606060",
                        letterSpacing: "0px",
                        fontSize: "15px",
                        fontFamily: "BukraMedium",
                        width: { xs: "40%", lg: "13%" }
                      }}
                    >
                      {headCell.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!loader &&
            tableBodyDetails &&
            tableBodyDetails.length > 0 &&
            orderBy && (
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                         rows.slice().sort(getComparator(order, orderBy)) */}
                {tableSorting(
                  tableBodyDetails,
                  getComparator(order, orderBy, true)
                )
                  .slice((page - 1) * pageSize, page * pageSize)
                  .map((item, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={`${index + 1}`}
                        style={
                          index % 2
                            ? { background: "#FFFFFF" }
                            : { background: "#F7F9FC" }
                        }
                        sx={{
                          display: "flex",
                          cursor: "default"
                        }}
                        data-testid="tableRow"
                      >
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            width: { xs: "40%", lg: "13%" },
                            paddingLeft: { lg: 5, xs: 2 }
                          }}
                        >
                          {getDDMMYYYYDateFormat(item.createdDateTime)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            paddingLeft: { lg: 5, xs: 2 },
                            width: "100%"
                          }}
                        >
                          {item.notificationType === CREDIT_TRANSFER_KEY &&
                            renderNotificationMsg(item)}
                          {item.notificationType === PASSCODE_RESET_KEY &&
                            RESET_PASSCODE_SUCCESS}
                          {item.notificationType === ACCOUNT_BLOCKED_KEY &&
                            BLOCK_ACCOUNT_SUCCESS}
                          {item.notificationType === ACCOUNT_UNBLOCKED_KEY &&
                            UNBLOCK_ACCOUNT_SUCCESS}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
        </Table>
      </TableContainer>
      {!loader && tableBodyDetails.length === 0 && (
        <Typography
          data-testid="noContent"
          textAlign={"center"}
          sx={{
            mt: 3,
            pb: 3
          }}
        >
          {NO_DATA}
        </Typography>
      )}
      {loader && <BodyLoader />}
      {tableBodyDetails && tableBodyDetails.length > 0 && (
        <CustomPagination
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          totalCount={totalCount}
          page={page}
          handlePageChange={handlePageChange}
        ></CustomPagination>
      )}
    </Box>
  );
};

export default memo(TransactionDetailsTable);
