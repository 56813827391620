import React, { useState } from "react";
import { Divider, Box, Paper, Input, Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  ENTER,
  STB_ID,
  CUSTOMER_ID,
  ITEM_NAME,
  ISSUE_DATE,
  WARRANT_EXPIRY_DATE,
  REGISTRATION_STATUS,
  BLANK,
  TOAST_ERROR,
  REGISTERED,
  SUPPLIED_DATE,
  NO_CONTENT,
  USER_ACCESS_STATUS,
  STB_HARDWARE_STATUS,
  STB_ALLOCATION_STATUS,
  REFRESH_STB_KEY_VALUE,
  REFRESH_STB,
  SOMETHING_WENT_WRONG,
  STB_MAX_LENGTH
} from "../../constant/Constants";
import { getRefreshSTBStatus, trackSTB } from "../../services/user.services";
import {
  failureToastMessage,
  getDDMMYYYYDateFormat,
  getSTBStatus,
  successToastMessage
} from "../../constant/Utils";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import SideLoader from "../loaders/sideLoader/SideLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import search from "./../../assets/images/search.svg";
/**
 * Gives the STB Dtails Data
 * @returns {component} the STB Details Component
 */
const STBDetails = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [stbDetails, setStbDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const config = useSelector((state) => getConfigDetails(state));
  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            background: " #F3F6F9 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontFamily: "BukraRegular",
            color: "#465964",
            "& ::placeholder": {
              opacity: 1
            }
          }
        }
      }
    }
  });

  /**
   * Handles the Change in STB Search input value.
   * @function handleInput - Performs action on STB input value change.
   * @param {string} value - STB search input value.
   */
  const handleInput = (value) => {
    const trimmedValue = value.trim();
    setInputValue(trimmedValue);
  };

  /**
   * Handles the Enter operation on search input.
   * @function handleEnter - Performs Search action on Pressing enter on the Search input
   * @param {object} event- STB Search input object input.
   */
  const handleEnter = (event) => {
    if (event.key === ENTER) {
      handleSTBTrack();
    }
  };

  /**
   * @function handleSTBTrack - Gets the STB Details on successfull response of Search value.
   */
  const handleSTBTrack = () => {
    setLoader(true);
    trackSTB(config.server, inputValue.trim())
      .then((res) => {
        if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
          setStbDetails(res.data);
        } else {
          setStbDetails(null);
          const message = res.data.message
            ? res.data.message
            : SOMETHING_WENT_WRONG;
          let toastDetails = {
            toastTitle: message,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_ERROR,
            toastTextclr: "#FF2A2A",
            toastBgclr: "#FDE2E2 ",
            toastBorderclr: "#FF2A2A"
          };
          dispatch(updateToastMessage(toastDetails));
        }
      })
      .catch((err) => {
        setStbDetails(null);
        let toastDetails = failureToastMessage(SOMETHING_WENT_WRONG);
        dispatch(updateToastMessage(toastDetails));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  /**
   * @function handleRefreshSTB - Gets the Stb refresh status for the hybrid STB.
   */
  const handleRefreshSTB = () => {
    let payload = {
      serialNumber: stbDetails.serialNumber,
      customerId: stbDetails.customer_id
    };
    getRefreshSTBStatus(config.server, payload)
      .then((res) => {
        if (res.status === 200) {
          let toastDetails = successToastMessage(res.data.message);
          dispatch(updateToastMessage(toastDetails));
        } else {
          const message = res.data.message
            ? res.data.message
            : SOMETHING_WENT_WRONG;
          let toastDetails = failureToastMessage(message);
          dispatch(updateToastMessage(toastDetails));
        }
      })
      .catch(() => {
        let toastDetails = failureToastMessage(SOMETHING_WENT_WRONG);
        dispatch(updateToastMessage(toastDetails));
      });
  };
  /**
   * Renders the STB Info Details.
   * @param {string} title - Title of the STB info.
   * @param {string} value - Description of the STB info.
   * @returns {component} the JSX component containing the STB Information
   */
  const renderSTBDetails = (title, value) => {
    return (
      <Box sx={{ display: "flex", mt: 0.5, mb: 0.5 }}>
        <Typography
          sx={{
            fontFamily: "BukraRegular",
            color: "#465964",
            fontSize: "15px",
            width: { md: "24%", xs: "52%", sm: "40%" },
            letterSpacing: 0,
            wordBreak: "break-word",
            pr: 0.6
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "BukraRegular",
            color: "#0F0F10",
            fontSize: "15px",
            letterSpacing: 0,
            lineBreak: "anywhere",
            width: { xs: "48%", sm: "unset" }
          }}
          data-testid={title}
        >
          {value ? value : BLANK}
        </Typography>
      </Box>
    );
  };

  /**
   * Renders Details of the Registered STBs.
   * @param {object} stb - STB details.
   * @returns {component} JSX component containing STB details for the Registered STBs
   */
  const renderRegisteredElements = (stb) => {
    return (
      <>
        {renderSTBDetails(STB_ID, stb.serialNumber)}
        {renderSTBDetails(CUSTOMER_ID, stb.customer_id)}
        {renderSTBDetails(ITEM_NAME, stb.hardwareModelName)}
        {renderSTBDetails(ISSUE_DATE, getDDMMYYYYDateFormat(stb.issueDate))}
        {renderSTBDetails(
          WARRANT_EXPIRY_DATE,
          stb.inventoryWarrantEndDate
            ? getDDMMYYYYDateFormat(stb.inventoryWarrantEndDate)
            : ""
        )}
        {renderSTBDetails(
          STB_HARDWARE_STATUS,
          stb?.qualityStatus?.toUpperCase()
        )}
        {renderSTBDetails(REGISTRATION_STATUS, REGISTERED)}
        {renderSTBDetails(USER_ACCESS_STATUS, stb.state?.toUpperCase())}
      </>
    );
  };

  /**
   * Renders Details of the Unregistered STBS
   * @param {object} stb
   * @returns {component} JSX component containing STB details for the Unregistered STBs
   */
  const renderUnregisteredElements = (stb) => {
    return (
      <>
        {renderSTBDetails(STB_ID, stb.serialNumber)}
        {renderSTBDetails(ITEM_NAME, stb.hardwareModelName)}
        {renderSTBDetails(
          SUPPLIED_DATE,
          getDDMMYYYYDateFormat(stb.suppliedDate)
        )}
        {renderSTBDetails(
          STB_HARDWARE_STATUS,
          stb?.qualityStatus?.toUpperCase()
        )}
        {renderSTBDetails(USER_ACCESS_STATUS, stb.state?.toUpperCase())}
        {renderSTBDetails(STB_ALLOCATION_STATUS, getSTBStatus(stb.status))}
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper
        data-testid="STBDetails"
        sx={{
          p: 1,
          elevation: 10
        }}
      >
        <Box
          sx={{
            display: "flex",
            pt: 0.5,
            pb: 0.5,
            justifyContent: "space-between",
            alignItems: { lg: "center" },
            flexDirection: { lg: "row", xs: "column" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "row", xs: "column" },
              ml: 2
            }}
          >
            <Typography
              data-testid="stbId"
              sx={{
                textAlign: "center",
                fontFamily: "BukraMedium",
                color: "#464E5F",
                fontSize: "16px",
                display: "flex",
                width: "60px",
                letterSpacing: 0,
                mr: 1.2,
                alignItems: "center"
              }}
            >
              {STB_ID}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                backgroundColor: "#f3f6f9",
                mt: { sm: 0, xs: 1 }
              }}
            >
              <img
                data-testid="customerImage"
                src={search}
                onClick={() => handleSTBTrack()}
                alt="search"
                style={{
                  height: "11px",
                  paddingLeft: "20px",
                  cursor: "pointer"
                }}
              ></img>
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                data-testid="input"
                required
                placeholder="Enter STB ID"
                name="stbid"
                onChange={(e) => handleInput(e.target.value)}
                onKeyDownCapture={(e) => handleEnter(e)}
                disableUnderline={true}
                sx={{
                  width: { sm: "286px", xs: "100%" },
                  pl: 1.5
                }}
                inputProps={{
                  "data-testid": "stbinput",
                  maxLength: STB_MAX_LENGTH
                }}
              />
            </Box>
            {loader && <SideLoader size={28} />}
          </Box>
          {stbDetails &&
            stbDetails.hardwareType &&
            stbDetails.customer_id &&
            stbDetails.hardwareType === REFRESH_STB_KEY_VALUE && (
              <Button
                data-testid="refreshSTB"
                variant="outlined"
                onClick={() => handleRefreshSTB()}
                sx={{
                  background:
                    "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                  color: "#ffffff",
                  borderRadius: "20px",
                  letterSpacing: "0px",
                  fontFamily: "BukraMedium",
                  fontSize: "15px",
                  width: "140px",
                  textTransform: "none",
                  height: "40px",
                  mt: { sm: 0, xs: 1 }
                }}
              >
                {REFRESH_STB}
              </Button>
            )}
        </Box>

        {stbDetails && (
          <>
            <Divider
              sx={{
                pt: 1
              }}
            />
            <Box
              data-testid="descriptionBox"
              sx={{
                background: "#FBF8FF 0% 0% no-repeat padding-box",
                display: "flex",
                flexDirection: "column",
                borderRadius: 2,
                ml: { sm: 4, xs: 1 },
                mr: { sm: 4, xs: 0 },
                mt: 1.5,
                mb: 0.5,
                pl: { sm: 3, xs: 1 },
                py: 1
              }}
            >
              {stbDetails.customer_id === null || stbDetails.customer_id === ""
                ? renderUnregisteredElements(stbDetails)
                : renderRegisteredElements(stbDetails)}
            </Box>
          </>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default STBDetails;
