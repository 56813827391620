import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InventoryStock from "./../../assets/images/inventory_stock.svg";
import { getInventoryStockCount } from "../../services/user.services";
import Tooltip from "@mui/material/Tooltip";
import { TOOLTIP_INVENTORY_STOCK } from "../../constant/Constants";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the UI for the Invetory Stock card in the Quick Links tab
 * @returns {component} the UI component for the Inventory Stock Card.
 */
const InventoryStockCard = (props) => {
  const [inventoryStockDetails, setInventoryStockDetails] = useState("");
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * UseEffect hook to get the Incventory Stock count on load.
   */
  useEffect(() => {
    if (config.server) {
      getInventoryStockCount(config.server).then((res) => {
        if (res && res.status === 200) {
          setInventoryStockDetails(res.data);
        } else {
          setInventoryStockDetails("");
        }
      });
    }
  }, [config.server]);
  return (
    <>
      {inventoryStockDetails && (
        <Box
          data-testid="inventoryStockCard"
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #7070704D",
            borderRadius: "8px",
            height: { sm: "80px", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: 1,
              pl: 1.5,
              pb: 1.2
            }}
          >
            <Box
              sx={{
                display: "flex"
              }}
            >
              <img src={InventoryStock} alt="sub" height={"45px"}></img>
              <ThemeProvider theme={ToolTipThemeWithBG}>
                <Tooltip title={TOOLTIP_INVENTORY_STOCK} placement="top" arrow>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      ml: 1
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        pt: 0.5,
                        color: "#202020",
                        fontFamily: "BukraMedium",
                        fontSize: "13px",
                        letterSpacing: "0px"
                      }}
                    >
                      {inventoryStockDetails.totalInventory}
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        pt: "5px",
                        color: "#282828",
                        fontFamily: "BukraRegular",
                        fontSize: "12px",
                        letterSpacing: "0px"
                      }}
                    >
                      {inventoryStockDetails.inventory_label}
                    </Box>
                  </Box>
                </Tooltip>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(InventoryStockCard);
