import { Box } from "@mui/material";
import React from "react";
import signInBackground from "./../../assets/images/signin_background.png";
const PageLayoutThree = ({ children }) => {
  return (
    <Box
      data-testid="mainSection"
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${signInBackground})`,
        backgroundSize: { xs: "inherit", lg: "100% auto" },
        backgroundRepeat: "no-repeat"
      }}
    >
      {children}
    </Box>
  );
};

export default PageLayoutThree;
