import React from "react";
import { Outlet } from "react-router-dom";
import MainSection from "../mainSection/MainSection";

/**
 * Renders the Layout for the Public routes.
 * @returns {component} the pageLyout for the Public routes.
 */
const PageLayoutTwo = () => {
  return (
    <>
      <MainSection data-testid="mainSection">
        <Outlet></Outlet>
      </MainSection>
    </>
  );
};

export default PageLayoutTwo;
