import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pushNotificationEnable: false
};

/**
 * Saves the Toast Messages info into the store.
 */
const pushNotificationSlice = createSlice({
  name: "pushNotification",
  initialState,
  reducers: {
    updatePushNotificationEnableStatus: (state, { payload }) => {
      return { ...state, pushNotificationEnable: payload };
    }
  }
});

/**
 * Fetches the Store message details from the store.
 * @param {store} state - Store State.
 * @returns {object} - Toast message details
 */
export const getPushNotificationEnableStatus = (state) => {
  return state.pushNotification.pushNotificationEnable;
};
export const { updatePushNotificationEnableStatus } =
  pushNotificationSlice.actions;
export default pushNotificationSlice.reducer;
