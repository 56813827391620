import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MultiRangeDatePicker from "./../../components/multiRangeDatePicker/MultiRangeDatePicker";
import filter from "./../../assets/images/filter.svg";
import done from "./../../assets/images/done.svg";
import filter_active from "./../../assets/images/filter_active.svg";
import {
  ALL_TIME,
  CUSTOM,
  RETAILER_USER_ROLE_TYPE,
  THIS_MONTH,
  THIS_YEAR,
  TO_DAY
} from "./../../constant/Constants";
import {
  getUserRoleType,
  getUserScope
} from "../../stateManagement/reducers/LoginSlice";
import CreditSummaryCard from "../creditSummaryCard/CreditSummaryCard";
import InventoryStockCard from "../inventoryStockCard/InventoryStockCard";
import { getStartDateAndEndDate, getToday } from "../../constant/Utils";
import {
  getFilteredDate,
  getShowDateFilter
} from "../../stateManagement/reducers/DateFilterSlice";
import RevenueCountCard from "../revenueCountCard/RevenueCountCard";
import MostVisitedPages from "../mostVisitedPages/MostVisitedPages";
import CreditsTransferredCard from "../creditsTransferredCard/CreditsTransferredCard";
import SummaryReport from "../summaryReport/SummaryReport";
import { Grid } from "@mui/material";

/**
 * Renders the Quick links card UI.
 * @param {object} props  - Props passed to Quick links component.
 * @returns {component} the Quick links UI with it's content based on the user type.
 */
const QuickLinks = (props) => {
  const [filterTime, setFilterTime] = useState(THIS_MONTH);
  const [displayFilterTime, setdisplayFilterTime] = useState(THIS_MONTH);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [showActiveFilterIcon, setShowActiveFilterIcon] = useState(false);
  const dispatch = useDispatch();
  const fetchedUserScope = useSelector((state) => getUserScope(state));
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const displayDateFilter = useSelector((state) => getShowDateFilter(state));

  const {
    showInventoryCard,
    showRevenueCard,
    showCards,
    showFilter,
    showCreditCard,
    childPath
  } = props;

  /**
   * useEffect hook to get the Start date and end date.
   */
  useEffect(() => {
    dispatch(getFilteredDate(getStartDateAndEndDate()));
    return () => {
      dispatch(getFilteredDate(getStartDateAndEndDate()));
    };
  }, [fetchedUserScope, dispatch]);
  /**
   * @constant {array<object>} timeFilterArray - Static filter array.
   */
  const timeFilterArray = [
    { key: "all_time", label: ALL_TIME },
    { key: "this_year", label: THIS_YEAR },
    { key: "this_month", label: THIS_MONTH },
    { key: "to_day", label: TO_DAY },
    { key: "custom_time", label: CUSTOM }
  ];

  /**
   * @constant {boolean} - Boolean true status to display the filter operation.
   */
  const open = Boolean(anchorEl);

  /**
   * Handles the click operation.
   * @function handleClickListItem - Handles the click on the Filter icon in Quicklinks.
   * @param {object} event - event object.
   */
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the Operation on Filter clik in Quick links
   * @function handleMenuItemClick - Performs action to filter the Quick links content based on the Filter option selected.
   * @param {object} event - event object
   * @param {number} index  - index of the time filter title.
   * @param {string} value - time filter label.
   */
  const handleMenuItemClick = (event, index, value) => {
    setSelectedIndex(index);
    setFilterTime(event.target.innerText);
    getStartDateAndEndDate(value);
    setShowActiveFilterIcon(true);
    dispatch(
      getFilteredDate(getStartDateAndEndDate(event.target.innerText, value))
    );
    setdisplayFilterTime(event.target.innerText);
    setAnchorEl(null);
  };

  /**
   * Handle the close operation of Time Filter.
   * @function handleClose - Performs the close action to the time filter pop-up.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles the Operation to filter the date for custom date filter
   * @function datafromtheDatePicker - Performs action to set the date range for custom date filter.
   */
  const datafromtheDatePicker = useCallback(
    (value) => {
      const startDateValue = value[0] ? `${value[0].format()}` : "";
      const endDateValue = value[1] ? `${value[1].format()}` : "";
      const endDate = endDateValue ? endDateValue : getToday();
      let date = {
        label: "Custom",
        startDate: startDateValue,
        endDate: endDate
      };
      dispatch(getFilteredDate(date));
    },
    [dispatch]
  );

  const isCreditsTransferredCardVisiable =
    showCreditCard &&
    fetchUserRoleType &&
    fetchUserRoleType !== RETAILER_USER_ROLE_TYPE
      ? true
      : false;
  return (
    <Paper
      data-testid="quicklinks"
      elevation={3}
      sx={{
        mt: 1,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: "8px"
        // minHeight: "155px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { sm: "space-between", xs: "center" },
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          minHeight: { sm: "50px", xs: "0px" },
          pl: 2,
          pr: 1,
          py: { xs: 2, lg: 0 },
          borderRadius: "8px",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <MostVisitedPages childPath={childPath}></MostVisitedPages>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" }, //
            alignItems: { xs: "center" }
          }}
        >
          {fetchUserRoleType !== RETAILER_USER_ROLE_TYPE && (
            <SummaryReport></SummaryReport>
          )}
          {fetchUserRoleType !== RETAILER_USER_ROLE_TYPE &&
            showFilter &&
            displayDateFilter && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ ml: 1, my: 1 }}
              ></Divider>
            )}
          {showFilter && displayDateFilter && (
            <Box
              sx={{
                pr: 1,
                ml: { xs: 2, sm: 0 },
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row"
                },
                width: "100%"
              }}
            >
              {filterTime === "Custom" && (
                <MultiRangeDatePicker
                  dateFromParent={datafromtheDatePicker}
                ></MultiRangeDatePicker>
              )}
              <Box
                sx={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  fontSize: "13px",
                  color: "#0F57CB",
                  border: "1px solid #0F57CB",
                  borderRadius: "4px",
                  display: "flex",
                  minWidth: "140px",
                  width: { lg: "140px", xs: "100%" },
                  height: "30px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontFamily: "BukraMedium",
                  pl: 1,
                  pr: 1,
                  ml: { sm: 1 },
                  my: { xs: 1, sm: 0 }
                }}
                onClick={handleClickListItem}
                data-testid="clickListItem"
              >
                <Typography
                  sx={{
                    font: "inherit"
                  }}
                >
                  {filterTime}
                </Typography>
                <Box sx={{ cursor: "pointer" }}>
                  {showActiveFilterIcon ? (
                    <img src={filter_active} alt="filter"></img>
                  ) : (
                    <img src={filter} alt="filterActive"></img>
                  )}
                </Box>
              </Box>

              <Menu
                data-testid="filter"
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: "205px",
                    overflow: "visible",
                    backgroundColor: "#f6f6f6",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,

                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "#f6f6f6",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {timeFilterArray.map((option, index) => (
                  <MenuItem
                    data-testid="menuClick"
                    key={option.key}
                    value={option.key}
                    selected={index === selectedIndex}
                    onClick={(event) =>
                      handleMenuItemClick(event, index, option.key)
                    }
                    divider
                    sx={{
                      fontFamily: "BukraRegular",
                      "&.Mui-selected": {
                        fontFamily: "BukraMedium",
                        backgroundColor: "inherit",
                        "&.Mui-selected:focus": {
                          backgroundColor: "inherit"
                        }
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        "&:hover": {
                          transition: "0.15s ease-in-out",
                          transform: "scale(1.05 ,1.05)"
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "BukraRegular",
                          color: "#212121"
                        }}
                      >
                        {option.label}
                      </Typography>{" "}
                      {index === selectedIndex ? (
                        <img src={done} alt="done"></img>
                      ) : (
                        ""
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>
      </Box>
      {showCards && (
        <Box>
          <Divider></Divider>
          <Grid container rowSpacing={1} columnSpacing={1.5} sx={{ p: 2 }}>
            {showCreditCard && (
              <Grid
                item
                sx={{
                  width: { xs: "100%", md: "auto" }
                }}
              >
                <CreditSummaryCard />
              </Grid>
            )}
            {isCreditsTransferredCardVisiable && (
              <Grid
                item
                sx={{
                  width: { xs: "100%", md: "auto" }
                }}
              >
                <CreditsTransferredCard displayFilterTime={displayFilterTime} />
              </Grid>
            )}
            {showRevenueCard && (
              <Grid
                item
                sx={{
                  width: { xs: "100%", md: "auto" }
                }}
              >
                <RevenueCountCard displayFilterTime={displayFilterTime} />
              </Grid>
            )}

            {showInventoryCard && (
              <Grid
                item
                sx={{
                  width: { xs: "100%", md: "auto" }
                }}
              >
                <InventoryStockCard displayFilterTime={displayFilterTime} />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

export default memo(QuickLinks);
