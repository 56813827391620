import React, { useEffect, useState } from "react";
import App from "../../App";
import axios from "axios";
import { updateBuildServer } from "../../stateManagement/reducers/ConfigSlice";
import { useDispatch } from "react-redux";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import PageLayoutThree from "../pageLayoutThree/PageLayoutThree";
import { Box, Paper, Typography } from "@mui/material";
import { APP_CONFIG_MISS_MSG } from "../../constant/Constants";

const AppConfig = () => {
  const [serverLoaded, setServerLoaded] = useState("loading");
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/config_b2bui.json")
      .then((res) => {
        dispatch(updateBuildServer(res.data));
        setServerLoaded("ready");
      })
      .catch(() => {
        setServerLoaded("error");
      });
  }, [dispatch]);
  return (
    <div>
      {serverLoaded === "loading" && (
        <PageLayoutThree>
          <Box>
            <BodyLoader></BodyLoader>
            <Typography
              sx={{
                color: "#ffffff"
              }}
            >
              Loading...
            </Typography>
          </Box>
        </PageLayoutThree>
      )}
      {serverLoaded === "ready" && <App></App>}
      {serverLoaded === "error" && (
        <PageLayoutThree>
          <Box>
            <Paper
              elevation={3}
              sx={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: "5px"
              }}
            >
              {" "}
              <Typography
                sx={{
                  color: "#0F0F10",
                  fontFamily: "BukraRegular",
                  p: 2
                }}
              >
                {APP_CONFIG_MISS_MSG}
              </Typography>
            </Paper>
          </Box>
        </PageLayoutThree>
      )}
    </div>
  );
};

export default AppConfig;
