import { Box } from "@mui/material";
import React from "react";

/**
 * Renders the respective Tab Content
 * @param {object} props - Props passed to the Tab Panel component.
 * @returns the Tab UI Component.
 */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      data-testid="tabPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
