import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import ProfileCard from "../profileCard/ProfileCard";
import subDistributorProfile from "./../../assets/images/sub_distributor_profile.svg";
import { getParentDetails } from "../../services/user.services";
import {
  MASTER_DISTRIBUTOR_PROFILE,
  MASTER_DISTRIBUTOR,
  NO_DATA_FOUND
} from "../../constant/Constants";
import { textWithComma } from "../../constant/Utils";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { useSelector } from "react-redux";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the UI component for the Master Distributor Profile.
 * @returns {component} the UI component for the Master Distributor Profile.
 */
const MasterDistributorProfile = () => {
  const [masterDistributorDetails, setMasterDistributorDetails] = useState("");
  const [showNoData, setShowNoData] = useState(false);
  const [loader, setLoader] = useState(false);
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Preload the Master Distributor details
   * */
  useEffect(() => {
    setLoader(true);
    if (config.server) {
      getParentDetails(config.server)
        .then((res) => {
          if (res && res.status === 200) {
            setMasterDistributorDetails(res.data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        })
        .finally(() => setLoader(false));
    }
  }, [config.server]);

  /**
   * Extract the required values from the Master distributor object
   */
  const {
    channelPartnerMobileNumber,
    channelPartnerEmail,
    channelPartnerAddressInformation,
    channelPartnerCity,
    channelPartnerCode,
    channelPartnerFirstName,
    channelPartnerLastName,
    channelPartnerCountry,
    companyDetails,
    channelPartnerArea,
    postalCode
  } = masterDistributorDetails;
  const address = `${textWithComma(
    channelPartnerAddressInformation
  )}${textWithComma(channelPartnerArea)} ${textWithComma(
    channelPartnerCity
  )}${textWithComma(postalCode)}${channelPartnerCountry}`;
  const firstName = channelPartnerFirstName ? channelPartnerFirstName : "";
  const lastName = channelPartnerLastName ? channelPartnerLastName : "";
  const name = `${firstName} ${lastName}`;
  const company = companyDetails && companyDetails.companyName;

  /**
   * @property {object} profileDetails - Master Distributer Formatted profile details.
   * Extracted Profile Details Object with Formatted Values
   */
  let profileDetails = {
    profileTittle: MASTER_DISTRIBUTOR_PROFILE,
    profileName: `${MASTER_DISTRIBUTOR} : ${channelPartnerCode} [${name}]`,
    profileNumber: channelPartnerMobileNumber,
    profileEmail: channelPartnerEmail,
    profileAddress: address,
    profileCompany: company,
    image: subDistributorProfile
  };
  return (
    <>
      {!loader && masterDistributorDetails && (
        <ProfileCard profileDetails={profileDetails}></ProfileCard>
      )}
      {loader && <BodyLoader />}
      {!loader && showNoData && (
        <Typography
          data-testid="noData"
          sx={{
            textAlign: "center",
            height: "600px"
          }}
        >
          {NO_DATA_FOUND}
        </Typography>
      )}
    </>
  );
};

export default MasterDistributorProfile;
