import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    "Content-type": "application/json"
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && sessionStorage.getItem("userToken")) {
      sessionStorage.removeItem("userToken");
      sessionStorage.removeItem("fcmToken");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
