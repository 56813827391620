import React, { useState, useEffect } from "react";
import ProfileCard from "../profileCard/ProfileCard";
import subDistributorProfile from "./../../assets/images/sub_distributor_profile.svg";
import { getParentDetails } from "../../services/user.services";
import { Typography } from "@mui/material";
import {
  SUB_DISTRIBUTOR_PROFILE,
  SUB_DISTRIBUTOR
} from "./../../constant/Constants";
import { textWithComma } from "../../constant/Utils";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { useSelector } from "react-redux";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the Parent Subdistributor profile card UI from retailer's login.
 * @returns {component} the Parent Subdistributor Profile card UI from retailer's login.
 */
const SubDistributorProfile = () => {
  const [subDistributorDetails, setSubDistributorDetails] = useState("");
  const [showNodata, setShowNoData] = useState(false);
  const [loader, setLoader] = useState(false);
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Preloads the parent Subdistributor Details
   */
  useEffect(() => {
    setLoader(true);
    if (config.server) {
      getParentDetails(config.server)
        .then((res) => {
          if (res && res.status === 200) {
            setSubDistributorDetails(res.data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [config.server]);

  /**
   * Extract the required data from the subdistributor details object.
   */
  const {
    channelPartnerMobileNumber,
    channelPartnerEmail,
    channelPartnerAddressInformation,
    channelPartnerCity,
    channelPartnerCode,
    channelPartnerFirstName,
    channelPartnerLastName,
    channelPartnerCountry,
    companyDetails,
    channelPartnerArea,
    postalCode
  } = subDistributorDetails;

  const address = `${textWithComma(
    channelPartnerAddressInformation
  )}${textWithComma(channelPartnerArea)}${textWithComma(
    channelPartnerCity
  )} ${textWithComma(postalCode)}${channelPartnerCountry}`;
  const firstName = channelPartnerFirstName ? channelPartnerFirstName : "";
  const lastName = channelPartnerLastName ? channelPartnerLastName : "";
  const name = `${firstName} ${lastName}`;
  const company = companyDetails && companyDetails.companyName;

  /**
   *@constant {object} profileDetails Create formatted details Object for the UI component.
   */
  let profileDetails = {
    profileTittle: SUB_DISTRIBUTOR_PROFILE,
    profileName: `${SUB_DISTRIBUTOR}: ${channelPartnerCode} [${name}]`,
    profileNumber: channelPartnerMobileNumber,
    profileEmail: channelPartnerEmail,
    profileAddress: address,
    profileCompany: company,
    image: subDistributorProfile
  };
  return (
    <>
      {!loader && showNodata && (
        <Typography data-testid="noData">No data</Typography>
      )}
      {!loader && subDistributorDetails && (
        <ProfileCard profileDetails={profileDetails}></ProfileCard>
      )}
      {loader && <BodyLoader />}
    </>
  );
};

export default SubDistributorProfile;
