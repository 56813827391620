import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { rootReducer } from "./rootReducer";

const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== "production"
});

/**
 * Store configuration
 */
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(logger),
  devTools: process.env.NODE_ENV !== "production"
});
