import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  VIEW_ALL,
  TOP,
  BASED_ON_REVENUE,
  MD_USER_ROLE_TYPE,
  LIST_OF
} from "./../../constant/Constants";
import RevenueCard from "./../revenueCard/RevenueCard";
import { getChildChannelPartnerRevenueList } from "../../services/user.services";
import { useSelector } from "react-redux";
import { getUserRoleType } from "../../stateManagement/reducers/LoginSlice";
import { Link } from "react-router-dom";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import { ThemeProvider } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { ToolTipTheme } from "../../constant/themes/ToolTipTheme";
import "./DashboardRevenueCard.scss";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
/**
 * Renders the Revenue card UI in Dashboard.
 * @returns {component}The Revenue cards UI component in Dashboard
 */
const DashboardRevenueCard = () => {
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const [revenueCardDetails, setRevenueCardDetails] = useState([]);
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * @constant {number} INITIAL_CARD_COUNT - Initial Revenue cards count.
   */
  const INITIAL_CARD_COUNT = 3;

  /**
   * @constant {string} fetchUserRoleType - Display the user Role type.
   */
  const label =
    fetchUserRoleType === MD_USER_ROLE_TYPE ? "Sub Distributors" : "Retailers";

  /**
   * @constant {string} routeto - Route link.
   */
  const routeto =
    fetchUserRoleType === MD_USER_ROLE_TYPE ? "/subdistributor" : "/retailers";

  /**
   * @const {object} selectedDateFilter - Gets the Date range object from the store.
   */
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );

  const { startDate, endDate } = selectedDateFilter;

  useEffect(() => {
    if (startDate && endDate && config.server) {
      getChildChannelPartnerRevenueList(config.server, startDate, endDate).then(
        (res) => {
          if (res && res.status === 200) {
            const revenueList = res.data.childRevenueList.slice(
              0,
              INITIAL_CARD_COUNT
            );
            setRevenueCardDetails(revenueList);
          }
        }
      );
    }
  }, [startDate, endDate, config.server]);

  return (
    <>
      {revenueCardDetails && revenueCardDetails.length > 0 && (
        <Box data-testid="dashboardRevenueCard" sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Typography
              data-testid="TopSD"
              sx={{
                fontFamily: "BukraMedium",
                fontSize: "16px",
                color: "#464E5F",
                letterSpacing: "0px"
              }}
            >{`${TOP} 3 ${label} (${BASED_ON_REVENUE})`}</Typography>

            <Link
              to={routeto}
              style={{
                textDecoration: "none"
              }}
            >
              <ThemeProvider theme={ToolTipTheme}>
                <Tooltip
                  title={`${LIST_OF} ${label}`}
                  classes={{ tooltip: "revenue_viewall_tt" }}
                >
                  <Typography
                    data-testid="viewAll"
                    sx={{
                      pr: { md: 3, xs: 1 },
                      fontFamily: "BukraMedium",
                      cursor: "pointer",
                      color: "#0F57CB",
                      fontSize: "15px",
                      width: "100px",
                      textAlign: "end",
                      ":hover": {
                        textDecoration: "underline"
                      }
                    }}
                  >
                    {VIEW_ALL}
                  </Typography>
                </Tooltip>
              </ThemeProvider>
            </Link>
          </Box>
          <RevenueCard revenueCardDetails={revenueCardDetails}></RevenueCard>
        </Box>
      )}
    </>
  );
};

export default memo(DashboardRevenueCard);
