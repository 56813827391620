import React, { useState } from "react";
import {
  Typography,
  Input,
  Button,
  Box,
  InputAdornment,
  IconButton
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import closeicon from "./../../assets/images/close.png";
import {
  CONFIRM_BUTTON,
  VERIFICATION,
  ENTER_PASSCODE,
  PASSCODE_IS_INCORRECT,
  ATTEMPTS_LEFT,
  PASSCODE_LIMIT,
  SOMETHING_WENT_WRONG
} from "../../constant/Constants";

import userService from "../../services/user.services";
import "./CustomPasscodeVerification.scss";
import { onlyNumber } from "../../constant/Utils";
import eyeClose from "./../../assets/images/eye_close.svg";
import eyeOpen from "./../../assets/images/eye_open.svg";
import { useSelector } from "react-redux";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the Passcode Input Popup UI and Handle its verification
 * @param {object} props - Prop elements passed to Passcode Dialog box component.
 * @returns {component} the Passcode verification popup UI
 */
const CustomPasscodeVerification = ({
  showPasscodeModal = false,
  handleClosePasscodeVerification,
  handleOpenNoAttempts,
  handleConfirmTransfer
}) => {
  const [passCode, setPassCode] = useState("");
  const [remainingAttempt, setRemainingAttempt] = useState(null);
  const [otherErrors, setOtherErrors] = useState(null);
  const [showPasscode, setShowPasscode] = useState(false);
  const config = useSelector((state) => getConfigDetails(state));
  /**
   * Handle the Popup Close.
   * @function handleClose - Close the Passcode Verification Popup Modal
   */
  const handleClose = () => {
    handleClosePasscodeVerification();
  };

  /**
   * Handle the Passcode Verification Process.
   * @function handlePassCodeVerification - Performs the Passcode verification operation.
   */
  const handlePassCodeVerification = () => {
    if (passCode !== null) {
      let passCodeData = { passcode: passCode };
      userService
        .getPasscodeVerification(config.server, passCodeData)
        .then((res) => {
          clearAllErrorMessages();
          if (res && res.status === 200) {
            handleConfirmTransfer(passCode);
            handleClose();
          } else {
            if (res && res.data && res.data.remianingAttempt) {
              setRemainingAttempt(res.data.remianingAttempt);
            } else if (res && res.data && res.data.remianingAttempt === 0) {
              handleClose();
              res.data.parentEmail &&
                handleOpenNoAttempts(res.data.parentEmail);
            } else {
              const message =
                res && res.data ? res.data.message : SOMETHING_WENT_WRONG;
              setOtherErrors(message);
            }
          }
        });
    }
    setPassCode("");
  };

  /**
   * Clears all error messages.
   * @function clearAllErrorMessages - Clear all the Error Messages while passcode Verification.
   */
  const clearAllErrorMessages = () => {
    setRemainingAttempt(null);
    setOtherErrors(null);
  };

  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDF4F6",
            color: "#0F0F10",
            border: "1px solid #E8E8E8",
            borderRadius: "3px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "13px",
            "&.Mui-focused": {
              border: "1px solid #0F57CB"
            },
            "& ::placeholder": {
              color: "#202020",
              opacity: 0.7
            }
          }
        }
      }
    }
  });

  /**
   * Handle the Passcode input operation.
   * @function handlePassCode - Performs action on the change of passcode input.
   * @param {object} e - Passcode input Pointer event.
   * Handle the Passcode input field.
   */
  const handlePassCode = (e) => {
    const { value } = e.target;
    if (onlyNumber(value)) {
      setPassCode(value.slice(0, PASSCODE_LIMIT));
      clearAllErrorMessages();
    }
  };

  /**
   * Handles the Passcode Verification submit button's disable or Enable view.
   * @function isBtnDisabled - Checks whether to Enable or disable the Passcode Verification submit button.
   * @returns {boolean} - true or false.
   */
  const isBtnDisabled = () => {
    let disabled = true;
    if (passCode.length === PASSCODE_LIMIT) {
      disabled = false;
    }
    return disabled;
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showPasscodeModal}
        // onClose={handleClose}
        data-testid="customPasscodeVerification"
        sx={{
          ".MuiPaper-root": {
            margin: 0
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { sm: "450px", xs: "100%" },
            minWidth: "360px",
            pt: 2,
            pb: 1
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%"
            }}
          >
            <Typography
              data-testid="verificationHeading"
              sx={{
                textAlign: "center",
                fontFamily: "BukraMedium",
                color: "#485061",
                fontSize: "16px",
                p: 0.5,
                flex: 1
              }}
            >
              {VERIFICATION}
            </Typography>

            <img
              data-testid="closeIcon"
              src={closeicon}
              alt="X"
              onClick={handleClose}
              height="16px"
              style={{
                cursor: "pointer",
                paddingRight: "16px"
              }}
            ></img>
          </Box>
          <Box
            sx={{
              width: "80%",
              // px: 8.5,
              py: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "Center"
            }}
          >
            <Typography
              data-testid="enterPasscode"
              sx={{
                fontSize: "14px",
                width: "100%",
                color: "#8f8f8f",
                fontFamily: "BukraRegular",
                letterSpacing: "0px"
              }}
            >
              {ENTER_PASSCODE}{" "}
              <span className="asterisk small-asterisk"> *</span>
            </Typography>

            <Input
              autoComplete="off"
              onDrop={(e) => {
                e.preventDefault();
                return false;
              }}
              onChange={(e) => handlePassCode(e)}
              value={passCode}
              id="passcode"
              fullWidth
              type={"text"}
              required
              pattern="^-?[0-9]\d*\.?\d*$"
              name="passcode"
              placeholder="Enter Passcode"
              disableUnderline={true}
              sx={{
                mt: 1.5,
                mb: 1,
                pl: 1,
                border: remainingAttempt ? "1px solid red" : "",
                fontSize: "14px",
                fontFamily: "BukraRegular",
                height: "44px"
              }}
              endAdornment={
                <InputAdornment position="end" sx={{ p: 1, ml: "4px" }}>
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setShowPasscode(!showPasscode)}
                    data-testid="showPasscode"
                  >
                    {showPasscode ? (
                      <img src={eyeOpen} alt="e"></img>
                    ) : (
                      <img src={eyeClose} alt="e"></img>
                    )}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                "data-testid": "enterPasscodeInput",
                inputMode: "numeric", // for number keyboard
                style: {
                  textSecurity: `${
                    !showPasscode && passCode ? "disc" : "unset"
                  } `, // part of hack described above. this disc mimics the password *** appearance
                  WebkitTextSecurity: `${
                    !showPasscode && passCode ? "disc" : "unset"
                  } ` // same hack
                }
              }}
            />
            <span className="red error-info">
              {remainingAttempt &&
                `${PASSCODE_IS_INCORRECT}, ${remainingAttempt} ${ATTEMPTS_LEFT}`}
              {otherErrors ? otherErrors : ""}
            </span>
            <Button
              data-testid="confirmButton"
              variant="outlined"
              disabled={isBtnDisabled()}
              sx={{
                background:
                  "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                color: "#FFFFFF",
                borderRadius: "20px",
                letterSpacing: "0px",
                fontFamily: "BukraMedium",
                fontSize: "15px",
                width: "200px",
                height: "40px",
                textTransform: "none",
                "&.Mui-disabled": {
                  background:
                    "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                  color: "#FFFFFF",
                  opacity: 0.5
                }
              }}
              onClick={() => handlePassCodeVerification()}
            >
              {CONFIRM_BUTTON}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default CustomPasscodeVerification;
