import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  server: "preprod"
};

/**
 * Saves the Toast Messages info into the store.
 */
const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateBuildServer: (state, { payload }) => {
      return { ...state, ...payload };
    }
  }
});

/**
 * Fetches the Store message details from the store.
 * @param {store} state - Store State.
 * @returns {object} - Toast message details
 */
export const getConfigDetails = (state) => {
  return state.config;
};
export const { updateBuildServer } = configSlice.actions;
export default configSlice.reducer;
