import React, { memo, useEffect } from "react";
import { Stack } from "@mui/material";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import STBDetails from "../../components/stbDetails/STBDetails";
import { updatePageVisit } from "../../constant/Utils";
import { useLocation } from "react-router-dom";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";

/**
 * Renders the Track STB Page.
 * @returns {component} Track STB page UI component
 */
const TrackSTBPage = () => {
  /**
   * @const {array<string>} childPath - URL Paths.
   */
  const childPath = [
    "/trackstb",
    "/hardware/trackstb",
    "/hardware",
    "/hardware/hardwareallocationandreplacement"
  ];

  /**
   * @constant {boolean} showCard - set value to false.
   */
  const showCard = false;
  const location = useLocation();

  /**
   * @constant {string} channelPartnerCode - Gets the channel partner code from the store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * Updates the Page visits info.
   */
  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  return (
    <div>
      <Stack data-testid="trackSTB" spacing={2}>
        <Box
          sx={{
            display: {
              xs: "none",
              lg: "block"
            }
          }}
        >
          <QuickLinks
            childPath={childPath}
            showCards={showCard}
            showFilter={showCard}
            showInventoryCard={showCard}
          ></QuickLinks>
        </Box>
        <STBDetails></STBDetails>
      </Stack>
    </div>
  );
};

export default memo(TrackSTBPage);
