import React, { memo, useEffect, useMemo, useState } from "react";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  ADDRESS,
  AREA,
  CITY,
  COMPANY,
  COUNTRY,
  EMAIL,
  FIRST,
  LAST,
  MOBILE,
  NAME,
  POSTAL_CODE,
  IS_REQUIRED,
  UPDATE,
  TOAST_ERROR,
  UPDATE_PROFILE_SUCCESSFULLY,
  EDIT_PROFILE,
  SELECT_CITY,
  MD_USER_ROLE_TYPE,
  SUB_DISTRIBUTOR,
  RETAILER,
  SOMETHING_WENT_WRONG,
  MAX_POSTAL_CODE_LENGTH,
  MAX_LENGTH_50
} from "../../constant/Constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  TextField
} from "@mui/material";
import {
  getChildDetails,
  getCityList,
  updateChildProfileDetails
} from "../../services/user.services";
import "./EditProfile.scss";
import { useNavigate, useParams } from "react-router-dom";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import { blockInvalidChar, onlyNumber } from "../../constant/Utils";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import { getUserRoleType } from "../../stateManagement/reducers/LoginSlice";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#202020",
          fontSize: "14px",
          letterSpacing: "0px",
          height: "44px",
          border: "none",
          ":focus": {
            background: "#EDF4F6 0% 0% no-repeat padding-box",
            color: "#202020",
            opacity: 1
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px",
          p: 0
        }
      }
    }
  }
});

/**
 * Renders the Edit Profile UI.
 * @returns {component} the UI to Edit the Profile of Channel Partner.
 */

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const params = useParams();

  const [userCity, setUserCity] = useState("");
  const [userArea, setUserArea] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userPostalCode, setUserPostalCode] = useState("");
  const [cityList, setCityList] = useState([]);
  const [userCityErr, setUserCityErr] = useState("");

  const [userDetails, setUserDetails] = useState({});
  const [userCountryCode, setUserCountryCode] = useState("");
  const partnerCode = useMemo(() => params, [params]);
  const config = useSelector((state) => getConfigDetails(state));
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (partnerCode.id && config.server) {
      getChildDetails(config.server, partnerCode.id).then((res) => {
        if (res && res.status === 200) {
          setUserDetails(res.data);
          if (res.data) {
            const channelPartnerCity = res.data.channelPartnerCity
              ? res.data.channelPartnerCity
              : "";
            const channelPartnerCountry = res.data.channelPartnerCountry
              ? res.data.channelPartnerCountry
              : "";
            const channelPartnerAddressInformation =
              res.data.channelPartnerAddressInformation !== undefined
                ? res.data.channelPartnerAddressInformation.toString()
                : "";
            const channelPartnerArea =
              res.data.channelPartnerArea !== undefined
                ? res.data.channelPartnerArea.toString()
                : "";
            const channelPartnerPostalCode =
              res.data.postalCode !== undefined
                ? res.data.postalCode.toString()
                : "";

            setUserCity(channelPartnerCity);
            setUserCountryCode(channelPartnerCountry);
            setUserArea(channelPartnerArea);
            setUserAddress(channelPartnerAddressInformation);
            setUserPostalCode(channelPartnerPostalCode);
          }
        }
      });
    }
  }, [partnerCode.id, config.server]);

  useEffect(() => {
    if (userDetails.channelPartnerCountry && config.server) {
      getCityList(config.server, userDetails.channelPartnerCountry).then(
        (res) => {
          if (res.status === 200) {
            if (res.data[0].cityList.length !== 0) {
              setCityList(res.data[0].cityList);
              setUserCityErr("");
            } else {
              setCityList([]);
              setUserCity("");
              setUserCityErr(`${CITY} ${IS_REQUIRED}`);
            }
          }
        }
      );
    }
  }, [userDetails.channelPartnerCountry, config.server]);

  const {
    channelPartnerEmail,
    companyDetails,
    channelPartnerFirstName,
    channelPartnerLastName,
    channelPartnerMobileNumber,
    channelPartnerCode,
    channelPartnerUsername,
    companyName,
    isKycDone
  } = userDetails;

  /**
   * Handles the Input Change in the city field.
   * @function handleChangeUserCity - Performs action on user City change.
   * @param {object} e - event object of Select option.
   */
  const handleChangeUserCity = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setUserCityErr(`${e.target.name} ${IS_REQUIRED}`);
    }
    setUserCity(e.target.value);
    setUserCityErr("");
  };

  /**
   * handle the Input Change in the area field.
   * @function handleChangeUserArea - Performs action on User Area input change.
   * @param {object} e - event object of Input field.
   */
  const handleChangeUserArea = (e) => {
    setUserArea(e.target.value.trimStart());
  };

  /**
   * handle the Input Change in the address field.
   * @function handleChangeUserAddress - Performs action on user Address input change.
   * @param {object} e - event object of Input field.
   */
  const handleChangeUserAddress = (e) => {
    setUserAddress(e.target.value.trimStart());
  };

  /**
   * handle the Input Change in the postal code field.
   * @function handleChangeUserPostalCode - Performs action on User Postal code input change.
   * @param {object} e - event object of Input field.
   */
  const handleChangeUserPostalCode = (e) => {
    if (onlyNumber(e.target.value)) {
      setUserPostalCode(e.target.value);
    }
  };

  /**
   * Handle the Profile Update on Click of the Update Button.
   * @function handleUpdateProfile - Perform the Action to Updates the profile on Submission of form.
   */
  const handleUpdateProfile = () => {
    setLoader(true);
    /**
     * @constant {object} payload - payload to submit the form for Edit Profile.
     */
    const payload = {
      channelPartnerFirstName: channelPartnerFirstName,
      channelPartnerLastName: channelPartnerLastName,
      channelPartnerCode: channelPartnerCode,
      channelPartnerMobileNumber: channelPartnerMobileNumber,
      channelPartnerEmail: channelPartnerEmail,
      channelPartnerUsername: channelPartnerUsername,
      channelPartnerCity: userCity,
      channelPartnerArea: userArea,
      channelPartnerAddressInformation: userAddress,
      postalCode: userPostalCode,
      channelPartnerDescription: "",
      companyName: companyName,
      isKycDone: isKycDone
    };

    updateChildProfileDetails(config.server, channelPartnerCode, payload)
      .then((res) => {
        const updateChildProfileName =
          fetchUserRoleType === MD_USER_ROLE_TYPE ? SUB_DISTRIBUTOR : RETAILER;
        if (res && res.status === 200) {
          let toastDetails = {
            toastTitle: `${updateChildProfileName}'s ${UPDATE_PROFILE_SUCCESSFULLY}`,
            isToastOpen: true
          };
          dispatch(updateToastMessage(toastDetails));
          navigate(-1);
        } else {
          const message =
            res && res.data && res.data.message
              ? res.data.message
              : SOMETHING_WENT_WRONG;
          let toastDetails = {
            toastTitle: message,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_ERROR,
            toastTextclr: "#FF2A2A",
            toastBgclr: "#FDE2E2 ",
            toastBorderclr: "#FF2A2A"
          };
          dispatch(updateToastMessage(toastDetails));
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const isButtonDisable = () => {
    return (
      userCity === "" ||
      (userCity === userDetails.channelPartnerCity &&
        userAddress ===
          (userDetails.channelPartnerAddressInformation !== undefined
            ? userDetails.channelPartnerAddressInformation.toString()
            : "") &&
        userArea ===
          (userDetails.channelPartnerArea !== undefined
            ? userDetails.channelPartnerArea.toString()
            : "") &&
        userPostalCode ===
          (userDetails.postalCode !== undefined
            ? userDetails.postalCode.toString()
            : ""))
    );
  };
  /**
   * Renders the Label UI for the Forms input field.
   * @function renderDefaultUI - renders the default label and input field UI.
   * @param {string} label - label for the Input field
   * @param {string} name  - name attribute for the Input field.
   * @returns the UI for the labels for form Input field
   */
  const renderDefaultUI = (label, name) => {
    return (
      <Box className="container">
        <label className="label">{label}</label>
        <Box className="nameWrapper">
          <Typography className="name">{name}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Paper
      elevation={3}
      data-testid="editProfile"
      className="editProfile_Wrapper"
      sx={{ p: { xs: 1, lg: 0 } }}
    >
      {loader && <OverlayLoader />}
      <Toolbar sx={{ px: { lg: 3.8, xs: 0 } }}>
        <Typography
          sx={{
            fontFamily: "BukraMedium",
            color: " #282828",
            fontSize: "15px",
            letterSpacing: "0px"
          }}
        >
          {EDIT_PROFILE}
        </Typography>
      </Toolbar>
      <Divider></Divider>
      <Box
        sx={{
          mx: { lg: 12 },
          mt: { lg: 2.7, xs: 2 },
          pb: 2,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <ThemeProvider theme={inputTheme}>
          <Stack spacing={3}>
            {companyDetails &&
              renderDefaultUI(`${COMPANY} ${NAME}`, companyDetails.companyName)}
            <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
              {channelPartnerFirstName &&
                renderDefaultUI(`${FIRST} ${NAME}`, channelPartnerFirstName)}

              {channelPartnerLastName &&
                renderDefaultUI(`${LAST} ${NAME}`, channelPartnerLastName)}
            </Stack>

            {channelPartnerEmail &&
              renderDefaultUI(`${EMAIL}`, channelPartnerEmail)}

            <Box className="container">
              <label className="label">{MOBILE}</label>
              <Box className="mobileWraper">
                <Box className="countryCode">{userCountryCode}</Box>{" "}
                <Box className="mobieNumber">{channelPartnerMobileNumber} </Box>
              </Box>
            </Box>
            {renderDefaultUI(`${COUNTRY}`, country.countryName)}
            <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
              <label htmlFor="demo-select-small" className="inputLabel">
                {CITY} <span className="asterisk">*</span>
              </label>

              <Select
                inputProps={{ "data-testid": "citySelect" }}
                labelId="demo-select-small"
                id="demo-select-small"
                value={userCity}
                onChange={(e) => handleChangeUserCity(e)}
                displayEmpty
                name={CITY}
                renderValue={(cityValue) =>
                  cityValue ? (
                    <Typography
                      data-testid="citySelected"
                      sx={{
                        letterSpacingpacing: "0px",
                        color: "#202020",
                        fontSize: "14px",
                        fontFamily: "BukraRegular",
                        opacity: 1
                      }}
                    >
                      {cityValue}
                    </Typography>
                  ) : (
                    <Typography
                      data-testid="citySelected"
                      sx={{
                        letterSpacingpacing: "0px",
                        color: "#202020",
                        fontSize: "14px",
                        fontFamily: "BukraRegular",
                        opacity: 0.5
                      }}
                    >
                      {SELECT_CITY}
                    </Typography>
                  )
                }
                sx={{
                  backgroundColor: "#edf4f6",
                  "&.MuiPaper-root": {
                    backgroundColor: "green"
                  }
                }}
                MenuProps={{
                  autoFocus: false,
                  PaperProps: {
                    style: {
                      maxHeight: 190,
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      marginTop: "3px"
                    }
                  }
                }}
              >
                {cityList &&
                  cityList.map((city, index) => {
                    return (
                      <MenuItem
                        key={city.cityName}
                        value={city.cityName}
                        sx={{
                          ...muiMenuItemStyles
                        }}
                      >
                        {city.cityName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {userCityErr && (
                <FormHelperText
                  sx={{ color: "#DE0909" }}
                  className="errorFormHelperText"
                >
                  {userCityErr}{" "}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl size="small" fullWidth>
              <label className="inputLabel">{AREA}</label>
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{ "data-testid": "areaInput", maxLength: MAX_LENGTH_50 }}
                size="medium"
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                onChange={(e) => handleChangeUserArea(e)}
                value={userArea}
              ></TextField>
            </FormControl>
            <FormControl size="small" fullWidth>
              <label className="inputLabel">{ADDRESS}</label>
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "address",
                  maxLength: MAX_LENGTH_50
                }}
                size="medium"
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                value={userAddress}
                onChange={(e) => handleChangeUserAddress(e)}
              ></TextField>
            </FormControl>
            <FormControl size="small" fullWidth>
              <label className="inputLabel">{POSTAL_CODE}</label>
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "postalCode",
                  maxLength: MAX_POSTAL_CODE_LENGTH
                }}
                size="medium"
                variant="outlined"
                fullWidth
                onKeyDown={blockInvalidChar}
                type={"number"}
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                value={userPostalCode}
                onChange={(e) => handleChangeUserPostalCode(e)}
              ></TextField>
            </FormControl>
          </Stack>
        </ThemeProvider>
        <Stack
          spacing={2}
          direction={{ xs: "column", lg: "row" }}
          justifyContent="flex-end"
          sx={{ pt: 4, pb: 2 }}
        >
          <Button
            variant="outlined"
            onClick={handleUpdateProfile}
            disabled={isButtonDisable()}
            sx={{
              borderRadius: "20px",
              fontSize: "15px",
              textTransform: "none",
              fontFamily: "BukraRegular",
              letterSpacing: 0,
              cursor: "pointer",
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              "&.Mui-disabled": {
                background:
                  "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                color: "#ffffff",
                opacity: 0.5
              },
              minWidth: "180px"
            }}
            data-testid="updateProfile"
          >
            {UPDATE}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default memo(EditProfile);
