import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Divider } from "@mui/material";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import searchIcon from "./../../assets/images/search.svg";
import {
  TRACKSTB,
  HARDWARE_TRANSACTIONS,
  NO_CONTENT,
  NO_DATA,
  STB_ALLOCATION_REPLACEMENT_LABEL
} from "../../constant/Constants";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import {
  getComparator,
  getDDMMYYYYDateFormat,
  getSearchItems,
  tableSorting
} from "../../constant/Utils";
import { getHardwareTransactions } from "../../services/user.services";
import CustomPagination from "../customComponents/customPagination/CustomPagination";
import { useSelector } from "react-redux";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import { muiMenuItemStyles, tableScrollStyles } from "../../style/CustomStyles";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * @constant {array<object>} searchOption Search option Obejcts with name and value.
 */
const searchOption = [
  {
    value: "hardwareModel",
    label: "Model"
  },
  {
    value: "customerId",
    label: "Customer ID"
  },
  {
    value: "serialNumber",
    label: "STB ID"
  }
];

/**
 * @constant {array<object>} headCells Tableheads for the Hardware Transaction Table.
 */
const headCells = [
  {
    id: "hardwareMake",
    label: "Manufacture"
  },
  {
    id: "hardwareModel",
    label: "Model"
  },
  {
    id: "customerId",
    label: "Customer ID"
  },
  {
    id: "serialNumber",
    label: "STB ID"
  },
  {
    id: "action",
    label: "Type"
  },
  {
    id: "updatedDate",
    label: "Date"
  }
];

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});

/**
 * Renders the Hardware transaction table UI.
 * @returns the UI for the Hardware Transation Table
 */
const HardwareTransactionsTable = () => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updatedDate");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [hardwareTransactions, setHardwareTransactions] = useState([]);
  const [hardwareTransactionsFixed, setHardwareTransactionsFixed] = useState(
    []
  );
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectSearchItem, setSelectedSearchItem] = useState("hardwareModel");
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Handle the Sorting based on the Selected table head.
   * @function handleRequestSort - Handle the Acsending and Descending sorting operation.
   * @param {object} _event - Pointer event.
   * @param {string} property - Selected Sorting table header.
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      selectedDateFilter.endDate &&
      selectedDateFilter.startDate &&
      config.server
    ) {
      setLoader(true);
      getHardwareTransactions(
        config.server,
        selectedDateFilter.startDate,
        selectedDateFilter.endDate
      )
        .then((res) => {
          if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
            setHardwareTransactionsFixed(res.data);
            setHardwareTransactions(res.data);
          } else {
            setHardwareTransactionsFixed([]);
            setHardwareTransactions([]);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [selectedDateFilter.endDate, selectedDateFilter.startDate, config.server]);

  useEffect(() => {
    let filteredRows = hardwareTransactionsFixed;
    if (search) {
      filteredRows = getSearchItems(
        hardwareTransactionsFixed,
        selectSearchItem,
        search
      );
    }
    setPage(1);
    setHardwareTransactions(filteredRows);
  }, [hardwareTransactionsFixed, selectSearchItem, search]);

  /**
   * @constant {number} totalCount - Total count.
   */
  const totalCount = Math.ceil(hardwareTransactions.length / pageSize);

  /**
   * Handle the Page Change based on the Page number Selected.
   * @function handlePageChange - Performs the Pagination change action.
   * @param {object} _event  - Pointer event
   * @param {number} value - Page number
   */
  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  /**
   * Handle the page size change based on the Number selected
   * @handlePageSizeChange - Perforns action to change the no. of rows length per page table.
   * @param {object} event - Pointer event
   */
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  /**
   * Handle the Search Operation in the table.
   * @function handlerequestSearch - Performs action on Search input value change.
   * @param {object} event - event object.
   */
  const handlerequestSearch = (event) => {
    setSearch(event.target.value.trim());
  };

  /**
   * Handles the Search option category change
   * @function handleSelectChange - Perform action to Change the Search select category changes.
   * @param {object} event - event object
   */
  const handleSelectChange = (event) => {
    setSelectedSearchItem(event.target.value);
  };

  return (
    <Box data-testid="hardwareTransactionsTable" sx={{ width: "100%", mt: 2 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            pt: { xs: 1, lg: 0 },
            display: "flex",
            alignItems: { lg: "center", xs: "flex-start" },
            justifyContent: "space-between",
            minHeight: { sm: "84px" },
            flexDirection: { xs: "column", md: "row" },
            width: { sm: "auto" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: { sm: "center", xs: "flex-start" },
              flexDirection: { xs: "column", sm: "row" },
              pt: { xs: 1, lg: 0 },
              width: { xs: "100%", sm: "auto" }
            }}
          >
            <Typography
              variant="h6"
              id="tableTitle"
              sx={{
                fontFamily: "BukraMedium",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#464E5F",
                mr: 2,
                ml: { xs: 0, md: 1 }
              }}
            >
              {HARDWARE_TRANSACTIONS}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                backgroundColor: "#f3f6f9",
                height: "40px",
                my: { xs: 1, lg: 0 },
                width: { xs: "100%", sm: "auto" }
              }}
            >
              <img
                src={searchIcon}
                alt="search"
                style={{
                  height: "11px",
                  paddingLeft: "20px"
                }}
              ></img>
              <ThemeProvider theme={inputTheme}>
                <InputBase
                  autoComplete="off"
                  value={search}
                  sx={{ ml: 1, flex: 1, width: { sm: "160px", xs: "100%" } }}
                  placeholder="Search"
                  onChange={(event) => handlerequestSearch(event)}
                  inputProps={{ "data-testid": "searchInput" }}
                />
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Select
                  inputProps={{
                    "data-testid": "selectSearchInner"
                  }}
                  id="filledoption"
                  sx={{
                    backgroundColor: "#7B7D801A",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    pr: 2,
                    pl: 2,
                    height: "40px",
                    fontSize: "13px",
                    fontFamily: "BukraRegular",
                    ".MuiSelect-icon": {
                      px: 1
                    }
                  }}
                  value={selectSearchItem}
                  onChange={handleSelectChange}
                  variant="standard"
                  disableUnderline={true}
                >
                  {searchOption.map((option) => (
                    <MenuItem
                      sx={{
                        ...muiMenuItemStyles
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </ThemeProvider>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { sm: "center" },
              cursor: "pointer",
              flexDirection: { xs: "column", sm: "row" },
              pt: { xs: 1, lg: 0 },
              width: { xs: "100%", sm: "auto" }
            }}
          >
            <Link
              to="trackstb"
              style={{
                textDecoration: "none"
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  background:
                    "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                  color: "#FFFFFF",
                  borderRadius: "20px",
                  letterSpacing: "0px",
                  fontFamily: "BukraMedium",
                  fontSize: { lg: "14px", xs: "13px" },
                  width: { sm: "140px", xs: "100%" },
                  textTransform: "none",
                  height: "40px",
                  mr: 1
                }}
              >
                {TRACKSTB}
              </Button>
            </Link>
            <Link
              to="hardwareallocationandreplacement"
              style={{
                textDecoration: "none"
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  background:
                    "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                  color: "#FFFFFF",
                  borderRadius: "20px",
                  letterSpacing: "0px",
                  fontFamily: "BukraMedium",
                  fontSize: { lg: "14px", xs: "13px" },
                  width: { sm: "fit-content", xs: "100%" },
                  textTransform: "none",
                  height: "40px",
                  mr: 1,
                  px: 2.5,
                  my: { xs: 1, lg: 0 }
                }}
              >
                {STB_ALLOCATION_REPLACEMENT_LABEL}
              </Button>
            </Link>
          </Box>
        </Toolbar>
        <Divider></Divider>
        <TableContainer sx={{ ...tableScrollStyles }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
            data-testid="hardwareTable"
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={headCell.id}
                    align={"left"}
                    padding={"normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ pl: index === 0 ? { xs: 2, md: "50px" } : 2 }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort("", headCell.id)}
                      sx={{
                        color: "#606060",
                        letterSpacing: "0px",
                        fontSize: "15px",
                        fontFamily: "BukraMedium"
                      }}
                      data-testid="sortBtn"
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!loader &&
              hardwareTransactions &&
              hardwareTransactions.length > 0 && (
                <TableBody data-testid="tableBody">
                  {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                 hardwareTransactions.slice().sort(getComparator(order, orderBy)) */}
                  {tableSorting(
                    hardwareTransactions,
                    getComparator(order, orderBy)
                  )
                    .slice((page - 1) * pageSize, page * pageSize)
                    .map((row, index) => {
                      const label = `hardware_${index}`;
                      return (
                        <TableRow
                          key={`${label}`}
                          style={
                            index % 2
                              ? { background: "#F7F9FC" }
                              : { background: "white" }
                          }
                        >
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular",
                              pl: { xs: 2, md: "50px" }
                            }}
                          >
                            {row.hardwareMake}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            {row.hardwareModel}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            {row.customerId}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            {row.serialNumber ? row.serialNumber : "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            {row.action ? row.action : "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              border: "none",
                              color: "#282828",
                              letterSpacing: "0px",
                              fontSize: "13px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            {getDDMMYYYYDateFormat(row.updatedDate)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        {!loader && hardwareTransactions.length === 0 && (
          <Typography
            textAlign={"center"}
            sx={{
              mt: 3,
              pb: 3
            }}
          >
            {NO_DATA}
          </Typography>
        )}

        {loader && <BodyLoader />}
        {hardwareTransactions && hardwareTransactions.length > 0 && (
          <CustomPagination
            pageSize={pageSize}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            page={page}
            handlePageChange={handlePageChange}
          ></CustomPagination>
        )}
      </Paper>
    </Box>
  );
};
export default HardwareTransactionsTable;
