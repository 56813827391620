import React, { memo, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import RenderChildComponent from "../../components/renderChildComponent/RenderChildComponent";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import RecentSubscriptionSalesTable from "../../components/recentSubscriptionSalesTable/RecentSubscriptionSalesTable";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { updatePageVisit } from "../../constant/Utils";
import { useSelector } from "react-redux";

/**
 * Renders the Customer Page component.
 * @returns {component} the Customer registration page component.
 */
const Customer = () => {
  const location = useLocation();
  const isCustomerPage =
    location.pathname.split("/").includes("customerregistration") ||
    location.pathname.split("/").includes("subscribenow") ||
    false;

  /**
   * @constant {string} channelPartnerCode - Gets the Channel Partner code from the store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * Updates the Page visits info.
   */
  useEffect(() => {
    if (location.pathname === "/customer" && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * @constant {array<string>} childPath - URL paths.
   */
  const childPath = [
    "/customer",
    "/customer/customerregistration",
    "/customer/subscribenow"
  ];
  return (
    <Stack data-testid="customer" spacing={2}>
      <Outlet></Outlet>
      <RenderChildComponent isChildPresent={isCustomerPage}>
        <Stack spacing={2}>
          <QuickLinks
            showCards={false}
            showFilter={true}
            childPath={childPath}
            showInventoryCard={false}
          ></QuickLinks>
          <RecentSubscriptionSalesTable></RecentSubscriptionSalesTable>
        </Stack>
      </RenderChildComponent>
    </Stack>
  );
};

export default memo(Customer);
