import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  convertMStoS,
  onlyNumber,
  validateEmail,
  validateRegisterMobileNumber,
  generateCaptcha,
  updatePageVisit,
  successToastMessage,
  failureToastMessage,
  whiteSpaceInWord,
  validFirstName,
  validLastName,
  handleMouseDownOnAndornment
} from "./../../constant/Utils";
import Reset from "./../../assets/images/noun_reset.svg";
import searchIcon from "../../assets/images/search.svg";

import {
  ADDRESS,
  ALPHABETS_ARABIC,
  AREA,
  CAPTCHA,
  CAPTCHA_ERROR_MSG,
  CITY,
  CITY_REQUIRED,
  COUNTRY,
  CUSTOMER_NOT_PRESENT_IN_THE_SYSTEM,
  CUSTOMER_PRESENT_IN_THE_SYSTEM,
  CUSTOMER_REGISTERED_SUCCESSFULLY,
  CUSTOMER_REGISTRATION,
  DATE_OF_BIRTH,
  EMAIL,
  ENTER,
  FIRST,
  FIRSTNAME_REQUIRED,
  GENDER,
  GENDERDATA,
  LAST,
  LASTNAME_REQUIRED,
  LAST_NAME_ERROR,
  MAX_LENGTH_50,
  MAX_POSTAL_CODE_LENGTH,
  MOBILE,
  NAME,
  NAME_MAX_LENGTH,
  NATIONALITY,
  NO_CONTENT,
  NO_RESULTS_FOUND,
  NUMBER,
  OTP_GENERATED_SUCCESS_DESCRIPTION,
  POSTAL_CODE,
  RESEND_CODE_TIME,
  SELECT,
  SELECT_CITY,
  SELECT_GENDER,
  SOMETHING_WENT_WRONG,
  STB_ALLOCATION_REPLACEMENT_LABEL,
  STB_CUSTOMER_PLACEHOLDER,
  STB_MAX_LENGTH,
  STB_REQUIRED,
  SUBMIT,
  VERIFY_MOBILE_NUMBER
} from "../../constant/Constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { nationalityList } from "./../../constant/NationalityList";
import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  ListSubheader,
  Stack,
  TextField
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./CustomerRegistration.scss";
import DatePicker from "react-multi-date-picker";
import calendar_month_black from "./../../assets/images/calendar_month_black.svg";
import {
  CustomerRegistrationWithStb,
  getCityList,
  validateCustomerWithOTP,
  verifyCustomerWithOTP
} from "../../services/user.services";
import { useDispatch, useSelector } from "react-redux";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  buttonStyles,
  crMuiInputBase,
  muiMenuItemStyles
} from "../../style/CustomStyles";
import VerificationBox from "../verificationBox/VerificationBox";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...crMuiInputBase
        }
      }
    }
  }
});
/**
 * Renders the Customer Registration UI.
 * @returns {component} the Customer Registration UI component.
 */
const CustomerRegistration = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateTo = useNavigate();
  const countryDetails = useSelector((state) => state.countryDetails);
  const [cityListInfo, setcityList] = useState([]);
  const [stbValue, setStbValue] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [shortToken, setshortToken] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [MobileValue, setMobileValue] = useState("");
  const [areaValue, setAreaValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [genderValue, setGendeValue] = useState("");
  const [dob, setDOB] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [customerEmailError, setCustomerEmailError] = useState("");
  const [mobileNumberError, setmobileNumberError] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [customerExist, setcustomerExist] = useState("");
  const [stbErrorMessage, setstbErrorMessage] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [cityError, setCityError] = useState("");
  const [errorInfo, setErrorInfo] = useState("");
  const [showErrorPop, setShowErrorPop] = useState(false);
  const [expireTime, setExpireTime] = useState();
  const [mobilePayload, setMobilePayload] = useState("");
  const datePickerRef = useRef();
  const [captchaCode, setCaptchaCode] = useState(generateCaptcha(6));
  const [loader, setLoader] = useState(false);
  const [nationalityValue, setNationalityValue] = useState("");
  const [confirmSTB, setConfirmSTB] = useState("");
  const [confirmSTBErrorMessage, setConfirmSTBErrorMessage] = useState("");
  const [searchNationalityText, setSearchNationalityText] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const [transformOrigin, setTransformOrigin] = useState({
    vertical: "top",
    horizontal: "center"
  });
  const [anchorOriginNationality, setAnchorOriginNationality] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const [transformOriginNationality, setTransformOriginNationality] = useState({
    vertical: "top",
    horizontal: "center"
  });
  const [anchorOriginGender, setAnchorOriginGender] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const [transformOriginGender, setTransformOriginGender] = useState({
    vertical: "top",
    horizontal: "center"
  });

  const cityRef = useRef(null);
  const nationalityRef = useRef(null);
  const genderRef = useRef(null);

  const handleToggle = (event) => {
    if (cityRef.current) {
      const selectBox = cityRef.current.getBoundingClientRect();
      const fitsBelow = selectBox.bottom + 200 <= window.innerHeight; // assuming 200px as dropdown height
      if (fitsBelow) {
        setAnchorOrigin({ vertical: "bottom", horizontal: "center" });
        setTransformOrigin({ vertical: "top", horizontal: "center" });
      } else {
        setAnchorOrigin({ vertical: "top", horizontal: "center" });
        setTransformOrigin({ vertical: "bottom", horizontal: "center" });
      }
    }
  };
  const handleToggleNationality = (event) => {
    if (nationalityRef.current) {
      const selectBox = nationalityRef.current.getBoundingClientRect();
      const fitsBelow = selectBox.bottom + 200 <= window.innerHeight; // assuming 250px as dropdown height
      if (fitsBelow) {
        setAnchorOriginNationality({
          vertical: "bottom",
          horizontal: "center"
        });
        setTransformOriginNationality({
          vertical: "top",
          horizontal: "center"
        });
      } else {
        setAnchorOriginNationality({ vertical: "top", horizontal: "center" });
        setTransformOriginNationality({
          vertical: "bottom",
          horizontal: "center"
        });
      }
    }
  };
  const handleToggleGender = (event) => {
    if (genderRef.current) {
      const selectBox = genderRef.current.getBoundingClientRect();
      const fitsBelow = selectBox.bottom + 100 <= window.innerHeight; // assuming 100px as dropdown height
      if (fitsBelow) {
        setAnchorOriginGender({ vertical: "bottom", horizontal: "center" });
        setTransformOriginGender({ vertical: "top", horizontal: "center" });
      } else {
        setAnchorOriginGender({ vertical: "top", horizontal: "center" });
        setTransformOriginGender({ vertical: "bottom", horizontal: "center" });
      }
    }
  };

  /**
   * Channel Partner Code
   * @constant
   * @type {string}
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  const config = useSelector((state) => getConfigDetails(state));

  useEffect(() => {
    const newCaptcha = generateCaptcha(6);
    setCaptchaCode(newCaptcha);
    return () => {
      setCaptchaCode("");
    };
  }, []);

  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * Handles the Select Option Change for the gender field value.
   * @function handleGenderChange - Performs Action on change in the Select option field of Gender
   * @param {object} e - Select option change for the gender field Pointer event.
   */
  const handleGenderChange = (e) => {
    setGendeValue(e.target.value);
  };

  /**
   * Handles the Security code reset.
   * @function resetSecurityCode - Reset the captcha code on click of reset Icon or wrong form submission.
   */
  const resetSecurityCode = () => {
    const SECURITY_CODE_LENGTH = Math.floor(Math.random() * (6 - 4 + 1)) + 4;
    const newCaptcha = generateCaptcha(SECURITY_CODE_LENGTH);
    setCaptchaCode(newCaptcha);
    setCaptchaValue("");
  };

  /**
   * Handles the date change Operation
   * @function handleDateofBirthChange - Performs action on the change of date of birth.
   * @param {date} date
   */
  const handleDateofBirthChange = (date) => {
    setDOB(date.format());
  };

  /**
   * Handles the STB change Operation.
   * @function handleChangeSTB - performs the Action on STB No. input field change.
   * @param {object} e - STB No input pointer event.
   */
  const handleChangeSTB = (e) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    setStbValue(trimmedValue);
    if (trimmedValue && confirmSTB && confirmSTB !== trimmedValue) {
      setConfirmSTBErrorMessage(`Confirm STB mismatch`);
    } else {
      setConfirmSTBErrorMessage("");
    }
  };
  /**
   * Handles the STB change Operation.
   * @function handleConfirmSTBChange - performs the Action on STB No. input field change.
   * @param {object} e - STB No input pointer event.
   */
  const handleConfirmSTBChange = (e) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    setConfirmSTB(trimmedValue);
    if (trimmedValue && stbValue && stbValue !== trimmedValue) {
      setConfirmSTBErrorMessage(`Confirm STB mismatch`);
    } else {
      setConfirmSTBErrorMessage("");
    }
  };

  /**
   * Handle the First name change field.
   * @function handleFirstNameChange - Performs action on First name input change
   * @param {object} e - First name input field pointer event
   */
  const handleFirstNameChange = (e) => {
    const { value } = e.target;
    const nameValue = value.replace(/\s+/g, " ").trimStart();
    setFirstNameValue(nameValue);
    setFirstNameError("");
  };

  /**
   * Handle the Last name change field.
   * @function handleLastNameChange - Performs action on Last name input change
   * @param {object} e - Last name input field pointer event
   */
  const handleLastNameChange = (e) => {
    const { value } = e.target;
    const nameValue = value.replace(/\s+/g, " ").trimStart();
    setLastNameValue(nameValue);
    setLastNameError("");
  };

  /**
   * Handle the Email change field.
   * @function handleEmailChange - Performs action on Email input change
   * @param {object} e - Email input field pointer event
   */
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmailValue(value);
    setCustomerEmailError("");
  };

  /**
   * Handle the City change field.
   * @function handleCityChange - Performs action on City input change
   * @param {object} e - City input field pointer event
   */
  const handleCityChange = (e) => {
    const { value } = e.target;
    setCityValue(value);
    setCityError("");
  };

  /**
   * Handles the Toast Message display
   * @function showToastCallback - Performs action to hide toast message.
   */
  const showToastCallback = () => {
    setShowErrorPop(false);
  };

  /**
   * Handles the display of the toast Message.
   * @function showPopup - Display the Popup element.
   * @param {string} message - Error nessage
   */
  const showPopup = (message) => {
    setShowErrorPop(true);
    setErrorInfo(message ? message : SOMETHING_WENT_WRONG);
  };

  /**
   * Handles the close operation of Dialog box.
   * @function closeDialog - Close the Dialog Box Popup.
   */
  const closeDialog = () => {
    setShowErrorPop(false);
    setOpenPopup(false);
  };

  /**
   * Handles the Mobile Change and Enable or disable fields based on the Mobile Input Change.
   * @function handleMobileChange - Performs action on Mobile no. input change.
   * @param {object} e - Mobile number input field pointer event.
   */
  const handleMobileChange = (e) => {
    const { value } = e.target;
    if (onlyNumber(value)) {
      if (value.length <= countryDetails?.maxSizeOfMobileNumber) {
        if (validateRegisterMobileNumber(value, countryDetails) !== "") {
          countryDetails &&
            setmobileNumberError(
              validateRegisterMobileNumber(value, countryDetails)
            );
          setcustomerExist("");
          setOtpVerified(false);
          setCaptchaValue("");
          setMobileValue(value);
        } else {
          setmobileNumberError("");
          setMobileValue(value);
        }
      }
    }
  };

  /**
   * Handles the Area Input field change
   * @function handleAreaChange - Performs action on the Area input field change.
   * @param {object} e - Area Input field Pointer event.
   */
  const handleAreaChange = (e) => {
    const { value } = e.target;
    setAreaValue(value);
  };

  /**
   * Handles the Adddress Input field change
   * @function handleAdddressChange - Performs action on the Adddress input field change.
   * @param {object} e - Address Input field Pointer event.
   */
  const handleAddressChange = (e) => {
    const { value } = e.target;
    setAddressValue(value);
  };
  /**
   * Handles the Nationality Input field change
   * @function handleChangeNationality - Performs action on the Nationality input field change.
   * @param {object} e - Nationality Input field Pointer event.
   */
  const handleChangeNationality = (e) => {
    const { value } = e.target;
    setNationalityValue(value);
  };

  /**
   * Handles the Postalcode Input field change
   * @function handlePostalcodeChange - Performs action on the Postalcode input field change.
   * @param {object} e - Postalcode Input field Pointer event.
   */

  const handlePostalcodeChange = (e) => {
    const { value } = e.target;
    if (onlyNumber(value)) {
      setPostalCodeValue(value);
    }
  };

  /**
   * Handles the Captcha Input field change
   * @function handleCaptchaChange - Performs action on the Captcha input field change.
   * @param {object} e - Captcha Input field Pointer event.
   */
  const handleCaptchaChange = (e) => {
    const { value } = e.target;
    setCaptchaValue(value);
  };

  /**
   * Handles the Email Validation
   * @function handleEmailValidation - Performs the Email Validation operation
   */
  const handleEmailValidation = () => {
    setCustomerEmailError(validateEmail(emailValue));
  };

  /**
   * Handles the STB input validation
   * @function handleStbValidation - Performs the STB validation.
   * @param {object} e - STB input Pointer event.
   */
  const handleStbValidation = (e) => {
    if (e.target.value === "") {
      setstbErrorMessage(`${STB_REQUIRED}`);
    } else {
      setstbErrorMessage(``);
    }
  };
  /**
   * Handles the STB input validation
   * @function handleConfirmSTBValidation - Performs the STB validation.
   * @param {object} e - STB input Pointer event.
   */
  const handleConfirmSTBValidation = (e) => {
    if (e.target.value === "") {
      setConfirmSTBErrorMessage(`${STB_REQUIRED}`);
    }
  };

  /**
   * Handle the First name validation.
   * @function handleFirstNameValidation - Performs Validation on FirstName
   * @param {object} e - First name input Pointer event.
   */
  const handleFirstNameValidation = (e) => {
    if (e.target.value === "") {
      setFirstNameError(`${FIRSTNAME_REQUIRED}`);
    } else if (!validFirstName(whiteSpaceInWord(firstNameValue))) {
      setFirstNameError(`${FIRST} ${NAME} ${ALPHABETS_ARABIC}`);
    } else {
      setFirstNameError(``);
    }
  };

  /**
   * Handles the Last name Validation.
   * @function handleLastNameValidation - Performs validation on last name input change.
   * @param {object} e - Last name input pointer event.
   */
  const handleLastNameValidation = (e) => {
    if (e.target.value === "") {
      setLastNameError(`${LASTNAME_REQUIRED}`);
    } else if (!validLastName(whiteSpaceInWord(lastNameValue))) {
      setLastNameError(`${LAST} ${NAME} ${LAST_NAME_ERROR}`);
    } else {
      setLastNameError(``);
    }
  };

  /**
   * Handles th City Validation.
   * @function handleCityValidation - Performs Validation on City input change.
   * @param {event} e - City input pointer event.
   */
  const handleCityValidation = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setCityError(`${CITY_REQUIRED}`);
    } else {
      setCityError("");
    }
  };

  /**
   * Handles the mobile Verification.
   * @function handleVerifyMobileNumber - Verify the Entered Mobile Number
   */
  const handleVerifyMobileNumber = () => {
    const countryPhoneCode = countryDetails.countryPhoneCode;
    const userData = {
      mobileNumber: `+${countryPhoneCode}${MobileValue}`.trim()
    };
    setMobilePayload(userData);
    setCaptchaValue("");
    setOtpVerified(false);
    setcustomerExist("");
    verifyCustomerWithOTP(config.server, userData).then((response) => {
      if (response && response.status === 200) {
        let toastDetails = successToastMessage(
          OTP_GENERATED_SUCCESS_DESCRIPTION
        );
        dispatch(updateToastMessage(toastDetails));
        setExpireTime(convertMStoS(response.data.validFor));
        setOpenPopup(true);
      } else {
        const message = response.data.message
          ? response.data.message
          : SOMETHING_WENT_WRONG;
        let toastDetails = failureToastMessage(message, "");
        dispatch(updateToastMessage(toastDetails));
      }
    });
  };

  /**
   * Get's the City list based on the country code.
   * @function getCityLists - Fetches the city list based on the country code selected.
   */
  const getCityLists = useCallback(() => {
    if (countryDetails.countryCode && config.server) {
      getCityList(config.server, countryDetails.countryCode).then((res) => {
        if (res && res["data"][0]) {
          setcityList(res["data"][0].cityList);
        }
      });
    }
  }, [countryDetails, config.server]);

  useEffect(() => {
    getCityLists();
  }, [getCityLists]);

  const renderCityName = (selectedId) => {
    const selectedCity = cityListInfo.find((city) => city.id === selectedId);
    return selectedCity ? selectedCity.cityName : "";
  };
  /**
   * Handles the OTP Verification
   * @function handleOtpVerification - Handles the OTP Verification Operation.
   * @param {string} value - Mobile OTP
   */
  const handleOtpVerification = (value) => {
    const userData = {
      mobileNumber: `+${countryDetails.countryPhoneCode}${MobileValue}`,
      otp: value
    };
    setOtpVerified(false);
    validateCustomerWithOTP(config.server, userData).then((response) => {
      if (response && response.status === 200) {
        setOpenPopup((prev) => !prev);
        if (!response.data.accountId) {
          setcustomerExist(
            <FormHelperText
              sx={{
                color: "green",
                textAlign: "left",
                fontWeight: "600",
                ml:0
              }}
            >
              {CUSTOMER_NOT_PRESENT_IN_THE_SYSTEM}
            </FormHelperText>
          );
          setOtpVerified(true);
          setshortToken(response.data.shortToken);
        } else {
          setcustomerExist(
            <FormHelperText
              sx={{
                fontWeight: "600"
              }}
              className="errorText"
            >
              {CUSTOMER_PRESENT_IN_THE_SYSTEM}
            </FormHelperText>
          );
        }
      } else {
        showPopup(response.data.message);
      }
    });
  };

  /**
   * Handles the Customer Registration.
   * @function handleCustomerRegistration - Handles the Customer Registration operation on Filling all form Values.
   */
  const handleCustomerRegistration = () => {
    setLoader(true);
    const payload = {
      username: `+${countryDetails.countryPhoneCode}${MobileValue}`.trim(),
      city: cityValue,
      email: emailValue.trim(),
      mobileNumber: `+${countryDetails.countryPhoneCode}${MobileValue}`.trim(),
      firstName: firstNameValue.trim(),
      lastName: lastNameValue.trim(),
      gender: genderValue.toUpperCase() || "OTHER",
      countryName: countryDetails.countryName,
      countryCode: countryDetails.countryCode,
      hardwareSerialNumber: stbValue.trim(),
      address: addressValue.trim(),
      area: areaValue.trim(),
      nationality: nationalityValue.trim(),
      postalCode: postalCodeValue.trim(),
      dateOfBirth: dob.split("-").reverse().join("-"),
      shortToken: shortToken
    };
    CustomerRegistrationWithStb(config.server, payload)
      .then((res) => {
        if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
          let toastDetails = successToastMessage(
            CUSTOMER_REGISTERED_SUCCESSFULLY,
            ""
          );
          dispatch(updateToastMessage(toastDetails));
          navigateTo("/customer");
        } else {
          const message = res.data.message
            ? res.data.message
            : SOMETHING_WENT_WRONG;
          let toastDetails = failureToastMessage(message, "");
          dispatch(updateToastMessage(toastDetails));
        }
        resetSecurityCode();
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const [openPopup, setOpenPopup] = useState(false);

  /**
   * Handles the Button's Enable or disable Status.
   * Checks whether to Enable or Disable the Customer Registration confirm button.
   * @returns {boolean} true or false to either enable or disable the confirm button
   */
  const isVerifyButtonDisable = () => {
    const valid =
      stbValue === "" ||
      confirmSTB === "" ||
      firstNameValue === "" ||
      lastNameValue === "" ||
      emailValue === "" ||
      MobileValue === "" ||
      cityValue === "" ||
      captchaValue === "" ||
      firstNameError !== "" ||
      lastNameError !== "" ||
      customerEmailError !== "" ||
      confirmSTBErrorMessage !== "" ||
      stbValue !== confirmSTB ||
      isCaptchaMatch(captchaValue)
        ? true
        : false;
    return valid;
  };

  /**
   * Displays the options based on the result of the search Input string.
   */
  const filternationalityList = useMemo(
    () =>
      nationalityList.filter((option) =>
        option.nationalityName
          .toLowerCase()
          .includes(searchNationalityText.toLowerCase())
      ),
    [searchNationalityText]
  );
  /**
   * Checks whether the CAPTCHA is valid or not.
   * @function isCaptchaMatch - Performs action to check whether the captcha input matches the captcha image.
   * @param {string} values
   * @returns true or false stating that the captcha is valid or not.
   */
  const isCaptchaMatch = (values) => {
    const isValidCaptcha = values && captchaCode !== values ? true : false;
    return isValidCaptcha;
  };

  /**
   * Renders the Labels of the Input fields
   * @function renderLabel - Create a UI for the form Input Labels.
   * @param {string} tittle
   * @param {*} asterisk
   * @returns the label text of the Input fields.
   */
  const renderLabel = (tittle, asterisk) => {
    return (
      <label className="inputLabel">
        {tittle} {asterisk && <span className="asterisk">*</span>}
      </label>
    );
  };

  const isTokenPresent = shortToken ? true : false;
  return (
    <Paper
      elevation={3}
      className="customer_registration"
      sx={{ p: { xs: 1, lg: 0 } }}
    >
      <Toolbar sx={{ px: { lg: 3.8, xs: 0 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            justifyContent: "space-between",
            alignItems: { sm: "center" },
            width: "100%"
          }}
        >
          <Typography className="header">{CUSTOMER_REGISTRATION}</Typography>{" "}
          <Link
            to="/hardware/hardwareallocationandreplacement"
            style={{
              textDecoration: "none"
            }}
          >
            <Button
              variant="outlined"
              sx={{
                background:
                  "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                color: "#FFFFFF",
                borderRadius: "20px",
                letterSpacing: "0px",
                fontFamily: "BukraMedium",
                fontSize: { lg: "14px", xs: "13px" },
                width: { sm: "fit-content", xs: "100%" },
                textTransform: "none",
                height: "40px",
                px: 2.5,
                my: { xs: 1, sm: 0 }
              }}
            >
              {STB_ALLOCATION_REPLACEMENT_LABEL}
            </Button>
          </Link>
        </Box>
      </Toolbar>
      <Divider></Divider>
      <Box
        sx={{
          mx: { lg: 12 },
          mt: { lg: 2.7, xs: 2 },
          pb: 2,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {loader && <OverlayLoader />}
        <ThemeProvider theme={inputTheme}>
          <Stack spacing={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                gap: "2%",
                alignItems: "baseline"
              }}
            >
              <FormControl
                sx={{ width: { xs: "100%", lg: "75%" } }}
                size="small"
                fullWidth
              >
                {renderLabel(MOBILE, true)}
                <Box
                  className="mobileWrapper"
                  sx={{
                    width: { xs: "100%", lg: "98%" }
                  }}
                >
                  <Box className="mobileCode">
                    +{countryDetails.countryPhoneCode}{" "}
                    {countryDetails.countryCode}
                  </Box>
                  <InputBase
                    autoComplete="off"
                    disabled={isTokenPresent}
                    value={MobileValue}
                    onChange={(e) => handleMobileChange(e)}
                    sx={{ ml: 1, flex: 1, pt: 0.5 }}
                    placeholder={`${ENTER} ${NUMBER}`}
                    required
                    inputProps={{ "data-testid": "mobileNumber" }}
                  />
                </Box>
                {mobileNumberError && (
                  <FormHelperText className="errorText">
                    {mobileNumberError}{" "}
                  </FormHelperText>
                )}
                {!mobileNumberError && customerExist}
              </FormControl>
              <Box
                className="verfiymobile"
                onClick={() => handleVerifyMobileNumber()}
                sx={{
                  width: { xs: "100%", lg: "23%" },
                  textAlign: { xs: "center" },
                  opacity:
                    !MobileValue || mobileNumberError || shortToken ? 0.6 : 1,
                  pointerEvents:
                    !MobileValue || mobileNumberError || shortToken
                      ? "none"
                      : "auto",
                  top: { xs: "10px", lg: "44px" }
                }}
              >
                <span data-testid="verifyMobileNumber">
                  {VERIFY_MOBILE_NUMBER}
                </span>
              </Box>
            </Box>
            <FormControl size="small" fullWidth>
              {renderLabel("STB #", true)}
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                data-testid="stbInput"
                type={"text"}
                size="medium"
                variant="outlined"
                disabled={!otpVerified}
                fullWidth
                value={stbValue}
                placeholder={
                  isMobile ? "Enter serial number" : STB_CUSTOMER_PLACEHOLDER
                }
                onChange={(e) => handleChangeSTB(e)}
                onBlur={(e) => handleStbValidation(e)}
                onKeyDownCapture={(e) =>
                  (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                  e.preventDefault()
                }
                className="textField"
                required
                inputProps={{
                  "data-testid": "stbTestInput",
                  maxLength: STB_MAX_LENGTH
                }}
              ></TextField>
              {stbErrorMessage && (
                <FormHelperText className="errorText">
                  {stbErrorMessage}{" "}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl size="small" fullWidth>
              {renderLabel("Confirm STB #", true)}
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                data-testid="stbConfirm"
                type={"text"}
                size="medium"
                variant="outlined"
                disabled={!otpVerified}
                fullWidth
                value={confirmSTB}
                placeholder={"Confirm serial number"}
                onChange={(e) => handleConfirmSTBChange(e)}
                onBlur={(e) => handleConfirmSTBValidation(e)}
                onKeyDownCapture={(e) =>
                  (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                  e.preventDefault()
                }
                className="textField"
                required
                inputProps={{
                  "data-testid": "stbInputConfirm",
                  maxLength: STB_MAX_LENGTH
                }}
              ></TextField>
              {confirmSTBErrorMessage && (
                <FormHelperText className="errorText">
                  {confirmSTBErrorMessage}{" "}
                </FormHelperText>
              )}
            </FormControl>
            <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
              <FormControl size="small" fullWidth>
                {renderLabel(`${FIRST} ${NAME}`, true)}
                <TextField
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  size="medium"
                  variant="outlined"
                  placeholder={`${ENTER} ${FIRST} ${NAME}`}
                  disabled={!otpVerified}
                  fullWidth
                  className="textField"
                  maxLength={NAME_MAX_LENGTH}
                  value={firstNameValue}
                  onChange={(e) => handleFirstNameChange(e)}
                  onBlur={(e) => {
                    handleFirstNameValidation(e);
                  }}
                  required
                  inputProps={{ "data-testid": "firstNameTestInput" }}
                ></TextField>
                {firstNameError && (
                  <FormHelperText className="errorText">
                    {firstNameError}{" "}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl size="small" fullWidth>
                {renderLabel(`${LAST} ${NAME}`, true)}
                <TextField
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  size="medium"
                  variant="outlined"
                  disabled={!otpVerified}
                  placeholder={`${ENTER} ${LAST} ${NAME}`}
                  fullWidth
                  className="textField"
                  value={lastNameValue}
                  maxLength={NAME_MAX_LENGTH}
                  onChange={(e) => handleLastNameChange(e)}
                  onBlur={(e) => {
                    handleLastNameValidation(e);
                  }}
                  required
                  inputProps={{ "data-testid": "lastNameTestInput" }}
                ></TextField>
                {lastNameError && (
                  <FormHelperText className="errorText">
                    {lastNameError}{" "}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
            <FormControl size="small" fullWidth>
              {renderLabel(EMAIL, true)}
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                size="medium"
                variant="outlined"
                fullWidth
                disabled={!otpVerified}
                placeholder={`${ENTER} ${EMAIL}`}
                className="textField"
                value={emailValue}
                onChange={(e) => handleEmailChange(e)}
                onBlur={() => handleEmailValidation()}
                inputProps={{
                  "data-testid": "emailTestInput",
                  maxLength: MAX_LENGTH_50
                }}
                required
              ></TextField>
              {customerEmailError && (
                <FormHelperText className="errorText">
                  {customerEmailError}{" "}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl size="small" fullWidth>
              {renderLabel(COUNTRY, true)}
              <Box
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px",
                  padding: 2
                }}
              >
                {countryDetails.countryName}
              </Box>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
              {renderLabel(CITY, true)}
              <Select
                ref={cityRef}
                labelId="demo-select-small"
                data-testid="citySelect"
                id="demo-select-small"
                disabled={!otpVerified}
                onOpen={handleToggle}
                value={cityValue}
                displayEmpty
                onChange={(e) => handleCityChange(e)}
                onBlur={(e) => handleCityValidation(e)}
                inputProps={{ "data-testid": "cityTestInput" }}
                renderValue={(age) =>
                  age ? (
                    renderCityName(age)
                  ) : (
                    <Typography
                      sx={{
                        letterSpacingpacing: "0px",
                        color: "#202020",
                        fontSize: "14px",
                        fontFamily: "BukraRegular",
                        opacity: 0.5
                      }}
                    >
                      {SELECT_CITY}
                    </Typography>
                  )
                }
                sx={{
                  backgroundColor: "#edf4f6",
                  "&.MuiPaper-root": {
                    backgroundColor: "green"
                  }
                }}
                required
                MenuProps={{
                  autoFocus: false,
                  anchorOrigin: anchorOrigin,
                  transformOrigin: transformOrigin,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      marginTop:
                        anchorOrigin.vertical === "top" ? "-5px" : "3px"
                    }
                  }
                }}
              >
                {cityListInfo.length > 0 &&
                  cityListInfo.map((city) => {
                    return (
                      <MenuItem
                        key={city.id}
                        value={city.id}
                        sx={{
                          ...muiMenuItemStyles
                        }}
                      >
                        {city.cityName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {cityError && (
                <FormHelperText className="errorText">
                  {cityError}{" "}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl size="small" fullWidth>
              {renderLabel(AREA, false)}
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                size="medium"
                variant="outlined"
                fullWidth
                disabled={!otpVerified}
                onChange={(e) => handleAreaChange(e)}
                value={areaValue}
                className="textField"
                inputProps={{
                  "data-testid": "areaTestInput",
                  maxLength: MAX_LENGTH_50
                }}
              ></TextField>
            </FormControl>
            <FormControl size="small" fullWidth>
              {renderLabel(ADDRESS, false)}
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                size="medium"
                variant="outlined"
                fullWidth
                value={addressValue}
                disabled={!otpVerified}
                onChange={(e) => handleAddressChange(e)}
                className="textField"
                inputProps={{
                  "data-testid": "addressTestInput",
                  maxLength: MAX_LENGTH_50
                }}
              ></TextField>
            </FormControl>
            <FormControl size="small" fullWidth>
              {renderLabel(POSTAL_CODE, false)}
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                size="medium"
                variant="outlined"
                fullWidth
                disabled={!otpVerified}
                value={postalCodeValue}
                onChange={(e) => handlePostalcodeChange(e)}
                className="textField"
                inputProps={{
                  "data-testid": "postalCodeTestInput",
                  maxLength: MAX_POSTAL_CODE_LENGTH
                }}
              ></TextField>
            </FormControl>
            <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
              <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                {renderLabel(GENDER, false)}
                <Select
                  ref={genderRef}
                  data-testid="genderSelect"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="gender"
                  onOpen={handleToggleGender}
                  onChange={(e) => handleGenderChange(e)}
                  value={genderValue}
                  disabled={!otpVerified}
                  displayEmpty
                  renderValue={(gender) =>
                    gender ? (
                      gender
                    ) : (
                      <Typography
                        sx={{
                          letterSpacingpacing: "0px",
                          color: "#202020",
                          fontSize: "14px",
                          fontFamily: "BukraRegular",
                          opacity: 0.5
                        }}
                      >
                        {SELECT_GENDER}
                      </Typography>
                    )
                  }
                  MenuProps={{
                    autoFocus: false,
                    anchorOrigin: anchorOriginGender,
                    transformOrigin: transformOriginGender,
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        marginTop:
                          anchorOriginGender.vertical === "top" ? "-5px" : "3px"
                      }
                    },
                    MenuListProps: {
                      style: {
                        paddingTop: 0
                      }
                    }
                  }}
                  sx={{
                    backgroundColor: "#edf4f6"
                  }}
                  inputProps={{ "data-testid": "genderTestInput" }}
                >
                  {GENDERDATA.map((item) => {
                    return (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          ...muiMenuItemStyles
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                {renderLabel(NATIONALITY, false)}

                <Select
                  ref={nationalityRef}
                  disabled={!otpVerified}
                  onOpen={handleToggleNationality}
                  MenuProps={{
                    autoFocus: false,
                    anchorOrigin: anchorOriginNationality,
                    transformOrigin: transformOriginNationality,
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        marginTop:
                          anchorOriginNationality.vertical === "top"
                            ? "-5px"
                            : "3px"
                      }
                    },
                    MenuListProps: {
                      style: {
                        paddingTop: 0
                      }
                    }
                  }}
                  inputProps={{ "data-testid": "nationalityInput" }}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="nationality"
                  value={nationalityValue}
                  onChange={(e) => handleChangeNationality(e)}
                  onClose={() => setSearchNationalityText("")}
                  displayEmpty
                  renderValue={(name) => {
                    if (!name) {
                      return (
                        <Typography
                          sx={{
                            letterSpacingpacing: "0px",
                            color: "#282828",
                            opacity: 0.5,
                            fontSize: "14px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          {`${SELECT} ${NATIONALITY} `}
                        </Typography>
                      );
                    }
                    return name;
                  }}
                  sx={{
                    width: "100%",
                    backgroundColor: "#edf4f6",
                    fontFamily: "BukraRegular",
                    ".MuiSelect-select": {
                      fontSize: "14px",
                      color: "#0F0F10",
                      fontFamily: "BukraRegular"
                    }
                  }}
                >
                  <ListSubheader sx={{ p: "4px " }}>
                    <Input
                      autoComplete="off"
                      placeholder="Type to search..."
                      autoFocus
                      sx={{
                        width: "100%",
                        maxWidth: "95%",
                        m: 1,
                        p: "4px"
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "default" }}
                        >
                          <img
                            src={searchIcon}
                            alt="Search Icon"
                            onMouseDown={handleMouseDownOnAndornment}
                          ></img>
                        </InputAdornment>
                      }
                      onChange={(e) => setSearchNationalityText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    ></Input>
                  </ListSubheader>
                  {filternationalityList && filternationalityList.length > 0 ? (
                    filternationalityList.map((nationality) => {
                      return (
                        <MenuItem
                          key={nationality.nationalityCode}
                          value={nationality.nationalityName}
                          sx={{
                            ...muiMenuItemStyles
                          }}
                        >
                          {nationality.nationalityName}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <Typography
                      disabled
                      sx={{
                        background: "white",
                        px: 2,
                        fontSize: "12px",
                        fontFamily: "BukraRegular"
                      }}
                    >
                      {NO_RESULTS_FOUND}
                    </Typography>
                  )}
                </Select>
              </FormControl>
            </Stack>
            <Stack
              spacing={2}
              direction={{ xs: "column", lg: "row" }}
              sx={{ alignItems: "flex-start" }}
            >
              <FormControl size="small" fullWidth>
                {renderLabel(DATE_OF_BIRTH, false)}
                <Box className="dob_wrapper">
                  <DatePicker
                    ref={datePickerRef}
                    disabled={!otpVerified}
                    inputClass="replacement-date-input"
                    value={dob || ""}
                    placeholder="DD-MM-YYYY"
                    onChange={(date) => {
                      handleDateofBirthChange(date);
                    }}
                    style={{
                      border: "none",
                      boxShadow: "none"
                    }}
                    editable={false}
                    format={"DD-MM-YYYY"}
                    maxDate={new Date()}
                    title="dateOfBirth"
                    role="dob"
                  />
                  <img
                    src={calendar_month_black}
                    alt="date"
                    height="15px"
                    width="15px"
                    style={{
                      paddingRight: "10px",
                      cursor: "pointer"
                    }}
                    onClick={() => datePickerRef.current.openCalendar()}
                  ></img>
                </Box>
              </FormControl>
              <FormControl size="small" fullWidth>
                {renderLabel(CAPTCHA, true)}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    userSelect: "none"
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box className="captchaCode">
                      <span
                        data-testid="captchValueInfo"
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        {captchaCode}
                      </span>
                    </Box>
                    <IconButton
                      onClick={() => resetSecurityCode()}
                      disabled={!otpVerified}
                      data-testid="resetSecurity"
                    >
                      <img src={Reset} height="22px" alt="refresh"></img>
                    </IconButton>
                  </Box>
                  <TextField
                    autoComplete="off"
                    onDrop={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    size="medium"
                    variant="outlined"
                    disabled={!otpVerified}
                    sx={{
                      ml: { md: "61px", xs: "8px" }
                    }}
                    fullWidth
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    className="textFieldCode"
                    value={captchaValue}
                    onChange={(date) => {
                      handleCaptchaChange(date);
                    }}
                    inputProps={{ "data-testid": "captchaTestInput" }}
                  ></TextField>
                </Box>
                {isCaptchaMatch(captchaValue) && (
                  <FormHelperText className="errorText">
                    {CAPTCHA_ERROR_MSG}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </Stack>
        </ThemeProvider>
        <Stack
          spacing={2}
          direction={{ xs: "column", lg: "row" }}
          justifyContent="flex-end"
          sx={{ pt: 4, pb: 2 }}
        >
          <Button
            variant="outlined"
            disabled={isVerifyButtonDisable()}
            type="submit"
            onClick={() => handleCustomerRegistration()}
            data-testid="customerRegistrationButton"
            sx={{
              borderRadius: "20px",
              fontSize: "15px",
              ...buttonStyles,
              minWidth: "180px"
            }}
          >
            {SUBMIT}
          </Button>
        </Stack>
      </Box>

      {openPopup && (
        <Dialog open={openPopup}>
          <VerificationBox
            payload={mobilePayload}
            handleConfirmVerification={handleOtpVerification}
            errorInfo={errorInfo}
            showErrorPop={showErrorPop}
            hidePopup={showToastCallback}
            showPopup={showPopup}
            isPopup={true}
            isOnlyMobileVerification={true}
            closeDialog={closeDialog}
            codeExpireTime={expireTime}
            resendCodeTime={RESEND_CODE_TIME}
          ></VerificationBox>
        </Dialog>
      )}
    </Paper>
  );
};

export default CustomerRegistration;
