export const buttonStyles = {
  textTransform: "none",
  fontFamily: "BukraRegular",
  letterSpacing: 0,
  background:
    "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
  color: "#ffffff",
  "&.Mui-disabled": {
    background:
      "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
    color: "#ffffff",
    opacity: 0.5
  }
};
export const crMuiInputBase = {
  fontFamily: "BukraRegular",
  color: "#202020",
  fontSize: "14px",
  letterSpacing: "0px",
  height: "44px",
  ":focus": {
    background: "#EDF4F6 0% 0% no-repeat padding-box",
    color: "#202020",
    opacity: 1
  }
};

export const muiMenuItemStyles = {
  fontFamily: "BukraRegular",
  color: "#202020",
  fontSize: "12px",
  letterSpacing: "0px",
  wordWrap: "break-word",
  whiteSpace: "normal",
  "&.Mui-selected": {
    fontFamily: "BukraMedium",
    color: " #181D25",
    opacity: 1,
    background: "#C6C6CC4D 0% 0% no-repeat padding-box",
    "&.Mui-selected:focus": {
      backgroundColor: "#e9e9eb"
    }
  }
};

export const tableScrollStyles = {
  "&::-webkit-scrollbar": {
    width: 0
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "unset"
  },
  scrollbarWidth: "none"
};
