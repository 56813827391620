import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";

import {
  BLANK,
  CUSTOMER_ID,
  EMAIL,
  ENTER,
  MOBILE_NUMBER,
  NO_CONTENT,
  SUBSCRIBE_NOW,
  TOAST_ERROR,
  HASH,
  SOMETHING_WENT_WRONG
} from "../../constant/Constants";
import Typography from "@mui/material/Typography";
import search from "./../../assets/images/search.svg";
// import ViewCart_Icon from "./../../assets/images/view_cart_icon.svg";
import "./SubscribeNow.scss";
import RenewSubscription from "../renewSubscription/RenewSubscription";
import { useDispatch, useSelector } from "react-redux";
import NewSubscription from "../newSubscription/NewSubscription";

import TabPanel from "../tabPanel/TabPanel";
import { getCustomerInfo } from "../../services/user.services";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import {
  getCustomerSearchErrorLabel,
  onlyNumber,
  updatePageVisit
} from "../../constant/Utils";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { useLocation } from "react-router-dom";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import SideLoader from "../loaders/sideLoader/SideLoader";

/**
 *@constant {array<object>} subscribeSelection - Search Option Initialization for the Subscrib now Page
 */
const subscribeSelection = [
  {
    value: "mobileNumber",
    label: "Mobile Number"
  },
  {
    value: "id",
    label: "Customer ID"
  },
  {
    value: "serialNo",
    label: "Serial Number"
  }
];

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "30px",
          marginLeft: "1px",
          textTransform: "capitalize",
          color: "#0F57CB",
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          fontFamily: "BukraMedium"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":focus": {
            backgroundColor: "#e7eaed"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ":focus": {
            backgroundColor: "#e7eaed"
          }
        }
      }
    }
  }
});
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});

/**
 * Renders he Subscribe now Page UI componet.
 * @returns {component} the UI component for the Subscribe now page.
 */
export default function SubscribeNow() {
  const dispatch = useDispatch();
  const location = useLocation();
  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const [value, setValue] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [sortingCredits, setSortingCredits] = useState("mobileNumber");
  const [showViewCart, setShowViewcart] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});
  const [stbInfo, setStbInfo] = useState([]);
  const [loader, setLoader] = useState(false);
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Updates the Page Visits info.
   */
  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  // const totalSelectedRenewItems = useSelector((state) =>
  //   getSelectRenewItems(state)
  // );
  // const totalSelectedNewSubscriptionItems = useSelector((state) =>
  //   getSelectNewSubscriptionItems(state)
  // );

  /**
   * Handles the Cart items length.
   * @function getTotalCartItems - Fetches the length of cart items.
   * @returns {number} - total no. of cart items
   */
  // const getTotalCartItems = () => {
  //   let total;
  //   if (value === 0) {
  //     total = totalSelectedNewSubscriptionItems.length;
  //   } else {
  //     total = totalSelectedRenewItems.length;
  //   }
  //   return total;
  // };

  /**
   * @constant {number} TotalCartItems - No. of total cart items.
   */
  // const TotalCartItems = getTotalCartItems();

  /**
   * Handles the Tab change operation.
   * @function handleChange - Performs action on Tab Change
   * @param {object} event - Selected tab event Object
   * @param {string} newValue - Label of Tab
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowViewcart(false);
    // dispatch(removeAllNewSubscriptionItems());
    // dispatch(removeAllItem());
  };

  /**
   * Handles the Search input Value.
   * @function handleSearchRequest - Performs action on Search input value change.
   * @param {object} event - Search input event object
   */
  const handleSearchRequest = (event) => {
    const searchedVal = event.target.value.trim();
    if (sortingCredits !== "serialNo") {
      if (onlyNumber(searchedVal)) {
        setSearchVal(searchedVal);
      }
    } else {
      setSearchVal(searchedVal);
    }
  };

  /**
   * Handles the Select Option value change operation.
   * @function handleSelectChange - Handles the Select Category Change Operation.
   * @param {object} event - Search category Select option event object.
   */
  const handleSelectChange = (event) => {
    setSearchVal("");
    setSortingCredits(event.target.value);
  };

  /**
   * Handles the search operation on Enter Press.
   * @function handleEnterDetails - Performs Search action on Enter Click.
   * @param {string} category - Search category.
   * @param {string} input - Search input.
   * @param {object} event - Search input action event object.
   */
  const handleEnterDetails = (category, input, event) => {
    if (event.key === ENTER) {
      getCustomerDetails(category, input);
    }
  };

  /**
   * Handles the Operation to get the Customer Details.
   * @function getCustomerDetails - Fetches the Customer Details
   * @param {string} category - Search category
   * @param {string} input - Search input value
   */
  const getCustomerDetails = (category, input) => {
    if (!input) {
      let toastDetails = {
        toastTitle: `${getCustomerSearchErrorLabel(
          category
        )} must not be blank`,
        isToastOpen: true,
        toastStatus: TOAST_ERROR,
        toastTextclr: "#FF2A2A",
        toastBgclr: "#FDE2E2 ",
        toastBorderclr: "#FF2A2A"
      };
      dispatch(updateToastMessage(toastDetails));
    }
    if (input) {
      setLoader(true);
      if (category === "mobileNumber") {
        input = `+${country.countryPhoneCode}${input}`;
      }
      getCustomerInfo(config.server, category, input.trim())
        .then((res) => {
          if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
            setCustomerInfo(res.data.customerInfo);
            setStbInfo(res.data.stbInfo);
          } else {
            setCustomerInfo({});
            setStbInfo([]);
            const message =
              res && res.data && res.data.message ? res.data.message : SOMETHING_WENT_WRONG;
            let toastDetails = {
              toastTitle: message,
              isToastOpen: true,
              toastDescription: "",
              toastStatus: TOAST_ERROR,
              toastTextclr: "#FF2A2A",
              toastBgclr: "#FDE2E2 ",
              toastBorderclr: "#FF2A2A"
            };
            dispatch(updateToastMessage(toastDetails));
          }
          // dispatch(removeAllNewSubscriptionItems());
          // dispatch(removeAllItem());
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  /**
   * useEffect Hook to remove all the Items from selection on load.
   */
  // useEffect(() => {
  //   return () => {
  //     dispatch(removeAllNewSubscriptionItems());
  //     dispatch(removeAllItem());
  //   };
  // }, [dispatch]);

  /**
   * Handles the Display of customer Info.
   * @returns {JSX} Customer Info UI.
   */
  const renderCustomerDetails = () => {
    return (
      <Box
        sx={{
          mx: { lg: 7, xs: 3 },
          pt: 2,
          pb: 3
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#465964",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                mr: "66px"
              }}
            >
              {" "}
              {CUSTOMER_ID}
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#0F0F10",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                lineBreak: "anywhere"
              }}
            >
              {customerInfo.customerId ? customerInfo.customerId : BLANK}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: { lg: "400px" },
              mt: { xs: 2, lg: 0 }
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                color: "#465964",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                mr: { xs: 3, lg: 5 }
              }}
            >
              {MOBILE_NUMBER} {HASH}
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#0F0F10",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                lineBreak: "anywhere"
              }}
            >
              {customerInfo.mobileNumber ? customerInfo.mobileNumber : BLANK}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", pt: 2 }}>
          <Typography
            sx={{
              fontSize: "15px",
              color: "#465964",
              letterSpacing: 0,
              fontFamily: "BukraRegular",
              mr: "119px"
            }}
          >
            {EMAIL}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              color: "#0F0F10",
              letterSpacing: 0,
              fontFamily: "BukraRegular",
              lineBreak: "anywhere"
            }}
          >
            {customerInfo.email ? customerInfo.email : BLANK}
          </Typography>
        </Box>
      </Box>
    );
  };

  /**
   * Handles the Display of Cart view.
   * @function handleViewCart - Displays the Cart UI.
   */
  // const handleViewCart = () => {
  //   if (TotalCartItems >= 1) {
  //     setShowViewcart(true);
  //     dispatch(setPurchaseType(ADDEDCART));
  //     dispatch(setRenewPurchaseType(ADDEDCART));
  //   }
  // };

  /**
   * Handles the Close operation on Cart UI.
   * @function handleCloseViewcart - Closes the Cart UI.
   */
  // const handleCloseViewcart = () => {
  //   setShowViewcart(false);
  // };

  return (
    <Paper data-testid="subscribeNow" elevation={2} sx={{ pb: 5, mb: 2 }}>
      <Toolbar
        sx={{
          pt: { xs: 2, md: 0 },
          pr: { xs: 0.5, sm: 1 },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // flexDirection: { xs: "column", lg: "row" },
          minHeight: { sm: "84px" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { md: "center", xs: "flex-start" },
            flexDirection: { md: "row", xs: "column" },
            width: { xs: "100%", md: "auto" }
          }}
        >
          <Typography
            data-testid="subscribeNowHeading"
            variant="h6"
            id="tableTitle"
            sx={{
              fontFamily: "BukraMedium",
              fontSize: "16px",
              letterSpacing: "0px",
              color: "#464E5F",
              mr: 2,
              ml: { xs: 1, lg: 1 },
              my: { xs: 1, lg: 0 }
            }}
          >
            {SUBSCRIBE_NOW}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "center" },
              flexDirection: { sm: "row", xs: "row" },
              width: { xs: "100%", md: "auto" }
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                backgroundColor: "#f3f6f9",
                height: "40px",
                width: { xs: "100%", md: "350px" }
              }}
            >
              <img
                data-testid="customerImage"
                src={search}
                onClick={() => getCustomerDetails(sortingCredits, searchVal)}
                alt="search"
                style={{
                  height: "11px",
                  paddingLeft: "20px",
                  cursor: "pointer"
                }}
              ></img>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", lg: "200px" }
                }}
              >
                {sortingCredits === "mobileNumber" &&
                  country &&
                  country.countryPhoneCode && (
                    <Typography
                      sx={{
                        pl: 1,
                        fontFamily: "BukraRegular",
                        color: "#464E5F",
                        fontSize: "15px"
                      }}
                    >
                      +{country.countryPhoneCode}
                    </Typography>
                  )}
                <ThemeProvider theme={inputTheme}>
                  <InputBase
                    autoComplete="off"
                    value={searchVal}
                    sx={{
                      ml: 1,
                      flex: 1
                    }}
                    placeholder="Search"
                    onChange={(event) => handleSearchRequest(event)}
                    onKeyDown={(e) =>
                      handleEnterDetails(sortingCredits, searchVal, e)
                    }
                    inputProps={{ "data-testid": "searchInput" }}
                  />
                </ThemeProvider>
              </Box>
              <ThemeProvider theme={theme}>
                <Select
                  inputProps={{
                    "data-testid": "selectSearchInner"
                  }}
                  id="filledoption"
                  sx={{
                    backgroundColor: "#7B7D801A",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                    pl: 2,
                    height: "40px",
                    fontSize: "13px",
                    color: "#282828",
                    fontFamily: "BukraRegular",
                    ".MuiSelect-select": {
                      fontSize: "13px",
                      color: "#282828",
                      fontFamily: "BukraRegular"
                    }
                  }}
                  value={sortingCredits}
                  onChange={handleSelectChange}
                  variant="standard"
                  disableUnderline={true}
                >
                  {subscribeSelection.map((option) => (
                    <MenuItem
                      sx={{
                        ...muiMenuItemStyles
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </ThemeProvider>
            </Box>
            {loader && <SideLoader size={20}></SideLoader>}
          </Box>
        </Box>
      </Toolbar>
      {renderCustomerDetails()}
      <Box sx={{ width: "100%", my: { xs: 1, lg: 0 } }}>
        <Box
          sx={{
            borderBottom: 0,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            minHeight: "45px",
            backgroundColor: "#EDF4F6"
          }}
        >
          <ThemeProvider theme={theme}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                display: "flex",
                alignItems: "center",
                minHeight: "45px",
                my: { xs: 1, lg: 0 },
                ml: { md: 3 }
              }}
            >
              <Tab
                data-testid="newSubscriptionTab"
                label="New Subscription"
                sx={{
                  width: { xs: "fit-content" },
                  fontSize: { xs: "12px", md: "14px" },
                  color: "#464E5F",
                  "&.Mui-selected": {
                    background: "#398BE733 0% 0% no-repeat padding-box",
                    borderRadius: "17px",
                    color: "#0F57CB",
                    height: "30px"
                  }
                }}
              />
              <Tab
                data-testid="renewSubscriptionTab"
                label="Renew Subscription"
                sx={{
                  width: { xs: "fit-content" },
                  fontSize: { xs: "12px", md: "14px" },
                  color: "#464E5F",
                  "&.Mui-selected": {
                    background: "#398BE733 0% 0% no-repeat padding-box",
                    borderRadius: "17px",
                    color: "#0F57CB",
                    height: "30px"
                  }
                }}
              />
            </Tabs>
          </ThemeProvider>
          {/* <Box
            data-testid="viewCartClick"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              cursor: "not-allowed",
              my: { xs: 1, sm: 0 },
              mr: { xs: 1, md: 3 }
            }}
            // onClick={() => handleViewCart()}
          >
            <img src={ViewCart_Icon} alt="ct"></img>

            <Typography
              sx={{
                color: "#0F57CB",
                fontSize: "14px",
                fontFamily: "BukraMedium",
                ml: 0.5
              }}
            >
              {" "}
              {VIEW_CART} {TotalCartItems !== 0 && `(${TotalCartItems})`}
             
            </Typography>
          </Box> */}
        </Box>
        <TabPanel value={value} index={0}>
          <NewSubscription
            customerInfo={customerInfo}
            stbInfo={stbInfo}
            showViewCart={showViewCart}
            // handleCloseViewcart={handleCloseViewcart}
          ></NewSubscription>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RenewSubscription
            customerInfo={customerInfo}
            stbInfo={stbInfo}
            showViewCart={showViewCart}
            // handleCloseViewcart={handleCloseViewcart}
          ></RenewSubscription>
        </TabPanel>
      </Box>
    </Paper>
  );
}
