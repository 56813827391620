import React, { useState } from "react";
import { Box, createTheme, Tab, Tabs, ThemeProvider } from "@mui/material";
import TabPanel from "../tabPanel/TabPanel";
import TransactionsPanel from "../transactionsPanel/TransactionsPanel";
import CampaignPanel from "../campaignPanel/CampaignPanel";
import { CAMPAIGN_LABEL, TRANSACTIONS_LABEL } from "../../constant/Constants";

const NotificationPanel = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "30px"
          }
        }
      }
    }
  });
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#0000001A",
          borderBottom: "1.5px solid #c3d2eb"
        }}
      >
        <ThemeProvider theme={theme}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{
              width: "100%",
              "& .MuiButtonBase-root": {
                alignItems: "flex-start"
              },
              ".flexContainer": {
                height: "40px"
              },
              "& .indicator": {
                display: "flex",
                justifyContent: "center",
                backgroundColor: "transparent",
                height: "3px",
                "& > span": {
                  maxWidth: 40,
                  height: "10px",
                  width: "100%",
                  backgroundColor: "#145acc",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px"
                }
              }
            }}
            classes={{
              flexContainer: "flexContainer",
              indicator: "indicator"
            }}
            TabIndicatorProps={{ children: <span /> }}
          >
            <Tab
              data-testid="transactionsTab"
              label={TRANSACTIONS_LABEL}
              sx={{
                textTransform: "capitalize",
                fontFamily: "BukraRegular",
                fontSize: "12px",
                color: "#181D25",
                letterSpacing: 0,
                width: { xs: "fit-content" },
                px: 2,
                py: 0,
                "&.Mui-selected": {
                  color: "#181D25",
                  fontFamily: "BukraMedium"
                }
              }}
            />
            <Tab
              data-testid="campaignTab"
              label={CAMPAIGN_LABEL}
              sx={{
                textTransform: "capitalize",
                fontFamily: "BukraRegular",
                fontSize: "12px",
                color: "#181D25",
                width: { xs: "fit-content" },
                letterSpacing: 0,
                px: 2,
                py: 0,
                "&.Mui-selected": {
                  color: "#181D25",
                  fontFamily: "BukraMedium"
                }
              }}
            />
          </Tabs>
        </ThemeProvider>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <TransactionsPanel />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CampaignPanel />
      </TabPanel>
    </Box>
  );
};

export default NotificationPanel;
