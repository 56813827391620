import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedReverseCreditsDetails: null
};

/**
 * Saves the Info for Child Channel Partner for reverse Credits transfer into the store.
 */
const selectedCreditsTransfersSlice = createSlice({
  name: "selectedCreditsTransfers",
  initialState,
  reducers: {
    setSelectedCreditsTransfersData: (state, action) => {
      state.selectedReverseCreditsDetails = action.payload;
    }
  }
});

/**
 * Fetches the Child Channel Partner Information from store.
 * @param {object} state - State
 * @returns {object} - Child Channel Partner information.
 */
export const getCreditsTransfersDetails = (state) => {
  const creditDetails = state.selectedCreditsTransfer
    ? state.selectedCreditsTransfer.selectedReverseCreditsDetails
    : null;
  return creditDetails;
};

export const { setSelectedCreditsTransfersData } =
  selectedCreditsTransfersSlice.actions;
export default selectedCreditsTransfersSlice.reducer;
