import { createSlice } from "@reduxjs/toolkit";
import { TOAST_SUCCESS } from "../../constant/Constants";

const initialState = {
  isToastOpen: false,
  toastTitle: "",
  toastDescription: "",
  toastStatus: TOAST_SUCCESS,
  toastBgclr: " #E6FFEE",
  toastBorderclr: "#50B768",
  toastTextclr: "#238931"
};

/**
 * Saves the Toast Messages info into the store.
 */
const toastSlice = createSlice({
  name: "toastMessage",
  initialState,
  reducers: {
    updateToastMessage: (state, { payload }) => {
      return { ...state, ...payload };
    },
    resetToast: () => initialState
  }
});

/**
 * Fetches the Store message details from the store.
 * @param {store} state - Store State.
 * @returns {object} - Toast message details
 */
export const getToastMessageDetails = (state) => {
  return state.toastMessage;
};
export const { updateToastMessage, resetToast } = toastSlice.actions;
export default toastSlice.reducer;
