import { createSlice } from "@reduxjs/toolkit";
import { getCreditSummaryBalance } from "../../services/user.services";

const initialState = {
  remainingCreditBalance: 0
};

/**
 * Saves the Toast Messages info into the store.
 */
const creditBalanceSlice = createSlice({
  name: "creditBalance",
  initialState,
  reducers: {
    updateCreditBalance: (state, { payload }) => {
      return { ...state, ...payload };
    }
  },
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(getCreditSummaryBalance.fulfilled, (state, { payload }) => {
      const balance =
        payload.data && payload.data.total_credits_without_prefix
          ? payload.data.total_credits_without_prefix
          : 0;
      return {
        ...state,
        ...payload.data,
        remainingCreditBalance: balance
      };
    });
  }
});

/**
 * Fetches the Store message details from the store.
 * @param {store} state - Store State.
 * @returns {object} - Toast message details
 */
export const getCreditBalanceFromStore = (state) => {
  return state.creditBalance;
};
export const { updateCreditBalance } = creditBalanceSlice.actions;
export default creditBalanceSlice.reducer;
