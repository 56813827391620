import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#F8F8F8 0% 0% no-repeat padding-box"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "12px",
          p: 0
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});

export const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#202020",
          fontSize: "14px",
          letterSpacing: "0px",
          ":focus": {
            background: "#EDF4F6 0% 0% no-repeat padding-box",
            color: "#202020",
            opacity: 1
          }
        }
      }
    }
  }
});

export const multilineInputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "14px",
          minHeight: "80px",
          letterSpacing: "0px",
          border: "1px solid #e8e8e8"
        }
      }
    }
  }
});
