import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import reverseTransfer from "./../../../assets/images/transfer_red.svg";
import transferGreen from "./../../../assets/images/transfer_green.svg";
import {
  currencyConvertToReadable,
  getComparator,
  getDDMMYYYYDateFormat,
  tableSorting,
  transferTypeLabel
} from "../../../constant/Utils";
import { setSelectedCreditsTransfersData } from "../../../stateManagement/reducers/CreditsTransfersSlice";
import { getChannelPartnerCode } from "../../../stateManagement/reducers/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  OUT,
  NINE_GRID_TRANSACTION_ID,
  NINE_GRID_TYPE,
  NINE_GRID_DATE,
  NO_DATA,
  CREDIT_KEY
} from "../../../constant/Constants";
import CustomPagination from "../../customComponents/customPagination/CustomPagination";
import { getFilteredDateFromStore } from "../../../stateManagement/reducers/DateFilterSlice";
import { tableScrollStyles } from "../../../style/CustomStyles";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import userName_Icon from "./../../../assets/images/user_name.svg";
import BodyLoader from "../../loaders/bodyLoader/BodyLoader";

/**
 * * Table headcells object for the Pending Transaction  table
 * @constant {array<object>} headCells - Table headers.
 */
const headCells = [
  {
    id: "creditType",
    numeric: true,
    disablePadding: false,
    label: NINE_GRID_TYPE,
    key: "creditType"
  },
  {
    id: "transferType",
    key: "transferType",
    numeric: false,
    disablePadding: false,
    label: "Transfer"
  },
  {
    id: "fromUser",
    key: "fromUser",
    numeric: false,
    disablePadding: false,
    label: "From"
  },
  {
    id: "toUser",
    key: "toUser",
    numeric: false,
    disablePadding: false,
    label: "To"
  },
  {
    id: "transactionId",
    key: "transactionId",
    numeric: false,
    disablePadding: false,
    label: NINE_GRID_TRANSACTION_ID
  },
  {
    id: "creditAmount",
    key: "creditAmount",
    numeric: false,
    disablePadding: false,
    label: "Amount"
  },
  {
    id: "id",
    key: "id",
    numeric: false,
    disablePadding: false,
    label: "Date"
  }
];

/**
 * Renders the Success Transaction Table component.
 * @param {object} props - Props for the Success Transaction Table
 * @returns {component} the Table UI for the Success transactions.
 */
export default function SuccessCreditsTransfersTable(props) {
  const { isFilterApply, loader, tableBody, handleLoadMoreData } = props;
  const { filterAppliedSelectedCheckBox } = isFilterApply;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedCreditTransfer, setSelectedCreditTransfer] = useState({});
  const [indexofFilterItem, setIndexofFilterItem] = useState([]);
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  const toolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: "#e0f2fc 0% 0% no-repeat padding-box",
            boxShadow: "0px 8px 12px #00000029",
            border: "1px solid #d8dcdf"
          },
          arrow: {
            color: "#e0f2fc"
          }
        }
      }
    }
  });

  /**
   * Fetches the date range from the store.
   */
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );
  const dispatch = useDispatch();

  /**
   * Handles the Success Transaction table sorting based on the selected table head cell.
   * @param {object} _event - Pointer Event.
   * @param {string} property - Ascending or descending order.
   * @function handleRequestSort - Handlle the Table sorting operation.
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedDateFilter.label]);

  useEffect(() => {
    const indexOfFilterCheckBox = filterAppliedSelectedCheckBox.map((obj) => {
      return headCells.findIndex((item) => item.label === obj);
    });
    setIndexofFilterItem(indexOfFilterCheckBox);
  }, [filterAppliedSelectedCheckBox]);

  const totalCount = Math.ceil(tableBody.length / pageSize);

  /**
   * Handle the Page change operation.
   * @param {event} _event - Pointer event.
   * @param {number} value - Total loaded data count.
   * @function handlePageChange - Handle the table Pagination
   */
  const handlePageChange = (_event, value) => {
    if (value === totalCount) {
      handleLoadMoreData();
    }
    setPage(value);
  };

  /**
   * Handles the Page size change operation.
   * @param {event} event - Pointer event.
   * @function handlePageSizeChange - Handles the Page size change.
   */
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  /**
   * Sets the store on Selection of Particular credits transfer detail.
   * @param {object} data - Selected Credit transfer detail
   */
  const onCreditTransferSelected = (data) => {
    if (
      data.transferType === CREDIT_KEY &&
      data.fromUser === channelPartnerCode &&
      selectedCreditTransfer.id !== data.id
    ) {
      setSelectedCreditTransfer(data);
      dispatch(setSelectedCreditsTransfersData(data));
    } else {
      setSelectedCreditTransfer({});
      dispatch(setSelectedCreditsTransfersData(null));
    }
  };

  /**
   * Handles the Filter operation based on the Selected checkbox in the grid element.
   * @param {string} id - Filter label.
   * @param {number} index - Filter string.
   * @function getSelectedCheckBoxStatus - Handle the Filter Operation based on Checked grid elements.
   * @returns {boolean} - true or false.
   */
  const getSelectedCheckBoxStatus = (id, index) => {
    switch (id) {
      case NINE_GRID_TRANSACTION_ID:
        return indexofFilterItem.includes(index);
      case NINE_GRID_DATE:
        return indexofFilterItem.includes(index);
      default:
        return true;
    }
  };
  /**
   * Renders the UI for the Sender name component.
   * @param {string} name - Name of sender.
   * @returns {component} the UI for the Sender Name
   */
  const renderToolTipName = (name, company) => {
    const senderName = name ? `${name} [${company}]` : "Admin";
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "20px",
          width: "fit-content"
        }}
      >
        <img src={userName_Icon} alt="ee"></img>{" "}
        <Typography
          sx={{
            pl: 1,
            color: "#181D25",
            textTransform: "capitalize",
            fontSize: "13px",
            fontFamily: "BukraRegular"
          }}
        >
          {senderName}
        </Typography>
      </Box>
    );
  };
  /**
   * Renders the UI for theNotes component.
   * @param {string} transferNotes - message of sender.
   * @returns {component} the UI for the Notes
   */
  const renderToolTipNotes = (transferNotes) => {
    return transferNotes ? (
      <Typography
        sx={{
          px: 0.5,
          color: "#181D25",
          fontSize: "13px",
          fontFamily: "BukraRegular",
          wordBreak: "break-all"
        }}
      >
        {transferNotes}
      </Typography>
    ) : (
      ""
    );
  };
  return (
    <Box
      sx={{ width: "100%", mt: 2 }}
      data-testid="successCreditsTransfersTable"
    >
      <TableContainer sx={{ ...tableScrollStyles }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow hover>
              {headCells.map((headCell, index) => (
                <Fragment key={headCell.id}>
                  {index === 0
                    ? indexofFilterItem.includes(index) && (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "center" : "left"}
                          padding={"normal"}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                          sx={{ border: "none" }}
                        >
                          <Typography
                            sx={{
                              color: "#606060",
                              letterSpacing: "0px",
                              fontSize: "15px",
                              fontFamily: "BukraMedium"
                            }}
                          >
                            {" "}
                            {headCell.label}
                          </Typography>
                        </TableCell>
                      )
                    : getSelectedCheckBoxStatus(headCell.label, index) && (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "center" : "left"}
                          padding={"normal"}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                          sx={{ border: "none" }}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleRequestSort("", headCell.id)}
                            data-testid="sorting"
                            sx={{
                              color: "#606060",
                              letterSpacing: "0px",
                              fontSize: "15px",
                              fontFamily: "BukraMedium"
                            }}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      )}
                </Fragment>
              ))}
            </TableRow>
          </TableHead>
          {!loader && tableBody && tableBody.length > 0 && (
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {tableSorting(tableBody, getComparator(order, orderBy))
                .slice((page - 1) * pageSize, page * pageSize)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={() => onCreditTransferSelected(row)}
                      sx={{
                        cursor:
                          row.transferType === CREDIT_KEY &&
                          row.fromUser === channelPartnerCode
                            ? "pointer"
                            : "not-allowed",
                        "&:hover": {
                          backgroundColor:
                            selectedCreditTransfer.id === row.id
                              ? "#447ce7"
                              : "#E1E6FBB8 !important"
                        },
                        background:
                          selectedCreditTransfer.id === row.id
                            ? "#447ce7 !important"
                            : ""
                      }}
                      key={row.id}
                      style={
                        index % 2
                          ? { background: "white" }
                          : { background: "#F7F9FC" }
                      }
                      data-testid="tableRow"
                    >
                      {filterAppliedSelectedCheckBox.includes(
                        NINE_GRID_TYPE
                      ) && (
                        <TableCell
                          padding={"normal"}
                          component="th"
                          id={labelId}
                          align="center"
                          scope="row"
                          sx={{
                            border: "none"
                          }}
                        >
                          {row.creditType === OUT ? (
                            <img src={reverseTransfer} alt="out"></img>
                          ) : (
                            <img src={transferGreen} alt="in"></img>
                          )}
                        </TableCell>
                      )}

                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular"
                        }}
                      >
                        {transferTypeLabel(row.transferType)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border: "none"
                        }}
                      >
                        <ThemeProvider theme={toolTipTheme}>
                          <Tooltip
                            arrow
                            placement="top"
                            title={renderToolTipName(
                              row.fromChannelPartnerName,
                              row.fromCompanyName
                            )}
                            sx={{
                              cursor: "pointer"
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#282828",
                                letterSpacing: "0px",
                                fontSize: "13px",
                                fontFamily: "BukraRegular"
                              }}
                            >
                              {row.fromChannelPartnerName}
                            </Typography>
                          </Tooltip>
                        </ThemeProvider>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border: "none"
                        }}
                      >
                        <ThemeProvider theme={toolTipTheme}>
                          <Tooltip
                            arrow
                            placement="top"
                            title={renderToolTipName(
                              row.toChannelPartnerName,
                              row.toCompanyName
                            )}
                            sx={{
                              cursor: "pointer"
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#282828",
                                letterSpacing: "0px",
                                fontSize: "13px",
                                fontFamily: "BukraRegular"
                              }}
                            >
                              {row.toChannelPartnerName}
                            </Typography>
                          </Tooltip>
                        </ThemeProvider>
                      </TableCell>
                      {filterAppliedSelectedCheckBox.includes(
                        NINE_GRID_TRANSACTION_ID
                      ) && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          <ThemeProvider theme={toolTipTheme}>
                            <Tooltip
                              arrow
                              placement="top"
                              title={renderToolTipNotes(
                                row.transferNotes || row.reverseTransferNotes
                              )}
                            >
                              <Typography
                                sx={{
                                  border: "none",
                                  color: "#282828",
                                  letterSpacing: "0px",
                                  fontSize: "13px",
                                  fontFamily: "BukraRegular",
                                  width: "fit-content"
                                }}
                              >
                                {row.transactionId}
                              </Typography>
                            </Tooltip>
                          </ThemeProvider>
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular"
                        }}
                      >
                        {`${row.currency} 
                          ${currencyConvertToReadable(row.creditAmount)}`}
                      </TableCell>
                      {filterAppliedSelectedCheckBox.includes(
                        NINE_GRID_DATE
                      ) && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          {getDDMMYYYYDateFormat(row.modifiedDateTime)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!loader && tableBody && tableBody.length === 0 && (
        <Typography
          textAlign={"center"}
          sx={{
            mt: 3,
            pb: 3
          }}
        >
          {NO_DATA}
        </Typography>
      )}

      {loader && <BodyLoader />}

      {tableBody && tableBody.length > 0 && (
        <CustomPagination
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          totalCount={totalCount}
          page={page}
          handlePageChange={handlePageChange}
        ></CustomPagination>
      )}
    </Box>
  );
}
