import React, { memo } from "react";
import Box from "@mui/material/Box";
import creditImg from "./../../assets/images/credits_card_transfer.svg";

import {
  MD_USER_ROLE_TYPE,
  RETAILER,
  SUB_DISTRIBUTOR,
  TOOLTIP_CREDIT_TRANSFERED
} from "../../constant/Constants";
import Tooltip from "@mui/material/Tooltip";
import { getUserRoleType } from "../../stateManagement/reducers/LoginSlice";
import { ThemeProvider } from "@mui/material";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import { getTotalCreditBalance } from "../../stateManagement/reducers/TotalCreditBalanceSummarySlice";
import { useSelector } from "react-redux";

const CreditsTransferredCard = (props) => {
  const { displayFilterTime } = props;
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const creditDetails = useSelector((state) => getTotalCreditBalance(state));
  const creditTransferedTo =
    fetchUserRoleType === MD_USER_ROLE_TYPE ? SUB_DISTRIBUTOR : RETAILER;
  return (
    <>
      {creditDetails && (
        <Box
          data-testid="creditsTransferredCard"
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #7070704D",
            borderRadius: "8px",
            height: { sm: "80px", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {displayFilterTime && (
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Box
                component={"span"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: " #EDEEFE 0% 0% no-repeat padding-box",
                  borderBottomLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  fontFamily: "BukraRegular",
                  color: "#181D25",
                  flexGrow: 0.5,
                  fontSize: "12px",
                  p: "2px"
                }}
                className="filterName"
              >
                {displayFilterTime}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              p: 1,
              pl: 1.5,
              pb: 1.2
            }}
          >
            <Box
              sx={{
                display: "flex"
              }}
            >
              <img src={creditImg} alt="sub" height={"45px"}></img>
            </Box>
            <ThemeProvider theme={ToolTipThemeWithBG}>
              <Tooltip
                title={`${TOOLTIP_CREDIT_TRANSFERED} ${creditTransferedTo}s`}
                placement="top"
                arrow
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ml: 1
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      pt: 0.5,
                      fontFamily: "BukraMedium",
                      fontSize: "13px",
                      letterSpacing: "0px"
                    }}
                  >
                    {creditDetails.credits_transferred}
                  </Box>

                  <Box
                    component="div"
                    sx={{
                      pt: "5px",
                      color: "#282828",
                      fontFamily: "BukraRegular",
                      fontSize: { lg: "12px", xs: "11px" },
                      letterSpacing: "0px"
                    }}
                  >
                    {creditDetails.credits_transferred_label}
                  </Box>
                </Box>
              </Tooltip>
            </ThemeProvider>
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(CreditsTransferredCard);
