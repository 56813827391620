import React, { useEffect, useRef, useState } from "react";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  ADDRESS,
  AREA,
  CANCEL,
  CITY,
  COMPANY,
  COUNTRY,
  EMAIL,
  FIRST,
  LAST,
  MOBILE,
  NAME,
  POSTAL_CODE,
  REGISTER,
  RETAILER_ONBOARDING,
  RETAILER_REGISTERATION_AWAITING_APPROVAL,
  TOAST_AWAIT,
  TOAST_ERROR,
  IS_REQUIRED,
  SELECT_CITY,
  ALPHABETS_ARABIC,
  MAX_POSTAL_CODE_LENGTH,
  NAME_MAX_LENGTH,
  MAX_COMPANY_LENGTH,
  COMPANY_NAME_ERR_TXT,
  VERIFY_MOBILE_NUMBER,
  SOMETHING_WENT_WRONG,
  RESEND_CODE_TIME,
  VERIFY_EMAIL_TXT,
  MAX_LENGTH_50,
  ENTER_VERIFICATION_TEXT,
  ENTER_EMAIL_VERIFICATION_TEXT,
  OTP_GENERATED_SUCCESS_DESCRIPTION_EMAIL_RT,
  OTP_GENERATED_SUCCESS_DESCRIPTION_MOBILE_RT,
  LAST_NAME_ERROR
} from "../../constant/Constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  Stack,
  TextField
} from "@mui/material";

import "./RetailerOnboarding.scss";
import {
  validLastName,
  validateEmail,
  validateRegisterMobileNumber,
  onlyNumber,
  updatePageVisit,
  whiteSpaceInWord,
  validFirstName,
  validCompanyName,
  successToastMessage,
  convertMStoS,
  failureToastMessage
} from "../../constant/Utils";
import { useNavigate, useLocation } from "react-router-dom";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import {
  generateUserOtpDetails,
  getCityList,
  postRetailerData,
  validateUserOtpDetails
} from "../../services/user.services";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import RetailerOtpVerificationPage from "../retailerOtpVerificationPage/RetailerOtpVerificationPage";

const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#202020",
          fontSize: "14px",
          letterSpacing: "0px",
          height: "44px",
          ":focus": {
            background: "#EDF4F6 0% 0% no-repeat padding-box",
            color: "#202020",
            opacity: 1
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px",
          p: 0
        }
      }
    }
  }
});

/**
 * Renders the Retailer Registration form UI.
 * @returns {component} the Retailer Registration form UI Component.
 */
const RetailerOnboarding = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const [retailerCompanyName, setRetailerCompanyName] = useState("");
  const [retailerFirstName, setFirstName] = useState("");
  const [retailerLastName, setRetailerLastName] = useState("");
  const [retailerEmail, setRetailerEmail] = useState("");
  const [retailerEmailError, setRetailerEmailError] = useState("");
  const [retailerMobileNumber, setMobileNumber] = useState("");
  const [retailerCity, setRetailerCity] = useState("");
  const [retailerArea, setRetailerArea] = useState("");
  const [retailerAddress, setRetailerAddress] = useState("");
  const [retailerPostalCode, setRetailerPostalCode] = useState("");
  const [cityList, setCityList] = useState([]);
  const [retailerCompanyNameErr, setRetailerCompanyNameErr] = useState("");
  const [retailerFirstNameErr, setRetailerFirstNameErr] = useState("");
  const [retailerLastNameErr, setRetailerLastNameErr] = useState("");
  const [retailerMobileErr, setRetailerMobileErr] = useState("");
  const [retailerCityErr, setRetailerCityErr] = useState("");
  const [mobileFocus, setMobileFocus] = useState(false);
  const [loader, setLoader] = useState(false);

  const [mobileOtpVerified, setMobileOtpVerified] = useState(false);
  const [showMobileOTPErrorPop, setShowMobileOTPErrorPop] = useState(false);
  const [mobileOTPExpireTime, setMobileOTPExpireTime] = useState();
  const [mobilePayload, setMobilePayload] = useState("");
  const [mobileOtpPopupOpen, setMobileOtpPopupOpen] = useState(false);
  const [mobileErrorInfo, setMobileErrorInfo] = useState("");
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [showEmailOTPErrorPop, setShowEmailOTPErrorPop] = useState(false);
  const [emailOTPExpireTime, setEmailOTPExpireTime] = useState();
  const [emailPayload, setEmailPayload] = useState("");
  const [emailOtpPopupOpen, setEmailOtpPopupOpen] = useState(false);
  const [emailErrorInfo, setEmailErrorInfo] = useState("");
  const cityRef = useRef(null);
  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const [transformOrigin, setTransformOrigin] = useState({
    vertical: "top",
    horizontal: "center"
  });
  /**
   *@constant {string} channgelPartnerCode -  Fetch the Channel Partnercode of the loggedIn user.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  const config = useSelector((state) => getConfigDetails(state));
  /**
   * Sets the Page Visits count
   */

  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * Preloads the Company list based on the country code.
   */

  useEffect(() => {
    if (config.server) {
      country.countryCode &&
        getCityList(config.server, country.countryCode).then((res) => {
          if (res && res.data && res.data[0].cityList.length !== 0) {
            setCityList(res.data[0].cityList);
            setRetailerCityErr("");
          } else {
            setCityList([]);
            setRetailerCity("");
          }
        });
    }
  }, [country.countryCode, config.server]);

  /**
   * Handles the Change in Retailer's company Name
   * @function handleChangeRetailerCompanyName - Performs action on change of Retailer's company name.
   * @param {object} e - Compoany Select option pointer event.
   */
  const handleChangeRetailerCompanyName = (e) => {
    setRetailerCompanyName(e.target.value);
    setRetailerCompanyNameErr("");
  };

  /**
   * Handles the change in Retailer's first name.
   * @function handleChangeRetailerFirstName - Performs action on change of Retailer's firstname.
   * @param {object} e - Retailer First name input event object.
   */
  const handleChangeRetailerFirstName = (e) => {
    const value = e.target.value.replace(/\s+/g, " ").trimStart();
    setFirstName(value);
    setRetailerFirstNameErr("");
  };

  /**
   * Handles the change in Retailer's last name.
   * @function handleChangeRetailerLastName - Performs action on change of Retailer's lastname.
   * @param {object} e - Retailer last name input event object.
   */
  const handleChangeRetailerLastName = (e) => {
    const value = e.target.value.replace(/\s+/g, " ").trimStart();
    setRetailerLastName(value);
    setRetailerLastNameErr("");
  };

  /**
   * Handles the change in Retailer's Email.
   * @function handleChangeRetailerEmail - Performs action on change of Retailer's email.
   * @param {object} e - Retailer Email input event object.
   */
  const handleChangeRetailerEmail = (e) => {
    setRetailerEmail(e.target.value);
    setRetailerEmailError("");
  };

  /**
   * Handles the change in Retailer's Mobile Number.
   * @function handleChangeRetailerMobileNumber - Performs action on change of Retailer's Mobile Number.
   * @param {object} e - Retailer Mobile Number input event object.
   */
  const handleChangeRetailerMobileNumber = (e) => {
    const { value } = e.target;
    if (onlyNumber(value)) {
      if (value.length <= country.maxSizeOfMobileNumber) {
        setMobileNumber(value);
        setRetailerMobileErr("");
      }
    }
  };

  /**
   * Handles the change in Retailer's City.
   * @function handleChangeRetailerCity - Performs action on change of Retailer's City.
   * @param {object} e - Retailer City input event object.
   */
  const handleChangeRetailerCity = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setRetailerCityErr(`${e.target.name} ${IS_REQUIRED}`);
    }
    setRetailerCity(e.target.value);
    setRetailerCityErr("");
  };

  /**
   * Handles the change in Retailer's Area.
   * @function handleChangeRetailerArea - Performs action on change of Retailer's Area.
   * @param {object} e - Retailer Area input event object.
   */
  const handleChangeRetailerArea = (e) => {
    setRetailerArea(e.target.value);
  };

  /**
   * Handles the change in Retailer's Address.
   * @function handleChangeRetailerAddress - Performs action on change of Retailer's Address.
   * @param {object} e - Retailer Address input event object.
   */
  const handleChangeRetailerAddress = (e) => {
    setRetailerAddress(e.target.value);
  };

  /**
   * Handles the change in Retailer's Postal Code.
   * @function handleChangeRetailerPostalCode - Performs action on change of Retailer's Postal Code.
   * @param {object} e - Retailer Postal Code input event object.
   */
  const handleChangeRetailerPostalCode = (e) => {
    if (onlyNumber(e.target.value)) {
      setRetailerPostalCode(e.target.value);
    }
  };

  /**
   * Performs operation to set the Retailer Registration button to be Enabled or disabled based on the Form values filled.
   * @function isRegisterDisable - Checks whether to enable or disable the Retailer Registration submit button.
   * @returns {boolean} true or false to make the Retailer Registration confirm button disabled or enabled.
   */
  const isRegisterDisable = () => {
    const valid =
      retailerCompanyName === "" ||
      retailerFirstName === "" ||
      retailerLastName === "" ||
      retailerEmail === "" ||
      retailerMobileNumber === "" ||
      retailerCity === "" ||
      retailerEmailError !== "" ||
      retailerMobileErr !== "" ||
      retailerFirstNameErr !== "" ||
      retailerLastNameErr !== ""
        ? true
        : false;
    return valid;
  };

  /**
   * Performs operation to reset the form.
   * @function resetForm - Resets the Retailer Registration form.
   */
  const resetForm = () => {
    navigateTo("/retailers");
    setRetailerCompanyName("");
    setFirstName("");
    setRetailerLastName("");
    setRetailerEmail("");
    setMobileNumber("");
    setRetailerCity("");
    setRetailerArea("");
    setRetailerAddress("");
    setRetailerPostalCode("");
  };

  /**
   * Handles the Validation for the Company
   * @function handleRetailerCompanyNameValidation - Handles the Validation of Company Name.
   * @param {object} e  - Company Name Select option event object
   */
  const handleRetailerCompanyNameValidation = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setRetailerCompanyNameErr(`${e.target.name} ${IS_REQUIRED}.`);
    } else {
      if (!validCompanyName(whiteSpaceInWord(e.target.value))) {
        setRetailerCompanyNameErr(`${COMPANY_NAME_ERR_TXT}`);
      } else {
        setRetailerCompanyNameErr("");
      }
    }
  };

  /**
   * Handles the Validation for the Retailer's first name.
   * @function handleRetailerFirstNameValidation - Performs Validation on First name input change.
   * @param {object} e  - First name input event object.
   */
  const handleRetailerFirstNameValidation = (e) => {
    if (retailerFirstName === "" || retailerFirstName === null) {
      setRetailerFirstNameErr(`${e.target.name} ${IS_REQUIRED}.`);
    } else {
      if (!validFirstName(whiteSpaceInWord(retailerFirstName))) {
        setRetailerFirstNameErr(`${FIRST} ${NAME} ${ALPHABETS_ARABIC}`);
      } else {
        setRetailerFirstNameErr("");
      }
    }
  };

  /**
   * Handles the Validation for the Retailer's Last name.
   * @function handleRetailerLastNameValidation - Performs Validation on Last name input change.
   * @param {object} e  - Last name input event object.
   */
  const handleRetailerLastNameValidation = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setRetailerLastNameErr(`${e.target.name} ${IS_REQUIRED}.`);
    } else {
      if (!validLastName(whiteSpaceInWord(e.target.value))) {
        setRetailerLastNameErr(`${LAST} ${NAME} ${LAST_NAME_ERROR}`);
      } else {
        setRetailerLastNameErr("");
      }
    }
  };

  /**
   * Handles the Validation for the Retailer's Email.
   * @function handleEmailValidation - Performs Validation on Email input change.
   * @param {object} e  - Email input event object.
   */
  const handleEmailValidation = () => {
    setRetailerEmailError(validateEmail(retailerEmail));
  };

  /**
   * Handles the Validation for the Retailer's Mobile no..
   * @function handleRetailerMobileValidation - Performs Validation on Mobile no. input change.
   * @param {object} e  - Mobile no. input event object.
   */
  const handleRetailerMobileValidation = (e) => {
    setMobileFocus(false);
    setRetailerMobileErr(
      validateRegisterMobileNumber(retailerMobileNumber, country)
    );
  };

  /**
   * Handles the Validation for the Retailer's City.
   * @function handleCityValidation - Performs Validation on City input change.
   * @param {object} e  - City input event object.
   */
  const handleCityValidation = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setRetailerCityErr(`${e.target.name} ${IS_REQUIRED}.`);
    } else {
      setRetailerCityErr("");
    }
  };

  /**
   * Handles the Retailer registration operation on successful filling of the form.
   * @function handleRegisterRetailerDetails - Performs the Retailer's registration process.
   */
  const handleRegisterRetailerDetails = () => {
    setLoader(true);
    let mobileWithCode =
      `+${country.countryPhoneCode}${retailerMobileNumber}`.trim();
    const retailersData = {
      channelPartnerFirstName: retailerFirstName.trim(),
      channelPartnerLastName: retailerLastName.trim(),
      channelPartnerMobileNumber: mobileWithCode,
      channelPartnerEmail: retailerEmail.trim(),
      channelPartnerUsername: retailerEmail.trim(),
      channelPartnerCity: retailerCity.trim(),
      channelPartnerAddressInformation: retailerAddress.trim(),
      companyName: retailerCompanyName.trim(),
      channelPartnerArea: retailerArea.trim(),
      postalCode: retailerPostalCode.trim()
    };
    postRetailerData(config.server, retailersData)
      .then((res) => {
        if (res && res.status === 200) {
          let toastDetails = {
            toastTitle: RETAILER_REGISTERATION_AWAITING_APPROVAL,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_AWAIT,
            toastTextclr: "#F49009",
            toastBgclr: "#FFF2DE ",
            toastBorderclr: "#F49009"
          };
          dispatch(updateToastMessage(toastDetails));
          navigateTo("/retailers");
        } else {
          let toastDetails = {
            toastTitle: res.data.message,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_ERROR,
            toastTextclr: "#FF2A2A",
            toastBgclr: "#FDE2E2 ",
            toastBorderclr: "#FF2A2A"
          };
          dispatch(updateToastMessage(toastDetails));
        }
      })

      .catch((err) => err)
      .finally(() => setLoader(false));
  };

  /**
   * Sets the focus to Mobile number input field.
   * @function handleFocusMobileNumber - Set focus on Mobile no. input.
   */
  const handleFocusMobileNumber = () => {
    setMobileFocus(true);
  };

  /**
   * Renders the boreder color of the input field based on the Mobile Validation result.
   * @function mobileNumbeBorderColor - Sets the Border color for mobile input based on the mobile validation.
   * @returns {style} the border color for the Mobile input field.
   */
  const mobileNumbeBorderColor = () => {
    let borderColor = "#e8e8e8";
    if (retailerMobileErr) {
      borderColor = "#DE0909";
    } else if (mobileFocus) {
      borderColor = "#0f57cb";
    }
    return `1px solid ${borderColor}`;
  };

  /**
   * Handles the Toast Message display
   * @function showMobileOTPToastCallback - Performs action to hide toast message.
   */
  const showMobileOTPToastCallback = () => {
    setShowMobileOTPErrorPop(false);
  };

  /**
   * Handles the display of the toast Message.
   * @function showMobilePopup - Display the Popup element.
   * @param {string} message - Error nessage
   */
  const showMobilePopup = (message) => {
    setShowMobileOTPErrorPop(true);
    setMobileErrorInfo(message ? message : SOMETHING_WENT_WRONG);
  };

  /**
   * Handles the close operation of Dialog box.
   * @function closeDialog - Close the Dialog Box Popup.
   */
  const closeMobileDialog = () => {
    setShowMobileOTPErrorPop(false);
    setMobileOtpPopupOpen(false);
  };

  /**
   * Handles the mobile Verification.
   * @function handleVerifyRetailerUser - Verify the Entered Mobile Number
   */
  const handleVerifyRetailerUserMobileNumber = () => {
    const MobileValue = `+${country.countryPhoneCode}${retailerMobileNumber}`;
    const userData = {
      mobileNumber: MobileValue.trim()
    };
    setMobilePayload(userData);
    setMobileOtpVerified(false);
    generateUserOtpDetails(config.server, userData).then((response) => {
      if (response && response.status === 200) {
        let toastDetails = successToastMessage(
          OTP_GENERATED_SUCCESS_DESCRIPTION_MOBILE_RT
        );
        dispatch(updateToastMessage(toastDetails));
        setMobileOTPExpireTime(convertMStoS(response.data.validFor));
        setMobileOtpPopupOpen(true);
      } else {
        const message = response.data.message
          ? response.data.message
          : SOMETHING_WENT_WRONG;
        let toastDetails = failureToastMessage(message, "");
        dispatch(updateToastMessage(toastDetails));
      }
    });
  };

  const handleOtpVerificationMobileNumber = (value) => {
    const MobileValue = `+${country.countryPhoneCode}${retailerMobileNumber}`;
    const userData = {
      mobileNumber: MobileValue.trim(),
      otpKey: Number(value)
    };
    setMobileOtpVerified(false);
    validateUserOtpDetails(config.server, userData).then((response) => {
      if (response && response.status === 200) {
        setMobileOtpPopupOpen((prev) => !prev);
        setMobileOtpVerified(true);
      } else {
        showMobilePopup(response.data.message);
      }
    });
  };

  // -------------------------------------------------- Email______________________

  /**
   * Handles the Toast Message display
   * @function showEmailOTPToastCallback - Performs action to hide toast message.
   */
  const showEmailOTPToastCallback = () => {
    setShowEmailOTPErrorPop(false);
  };

  /**
   * Handles the display of the toast Message.
   * @function showEmailPopup - Display the Popup element.
   * @param {string} message - Error nessage
   */
  const showEmailPopup = (message) => {
    setShowEmailOTPErrorPop(true);
    setEmailErrorInfo(message ? message : SOMETHING_WENT_WRONG);
  };

  /**
   * Handles the close operation of Dialog box.
   * @function closeEmailDialog - Close the Dialog Box Popup.
   */
  const closeEmailDialog = () => {
    setShowEmailOTPErrorPop(false);
    setEmailOtpPopupOpen(false);
  };

  /**
   * Handles the mobile Verification.
   * @function handleVerifyRetailerUserEmail - Verify the Entered Mobile Number
   */
  const handleVerifyRetailerUserEmail = () => {
    const userData = {
      email: retailerEmail.trim()
    };
    setEmailPayload(userData);
    setEmailOtpVerified(false);
    generateUserOtpDetails(config.server, userData).then((response) => {
      if (response && response.status === 200) {
        let toastDetails = successToastMessage(
          OTP_GENERATED_SUCCESS_DESCRIPTION_EMAIL_RT
        );
        dispatch(updateToastMessage(toastDetails));
        setEmailOTPExpireTime(convertMStoS(response.data.validFor));
        setEmailOtpPopupOpen(true);
      } else {
        const message =
          response && response.data && response.data.message
            ? response.data.message
            : SOMETHING_WENT_WRONG;
        let toastDetails = failureToastMessage(message, "");
        dispatch(updateToastMessage(toastDetails));
      }
    });
  };

  const handleOtpVerificationEmail = (value) => {
    const userData = {
      email: retailerEmail.trim(),
      otpKey: Number(value)
    };
    setEmailOtpVerified(false);
    validateUserOtpDetails(config.server, userData).then((response) => {
      if (response && response.status === 200) {
        setEmailOtpPopupOpen((prev) => !prev);
        if (!response.data.accountId) {
          setEmailOtpVerified(true);
        }
      } else {
        showEmailPopup(response.data.message);
      }
    });
  };

  const inputFieldDisable = () => {
    return !emailOtpVerified || !mobileOtpVerified;
  };
  const handleToggle = (event) => {
    if (cityRef.current) {
      const selectBox = cityRef.current.getBoundingClientRect();
      const fitsBelow = selectBox.bottom + 200 <= window.innerHeight; // 200px as dropdown height
      if (fitsBelow) {
        setAnchorOrigin({ vertical: "bottom", horizontal: "center" });
        setTransformOrigin({ vertical: "top", horizontal: "center" });
      } else {
        setAnchorOrigin({ vertical: "top", horizontal: "center" });
        setTransformOrigin({ vertical: "bottom", horizontal: "center" });
      }
    }
  };
  return (
    <Paper
      data-testid="retailerOnboarding"
      elevation={3}
      className="retailerOnboarding"
      sx={{ p: { xs: 1, lg: 0 } }}
    >
      <Toolbar sx={{ px: { lg: 3.8, xs: 0 } }}>
        <Typography
          data-testid="retailerOnboardingHeading"
          sx={{
            fontFamily: "BukraMedium",
            color: " #282828",
            fontSize: "15px",
            letterSpacing: "0px"
          }}
        >
          {RETAILER_ONBOARDING}
        </Typography>
      </Toolbar>
      <Divider></Divider>
      <Box
        sx={{
          mx: { lg: 12 },
          mt: { lg: 2.7, xs: 2 },
          pb: 2,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {loader && <OverlayLoader />}
        <ThemeProvider theme={inputTheme}>
          <Stack spacing={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: { xs: "center", lg: "flex-start" },
                flexDirection: { xs: "column", lg: "row" }
              }}
            >
              <FormControl
                size="small"
                fullWidth
                sx={{ minWidth: 120, width: { xs: "100%" } }}
              >
                <label className="inputLabel">
                  {EMAIL} <span className="asterisk">*</span>
                </label>
                <TextField
                  disabled={emailOtpVerified}
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  inputProps={{
                    "data-testid": "email",
                    maxLength: MAX_LENGTH_50
                  }}
                  size="medium"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Email"
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: retailerEmailError
                        ? "#DE0909 !important"
                        : "#e8e8e8"
                    },
                    backgroundColor: "#edf4f6",
                    fontSize: "14px"
                  }}
                  type="email"
                  value={retailerEmail}
                  name={EMAIL}
                  onBlur={() => handleEmailValidation()}
                  onChange={(e) => handleChangeRetailerEmail(e)}
                ></TextField>
                {retailerEmailError && (
                  <FormHelperText
                    sx={{ color: "#DE0909" }}
                    className="errorFormHelperText"
                  >
                    {retailerEmailError}{" "}
                  </FormHelperText>
                )}
              </FormControl>
              <Box
                data-testid="verifyEmail"
                className="verifyText"
                sx={{
                  width: { xs: "fit-content", lg: "245px" },
                  top: { xs: "10px", lg: "44px" },
                  pl: 3,
                  opacity:
                    !retailerEmail || retailerEmailError || emailOtpVerified
                      ? 0.6
                      : 1,
                  pointerEvents:
                    !retailerEmail || retailerEmailError || emailOtpVerified
                      ? "none"
                      : "auto"
                }}
                onClick={() => handleVerifyRetailerUserEmail()}
              >
                {VERIFY_EMAIL_TXT}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: { xs: "center", lg: "flex-start" },
                flexDirection: { xs: "column", lg: "row" }
              }}
            >
              <FormControl
                size="small"
                fullWidth
                sx={{ minWidth: 120, width: { xs: "100%" } }}
              >
                <label className="inputLabel">
                  {MOBILE} <span className="asterisk">*</span>
                </label>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                    border: mobileNumbeBorderColor(),
                    backgroundColor: "#edf4f6",
                    height: "44px"
                  }}
                >
                  <Box
                    sx={{
                      height: "inherit",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80px",
                      color: "#282828",
                      fontSize: "13px",
                      fontFamily: "BukraRegular",
                      background: " #7B7D801A 0% 0% no-repeat padding-box"
                    }}
                  >
                    {"+" + country.countryPhoneCode + " " + country.countryCode}
                  </Box>

                  <InputBase
                    disabled={mobileOtpVerified}
                    autoComplete="off"
                    inputProps={{ "data-testid": "mobileNumber" }}
                    sx={{ ml: 1, flex: 1, pt: 0.5 }}
                    placeholder="Enter Number"
                    value={retailerMobileNumber}
                    name={MOBILE}
                    onFocus={() => handleFocusMobileNumber()}
                    onChange={(e) => handleChangeRetailerMobileNumber(e)}
                    onBlur={(e) => handleRetailerMobileValidation(e)}
                  />
                </Box>

                {retailerMobileErr && (
                  <FormHelperText
                    sx={{ color: "#DE0909" }}
                    className="errorFormHelperText"
                  >
                    {retailerMobileErr}{" "}
                  </FormHelperText>
                )}
              </FormControl>

              <Box
                className="verifyText"
                sx={{
                  width: { xs: "fit-content", lg: "245px" },
                  top: { xs: "10px", lg: "44px" },
                  pl: 3,
                  opacity:
                    !retailerMobileNumber ||
                    retailerMobileErr ||
                    mobileOtpVerified
                      ? 0.6
                      : 1,
                  pointerEvents:
                    !retailerMobileNumber ||
                    retailerMobileErr ||
                    mobileOtpVerified
                      ? "none"
                      : "auto"
                }}
                onClick={() => handleVerifyRetailerUserMobileNumber()}
                data-testid="verifyMobile"
              >
                {VERIFY_MOBILE_NUMBER}
              </Box>
            </Box>
            <FormControl size="small" fullWidth>
              <label className="inputLabel">
                {COMPANY} {NAME} <span className="asterisk">*</span>
              </label>

              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                disabled={inputFieldDisable()}
                inputProps={{
                  "data-testid": "companyNameSelect",
                  maxLength: MAX_COMPANY_LENGTH
                }}
                size="medium"
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                value={retailerCompanyName}
                name={`${COMPANY} ${NAME}`}
                onChange={(e) => handleChangeRetailerCompanyName(e)}
                onBlur={(e) => handleRetailerCompanyNameValidation(e)}
              ></TextField>
              {retailerCompanyNameErr && (
                <FormHelperText
                  sx={{ color: "#DE0909" }}
                  className="errorFormHelperText"
                >
                  {retailerCompanyNameErr}{" "}
                </FormHelperText>
              )}
            </FormControl>
            <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
              <FormControl size="small" fullWidth>
                <label className="inputLabel">
                  {FIRST} {NAME} <span className="asterisk">*</span>
                </label>
                <TextField
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={inputFieldDisable()}
                  inputProps={{ "data-testid": "firstName" }}
                  size="medium"
                  variant="outlined"
                  placeholder="Enter First Name"
                  fullWidth
                  maxLength={NAME_MAX_LENGTH}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: retailerFirstNameErr
                        ? "#DE0909 !important"
                        : "#e8e8e8"
                    },
                    backgroundColor: "#edf4f6",
                    fontSize: "14px"
                  }}
                  value={retailerFirstName}
                  name={`${FIRST} ${NAME}`}
                  onChange={(e) => handleChangeRetailerFirstName(e)}
                  onBlur={(e) => handleRetailerFirstNameValidation(e)}
                ></TextField>
                {retailerFirstNameErr && (
                  <FormHelperText
                    sx={{ color: "#DE0909" }}
                    className="errorFormHelperText"
                  >
                    {retailerFirstNameErr}{" "}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl size="small" fullWidth>
                <label className="inputLabel">
                  {LAST} {NAME} <span className="asterisk">*</span>
                </label>
                <TextField
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={inputFieldDisable()}
                  inputProps={{ "data-testid": "lastName" }}
                  size="medium"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Last Name"
                  maxLength={NAME_MAX_LENGTH}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: retailerLastNameErr
                        ? "#DE0909 !important"
                        : "#e8e8e8"
                    },
                    backgroundColor: "#edf4f6",
                    fontSize: "14px"
                  }}
                  value={retailerLastName}
                  name={`${LAST} ${NAME}`}
                  onChange={(e) => handleChangeRetailerLastName(e)}
                  onBlur={(e) => handleRetailerLastNameValidation(e)}
                ></TextField>
                {retailerLastNameErr && (
                  <FormHelperText
                    sx={{ color: "#DE0909" }}
                    className="errorFormHelperText"
                  >
                    {retailerLastNameErr}{" "}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
              <label htmlFor="demo-select-small" className="inputLabel">
                {COUNTRY} <span className="asterisk">*</span>
              </label>
              <TextField
                autoComplete="off"
                inputProps={{ "data-testid": "country" }}
                size="medium"
                disabled
                variant="outlined"
                fullWidth
                placeholder="Select Country"
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                type="text"
                value={country.countryName ? country.countryName : ""}
                name={COUNTRY}
              ></TextField>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
              <label htmlFor="demo-select-small" className="inputLabel">
                {CITY} <span className="asterisk">*</span>
              </label>

              <Select
               ref={cityRef}
                data-testid="citySelect"
                labelId="demo-select-small"
                id="demo-select-small"
                value={retailerCity}
                inputProps={{ "data-testid": "cityTestInput" }}
                disabled={inputFieldDisable()}
                onOpen={handleToggle}
                onChange={(e) => handleChangeRetailerCity(e)}
                onBlur={(e) => handleCityValidation(e)}
                name={CITY}
                displayEmpty
                renderValue={(retailerCityLabel) => {
                  if (!retailerCityLabel) {
                    return (
                      <Typography
                        sx={{
                          letterSpacingpacing: "0px",
                          color: "#282828",
                          opacity: 0.5,
                          fontSize: "13px",
                          fontFamily: "BukraRegular"
                        }}
                      >
                        {`${SELECT_CITY}`}
                      </Typography>
                    );
                  }
                  return retailerCity;
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: retailerCityErr
                      ? "#DE0909 !important"
                      : "#e8e8e8"
                  },
                  backgroundColor: "#edf4f6",
                  "&.MuiPaper-root": {
                    backgroundColor: "green"
                  }
                }}
                MenuProps={{
                  autoFocus: false,
                  anchorOrigin: anchorOrigin,
                  transformOrigin: transformOrigin,
                  PaperProps: {
                    style: {
                      maxHeight: 190,
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      marginTop:
                        anchorOrigin.vertical === "top" ? "-5px" : "3px"
                    
                    }
                  }
                }}
              >
                {cityList &&
                  cityList.map((city, index) => {
                    return (
                      <MenuItem
                        data-testid={city.cityName}
                        key={city.cityName}
                        value={city.cityName}
                        sx={{
                          ...muiMenuItemStyles
                        }}
                      >
                        {city.cityName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {retailerCityErr && (
                <FormHelperText
                  sx={{ color: "#DE0909" }}
                  className="errorFormHelperText"
                >
                  {retailerCityErr}{" "}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl size="small" fullWidth>
              <label className="inputLabel">{AREA}</label>
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                disabled={inputFieldDisable()}
                inputProps={{ "data-testid": "area", maxLength: MAX_LENGTH_50 }}
                size="medium"
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                value={retailerArea}
                onChange={(e) => handleChangeRetailerArea(e)}
              ></TextField>
            </FormControl>
            <FormControl size="small" fullWidth>
              <label className="inputLabel">{ADDRESS}</label>
              <TextField
                autoComplete="off"
                disabled={inputFieldDisable()}
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "address",
                  maxLength: MAX_LENGTH_50
                }}
                size="medium"
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                value={retailerAddress}
                onChange={(e) => handleChangeRetailerAddress(e)}
              ></TextField>
            </FormControl>
            <FormControl size="small" fullWidth>
              <label className="inputLabel">{POSTAL_CODE}</label>
              <TextField
                autoComplete="off"
                disabled={inputFieldDisable()}
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "postalCode",
                  maxLength: MAX_POSTAL_CODE_LENGTH
                }}
                size="medium"
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px"
                }}
                value={retailerPostalCode}
                onChange={(e) => handleChangeRetailerPostalCode(e)}
              ></TextField>
            </FormControl>
          </Stack>
        </ThemeProvider>
        <Stack
          spacing={2}
          direction={{ xs: "column", lg: "row" }}
          justifyContent="flex-end"
          sx={{ pt: 4, pb: 2 }}
        >
          <Button
            data-testid="cancelClick"
            variant="outlined"
            sx={{
              borderRadius: "20px",
              borderColor: "#4079d5",
              fontSize: "15px",
              textTransform: "none",
              fontFamily: "BukraRegular",
              letterSpacing: 0,
              color: "#4079d5",
              minWidth: "180px",
              cursor: "pointer"
            }}
            onClick={() => resetForm()}
          >
            {CANCEL}
          </Button>
          <Button
            data-testid="registerClick"
            variant="outlined"
            onClick={handleRegisterRetailerDetails}
            disabled={isRegisterDisable()}
            sx={{
              borderRadius: "20px",
              fontSize: "15px",
              textTransform: "none",
              fontFamily: "BukraRegular",
              letterSpacing: 0,
              cursor: "pointer",
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              "&.Mui-disabled": {
                background:
                  "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                color: "#ffffff",
                opacity: 0.5
              },

              minWidth: "180px"
            }}
          >
            {REGISTER}
          </Button>
        </Stack>
      </Box>
      {mobileOtpPopupOpen && (
        <Dialog open={mobileOtpPopupOpen}>
          <RetailerOtpVerificationPage
            payload={mobilePayload}
            handleConfirmVerification={handleOtpVerificationMobileNumber}
            errorInfo={mobileErrorInfo}
            showErrorPop={showMobileOTPErrorPop}
            hidePopup={showMobileOTPToastCallback}
            showPopup={showMobilePopup}
            isPopup={true}
            closeDialog={closeMobileDialog}
            codeExpireTime={mobileOTPExpireTime}
            resendCodeTime={RESEND_CODE_TIME}
            headerLabel={ENTER_VERIFICATION_TEXT}
          ></RetailerOtpVerificationPage>
        </Dialog>
      )}
      {emailOtpPopupOpen && (
        <Dialog open={emailOtpPopupOpen}>
          <RetailerOtpVerificationPage
            payload={emailPayload}
            handleConfirmVerification={handleOtpVerificationEmail}
            errorInfo={emailErrorInfo}
            showErrorPop={showEmailOTPErrorPop}
            hidePopup={showEmailOTPToastCallback}
            showPopup={showEmailPopup}
            isPopup={true}
            closeDialog={closeEmailDialog}
            codeExpireTime={emailOTPExpireTime}
            resendCodeTime={RESEND_CODE_TIME}
            headerLabel={ENTER_EMAIL_VERIFICATION_TEXT}
          ></RetailerOtpVerificationPage>
        </Dialog>
      )}
    </Paper>
  );
};

export default RetailerOnboarding;
