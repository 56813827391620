import React from "react";
import CustomBreadcrumbs from "../customComponents/customBreadcrumbs/CustomBreadcrumbs";
import Notification from "../notification/Notification";
import ProfileMenu from "../profileMenu/ProfileMenu";
import mbcBlueLogo from "./../../assets/images/mbcnewlogomobile.svg";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getUserEmail,
  getUserName
} from "../../stateManagement/reducers/LoginSlice";
import "./NavHeaderBar.scss";
import { getPushNotificationEnableStatus } from "../../stateManagement/reducers/PushNotificationSlice";
import PushNotificationEnablePopup from "../pushNotificationEnablePopup/PushNotificationEnablePopup";

/**
 * Renders the Headerbar Navigation UI component
 * @param {object} props  - Prop elements passed to the Header Navigation bar of the Page to display the data.
 * @returns {component} the UI component  for Navigation Headerbar containing the required elements.
 */
const NavHeaderBar = (props) => {
  const { handleDrawerToggle } = props;
  const userEmail = useSelector((state) => getUserEmail(state));
  const userName = useSelector((state) => getUserName(state));
  const pushNotificationEnableStatus = useSelector((state) =>
    getPushNotificationEnableStatus(state)
  );
  const isFcmTokenPeresent =
    pushNotificationEnableStatus && !sessionStorage.getItem("fcmToken");
  /**
   * Handles the drawerToggle on Smaller Screens
   * @function openSideNavbarMenu - Performs action to toggle the Sidebar Menu on Smaller Screens.
   */
  const openSideNavbarMenu = () => {
    handleDrawerToggle();
  };
  return (
    <AppBar
      position="sticky"
      elevation={2}
      sx={{
        backgroundColor: "white",
        color: "#000000",
        background: "#FFFFFF 0% 0% no-repeat padding-box"
      }}
      data-testid="navHeaderBar"
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box
          component={"div"}
          sx={{
            display: "flex",
            alignItems: { lg: "center", xs: "flex-start" },
            flexDirection: { xs: "column", lg: "row" }
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={() => openSideNavbarMenu()}
            sx={{ display: { lg: "none" } }}
            data-testid="navIcon"
          >
            <img src={mbcBlueLogo} alt="logo" height="51px" width="90px"></img>
          </IconButton>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pb: { xs: 1, lg: 0 }
            }}
          >
            <Box
              sx={{ display: { lg: "none", xs: "flex" } }}
              className="hamburger-menu"
              data-testid="hamburger"
              onClick={() => openSideNavbarMenu()}
            >
              <div className="hamburger-line"></div>
              <div className="hamburger-line"></div>
              <div className="hamburger-line"></div>
            </Box>
            <Typography
              sx={{ ml: { xs: 2, lg: 0 } }}
              variant="h6"
              noWrap
              component="div"
            >
              <CustomBreadcrumbs></CustomBreadcrumbs>
            </Typography>
          </Box>
        </Box>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Box>
            {isFcmTokenPeresent && (
              <PushNotificationEnablePopup></PushNotificationEnablePopup>
            )}
            <Notification></Notification>
          </Box>
          <Box
            component={"div"}
            sx={{ display: { xs: "none", lg: "block" }, ml: 2 }}
          >
            <div className="userName">{userName}</div>
            <div className="userEmail">{userEmail}</div>
          </Box>
          <ProfileMenu></ProfileMenu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavHeaderBar;
