import React, { useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  getComparator,
  getSearchItems,
  getToday,
  tableSorting
} from "../../constant/Utils";
import { useSelector } from "react-redux";
import {
  getFilteredDateFromStore,
  getShowDateFilter
} from "../../stateManagement/reducers/DateFilterSlice";
import { ALLTIME_DATE, NO_DATA } from "../../constant/Constants";
import { getInventoryStocks } from "../../services/user.services";
import { availableFaultyStockTransferTableHeader } from "../../constant/TableHeaderConstants";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the Stock Transfer Table UI component.
 * @param {object} props - Props passed to the Stock transfer table component for rendering the data
 * @returns {component} the Stock Transfer Table UI component.
 */
const AvailableFaultyStockTransferTable = (props) => {
  const { tabStatus, search, selectSearchItem } = props;
  const [tableBodyDetails, setTableBodyDetails] = useState([]);
  const [searchTableBodyDetails, setSearchTableBodyDetails] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("count");
  const [loader, setLoader] = useState(false);
  const displayDateFilter = useSelector((state) => getShowDateFilter(state));
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Performs the Ascending and descending Operation on Table Items based on the Head cell selected.
   * @function handleRequestSort - Performs the Ascending or descending sorting action.
   * @param {event} _event - event object
   * @param {string} property - Sorting category
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * Handles the List of Inventory Stock items based on the Date range
   * @function getInventoryStockDetails - Fethces the Inventory Stock list based on the date range.
   */
  const getInventoryStockDetails = useCallback(() => {
    if (
      selectedDateFilter.startDate &&
      selectedDateFilter.endDate &&
      config.server
    ) {
      setLoader(true);
      const startDate = displayDateFilter
        ? selectedDateFilter.startDate
        : ALLTIME_DATE;
      const endDate = displayDateFilter
        ? selectedDateFilter.endDate
        : getToday();

      let data = {
        startDate: startDate,
        endDate: endDate,
        status: tabStatus
      };
      getInventoryStocks(config.server, data)
        .then((res) => {
          if (
            res &&
            res.status === 200 &&
            res.data &&
            res.data.status !== "NO_CONTENT"
          ) {
            if (res.data.channelPartnerInventoryDetails) {
              setTableBodyDetails(res.data.channelPartnerInventoryDetails);
              setSearchTableBodyDetails(
                res.data.channelPartnerInventoryDetails
              );
            } else {
              setTableBodyDetails([]);
              setSearchTableBodyDetails([]);
            }
          } else {
            setTableBodyDetails([]);
            setSearchTableBodyDetails([]);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [
    selectedDateFilter.startDate,
    selectedDateFilter.endDate,
    tabStatus,
    displayDateFilter,
    config.server
  ]);

  useEffect(() => {
    getInventoryStockDetails();
  }, [getInventoryStockDetails]);

  useEffect(() => {
    let filteredRows = searchTableBodyDetails;
    if (search) {
      filteredRows = getSearchItems(
        searchTableBodyDetails,
        selectSearchItem,
        search
      );
    }
    setTableBodyDetails(filteredRows);
  }, [searchTableBodyDetails, selectSearchItem, search]);

  return (
    <Box data-testid="stockTransferTable">
      <TableContainer
        sx={{
          maxHeight: 210,
          "&::-webkit-scrollbar": {
            width: { xs: 0, lg: "5px" }
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: { xs: "unset", lg: "#b1b1b1" }
          },
          scrollbarWidth: "none"
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {availableFaultyStockTransferTableHeader.map(
                (headCell, index) => (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    padding={"normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      borderBottom: "none",
                      paddingLeft: { lg: 10, xs: 1 }
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort("", headCell.id)}
                      sx={{
                        color: "#606060",
                        letterSpacing: "0px",
                        fontSize: "15px",
                        fontFamily: "BukraMedium",
                        width: { xs: index === 0 ? "80px" : "unset" }
                      }}
                      data-testid="sortBtn"
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {!loader &&
            tableBodyDetails &&
            tableBodyDetails.length > 0 &&
            orderBy && (
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                       rows.slice().sort(getComparator(order, orderBy)) */}
                {tableSorting(
                  tableBodyDetails,
                  getComparator(order, orderBy)
                ).map((item, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`${index + 1}`}
                      style={
                        index % 2
                          ? { background: "#FFFFFF" }
                          : { background: "#F7F9FC" }
                      }
                      sx={{
                        width: "50px",
                        paddingLeft: 3
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          paddingLeft: { lg: 10, xs: 1 },
                          width: { xs: "240px", md: "unset" }
                        }}
                      >
                        {item.hardwareMake}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          paddingLeft: { lg: 10, xs: 1 }
                        }}
                      >
                        {item.hardwareModelName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          paddingLeft: { lg: 10, xs: 1 }
                        }}
                      >
                        {item.count}
                      </TableCell>

                      {displayDateFilter && item.trasferDate && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            paddingLeft: { lg: 10, xs: 1 }
                          }}
                        >
                          {item.trasferDate}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
        </Table>
      </TableContainer>
      {!loader && tableBodyDetails.length === 0 && (
        <Typography
          textAlign={"center"}
          sx={{
            mt: 3,
            pb: 3
          }}
        >
          {NO_DATA}
        </Typography>
      )}

      {loader && <BodyLoader />}
    </Box>
  );
};

export default AvailableFaultyStockTransferTable;
