import React, { useEffect, useState } from "react";
import { getCompanyDetailsById } from "../../services/user.services";
import ProfileCard from "../profileCard/ProfileCard";
import office_Icon from "./../../assets/images/noun_office.svg";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../stateManagement/reducers/LoginSlice";
import { Typography } from "@mui/material";
import { NO_DATA_FOUND } from "../../constant/Constants";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the Comapny Profile of the LoggedIn user.
 * @returns {component} The Company Profile UI containing the Company Details of Logged in user
 */
const CompanyProfile = () => {
  const [companyDetails, setCompanyDetails] = useState("");
  const [showNoData, setShowNoData] = useState(false);
  const [loader, setLoader] = useState(false);
  const companyId = useSelector((state) => getCompanyId(state));
  const config = useSelector((state) => getConfigDetails(state));
  useEffect(() => {
    if (companyId && config.server) {
      setLoader(true);
      getCompanyDetailsById(config.server, companyId)
        .then((res) => {
          if (res && res.status === 200) {
            setCompanyDetails(res.data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        })
        .finally(() => setLoader(false));
    }
  }, [companyId, config.server]);
  const { address, companyName, contactNumber, emailId } = companyDetails;
  let profileDetails = {
    profileTittle: "Company Profile",
    profileName: companyName,
    profileNumber: contactNumber,
    profileEmail: emailId,
    profileAddress: address,
    image: office_Icon
  };
  return (
    <>
      {companyDetails && (
        <ProfileCard profileDetails={profileDetails}></ProfileCard>
      )}
      {loader && <BodyLoader />}
      {!loader && showNoData && (
        <Typography
          data-testid="noData"
          sx={{
            textAlign: "center",
            height: "600px"
          }}
        >
          {NO_DATA_FOUND}
        </Typography>
      )}
    </>
  );
};

export default CompanyProfile;
