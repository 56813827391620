import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Alert, Button, Input, Paper, FormHelperText } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import {
  CHANGE_PASSWORD_HINT,
  CONTINUE_TO_DASHBOARD,
  CONFIRM_PASSWORD_MISMATCH,
  PASSWORD_CHANGE_SUCCESSFUL,
  RESET_PASSWORD,
  TOAST_SUCCESS
} from "./../../constant/Constants";
import leftarrow from "./../../assets/images/arrow_back_black.svg";
import eyeClose from "./../../assets/images/eye_close.svg";
import setting from "./../../assets/images/setting.svg";
import toastErrorIcon from "./../../assets/images/toast_error_small.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import eyeOpen from "./../../assets/images/eye_open.svg";
import {
  regexExpression,
  removeWhiteSpaceInString
} from "../../constant/Utils";
import { getForgotPassword } from "../../services/user.services";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";

/**
 * Renders the Reset Password UI Component
 * @returns {component} the Reset password UI component
 */
const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            background:
              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0%no-repeat padding-box",
            color: "#ffffff",
            "&.Mui-disabled": {
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              opacity: 0.5
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDF4F6",
            color: "#0F0F10",
            border: "1px solid #E8E8E8",
            borderRadius: "3px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "13px",
            fontFamily: "BukraRegular",
            "&.Mui-focused": {
              border: "1px solid #0F57CB"
            },
            "& ::placeholder": {
              opacity: 0.8,
              color: "#5B636F"
            }
          }
        }
      }
    }
  });
  const initialValues = { newPassword: "", confirmPassword: "" };
  const shortToken = sessionStorage.getItem("shortToken");
  const channelPartnerCode = sessionStorage.getItem("channelPartnerCode");
  const [formValues, setFormValues] = useState(initialValues);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [showCurrentPasswordErrorMsg, setShowCurrentPasswordErrorMsg] =
    useState("");

  /**
   * Handles the submit function after entering valid data in the Reset Password input field.
   * @function handleSubmit - Performs the Action on Submission of form.
   * @param {object} e - Submit button event object.
   */
  const handleSubmit = (e) => {
    const { confirmPassword } = formValues;
    e.preventDefault();
    const checkedNewPasswordStatus = checkPassword(formValues.newPassword);
    const checkedconfirmPasswordStatus = checkPassword(
      formValues.confirmPassword
    );
    if (!(checkedNewPasswordStatus && checkedconfirmPasswordStatus)) {
      setOpen(true);
      setShowErrorMsg(CHANGE_PASSWORD_HINT);
    } else if (checkedNewPasswordStatus && checkedconfirmPasswordStatus) {
      let resetPasswordDetails = {
        newPassword: confirmPassword,
        shortToken,
        channelPartnerCode
      };
      dispatch(getForgotPassword(resetPasswordDetails))
        .unwrap()
        .then((res) => {
          if (res && res.status === 200) {
            let toastDetails = {
              toastTitle: PASSWORD_CHANGE_SUCCESSFUL,
              isToastOpen: true,
              toastStatus: TOAST_SUCCESS
            };
            dispatch(updateToastMessage(toastDetails));
            navigate("/dashboard");
          } else {
            setOpen(true);
            setShowErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          setOpen(true);
          setShowErrorMsg(error);
        });
    }
  };

  /**
   * Handles the Change in Reset Password form input values.
   * @function handleChange - Performs action on change of form input values.
   * @param {object} e - form input values
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = removeWhiteSpaceInString(value);
    setFormValues({ ...formValues, [name]: value.trim() });
    if (name === "confirmPassword" && trimmedValue !== formValues.newPassword) {
      setShowCurrentPasswordErrorMsg(CONFIRM_PASSWORD_MISMATCH);
    } else {
      setShowCurrentPasswordErrorMsg("");
    }
  };

  /**
   * @function checkPassword - Checks Whether Password matches particular criteria or not.
   * @param {string} str - Password string.
   * @returns {boolean} true or false regarding whether passwoerd follows the particular criteria or nor
   */
  function checkPassword(str) {
    let regex = regexExpression();
    return regex.test(str);
  }

  /**
   * Checks whether to Keep the Reset Password submit button disabled or enabled.
   * @function isButtonDisabled - Check whether to enable or disable the button.
   * @returns {boolean} true or false to define whether to disable or enable the Reset Password submit button.
   */
  const isButtonDisabled = () => {
    let value = true;
    if (
      formValues.newPassword &&
      formValues.confirmPassword &&
      formValues.confirmPassword === formValues.newPassword
    ) {
      value = false;
    }

    return value;
  };

  /**
   * Handles the close operation on the Snackbar component.
   * @function handleClose - Performs the Close for the Reset Password error snackbar.
   */
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={2}
        sx={{
          p: 2.1,
          pb: 0,
          maxWidth: { xs: "88%", sm: "370px" }
        }}
        data-testid="resetPassword"
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              data-testid="imageClick"
              src={leftarrow}
              alt="left arrow"
              style={{
                height: "15px",
                width: "15px",
                cursor: "pointer"
              }}
              onClick={() => navigate(-1)}
            ></img>{" "}
            <Box sx={{ margin: "0 auto" }}>
              <Snackbar
                data-testid="closeHandle"
                open={open}
                onClose={handleClose}
                key={"top"}
                autoHideDuration={3000}
                sx={{
                  "&.MuiSnackbar-anchorOriginBottomLeft ": {
                    top: "0px",
                    position: "relative",
                    left: "0px"
                  }
                }}
              >
                <Alert
                  severity="error"
                  icon={
                    <img src={toastErrorIcon} alt="error" height="15px"></img>
                  }
                  sx={{
                    width: "300px",
                    color: "#FF2A2A",
                    fontSize: "12px",
                    border: "1px solid #FF2A2A",
                    background: "#FDE2E2 0% 0% no-repeat padding-box",
                    fontFamily: "BukraMedium",
                    letterSpacing: "0.3px",
                    pt: 0,
                    pb: 0,
                    ".MuiAlert-icon,.MuiAlert-action": {
                      alignItems: "center",
                      pt: 0,
                      pb: 0,
                      pr: 0.8,
                      mr: 0
                    }
                  }}
                >
                  {showErrorMsg}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
          <Box
            sx={{
              p: 3,
              pt: open ? 0 : 3
            }}
          >
            <Typography
              sx={{
                fontFamily: "BukraMedium",
                textAlign: "center",
                fontSize: "16px",
                color: "#464e5f",
                fontWeight: "600"
              }}
            >
              {RESET_PASSWORD}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "600",
                mt: 1,
                fontFamily: "BukraRegular",
                letterSpacing: "0.02px",
                color: "#62656b"
              }}
            >
              {CHANGE_PASSWORD_HINT}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                pt: 2,
                pl: 3,
                pr: 3
              }}
            >
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                id="newPassword"
                fullWidth
                required
                name="newPassword"
                type={showPassword ? "text" : "password"}
                placeholder={
                  isMobile ? "Enter New Password" : "Enter Your New Password"
                }
                disableUnderline={true}
                sx={{
                  mt: 3,
                  color: "#0F0F10"
                }}
                inputProps={{ "data-testid": "enterNewPassword" }}
                startAdornment={
                  <InputAdornment position="start" sx={{ p: 1 }}>
                    <img src={setting} alt="s"></img>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" sx={{ p: 1 }}>
                    <IconButton
                      data-testid="setShowClick"
                      tabIndex={-1}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <img src={eyeOpen} alt="e"></img>
                      ) : (
                        <img src={eyeClose} alt="e"></img>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => handleChange(e)}
              />
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                id="confirmPassword"
                fullWidth
                required
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder={
                  isMobile
                    ? "Confirm New Password"
                    : "Confirm Your New Password"
                }
                disableUnderline={true}
                sx={{
                  mt: 3,
                  color: "#0F0F10"
                }}
                inputProps={{ "data-testid": "confirmNewPassword" }}
                startAdornment={
                  <InputAdornment position="start" sx={{ p: 1 }}>
                    <img src={setting} alt="s"></img>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" sx={{ p: 1, ml: "4px" }}>
                    <IconButton
                      data-testid="clickConfirm"
                      tabIndex={-1}
                      onClick={() =>
                        setshowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <img src={eyeOpen} alt="e"></img>
                      ) : (
                        <img src={eyeClose} alt="e"></img>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => handleChange(e)}
              />
              {showCurrentPasswordErrorMsg && (
                <FormHelperText
                  sx={{ color: "#DE0909", textAlign: "left" }}
                  className="errorFormHelperText"
                >
                  {showCurrentPasswordErrorMsg}
                </FormHelperText>
              )}

              <Button
                type="submit"
                data-testid="resetSubmit"
                variant="text"
                sx={{
                  mt: 4,
                  mb: 2,
                  borderRadius: "20px",
                  pl: 1,
                  pr: 1,
                  textTransform: "none",
                  fontFamily: "BukraMedium",
                  width: { md: "240px", xs: "100%" },
                  height: "40px",
                  ml: "auto",
                  mr: "auto"
                }}
                disabled={isButtonDisabled()}
              >
                <Typography
                  sx={{
                    fontSize: { md: "15px", sm: "13px", xs: "12px" },
                    textTransform: "none",
                    fontFamily: "BukraMedium"
                  }}
                >
                  {CONTINUE_TO_DASHBOARD}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default ResetPassword;
