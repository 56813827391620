import React from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import {
  Alert,
  AlertTitle,
  Box,
  ListSubheader,
  Typography
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import close from "./../../assets/images/close.png";
import success_icon from "./../../assets/images/toast_success_icon.svg";
import error_icon from "./../../assets/images/toast_error_icon.svg";
import {
  TRANSACTION_COMPLETED,
  TRANSFER_TO,
  ERROR,
  ERROR_IN,
  TRANSACTION,
  SUCCESS,
  TRANSACTION_SUCCESS_STATUS
} from "../../constant/Constants";
import { setCreditsTransferStatus } from "../../stateManagement/reducers/CreditsTransferStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getMultipleTransferMessages,
  setMultipleTransferMsg
} from "../../stateManagement/reducers/MultipleTransferSlice";

/**
 * Renders the UI for the MULTI_STATUS error for the Credits Transfer.
 * @param {{handleClosePopup: function}} prop - Prop element Passed to the Transfer Error Modal to close the Popup.
 * @returns {component} - Transfer Error Modal Box UI for MULTI_STATUS error.
 */
const TransferErrorModal = ({ handleClosePopup }) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const messages = useSelector((state) => getMultipleTransferMessages(state));

  /**
   * Handle the Transaction Errpr Popup clodse operation
   * @function handleClose - Close the Transaction Error Popup.
   */
  const handleClose = () => {
    handleClosePopup();
    if (messages && messages.creditFailureResponseList.length === 0) {
      navigateTo("/credits");
      dispatch(setMultipleTransferMsg(null));
      let transferDetails = {
        transactionStatus: TRANSACTION_SUCCESS_STATUS,
        statusValue: 0
      };
      dispatch(setCreditsTransferStatus(transferDetails));
    } else if (
      messages &&
      messages.creditFailureResponseList.length >= 1 &&
      messages.creditSuccessResponseList.length > 0
    ) {
      dispatch(setMultipleTransferMsg(null));
    } else {
      dispatch(setMultipleTransferMsg(null));
    }
  };

  /**
   * @constant {JSX} action - Renders the Icon to close the Popup.
   */
  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      data-testid="closeIconBtn"
    >
      <img src={close} height="12px" alt="cls"></img>
    </IconButton>
  );

  /**
   * Renders the Error Message UI
   * @function errorTranscationUI - Displays the UI for Error Messages.
   * @returns {component} to render the Error Message Popup
   */
  const errorTranscationUI = () => {
    return (
      messages &&
      messages.creditFailureResponseList.length >= 1 && (
        <Snackbar
          open={true}
          sx={{
            "&.MuiSnackbar-anchorOriginBottomLeft ": {
              top: "0px",
              position: "relative",
              left: "0px",
              backgroundColor: "#FFFFFF",
              borderRadius: "2px",
              width: { xl: "100%", sm: "450px" },
              alignItems: "flex-start",
              ".MuiPaper-root": {
                pt: 0,
                pb: 0,
                alignItems: "center"
              }
            }
          }}
        >
          <Box sx={{ width: "inherit" }}>
            <Alert
              action={action}
              icon={<img src={error_icon} alt="err" height="16px"></img>}
              sx={{
                backgroundColor: "#fde7e7",
                fontSize: `12px`,
                letterSpacing: "0px",
                borderRadius: "8px",
                ".MuiAlert-icon,.MuiAlert-action": {
                  alignItems: "center",
                  pt: 0,
                  pb: 0
                }
              }}
            >
              <AlertTitle
                sx={{
                  color: "#DE0909",
                  fontFamily: "BukraMedium",
                  letterSpacing: 0,
                  mb: 0,
                  fontSize: "14px",
                  pl: 1
                }}
              >
                {ERROR}
              </AlertTitle>
              <Box sx={{ display: "flex", pl: 1 }}>
                <Typography
                  sx={{ fontSize: "12px", color: "#fe6969", letterSpacing: 0 }}
                >
                  {ERROR_IN} {messages.creditFailureResponseList.length}{" "}
                  {TRANSACTION}
                </Typography>{" "}
              </Box>
            </Alert>
            <List
              sx={{
                maxHeight: "200px",
                overflow: "auto",
                pt: 0,
                border: "2px solid #ededed",
                ml: 2,
                mr: 2,
                mt: 1,
                mb: 1,
                "&::-webkit-scrollbar": {
                  width: "4px"
                },
                scrollbarWidth: "thin"
              }}
            >
              <ListSubheader
                sx={{
                  pt: 0.5,
                  pb: 0.5,
                  display: "flex",
                  boxShadow:
                    "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                }}
              >
                {" "}
                <ListItemText
                  sx={{ flex: 1 }}
                  primaryTypographyProps={{
                    fontSize: 12,
                    color: "#5b636f",
                    fontFamily: "BukraMedium",
                    letterSpacing: "0px"
                  }}
                >
                  {TRANSFER_TO}
                </ListItemText>
                <ListItemText
                  sx={{ flex: 2 }}
                  primaryTypographyProps={{
                    fontSize: 12,
                    color: "#5b636f",
                    fontFamily: "BukraMedium",
                    letterSpacing: "0px"
                  }}
                >
                  {ERROR}
                </ListItemText>
              </ListSubheader>

              {messages?.creditFailureResponseList?.map((item, index) => {
                return (
                  <ListItem
                    key={`${item.toUser + index}`}
                    sx={{
                      ":nth-of-type(even)": {
                        backgroundColor: "#f7fafb"
                      }
                    }}
                  >
                    <ListItemText
                      sx={{ flex: 1, wordWrap: "break-word" }}
                      primaryTypographyProps={{
                        fontSize: 10,
                        color: "#131415",
                        fontFamily: "BukraRegular",
                        letterSpacing: "0px"
                      }}
                    >
                      {item.toUser}
                    </ListItemText>
                    <ListItemText
                      sx={{ flex: 2, wordWrap: "break-word" }}
                      primaryTypographyProps={{
                        fontSize: 10,
                        color: "#131415",
                        fontFamily: "BukraRegular",
                        letterSpacing: "0px"
                      }}
                    >
                      {item.message}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Snackbar>
      )
    );
  };

  /**
   * Renders the Success Message UI
   * @function successTranscationUI - Displays the UI for Success Messages.
   * @returns {component} to render the Success Message Popup
   */
  const successTranscationUI = () => {
    return (
      messages &&
      messages.creditSuccessResponseList.length >= 1 && (
        <Snackbar
          open={true}
          sx={{
            "&.MuiSnackbar-anchorOriginBottomLeft ": {
              top: "0px",
              position: "relative",
              left: "0px",
              backgroundColor: "transparent",
              width: { xl: "100%", sm: "450px" },
              alignItems: "flex-start",
              ".MuiPaper-root": {
                pt: 0,
                pb: 0,
                alignItems: "center"
              }
            }
          }}
        >
          <Box sx={{ width: "inherit" }}>
            <Alert
              action={action}
              icon={<img src={success_icon} alt="err" height="16px"></img>}
              sx={{
                backgroundColor: "#e6ffee",
                fontSize: `12px`,
                letterSpacing: "0px",
                border: "1px solid green",
                borderRadius: "8px",
                ".MuiAlert-icon,.MuiAlert-action": {
                  alignItems: "center",
                  pt: 0,
                  pb: 0
                }
              }}
            >
              <AlertTitle
                sx={{
                  color: "green",
                  fontFamily: "BukraMedium",
                  letterSpacing: "0px",
                  mb: 0,
                  fontSize: "14px",
                  pl: 1
                }}
              >
                {SUCCESS}
              </AlertTitle>
              <Typography
                sx={{
                  fontSize: "11px",
                  color: "green",
                  fontFamily: "BukraRegular",
                  letterSpacing: "0px",
                  pl: 1
                }}
              >
                {messages.creditSuccessResponseList.length}{" "}
                {TRANSACTION_COMPLETED}
              </Typography>{" "}
            </Alert>
          </Box>
        </Snackbar>
      )
    );
  };
  return (
    <Dialog
      // onClose={handleClose}
      data-testid="transferErrorModal"
      open={true}
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: "transparent",
          width: { xl: "576px", sm: "100%" }
        }
      }}
    >
      <Stack spacing={2}>
        {successTranscationUI()}
        {errorTranscationUI()}
      </Stack>
    </Dialog>
  );
};

export default TransferErrorModal;
