import { createSlice } from "@reduxjs/toolkit";

import { getCreditSummary } from "../../services/user.services";

const initialState = {
  userBalanceSummary: {}
};

/**
 * Saves the User Information into the Store on login.
 */
const totalCreditBalanceSummarySlice = createSlice({
  name: "totalCreditBalanceSummary",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(getCreditSummary.fulfilled, (state, { payload }) => {
      if (payload.data && payload.status && payload.status === 200) {
        state.userBalanceSummary = payload.data;
      } else {
        state.userBalanceSummary = {};
      }
    });
  }
});

/**
 * Fetches the User  Information from store.
 * @param {object} state - Store State.
 * @returns {object} - User balance information.
 */
export const getTotalCreditBalance = (state) => {
  const totalSummary = state.totalCreditBalanceSummary.userBalanceSummary
    ? state.totalCreditBalanceSummary.userBalanceSummary
    : {};
  return totalSummary;
};

export default totalCreditBalanceSummarySlice.reducer;
