import {
  Alert,
  AlertTitle,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Snackbar,
  Typography
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import {
  ERROR,
  SUCCESS,
  ERRORS_FOUND,
  STB_REGISTERED_SUCCESSFULLY
} from "../../constant/Constants";
import success_icon from "./../../assets/images/toast_success_icon.svg";
import error_icon from "./../../assets/images/toast_error_icon.svg";
import close from "./../../assets/images/close.png";

/**
 * Renders the Multi Response Popup UI.
 * @param {multiResponse} - Array of success and error messages as props.
 * @param {handleClosePopup}- function to closr the MultiResponse Popup.
 * @returns {component} the Multi response Popup UI.
 */
const CustomMultiPopup = ({ multiResponse, handleClosePopup }) => {
  const headers = ["STB ID", "Error"];
  const handleClose = () => {
    handleClosePopup();
  };

  /**
   * @constant {component} action  - Returns the close icon Image UI.
   */
  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      data-testid="closeMultipop"
    >
      <img src={close} height="12px" alt="cls"></img>
    </IconButton>
  );

  /**
   * Renders the Error Response UI in Popup
   * @function errorTranscationUI - Gives the UI for the Error Transaction details.
   * @returns {component} Error Response UI in Popup
   */
  const errorTranscationUI = () => {
    return (
      multiResponse &&
      multiResponse.errors?.length >= 1 && (
        <Snackbar
          data-testid="error"
          open={true}
          sx={{
            "&.MuiSnackbar-anchorOriginBottomLeft ": {
              top: "0px",
              position: "relative",
              left: "0px",
              backgroundColor: "#FFFFFF",
              borderRadius: "2px",
              width: { xxl: "100%", sm: "450px" },
              alignItems: "flex-start",
              ".MuiPaper-root": {
                pt: 0,
                pb: 0,
                alignItems: "center"
              }
            }
          }}
        >
          <Box sx={{ width: "inherit" }}>
            <Alert
              action={action}
              icon={<img src={error_icon} alt="err" height="16px"></img>}
              sx={{
                backgroundColor: "#fde7e7",
                fontSize: `12px`,
                borderRadius: "8px",
                letterSpacing: "0px",
                ".MuiAlert-icon,.MuiAlert-action": {
                  alignItems: "center",
                  pt: 0,
                  pb: 0
                }
              }}
            >
              <AlertTitle
                sx={{
                  color: "#DE0909",
                  fontFamily: "BukraMedium",
                  letterSpacing: 0,
                  mb: 0,
                  fontSize: "14px",
                  pl: 1
                }}
              >
                {ERROR}
              </AlertTitle>
              <Box sx={{ display: "flex", pl: 1 }}>
                <Typography
                  sx={{ fontSize: "12px", color: "#fe6969", letterSpacing: 0 }}
                >
                  {multiResponse?.errors?.length} {ERRORS_FOUND}
                </Typography>{" "}
              </Box>
            </Alert>
            <List
              sx={{
                maxHeight: "200px",
                overflow: "auto",
                pt: 0,
                border: "2px solid #ededed",
                ml: 2,
                mr: 2,
                mt: 1,
                mb: 1,
                "&::-webkit-scrollbar": {
                  width: "4px"
                },
                scrollbarWidth: "thin"
              }}
            >
              <ListSubheader
                sx={{
                  pt: 0.5,
                  pb: 0.5,
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  boxShadow:
                    "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                }}
              >
                {" "}
                {headers &&
                  headers.map((header, index) => {
                    let flexLength = index + 1;
                    return (
                      <ListItemText
                        key={flexLength}
                        sx={{ flex: flexLength, px: "10px" }}
                      >
                        <Typography
                          data-testid="listItem"
                          sx={{
                            fontSize: 12,
                            color: "#5b636f",
                            fontFamily: "BukraMedium",
                            letterSpacing: "0px"
                          }}
                        >
                          {header}
                        </Typography>
                      </ListItemText>
                    );
                  })}
              </ListSubheader>

              {multiResponse?.errors?.map((item, index) => {
                const errorResponse = `error_${index}`;
                return (
                  <ListItem
                    key={errorResponse}
                    sx={{
                      ":nth-of-type(even)": {
                        backgroundColor: "#f7fafb"
                      }
                    }}
                  >
                    <ListItemText
                      sx={{
                        flex: 1,
                        wordWrap: "break-word",
                        px: "10px",
                        py: "5px"
                      }}
                      primaryTypographyProps={{
                        fontSize: 10,
                        color: "#131415",
                        fontFamily: "BukraRegular",
                        letterSpacing: "0px"
                      }}
                    >
                      {item.serialNumber}
                    </ListItemText>
                    <ListItemText
                      sx={{ flex: 2, wordWrap: "break-word" }}
                      primaryTypographyProps={{
                        fontSize: 10,
                        color: "#131415",
                        fontFamily: "BukraRegular",
                        letterSpacing: "0px",
                        whiteSpace: "break-spaces"
                      }}
                    >
                      {item.message}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Snackbar>
      )
    );
  };

  /**
   * Renders the Success UI Popup.
   * @function successTranscationUI - Gives the UI for the Successfull Transaction details.
   * @returns the Success Response UI in the Popup
   */
  const successTranscationUI = () => {
    return (
      multiResponse &&
      multiResponse.success?.length >= 1 && (
        <Snackbar
          data-testid="success"
          open={true}
          sx={{
            "&.MuiSnackbar-anchorOriginBottomLeft ": {
              top: "0px",
              position: "relative",
              left: "0px",
              backgroundColor: "transparent",
              width: { xxl: "100%", sm: "450px" },
              alignItems: "flex-start",
              ".MuiPaper-root": {
                pt: 0,
                pb: 0,
                alignItems: "center"
              }
            }
          }}
        >
          <Box sx={{ width: "inherit" }}>
            <Alert
              onClick={handleClose}
              action={action}
              icon={<img src={success_icon} alt="err" height="16px"></img>}
              sx={{
                backgroundColor: "#e6ffee",
                fontSize: `12px`,
                letterSpacing: "0px",
                border: "1px solid green",
                borderRadius: "8px",
                ".MuiAlert-icon,.MuiAlert-action": {
                  alignItems: "center",
                  pt: 0,
                  pb: 0
                }
              }}
            >
              <AlertTitle
                sx={{
                  color: "green",
                  fontFamily: "BukraMedium",
                  letterSpacing: "0px",
                  mb: 0,
                  fontSize: "14px",
                  pl: 1
                }}
              >
                {SUCCESS}
              </AlertTitle>
              <Typography
                sx={{
                  fontSize: "11px",
                  color: "green",
                  fontFamily: "BukraRegular",
                  letterSpacing: "0px",
                  pl: 1
                }}
              >
                {multiResponse?.success?.length} {STB_REGISTERED_SUCCESSFULLY}
              </Typography>{" "}
            </Alert>
          </Box>
        </Snackbar>
      )
    );
  };

  return (
    <>
      <Dialog
        // onClose={handleClose}
        data-testid="transferErrorModal"
        open={true}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "transparent",
            width: { xl: "576px", sm: "100%" },
            alignItems: "center"
          }
        }}
      >
        <Stack spacing={2}>
          {successTranscationUI()}
          {errorTranscationUI()}
        </Stack>
      </Dialog>
    </>
  );
};

export default CustomMultiPopup;
