import { combineReducers } from "@reduxjs/toolkit";
import LoginSlice from "./reducers/LoginSlice";
import NewSubscriptionCartSlice from "./reducers/NewSubscriptionCartSlice";
import ToastMessageSlice from "./reducers/ToastMessageSlice";
import creditsTransfersSlice from "./reducers/CreditsTransfersSlice";
import RenewSubscriptionCartSlice from "./reducers/RenewSubscriptionCartSlice";
import DateFilterSlice from "./reducers/DateFilterSlice";
import CreditsTransferStatusSlice from "./reducers/CreditsTransferStatusSlice";
import UserResetPasswordDetailsSlice from "./reducers/UserResetPasswordDetailsSlice";
import CountryDetailsSlice from "./reducers/CountryDetailsSlice";
import MultipleTransferSlice from "./reducers/MultipleTransferSlice";
import NotificationSlice from "./reducers/NotificationSlice";
import BroadcastNotificationSlice from "./reducers/BroadcastNotificationSlice";
import LocationSlice from "./reducers/LocationSlice";
import ConfigSlice from "./reducers/ConfigSlice";
import CreditBalanceSlice from "./reducers/CreditBalanceSlice";
import PushNotificationSlice from "./reducers/PushNotificationSlice";
import TotalCreditBalanceSummarySlice from "./reducers/TotalCreditBalanceSummarySlice";

/**
 * All Reducers Listing.
 */
export const rootReducer = combineReducers({
  login: LoginSlice,
  renewSubscriptionCart: RenewSubscriptionCartSlice,
  newSubscriptionCart: NewSubscriptionCartSlice,
  toastMessage: ToastMessageSlice,
  selectedCreditsTransfer: creditsTransfersSlice,
  dateFilter: DateFilterSlice,
  creditsTransferStatus: CreditsTransferStatusSlice,
  userResetPasswordDetails: UserResetPasswordDetailsSlice,
  countryDetails: CountryDetailsSlice,
  locationDetails: LocationSlice,
  multipleTransfer: MultipleTransferSlice,
  notification: NotificationSlice,
  broadcastNotification: BroadcastNotificationSlice,
  config: ConfigSlice,
  creditBalance: CreditBalanceSlice,
  pushNotification: PushNotificationSlice,
  totalCreditBalanceSummary:TotalCreditBalanceSummarySlice
});
