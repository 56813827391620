import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  notificationMessage: []
};

/**
 * Saves the Notification messages into the store.
 */
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationSource: (state, action) => {
      state.notificationMessage = [...action.payload];
    }
  }
});
/**
 * Fetches the Notifications info from the store.
 * @param {object} state - Store State.
 * @returns {object} - Notifications.
 */
export const getNotification = (state) => {
  return state.notification ? state.notification.notificationMessage : [];
};

export const { setNotificationSource } = notificationSlice.actions;
export default notificationSlice.reducer;
