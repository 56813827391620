/**
 * @constant {array<object>} subscriptionPackageTableHeader - Details for the  Subscription Component Table UI.
 */

export const subscriptionPackageTableHeader = [
  {
    id: "planName",
    key: "planName",
    numeric: false,
    disablePadding: false,
    label: "Package"
  },
  {
    id: "duration",
    key: "duration",
    numeric: false,
    disablePadding: false,
    label: "Duration"
  },
  {
    id: "finalAmount",
    key: "finalAmount",
    numeric: false,
    disablePadding: false,
    label: "Amount"
  }
];

export const availableFaultyStockTransferTableHeader = [
  {
    id: "hardwareMake",
    key: "hardwareMake",
    numeric: false,
    disablePadding: false,
    label: "Manufacture"
  },
  {
    id: "hardwareModelName",
    key: "hardwareModelName",
    numeric: false,
    disablePadding: false,
    label: "Model"
  },
  {
    id: "count",
    key: "count",
    numeric: false,
    disablePadding: false,
    label: "Stock"
  }
];

export const inComingOutGoingStockTransferTableHeader = [
  {
    id: "hardwareMake",
    key: "hardwareMake",
    numeric: false,
    disablePadding: false,
    label: "Manufacture"
  },
  {
    id: "hardwareModelName",
    key: "hardwareModelName",
    numeric: false,
    disablePadding: false,
    label: "Model"
  },
  {
    id: "count",
    key: "count",
    numeric: false,
    disablePadding: false,
    label: "Stock"
  },
  {
    id: "trasferDate",
    key: "trasferDate",
    numeric: false,
    disablePadding: false,
    label: "Date"
  }
];

export const promotionTableHeader = [
  {
    id: "publishStartDate",
    key: "publishStartDate",
    label: "Date",
    width: "100px",
    display: "table-cell"
  },
  {
    id: "message",
    key: "message",
    label: "Message",
    width: "500px",
    display: "block"
  },
  {
    id: "status",
    key: "status",
    label: "Status",
    width: "100px",
    display: "table-cell"
  }
];

export const transactionNotificationTableHeader = [
  {
    id: "createdDateTime",
    key: "createdDateTime",
    label: "Date"
  },
  {
    id: "message",
    key: "message",
    label: "Message"
  }
];
