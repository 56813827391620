import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Dialog } from "@mui/material";
import close from "./../../assets/images/close.png";
import Typography from "@mui/material/Typography";
import {
  PASSCODE_ENTRY_ATTEMPT,
  PASSCODE_ERROR,
  RECOVER_YOUR_ACCOUNT
} from "./../../constant/Constants";

import Lock_Icon from "./../../assets/images/lock_icon_fill.svg";

/**
 * Renders the Dialog Box UI component for the Passcode attempt exhaust message
 * @param {object} props - Props passed to the Passcode attempt eaxhaust UI for the Message display in pop-up
 * @returns {component} the Dialog box UI message for hte passcode attemt exhaust message.
 */
const PasscodeError = ({
  parentEmail,
  handleCloseNoAttempts,
}) => {
  const [open, setOpen] = useState(true);

  /**
   * Handle the operation for closing the Dialog box.
   * @function handleClose - Performs action to close the Pop-up.
   */
  const handleClose = () => {
    setOpen(false);
    handleCloseNoAttempts();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      data-testid="passcodeError"
      open={open}
      // onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: { sm: "319px" },
          px: { sm: 2.8 },
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          pb: { xs: 2, sm: 0 },
          boxShadow: "0px 0px 14px #0000004D",
          maxWidth: { xs: "100%", sm: "384px" }
        }}
      >
        <Box>
          <Box
            data-testid="image"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              pt: 2.8,
              px: { xs: 2, sm: 0 }
            }}
          >
            <img
              data-testid="closeIcon"
              src={close}
              alt="X"
              onClick={handleClose}
              height="16px"
              style={{
                cursor: "pointer"
              }}
            ></img>
          </Box>
          <Box
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <img src={Lock_Icon} alt="lock" height="50px"></img>
            <Typography
              data-testid="passcodeEntryAttemptText"
              sx={{
                fontFamily: "BukraMedium",
                textAlign: "center",
                fontSize: { sm: "16px", xs: "14px" },
                color: "#464E5F",
                pt: 3.3
              }}
            >
              {PASSCODE_ENTRY_ATTEMPT}
            </Typography>
            <Typography
              data-testid="passcodeErrorSubHeader"
              sx={{
                fontSize: "12px",
                textAlign: "center",
                mt: 2,
                fontFamily: "BukraRegular",
                letterSpacing: "0px",
                color: "#000000"
              }}
            >
              {PASSCODE_ERROR} {parentEmail ? parentEmail : ""}{" "}
              {RECOVER_YOUR_ACCOUNT}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PasscodeError;
