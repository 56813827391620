import {
  Box,
  createTheme,
  Divider,
  Paper,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
  MenuItem,
  Menu,
  Typography
} from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import TabPanel from "../../components/tabPanel/TabPanel";
import filter from "./../../assets/images/filter.svg";
import done from "./../../assets/images/done.svg";
import filter_active from "./../../assets/images/filter_active.svg";
import {
  ALL_TIME,
  CAMPAIGN_LABEL,
  CUSTOM,
  NOTIFICATION,
  THIS_MONTH,
  THIS_YEAR,
  TO_DAY,
  TRANSACTIONS_LABEL
} from "./../../constant/Constants";
import MultiRangeDatePicker from "../../components/multiRangeDatePicker/MultiRangeDatePicker";
import TransactionDetailsTable from "../../components/transactionDetailsTable/TransactionDetailsTable";
import CampaignDetailsTable from "../../components/campaignDetailsTable/CampaignDetailsTable";

const tabTheme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "30px",
          marginLeft: "1px",
          fontSize: "14px",
          textTransform: "capitalize",
          color: "#5c6473",
          display: "flex",
          alignItems: "center",
          fontFamily: "BukraMedium"
        }
      }
    }
  }
});
const NotificationsDetailsPage = () => {
  const [showActiveFilterIcon, setShowActiveFilterIcon] = useState(false);
  const [filterTime, setFilterTime] = useState("this_month");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const timeFilterArray = [
    { key: "all_time", label: ALL_TIME },
    { key: "this_year", label: THIS_YEAR },
    { key: "this_month", label: THIS_MONTH },
    { key: "to_day", label: TO_DAY },
    { key: "custom_time", label: CUSTOM }
  ];
  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      setFilterTime("all_time");
    } else {
      setFilterTime("this_month");
    }
    setStartDate("");
    setEndDate("");
    setTabValue(newValue);
  };
  const handleMenuItemClick = (event, index, value) => {
    setFilterTime(value);
    setShowActiveFilterIcon(true);
    setAnchorEl(null);
  };

  /**
   * Handle the close operation of Time Filter.
   * @function handleClose - Performs the close action to the time filter pop-up.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles the Operation to filter the date for custom date filter
   * @function datafromtheDatePicker - Performs action to set the date range for custom date filter.
   */
  const datafromtheDatePicker = useCallback((value) => {
    const startDateValue = value[0] ? `${value[0].format("YYYY-MM-DD")}` : "";
    const endDateValue = value[1] ? `${value[1].format("YYYY-MM-DD")}` : "";
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  }, []);

  /**
   * @constant {boolean} - Boolean true status to display the filter operation.
   */
  const open = Boolean(anchorEl);

  /**
   * Handles the click operation.
   * @function handleClickListItem - Handles the click on the Filter icon in Quicklinks.
   * @param {object} event - event object.
   */
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getFilterLabel = () => {
    const filterlabelObject = timeFilterArray.find(
      (item) => item.key === filterTime
    );
    return filterlabelObject ? filterlabelObject.label : "";
  };

  return (
    <Paper
      elevation={2}
      className="notificationTable_wrapper"
      data-testid="notificationTable"
    >
      <Toolbar
        sx={{
          py: { xs: 1, md: 0 },
          px: 2,
          display: "flex",
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: { sm: "space-between", xs: "center" },
          flexDirection: { xs: "column", sm: "row" },
          minHeight: { sm: "45px" }
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontFamily: "BukraMedium",
            color: "#464E5F",
            letterSpacing: "0px"
          }}
        >
          {NOTIFICATION}
        </Typography>

        <Box
          sx={{
            pr: { md: 1 },
            display: "flex",
            cursor: "pointer",
            flexDirection: {
              xs: "column-reverse",
              sm: "row"
            },
            width: { xs: "98%", sm: "auto" }
          }}
        >
          {filterTime === "custom_time" && (
            <MultiRangeDatePicker
              dateFromParent={datafromtheDatePicker}
            ></MultiRangeDatePicker>
          )}
          <Box
            sx={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              fontSize: "13px",
              color: "#0F57CB",
              border: "1px solid #0F57CB",
              borderRadius: "4px",
              display: "flex",
              minWidth: "140px",
              width: { md: "140px", xs: "100%" },
              height: "30px",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "BukraMedium",
              pl: 1,
              pr: 1,
              ml: { sm: 1 },
              my: { xs: 1, sm: 0 }
            }}
            onClick={handleClickListItem}
            data-testid="clickListItem"
          >
            <Typography
              sx={{
                font: "inherit"
              }}
            >
              {getFilterLabel()}
            </Typography>
            <Box sx={{ cursor: "pointer" }}>
              {showActiveFilterIcon ? (
                <img src={filter_active} alt="filter"></img>
              ) : (
                <img src={filter} alt="filterActive"></img>
              )}
            </Box>
          </Box>

          <Menu
            data-testid="filter"
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                minWidth: "205px",
                overflow: "visible",
                backgroundColor: "#f6f6f6",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,

                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "#f6f6f6",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {timeFilterArray.map((option, index) => (
              <MenuItem
                data-testid="menuClick"
                key={option.key}
                value={option.key}
                selected={option.key === filterTime}
                onClick={(event) =>
                  handleMenuItemClick(event, index, option.key)
                }
                divider
                sx={{
                  fontFamily: "BukraRegular",
                  "&.Mui-selected": {
                    fontFamily: "BukraMedium",
                    backgroundColor: "inherit",
                    "&.Mui-selected:focus": {
                      backgroundColor: "inherit"
                    }
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    "&:hover": {
                      transition: "0.15s ease-in-out",
                      transform: "scale(1.05 ,1.05)"
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "inherit",
                      color: "#212121"
                    }}
                  >
                    {option.label}
                  </Typography>{" "}
                  {option.key === filterTime ? (
                    <img src={done} alt="done"></img>
                  ) : (
                    ""
                  )}
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      <Divider></Divider>

      <Box>
        <Box
          sx={{
            mt: 2,
            pl: 2,
            height: "45px",
            background: " #EDF4F6 0% 0% no-repeat padding-box"
          }}
        >
          <ThemeProvider theme={tabTheme}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                display: "flex",
                alignItems: "center",
                minHeight: "45px",
                my: { xs: 1, lg: 0 }
              }}
            >
              <Tab
                data-testid="transactionsTab"
                label={TRANSACTIONS_LABEL}
                sx={{
                  width: { xs: "fit-content" },
                  "&.Mui-selected": {
                    background: "#cfebe0 0% 0% no-repeat padding-box",
                    borderRadius: "17px",
                    color: "#06B250",
                    height: "30px"
                  }
                }}
              />

              <Tab
                data-testid="campaignTab"
                label={CAMPAIGN_LABEL}
                sx={{
                  width: { xs: "fit-content" },
                  "&.Mui-selected": {
                    background: "#cfebe0 0% 0% no-repeat padding-box",
                    borderRadius: "17px",
                    color: "#06B250",
                    height: "30px"
                  }
                }}
              />
            </Tabs>
          </ThemeProvider>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <TransactionDetailsTable
            selectedDateFilter={filterTime}
            startDate={startDate}
            endDate={endDate}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CampaignDetailsTable
            selectedDateFilter={filterTime}
            startDate={startDate}
            endDate={endDate}
          />
        </TabPanel>
      </Box>
    </Paper>
  );
};

export default memo(NotificationsDetailsPage);
