import React, { memo, useEffect } from "react";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import { Outlet, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import RenderChildComponent from "../../components/renderChildComponent/RenderChildComponent";
import CreditsTransferTable from "../../components/creditsTransferTable/CreditsTransferTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getChannelPartnerCode,
  getUserRoleType
} from "../../stateManagement/reducers/LoginSlice";
import {
  CREDITS,
  RETAILER_USER_ROLE_TYPE,
  TRANSACTION_SUCCESS_STATUS,
  TRANSFER
} from "../../constant/Constants";
import RecentCreditsTransferTable from "../../components/recentCreditsTransferTable/RecentCreditsTransferTable";
import { updatePageVisit } from "../../constant/Utils";
import { urlPath } from "../../constant/ScopeMenus";
import { setCreditsTransferStatus } from "../../stateManagement/reducers/CreditsTransferStatusSlice";

/**
 * Renders the Credits Page component.
 * @returns {component} Credits Page component
 */
const Credits = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  /**
   * @constant {string} channelPartnerCode - Gets the channel Partner code from store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * @constant {number} fetchUserRoleType - Gets the User role type from store.
   */
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));

  /**
   * @constant {boolean} isRetailerUser - Checks if the User is retailer, set true if it is retailer.
   */
  const isRetailerUser =
    fetchUserRoleType === RETAILER_USER_ROLE_TYPE ? true : false;
  const isCreditTablePresent =
    isRetailerUser ||
    location.pathname.split("/").includes("transfercredits") ||
    location.pathname.split("/").includes("reversecredits") ||
    false;
  const childPath = [
    "/credits",
    "/credits/transfercredits",
    "/credits/reversecredits"
  ];

  /**
   * Sets the Page visits data.
   */
  useEffect(() => {
    if (location.pathname === urlPath.credits && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * Sets the success credits transfer tab active.
   */
  useEffect(() => {
    return () => {
      if (!isRetailerUser) {
        let transferStatusDetails = {
          transactionStatus: TRANSACTION_SUCCESS_STATUS,
          statusValue: 0
        };
        dispatch(setCreditsTransferStatus(transferStatusDetails));
      }
    };
  }, [dispatch,isRetailerUser]);

  return (
    <div data-testid="creditTransferPage">
      <Stack spacing={2}>
        <QuickLinks
          childPath={childPath}
          showCards={true}
          showFilter={true}
          showCreditCard={true}
        ></QuickLinks>
        <Outlet />

        <RenderChildComponent isChildPresent={isCreditTablePresent}>
          <CreditsTransferTable></CreditsTransferTable>
        </RenderChildComponent>
        <RenderChildComponent isChildPresent={!isRetailerUser}>
          <RecentCreditsTransferTable
            fromCredits={true}
            headerLabel={`${CREDITS} ${TRANSFER}`}
            limit={0}
          ></RecentCreditsTransferTable>
        </RenderChildComponent>
      </Stack>
    </div>
  );
};

export default memo(Credits);
