import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedDateFilter: {},
  showDateFilter: true
};

/**
 * Saves the Date range into the store.
 */
const dateFilterSlice = createSlice({
  name: "dateFilter",
  initialState,
  reducers: {
    getFilteredDate: (state, action) => {
      state.selectedDateFilter = action.payload;
    },
    setShowDateFilter: (state, action) => {
      state.showDateFilter = action.payload;
    }
  }
});

/**
 * Fetches the date range from the store.
 * @param {object} state - Store State .
 * @returns {object} - Date range object with label.
 */
export const getFilteredDateFromStore = (state) => {
  return state.dateFilter ? state.dateFilter.selectedDateFilter : null;
};

/**
 * Fetches the Status for whether to display the date range UI or not from the store.
 * @param {object} state - Store State.
 * @returns {boolean} - true or false to display or hide the Date filter
 */
export const getShowDateFilter = (state) => {
  return state.dateFilter ? state.dateFilter.showDateFilter : true;
};

export const { getFilteredDate, setShowDateFilter } = dateFilterSlice.actions;
export default dateFilterSlice.reducer;
