import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateOTP } from "../../services/user.services";
import { useSelector } from "react-redux";
import { getUserResetPasswordDetails } from "../../stateManagement/reducers/UserResetPasswordDetailsSlice";
import VerificationBox from "../../components/verificationBox/VerificationBox";
import {
  RESEND_CODE_TIME,
  SOMETHING_WENT_WRONG
} from "../../constant/Constants";
import { Paper } from "@mui/material";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the Verification Page UI.
 * @returns {component} Verification Page UI component
 */
const VerificationPage = () => {
  const location = useLocation();
  const config = useSelector((state) => getConfigDetails(state));
  /**
   * @constant {number} expiryTime - gets the expiry time from the localstorage.
   */
  const expiryTime = location && location.state;

  const [errorInfo, setErrorInfo] = useState("");
  const [showErrorPop, setShowErrorPop] = useState(false);
  const navigate = useNavigate();

  /**
   *@constant {object} recoverUserDetails -  User Details stored to change it's password
   */
  const recoverUserDetails = useSelector((state) =>
    getUserResetPasswordDetails(state)
  );

  /**
   * Navigates to Forgot Password if recoder user details is not there.
   */
  useEffect(() => {
    if (recoverUserDetails.email === "") {
      navigate("/forgotpassword");
    }
  }, [recoverUserDetails.email, navigate]);

  /**
   * @function showToastCallback - Handles the Snackbar close operation.
   */
  const showToastCallback = () => {
    setShowErrorPop(false);
  };

  const handleConfirmVerification = (code) => {
    const otpKey = code;
    const otpRequest = { otpKey: otpKey, ...recoverUserDetails };
    validateOTP(config.server, otpRequest)
      .then((res) => {
        if (res && res.status === 200) {
          localStorage.removeItem("userRecoverDetails");
          sessionStorage.setItem("shortToken", res.data.shortToken);
          sessionStorage.setItem(
            "channelPartnerCode",
            res.data.channelPartnerCode
          );
          navigate("/resetpassword");
        } else {
          showPopup(res.data.message);
        }
      })
      .catch((err) => {
        showPopup(err);
      });
  };

  const showPopup = (message) => {
    setShowErrorPop(true);
    setErrorInfo(message ? message : SOMETHING_WENT_WRONG);
  };

  return (
    <Paper
      data-testid="verificationPage"
      sx={{
        width: { xs: "90%", sm: "fit-content" }
      }}
    >
      <VerificationBox
        payload={recoverUserDetails}
        handleConfirmVerification={handleConfirmVerification}
        errorInfo={errorInfo}
        showErrorPop={showErrorPop}
        showPopup={showPopup}
        hidePopup={showToastCallback}
        isPopup={false}
        isOnlyMobileVerification={false}
        codeExpireTime={expiryTime}
        resendCodeTime={RESEND_CODE_TIME}
      ></VerificationBox>
    </Paper>
  );
};

export default VerificationPage;
