import React, { memo, useState } from "react";
import {
  Button,
  createTheme,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import close from "./../../assets/images/close.png";
import {
  CANCEL,
  CONFIRM,
  CONFIRMATION,
  KEY_BLOCK_USER,
  REASON,
  REASON_BLOCK_CHILD_USER,
  SELECT
} from "../../constant/Constants";
import { muiMenuItemStyles } from "../../style/CustomStyles";

/**
 * Renders the Confirmation modal dialog box.
 * @param {object} props - Props passed to the Confirmation Modal Dialog Box.
 * @returns {component} The confirmation modal Dialog box.
 */
const ConfirmationModal = (props) => {
  const {
    handleCloseConfirmationModal,
    showConfirm,
    label,
    handleConfirm,
    labelKey,
    reasonprop = ""
  } = props;
  const [reason, setReason] = useState(reasonprop);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };
  /**
   * Handles the Confirmation Modal close operation.
   * @function handleClose - Handle the close operation of the Confirmation modal dialog box.
   */
  const handleClose = () => {
    setReason("");
    handleCloseConfirmationModal();
  };

  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            background: "#F8F8F8 0% 0% no-repeat padding-box",
            "&.MuiMenu-paper": {
              marginTop: "5px"
            }
          }
        }
      }
    }
  });
  const isDisabled = () => {
    return reason === "" && labelKey === KEY_BLOCK_USER ? true : false;
  };
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={showConfirm}
      sx={{ p: 1 }}
      data-testid="ConfirmationModalDialog"
      // onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pt: 2,
          pr: 3
        }}
      >
        <img
          src={close}
          data-testid="close"
          alt="X"
          onClick={handleClose}
          height="15px"
          style={{
            cursor: "pointer"
          }}
        ></img>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "BukraMedium",
          letterSpacing: "0px",
          color: "#485061",
          fontSize: "16px"
        }}
      >
        {CONFIRMATION}
      </Typography>
      <DialogContent sx={{ pb: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Typography
            sx={{
              pl: { lg: 4 },
              pr: { lg: 4 },
              textAlign: "center",
              fontFamily: "BukraRegular",
              letterSpacing: "0px",
              color: "#797f8b",
              fontSize: "16px"
            }}
          >
            {label}
          </Typography>
          {labelKey === KEY_BLOCK_USER && (
            <FormControl sx={{ minWidth: 120, mt: 2 }} size="small" fullWidth>
              <label htmlFor="demo-select-small" className="inputLabel">
                {REASON} <span className="asterisk">*</span>
              </label>
              <ThemeProvider theme={theme}>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={reason}
                  displayEmpty
                  inputProps={{
                    "data-testid": "reason"
                  }}
                  onChange={(e) => handleReasonChange(e)}
                  renderValue={(reasonValue) =>
                    reasonValue ? (
                      <Typography
                        sx={{
                          letterSpacingpacing: "0px",
                          color: "#0F0F10",
                          opacity: 1,
                          fontSize: "14px",
                          fontFamily: "BukraRegular"
                        }}
                      >
                        {reasonValue}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          letterSpacingpacing: "0px",
                          color: "#0F0F10",
                          opacity: 0.5,
                          fontSize: "14px",
                          fontFamily: "BukraRegular"
                        }}
                      >
                        {SELECT}
                      </Typography>
                    )
                  }
                  sx={{
                    height: "44px",
                    background: "#EDF4F6 0% 0% no-repeat padding-box",
                    fontFamily: "BukraRegular",
                    ".MuiSelect-select": {
                      fontSize: "14px",
                      color: "#0F0F10",
                      fontFamily: "BukraRegular"
                    }
                  }}
                >
                  {REASON_BLOCK_CHILD_USER.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{
                        ...muiMenuItemStyles
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </ThemeProvider>
            </FormControl>
          )}
          <Box
            sx={{
              display: "flex",
              mt: 4,
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Button
              onClick={handleClose}
              data-testid="viewProfileButton"
              variant="outlined"
              sx={{
                borderRadius: "20px",
                borderColor: "#0F57CB",
                textTransform: "none",
                fontFamily: "BukraRegular",
                letterSpacing: 0,
                color: "#0F57CB",
                height: "35px",
                minWidth: "129px",
                fontSize: { lg: "15px", xs: "14px" }
              }}
            >
              {CANCEL}
            </Button>
            <Button
              data-testid="confirm"
              onClick={() => {
                handleConfirm(reason);
              }}
              disabled={isDisabled()}
              variant="outlined"
              sx={{
                ml: { sm: 2 },
                mt: { xs: 2, sm: 0 },
                borderRadius: "20px",
                height: "35px",
                minWidth: "160px",
                fontSize: { lg: "15px", xs: "14px" },
                textTransform: "none",
                fontFamily: "BukraMedium",
                letterSpacing: 0,
                color: "#ffffff",
                background:
                  "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                "&.Mui-disabled": {
                  background:
                    "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                  opacity: 0.5,
                  color: "#ffffff"
                }
              }}
            >
              {CONFIRM}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ConfirmationModal);
