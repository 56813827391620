import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Paper,
  Toolbar,
  Typography,
  Box,
  TextField,
  InputBase,
  Divider,
  FormHelperText,
  InputAdornment,
  ListSubheader,
  Input
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import {
  TRANSFER_INFORMATION,
  ADD_TRANSFER,
  CONFIRM,
  NOTES,
  OPTIONAL,
  CREDIT,
  AMOUNT,
  TRANSFER_TO,
  IS_REQUIRED,
  CREDIT_KEY,
  ENTER_AMOUNT,
  CREDIT_BALANCE_ERR_LABEL,
  REMAINING_BALANCE,
  TRANSACTION_SUCCESS_STATUS,
  CREDIT_TRANSFER_SUCCESSFULL,
  REMOVE,
  TRANSACTION_FAILED,
  MD_USER_ROLE_TYPE,
  SUB_DISTRIBUTOR,
  RETAILER,
  SELECT,
  NO_RESULTS_FOUND,
  MAX_NOTES_LENGTH
} from "./../../constant/Constants";
import { ThemeProvider } from "@mui/material/styles";
import "./TransferInformation.scss";
import TransferInfoModal from "../transferInfoModal/TransferInfoModal";
import MultipleCreditTransferPopup from "../multipleCreditTransferPopup/MultipleCreditTransferPopup";
import PasscodeError from "../passcodeError/PasscodeError";
import {
  getCreditBalance,
  getChildDetails,
  getChildChannelPartners,
  transferCredit,
  getCreditSummary
} from "../../services/user.services";
import { useSelector, useDispatch } from "react-redux";
import {
  getChannelPartnerCode,
  getUserRoleType
} from "../../stateManagement/reducers/LoginSlice";
import {
  currencyConvertToReadable,
  convertStringToNumber,
  updatePageVisit,
  handleMouseDownOnAndornment,
  failureToastMessage,
  successToastMessage,
  allowNumberandDecimals,
} from "../../constant/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { setCreditsTransferStatus } from "../../stateManagement/reducers/CreditsTransferStatusSlice";
import CustomPasscodeVerification from "../customPasscodeVerification/CustomPasscodeVerification";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import TransferErrorModal from "../transferErrorModal/TransferErrorModal";
import { setMultipleTransferMsg } from "../../stateManagement/reducers/MultipleTransferSlice";
import searchIcon from "../../assets/images/search.svg";
import { urlPath } from "../../constant/ScopeMenus";
import {
  theme,
  inputTheme,
  multilineInputTheme
} from "../../style/themes/CommonThemes";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";

/**
 * Renders the Credits Transfer form UI for the direct Transfers
 * @returns {component} the Credits Transfer UI for the direct transfers.
 */
const TransferInformation = () => {
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const childChannelPartnerLabel =
    fetchUserRoleType === MD_USER_ROLE_TYPE ? SUB_DISTRIBUTOR : RETAILER;
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const [formValues, setFormValues] = useState([]);
  const [finalFormValues, setFinalFormValues] = useState([]);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [transferToInfoDetails, setTransferToInfoDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPasscodeModal, setShowPasscodeModal] = useState(false);
  const [showNoAttemptsModal, setShowNoAttemptsModal] = useState(false);
  const [creditAmountFocus, setCreditAmountFocus] = useState(false);
  const [creditDetails, setCreditDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const getdate = useSelector((state) => getFilteredDateFromStore(state));
  const { endDate, startDate } = getdate;
  /**
   * @constant {string} channelPartnerCode Returns theChannelPartner code from the store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  const [balance, setBalance] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [childChannelPartnerList, setChildChannelPartnerList] = useState([]);
  const [parentEmail, setParentEmail] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const config = useSelector((state) => getConfigDetails(state));
  /**
   * @constant {string} childChannelPartnerCode Returns the Child ChannelPartner code from the localstorage.
   */
  const childChannelPartnerCode =
    location && location?.state?.channelPartnerCode;

  /**
   * Sets the Page visits data.
   */
  useEffect(() => {
    if (location.pathname === urlPath.transferCredits && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * Sets the initial form values.
   */
  useEffect(() => {
    setFormValues([
      {
        toUser: "",
        toUserGroup: null,
        creditsAmount: "",
        currency: country.countryCurrencyCode
          ? country.countryCurrencyCode
          : "",
        transferNotes: "",
        reverserCreditTransferReason: "",
        transferType: CREDIT_KEY,
        reason: null,
        errors: {}
      }
    ]);

    if (config.server) {
      /**
       * Gets the Child Channel Partner List.
       */
      getChildChannelPartners(config.server).then((res) => {
        if (res && res.status === 200) {
          setChildChannelPartnerList(res.data);
        } else {
          setChildChannelPartnerList([]);
        }
      });

      /**
       * Gets the Credit Balance of the ChildChannelPartner based on the ChildChannelPartner code.
       */
      channelPartnerCode &&
        getCreditBalance(config.server, channelPartnerCode, CREDIT_KEY)
          .then((res) => {
            if (res && res.status && res.status === 200) {
              setBalance(res.data.balanceCredits);
              setRemainingBalance(res.data.balanceCredits);
            } else {
              setBalance(0);
              setRemainingBalance(0);
            }
          })
          .catch(() => {
            setBalance(0);
            setRemainingBalance(0);
          });

      /**
       * Get the Details of the Child Channel Partner if its already selected.
       */
      childChannelPartnerCode &&
        getChildDetails(config.server, childChannelPartnerCode)
          .then((res) => {
            if (res && res.status && res.status === 200) {
              setFormValues([
                {
                  toUser: res.data.channelPartnerCode,
                  toUserGroup: res.data.channelPartnerType,
                  creditsAmount: "",
                  currency: country?.countryCurrencyCode,
                  transferNotes: "",
                  reverserCreditTransferReason: "",
                  transferType: CREDIT_KEY,
                  reason: "",
                  errors: {}
                }
              ]);
            } else {
              setCreditDetails({});
            }
          })
          .catch((err) => err);
    }
  }, [
    channelPartnerCode,
    childChannelPartnerCode,
    country.countryCurrencyCode,
    config.server
  ]);

  /**
   * Gets the remaining balance based on the credit Amount entered to transfer.
   */
  useEffect(() => {
    const Total = formValues.reduce(
      (accumulator, current) =>
        accumulator + Number(current.creditsAmount ? current.creditsAmount : 0),
      0
    );
    const totalbalance = balance;
    setRemainingBalance(totalbalance - Total);
  }, [formValues, balance]);

  /**
   * Handles the popup on click of Add transfer
   * @function handlePopup - Display popup on click of Add transfer button.
   */
  const handlePopup = () => {
    setShowPopup(true);
  };

  /**
   * Handle the Popup closeoperation
   * @function handleClosePopup - Closes all the Popups.
   */
  const handleClosePopup = () => {
    setShowPopup(false);
    setShowPasscodeModal(false);
    setShowNoAttemptsModal(false);
  };

  /**
   * Handles the Passcode Modal UI display.
   * @function handleOpenPasscodeModal - Display the Passcode verification Popup UI.
   */
  const handleOpenPasscodeModal = () => {
    setShowPasscodeModal(true);
  };

  /**
   * Handles the Passcode attempt exhaust UI.
   * @function handleOpenNoAttemptsModal - Display the Passcode Attempt exhaust Popup UI.
   * @param {string} value - Parent Email
   */
  const handleOpenNoAttemptsModal = (value) => {
    setParentEmail(value);
    setShowNoAttemptsModal(true);
  };

  /**
   * Handle the New form Addition
   * @function addNewForm - Performs action to add one more Form value field.
   */
  const addNewForm = () => {
    setFormValues([
      ...formValues,
      {
        toUser: "",
        creditsAmount: "",
        toUserGroup: "",
        currency: country && country.countryCurrencyCode,
        transferNotes: "",
        reverserCreditTransferReason: "",
        transferType: CREDIT_KEY,
        reason: null,
        errors: {}
      }
    ]);
    setShowPopup(false);
  };

  /**
   * Handles the Repeat Last Transfer operation.
   * @function repeatLastTransfer - to Add one more Field for Multiple Credits Transfer containing the Same form value as previous one.
   */
  const repeatLastTransfer = () => {
    const lastFilledElement = formValues[formValues.length - 1];
    setFormValues([
      ...formValues,
      {
        toUser: lastFilledElement.toUser,
        toUserGroup: lastFilledElement.toUserGroup,
        creditsAmount: lastFilledElement.creditsAmount,
        currency: lastFilledElement.currency,
        transferNotes: lastFilledElement.transferNotes,
        reverserCreditTransferReason:
          lastFilledElement.reverserCreditTransferReason,
        transferType: lastFilledElement.transferType,
        reason: lastFilledElement.reason,
        errors: lastFilledElement.errors
      }
    ]);
    setShowPopup(false);
  };

  /**
   * handle the transfer confirmation operation.
   * @function handleTransferConfirm - Handle the Submission of Credits Transfer form
   */
  const handleTransferConfirm = () => {
    const isTransferToDuplicate = foundDuplicateName();
    const filteredValues = formValues.map(
      ({
        toUser,
        toUserGroup,
        creditsAmount,
        currency,
        transferNotes,
        reverserCreditTransferReason,
        transferType,
        reason
      }) => ({
        toUser,
        toUserGroup,
        creditsAmount,
        currency,
        transferNotes,
        reverserCreditTransferReason,
        transferType,
        reason
      })
    );
    setFinalFormValues(filteredValues);

    if (isTransferToDuplicate.length > 0) {
      setShowTransferModal(true);
      setTransferToInfoDetails(isTransferToDuplicate);
    } else {
      setShowPasscodeModal(true);
    }
  };

  /**
   * Handle the Close operation of the Transfer Details before confirmation.
   * @function handleTransferInfoModalclose closes the Transfer info Popup.
   */
  const handleTransferInfoModalclose = () => {
    setShowTransferModal(false);
  };

  /**
   * Handles the Searching of duplicate Names after filling the Credits transfer form.
   * @function foundDuplicateName - Finds and returns the Duplicate Child Channel partner code while Credits transfer.
   * @returns {object} Objects containing the Duplicate Transfers.
   */
  const foundDuplicateName = () => {
    const getDuplicatePacks = formValues.filter((nnn, index) => {
      return formValues.find(
        (x, ind) => index !== ind && nnn.toUser === x.toUser
      );
    });
    const uniquePacks = getDuplicatePacks.filter(
      (tag, index, array) =>
        array.findIndex((t) => t.toUser === tag.toUser) === index
    );
    const finalDuplicates = uniquePacks.map((unique) => ({
      ...unique,
      toUserName: getLabel(unique.toUser)
    }));
    return finalDuplicates;
  };

  /**
   * Gives error on wrong Input values entered for Transfer to user and Credits Amount.
   * @function validate - Validates the form fields entered.
   * @param {string} nameofInput - Transfer to user.
   * @param {string} values - Credits Amount
   * @returns  {string} any error message if there regarding the Transfer to user or Credits Amount.
   */
  const validate = (nameofInput, values) => {
    const errors = {};
    if (nameofInput === "toUser") {
      if (!values) {
        errors.toUser = `${TRANSFER_TO} ${IS_REQUIRED}`;
      }
    } else if (nameofInput === "creditsAmount") {
      if (!values) {
        errors.creditsAmount = `${AMOUNT} ${IS_REQUIRED}`;
      }
    }
    return errors;
  };

  /**
   * Handles the form input field change operation.
   * @function handleChange - Performs action on form input field change.
   * @param {number} i - index no. of form array.
   * @param {object} e - event object
   */
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];

    if (e.target.name === "toUser") {
      newFormValues[i]["toUserGroup"] = getUserGroup(e.target.value);
    }

    if (e.target.name === "creditsAmount") {
      if (allowNumberandDecimals(e.target.value) || e.target.value === "") {
        newFormValues[i][e.target.name] = e.target.value;
      }
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    newFormValues[i]["errors"] = validate(e.target.name, e.target.value);
    setFormValues(newFormValues);
  };

  /**
   * Provides the User group based on the ChannelPartner Code Selected.
   * @function getUserGroup - Gets the user group of the Child Channel Partner Selected.
   * @param {string} distributor - ChannelParnterCode
   * @returns {number} the User Group based on the channel partner code.
   */
  const getUserGroup = (distributor) => {
    const selectedDistributor =
      childChannelPartnerList &&
      childChannelPartnerList.find(
        (selected) => selected.channelPartnerCode === distributor
      );
    return selectedDistributor && selectedDistributor.channelPartnerType;
  };

  /**
   * Removes the Selected Form Field for the Credits Transfer Form.
   * @function removeFormFields - removes the Selected form array.
   * @param {index} i  - Array index of the form field.
   */
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.pop();
    setFormValues(newFormValues);
  };

  /**
   * Handle the Transfet of Credit after entering the Passcode after the Form Submission.
   * @function handleConfirmTransfer - Handles the Credits transfer after Passcode verification.
   * @param {string} passCode - Channel Partner passCode.
   */
  const handleConfirmTransfer = (passCode) => {
    setLoader(true);
    const transferDetails = {
      passcode: passCode,
      channelPartnerCreditTransfer: finalFormValues
    };

    transferCredit(config.server, transferDetails)
      .then((res) => {
        dispatch(getCreditSummary({ startDate, endDate }));
        if (res && res.status === 200) {
          handleCreditTransferSuccess();
        } else if (res && res.status === 207) {
          const errorResponse = res.data;
          dispatch(setMultipleTransferMsg(errorResponse));
          setShowErrorModal(true);
        } else {
          const error = res.data && res.data.creditFailureResponseList[0];
          const message =
            error && error.message ? error.message : TRANSACTION_FAILED;
          handleCreditTransferFail(message);
        }
      })
      .catch(() => {
        handleCreditTransferFail(TRANSACTION_FAILED);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleCreditTransferFail = (message) => {
    let toastDetails = failureToastMessage(message, "");
    dispatch(updateToastMessage(toastDetails));
  };

  const handleCreditTransferSuccess = () => {
    let toastDetails = successToastMessage(CREDIT_TRANSFER_SUCCESSFULL);
    let transferDetails = {
      transactionStatus: TRANSACTION_SUCCESS_STATUS,
      statusValue: 0
    };
    dispatch(updateToastMessage(toastDetails));
    dispatch(setCreditsTransferStatus(transferDetails));
    navigateTo("/credits");
  };

  /**
   * Provides the Select Channel Partner Code's Label Name in Select options.
   * @function getLabel - Gets the Formatted label for the child channel partner select option.
   */
  const getLabel = useCallback(
    (value) => {
      const distributor =
        childChannelPartnerList &&
        childChannelPartnerList.length > 0 &&
        childChannelPartnerList.find(
          (channelPartnerDistributor) =>
            channelPartnerDistributor.channelPartnerCode === value
        );
      return distributor
        ? `${distributor.channelPartnerName} [${distributor.companyName}]`
        : "";
    },
    [childChannelPartnerList]
  );

  /**
   * Checks Whether the Credits transfer Confirm button is enabled or disabled.
   * @function isConfirmEnabled - Sets whether to enable or disable the Credits transfer Confirm button.
   * @returns {boolean} true or false which results in Enable or Disable of the button.
   */
  const isConfirmEnabled = () => {
    let value = false;
    formValues &&
      formValues.map((item) => {
        if (
          item.toUser.length === 0 ||
          item.creditsAmount.length === 0 ||
          Number(item.creditsAmount) <= 0 ||
          Number(item.creditsAmount) > balance ||
          remainingBalance < 0
        ) {
          value = true;
        }
        return null;
      });
    return value;
  };

  /**
   * Checks whether to enable or disable the Add Transfer Button.
   * @function isAddTransferDisabled - Set Disable/Enable action on the Add Transfer button.
   * @returns {boolean} true or false to Disable or Enable the Add Transfer button
   */
  const isAddTransferDisabled = () => {
    return (
      formValues.length > 0 &&
      formValues.some((value) => value.toUser === "" || remainingBalance <= 0)
    );
  };

  /**
   * Checks whether the object is empty.
   * @function isObjectEmpty - Checks the Object if its empty.
   * @param {object} objectName - object
   * @returns {bolean} true or false
   */
  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  /**
   * Handles the Close operation on MULTI_STATUS error UI box.
   * @function handleCloseMultipleError - Close the MULTI_STATUS error UI modal box
   */
  const handleCloseMultipleError = () => {
    setShowErrorModal(false);
  };

  /**
   * Handles the Select Dropdown Search Operation.
   *@function containsText Handles the Select Dropdown search functionality
   */
  const containsText = useCallback(
    (text, searchTextValue) =>
      getLabel(text).toLowerCase().includes(searchTextValue.toLowerCase()),
    [getLabel]
  );

  /**
   * Displays the options based on the result of the search Input string.
   */
  const displayedOptions = useMemo(
    () =>
      childChannelPartnerList &&
      childChannelPartnerList.length > 0 &&
      childChannelPartnerList.filter((option) =>
        containsText(option.channelPartnerCode, searchText)
      ),
    [childChannelPartnerList, searchText, containsText]
  );

  const TransferInputs = () => {
    return formValues.map((item, index) => {
      return (
        <Box
          key={index}
          data-testid="transferInformation"
          className="transferInformation"
        >
          {index !== 0 ? (
            <Divider sx={{ mt: 4, mb: 4 }} variant="fullWidth"></Divider>
          ) : (
            ""
          )}
          <Box
            sx={{
              ml: { sm: 7.8, xs: 2 },
              mt: { sm: 0, xs: 1 },
              mr: { sm: 3 }
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 16 }}
              sx={{
                flexDirection: { xs: "column", sm: "row" }
              }}
            >
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                  <label htmlFor="demo-select-small" className="inputLabel">
                    {TRANSFER_TO} <span className="asterisk">*</span>
                  </label>

                  <ThemeProvider theme={theme}>
                    <Box
                      sx={{
                        mt: 1.5,
                        height: "44px",
                        border: "1px solid #e8e8e8",
                        borderRadius: "4px"
                      }}
                    >
                      <Select
                        MenuProps={{
                          autoFocus: false,
                          PaperProps: {
                            style: {
                              maxHeight: 155,
                              overflow: "auto",
                              scrollbarWidth: "thin"
                            }
                          },
                          MenuListProps: {
                            style: {
                              paddingTop: 0
                            }
                          }
                        }}
                        inputProps={{ "data-testid": "transferTo" }}
                        labelId="demo-select-small"
                        id="demo-select-small"
                        name="toUser"
                        value={item.toUser}
                        onChange={(e) => handleChange(index, e)}
                        onClose={() => setSearchText("")}
                        displayEmpty
                        renderValue={(transferId) => {
                          if (!transferId) {
                            return (
                              <Typography
                                sx={{
                                  letterSpacingpacing: "0px",
                                  color: "#282828",
                                  opacity: 0.5,
                                  fontSize: "14px",
                                  fontFamily: "BukraRegular"
                                }}
                              >
                                {`${SELECT} ${childChannelPartnerLabel}`}
                              </Typography>
                            );
                          }
                          return getLabel(transferId);
                        }}
                        sx={{
                          height: "100%",
                          width: "100%",
                          backgroundColor: "#edf4f6",
                          fontFamily: "BukraRegular",
                          ".MuiSelect-select": {
                            fontSize: "14px",
                            color: "#0F0F10",
                            fontFamily: "BukraRegular"
                          }
                        }}
                      >
                        <ListSubheader sx={{ p: "4px " }}>
                          <Input
                            autoComplete="off"
                            placeholder="Type to search..."
                            autoFocus
                            sx={{
                              width: "100%",
                              maxWidth: "95%",
                              m: 1,
                              p: "4px"
                            }}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ cursor: "default" }}
                              >
                                <img
                                  src={searchIcon}
                                  alt="Search Icon"
                                  onMouseDown={handleMouseDownOnAndornment}
                                ></img>
                              </InputAdornment>
                            }
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          ></Input>
                        </ListSubheader>
                        {displayedOptions && displayedOptions.length > 0 ? (
                          displayedOptions.map((distributor) => {
                            return (
                              <MenuItem
                                key={distributor.channelPartnerCode}
                                value={distributor.channelPartnerCode}
                                sx={{
                                  ...muiMenuItemStyles
                                }}
                              >
                                {getLabel(distributor.channelPartnerCode)}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <Typography
                            disabled
                            sx={{
                              background: "white",
                              px: 2,
                              fontSize: "12px",
                              fontFamily: "BukraRegular"
                            }}
                          >
                            {NO_RESULTS_FOUND}
                          </Typography>
                        )}
                      </Select>
                    </Box>
                  </ThemeProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                  <label className="inputLabel">
                    {CREDIT} {AMOUNT} <span className="asterisk">*</span>
                  </label>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "4px",
                      background: " #EDF4F6 0% 0% no-repeat padding-box",
                      height: "44px",
                      width: { sm: "100%" },
                      mt: 1.5,
                      border: creditAmountBorderColor()
                    }}
                  >
                    <Box
                      sx={{
                        height: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "80px",
                        color: "#282828",
                        fontSize: "13px",
                        fontFamily: "BukraRegular",
                        background: " #7B7D801A 0% 0% no-repeat padding-box"
                      }}
                    >
                      {country && country.countryCurrencyCode}
                    </Box>
                    <ThemeProvider theme={inputTheme}>
                      <InputBase
                        autoComplete="off"
                        sx={{ ml: 1, flex: 1, pt: 0.5 }}
                        onFocus={() => handleFocusOnCreditAmount()}
                        onBlur={() => handleFocusOnCreditAmount()}
                        name="creditsAmount"
                        placeholder={ENTER_AMOUNT}
                        value={item.creditsAmount}
                        onChange={(e) => handleChange(index, e)}
                        inputProps={{ "data-testid": "creditsAmount", min: 0 }}
                      />
                    </ThemeProvider>
                  </Box>

                  <FormHelperText
                    sx={{
                      textAlign: "left"
                    }}
                    className={`errorFormHelperText ${
                      remainingBalance < 0 ? "balance_error" : "balance_ok"
                    }`}
                  >
                    {remainingBalance < 0
                      ? CREDIT_BALANCE_ERR_LABEL
                      : `${REMAINING_BALANCE}: ${
                          country?.countryCurrencyCode
                        } ${currencyConvertToReadable(remainingBalance)}`}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <label className="inputLabel">
                  {NOTES} ({OPTIONAL})
                </label>
                <ThemeProvider theme={multilineInputTheme}>
                  <TextField
                    autoComplete="off"
                    onDrop={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    inputProps={{
                      "data-testid": "multilineChange",
                      maxLength: MAX_NOTES_LENGTH
                    }}
                    size="medium"
                    multiline
                    name="transferNotes"
                    rows={3}
                    onChange={(e) => handleChange(index, e)}
                    variant="outlined"
                    value={item.transferNotes}
                    fullWidth
                    sx={{
                      mt: 1.5,
                      backgroundColor: "#edf4f6",
                      fontSize: "14px"
                    }}
                  ></TextField>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    });
  };

  const creditAmountBorderColor = () => {
    let color = "#e8e8e8";
    if (convertStringToNumber(remainingBalance) < 0) {
      color = "#DE0909";
    } else if (creditAmountFocus) {
      color = "#0f57cb";
    }
    return `1px solid ${color}`;
  };
  const handleFocusOnCreditAmount = () => {
    setCreditAmountFocus(!creditAmountFocus);
  };

  return (
    <Paper elevation={2} sx={{ p: 1 }}>
      {loader && <OverlayLoader />}
      {showTransferModal && (
        <TransferInfoModal
          transferToInfoDetails={transferToInfoDetails}
          showTransferModal={showTransferModal}
          handleTransferInfoModalclose={handleTransferInfoModalclose}
          handleOpenPasscodeModal={handleOpenPasscodeModal}
        ></TransferInfoModal>
      )}
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        <Typography
          sx={{
            color: "#464E5F",
            letterSpacing: "0px",
            fontSize: "16px",
            fontFamily: "BukraMedium"
          }}
        >
          {TRANSFER_INFORMATION}
        </Typography>
      </Toolbar>
      {TransferInputs()}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 5,
          pr: { sm: 3 }
        }}
      >
        {creditDetails && isObjectEmpty(creditDetails) && (
          <Button
            disabled={isAddTransferDisabled()}
            className={`${isAddTransferDisabled() ? "disable_add" : ""}`}
            sx={{
              color: "#0f57cb",
              padding: 0,
              lineHeight: 1.5,
              fontSize: "15px",
              textTransform: "capitalize",
              ":hover": {
                textDecoration: "underline",
                background: "none"
              },
              fontWeight: "600"
            }}
            data-testid="handleMultipleTransfer"
            onClick={() => {
              handlePopup();
            }}
          >
            {ADD_TRANSFER}
          </Button>
        )}
        {formValues && formValues.length > 1 && (
          <Typography
            data-testid="removeIcon"
            sx={{
              pl: 1,
              color: "#0f57cb",
              fontSize: "15px",
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline"
              }
            }}
            onClick={() => removeFormFields()}
          >
            {REMOVE}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2
        }}
      >
        <Button
          disabled={isConfirmEnabled()}
          data-testid="confirmBtn"
          variant="outlined"
          sx={{
            background:
              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
            color: "#ffffff",
            "&.Mui-disabled": {
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              opacity: 0.5
            },
            borderRadius: "20px",
            letterSpacing: "0px",
            fontFamily: "BukraMedium",
            fontSize: "15px",
            width: { sm: "160px", xs: "100%" },
            textTransform: "none",
            height: "40px",
            mt: { sm: 0, xs: 1 }
          }}
          onClick={() => handleTransferConfirm()}
        >
          {CONFIRM}
        </Button>
      </Box>
      {showPopup && (
        <MultipleCreditTransferPopup
          repeatLastTransfer={repeatLastTransfer}
          addNewForm={addNewForm}
          handleClosePopup={handleClosePopup}
        />
      )}
      {showPasscodeModal && (
        <CustomPasscodeVerification
          handleConfirmTransfer={handleConfirmTransfer}
          showPasscodeModal={showPasscodeModal}
          handleClosePasscodeVerification={handleClosePopup}
          handleOpenNoAttempts={handleOpenNoAttemptsModal}
        />
      )}
      {showNoAttemptsModal && (
        <PasscodeError
          parentEmail={parentEmail}
          handleCloseNoAttempts={handleClosePopup}
        />
      )}

      {showErrorModal && (
        <TransferErrorModal
          handleClosePopup={handleCloseMultipleError}
        ></TransferErrorModal>
      )}
    </Paper>
  );
};

export default TransferInformation;
