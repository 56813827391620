import React from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import {
  Alert,
  AlertTitle,
  Box,
  ListSubheader,
  Typography
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import close from "./../../assets/images/close.png";
import Toast_Succes_Icon from "./../../assets/images/toast_success_icon.svg";
import toastErrorIcon from "./../../assets/images/toast_error_small.png";
import { getDurationLabel } from "../../constant/Utils";
import {
  ERROR,
  NEW_SINGLE_SUBSCRIPTION_BOUGHT,
  NEW_SUBSCRIPTION_BOUGHT,
  PLAN_NAME,
  PLAN_PERIOD,
  SUBSCRIPTION_RENEW_SUCEESFULLY,
  SUCCESS
} from "../../constant/Constants";

/**
 * Handles the UI for the Multiple Types of Response - Success and Error.
 * @param {{handleCloseMultiErrorDialogBox:function, multiErrorData:array<object>,isFromRenew:Boolean}} {handleCloseMultiErrorDialogBox,multiErrorData,successLabel} Prop elements passed to the MULTI STATUS Error Dialog Box.
 * @returns {component} the Dialog Box UI Component for the Multiple Type of Messages.
 */
const MultiErrorDialogBox = ({
  handleCloseMultiErrorDialogBox,
  multiErrorData,
  isFromRenew
}) => {
  /**
   * Handle the close operation of the Multiple Messages Dialog Box.
   */
  const handleClose = () => {
    handleCloseMultiErrorDialogBox();
  };

  /**
   * @constant {JSX} action - closeIcon UI for the close Icon.
   */
  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      data-testid="close"
      onClick={handleClose}
    >
      <img src={close} height="12px" alt="cls"></img>
    </IconButton>
  );

  /**
   * Filters the Error Message containing the MULTI_STATUS
   * @constant {object} errorMessageDetails - Error Message object.
   */
  const errorMessageDetails = multiErrorData.filter(
    (item) => item.httpStatus === "MULTI_STATUS"
  );

  /**
   * Filters the Success Message containing the MULTI_STATUS
   * @constant {object} successDetails - Success Message object.
   */
  const successDetails = multiErrorData.filter((item) => item.orderId);

  const sucessDescriptionOfSubscription = (data) => {
    if (isFromRenew) {
      return `${data.length} ${SUBSCRIPTION_RENEW_SUCEESFULLY}`;
    } else {
      if (data.length > 1) {
        return NEW_SUBSCRIPTION_BOUGHT;
      } else {
        return NEW_SINGLE_SUBSCRIPTION_BOUGHT;
      }
    }
  };
  /**
   * Renders the UI for the Failure case messages.
   * @param {array} data - contains the Error Messages in array of object
   * @returns {component} - the UI component for the Failure case messages.
   */
  const errorTranscationUI = (data) => {
    return (
      <Snackbar
        data-testid="error"
        open={true}
        sx={{
          "&.MuiSnackbar-anchorOriginBottomLeft ": {
            top: "0px",
            position: "relative",
            left: "0px",
            backgroundColor: "#FFFFFF",
            borderRadius: "2px",
            width: { xl: "100%", sm: "450px" },
            alignItems: "flex-start",
            ".MuiPaper-root": {
              pt: 0,
              pb: 0,
              alignItems: "center"
            }
          }
        }}
      >
        <Box sx={{ width: "inherit" }}>
          <Alert
            action={action}
            icon={<img src={toastErrorIcon} alt="err" height="16px"></img>}
            sx={{
              backgroundColor: "#fde7e7",
              fontSize: `12px`,
              letterSpacing: "0px",
              ".MuiAlert-icon,.MuiAlert-action": {
                alignItems: "center",
                pt: 0,
                pb: 0
              }
            }}
          >
            <AlertTitle
              sx={{
                color: "red",
                fontFamily: "BukraMedium",
                letterSpacing: 0,
                mb: 0,
                fontSize: "14px",
                pl: 1
              }}
            >
              {ERROR}
            </AlertTitle>
          </Alert>
          <List
            sx={{
              maxHeight: "200px",
              overflow: "auto",
              pt: 0,
              border: "2px solid #ededed",
              ml: 2,
              mr: 2,
              mt: 1,
              mb: 1,
              "&::-webkit-scrollbar": {
                width: "4px"
              },
              scrollbarWidth: "thin"
            }}
          >
            <ListSubheader
              sx={{
                pt: 0.5,
                pb: 0.5,
                display: "flex",
                boxShadow:
                  "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
              }}
            >
              {" "}
              <ListItemText
                sx={{
                  flex: 0.6,
                  lineBreak: "anywhere",
                  mx: 0.4
                }}
                primaryTypographyProps={{
                  fontSize: 12,
                  color: "#5b636f",
                  fontFamily: "BukraMedium",
                  letterSpacing: "0px"
                }}
              >
                {PLAN_NAME}
              </ListItemText>
              <ListItemText
                sx={{ flex: 0.4 }}
                primaryTypographyProps={{
                  fontSize: 12,
                  color: "#5b636f",
                  fontFamily: "BukraMedium",
                  letterSpacing: "0px"
                }}
              >
                {PLAN_PERIOD}
              </ListItemText>
              <ListItemText
                sx={{ flex: 0.8 }}
                primaryTypographyProps={{
                  fontSize: 12,
                  color: "#5b636f",
                  fontFamily: "BukraMedium",
                  letterSpacing: "0px"
                }}
              >
                {ERROR}
              </ListItemText>
            </ListSubheader>

            {data.map((item) => {
              return (
                <ListItem
                  key={`${item.planSkuCode}`}
                  sx={{
                    ":nth-of-type(even)": {
                      backgroundColor: "#f7fafb"
                    }
                  }}
                >
                  <ListItemText
                    sx={{
                      flex: 0.6,
                      lineBreak: "anywhere",
                      mx: 0.4
                    }}
                    primaryTypographyProps={{
                      fontSize: 10,
                      color: "#131415",
                      fontFamily: "BukraRegular",
                      letterSpacing: "0px"
                    }}
                  >
                    {item.planName}
                  </ListItemText>
                  <ListItemText
                    sx={{ flex: 0.4 }}
                    primaryTypographyProps={{
                      fontSize: 10,
                      color: "#131415",
                      fontFamily: "BukraRegular",
                      letterSpacing: "0px"
                    }}
                  >
                    {`${item.duration} ${getDurationLabel(item.period)}`}
                  </ListItemText>
                  <ListItemText
                    sx={{ flex: 0.8 }}
                    primaryTypographyProps={{
                      fontSize: 10,
                      color: "#131415",
                      fontFamily: "BukraRegular",
                      letterSpacing: "0px"
                    }}
                  >
                    {item.message}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Snackbar>
    );
  };

  /**
   * Renders the UI for the Success case messages.
   * @param {array} data - contains the Success Messages in array of object
   * @returns {component} the UI component for the Success case messages.
   */
  const successTranscationUI = (data) => {
    return (
      <Snackbar
        open={true}
        data-testid="success"
        sx={{
          "&.MuiSnackbar-anchorOriginBottomLeft ": {
            top: "0px",
            position: "relative",
            left: "0px",
            backgroundColor: "transparent",
            width: { xl: "100%", sm: "450px" },
            alignItems: "flex-start",
            ".MuiPaper-root": {
              pt: 0,
              pb: 0,
              alignItems: "center"
            }
          }
        }}
      >
        <Box sx={{ width: "inherit" }}>
          <Alert
            action={action}
            icon={<img src={Toast_Succes_Icon} alt="err" height="16px"></img>}
            sx={{
              backgroundColor: "#e6ffee",
              fontSize: `12px`,
              letterSpacing: "0px",
              border: "1px solid green",
              borderRadius: "8px",
              ".MuiAlert-icon,.MuiAlert-action": {
                alignItems: "center",
                pt: 0,
                pb: 0
              }
            }}
          >
            <AlertTitle
              sx={{
                color: "green",
                fontFamily: "BukraMedium",
                letterSpacing: "0px",
                mb: 0,
                fontSize: "14px",
                pl: 1
              }}
            >
              {SUCCESS}
            </AlertTitle>
            <Typography
              sx={{
                fontSize: "11px",
                color: "green",
                fontFamily: "BukraRegular",
                letterSpacing: "0px",
                pl: 1
              }}
            >
              {sucessDescriptionOfSubscription(data)}
            </Typography>{" "}
          </Alert>
        </Box>
      </Snackbar>
    );
  };

  return (
    <Dialog
      // onClose={handleClose}
      open={true}
      data-testid="multiError"
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: "transparent"
        }
      }}
    >
      <Stack spacing={2}>
        {successDetails.length > 0 && successTranscationUI(successDetails)}
        {errorMessageDetails.length > 0 &&
          errorTranscationUI(errorMessageDetails)}
      </Stack>
    </Dialog>
  );
};

export default MultiErrorDialogBox;
