import React from "react";
import "./ColoredCircle.scss";
/**
 * Renders the Circle with the selected Color code
 * @param {{color:string}} color - Hash color value
 * @returns {component} the UI containing circle with selected color code.
 */
const ColoredCircle = ({ color }) => {
  return color ? (
    <div>
      <span
        data-testid="colored"
        className="colored-circle"
        style={{
          backgroundColor: color
        }}
      ></span>
    </div>
  ) : (
    ""
  );
};

export default ColoredCircle;
