import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import search from "./../../assets/images/search.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  BUYNOW,
  BUY_NOW,
  CANCEL,
  INSUFFICENT_BALANCE_MSG,
  NEW_SINGLE_SUBSCRIPTION_BOUGHT,
  NEW_SUBSCRIPTION,
  PURCHASE_PLAN_TITTLE,
  NOT_ELIGIBLE,
  NO_CONTENT,
  NO_DATA,
  PACKAGES,
  planPeriodOption,
  PLAN_PERIOD,
  PURCHASE_PLAN_DES,
  SELECT_PLAN_PERIOD,
  SOMETHING_WENT_WRONG,
  KEY_ACTIVE,
  KEY_CANCELED
} from "../../constant/Constants";

import { setPurchaseType } from "../../stateManagement/reducers/NewSubscriptionCartSlice";
import {
  CapitalizeLetter,
  failureToastMessage,
  getSearchItems,
  successToastMessage
} from "../../constant/Utils";
import {
  addSubscription,
  checkEligibility,
  getCreditSummaryBalance,
  getPlanList
} from "../../services/user.services";
import CustomPasscodeVerification from "../customPasscodeVerification/CustomPasscodeVerification";
import PasscodeError from "../passcodeError/PasscodeError";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import MultiErrorDialogBox from "../multiErrorDialogBox/MultiErrorDialogBox";
import { muiMenuItemStyles } from "../../style/CustomStyles";

import SubscriptionPackageListTable from "../subscriptionPackageListTable/SubscriptionPackageListTable";
import SubscriptionConfirmationPop from "../subscriptionConfirmationPop/SubscriptionConfirmationPop";
import SubscriptionPackEligibilityPopup from "../subscriptionPackEligibilityPopup/SubscriptionPackEligibilityPopup";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { getCreditBalanceFromStore } from "../../stateManagement/reducers/CreditBalanceSlice";

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});

/**
 * Renders the UI component for the New Subscription.
 * @param {object} props - Prop elements passed to the New Subscription form component.
 * @returns {component} New Subscription form component UI.
 */
const NewSubscription = (props) => {
  const [searchVal, setSearchVal] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [period, setPeriod] = useState("");
  const [showBuyNowViewCart, setShowBuyNowViewCart] = useState(false);
  const [buyNowPackDetails, setBuyNowPackDetails] = useState([]);
  const [stbPlanList, setStbPlanList] = useState([]);
  const [stbPlanListFixed, setStbPlanListFixed] = useState([]);
  const [showPasscodePop, setshowPasscodePop] = useState(false);
  const [showNoAttemptsModal, setShowNoAttemptsModal] = useState(false);
  const [showMultiErrorDialogBox, setShowMultiErrorDialogBox] = useState(false);
  const [showEligibility, setShowEligibility] = useState({
    eligibility: false,
    message: "",
    headerLabel: ""
  });
  const [multiErrorData, setMultiErrorData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [planListLoader, setPlanListLoader] = useState(false);
  const [isBuyNowDisabled, setIsBuyNowDisabled] = useState(false);

  const { customerInfo } = props;
  const customerId = customerInfo.customerId ? customerInfo.customerId : "";
  const mobileNumber = customerInfo.mobileNumber
    ? customerInfo.mobileNumber.replace(/^\+/, "")
    : "";
  const dispatch = useDispatch();
  // const totalSelectedNewSubscriptionItems = useSelector((state) =>
  //   getSelectNewSubscriptionItems(state)
  // );
  // const purchaseType = useSelector((state) => getPurchaseType(state));
  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const config = useSelector((state) => getConfigDetails(state));
  const creditSummary = useSelector((state) =>
    getCreditBalanceFromStore(state)
  );
  useEffect(() => {
    if (customerId) {
      dispatch(getCreditSummaryBalance());
    }
  }, [dispatch, customerId]);
  /**
   * Gets the Plan list based on the Customer and STB id.
   * @function getAllPlanList - Performs action to get the Plan list and Sets all the Plans based on the customer and STB id.
   */
  const getAllPlanList = useCallback(() => {
    setPlanListLoader(true);
    getPlanList(config.server, customerId)
      .then((res) => {
        if (
          res &&
          res.status === 200 &&
          res.data &&
          res.data.status !== NO_CONTENT
        ) {
          const completePlanList = res.data.filter(
            (item) => item.period !== null
          );
          const hasActivePlan = completePlanList.some(
            (plan) =>
              plan.activeForCustomer === KEY_ACTIVE ||
              plan.activeForCustomer === KEY_CANCELED
          );
          const newPlanlist = completePlanList.filter(
            (item) => !item.customerId
          );
          setIsBuyNowDisabled(hasActivePlan);
          setStbPlanListFixed(newPlanlist);
        } else {
          setStbPlanListFixed([]);
        }
      })
      .finally(() => {
        setPlanListLoader(false);
      });
  }, [customerId, config.server]);

  // /**
  //  * useEffect hook to render the STB ids mapped to the customer
  //  */
  // useEffect(() => {
  //   if (stbInfo && stbInfo.length === 1) {
  //     dispatch(updateSelectedSTBID(stbInfo[0].serialNo));
  //     setSTBID(stbInfo[0].serialNo);
  //   }
  // }, [customerId, stbInfo, dispatch]);

  // /**
  //  * useEffect hook to set the selected STB id on change of selecting STB id.
  //  */
  // useEffect(() => {
  //   setSTBID(selectedSTBID);
  // }, [selectedSTBID]);

  /**
   * useEffect hook to get All the Plan list and if the customer ID and STB id is present.
   */
  // const seletedStbInfo = useMemo(
  //   () => stbInfo && stbInfo.find((item) => item.serialNo === stbID),
  //   [stbInfo, stbID]
  // );
  useEffect(() => {
    if (customerId) {
      getAllPlanList();
    } else {
      setStbPlanListFixed([]);
    }
    setSearchVal("");
    setPeriod("");
  }, [customerId, getAllPlanList]);

  /**
   * useEffect hook to change the Table element based on the Plan, Plan period and search value change.
   */
  useEffect(() => {
    let filteredList = [];
    if (searchVal && period) {
      const searchFilteredList = getSearchItems(
        stbPlanListFixed,
        "planName",
        searchVal
      );
      filteredList = getSearchItems(searchFilteredList, "period", period);
    } else if (searchVal) {
      filteredList = getSearchItems(stbPlanListFixed, "planName", searchVal);
    } else if (period) {
      filteredList = getSearchItems(stbPlanListFixed, "period", period);
    } else {
      filteredList = stbPlanListFixed;
    }
    setStbPlanList(filteredList);
  }, [stbPlanListFixed, period, searchVal]);

  /**
   * Handle the Search Operation in the table.
   * @function handlerequestSearch - Performs action on Search input value change.
   * @param {object} event - Search input event object.
   */
  const handlerequestSearch = (event) => {
    const searchedVal = event.target.value.trim();
    setSearchVal(searchedVal);
  };

  /**
   * Handles the close operation on View Cart UI.
   * @function handleClose - Performs action to close the View Cart UI.
   */
  // const handleClose = () => {
  //   handleCloseViewcart();
  // };

  /**
   * Handle the operation to close the Buy Now dialog box.
   * @function handleBuyNowClose - Close the Buy Now dialog box.
   */
  const handleBuyNowClose = () => {
    setShowBuyNowViewCart(false);
  };

  /**
   * Handles the Operation to remove all the cart items.
   * @function removeAllCartItems - Perform Action to remove all the cart items.
   */
  // const removeAllCartItems = () => {
  //   dispatch(removeAllNewSubscriptionItems());
  //   let payload = {
  //     remainingCreditBalance: creditSummary.total_credits_without_prefix
  //   };
  //   dispatch(updateCreditBalance(payload));
  //   getAllgetPlanList(stbInfo[0].serialNo, stbInfo[0].hardwareType);
  // };

  /**
   * Handles the Operation to remove all the Buy Now cart items.
   * @function removeAllCartItems - Perform Action to remove all the Buy Now cart items.
   */
  const removeAllBuyNowCartItems = () => {
    setBuyNowPackDetails([]);
  };

  /**
   * Handles the display of Subscriptn pack details.
   * @function getSubscriptionPacksDetails - Gets the Subscription pack details of selected items.
   * @returns {array} - List of Subscripction pack details added.
   */
  // const getSubscriptionPacksDetails = useMemo(() => {
  //   if (purchaseType === ADDEDCART) {
  //     return totalSelectedNewSubscriptionItems;
  //   } else if (purchaseType === BUYNOW) {
  //     return buyNowPackDetails;
  //   } else {
  //     return [];
  //   }
  // }, [purchaseType, totalSelectedNewSubscriptionItems, buyNowPackDetails]);

  /**
   * Renders the Passcode Dialog box UI.
   * @function paynowForSubscription - Performs action to display the passcode Dialog box UI.
   */
  const paynowForSubscription = () => {
    setshowPasscodePop(true);
  };

  /**
   * Handles the change in Plan Period Select option
   * @function handlePeriod - Performs action on change of the Plan period.
   * @param {object} e - Plan period select option event
   */
  const handlePeriod = (e) => {
    const { value } = e.target;
    setPeriod(value);
  };

  // const checkEligibilityCallback = useCallback(
  //   (data) => {
  //     setLoader(true);
  //     const planCode = data.skuCode;
  //     checkEligibility(config.server, planCode, mobileNumber)
  //       .then((res) => {
  //         if (res && res.status === 200) {
  //           if (res.data.isEligible) {
  //             setShowEligibility({
  //               eligibility: false,
  //               message: ""
  //             });
  //             let selectedPackages = {
  //               package: data
  //             };
  //             dispatch(setPurchaseType(ADDEDCART));
  //             dispatch(addNewSubscriptionItem(selectedPackages));
  //             let payload = {
  //               remainingCreditBalance:
  //                 creditSummary.remainingCreditBalance - data.finalAmount
  //             };
  //             dispatch(updateCreditBalance(payload));
  //           } else {
  //             setShowEligibility({
  //               eligibility: true,
  //               message: res.data.message
  //             });
  //           }
  //         } else {
  //           let toastDetails = failureToastMessage(SOMETHING_WENT_WRONG, "");
  //           dispatch(updateToastMessage(toastDetails));
  //         }
  //       })
  //       .catch(() => {
  //         let toastDetails = failureToastMessage(SOMETHING_WENT_WRONG, "");
  //         dispatch(updateToastMessage(toastDetails));
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //       });
  //   },
  //   [
  //     config.server,
  //     creditSummary.remainingCreditBalance,
  //     dispatch,
  //     mobileNumber
  //   ]
  // );

  const isPackPurchaseble = () => {
    setShowEligibility({
      eligibility: true,
      message: PURCHASE_PLAN_DES,
      headerLabel: PURCHASE_PLAN_TITTLE
    });
  };
  // const checkPackDetails = (data) => {
  //   if (data.disabled ) {
  //     isPackPurchaseble();
  //   } else {
  //     checkEligibilityCallback(data);
  //   }
  // };
  /**
   * Handles the Operation to add item to the cart.
   * @function handleAddItemsToCart  - Performs action the handle the cart item on click of Add to cart icon.
   * @param {object} data - Item object to add to cart
   */
  // const handleAddItemsToCart = (data, next = false) => {
  //   if (totalSelectedNewSubscriptionItems.length < LIMIT_ADD_TO_CART) {
  //     if (creditSummary.remainingCreditBalance >= data.finalAmount) {
  //       checkPackDetails(data);
  //     } else {
  //       let toastDetails = failureToastMessage(INSUFFICENT_BALANCE_MSG, "");
  //       dispatch(updateToastMessage(toastDetails));
  //     }
  //   }
  // };

  const checkEligibilityBuynowCallback = useCallback(
    (data) => {
      setLoader(true);
      const planCode = data.skuCode;
      checkEligibility(config.server, planCode, mobileNumber)
        .then((res) => {
          if (res && res.status === 200) {
            if (res.data.isEligible) {
              setShowEligibility({
                eligibility: false,
                message: "",
                headerLabel: ""
              });
              dispatch(setPurchaseType(BUYNOW));
              setBuyNowPackDetails([data]);
              setShowBuyNowViewCart(true);
            } else {
              setShowEligibility({
                eligibility: true,
                message: res.data.message,
                headerLabel: NOT_ELIGIBLE
              });
            }
          } else {
            let toastDetails = failureToastMessage(SOMETHING_WENT_WRONG, "");
            dispatch(updateToastMessage(toastDetails));
          }
        })
        .catch(() => {
          let toastDetails = failureToastMessage(SOMETHING_WENT_WRONG, "");
          dispatch(updateToastMessage(toastDetails));
        })
        .finally(() => {
          setLoader(false);
        });
    },
    [config.server, dispatch, mobileNumber]
  );

  /**
   * Handles the Operation to add item on click of buy now icon.
   * @function handleBuyNow  - Performs action the handle the cart item on click of Buy now icon.
   * @param {object} data - Item object to add to cart
   */
  const handleBuyNow = (data) => {
    if (isBuyNowDisabled) {
      isPackPurchaseble();
    } else {
      if (
        creditSummary &&
        creditSummary.remainingCreditBalance >= data.finalAmount
      ) {
        checkEligibilityBuynowCallback(data);
      } else {
        let toastDetails = failureToastMessage(INSUFFICENT_BALANCE_MSG, "");
        dispatch(updateToastMessage(toastDetails));
      }
    }
  };

  /**
   * Handle the Removal of Added Plan package.
   * @function handleRemoveSeletedItem - Performs action to remove the Selected Plan pack
   * @param {object} removeItem  - data object to remove.
   */
  // const handleRemoveSeletedItem = (removeItem) => {
  //   const { skuCode, finalAmount } = removeItem;
  //   let payload = {
  //     remainingCreditBalance: creditSummary.remainingCreditBalance + finalAmount
  //   };
  //   dispatch(updateCreditBalance(payload));
  //   dispatch(removeNewSubscriptionItem(skuCode));
  // };

  /**
   * Handles the Close Operation of All popups
   * @callback handleClosePopup - Performs Action to close all Popups
   */
  const handleClosePopup = useCallback(() => {
    setshowPasscodePop(false);
    setShowNoAttemptsModal(false);
  }, []);

  /**
   * Handles the display of the Passcode Locked Popup
   * @callback handleOpenNoAttemptsModal - Performs action to display the Passcode Locked Popup UI.
   */
  const handleOpenNoAttemptsModal = useCallback((value) => {
    setParentEmail(value);
    setShowNoAttemptsModal(true);
  }, []);
  const handleFailureToastMsg = useCallback(
    (message) => {
      dispatch(updateToastMessage(failureToastMessage(message)));
    },
    [dispatch]
  );

  /**
   * Handles he filtered items added to cart.
   * @param {array} item - Selected Plan package.
   * @returns {object} - selected Plan .
   */
  // const isAddedtocart = (item) => {
  //   const { skuCode } = item;
  //   return totalSelectedNewSubscriptionItems.find(
  //     (value) => value.skuCode === skuCode
  //   );
  // };

  /**
   * Handles the Subscription Add Operation
   * @callback handleConfirmTransfer - Performs Action to Add the items to Subscription
   */
  const handleConfirmTransfer = useCallback(
    (passcode) => {
      setLoader(true);
      let payload = {
        customerId: customerId,
        serialNumber: null,
        countryCode: country.countryCode,
        currencyCode: country.countryCurrencyCode,
        passcode: passcode,
        planDetails: buyNowPackDetails
      };
      addSubscription(config.server, payload)
        .then((res) => {
          if (res && res.status === 200) {
            dispatch(getCreditSummaryBalance());
            getAllPlanList();
            if (res.data.length > 1) {
              setShowMultiErrorDialogBox(true);
              setMultiErrorData(res.data);
            } else {
              if (res.data[0].httpStatus === "MULTI_STATUS") {
                handleFailureToastMsg(res.data[0].message);
              } else {
                dispatch(
                  updateToastMessage(
                    successToastMessage(NEW_SINGLE_SUBSCRIPTION_BOUGHT)
                  )
                );
              }
            }
          } else {
            handleFailureToastMsg(res.data.message);
          }
        })
        .finally(() => {
          // if (purchaseType === ADDEDCART) {
          //   dispatch(removeAllNewSubscriptionItems());
          // } else {
          //   setBuyNowPackDetails([]);
          // }
          setBuyNowPackDetails([]);
          setLoader(false);
        });
    },
    [
      buyNowPackDetails,
      country,
      getAllPlanList,
      customerId,
      handleFailureToastMsg,
      config.server,
      dispatch
    ]
  );

  // /**
  //  * Handles the Change on Selection of the STB.
  //  * @function handleSelectStb - Performs action on change of STB select option.
  //  * @param {object} e - STB Select option pointer event.
  //  */
  // const handleSelectStb = (e) => {
  //   const { value } = e.target;
  //   setSTBID(value);
  //   dispatch(removeAllNewSubscriptionItems());
  //   dispatch(updateSelectedSTBID(value));
  // };

  /**
   * Handles the Operation to Close the MULTI_STATUS error dialog Box.
   * @function handleCloseMultiErrorDialogBox - Performs action to close the MULTI_STATUS error dialog Box on addition of Multiple packs.
   */
  const handleCloseMultiErrorDialogBox = () => {
    setShowMultiErrorDialogBox(false);
  };
  const handleCloseShowEligibilitynModal = () => {
    setShowEligibility(false);
  };

  return (
    <Box data-testid="newSubscription" sx={{ ml: 7, mr: 5, pt: 3 }}>
      {loader && <OverlayLoader />}

      <ThemeProvider theme={theme}>
        <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
          {/* <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
            <label htmlFor="demo-select-small" className="inputLabel">
              {STB} <span className="asterisk">*</span>
            </label>
            {stbInfo && stbInfo.length <= 1 && (
              <Box
                sx={{
                  height: "44px",
                  background: " #EDF4F6 0% 0% no-repeat padding-box",
                  border: "1px solid #E8E8E8",
                  borderRadius: "5px",
                  opacity: 1,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {stbID ? (
                  <Typography
                    sx={{
                      fontFamily: "BukraRegular",
                      fontSize: "14px",
                      color: "#202020",
                      pl: 2,
                      opacity: 0.7
                    }}
                  >
                    {stbID}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "BukraRegular",
                      fontSize: "14px",
                      color: "#202020",
                      pl: 2,
                      opacity: 0.5
                    }}
                  >
                    {SELECT_STB}
                  </Typography>
                )}
              </Box>
            )}
            {stbInfo && stbInfo.length > 1 && (
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                name="stb"
                value={stbID}
                inputProps={{
                  "data-testid": "selectStb"
                }}
                onChange={(e) => handleSelectStb(e)}
                displayEmpty
                sx={{
                  height: "44px",
                  background: " #EDF4F6 0% 0% no-repeat padding-box",
                  border: "1px solid #E8E8E8",
                  borderRadius: "5px",
                  opacity: 1
                }}
                renderValue={(stbId) =>
                  stbId ? (
                    <Typography
                      sx={{
                        letterSpacingpacing: "0px",
                        color: "#202020",
                        opacity: 1,
                        fontSize: "14px",
                        fontFamily: "BukraRegular"
                      }}
                    >
                      {stbId}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        letterSpacingpacing: "0px",
                        color: "#202020",
                        opacity: 0.5,
                        fontSize: "14px",
                        fontFamily: "BukraRegular"
                      }}
                    >
                      {SELECT_STB}
                    </Typography>
                  )
                }
              >
                {stbInfo.map((stbInfoRes) => (
                  <MenuItem
                    key={stbInfoRes.serialNo}
                    value={stbInfoRes.serialNo}
                    sx={{
                      ...muiMenuItemStyles
                    }}
                  >
                    {stbInfoRes.serialNo}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl> */}
          <FormControl
            sx={{ minWidth: 120, maxWidth: { lg: 300, xs: "none" } }}
            size="small"
            fullWidth
          >
            <label htmlFor="demo-select-small" className="inputLabel">
              {PLAN_PERIOD}
            </label>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={period}
              inputProps={{
                "data-testid": "periodElement"
              }}
              name="period"
              displayEmpty
              renderValue={(selectdPeriod) =>
                !selectdPeriod ? (
                  <Typography
                    sx={{
                      letterSpacingpacing: "0px",
                      fontSize: "14px",
                      fontFamily: "BukraRegular",
                      color: "#202020",
                      opacity: 0.5
                    }}
                  >
                    {SELECT_PLAN_PERIOD}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      letterSpacingpacing: "0px",
                      fontSize: "14px",
                      fontFamily: "BukraRegular",
                      color: "#202020",
                      opacity: 1
                    }}
                  >
                    {CapitalizeLetter(period)}
                  </Typography>
                )
              }
              onChange={(e) => handlePeriod(e)}
              sx={{
                height: "44px",
                background: " #EDF4F6 0% 0% no-repeat padding-box",
                border: "1px solid #E8E8E8",
                borderRadius: "5px",
                opacity: 1
              }}
            >
              {planPeriodOption.map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      ...muiMenuItemStyles
                    }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
            <label htmlFor="demo-select-small" className="inputLabel">
              {PACKAGES}
            </label>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                backgroundColor: "#F3F6F9",
                height: "44px",
                width: { xs: "100%" },
                my: { xs: 1, lg: 0 }
              }}
            >
              <img
                src={search}
                alt="search"
                style={{
                  height: "11px",
                  paddingLeft: "20px"
                }}
              ></img>

              <InputBase
                autoComplete="off"
                inputProps={{ "data-testid": "searchPackage" }}
                sx={{
                  ml: 1,
                  flex: 1,
                  fontSize: "14px",
                  fontFamily: "BukraRegular",
                  color: "#202020",
                  opacity: 1
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                placeholder="Search Packages"
                onChange={(event) => handlerequestSearch(event)}
              />
            </Box>
          </FormControl>
        </Stack>
        {customerId && !planListLoader && (
          <SubscriptionPackageListTable
            stbPlanList={stbPlanList}
            countryCode={country.countryCode}
            handleBuyNow={handleBuyNow}
            // handleRemoveSeletedItem={handleRemoveSeletedItem}
            // handleAddItemsToCart={handleAddItemsToCart}
            // isAddedtocart={isAddedtocart}
            isfromRenew={false}
          ></SubscriptionPackageListTable>
        )}
        {planListLoader && <BodyLoader />}
        {!planListLoader && customerId && stbPlanList.length === 0 && (
          <Typography
            textAlign={"center"}
            sx={{
              mt: 3
            }}
          >
            {NO_DATA}
          </Typography>
        )}
      </ThemeProvider>

      {/* {showViewCart && (
        <SubscriptionConfirmationPop
          removeAllCartItems={removeAllCartItems}
          showViewCart={showViewCart}
          handleViewCartClose={handleClose}
          paynowForSubscription={paynowForSubscription}
          subscriptionDetails={totalSelectedNewSubscriptionItems}
          tittle={CART}
          clearLabel={CLEAR_CART}
          subscriptionType={NEW_SUBSCRIPTION}
        />
      )} */}
      {showBuyNowViewCart && (
        <SubscriptionConfirmationPop
          clearLabel={CANCEL}
          removeAllCartItems={removeAllBuyNowCartItems}
          showViewCart={showBuyNowViewCart}
          handleViewCartClose={handleBuyNowClose}
          paynowForSubscription={paynowForSubscription}
          subscriptionDetails={buyNowPackDetails}
          tittle={BUY_NOW}
          subscriptionType={NEW_SUBSCRIPTION}
        />
      )}
      {showPasscodePop && (
        <CustomPasscodeVerification
          showPasscodeModal={showPasscodePop}
          handleClosePasscodeVerification={handleClosePopup}
          handleConfirmTransfer={handleConfirmTransfer}
          handleOpenNoAttempts={handleOpenNoAttemptsModal}
        ></CustomPasscodeVerification>
      )}
      {showNoAttemptsModal && (
        <PasscodeError
          parentEmail={parentEmail}
          handleCloseNoAttempts={handleClosePopup}
        />
      )}
      {showMultiErrorDialogBox && (
        <MultiErrorDialogBox
          isFromRenew={false}
          multiErrorData={multiErrorData}
          handleCloseMultiErrorDialogBox={handleCloseMultiErrorDialogBox}
        ></MultiErrorDialogBox>
      )}

      {showEligibility.eligibility && (
        <SubscriptionPackEligibilityPopup
          handleCloseShowEligibilitynModal={handleCloseShowEligibilitynModal}
          showEligibility={showEligibility.eligibility}
          message={showEligibility.message}
          headerLabel={showEligibility.headerLabel}
        ></SubscriptionPackEligibilityPopup>
      )}
    </Box>
  );
};

export default NewSubscription;
