import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  renewCartItems: [],
  selectedSTBID: "",
  rewPurchaseType: ""
};

/**
 * Saves the selected Plan package information based on STB into the store
 */
const renewSubscriptionCartSlice = createSlice({
  name: "renewSubscriptionCart",
  initialState,
  reducers: {
    setRenewPurchaseType(state, action) {
      state.rewPurchaseType = action.payload;
    }
  }
  // reducers: {
  //   updateRenewSelectedSTBID(state, action) {
  //     state.selectedSTBID = action.payload;
  //   },
  //   setRenewPurchaseType(state, action) {
  //     state.rewPurchaseType = action.payload;
  //   },
  //   addItem(state, action) {
  //     const newItemSkuCode = action.payload.package.skuCode;
  //     const selectedSTBID = action.payload.stbID;
  //     const existingItem = state.renewCartItems.find(
  //       (item) => item.skuCode === newItemSkuCode
  //     );
  //     if (!existingItem) {
  //       state.renewCartItems.push(action.payload.package);
  //     }
  //     state.selectedSTBID = selectedSTBID;
  //   },

  //   removeItem(state, action) {
  //     state.renewCartItems = state.renewCartItems.filter(
  //       (item) => item.skuCode !== action.payload
  //     );
  //   },
  //   removeAllItem(state) {
  //     state.renewCartItems = [];
  //     state.selectedSTBID = "";
  //   }
  // }
});

/**
 * Fetches the Plan Package
 * @param {object} state - Store state.
 * @returns {array} - Plan Package list
 */
// export const getSelectRenewItems = (state) => {
//   return state.renewSubscriptionCart.renewCartItems;
// };

/**
 * Fetches the STB ID.
 * @param {object} state - Store state.
 * @returns {string} STB ID.
 */
// export const getSelectRenewSTBID = (state) => {
//   return state.renewSubscriptionCart.selectedSTBID;
// };

/**
 * Fetches the Plan Selection type - BUY_NOW or ADD_TO_CART.
 * @param {object} state - Store State.
 * @returns {string} plan package Selection type
 */
// export const getRenewPurchaseType = (state) => {
//   return state.renewSubscriptionCart.rewPurchaseType;
// };
export const { setRenewPurchaseType } = renewSubscriptionCartSlice.actions;
export default renewSubscriptionCartSlice.reducer;
