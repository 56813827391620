import { createTheme } from "@mui/material/styles";
export const ToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          marginTop: "0px !important",
          color: "#5B636F",
          backgroundColor: "transparent",
          fontSize: "11px",
          fontFamily: "BukraRegular",
          letterSpacing: 0
        }
      }
    }
  }
});

export const ToolTipThemeWithBG = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#5B636F",
          fontSize: "12px",
          fontFamily: "BukraMedium",
          backgroundColor: "#ffffff",
          letterSpacing: 0,
          padding: "8px 10px",
          border: "1px solid #dddddd",
          boxShadow: "1px 1px 10px 1px #aaaaaa",
          textAlign: "center"
        },
        arrow: {
          color: "#ffffff",
          fontSize: 14,
          "&::before": {
            content: "''",
            display: "block",
            border: "1px solid #dddddd"
          },
          "&::after": {
            content: "''",
            display: "block",
            border: "1px solid #dddddd"
          }
        }
      }
    }
  }
});
