import React, { memo, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import StockTransfer from "../../components/stockTransfer/StockTransfer";
import RenderChildComponent from "../../components/renderChildComponent/RenderChildComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getChannelPartnerCode,
  getUserRoleType
} from "../../stateManagement/reducers/LoginSlice";
import { MD_USER_ROLE_TYPE } from "../../constant/Constants";
import { updatePageVisit } from "../../constant/Utils";
import { setShowDateFilter } from "../../stateManagement/reducers/DateFilterSlice";

/**
 * Renders the STB page UI.
 * @returns {component} STB Page UI component
 */
const STBPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isStockTransferPresent =
    location.pathname.split("/").includes("trackstb") || false;

  /**
   * @constant {number} fetchUserRoleType - Gets the Logged in user type.
   */
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));

  /**
   * @constant {boolean} showCard - true or false.
   */
  const showCard = fetchUserRoleType === MD_USER_ROLE_TYPE ? true : false;

  /**
   * @constant {string} channelPartnerCode - Gets the Logged in user channel partner code.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * @constant {array<string>} childPath - URL Paths.
   */
  const childPath = ["/stb/trackstb", "/stb"];

  /**
   * Updates the Page visits info.
   */
  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
    return () => {
      dispatch(setShowDateFilter(true));
    };
  }, [location.pathname, channelPartnerCode, dispatch]);

  return (
    <div data-testid="stbpage">
      <Stack spacing={2}>
        <QuickLinks
          childPath={childPath}
          showCards={showCard}
          showFilter={!isStockTransferPresent}
          showInventoryCard={showCard}
        ></QuickLinks>
        <RenderChildComponent isChildPresent={isStockTransferPresent}>
          <StockTransfer></StockTransfer>
        </RenderChildComponent>
        <Outlet />
      </Stack>
    </div>
  );
};

export default memo(STBPage);
