import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import {
  getChangePasswordStatus,
  getChannelPartnerCode,
  getUserInfo
} from "./stateManagement/reducers/LoginSlice";
import { memo, useCallback, useEffect, useState } from "react";
import {
  getToastMessageDetails,
  resetToast,
  updateToastMessage
} from "./stateManagement/reducers/ToastMessageSlice";
import { CustomRoutes } from "./services/CustomRoutes";
import "./App.scss";
import {
  getUserScopeDetails,
  userSignOut,
  getLocationDetails,
  addFcmToken,
  getNotifications
} from "./services/user.services";
import { setNotificationSource } from "./stateManagement/reducers/NotificationSlice";
import {
  ALL,
  GENERATED_FC_MESSAGING_KEY,
  IDLE_LOGOUT_TIME,
  LOGGED_OUT,
  NO_CONTENT,
  PROMPT_BEFORE_IDLE_LOGOUT_TIME
} from "./constant/Constants";
import { Box, Button, Dialog, Typography } from "@mui/material";
import IdleRemainingTime from "./components/idleRemainingTime/IdleRemainingTime";
import CustomToastMessage from "./components/customComponents/customToastMessage/CustomToastMessage";
import ToastPortal from "./components/toastPortal/ToastPortal";
import { getConfigDetails } from "./stateManagement/reducers/ConfigSlice";
import { messaging, onMessageListener } from "./services/firebase";
import { getToken } from "firebase/messaging";
import { updatePushNotificationEnableStatus } from "./stateManagement/reducers/PushNotificationSlice";
import { successToastMessage } from "./constant/Utils";

/**
 * Renders the Main Application
 * @returns {JSX} main Application Endpoint
 */
const App = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [pushNotify, setPushNotify] = useState([]);
  const [notify, setNotify] = useState([]);
  const changePasswordStatus = useSelector((state) =>
    getChangePasswordStatus(state)
  );
  const config = useSelector((state) => getConfigDetails(state));
  /**
   *@constant {object} toastMessageDetails Gets the Toast Message Info.
   */
  const toastMessageDetails = useSelector((state) =>
    getToastMessageDetails(state)
  );
  const {
    isToastOpen,
    toastTitle,
    toastDescription,
    toastStatus,
    toastBgclr,
    toastBorderclr,
    toastTextclr
  } = toastMessageDetails;
  const token = sessionStorage.getItem("userToken");
  const fcmToken = sessionStorage.getItem("fcmToken");
  const userInfo = useSelector((state) => getUserInfo(state));
  const [open, setOpen] = useState(false);
  // const [isOnline, setOnline] = useState(navigator.onLine);

  // On initization set the isOnline state.
  // useEffect(() => {
  //   function changeStatus() {
  //     setOnline(navigator.onLine);
  //   }
  //   window.addEventListener("online", changeStatus);
  //   window.addEventListener("offline", changeStatus);
  //   return () => {
  //     window.removeEventListener("online", changeStatus);
  //     window.removeEventListener("offline", changeStatus);
  //   };
  // }, []);
  useEffect(() => {
    if (token && messaging) {
      getToken(messaging, {
        vapidKey: GENERATED_FC_MESSAGING_KEY
      })
        .then((currentToken) => {
          if (currentToken) {
            sessionStorage.setItem("fcmToken", currentToken);
            addFcmToken(config.server, currentToken).catch((err) =>
              console.log(err)
            );
            dispatch(updatePushNotificationEnableStatus(false));
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            sessionStorage.setItem("fcmToken", "");
            dispatch(updatePushNotificationEnableStatus(true));
            // shows on the UI that permission is required
          }
        })
        .catch((err) => {
          sessionStorage.setItem("fcmToken", "");
          dispatch(updatePushNotificationEnableStatus(true));
          console.log("An error occurred while retrieving token. ", err);
          // catch error while creating client token
        });
    }

    return () => {};
  }, [dispatch, fcmToken, token, config.server]);
  useEffect(() => {
    dispatch(getLocationDetails());
  }, [dispatch]);

  /**
   * Gets the ScopeMenu details on load.
   */
  useEffect(() => {
    if (token && userInfo === null) {
      dispatch(getUserScopeDetails()).then((res) => {
        if (res && res.error && res.error.message) {
          dispatch(userSignOut());
          dispatch(setNotificationSource([]));
          sessionStorage.removeItem("userToken");
          sessionStorage.removeItem("fcmToken");
          navigateTo("/login");
        }
      });
    }
  }, [dispatch, token, userInfo, navigateTo]);

  // useEffect(() => {
  //   const handleInvalidToken = (e) => {
  //     window.location.reload();
  //   };
  //   window.addEventListener("storage", handleInvalidToken);
  //   return () => {
  //     // When the component unmounts remove the event listener
  //     window.removeEventListener("storage", handleInvalidToken);
  //   };
  // }, []);

  /**
   * Navigate to Change Password page.
   */
  useEffect(() => {
    if (changePasswordStatus) {
      navigateTo("/changepassword");
    }
  }, [changePasswordStatus, navigateTo]);

  /**
   * Handle the Snackbar close operation
   * @function handleCloseSnackBar - Close the Snackabar.
   */
  const handleCloseSnackBar = useCallback(() => {
    dispatch(resetToast());
  }, [dispatch]);
  const snackBarDetails = {
    toastMessage: toastTitle,
    toastStatus: toastStatus,
    toastSubTittle: toastDescription,
    toastTextclr: toastTextclr,
    toastBgclr: toastBgclr,
    toastBorderclr: toastBorderclr,
    handleCloseSnackBar: handleCloseSnackBar
  };

  /**
   * @constant {string} channelPartnerCode - Channel Partner code.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  useEffect(() => {
    const payLoad = {
      type: ALL,
      fromCount: 0,
      toCount: 50
    };
    if (channelPartnerCode && config.server) {
      getNotifications(config.server, payLoad).then((res) => {
        if (res.status === 200 && res.data.status !== NO_CONTENT) {
          dispatch(setNotificationSource(res.data.userNotifications));
          setNotify(res.data.userNotifications);
        } else {
          setNotify([]);
        }
      });
    }
  }, [channelPartnerCode, config.server, dispatch]);

  try {
    let oldMessageData = [];
    if (token && messaging) {
      onMessageListener()
        .then((payload) => {
          const parsePayloadData = JSON.parse(payload.data.body);
          oldMessageData.unshift(parsePayloadData);
          localStorage.setItem(
            `${channelPartnerCode}_notificationReadStatus`,
            true
          );
          setPushNotify([...oldMessageData, ...pushNotify]);
        })
        .catch((err) => console.log("failed: ", err));
    }
  } catch (error) {
    console.log("catch failed: ", error);
  }

  useEffect(() => {
    let backgroundMessage = [];
    const channel = new BroadcastChannel("sw-messages");
    channel.addEventListener("message", (event) => {
      const parsePayloadData = JSON.parse(event.data.data.body);
      backgroundMessage.unshift(parsePayloadData);
      localStorage.setItem(
        `${channelPartnerCode}_notificationReadStatus`,
        true
      );
      setPushNotify([...backgroundMessage, ...pushNotify]);
    });
    return () => {
      channel.close();
    };
  }, [dispatch, channelPartnerCode, pushNotify]);

  useEffect(() => {
    const uniqueNotification = [...pushNotify, ...notify].filter(
      (item, index, self) =>
        self.findIndex((obj) => obj.id === item.id) === index
    );
    dispatch(setNotificationSource([...uniqueNotification]));
  }, [dispatch, pushNotify, notify]);

  const onIdle = useCallback(() => {
    if (token) {
      setOpen(false);
      let toastDetails = successToastMessage(LOGGED_OUT);
      dispatch(userSignOut());
      dispatch(updateToastMessage(toastDetails));
      dispatch(setNotificationSource([]));
      sessionStorage.removeItem("userToken");
      sessionStorage.removeItem("fcmToken");
      navigateTo("/login");
    }
  }, [token, dispatch, navigateTo]);
  const onPrompt = useCallback(() => {
    if (token) {
      setOpen(true);
    }
  }, [token]);

  const onActive = useCallback(
    (event, idleTimer) => {
      if (token && idleTimer.isPrompted()) {
        setOpen(false);
      }
    },
    [token]
  );
  const { activate, getRemainingTime } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    timeout: IDLE_LOGOUT_TIME,
    promptBeforeIdle: PROMPT_BEFORE_IDLE_LOGOUT_TIME,
    debounce: 250
    // crossTab: true
  });

  const handleStillHere = () => {
    activate();
  };
  return (
    <div className="App" data-testid="app">
      <>
        <CustomRoutes></CustomRoutes>
        {isToastOpen && (
          <ToastPortal>
            <CustomToastMessage
              snackBarDetails={snackBarDetails}
            ></CustomToastMessage>
          </ToastPortal>
        )}
      </>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") return;
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "350px",

            alignItems: "center",
            pt: 2,
            pb: 1
          }}
        >
          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Typography
              sx={{
                fontFamily: "BukraMedium",
                fontSize: "15px",
                color: "#282828"
              }}
            >
              Are you still here?
            </Typography>

            <Typography
              sx={{
                fontFamily: "BukraRegular",
                fontSize: "13px",
                color: "#282828",
                opacity: 0.8,
                display: "flex",
                pt: 1
              }}
            >
              Logging out in
              {
                <IdleRemainingTime
                  getRemainingTime={getRemainingTime}
                ></IdleRemainingTime>
              }
              seconds
            </Typography>
          </Box>

          <Button
            data-testid="confirmButton"
            variant="outlined"
            sx={{
              background:
                "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
              color: "#FFFFFF",
              borderRadius: "20px",
              letterSpacing: "0px",
              fontFamily: "BukraMedium",
              fontSize: "15px",
              width: "200px",
              height: "40px",
              textTransform: "none",
              mb: 2,
              mt: 1
            }}
            onClick={() => handleStillHere()}
          >
            Keep login
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default memo(App);
