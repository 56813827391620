import { Navigate, useRoutes } from "react-router-dom";
import ProtectedRoutes from "../components/protectedRoutes/ProtectedRoutes";
import DashBoard from "../pages/dashBoard/DashBoard";
import Login from "../pages/login/Login";
import CompanyProfile from "../components/companyProfile/CompanyProfile";
import Credits from "../pages/credits/Credits";
import TransferInformation from "../components/transferInformation/TransferInformation";
import ReverseTransferInformation from "../components/reverseTransferInformation/ReverseTransferInformation";
import HardwareAllocationandReplacement from "../components/hardwareAllocationandReplacement/HardwareAllocationandReplacement";
import STBDetails from "../components/stbDetails/STBDetails";
import Subscription from "../pages/subscription/Subscription";
import Customer from "../pages/customer/Customer";
import SubscribeNow from "../components/subscribeNow/SubscribeNow";
import CustomerRegistration from "../components/customerRegistration/CustomerRegistration";
import Retailers from "../pages/retailers/Retailers";
import RetailerOnboarding from "../components/retailerOnboarding/RetailerOnboarding";
import PageLayoutOne from "../components/pageLayoutOne/PageLayoutOne";
import PageLayoutTwo from "../components/pageLayoutTwo/PageLayoutTwo";
import VerificationPage from "../pages/verificationPage/VerificationPage";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import AccountLocked from "../components/acountLocked/AccountLocked";
import ChangePassword from "../components/changePassword/ChangePassword";
import Hardware from "../pages/hardware/Hardware";
import ResetPassword from "../components/resetPassword/ResetPassword";
import EditProfile from "../components/editProfile/EditProfile";
import RetailerViewProfile from "../components/retailerViewProfile/RetailerViewProfile";
import SubDistributorViewProfile from "../components/subDistributorViewProfile/SubDistributorViewProfile";
import MyProfile from "../components/myProfile/MyProfile";
import MasterDistributorProfile from "../components/masterDistributorProfile/MasterDistributorProfile";
import SubDistributorProfile from "../components/subDistributorProfile/SubDistributorProfile";
import SubDistributor from "../pages/subDistributor/SubDistributor";
import STBPage from "../pages/stbPage/STBPage";
import TrackSTBPage from "../pages/trackSTBPage.js/TrackSTBPage";
import NotificationsDetailsPage from "../pages/notificationsDetailsPage/NotificationsDetailsPage";

export const CustomRoutes = () => {
  const element = useRoutes([
    {
      element: <ProtectedRoutes></ProtectedRoutes>,
      children: [
        {
          element: <PageLayoutTwo></PageLayoutTwo>,
          children: [{ path: "/changepassword", element: <ChangePassword /> }]
        },
        {
          path: "/",
          element: <PageLayoutOne />,
          children: [
            {
              path: "/dashboard",
              element: <DashBoard />,
              children: [
                {
                  path: "/dashboard/myprofile",
                  element: <MyProfile></MyProfile>
                },
                {
                  path: "/dashboard/masterdistributorprofile",
                  element: <MasterDistributorProfile></MasterDistributorProfile>
                },
                {
                  path: "/dashboard/subdistributorprofile",
                  element: <SubDistributorProfile></SubDistributorProfile>
                },
                {
                  path: "/dashboard/companyprofile",
                  element: <CompanyProfile></CompanyProfile>
                }
              ]
            },
            {
              path: "/credits",
              element: <Credits />,
              children: [
                {
                  path: "/credits/transfercredits",
                  element: <TransferInformation></TransferInformation>
                },
                {
                  path: "/credits/reversecredits",
                  element: (
                    <ReverseTransferInformation></ReverseTransferInformation>
                  )
                }
              ]
            },

            {
              path: "/hardware",
              element: <Hardware />,
              children: [
                {
                  path: "/hardware/hardwareallocationandreplacement",
                  element: (
                    <HardwareAllocationandReplacement></HardwareAllocationandReplacement>
                  )
                },
                {
                  path: "/hardware/trackstb",
                  element: <TrackSTBPage />
                }
              ]
            },
            {
              path: "/stb",
              element: <STBPage />,
              children: [
                {
                  path: "/stb/trackstb",
                  element: <STBDetails></STBDetails>
                }
              ]
            },
            {
              path: "/trackstb",
              element: <TrackSTBPage />
            },
            {
              path: "/subscription",
              element: <Subscription />
            },
            {
              path: "/customer",
              element: <Customer />,
              children: [
                {
                  path: "/customer/subscribenow",
                  element: <SubscribeNow />
                },
                {
                  path: "/customer/customerregistration",
                  element: <CustomerRegistration />
                }
              ]
            },
            {
              path: "/subdistributor",
              element: <SubDistributor />,
              children: [
                {
                  path: `/subdistributor/subdistributorviewprofile/:id`,
                  element: <SubDistributorViewProfile />
                },
                {
                  path: "/subdistributor/editprofile/:id",
                  element: <EditProfile />
                }
              ]
            },
            {
              path: "/notification",
              element: <NotificationsDetailsPage></NotificationsDetailsPage>
            },
            {
              path: "/retailers",
              element: <Retailers />,
              children: [
                {
                  path: "/retailers/retailerprofile/:id",
                  element: <RetailerViewProfile />
                },
                {
                  path: "/retailers/editprofile/:id",
                  element: <EditProfile />
                },
                {
                  path: "/retailers/retaileronboarding",
                  element: <RetailerOnboarding />
                }
              ]
            }
            // { path: "/notification", element: <NotificationsDetailsPage /> },
          ]
        },
        {
          path: "*",
          element: <Navigate to="/"></Navigate>
        }
      ]
    },

    {
      element: <PageLayoutTwo></PageLayoutTwo>,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/accountlock", element: <AccountLocked /> },
        { path: "/forgotpassword", element: <ForgotPassword /> },
        { path: "/verificationpage", element: <VerificationPage /> },
        { path: "/resetpassword", element: <ResetPassword /> },
        {
          path: "/healthCheck",
          element: (
            <h1
              style={{
                color: "green"
              }}
            >
              I'm healthy
            </h1>
          )
        }
      ]
    },

    { path: "*", element: <Login /> }
  ]);
  return element;
};
