import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

/**
 * Renders the Background section for the Protected routes.
 * @returns {component} The Background Section for the Protected Routes
 */
const MainContent = () => {
  return (
    <Box
      data-testid="mainContent"
      component="main"
      sx={{
        flexGrow: 1,
        p: 2
      }}
    >
      <Outlet />
    </Box>
  );
};

export default MainContent;
