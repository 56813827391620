import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NO_DATA_FOUND } from "../../constant/Constants";
import { textWithComma } from "../../constant/Utils";
import { getUserDetails } from "../../services/user.services";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import {
  getUserName,
  getUserRole
} from "../../stateManagement/reducers/LoginSlice";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import ProfileCard from "../profileCard/ProfileCard";
import subDistributorProfile from "./../../assets/images/sub_distributor_profile.svg";

/**
 * Renders the Logged in user's profile card component.
 * @returns {component} The UI component for the Profile display of the Logged in user.
 */
const MyProfile = () => {
  const [userDetails, setUserDetails] = useState("");
  const [showNoData, setShowNoData] = useState(false);
  const [loader, setLoader] = useState(false);
  const userRole = useSelector((state) => getUserRole(state));
  const UserName = useSelector((state) => getUserName(state));
  const config = useSelector((state) => getConfigDetails(state));
  /**
   * useEffect hook to fetch User Profile Data on load.
   */
  useEffect(() => {
    setLoader(true);
    if (config.server) {
      getUserDetails(config.server)
        .then((res) => {
          if (res && res.status === 200) {
            setUserDetails(res.data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [config.server]);

  /**
   * Filters the required user profile data.
   */
  const {
    channelPartnerAddressInformation,
    channelPartnerMobileNumber,
    companyName,
    channelPartnerEmail,
    channelPartnerCity,
    channelPartnerState,
    channelPartnerCountry,
    channelPartnerCode,
    channelPartnerArea,
    postalCode
  } = userDetails;

  const address = `${textWithComma(
    channelPartnerAddressInformation
  )}${textWithComma(channelPartnerArea)}${textWithComma(
    channelPartnerCity
  )} ${textWithComma(postalCode)}${textWithComma(
    channelPartnerState
  )}${channelPartnerCountry}`;

  /**
   * Create an Object with Filtered and formatted data from the Object.
   * @property {object} profileDetails - Formatted Profile Details of the logged in user.
   */
  let profileDetails = {
    profileTittle: `${userRole} Profile`,
    profileName: `${userRole} : ${channelPartnerCode} [${UserName}]`,
    profileNumber: channelPartnerMobileNumber,
    profileEmail: channelPartnerEmail,
    profileAddress: address,
    profileCompany: companyName,
    image: subDistributorProfile
  };
  return (
    <>
      {!loader && showNoData && (
        <Typography data-testid="NoData">{NO_DATA_FOUND}</Typography>
      )}
      {userDetails && (
        <ProfileCard profileDetails={profileDetails}></ProfileCard>
      )}
      {loader && <BodyLoader />}
    </>
  );
};

export default MyProfile;
