import { createSlice } from "@reduxjs/toolkit";
import { TRANSACTION_SUCCESS_STATUS } from "../../constant/Constants";

const initialState = {
  transferDetails: {
    transactionStatus: TRANSACTION_SUCCESS_STATUS,
    statusValue: 0
  }
};

/**
 * Store the Transfer details with status like SUCCESS, PENDING and REJECTED into the store.
 */
const creditsTransferStatusSlice = createSlice({
  name: "creditsTransferStatus",
  initialState,
  reducers: {
    setCreditsTransferStatus: (state, action) => {
      state.transferDetails = action.payload;
    }
  }
});

/**
 * Fetches the Credits transfer details based on status.
 * @param {object} state - Store State
 * @returns {object}-Credits Transfer details with status
 */
export const getCreditsTransferStatusDetails = (state) => {
  const creditDetails = state.creditsTransferStatus
    ? state.creditsTransferStatus.transferDetails
    : null;
  return creditDetails;
};

export const { setCreditsTransferStatus } = creditsTransferStatusSlice.actions;
export default creditsTransferStatusSlice.reducer;
