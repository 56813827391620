export const nationalityList = [
  {
    nationalityCode: "AGG",
    nationalityName: "Afghanistan"
  },
  {
    nationalityCode: "ALB",
    nationalityName: "Albania"
  },
  {
    nationalityCode: "DZA",
    nationalityName: "Algeria"
  },
  {
    nationalityCode: "AND",
    nationalityName: "Andorra"
  },
  {
    nationalityCode: "AGO",
    nationalityName: "Angola"
  },
  {
    nationalityCode: "AIA",
    nationalityName: "Anguilla"
  },
  {
    nationalityCode: "ATA",
    nationalityName: "Antarctica"
  },

  {
    nationalityCode: "ATG",
    nationalityName: "Antigua and Barbuda"
  },
  {
    nationalityCode: "ARG",
    nationalityName: "Argentina"
  },
  {
    nationalityCode: "ARM",
    nationalityName: "Armenia"
  },
  {
    nationalityCode: "ABW",
    nationalityName: "Aruba"
  },
  {
    nationalityCode: "ALA",
    nationalityName: "Aland Islands"
  },
  {
    nationalityCode: "AUS",
    nationalityName: "Australia"
  },
  {
    nationalityCode: "AUT",
    nationalityName: "Austria"
  },

  {
    nationalityCode: "AZE",
    nationalityName: "Azerbaijan"
  },
  {
    nationalityCode: "BHS",
    nationalityName: "Bahamas"
  },
  {
    nationalityCode: "BHR",
    nationalityName: "Bahrain"
  },
  {
    nationalityCode: "BGD",
    nationalityName: "Bangladesh"
  },
  {
    nationalityCode: "BRB",
    nationalityName: "Barbados"
  },
  {
    nationalityCode: "BLR",
    nationalityName: "Belarus"
  },
  {
    nationalityCode: "BEL",
    nationalityName: "Belgium"
  },
  {
    nationalityCode: "BLZ",
    nationalityName: "Belize"
  },
  {
    nationalityCode: "BEN",
    nationalityName: "Benin"
  },
  {
    nationalityCode: "BMU",
    nationalityName: "Bermuda"
  },
  {
    nationalityCode: "BTN",
    nationalityName: "Bhutan"
  },
  {
    nationalityCode: "BOL",
    nationalityName: "Bolivia"
  },
  {
    nationalityCode: "BES",
    nationalityName: "Bonaire, Sint Eustatius and Saba"
  },
  {
    nationalityCode: "BIH",
    nationalityName: "Bosnia and Herzegovina"
  },
  {
    nationalityCode: "BWA",
    nationalityName: "Botswana"
  },
  {
    nationalityCode: "BVT",
    nationalityName: "Bouvet Island"
  },
  {
    nationalityCode: "BRA",
    nationalityName: "Brazil"
  },
  {
    nationalityCode: "BRN",
    nationalityName: "Brunei Darussalam"
  },

  { nationalityCode: "BGR", nationalityName: "Bulgaria" },
  {
    nationalityCode: "BFA",
    nationalityName: "Burkina Faso"
  },
  {
    nationalityCode: "BDI",
    nationalityName: "Burundi"
  },
  {
    nationalityCode: "CPV",
    nationalityName: "Cabo Verde"
  },
  {
    nationalityCode: "KHM",
    nationalityName: "Cambodia"
  },
  {
    nationalityCode: "CMR",
    nationalityName: "Cameroon"
  },
  {
    nationalityCode: "CAN",
    nationalityName: "Canada"
  },
  {
    nationalityCode: "CYM",
    nationalityName: "Cayman Islands"
  },
  {
    nationalityCode: "CAF",
    nationalityName: "Central African Republic"
  },
  {
    nationalityCode: "TCD",
    nationalityName: "Chad"
  },
  {
    nationalityCode: "CHL",
    nationalityName: "Chile"
  },
  {
    nationalityCode: "CHN",
    nationalityName: "China"
  },
  {
    nationalityCode: "CXR",
    nationalityName: "Christmas Island"
  },
  {
    nationalityCode: "CCK",
    nationalityName: "Cocos (Keeling) Islands"
  },
  {
    nationalityCode: "COL",
    nationalityName: "Colombia"
  },
  {
    nationalityCode: "COM",
    nationalityName: "Comoros"
  },
  {
    nationalityCode: "COG",
    nationalityName: "Congo"
  },

  {
    nationalityCode: "COD",
    nationalityName: "Congo, the Democratic Republic"
  },

  {
    nationalityCode: "COK",
    nationalityName: "Cook Islands"
  },
  {
    nationalityCode: "CRI",
    nationalityName: "Costa Rica"
  },
  {
    nationalityCode: "HRV",
    nationalityName: "Croatia"
  },
  {
    nationalityCode: "CUB",
    nationalityName: "Cuba"
  },
  {
    nationalityCode: "CUW",
    nationalityName: "Curacao"
  },
  {
    nationalityCode: "CYP",
    nationalityName: "Cyprus"
  },
  {
    nationalityCode: "CZE",
    nationalityName: "Czech Republic"
  },
  {
    nationalityCode: "CIV",
    nationalityName: "Cote Divoire"
  },
  {
    nationalityCode: "DNK",
    nationalityName: "Denmark"
  },
  {
    nationalityCode: "DJI",
    nationalityName: "Djibouti"
  },
  {
    nationalityCode: "DMA",
    nationalityName: "Dominica"
  },
  {
    nationalityCode: "DOM",
    nationalityName: "Dominican Republic"
  },
  {
    nationalityCode: "ECU",
    nationalityName: "Ecuador"
  },
  {
    nationalityCode: "EGY",
    nationalityName: "Egypt"
  },
  {
    nationalityCode: "SLV",
    nationalityName: "El Salvador"
  },
  {
    nationalityCode: "GNQ",
    nationalityName: "Equatorial Guinea"
  },
  {
    nationalityCode: "ERI",
    nationalityName: "Eritrea"
  },
  {
    nationalityCode: "EST",
    nationalityName: "Estonia"
  },
  {
    nationalityCode: "ETH",
    nationalityName: "Ethiopia"
  },
  {
    nationalityCode: "FLK",
    nationalityName: "Falkland Islands (Malvinas)"
  },
  {
    nationalityCode: "FRO",
    nationalityName: "Faroe Islands"
  },
  {
    nationalityCode: "FJI",
    nationalityName: "Fiji"
  },
  {
    nationalityCode: "FIN",
    nationalityName: "Finland"
  },
  {
    nationalityCode: "FRA",
    nationalityName: "France"
  },
  {
    nationalityCode: "GUF",
    nationalityName: "French Guiana"
  },
  {
    nationalityCode: "PYF",
    nationalityName: "French Polynesia"
  },
  {
    nationalityCode: "ATF",
    nationalityName: "French Southern Territories"
  },
  {
    nationalityCode: "GAB",
    nationalityName: "Gabon"
  },
  {
    nationalityCode: "GMB",
    nationalityName: "Gambia"
  },
  {
    nationalityCode: "GEO",
    nationalityName: "Georgia"
  },
  {
    nationalityCode: "DEU",
    nationalityName: "Germany"
  },
  {
    nationalityCode: "GHA",
    nationalityName: "Ghana"
  },
  {
    nationalityCode: "GIB",
    nationalityName: "Gibraltar"
  },
  {
    nationalityCode: "GRC",
    nationalityName: "Greece"
  },
  {
    nationalityCode: "GRL",
    nationalityName: "Greenland"
  },
  {
    nationalityCode: "GRD",
    nationalityName: "Grenada"
  },
  {
    nationalityCode: "GLP",
    nationalityName: "Guadeloupe"
  },
  {
    nationalityCode: "GUM",
    nationalityName: "Guam"
  },
  {
    nationalityCode: "GTM",
    nationalityName: "Guatemala"
  },
  {
    nationalityCode: "GGY",
    nationalityName: "Guernsey"
  },
  {
    nationalityCode: "GIN",
    nationalityName: "Guinea"
  },
  {
    nationalityCode: "GNB",
    nationalityName: "Guinea-Bissau"
  },
  {
    nationalityCode: "GUY",
    nationalityName: "Guyana"
  },
  {
    nationalityCode: "HTI",
    nationalityName: "Haiti"
  },
  {
    nationalityCode: "HMD",
    nationalityName: "Heard Island and McDonald Islands"
  },
  {
    nationalityCode: "VAT",
    nationalityName: "Holy See (Vatican City State)"
  },
  {
    nationalityCode: "HND",
    nationalityName: "Honduras"
  },
  {
    nationalityCode: "HKG",
    nationalityName: "Hong Kong"
  },
  {
    nationalityCode: "HUN",
    nationalityName: "Hungary"
  },
  {
    nationalityCode: "ISL",
    nationalityName: "Iceland"
  },
  {
    nationalityCode: "IND",
    nationalityName: "India"
  },
  {
    nationalityCode: "IDN",
    nationalityName: "Indonesia"
  },
  {
    nationalityCode: "IRN",
    nationalityName: "Iran"
  },
  {
    nationalityCode: "IRQ",
    nationalityName: "Iraq"
  },
  {
    nationalityCode: "IRL",
    nationalityName: "Ireland"
  },
  {
    nationalityCode: "IMN",
    nationalityName: "Isle of Man"
  },
  {
    nationalityCode: "ISR",
    nationalityName: "Israel"
  },
  {
    nationalityCode: "ITA",
    nationalityName: "Italy"
  },
  {
    nationalityCode: "JAM",
    nationalityName: "Jamaica"
  },
  {
    nationalityCode: "JPN",
    nationalityName: "Japan"
  },
  {
    nationalityCode: "JEY",
    nationalityName: "Jersey"
  },
  {
    nationalityCode: "JOR",
    nationalityName: "Jordan"
  },
  {
    nationalityCode: "KAZ",
    nationalityName: "Kazakhstan"
  },
  {
    nationalityCode: "KEN",
    nationalityName: "Kenya"
  },
  {
    nationalityCode: "KIR",
    nationalityName: "Kiribati"
  },
  {
    nationalityCode: "KOR",
    nationalityName: "Korea"
  },
  {
    nationalityCode: "KWT",
    nationalityName: "Kuwait"
  },
  {
    nationalityCode: "KGZ",
    nationalityName: "Kyrgyzstan"
  },
  {
    nationalityCode: "LAO",
    nationalityName: "Lao Peoples Democratic Republic"
  },
  {
    nationalityCode: "LVA",
    nationalityName: "Latvia"
  },
  {
    nationalityCode: "LBN",
    nationalityName: "Lebanon"
  },
  {
    nationalityCode: "LSO",
    nationalityName: "Lesotho"
  },
  {
    nationalityCode: "LBR",
    nationalityName: "Liberia"
  },
  {
    nationalityCode: "LBY",
    nationalityName: "Libya"
  },
  {
    nationalityCode: "LIE",
    nationalityName: "Liechtenstein"
  },
  {
    nationalityCode: "LTU",
    nationalityName: "Lithuania"
  },
  {
    nationalityCode: "LUX",
    nationalityName: "Luxembourg"
  },
  {
    nationalityCode: "MAC",
    nationalityName: "Macao"
  },
  {
    nationalityCode: "MKD",
    nationalityName: "Macedonia"
  },
  {
    nationalityCode: "MDG",
    nationalityName: "Madagascar"
  },
  {
    nationalityCode: "MWI",
    nationalityName: "Malawi"
  },
  {
    nationalityCode: "MYS",
    nationalityName: "Malaysia"
  },
  {
    nationalityCode: "MDV",
    nationalityName: "Maldives"
  },
  {
    nationalityCode: "MLI",
    nationalityName: "Mali"
  },
  {
    nationalityCode: "MLT",
    nationalityName: "Malta"
  },
  {
    nationalityCode: "MHL",
    nationalityName: "Marshall Islands"
  },
  {
    nationalityCode: "MTQ",
    nationalityName: "Martinique"
  },
  {
    nationalityCode: "MRT",
    nationalityName: "Mauritania"
  },
  {
    nationalityCode: "MUS",
    nationalityName: "Mauritius"
  },
  {
    nationalityCode: "MYT",
    nationalityName: "Mayotte"
  },
  {
    nationalityCode: "MEX",
    nationalityName: "Mexico"
  },
  {
    nationalityCode: "FSM",
    nationalityName: "Micronesia"
  },
  {
    nationalityCode: "MDA",
    nationalityName: "Moldova"
  },
  {
    nationalityCode: "MCO",
    nationalityName: "Monaco"
  },
  {
    nationalityCode: "MNG",
    nationalityName: "Mongolia"
  },
  {
    nationalityCode: "MNE",
    nationalityName: "Montenegro"
  },
  {
    nationalityCode: "MSR",
    nationalityName: "Montserrat"
  },
  {
    nationalityCode: "MAR",
    nationalityName: "Morocco"
  },
  {
    nationalityCode: "MOZ",
    nationalityName: "Mozambique"
  },
  {
    nationalityCode: "MMR",
    nationalityName: "Myanmar"
  },
  {
    nationalityCode: "NAM",
    nationalityName: "Namibia"
  },
  {
    nationalityCode: "NRU",
    nationalityName: "Nauru"
  },
  {
    nationalityCode: "NPL",
    nationalityName: "Nepal"
  },
  {
    nationalityCode: "NLD",
    nationalityName: "Netherlands"
  },
  {
    nationalityCode: "NCL",
    nationalityName: "New Caledonia"
  },
  {
    nationalityCode: "NZL",
    nationalityName: "New Zealand"
  },
  {
    nationalityCode: "NIC",
    nationalityName: "Nicaragua"
  },
  {
    nationalityCode: "NER",
    nationalityName: "Niger"
  },
  {
    nationalityCode: "NGA",
    nationalityName: "Nigeria"
  },
  {
    nationalityCode: "NIU",
    nationalityName: "Niue"
  },
  {
    nationalityCode: "NFK",
    nationalityName: "Norfolk Island"
  },
  {
    nationalityCode: "MNP",
    nationalityName: "Northern Mariana Islands"
  },
  {
    nationalityCode: "NOR",
    nationalityName: "Norway"
  },
  {
    nationalityCode: "OMN",
    nationalityName: "Oman"
  },
  {
    nationalityCode: "PAK",
    nationalityName: "Pakistan"
  },
  {
    nationalityCode: "PLW",
    nationalityName: "Palau"
  },
  { nationalityCode: "PSE", nationalityName: "Palestine" },
  {
    nationalityCode: "PAN",
    nationalityName: "Panama"
  },
  {
    nationalityCode: "PNG",
    nationalityName: "Papua New Guinea"
  },
  {
    nationalityCode: "PRY",
    nationalityName: "Paraguay"
  },
  {
    nationalityCode: "PER",
    nationalityName: "Peru"
  },
  {
    nationalityCode: "PHL",
    nationalityName: "Philippines"
  },
  {
    nationalityCode: "PCN",
    nationalityName: "Pitcairn"
  },
  {
    nationalityCode: "POL",
    nationalityName: "Poland"
  },
  {
    nationalityCode: "PRT",
    nationalityName: "Portugal"
  },
  {
    nationalityCode: "PRI",
    nationalityName: "Puerto Rico"
  },
  {
    nationalityCode: "QAT",
    nationalityName: "Qatar"
  },
  {
    nationalityCode: "ROU",
    nationalityName: "Romania"
  },
  {
    nationalityCode: "RUS",
    nationalityName: "Russian Federation"
  },
  {
    nationalityCode: "RWA",
    nationalityName: "Rwanda"
  },
  {
    nationalityCode: "REU",
    nationalityName: "Reunion"
  },
  {
    nationalityCode: "BLM",
    nationalityName: "Saint Barthelemy"
  },
  {
    nationalityCode: "SHN",
    nationalityName: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    nationalityCode: "KNA",
    nationalityName: "Saint Kitts and Nevis"
  },
  {
    nationalityCode: "LCA",
    nationalityName: "Saint Lucia"
  },
  {
    nationalityCode: "MAF",
    nationalityName: "Saint Martin (French part)"
  },
  {
    nationalityCode: "SPM",
    nationalityName: "Saint Pierre and Miquelon"
  },
  {
    nationalityCode: "VCT",
    nationalityName: "Saint Vincent and the Grenadines"
  },
  {
    nationalityCode: "WSM",
    nationalityName: "Samoa"
  },
  {
    nationalityCode: "SMR",
    nationalityName: "San Marino"
  },
  {
    nationalityCode: "STP",
    nationalityName: "Sao Tome and Principe"
  },
  {
    nationalityCode: "SAU",
    nationalityName: "Saudi Arabia"
  },
  {
    nationalityCode: "SEN",
    nationalityName: "Senegal"
  },
  {
    nationalityCode: "SRB",
    nationalityName: "Serbia"
  },
  {
    nationalityCode: "SYC",
    nationalityName: "Seychelles"
  },
  {
    nationalityCode: "SLE",
    nationalityName: "Sierra Leone"
  },
  {
    nationalityCode: "SGP",
    nationalityName: "Singapore"
  },
  {
    nationalityCode: "SXM",
    nationalityName: "Sint Maarten (Dutch part)"
  },
  {
    nationalityCode: "SVK",
    nationalityName: "Slovakia"
  },
  {
    nationalityCode: "SVN",
    nationalityName: "Slovenia"
  },
  {
    nationalityCode: "SLB",
    nationalityName: "Solomon Islands"
  },
  {
    nationalityCode: "SOM",
    nationalityName: "Somalia"
  },
  {
    nationalityCode: "ZAF",
    nationalityName: "South Africa"
  },
  {
    nationalityCode: "SGS",
    nationalityName: "South Georgia and the South Sandwich Islands"
  },
  {
    nationalityCode: "ESP",
    nationalityName: "Spain"
  },
  {
    nationalityCode: "LKA",
    nationalityName: "Sri Lanka"
  },
  {
    nationalityCode: "SSD",
    nationalityName: "Sudan"
  },
  {
    nationalityCode: "SUR",
    nationalityName: "Suriname"
  },
  {
    nationalityCode: "SJM",
    nationalityName: "Svalbard and Jan Mayen"
  },
  {
    nationalityCode: "SWZ",
    nationalityName: "Swaziland"
  },
  {
    nationalityCode: "SWE",
    nationalityName: "Sweden"
  },
  {
    nationalityCode: "CHE",
    nationalityName: "Switzerland"
  },
  {
    nationalityCode: "SYR",
    nationalityName: "Syria"
  },
  {
    nationalityCode: "TWN",
    nationalityName: "Taiwan, Province of China"
  },
  {
    nationalityCode: "TJK",
    nationalityName: "Tajikistan"
  },
  {
    nationalityCode: "TZA",
    nationalityName: "Tanzania"
  },
  {
    nationalityCode: "THA",
    nationalityName: "Thailand"
  },
  {
    nationalityCode: "TLS",
    nationalityName: "Timor-Leste"
  },
  {
    nationalityCode: "TGO",
    nationalityName: "Togo"
  },
  {
    nationalityCode: "TKL",
    nationalityName: "Tokelau"
  },
  {
    nationalityCode: "TON",
    nationalityName: "Tonga"
  },
  {
    nationalityCode: "TTO",
    nationalityName: "Trinidad and Tobago"
  },
  {
    nationalityCode: "TUN",
    nationalityName: "Tunisia"
  },
  {
    nationalityCode: "TUR",
    nationalityName: "Turkey"
  },
  {
    nationalityCode: "TKM",
    nationalityName: "Turkmenistan"
  },
  {
    nationalityCode: "TCA",
    nationalityName: "Turks and Caicos Islands"
  },
  {
    nationalityCode: "TUV",
    nationalityName: "Tuvalu"
  },
  {
    nationalityCode: "ARE",
    nationalityName: "UAE"
  },
  {
    nationalityCode: "UGA",
    nationalityName: "Uganda"
  },
  {
    nationalityCode: "UKR",
    nationalityName: "Ukraine"
  },
  {
    nationalityCode: "GBR",
    nationalityName: "United Kingdom"
  },
  {
    nationalityCode: "UMI",
    nationalityName: "United States Minor Outlying Islands"
  },
  {
    nationalityCode: "USA",
    nationalityName: "United States of America"
  },
  {
    nationalityCode: "URY",
    nationalityName: "Uruguay"
  },
  {
    nationalityCode: "UZB",
    nationalityName: "Uzbekistan"
  },
  {
    nationalityCode: "VUT",
    nationalityName: "Vanuatu"
  },
  {
    nationalityCode: "VEN",
    nationalityName: "Venezuela"
  },
  {
    nationalityCode: "VNM",
    nationalityName: "Viet Nam"
  },
  {
    nationalityCode: "VGB",
    nationalityName: "Virgin Islands, British"
  },
  {
    nationalityCode: "VIR",
    nationalityName: "Virgin Islands, U.S."
  },
  {
    nationalityCode: "WLF",
    nationalityName: "Wallis and Futuna"
  },
  {
    nationalityCode: "ESH",
    nationalityName: "Western Sahara"
  },
  {
    nationalityCode: "YEM",
    nationalityName: "Yemen"
  },
  {
    nationalityCode: "ZMB",
    nationalityName: "Zambia"
  },
  {
    nationalityCode: "ZWE",
    nationalityName: "Zimbabwe"
  }
];
