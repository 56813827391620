import { createAsyncThunk } from "@reduxjs/toolkit";
import urls from "./../constant/Url";
import axios from "./http-common";
import axiosDirect from "axios";
import { getThisMonth, getToday, getUrls } from "../constant/Utils";
import { ALLTIME_DATE } from "../constant/Constants";

/**
 * @constant {string} baseUrl - B2B base URL.
 */

/**
 * @constant {string} adminLocationBaseUrl - Admin Location base URL.
 */

/**
 * @constant {string} adminBaseUrl - Admin base URL.
 */
/**
 * The Login API.
 */
export const getLoginDetails = createAsyncThunk(
  "userLogin/login",
  async (userData, thunkAPI) => {
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.post(`${baseUrl}/${urls.login}`, userData);
      return response;
    } catch (error) {
      // return error message from API if anyverifyCustomerWithOTP
      return error;
    }
  }
);

/**
 * User Sign out.
 */
export const userSignOut = createAsyncThunk(
  "userLogin/signout",
  async (arg, thunkAPI) => {
    const token = sessionStorage.getItem("userToken");
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.get(`${baseUrl}/${urls.signOut}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

/**
 * Fetches the Company details based on company id.
 * @param {number} id - Company Id.
 * @returns {object} API response containing.
 */
export const getCompanyDetailsById = async (server, id) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(`${baseUrl}/${urls.company}/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Get the User details of logged in user.
 */
export const getUserScopeDetails = createAsyncThunk(
  "userLogin/scope",
  async (arg, thunkAPI) => {
    const token = sessionStorage.getItem("userToken");
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.get(`${baseUrl}/${urls.scope}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

/**
 * Fire API for the save the data in store to reset password.
 */
export const getForgotPassword = createAsyncThunk(
  "userLogin/forgotPassword",
  async (userData, thunkAPI) => {
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.post(
        `${baseUrl}/${urls.forgotPassword}`,
        userData
      );
      return response;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

/**
 * Handles the Generate OTP API integration.
 * @param {object} userData - object containing the required data to generate API
 * @returns {object} - API response for the Generate OTP.
 */
export const generateOTP = async (server, userData) => {
  const token = sessionStorage.getItem("userToken");
  try {
    const baseUrl = getUrls(server).b2bBaseUrl;
    const response = await axios.post(
      `${baseUrl}/${urls.generateOTP}`,
      userData,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the Validate OTP API integration.
 * @param {object} userOtp - User OTP with required  details.
 * @returns {object} - API response for the validate API.
 */
export const validateOTP = async (server, userOtp) => {
  try {
    const baseUrl = getUrls(server).b2bBaseUrl;
    const response = await axios.post(
      `${baseUrl}/${urls.validateOTP}`,
      userOtp,
      {}
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
/**
 * Handles the Generate OTP hardware allocation/ replacement API integration.
 * @param {object} userData - object containing the required data to generate API
 * @returns {object} - API response for the Generate OTP.
 */
export const generateCustomerOtpDetails = async (server, userData) => {
  const token = sessionStorage.getItem("userToken");
  try {
    const baseUrl = getUrls(server).crmBaseUrl;
    const response = await axios.post(
      `${baseUrl}/${urls.generateCustomerHardwareOtp}`,
      userData,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the Validate OTP  hardware allocation/ replacement API integration.
 * @param {object} userOtp - User OTP with required  details.
 * @returns {object} - API response for the validate API.
 */
export const validateCustomerOtpDetails = async (server, userOtp) => {
  try {
    const baseUrl = getUrls(server).crmBaseUrl;
    const response = await axios.post(
      `${baseUrl}/${urls.validateCustomerHardWareOtp}`,
      userOtp,
      {}
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
/**
 * Handles the Generate OTP Retailer API integration.
 * @param {object} userData - object containing the required data to generate API
 * @returns {object} - API response for the Generate OTP.
 */
export const generateUserOtpDetails = async (server, userData) => {
  const token = sessionStorage.getItem("userToken");
  try {
    const baseUrl = getUrls(server).b2bBaseUrl;
    const response = await axios.post(
      `${baseUrl}/${urls.generateUserOtp}`,
      userData,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the Validate OTP Retailer API integration.
 * @param {object} userOtp - User OTP with required  details.
 * @returns {object} - API response for the validate API.
 */
export const validateUserOtpDetails = async (server, userOtp) => {
  const token = sessionStorage.getItem("userToken");
  try {
    const baseUrl = getUrls(server).b2bBaseUrl;
    const response = await axios.post(
      `${baseUrl}/${urls.validateUserOtp}`,
      userOtp,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the Credit Transfer details API Integration.
 * @param {string} transactionStatus - Transaction Status of Credits details.
 * @param {string} startDate - Start Date for the Credit Transfer.
 * @param {string} endDate - End date for the Credit Transfer.
 * @param {number} fromCount - Starting count for Pagination.
 * @param {number} toCount - Ending count for pagination.
 * @returns {object} - API response for Fetching the Credits transfer details.
 */
export const getCreditTransferDetails = async (
  server,
  transactionStatus,
  startDate,
  endDate,
  fromCount,
  toCount
) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.credits}?transactionStatus=${transactionStatus}&startDate=${startDate}&endDate=${endDate}&fromCount=${fromCount}&toCount=${toCount}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the Passcode Verification API integration
 * @param {object} passcodeData -  Object containing passcode details.
 * @returns {object} - API response for the Passcode Verification.
 */
export const getPasscodeVerification = async (server, passcodeData) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.passcodeVerification}`,
      passcodeData,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the Change Password API integration.
 */
export const updateChangePassword = createAsyncThunk(
  "userLogin/changePassword",
  async (userPasswordDetails, thunkAPI) => {
    const token = sessionStorage.getItem("userToken");
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.post(
        `${baseUrl}/${urls.changePassword}`,
        userPasswordDetails,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

/**
 * Handles the API integration to get the location.
 * @returns {object} - API response containing the location details based on user Location IP.
 */
export const getLocation = async () => {
  try {
    const response = await axiosDirect.get(urls.geoLocation, {
      headers: {
        "Content-type": "application/json"
      }
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getLocationDetails = createAsyncThunk(
  "locationDetails",
  async (arg, thunkAPI) => {
    const { server } = thunkAPI.getState().config;
    const adminLocationBaseUrl = getUrls(server).adminLocationBaseUrl;
    try {
      const response = await axiosDirect.get(
        `${adminLocationBaseUrl}/${urls.geoLocation}`,
        {
          headers: {}
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);
/**
 * Handles the API integration to get the country details.
 * @returns {object} - API response containing the country details based on user Location IP.
 */
export const getCountry = async (server) => {
  try {
    const adminBaseUrl = getUrls(server).adminBaseUrl;
    const response = await axiosDirect.get(
      `${adminBaseUrl}/${urls.coutriesList}`,
      {
        headers: {
          "Content-type": "application/json"
        }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API integration for Changing the Child Channel Partner Passcode.
 * @param {string} partnerCode - Child Channel Partner code.
 * @returns {object} API response of reset child passcode.
 */
export const resetChildPasscode = async (server, partnerCode) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.resetChildPasscode}?childChannelPartnerCode=${partnerCode} `,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration for Changing the Child Channel Partner password.
 * @param {string} partnerCode - Child Channel Partner code.
 * @returns {object} API response of reset child password.
 */
export const resetChildPassword = async (server, partnerCode) => {
  const token = sessionStorage.getItem("userToken");
  try {
    const baseUrl = getUrls(server).b2bBaseUrl;
    const response = await axios.post(
      `${baseUrl}/${urls.resetChildPassword}?childChannelPartnerCode=${partnerCode} `,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to get the Logged in user details.
 * @returns {object} API respos
 */
export const getUserDetails = async (server) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(`${baseUrl}/${urls.userDetails}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handling API integration for getting the Credits transfer details.
 * @param {string} startDate - Start Date of Credit Details info.
 * @param {string} endDate - End Date for Credit Details info.
 * @returns {object} API response for the Credits Transfer details API.
 */
export const getCreditSummary = createAsyncThunk(
  "totalCreditBalanceSummary",
  async (arg, thunkAPI) => {
    const { startDate, endDate } = arg;
    const token = sessionStorage.getItem("userToken");
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.get(
        `${baseUrl}/${urls.creditSummary}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

/**
 * Handling API integration for Getting the Recent Credits Transfer.
 * @param {string} startDate - Start Date for Recent Credits list
 * @param {string} endDate - End Date for Recent Credits list.
 * @param {number} fromCount - Start count number
 * @param {number} toCount - End count number
 * @param {number} limit - limit number for recent credits transfer
 * @returns {object} API response for the API of Recent Credits Transfer list.
 */
export const getRecentCredits = async (
  server,
  startDate,
  endDate,
  fromCount,
  toCount,
  limit = 0
) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.recentCredits}?startDate=${startDate}&endDate=${endDate}&fromCount=${fromCount}&toCount=${toCount}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API Integration for getting the City List based on the Country Code.
 * @param {string} countryCode - Country code
 * @returns {object} API response with City list.
 */
export const getCityList = async (server, countryCode) => {
  try {
    const adminBaseUrl = getUrls(server).adminBaseUrl;
    const response = await axiosDirect.get(
      `${adminBaseUrl}/${urls.cityList}?countryCode=${countryCode}`,
      {}
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles API integration for Retailer Registration.
 * @param {object} retailer - Retailier info for Registration
 * @returns {object} API response for the Retailer Registration action.
 */
export const postRetailerData = async (server, retailer) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.retailerOnboarding}`,
      retailer,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to get the Parent Channel Partner info.
 * @returns {object} API response of the Partner Channel Details response
 */
export const getParentDetails = async (server) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(`${baseUrl}/${urls.parentDetails}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API Integration to get the Channel Partner Code Credit Balance info.
 * @param {string} code -  Channel Partner Code.
 * @returns {object} API response for the Child Channel Partner Credit balance info.
 */
export const getCreditBalance = async (server, code, transferType) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.creditBalance}${code}&transferType=${transferType}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API Integration for getting the Stock inventory info.
 * @param {object} date - Filtered Date info with the Tab Status.
 * @returns {object} - API response for the Stock inventory info.
 */
export const getInventoryStocks = async (server, date) => {
  const { startDate, endDate, status } = date;
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.inventoryStock}?status=${status}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API integration to get the companies list.
 * @returns {object} API response with the companies list.
 */
export const getCompanies = async (server) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(`${baseUrl}/${urls.companies}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API Integration to Transfer credits.
 * @param {object} transferDetails - transfer details object.
 * @returns {object} API response for the Credit transfer.
 */
export const transferCredit = async (server, transferDetails) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.creditsTransfer}`,
      transferDetails,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API Integration to get the details of Child channel partner based on Child channel partner code.
 * @param {string} code - Child Channel Partner Code.
 * @returns {object} API response with the Child Channel Partner details.
 */
export const getChildDetails = async (server, code) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(`${baseUrl}/${urls.childDetails}${code}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API integration to unblock child channel partner based on child channel partner code.
 * @param {string} partnerCode - Child channel partner code
 * @returns {object} API response for the Ublock Action.
 */
export const unblockChildAccount = async (server, partnerCode) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.unblockChildUserAccount}?childChannelPartnerCode=${partnerCode} `,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to block child channel partner based on child channel partner code.
 * @param {string} partnerCode - Child channel partner code
 * @returns {object} API response for the block Action.
 */
export const blockChildAccount = async (server, partnerCode, reason) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;

  try {
    const response = await axios.post(
      `${baseUrl}/${urls.blockChildUserAccount}`,
      {
        channelPartnerCode: partnerCode,
        reason: reason
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to update Child channel partner details.
 * @param {string} partnerCode - Channel partner code.
 * @param {object} payload - Channel partner details
 * @returns {object} API response on Update child Channel Partner.
 */
export const updateChildProfileDetails = async (
  server,
  partnerCode,
  payload
) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.updateChildProfile}?childChannelPartnerCode=${partnerCode} `,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to get the Child channel partner list.
 * @returns {object} API response with the list of child channel partners.
 */
export const getChildChannelPartners = async (server) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(`${baseUrl}/${urls.childChannelPartner}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles to update the Store with the Country details info.
 */
export const getCountryDetails = createAsyncThunk(
  "countryDetails",
  async (arg, thunkAPI) => {
    const token = sessionStorage.getItem("userToken");
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.get(`${baseUrl}/${urls.countryDetails}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

/**
 * Handles the OTP verification process with the help of mobile no.
 * @param {object} userOtp - Mobile no. with country code.
 * @returns {object} API response with OTP.
 */
export const verifyCustomerWithOTP = async (server, userOtp) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.verifyCustomerWithOTP}`,
      userOtp,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API Integration to Validate the OTP.
 * @param {object} userOtp - Mobile no. with OTP value.
 * @returns {object} API response of the OTP validation.
 */
export const validateCustomerWithOTP = async (server, userOtp) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.validateCustomerWithOTP}`,
      userOtp,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to get the Subscription list.
 * @param {string} startDate - Start Date
 * @param {string} endDate - End Date
 * @returns {object} API response with the subscription list.
 */
export const getSubscriptionList = async (server, startDate, endDate) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.subscriptionList}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API Integration to register customer.
 * @param {object} payload - Customer details.
 * @returns {object} API response for the Customer registration.
 */
export const CustomerRegistrationWithStb = async (server, payload) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;

  try {
    const response = await axios.post(
      `${baseUrl}/${urls.customerRegistration}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to track STB.
 * @param {string} stbId  - STB ID.
 * @returns {object} API response with STB details.
 */
export const trackSTB = async (server, stbId) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.trackSTB}?serialNumber=${stbId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration for getting the Customer details.
 * @param {string} type - Search type.
 * @param {string} value - Search Value.
 * @returns {object} API response with customer details.
 */
export const getCustomerInfo = async (server, type, value) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;

  try {
    const response = await axios.get(
      `${baseUrl}/${urls.getCustomer}?${type}=${value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to allocate STB.
 * @param {object} payload - STB info.
 * @returns {object} API response for the STB Allocation status.
 */
export const allocateSTB = async (server, payload) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;

  try {
    const response = await axios.post(
      `${baseUrl}/${urls.allocateSTB}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API Integration to get the Plan list.
 * @param {string} customerId - Customer Id.
 * @param {string} type - Type of Subscription
 * @param {*} serialNumber - Serial no.
 * @returns {object} API response with the Plan list
 */
export const getPlanList = async (server, customerId) => {
  const baseUrl = getUrls(server).b2bBaseUrl;
  const token = sessionStorage.getItem("userToken");
  try {
    const response = await axios.get(
      `${baseUrl}/${
        urls.planList
      }?customerId=${customerId}&serialNumber=${null}&hardwareType=OTT`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to get the Hardware transaction details.
 * @param {string} startDate - Start date
 * @param {string} endDate - End date
 * @returns {object} API response with Hardware transaction details.
 */
export const getHardwareTransactions = async (server, startDate, endDate) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.hardwareTransactions}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles API integration to get the Inventry stock count.
 * @returns {object} API response for the Inventory stock count.
 */
export const getInventoryStockCount = async (server, id) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(`${baseUrl}/${urls.inventoryStockCount}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API integration to Add Subscription.
 * @param {object} payload - Subscription payload.
 * @returns {object} API response with Subscription status.
 */
export const addSubscription = async (server, payload) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.addSubscription}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Revenue List API Handling.
 * @param {string} startDate - Start date.
 * @param {string} endDate - End date.
 * @returns {object} API response with revenue list details.
 */
export const getChildChannelPartnerRevenueList = async (
  server,
  startDate,
  endDate
) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.childChannelPartnerRevenueList}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Revenue List API Handling.
 * @param {string} startDate - Start date.
 * @param {string} endDate - End date.
 * @returns {object} API response with revenue count details.
 */
export const getChildChannelPartnerRevenue = async (
  server,
  startDate,
  endDate
) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.childChannelPartnerRevenue}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API integration to get the Notification messages.
 * @param {string} type - Notification type - ALL or USER.
 * @returns {object} API response with Notification details.
 */
export const getNotifications = async (server, payload) => {
  const token = sessionStorage.getItem("userToken");
  const x = getThisMonth();
  const y = getToday();
  const { startDate = x, endDate = y, fromCount, toCount, type } = payload;
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.notifications}?type=${type}&startDate=${startDate}&endDate=${endDate}&fromCount=${fromCount}&toCount=${toCount}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API Integration for STB Replacement.
 * @param {object} payload - STB details for replacement
 * @returns {object} - API response with replacement status.
 */
export const replaceSTB = async (server, payload) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.replaceSTB}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API Integration for the Push notification.
 * @param {string} channelPartnerCode - Channel partner code.
 * @returns {object} API response for the push notification.
 */
export const pushNotification = (server, channelPartnerCode) => {
  const baseUrl = getUrls(server).b2bBaseUrl;
  return `${baseUrl}/${urls.pushNotify}?channelPartnerCode=${channelPartnerCode}`;
};

/**
 * Handle API Integration to Generate Invoice.
 * @param {string} customerId - Customer ID.
 * @param {string} orderId - Invoice ID.
 * @returns {blob} PDF response.
 */
export const generateInvoice = async (server, customerId, orderId) => {
  const baseUrl = getUrls(server).b2bBaseUrl;
  const token = sessionStorage.getItem("userToken");
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.invoiceDownload}?orderId=${orderId}&customerId=${customerId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob"
      }
    );
    if (response.status === 200) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      link.download = "Receipt.pdf";
      link.click();
    } else {
      return response;
    }
    return response;
  } catch (e) {
    return e;
  }
};

/**
 * Handles the API integration to download credit report.
 * @param {string} start  - Start Date.
 * @param {string} end - End Date.
 * @param {string} transactionStatus - transaction Status.
 * @returns {string} API response with CSV data.
 */
export const downloadCreditReport = async (
  server,
  start,
  end,
  transactionStatus
) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.downloadCreditReport}?startDate=${start}&endDate=${end}&transactionStatus=${transactionStatus}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "text/csv"
        }
      }
    );
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to download Child revenue report.
 * @param {string} start  - Start Date.
 * @param {string} end - End Date.
 * @returns {string} API response with CSV data.
 */
export const downloadChildRevenueReport = async (server, start, end) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.downloadRevenueReport}?startDate=${start}&endDate=${end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "text/csv"
        }
      }
    );
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to download Stock report.
 * @param {string} start  - Start Date.
 * @param {string} end - End Date.
 * @param {string} status - Inventory stock status.
 * @returns {string} API response with CSV data.
 */
export const downloadInventoryStockReport = async (
  server,
  start,
  end,
  status
) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.downloadStockReport}?startDate=${start}&endDate=${end}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "text/csv"
        }
      }
    );
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to check the eligibility of the subscription pack.
 * @param {string} type - Notification type - ALL or USER.
 * @returns {object} API response with eligibility details.
 */
export const checkEligibility = async (server, planCode, mobileNumber) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.checkEligibility}?planCode=${planCode}&mobileNumber=${mobileNumber}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Handles the API Integration for broadcast-notification.

 * @returns {object} - API response with broadcast message.
 */
export const getBroadcastNotification = createAsyncThunk(
  "broadcastNotification",
  async (arg, thunkAPI) => {
    const token = sessionStorage.getItem("userToken");
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.get(
        `${baseUrl}/${urls.broadcastNotification}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

/**
 * if user see the notification  and close
 * Handles the API Integration for notification read,
 * @param {object} payload - notification details
 * @returns {object} - API response with notification read status.
 */
export const readNotification = async (server, payload) => {
  const token = sessionStorage.getItem("userToken");
  const { messageId } = payload;
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.notificationRead}?messageId=${messageId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Handles the API integration to download the summary Report.
 * @param {string} start  - Start Date.
 * @param {string} end - End Date.
 * @returns {string} API response with pdf data.
 */
export const downloadSummaryReport = async (server, start, end) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.get(
      `${baseUrl}/${urls.summaryReport}?startDate=${start}&endDate=${end}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob"
      }
    );
    if (response.status === 200) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      link.download = "Summary Report.pdf";
      link.click();
    } else {
      return response;
    }
    return response;
  } catch (e) {
    return e;
  }
};

/**
 * if user see the notification  and close
 * Handles the API Integration for notification read,
 * @param {object} payload - notification details
 * @returns {object} - API response with notification read status.
 */
export const getRefreshSTBStatus = async (server, payload) => {
  const token = sessionStorage.getItem("userToken");
  const { serialNumber, customerId } = payload;
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.post(
      `${baseUrl}/${urls.refreshSTB}`,
      {
        customerId: customerId,
        serialNumber: serialNumber
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCreditSummaryBalance = createAsyncThunk(
  "creditBalance",
  async (arg, thunkAPI) => {
    const startDate = ALLTIME_DATE;
    const endDate = getToday();
    const token = sessionStorage.getItem("userToken");
    const { server } = thunkAPI.getState().config;
    const baseUrl = getUrls(server).b2bBaseUrl;
    try {
      const response = await axios.get(
        `${baseUrl}/${urls.creditSummary}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

/**
 * if user see the notification  and close
 * Handles the API Integration for notification read,
 * @param {object} payload - notification details
 * @returns {object} - API response with notification read status.
 */
export const readTransactionNotification = async (server, messageId) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.put(
      `${baseUrl}/${urls.transactionRead}?notificationId=${messageId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
/**
 * if user see the notification  and close
 * Handles the API Integration for notification read,
 * @param {object} payload - notification details
 * @returns {object} - API response with notification read status.
 */
export const addFcmToken = async (server, fcmToken) => {
  const token = sessionStorage.getItem("userToken");
  const baseUrl = getUrls(server).b2bBaseUrl;
  try {
    const response = await axios.put(
      `${baseUrl}/${urls.addFcmToken}`,
      {
        fcmToken: fcmToken
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
/**
 * @constant {object} userService - List of API calls.
 */
const userService = {
  getInventoryStockCount,
  getLoginDetails,
  userSignOut,
  getCompanyDetailsById,
  getUserScopeDetails,
  getPasscodeVerification,
  getLocation,
  getCountry,
  validateOTP,
  resetChildPassword,
  resetChildPasscode,
  getUserDetails,
  getCreditSummary,
  getRecentCredits,
  getParentDetails,
  getCreditBalance,
  getInventoryStocks,
  getForgotPassword,
  getChildDetails,
  verifyCustomerWithOTP,
  validateCustomerWithOTP,
  CustomerRegistrationWithStb,
  getPlanList,
  pushNotification
};

export default userService;
