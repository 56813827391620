import { Box, Divider, MenuItem, MenuList, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  ACCOUNT_BLOCKED_KEY,
  ACCOUNT_UNBLOCKED_KEY,
  BLOCK_ACCOUNT_SUCCESS,
  CREDIT_KEY,
  CREDIT_TRANSFER_KEY,
  DIRECT_KEY,
  NO_TRANSACTIONS,
  PASSCODE_RESET_KEY,
  REASON,
  RESET_PASSCODE_SUCCESS,
  REVERSE_CREDIT_KEY,
  REVERSE_DIRECT_KEY,
  UNBLOCK_ACCOUNT_SUCCESS
} from "../../constant/Constants";
import {
  CapitalizeLetter,
  currencyConvertToReadable,
  getReceiverTransactionStatus,
  getTransactionStatus,
  ksaRelativeTime
} from "../../constant/Utils";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { getNotification } from "../../stateManagement/reducers/NotificationSlice";
import ColoredCircle from "../coloredCircle/ColoredCircle";

const TransactionsPanel = (props) => {
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  const notificationList = useSelector((state) => getNotification(state));
  /**
   * Renders the text for the Notification based on the Credit Transfet type.
   * @param {object} notification
   * @returns {component}the text messages Formatted message UI based on the Credit Transfer type.
   */

  /**
   * useEffect Hook Preloads the Notification messages.
   */

  // useEffect(() => {

  //   const uniqueNotification = notifactionMessage.filter(
  //     (item, index, self) =>
  //       self.findIndex((obj) => obj.id === item.id) === index
  //   );
  //   setNotificationList(uniqueNotification);
  // }, [notifactionMessage]);

  const directAndreverserpendingNotification = (notification) => {
    return (
      <Typography
        sx={{
          fontSize: 12,
          whiteSpace: "break-spaces",
          pl: 0.6,
          fontFamily: "BukraRegular",
          letterSpacing: "0px"
        }}
        align="left"
      >
        <Typography
          component="span"
          sx={{
            fontSize: 12,
            fontFamily: "BukraBold",
            letterSpacing: "0px",
            color: "#212121"
          }}
        >
          {channelPartnerCode && channelPartnerCode === notification.fromUser
            ? `${notification.toUserChannelPartnerName} [${notification.toUserCompanyName}]`
            : `${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: 12,
            fontFamily: "BukraRegular",
            letterSpacing: "0px",
            px: 0.3
          }}
          align="left"
        >
          {notification.transferType === DIRECT_KEY ||
          notification.transferType === CREDIT_KEY
            ? "has transferred "
            : "has initiated a reverse transfer of"}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: 12,
            fontFamily: "BukraBold",
            letterSpacing: "0px",
            color: "#212121"
          }}
        >
          {notification.currency}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: 12,
            fontFamily: "BukraBold",
            letterSpacing: "0px",
            color: "#212121",
            pl: 0.3
          }}
        >
          {currencyConvertToReadable(notification.creditAmount)}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: 12,
            fontFamily: "BukraRegular",
            letterSpacing: "0px",
            px: 0.3
          }}
          align="left"
        >
          {
            getReceiverTransactionStatus(
              notification.transferType,
              notification.status
            ).completion
          }
        </Typography>
      </Typography>
    );
  };

  const reverserSucessandrejectNotificationMsg = (notification) => {
    return (
      <Typography
        sx={{
          fontSize: 12,
          whiteSpace: "break-spaces",
          pl: 0.6,
          fontFamily: "BukraRegular",
          letterSpacing: "0px"
        }}
        align="left"
      >
        Reverse transfer of
        <Typography
          component="span"
          sx={{
            fontSize: 12,
            fontFamily: "BukraBold",
            px: 0.5,
            letterSpacing: "0px",
            color: "#212121"
          }}
        >
          {notification.currency}{" "}
          {currencyConvertToReadable(notification.creditAmount)}
        </Typography>
        Credits initiated by
        <Typography
          component="span"
          sx={{
            fontSize: 12,
            fontFamily: "BukraBold",
            pr: 0.5,
            pl: 0.5,
            letterSpacing: "0px",
            color: "#212121"
          }}
        >
          {channelPartnerCode && channelPartnerCode === notification.fromUser
            ? `${notification.toUserChannelPartnerName} [${notification.toUserCompanyName}]`
            : `${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`}
          {/* {`${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`} */}
        </Typography>
        {
          getReceiverTransactionStatus(
            notification.transferType,
            notification.status
          ).completion
        }
      </Typography>
    );
  };

  const receiverMessage = (notification) => {
    return (
      <Box sx={{ display: "flex" }}>
        <ColoredCircle
          color={getTransactionStatus(notification.status).color}
        ></ColoredCircle>
        {/* direct message */}

        {(notification.transferType === DIRECT_KEY ||
          notification.transferType === CREDIT_KEY) &&
          directAndreverserpendingNotification(notification)}
        {(notification.transferType === REVERSE_DIRECT_KEY ||
          notification.transferType === REVERSE_CREDIT_KEY) &&
          notification.status === "PENDING" &&
          directAndreverserpendingNotification(notification)}
        {(notification.transferType === REVERSE_DIRECT_KEY ||
          notification.transferType === REVERSE_CREDIT_KEY) &&
          notification.status === "SUCCESS" &&
          reverserSucessandrejectNotificationMsg(notification)}
        {(notification.transferType === REVERSE_DIRECT_KEY ||
          notification.transferType === REVERSE_CREDIT_KEY) &&
          notification.status === "REJECTED" &&
          reverserSucessandrejectNotificationMsg(notification)}
        {/* reverser message sucess */}
        {/* reverser message Rejected */}

        {/* <Typography
          sx={{
            fontSize: 12,
            whiteSpace: "break-spaces",
            pl: 0.6,
            fontFamily: "BukraRegular",
            letterSpacing: "0px",
          }}
          align="left"
        >
          <Typography
            component="span"
            sx={{
              fontSize: 12,
              fontFamily: "BukraBold",
              pr: 0.5,
              pl: 0.5,
              letterSpacing: "0px",
              color: "#212121",
            }}
          >
            {channelPartnerCode && channelPartnerCode === notification.fromUser
              ? `${notification.toUserChannelPartnerName} [${notification.toUserCompanyName}]`
              : `${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`}
          </Typography>
          <Typography
            component="span"
            sx={{
              pl: 0.6,
              fontSize: 12,
              fontFamily: "BukraRegular",
              letterSpacing: "0px",
            }}
          >
            {notification.transferType === DIRECT
              ? "has transferred"
              : "has  initiated a reverse transfer of"}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: 12,
              fontFamily: "BukraBold",
              pr: 0.5,
              pl: 0.5,
              letterSpacing: "0px",
              color: "#212121",
            }}
          >
            {notification.currency} {notification.creditAmount}{" "}
          </Typography>
          {notification.transferType === DIRECT
            ? "Credits to your account.Please check your wallet!"
            : "Credits from your account and is awaiting approval"}
        </Typography> */}
      </Box>
    );
  };

  const initiatorMessage = (notification) => {
    return (
      <Box sx={{ display: "flex" }}>
        <ColoredCircle
          color={getTransactionStatus(notification.status).color}
        ></ColoredCircle>
        <Typography
          sx={{
            fontSize: 12,
            whiteSpace: "break-spaces",
            pl: 0.6,
            fontFamily: "BukraRegular",
            letterSpacing: "0px"
          }}
          align="left"
        >
          <Typography
            component="span"
            sx={{
              fontSize: 12,
              fontFamily: "BukraBold",
              pr: 0.5,
              letterSpacing: "0px",
              color: "#212121"
            }}
          >
            {CapitalizeLetter(notification.status)}!
          </Typography>
          {notification.transferType === DIRECT_KEY ||
          notification.transferType === CREDIT_KEY
            ? "Your Credit Transfer"
            : "Your Reverse Transfer"}{" "}
          of{" "}
          <Typography
            component="span"
            sx={{
              fontSize: 12,
              fontFamily: "BukraBold",
              pr: 0.5,
              pl: 0.5,
              letterSpacing: "0px",
              color: "#212121"
            }}
          >
            {notification.currency}{" "}
            {currencyConvertToReadable(notification.creditAmount)}{" "}
            {channelPartnerCode && channelPartnerCode === notification.fromUser
              ? "to"
              : "from"}{" "}
            {channelPartnerCode && channelPartnerCode === notification.fromUser
              ? `${notification.toUserChannelPartnerName} [${notification.toUserCompanyName}]`
              : `${notification.fromUserChannelPartnerName} [${notification.fromUserCompanyName}]`}
          </Typography>
          {
            getTransactionStatus(notification.status, notification.transferType)
              .completion
          }
        </Typography>
      </Box>
    );
  };

  const renderNotificationMsg = (notification) => {
    if (
      channelPartnerCode === notification.fromUser &&
      (notification.transferType === DIRECT_KEY ||
        notification.transferType === CREDIT_KEY)
    ) {
      return initiatorMessage(notification);
    } else if (
      channelPartnerCode === notification.toUser &&
      (notification.transferType === REVERSE_DIRECT_KEY ||
        notification.transferType === REVERSE_CREDIT_KEY)
    ) {
      return initiatorMessage(notification);
    } else {
      return receiverMessage(notification);
    }
  };

  /**
   * Renders the text for the Notification based on the Credit Transfet type.
   * @param {object} notification
   * @returns {component}the text messages Formatted message UI based on the Credit Transfer type.
   */
  const renderCreditTransferUI = (notification) => {
    return (
      <Box
        data-testid="creditTransferUI"
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column"
        }}
      >
        {renderNotificationMsg(notification)}
        {notification.transactionReason && (
          <Typography
            sx={{
              fontSize: "11px",
              pl: 1,
              fontFamily: "BukraRegular",
              color: "#282828"
            }}
          >
            <b>{REASON}:</b> {notification.transactionReason}
          </Typography>
        )}

        <Typography
          sx={{
            fontSize: 11,
            fontFamily: "BukraRegular",
            pl: 1,
            pt: 2,
            color: "#959595",
            letterSpacing: "0px",
            opacity: 1
          }}
        >
          {ksaRelativeTime(notification.createdDateTimeKSA)}
        </Typography>
      </Box>
    );
  };
  /**
   * Renders the UI for the text message containg the UI for other messages than the CREDIT TRANSFER type.
   * @param {object} notification - object containing the notification details.
   * @param {string} message - string containing the notification message.
   * @returns {component} the Notification message UI for the other messages than the CREDIT TRANSFER type
   */
  const renderOtherNotificationUI = (notification, message) => {
    return (
      <Box>
        <Box data-testid="otherNotificationUI" sx={{ display: "flex" }}>
          <ColoredCircle
            color={getTransactionStatus(notification.status).color}
          ></ColoredCircle>
          <Typography
            sx={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              pl: 0.6,
              fontFamily: "BukraRegular",
              letterSpacing: "0px"
            }}
            align="left"
          >
            <Typography
              component="span"
              sx={{
                fontSize: 12,
                whiteSpace: "break-spaces",
                fontFamily: "BukraRegular",
                letterSpacing: "0px",
                color: "#212121"
              }}
              align="left"
            >
              {message}
            </Typography>
          </Typography>
        </Box>
        {notification.blockedReason && (
          <Typography
            sx={{
              fontSize: "11px",
              pl: 1.4,
              fontFamily: "BukraRegular",
              color: "#282828"
            }}
          >
            <b>{REASON}:</b> {notification.blockedReason}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: 11,
            fontFamily: "BukraRegular",
            pl: 1,
            pt: 2,
            color: "#959595",
            letterSpacing: "0px",
            opacity: 1
          }}
        >
          {ksaRelativeTime(notification.createdDateTimeKSA)}
        </Typography>
      </Box>
    );
  };
  return (
    <MenuList
      sx={{
        height: "208px",
        overflowY: "auto",
        scrollbarWidth: "thin"
      }}
    >
      {notificationList && notificationList.length > 0 ? (
        notificationList.map((notification) => (
          <MenuItem
            key={notification.id}
            sx={{
              display: "block",
              cursor: "default"
            }}
          >
            {notification.notificationType === CREDIT_TRANSFER_KEY &&
              renderCreditTransferUI(notification)}
            {notification.notificationType === PASSCODE_RESET_KEY &&
              renderOtherNotificationUI(notification, RESET_PASSCODE_SUCCESS)}

            {notification.notificationType === ACCOUNT_BLOCKED_KEY &&
              renderOtherNotificationUI(notification, BLOCK_ACCOUNT_SUCCESS)}
            {notification.notificationType === ACCOUNT_UNBLOCKED_KEY &&
              renderOtherNotificationUI(notification, UNBLOCK_ACCOUNT_SUCCESS)}
            <Divider sx={{ mt: "4px !important" }} variant="fullWidth" />
          </MenuItem>
        ))
      ) : (
        <Typography
          sx={{
            fontSize: 12,
            whiteSpace: "break-spaces",
            pr: 0.5,
            fontFamily: "BukraRegular",
            letterSpacing: "0px",
            color: "#212121"
          }}
          textAlign="center"
          data-testid="noContent"
        >
          {NO_TRANSACTIONS}
        </Typography>
      )}
    </MenuList>
  );
};

export default memo(TransactionsPanel);
