import React, { memo, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import creditImg from "./../../assets/images/credits_card_transfer.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserRoleType } from "../../stateManagement/reducers/LoginSlice";
import {
  CREDITS_BLOCKED,
  MASTER_DISTRIBUTOR,
  MD_USER_ROLE_TYPE,
  SD_USER_ROLE_TYPE,
  SUB_DISTRIBUTOR,
  TOOLTIP_CREDIT_BALANCE,
  TOOLTIP_CREDIT_BLOCKED
} from "../../constant/Constants";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider } from "@mui/material";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import { getTotalCreditBalance } from "../../stateManagement/reducers/TotalCreditBalanceSummarySlice";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import { getCreditSummary } from "../../services/user.services";

const CreditSummaryCard = (props) => {
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const creditDetails = useSelector((state) => getTotalCreditBalance(state));
  const getdate = useSelector((state) => getFilteredDateFromStore(state));
  const dispatch = useDispatch();
  const { endDate, startDate } = getdate;

  useEffect(() => {
    if (endDate && startDate) {
      dispatch(getCreditSummary({ startDate, endDate }));
    }
  }, [startDate, endDate, dispatch]);

  const blockedBy =
    fetchUserRoleType === SD_USER_ROLE_TYPE
      ? MASTER_DISTRIBUTOR
      : SUB_DISTRIBUTOR;
  return (
    <>
      {creditDetails && (
        <Box
          data-testid="creditSummaryCard"
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #7070704D",
            borderRadius: "8px",
            height: { sm: "80px", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <ThemeProvider theme={ToolTipThemeWithBG}>
            <Box
              sx={{
                display: "flex",
                p: 1,
                pl: 1.5,
                pb: 1.2,
                pr: 1.5
              }}
            >
              <Box
                sx={{
                  display: "flex"
                }}
              >
                <img src={creditImg} alt="sub" height={"45px"}></img>

                <Tooltip title={TOOLTIP_CREDIT_BALANCE} placement="top" arrow>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      ml: 1
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        pt: 0.5,
                        color: "#202020",
                        fontFamily: "BukraMedium",
                        fontSize: "13px",
                        letterSpacing: "0px"
                      }}
                    >
                      {creditDetails.total_credits}
                    </Box>

                    <Box
                      component="div"
                      sx={{
                        pt: "5px",
                        color: "#282828",
                        fontFamily: "BukraRegular",
                        fontSize: { lg: "12px", xs: "11px" },
                        letterSpacing: "0px"
                      }}
                    >
                      {creditDetails.total_credits_label}
                    </Box>
                  </Box>
                </Tooltip>
              </Box>

              {fetchUserRoleType && fetchUserRoleType !== MD_USER_ROLE_TYPE && (
                <Box
                  sx={{
                    display: "flex",
                    pl: { sm: 4, xs: 3 }
                  }}
                >
                  <Divider orientation="vertical" flexItem />
                  <Tooltip
                    title={`${TOOLTIP_CREDIT_BLOCKED} ${blockedBy}.`}
                    placement="top"
                    arrow
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: { sm: 3, xs: 1 }
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          pt: 0.5,
                          fontFamily: "BukraMedium",
                          fontSize: "13px",
                          letterSpacing: "0px"
                        }}
                      >
                        {creditDetails.blockedCredits}
                      </Box>
                      <Box
                        component="div"
                        sx={{
                          pt: "5px",
                          color: "#282828",
                          fontFamily: "BukraRegular",
                          fontSize: { lg: "12px", xs: "11px" },
                          letterSpacing: "0px"
                        }}
                      >
                        {CREDITS_BLOCKED}
                      </Box>
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
};

export default memo(CreditSummaryCard);
