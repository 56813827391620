import React from "react";
import { Dialog, DialogContent, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import close from "./../../assets/images/close.png";
import { NOT_ELIGIBLE, REASON } from "../../constant/Constants";

/**
 * Renders the Confirmation modal dialog box.
 * @param {object} props - Props passed to the Confirmation Modal Dialog Box.
 * @returns {component} The confirmation modal Dialog box.
 */
const SubscriptionPackEligibilityPopup = (props) => {
  const {
    handleCloseShowEligibilitynModal,
    showEligibility,
    message,
    headerLabel
  } = props;

  /**
   * Handles the Confirmation Modal close operation.
   * @function handleClose - Handle the close operation of the Confirmation modal dialog box.
   */
  const handleClose = () => {
    handleCloseShowEligibilitynModal();
  };

  const renderReason = () => {
    return headerLabel === NOT_ELIGIBLE ? `${REASON}:` : "";
  };
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={showEligibility}
      sx={{ p: 1 }}
      data-testid="ConfirmationModalDialog"
      // onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pt: 2,
          pr: 3
        }}
      >
        <img
          src={close}
          data-testid="closeButton"
          alt="X"
          onClick={handleClose}
          height="15px"
          style={{
            cursor: "pointer"
          }}
        ></img>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "BukraMedium",
          letterSpacing: "0px",
          color: "#485061",
          fontSize: "16px"
        }}
      >
        {headerLabel}
      </Typography>
      <Divider sx={{ mt: 2 }}></Divider>
      <DialogContent sx={{ pb: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            minWidth: { lg: "400px" }
          }}
        >
          <Typography
            sx={{
              pl: { lg: 1 },
              pr: { lg: 1 },
              textAlign: "center",
              fontFamily: "BukraRegular",
              letterSpacing: "0px",
              color: "#797f8b",
              fontSize: "16px"
            }}
          >
            {`${renderReason()} ${message}`}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionPackEligibilityPopup;
