import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Alert, Button, FormHelperText, Input, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {
  CHANGE_YOUR_PASSWORD,
  CHANGE_PASSWORD_HINT,
  CONTINUE_TO_DASHBOARD,
  PASSWORD_CHANGE_SUCCESSFUL,
  CONFIRM_PASSWORD_MISMATCH,
  TOAST_SUCCESS,
  SAME_PASSWORD_HINT
} from "./../../constant/Constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import leftarrow from "./../../assets/images/arrow_back_black.svg";
import eyeClose from "./../../assets/images/eye_close.svg";
import setting from "./../../assets/images/setting.svg";
import eyeOpen from "./../../assets/images/eye_open.svg";
import toastErrorIcon from "./../../assets/images/toast_error_small.png";
import {
  regexExpression,
  removeWhiteSpaceInString
} from "../../constant/Utils";
import { useDispatch, useSelector } from "react-redux";
import { updateChangePassword } from "../../services/user.services";
import {
  getChangePasswordStatus,
  getChannelPartnerCode
} from "../../stateManagement/reducers/LoginSlice";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";

/**
 * Renders the Change Password screen UI.
 * @returns {component} the Change Password screen UI component.
 */
const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  const changePasswordStatus = useSelector((state) =>
    getChangePasswordStatus(state)
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            background:
              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0%no-repeat padding-box",
            color: "#ffffff",
            "&.Mui-disabled": {
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              opacity: 0.5
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDF4F6",
            color: "#0F0F10",
            border: "1px solid #E8E8E8",
            borderRadius: "3px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "13px",
            fontFamily: "BukraRegular",
            "&.Mui-focused": {
              border: "1px solid #0F57CB"
            },
            "& ::placeholder": {
              opacity: 0.8,
              color: "#5B636F"
            }
          }
        }
      }
    }
  });

  /**
   * Initial Password Input fields.
   * @property {object} initialValues
   */
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  /**
   * Handles the Change Password form submission after filling the required fields.
   * @function handleSubmit - Handles the Change password form submission.
   * @param {object} e - pointer event.
   */
  const handleSubmit = (e) => {
    const { currentPassword, confirmPassword } = formValues;
    e.preventDefault();
    const checkedNewPasswordStatus = checkPassword(formValues.newPassword);
    const checkedconfirmPasswordStatus = checkPassword(
      formValues.confirmPassword
    );
    if (!(checkedNewPasswordStatus && checkedconfirmPasswordStatus)) {
      setOpen(true);
      setShowErrorMsg(CHANGE_PASSWORD_HINT);
    } else if (checkedNewPasswordStatus && checkedconfirmPasswordStatus) {
      let userPasswordDetails = {
        currentPassword: currentPassword.trim(),
        newPassword: confirmPassword.trim(),
        channelPartnerCode: channelPartnerCode
      };

      dispatch(updateChangePassword(userPasswordDetails))
        .unwrap()
        .then((res) => {
          if (res && res.status === 200) {
            let toastDetails = {
              toastTitle: PASSWORD_CHANGE_SUCCESSFUL,
              isToastOpen: true,
              toastStatus: TOAST_SUCCESS
            };
            dispatch(updateToastMessage(toastDetails));
            navigate("/dashboard");
          } else {
            setOpen(true);
            setShowErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          setOpen(true);
          setShowErrorMsg(error);
        });
    }
  };

  /**
   * @function handleChange - Handles the Input value change operation for the Change password form Inputs.
   * @param {object} e - Pointer event.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = removeWhiteSpaceInString(value);

    setFormValues({ ...formValues, [name]: trimmedValue });
  };

  /**
   * @function checkPassword Checks whether Password entered matches the particular format.
   * @param {string} str - Password string.
   * @returns {boolean} true or false based on the Regex match.
   */
  function checkPassword(str) {
    let regex = regexExpression();
    return regex.test(str);
  }

  /**
   * Checks whether to Enable or disable the Change password button based on the Criteria.
   * @function isButtonDisabled - Handles the Operation to Enable or disable the Change Password button.
   * @returns {boolean} true or false to disable or Enable the Change Password Button.
   */
  const isButtonDisabled = () => {
    let value = true;
    if (
      formValues.currentPassword &&
      formValues.confirmPassword &&
      formValues.newPassword &&
      formValues.currentPassword !== formValues.newPassword &&
      formValues.confirmPassword === formValues.newPassword
    ) {
      value = false;
    }

    return value;
  };

  const renderSamepasswordErrorMSG = () => {
    let value = false;
    if (
      formValues.currentPassword &&
      formValues.newPassword &&
      formValues.currentPassword === formValues.newPassword
    ) {
      value = true;
    }

    return value;
  };
  const renderShowCurrentPasswordErrorMsg = () => {
    let value = false;
    if (
      formValues.confirmPassword &&
      formValues.newPassword &&
      formValues.confirmPassword !== formValues.newPassword
    ) {
      value = true;
    }

    return value;
  };

  /**
   * Handle the Closing of Snackbar component.
   * @function handleClose - Handle the close opertion on Snackbar component in Change Password UI.
   */
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Paper
        data-testid="changePassword"
        elevation={2}
        sx={{
          p: 2.1,
          pb: 0,
          maxWidth: { xs: "88%", sm: "390px" }
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            {!changePasswordStatus && (
              <img
                src={leftarrow}
                alt="left arrow"
                style={{
                  height: "20px",
                  width: "20px",
                  cursor: "pointer"
                }}
                onClick={() => navigate(-1)}
                data-testid="leftArrow"
              ></img>
            )}
            <Box sx={{ margin: "0 auto" }}>
              <Snackbar
                open={open}
                onClose={handleClose}
                data-testid="closeButton"
                key={"top"}
                autoHideDuration={3000}
                sx={{
                  "&.MuiSnackbar-anchorOriginBottomLeft ": {
                    top: "0px",
                    position: "relative",
                    left: "0px"
                  }
                }}
              >
                <Alert
                  severity="error"
                  icon={
                    <img src={toastErrorIcon} alt="error" height="15px"></img>
                  }
                  sx={{
                    width: "300px",
                    color: "#FF2A2A",
                    fontSize: "12px",
                    border: "1px solid #FF2A2A",
                    background: "#FDE2E2 0% 0% no-repeat padding-box",
                    fontFamily: "BukraMedium",
                    letterSpacing: "0.3px",
                    pt: 0,
                    pb: 0,
                    ".MuiAlert-icon,.MuiAlert-action": {
                      alignItems: "center",
                      pt: 0,
                      pb: 0,
                      pr: 0.8,
                      mr: 0
                    }
                  }}
                >
                  {showErrorMsg}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
          <Box
            sx={{
              p: 3,
              pt: open ? 0 : 3
            }}
          >
            <Typography
              sx={{
                fontFamily: "BukraMedium",
                textAlign: "center",
                fontSize: "16px",
                color: "#464e5f",
                fontWeight: "600"
              }}
            >
              {CHANGE_YOUR_PASSWORD}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "600",
                mt: 1,
                fontFamily: "BukraRegular",
                letterSpacing: "0.02px",
                color: "#62656b"
              }}
            >
              {CHANGE_PASSWORD_HINT}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                pt: 2,
                pl: 2.9,
                pr: 2.9
              }}
            >
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                id="currentPassword"
                fullWidth
                required
                value={formValues.currentPassword}
                name="currentPassword"
                type={showCurrentPassword ? "text" : "password"}
                placeholder={
                  isMobile
                    ? "Enter Current Password"
                    : "Enter Your Current Password"
                }
                disableUnderline={true}
                sx={{
                  mt: 3,
                  color: "#0F0F10"
                }}
                startAdornment={
                  <InputAdornment position="start" sx={{ p: 1 }}>
                    <img src={setting} alt="s"></img>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" sx={{ p: 1, ml: "4px" }}>
                    <IconButton
                      tabIndex={-1}
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      data-testid="showCurrentPwdIcon"
                    >
                      {showCurrentPassword ? (
                        <img src={eyeOpen} alt="e"></img>
                      ) : (
                        <img src={eyeClose} alt="e"></img>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => handleChange(e)}
                inputProps={{ "data-testid": "currentPassword" }}
              />
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                id="newPassword"
                fullWidth
                required
                value={formValues.newPassword}
                name="newPassword"
                type={showPassword ? "text" : "password"}
                placeholder={
                  isMobile ? "Enter New Password" : "Enter Your New Password"
                }
                disableUnderline={true}
                sx={{
                  mt: 3,
                  color: "#0F0F10"
                }}
                startAdornment={
                  <InputAdornment position="start" sx={{ p: 1 }}>
                    <img src={setting} alt="s"></img>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" sx={{ p: 1 }}>
                    <IconButton
                      tabIndex={-1}
                      onClick={() => setShowPassword(!showPassword)}
                      data-testid="showNewPwdIcon"
                    >
                      {showPassword ? (
                        <img src={eyeOpen} alt="e"></img>
                      ) : (
                        <img src={eyeClose} alt="e"></img>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => handleChange(e)}
                inputProps={{ "data-testid": "newPassword" }}
              />
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                id="confirmPassword"
                fullWidth
                required
                name="confirmPassword"
                value={formValues.confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                placeholder={
                  isMobile
                    ? "Confirm New Password"
                    : "Confirm Your New Password"
                }
                disableUnderline={true}
                sx={{
                  mt: 3,
                  color: "#0F0F10",
                  borderColor: renderShowCurrentPasswordErrorMsg()
                    ? "red"
                    : "#e8e8e8"
                }}
                startAdornment={
                  <InputAdornment position="start" sx={{ p: 1 }}>
                    <img src={setting} alt="s"></img>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" sx={{ p: 1, ml: "4px" }}>
                    <IconButton
                      tabIndex={-1}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      data-testid="showConfirmPwdIcon"
                    >
                      {showConfirmPassword ? (
                        <img src={eyeOpen} alt="e"></img>
                      ) : (
                        <img src={eyeClose} alt="e"></img>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => handleChange(e)}
                inputProps={{ "data-testid": "confirmPassword" }}
              />
              {renderShowCurrentPasswordErrorMsg() && (
                <FormHelperText
                  sx={{ color: "#DE0909", textAlign: "left" }}
                  className="errorFormHelperText"
                >
                  {CONFIRM_PASSWORD_MISMATCH}
                </FormHelperText>
              )}
              {renderSamepasswordErrorMSG() && (
                <FormHelperText
                  sx={{ color: "#DE0909", textAlign: "left" }}
                  className="errorFormHelperText"
                >
                  {SAME_PASSWORD_HINT}
                </FormHelperText>
              )}
              <Button
                type="submit"
                variant="text"
                sx={{
                  mt: 4,
                  mb: 2,
                  borderRadius: "20px",
                  pl: 1,
                  pr: 1,
                  width: { md: "240px", xs: "100%" },
                  height: "40px",
                  ml: "auto",
                  mr: "auto"
                }}
                disabled={isButtonDisabled()}
                data-testid="submitButton"
              >
                <Typography
                  sx={{
                    fontSize: { md: "15px", sm: "13px", xs: "12px" },
                    textTransform: "none",
                    fontFamily: "BukraMedium"
                  }}
                >
                  {CONTINUE_TO_DASHBOARD}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default memo(ChangePassword);
