import React, { memo } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Zoom
} from "@mui/material";
import close from "./../../assets/images/close.png";
import IconPopup from "./../../assets/images/Icon_pop_up_notification.svg";
const TransitionEffect = React.forwardRef(function Transition(props, ref) {
  return (
    <Zoom
      style={{ transitionDelay: "400ms" }}
      ref={ref}
      {...props}
      easing={{
        enter: "cubic-bezier(0, 0.5, .5, 1)",
        exit: "linear"
      }}
    />
  );
});
const PromotionsPopup = (props) => {
  const {
    handleClosePromotionsPopup,
    showModal,
    messageDetails,
    showAlertImg
  } = props;

  /**
   * Handles the Confirmation Modal close operation.
   * @function handleClose - Handle the close operation of the Confirmation modal dialog box.
   */
  const handleClose = (data) => {
    handleClosePromotionsPopup(data);
  };
  const { messageTitle, message, anchorUrl } = messageDetails;
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={showModal}
      data-testid="notificationDialog"
      TransitionComponent={TransitionEffect}
      keepMounted
      sx={{
        ".MuiPaper-root": {
          width: { xs: "90%", lg: "fit-content" },
          maxWidth: { xs: "92%", lg: "fit-content" },
          minWidth: { lg: "600px" },
          m: 0
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
          pb: 0
        }}
      >
        <img
          src={close}
          data-testid="closeButton"
          alt="X"
          onClick={() => handleClose(messageDetails)}
          height="15px"
          width="15px"
          style={{
            opacity: 0.8,
            cursor: "pointer"
          }}
        ></img>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {showAlertImg && (
          <img
            src={IconPopup}
            alt="X"
            height="28px"
            width="28px"
            style={{
              paddingRight: "10px"
            }}
          ></img>
        )}
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "BukraMedium",
            letterSpacing: "0px",
            color: "#464E5F",
            fontSize: { lg: "20px", xs: "16px" }
          }}
        >
          {messageTitle}
        </Typography>
      </Box>
      <Divider sx={{ mt: 3 }} style={{ borderBottomWidth: "unset" }}></Divider>
      <DialogContent sx={{ px: 5, pb: 3, maxWidth: { lg: "800px" } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "left",
            flexDirection: "column",
            maxHeight: "300px",
            overflowY: "auto",
            pb: 0,
            "&::-webkit-scrollbar": {
              width: { xs: 0, lg: "5px" }
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: { xs: "unset", lg: "#b1b1b1" }
            },
            scrollbarWidth: "none"
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "BukraRegular",
              letterSpacing: "0px",
              color: "#282828",
              fontSize: { lg: "16px", xs: "14px" },
              whiteSpace: "pre-line"
            }}
          >
            {message}
          </Typography>
          {anchorUrl && (
            <Link
              to={anchorUrl}
              target="_blank"
              style={{ textDecorationColor: "#1155CC" }}
            >
              {" "}
              <Typography
                sx={{
                  textAlign: "left",
                  fontFamily: "BukraRegular",
                  letterSpacing: "0px",
                  color: "#1155CC",
                  fontSize: { lg: "16px", xs: "14px" },
                  mt: 2
                }}
              >
                {anchorUrl}
              </Typography>
            </Link>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default memo(PromotionsPopup);
