import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  multipleMessages: {
    creditSuccessResponseList: [],
    creditFailureResponseList: []
  }
};

/**
 * Saves the Error and Succes Message on MULTI_STATUS error into the Store.
 */
const MulitpleTransferSlice = createSlice({
  name: "multipleTransfer",
  initialState,
  reducers: {
    setMultipleTransferMsg: (state, action) => {
      state.multipleMessages = action.payload;
    }
  }
});

/**
 * Fetches the Error and Success messages from the store.
 * @param {{multipleTransfer:object}} multipleTransfer  - State Object
 * @returns {object} - Multiple Transfer status messages
 */
export const getMultipleTransferMessages = ({ multipleTransfer }) => {
  const message = multipleTransfer ? multipleTransfer.multipleMessages : null;
  return message;
};

export const { setMultipleTransferMsg } = MulitpleTransferSlice.actions;
export default MulitpleTransferSlice.reducer;
