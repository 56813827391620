import React, { memo, useEffect } from "react";
import RecentSubscriptionSalesTable from "../../components/recentSubscriptionSalesTable/RecentSubscriptionSalesTable";
import Stack from "@mui/material/Stack";
import WrapperComponent from "../dashBoard/WrapperComponent";
import QuickLinks from "../../components/quickLinks/QuickLinks";
import { useSelector } from "react-redux";
import {
  getUserRoleType,
  getChannelPartnerCode
} from "../../stateManagement/reducers/LoginSlice";
import { quickLinkCardWidgets } from "../../constant/ScopeMenus";
import { useLocation } from "react-router-dom";
import { updatePageVisit } from "../../constant/Utils";

/**
 * Renders the Subscription page UI.
 * @returns {component} Subscription Page UI component.
 */
const Subscription = () => {
  const location = useLocation();

  /**
   * @constant {string} channelPartnerCode - Gets the Channel partner code from store.
   */
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * @constant {number} fetchUserRoleType - Gets the User role type from store.
   */
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));

  /**
   * @constant {object} getQuickLinksCardsByRoleType - Gets the Object to display the cards in the Quicklinks.
   */
  const getQuickLinksCardsByRoleType = quickLinkCardWidgets.find(
    (item) => item.roleType === fetchUserRoleType
  );

  /**
   * @constant {boolean} - true or false to show/hide the Credit Balance card
   */
  const showCreditCard =
    getQuickLinksCardsByRoleType && getQuickLinksCardsByRoleType.quickLinkCard
      ? getQuickLinksCardsByRoleType.quickLinkCard.showCreditCard
      : false;

  /**
   * @constant {boolean} - true or false to show/hide the Revenue card
   */
  const showRevenueCard =
    getQuickLinksCardsByRoleType && getQuickLinksCardsByRoleType.quickLinkCard
      ? getQuickLinksCardsByRoleType.quickLinkCard.showRevenueCard
      : false;

  /**
   * @constant {boolean} - true or false to show/hide the Inventory card
   */
  const showInventoryCard =
    getQuickLinksCardsByRoleType && getQuickLinksCardsByRoleType.quickLinkCard
      ? getQuickLinksCardsByRoleType.quickLinkCard.showInventoryCard
      : false;

  /**
   * Updates the page visits info.
   */
  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * @constant {array<string>} childPath - URL Path.
   */
  const childPath = ["/subscription"];

  return (
    <div>
      <WrapperComponent isProfile={false}>
        <Stack data-testid="subscriptionPage" spacing={2}>
          <QuickLinks
            childPath={childPath}
            showCards={true}
            showFilter={true}
            showRevenueCard={showRevenueCard}
            showCreditCard={showCreditCard}
            showInventoryCard={showInventoryCard}
          ></QuickLinks>
          <RecentSubscriptionSalesTable></RecentSubscriptionSalesTable>
        </Stack>
      </WrapperComponent>
    </div>
  );
};

export default memo(Subscription);
