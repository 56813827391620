import React, { useState, useEffect, useCallback } from "react";
import ProfileCard from "../profileCard/ProfileCard";
import subDistributorProfile from "./../../assets/images/sub_distributor_profile.svg";
import { PROFILE, RETAILER, NO_DATA } from "../../constant/Constants";
import { Box, Typography } from "@mui/material";
import { getChildDetails } from "../../services/user.services";
import { useParams } from "react-router-dom";
import { textWithComma } from "../../constant/Utils";
import { useSelector } from "react-redux";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";

/**
 * Renders the Retailer's Profile card UI component.
 * @returns {component} the Retailer's Profile card UI component.
 */
const RetailerViewProfile = () => {
  const [retailerViewDetails, setRetailerViewDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [showNoData, setShowNoData] = useState(false);
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Preloads the retailer details based on the retailer's id selected throuh params
   */

  const getChildDetailsApi = useCallback(() => {
    if (params.id) {
      setLoading(true);
      getChildDetails(config.server, params.id)
        .then((res) => {
          if (res && res.status === 200) {
            setRetailerViewDetails(res.data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.id, config.server]);

  useEffect(() => {
    getChildDetailsApi();
  }, [getChildDetailsApi]);

  const handleCallBackUpdateUserDetails = () => {
    getChildDetailsApi();
  };
  /**
   * Extracts the required data from the retailer's details object.
   */
  const {
    channelPartnerMobileNumber,
    channelPartnerEmail,
    channelPartnerAddressInformation,
    channelPartnerCity,
    channelPartnerCode,
    channelPartnerFirstName,
    channelPartnerCountry,
    channelPartnerArea,
    creditBalance,
    postalCode,
    status,
    approvalStatus
  } = retailerViewDetails;
  const name = `${channelPartnerFirstName}`;
  const address = `${textWithComma(
    channelPartnerAddressInformation
  )}${textWithComma(channelPartnerArea)} ${textWithComma(
    channelPartnerCity
  )}${textWithComma(postalCode)}${channelPartnerCountry}`;

  /**
   * @property {object} profileDetails - Creates the formatted data for display in UI purpose.
   */
  let profileDetails = {
    profileTittle: `${RETAILER} ${PROFILE}`,
    profileName: `${RETAILER}: ${channelPartnerCode} [${name}]`,
    profileNumber: channelPartnerMobileNumber,
    profileEmail: channelPartnerEmail,
    profileAddress: address,
    image: subDistributorProfile,
    isChildProfile: true,
    creditBalance: creditBalance,
    userId: 1,
    routeToEditProfile: "/retailers/editprofile",
    profileStatus: RETAILER,
    channelPartnerCode: channelPartnerCode,
    status: status,
    approvalStatus: approvalStatus,
    handleCallBackUpdateUserDetails: handleCallBackUpdateUserDetails
  };
  return (
    <Box data-testid="retailerViewProfile">
      {!loading && showNoData && (
        <Typography data-testid="noData">{NO_DATA}</Typography>
      )}
      {loading && <BodyLoader />}
      {!loading && retailerViewDetails && (
        <ProfileCard profileDetails={profileDetails}></ProfileCard>
      )}
    </Box>
  );
};

export default RetailerViewProfile;
