import { memo, useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import eyeClose from "./../../assets/images/eye_close.svg";
import eyeOpen from "./../../assets/images/eye_open.svg";
import setting from "./../../assets/images/setting.svg";
import {
  FORGOT_YOUR_PASSWORD,
  ENTER_PASSWORD,
  ENTER_YOUR_REGISTERED_EMAIL,
  ATTEMPTS_REMAINING,
  MULTI_STATUS,
  REMEMBER_ME,
  LOGIN,
  I_AGREE_TO_THE,
  TERMS_AND_CONDITIONS,
  OF_THE_CHANNEL_PARTNER_AGREEMENT,
  PASSWORD_LEFT_BLANK_ERR_LABEL
} from "../../constant/Constants";
import InputAdornment from "@mui/material/InputAdornment";
import emailicon from "../../assets/images/email_Icon.svg";
import {
  Checkbox,
  IconButton,
  Input,
  Typography,
  FormHelperText
} from "@mui/material";
import CustomSnackbar from "../../components/customComponents/customSnackbar/CustomSnackbar";
import {
  decryptData,
  encryptData,
  setIntialLogHistory,
  validateEmail
} from "../../constant/Utils";
import userService from "./../../services/user.services";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background:
            "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
          color: "#ffffff",
          "&.Mui-disabled": {
            background:
              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
            color: "#ffffff",
            opacity: 0.5
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
          paddingTop: "0px"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#EDF4F6",
          color: "#0F0F10",
          border: "1px solid #E8E8E8",
          borderRadius: "3px",
          paddingTop: "3px",
          paddingBottom: "3px",
          fontSize: "13px",
          fontFamily: "BukraRegular",
          letterSpacing: "0px",
          "&.Mui-focused": {
            border: "1px solid #0F57CB"
          },
          input: {
            "&::placeholder": {
              opacity: 1,
              color: "#7b838c"
            }
          }
        }
      }
    }
  }
});

/**
 * Renders the Login Page.
 * @returns {component} Login Page UI component
 */
const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const decrptedUserData = localStorage.getItem("userDetails");
  const userData = decrptedUserData ? decryptData(decrptedUserData) : "";
  const userEmail = userData && userData.username ? userData.username : "";
  const userPassword = userData && userData.password ? userData.password : "";
  const isrememberMeChecked = userEmail ? true : false;

  const [email, setEmail] = useState(userEmail);
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState(userPassword);
  const [passwordError, setPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [rememberMeChecked, setRememberMeChecked] =
    useState(isrememberMeChecked);
  const [showErrorPop, setShowErrorPop] = useState(false);
  const [isButtonEnable, setIsButtonEnable] = useState(true);
  const [userErrorInfo, setUserErrorInfo] = useState({});
  const [termsView, setTermsView] = useState(false);

  /**
   * Handles the Login Form Submission operation.
   * @function handleSubmit - Performs action on Login submit button click.
   * @param {object} event - Login Button event object.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsButtonEnable(false);
    let updatedUserData = {
      username: email.trim(),
      password
    };
    dispatch(userService.getLoginDetails(updatedUserData))
      .unwrap()
      .then((res) => {
        if (res && res.status && res.status === 200) {
          sessionStorage.setItem("showInitialModal", "true");
          if (res.data.channelPartnerCode) {
            setIntialLogHistory(res.data.channelPartnerCode, res.data.roleType);
          }
          if (res.data.changePassword) {
            navigate("/changepassword");
          } else {
            navigate("/dashboard");
          }
          if (rememberMeChecked) {
            const data = encryptData(updatedUserData);
            localStorage.setItem("userDetails", data);
          } else {
            localStorage.setItem("userDetails", "");
          }
        } else if (
          res.data &&
          res.data.status &&
          res.data.status === MULTI_STATUS
        ) {
          navigate("/accountlock", {
            state: {
              parentUserEmail: res.data.parentUserEmail,
              maxLoginRetryCountReached: res.data.maxLoginRetryCountReached
            }
          });
        } else {
          setUserErrorInfo(res.response.data);
          setShowErrorPop(true);
        }
        setIsButtonEnable(true);
      })
      .catch((error) => {
        const catchMessage =
          error && error.message ? error.message : "In valid data";
        setUserErrorInfo(catchMessage);
        setIsButtonEnable(true);
        setShowErrorPop(true);
      });
  };

  /**
   * Handles the Snackbar close operation.
   * @function showToastCallback - Performs the Snackbar close operation.
   */
  const showToastCallback = useCallback(() => {
    setShowErrorPop(false);
    setIsButtonEnable(true);
  }, []);

  /**
   * Handles the Email input validation.
   * @function handleEmailValidation - Performs action to validate on email input change.
   */
  const handleEmailValidation = () => {
    setEmailError(validateEmail(email, true));
  };

  /**
   * Handles the Password Validation
   * @function handlePasswordValidation - Performs action to Validate if password matches the particular criteria.
   */
  const handlePasswordValidation = () => {
    setPasswordError(validatePassword(password));
  };

  /**
   * Handles the Email input change Operation.
   * @function handleEmailChange - Performs action on Email input change.
   * @param {object} e - Email input event object.
   */
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError("");
  };

  /**
   * Handles the Password input change Operation.
   * @function handlePasswordChange - Performs action on Password input change.
   * @param {object} e - Password input event object.
   */
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value.trim());
    setPasswordError("");
  };

  /**
   * Handles the Terms and condition Checkbox change
   * @function handleCheckBox - Performs the Terms and Conditions Checkbox change actions.
   */
  const handleCheckBox = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  /**
   * Handles the Remember Me Checkbox change
   * @function handleRememberCheckBox - Performs the Remember Me Checkbox change actions.
   */
  const handleRememberCheckBox = () => {
    setRememberMeChecked(!rememberMeChecked);
  };

  /**
   * Handles the Password Validation
   * @function validatePassword - Performs the Validation operation on Password input.
   */
  const validatePassword = (values) => {
    let errors = "";
    if (values === "") {
      errors = PASSWORD_LEFT_BLANK_ERR_LABEL;
    }
    return errors;
  };

  /**
   * Handles the Login Button Status.
   * @function isFormSubmitButtonDisable - Performs action whether to Enable or disable the Login button.
   * @returns {boolean} true or false whether the disable or enable the Login button.
   */
  const isFormSubmitButtonDisable = () => {
    let valid =
      !checked ||
      emailError.length > 0 ||
      passwordError.length > 0 ||
      email === "" ||
      password === "" ||
      !isButtonEnable
        ? true
        : false;
    return valid;
  };

  /**
   * @constant {string} totalAttemptsRemain - String for Total Attempts remain for Login Attempt.
   */
  const totalAttemptsRemain =
    userErrorInfo &&
    userErrorInfo.loginAttemptRemaining &&
    userErrorInfo.loginAttemptRemaining >= 0
      ? `${userErrorInfo.loginAttemptRemaining} ${ATTEMPTS_REMAINING}`
      : "";

  /**
   * @constant {string} message - Error Message string for Snackbar.
   */
  const message = userErrorInfo ? userErrorInfo.message : "";

  /**
   * Handles the Display of Terms and Conditions Dialog Box.
   * @function openTerms - Performs action to display the Terms and condition Dialog Box
   * @param {object} e - Terms And Condition link event object
   */
  const openTerms = (e) => {
    e.preventDefault();
    setTermsView(true);
  };

  /**
   * Handles the close operation on the Terms and Conditions Dialog box.
   * @function handleClose - Closes the Terms and Conditions Dialog box.
   */
  const handleClose = useCallback(() => {
    setTermsView(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          pb: 0,
          maxWidth: { xs: "85%", sm: "351px" },
          pt: 1
        }}
        data-testid="loginWrapper"
      >
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: 0
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
              width: "100%"
            }}
          >
            <CustomSnackbar
              message={message}
              borderClr="#FF2A2A"
              bgClr="#FDE2E2 "
              toastTextclr="#FF2A2A"
              toastSubTittle={totalAttemptsRemain}
              showToast={showErrorPop}
              showToastCallback={showToastCallback}
            ></CustomSnackbar>
          </Box>
          <Box
            data-testid="formTest"
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              pl: 2.8,
              pr: 2.8,
              pt: 1
            }}
          >
            <Input
              autoComplete="off"
              inputProps={{ "data-testid": "emailTest" }}
              id="email"
              fullWidth
              name="email"
              type="email"
              onDrop={(e) => {
                e.preventDefault();
                return false;
              }}
              value={email}
              placeholder={ENTER_YOUR_REGISTERED_EMAIL}
              disableUnderline={true}
              sx={{
                color: "#0F0F10"
              }}
              startAdornment={
                <InputAdornment position="start" sx={{ p: 1 }}>
                  <img src={emailicon} alt="s"></img>
                </InputAdornment>
              }
              onBlur={() => handleEmailValidation()}
              onChange={(e) => handleEmailChange(e)}
              style={{
                border: `${emailError ? "1px solid #DE0909" : ""}`
              }}
            />
            {emailError && (
              <FormHelperText
                sx={{ color: "#DE0909" }}
                className="errorFormHelperText"
                data-testid="emailError"
              >
                {emailError}{" "}
              </FormHelperText>
            )}
            <Input
              autoComplete="off"
              id="password"
              fullWidth
              name="password"
              value={password}
              type={showPassword ? "text" : "password"}
              placeholder={ENTER_PASSWORD}
              disableUnderline={true}
              inputProps={{ "data-testid": "passwordTest" }}
              sx={{
                mt: 2,
                color: "#0F0F10"
              }}
              onDrop={(e) => {
                e.preventDefault();
                return false;
              }}
              startAdornment={
                <InputAdornment position="start" sx={{ p: 1 }}>
                  <img src={setting} alt="s"></img>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end" sx={{ p: 1, cursor: "pointer" }}>
                  <IconButton
                    data-testid="showPwdIcon"
                    tabIndex={-1}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <img src={eyeOpen} alt="e"></img>
                    ) : (
                      <img src={eyeClose} alt="e"></img>
                    )}
                  </IconButton>
                </InputAdornment>
              }
              style={{
                border: `${passwordError ? "1px solid #DE0909" : ""}`
              }}
              onChange={(e) => handlePasswordChange(e)}
              onBlur={() => handlePasswordValidation()}
            />
            {passwordError && (
              <FormHelperText
                sx={{ color: "#DE0909", textAlign: "left" }}
                className="errorFormHelperText"
                data-testid="passwordError"
              >
                {passwordError}{" "}
              </FormHelperText>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                mt: 6
              }}
            >
              <Checkbox
                checked={rememberMeChecked}
                data-testid="rememberMeChecked"
                onClick={() => handleRememberCheckBox()}
                sx={{
                  p:0,
                  mb:1,
                  mr:1,
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "#0f57cb"
                  }
                }}
              ></Checkbox>{" "}
              <Typography
                sx={{
                  fontSize: "11px",
                  fontFamily: "BukraRegular",
                  letterSpacing: "0px",
                  color: "#9e9e9e"
                }}
              >
                {REMEMBER_ME}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-start"
              }}
            >
              <Checkbox
                checked={checked}
                data-testid="termsChecked"
                onClick={() => handleCheckBox()}
                sx={{
                  p:0,
                  marginRight:1,
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "#0f57cb"
                  }
                }}
              ></Checkbox>{" "}
              <Typography
                sx={{
                  fontSize: "11px",
                  fontFamily: "BukraRegular",
                  letterSpacing: "0px",
                  pt: 0.6,
                  color: "#9e9e9e"
                }}
              >
                {I_AGREE_TO_THE}{" "}
                <Typography
                  data-testid="term"
                  component="span"
                  sx={{
                    fontSize: "11px",
                    fontFamily: "BukraRegular",
                    letterSpacing: "0px",
                    pt: 0.6,
                    color: "rgb(15, 87, 203)",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  onClick={(e) => openTerms(e)}
                >
                  {TERMS_AND_CONDITIONS}
                </Typography>{" "}
                {OF_THE_CHANNEL_PARTNER_AGREEMENT}
              </Typography>
            </Box>
            <Button
              type="submit"
              data-testid="submit"
              variant="text"
              disabled={isFormSubmitButtonDisable()}
              sx={{
                mt: 4,
                mb: 2,
                borderRadius: "20px",
                pl: 8,
                pr: 8,
                fontSize: "15px",
                textTransform: "none",
                fontFamily: "BukraMedium",
                width: "200px",
                height: "40px",
                ml: "auto",
                mr: "auto"
              }}
              // onClick={(e) => handleSubmit(e)}
            >
              {LOGIN}
            </Button>
          </Box>
          <Link
            to="/forgotpassword"
            style={{
              color: "#0f57cb",
              textAlign: "center",
              fontFamily: "BukraMedium",
              fontSize: "12px",
              textDecoration: "none",
              paddingBottom: "8px",
              cursor: "pointer"
            }}
          >
            {FORGOT_YOUR_PASSWORD}
          </Link>
        </Box>
        {termsView && (
          <TermsAndConditions
            handleClosePopup={handleClose}
            checked={checked}
            handleCheckBox={handleCheckBox}
          ></TermsAndConditions>
        )}
      </Paper>
    </ThemeProvider>
  );
};
export default memo(Login);
