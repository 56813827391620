import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newSubscriptionCartItems: [],
  selectedSTBID: "",
  purchaseType: ""
};

/**
 * Saves the selected Plan package information based on STB into the store
 */
const newSubscriptionCartSlice = createSlice({
  name: "newSubscriptionCart",
  initialState,
  reducers: {
    // updateSelectedSTBID(state, action) {
    //   state.selectedSTBID = action.payload;
    // },
    setPurchaseType(state, action) {
      state.purchaseType = action.payload;
    },
    // addNewSubscriptionItem(state, action) {
    //   const newItemSkuCode = action.payload.package.skuCode;
    //   const selectedSTBID = action.payload.stbID;
    //   const existingItem = state.newSubscriptionCartItems.find(
    //     (item) => item.skuCode === newItemSkuCode
    //   );
    //   if (!existingItem) {
    //     state.newSubscriptionCartItems.push(action.payload.package);
    //   }
    //   state.selectedSTBID = selectedSTBID;
    // },

    // removeNewSubscriptionItem(state, action) {
    //   state.newSubscriptionCartItems = state.newSubscriptionCartItems.filter(
    //     (item) => item.skuCode !== action.payload
    //   );
    // },
    // removeAllNewSubscriptionItems(state) {
    //   state.newSubscriptionCartItems = [];
    //   state.selectedSTBID = "";
    // }
  }
});

/**
 * Fetches the Plan Package
 * @param {object} state - Store state.
 * @returns {array} - Plan Package list
 */
// export const getSelectNewSubscriptionItems = (state) => {
//   return state.newSubscriptionCart.newSubscriptionCartItems;
// };

/**
 * Fetches the STB ID.
 * @param {object} state - Store state.
 * @returns {string} STB ID.
 */
// export const getSelectNewSTBID = (state) => {
//   return state.newSubscriptionCart.selectedSTBID;
// };

/**
 * Fetches the Plan Selection type - BUY_NOW or ADD_TO_CART.
 * @param {object} state - Store State.
 * @returns {string} plan package Selection type
 */
// export const getPurchaseType = (state) => {
//   return state.newSubscriptionCart.purchaseType;
// };
export const {
  setPurchaseType
} = newSubscriptionCartSlice.actions;
export default newSubscriptionCartSlice.reducer;
