import React, { memo, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import searchIcon from "./../../assets/images/search.svg";
import Typography from "@mui/material/Typography";
import RevenueCard from "../../components/revenueCard/RevenueCard";
import CustomerRegistrationIcon from "./../../assets/images/customer_registration.svg";
import { ToolTipTheme } from "../../constant/themes/ToolTipTheme";
import {
  downloadChildRevenueReport,
  getChildChannelPartnerRevenueList
} from "../../services/user.services";
import { getSearchItems } from "../../constant/Utils";
import { Select } from "@mui/material";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import DownloadReport from "../downloadReport/DownloadReport";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { NO_DATA } from "../../constant/Constants";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * @property {array} searchOption - Initialize the select Option for the Search in ChildChannelPartnerList.
 */
const searchOption = [
  {
    value: "channelPartnerEmail",
    label: "Email"
  },
  {
    value: "channelPartnerName",
    label: "Name"
  },
  {
    value: "channelPartnerMobileNumber",
    label: "Mobile Number"
  }
];
const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});

/**
 * Renders the List of Child Channel Partners.
 * @param {object} props - Prop elements passed to Child Channel Partner.
 * @returns {component} the UI component containing the list of Child channel Partners.
 */
const ChildChannelPartnerList = (props) => {
  const { tittle, isRetailer, routeToProfile } = props;
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );
  const [search, setSearch] = useState("");
  const [searchSelect, setSearchSelect] = useState("channelPartnerEmail");
  const [revenueCardDetails, setRevenueCardDetails] = useState([]);
  const [searchRevenueCardDetails, setSearchRevenueCardDetails] = useState([]);
  const { startDate, endDate } = selectedDateFilter;
  const [loader, setLoader] = useState(false);
  const config = useSelector((state) => getConfigDetails(state));

  useEffect(() => {
    if (startDate && endDate && config.server) {
      setLoader(true);
      getChildChannelPartnerRevenueList(config.server, startDate, endDate)
        .then((res) => {
          if (res && res.status === 200) {
            setRevenueCardDetails(res.data.childRevenueList);
            setSearchRevenueCardDetails(res.data.childRevenueList);
          } else {
            setRevenueCardDetails([]);
            setSearchRevenueCardDetails([]);
          }
        })
        .finally(() => setLoader(false));
    }
  }, [startDate, endDate, config.server]);

  /**
   * Handle the change on the search input element.
   * @function handleSelectChange - Handles the Select option value change operation.
   * @param {object} event
   */
  const handleSelectChange = (event) => {
    setSearchSelect(event.target.value);
  };

  /**
   * Set the Search Text on input in search input field.
   * @function handlerequestSearch - Handle the Search Input value.
   * @param {object} event
   */
  const handlerequestSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    let filteredRows = searchRevenueCardDetails;
    if (search) {
      filteredRows = getSearchItems(
        searchRevenueCardDetails,
        searchSelect,
        search
      );
    }
    setRevenueCardDetails(filteredRows);
  }, [searchRevenueCardDetails, searchSelect, search]);

  return (
    <Paper data-testid="listofRetailers">
      <Toolbar
        sx={{
          pl: { sm: 2, xs: 1 },
          pr: { xs: 1, sm: 1 },
          display: "flex",
          alignItems: { xs: "flex-start", md: "center" },
          flexDirection: { xs: "column", md: "row" },
          // alignItems: "stretch",
          justifyContent: "space-between",
          minHeight: { sm: "84px" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
            width: { xs: "100%", md: "auto" }
          }}
        >
          <Typography
            variant="h6"
            id="tableTitle"
            sx={{
              fontFamily: "BukraMedium",
              fontSize: "16px",
              letterSpacing: "0px",
              color: "#464E5F",
              pl: 1,
              mr: 2,
              my: { xs: 1, lg: 0 }
            }}
          >
            {tittle}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "12px",
              backgroundColor: "#f3f6f9",
              height: "40px",
              mb: { xs: 1, lg: 0 },
              width: { xs: "100%", md: "auto" }
            }}
          >
            <img
              src={searchIcon}
              alt="search"
              style={{
                height: "11px",
                paddingLeft: "20px"
              }}
            ></img>
            <ThemeProvider theme={inputTheme}>
              <InputBase
                autoComplete="off"
                inputProps={{ "data-testid": "requestSearch" }}
                sx={{ ml: 1, flex: 1, width: { md: "160px", xs: "100%" } }}
                placeholder="Search"
                onChange={(event) => handlerequestSearch(event)}
              />
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Select
                disableUnderline={true}
                id="filledoption"
                inputProps={{
                  "data-testid": "selectSearchInner"
                }}
                sx={{
                  backgroundColor: "#7B7D801A",
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                  fontSize: "13px",
                  color: "#282828",
                  pl: 2,
                  pr: 2,
                  fontFamily: "BukraRegular",
                  height: "40px",
                  ".MuiSelect-icon": {
                    pr: 1
                  }
                }}
                value={searchSelect}
                onChange={handleSelectChange}
                variant="standard"
              >
                {searchOption.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ ...muiMenuItemStyles }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            my: { xs: 1, lg: 0 },
            pr: { sm: 2.5 },
            width: { xs: "95%", md: "auto" }
          }}
        >
          {isRetailer && (
            <ThemeProvider theme={ToolTipTheme}>
              <Tooltip title="Add Retailer">
                <Box
                  className="tooltip_mweb"
                  sx={{
                    pr: { xs: "0px", sm: "12px" }
                  }}
                >
                  <RouterLink
                    to="retaileronboarding"
                    state={{ label: "Add Retailer" }}
                  >
                    <img
                      src={CustomerRegistrationIcon}
                      alt="Add Retailer"
                      className="image_subscribe imgHover"
                      width="36px"
                      height="36px"
                    ></img>
                  </RouterLink>
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        lg: "none",
                        fontSize: "11px",
                        paddingTop: "5px",
                        color: "#282828",
                        fontFamily: "BukraRegular",
                        width: "max-content"
                      }
                    }}
                  >
                    Add Retailer
                  </Box>
                </Box>
              </Tooltip>
            </ThemeProvider>
          )}
          <DownloadReport
            reportType={downloadChildRevenueReport}
            status={""}
            fileName={tittle}
          ></DownloadReport>
        </Box>
      </Toolbar>
      <Divider></Divider>
      <Box sx={{ px: 3, py: 2 }}>
        <RevenueCard
          revenueCardDetails={revenueCardDetails}
          viewChild={true}
          routeToProfile={routeToProfile}
        ></RevenueCard>
        {!loader && revenueCardDetails.length === 0 && (
          <Typography
            textAlign={"center"}
            sx={{
              mt: 3,
              pb: 3
            }}
          >
            {NO_DATA}
          </Typography>
        )}

        {loader && <BodyLoader />}
      </Box>
    </Paper>
  );
};

export default memo(ChildChannelPartnerList);
