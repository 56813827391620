import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { NavLink, useLocation } from "react-router-dom";
import mbcLogo from "./../../assets/images/mbcnewlogo.svg";
import { getStartDateAndEndDate, sideBarImage } from "./../../constant/Utils";
import { sideNavBarDetails } from "./../../constant/ScopeMenus";
import {
  REACT_APP_B2B_BUILD_VERSION,
  SIDE_NAVBAR_WIDTH,
  TOOLTIP_CREDITS,
  TOOLTIP_CUSTOMER,
  TOOLTIP_DASHBOARD,
  TOOLTIP_HARDWARE,
  TOOLTIP_RETAILER,
  TOOLTIP_STB,
  TOOLTIP_SUBDISTRIBUTOR,
  TOOLTIP_SUBSCRIPTION,
  TOOLTIP_TRACK_STB
} from "../../constant/Constants";
import { useSelector,useDispatch } from "react-redux";
import { getUserScope } from "../../stateManagement/reducers/LoginSlice";
import { ThemeProvider, Tooltip, Typography } from "@mui/material";
import "./NavSideBar.scss";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import { getFilteredDate } from "../../stateManagement/reducers/DateFilterSlice";

/**
 * Renders the Navigation Siderbar Component
 * @param {object} props - Passes Prop elements to the Sidebar Navigation menu for the view and perform action.
 * @returns the Navigations Sidebar component based on the logged in user type.
 */
const NavSideBar = (props) => {
  const { mobileOpen, handleDrawerToggle } = props;
  const [pathname, setPathname] = useState("/");
  const [sideNavBar, setSideNavBar] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch()
  const fetchedUserRoleType = useSelector((state) => getUserScope(state));

  /**
   * useEffect hook to preload the Sidebar content based on the SCOPE values.
   * SCOPE VALUES - accessible menulinks based on the user type.
   */
  useEffect(() => {
    const updateSideBar = sideNavBarDetails.filter((element) =>
      fetchedUserRoleType.includes(element.scope)
    );

    setSideNavBar(updateSideBar);
    setPathname(location.pathname);
  }, [location, fetchedUserRoleType]);

  /**
   * Handles the Operation of drawer toggle on smaller screens
   * @function openSideNavbarMenu - Performs action to toggle the Sidebar Menu drawer to show and hide on smaller screens.
   */
  const openSideNavbarMenu = () => {
    handleDrawerToggle();
  };
  
  const defaultFilterTime =()=>{
    dispatch(getFilteredDate(getStartDateAndEndDate()));
  }
  /**
   * Performs operation to change the image on active link.
   * @function sideNavBarImages - Performs action to dispay the Image UI for the Sidebar menu items.
   * @param {number} id  - id of the Sidebar menu items
   * @returns {JSX} the UI for the Image of  active Navigation menu link
   */
  const sideNavBarImages = (id) => {
    const imageState = sideBarImage.find((element) => element.id === id);
    const finalImage = pathname.includes(id) ? (
      <img
        src={imageState.activeImage}
        alt="active"
        height={imageState.height}
        width={imageState.width}
      ></img>
    ) : (
      <img
        src={imageState.defaultImage}
        alt="default"
        height={imageState.height}
        width={imageState.width}
      ></img>
    );
    return finalImage;
  };

  /**
   * Renders the Sidebar Menu links.
   * @function renderSideBarDetails - Performs action to render the Sidebar Menu links.
   */
  const renderSideBarDetails = (
    <div data-testid="navSideBarPage" className="listWrapper">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#180A40"
        }}
      >
        <img src={mbcLogo} alt="logo" height="51px" width="90px"></img>
      </Toolbar>
      <Divider style={{ background: "#FFFFFF26", width: "inherit" }} />
      <List
        sx={{
          [`& .${listItemClasses.root}:hover`]: {
            backgroundColor: "#383a67"
          },
          pt: 0
        }}
      >
        {sideNavBar.map((data, index) => {
          let route = "#";
          let tooltipMsg = "";
          switch (data.unique_id) {
            case "credits_1":
              route = "credits";
              tooltipMsg = TOOLTIP_CREDITS;
              break;
            case "stb":
              route = "stb";
              tooltipMsg = TOOLTIP_STB;
              break;
            case "sub_distributor_1":
              route = "subdistributor";
              tooltipMsg = TOOLTIP_SUBDISTRIBUTOR;
              break;
            case "subscription_sales_1":
              route = "subscription";
              tooltipMsg = TOOLTIP_SUBSCRIPTION;
              break;
            case "hardware_1":
              route = "hardware";
              tooltipMsg = TOOLTIP_HARDWARE;
              break;
            case "customer_1":
              route = "customer";
              tooltipMsg = TOOLTIP_CUSTOMER;
              break;
            case "retailers_1":
              route = "retailers";
              tooltipMsg = TOOLTIP_RETAILER;
              break;
            case "dashboard_1":
              route = "dashboard";
              tooltipMsg = TOOLTIP_DASHBOARD;
              break;
            case "trackstb":
              route = "trackstb";
              tooltipMsg = TOOLTIP_TRACK_STB;
              break;
            default:
              break;
          }

          return (
            <ListItem
              disablePadding
              key={data.unique_id}
              sx={{
                display: "flex",
                flexDirection: "column",
                fontFamily: "BukraRegular",
                width: "99%"
              }}
            >
              <ThemeProvider theme={ToolTipThemeWithBG}>
                <Tooltip
                  title={tooltipMsg}
                  placement="left"
                  arrow
                  // open={true}
                >
                  <NavLink
                    to={route}
                    // onClick={()=>}
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      width: "inherit",
                      background: isActive
                        ? "#383a67 0% 0% no-repeat padding-box"
                        : "inherit",
                      color: "#FFFFFF"
                    })}
                  >
                    <ListItemButton
                    onClick={()=>{
                      defaultFilterTime()
                    }}
                      sx={{
                        backgroundColor: "inherit",
                        minWidth: "30px",
                        "&:hover": {
                          transition: "0.15s ease-in-out",
                          transform: "scale(1.033,1.1)"
                          // border:"1px solid red"
                        }
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        {sideNavBarImages(route)}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontFamily: "BukraRegular",
                          fontSize: "13px"
                        }}
                      >
                        {data.label}
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </Tooltip>
              </ThemeProvider>
              <Divider style={{ background: "#FFFFFF26", width: "inherit" }} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  /**
   * Renders the Sidebar Content for the Mobile View.
   * @function renderMobileSideBarDetails - Performs action to  render the Sidebar Menu Link items in the Movbile View.
   */
  const renderMobileSideBarDetails = (
    <div data-testid="navSideBarPage" className="listWrapper">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#180A40",
          alignItems: "center"
        }}
      >
        <img src={mbcLogo} alt="logo" height="51px" width="90px"></img>{" "}
        <Box sx={{ fontSize: "14px" }} onClick={() => openSideNavbarMenu()}>
          x
        </Box>
      </Toolbar>
      <Divider style={{ background: "#FFFFFF26", width: "inherit" }} />
      <List
        data-testid="listClick"
        onClick={() => openSideNavbarMenu()}
        sx={{
          [`& .${listItemClasses.root}:hover`]: {
            backgroundColor: "#383a67"
          },
          pt: 0
        }}
      >
        {sideNavBar.map((data, index) => {
          let route = "#";
          switch (data.unique_id) {
            case "credits_1":
              route = "credits";
              break;
            case "stb":
              route = "stb";
              break;
            case "sub_distributor_1":
              route = "subdistributor";
              break;
            case "subscription_sales_1":
              route = "subscription";
              break;
            case "hardware_1":
              route = "hardware";
              break;
            case "customer_1":
              route = "customer";
              break;
            case "retailers_1":
              route = "retailers";
              break;
            case "dashboard_1":
              route = "dashboard";
              break;
            case "trackstb":
              route = "trackstb";
              break;
            default:
              break;
          }

          return (
            <ListItem
              disablePadding
              key={data.unique_id}
              sx={{
                display: "flex",
                flexDirection: "column",
                fontFamily: "BukraRegular",
                width: "99%"
              }}
            >
              <NavLink
                to={route}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  width: "inherit",
                  background: isActive
                    ? "#383a67 0% 0% no-repeat padding-box"
                    : "inherit",
                  color: "#FFFFFF"
                })}
              >
                <ListItemButton
                 onClick={()=>{
                  defaultFilterTime()
                }}
                  sx={{
                    backgroundColor: "inherit",
                    minWidth: "30px"
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {sideNavBarImages(route)}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: "BukraRegular",
                      fontSize: "13px"
                    }}
                  >
                    {data.label}
                  </ListItemText>
                </ListItemButton>
              </NavLink>

              <Divider style={{ background: "#FFFFFF26", width: "inherit" }} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
  return (
    <Box
      component="nav"
      sx={{
        width: { lg: `${SIDE_NAVBAR_WIDTH} px` },
        flexShrink: { sm: 0 }
      }}
      className="sideNavbar"
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={() => openSideNavbarMenu()}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: `${SIDE_NAVBAR_WIDTH}px`,
            backgroundColor: "#180A40",
            color: "#FFFFFF",
            justifyContent: "space-between"
          }
        }}
      >
        {renderMobileSideBarDetails}
        <Typography sx={{ textAlign: "center" }}>
          {REACT_APP_B2B_BUILD_VERSION}
        </Typography>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: `${SIDE_NAVBAR_WIDTH}px`,
            backgroundColor: "#180A40",
            color: "#FFFFFF"
          }
        }}
        open
      >
        {renderSideBarDetails}
      </Drawer>
    </Box>
  );
};

export default NavSideBar;
