import {
  Box,
  Button,
  Dialog,
  Divider,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  CLOSE,
  SOMETHING_WENT_WRONG,
  ORDER,
  RECENT_ORDER_RECEIPT,
  SUMMARY,
  TOTAL,
  TRANSACTION_DATE,
  TOOLTIP_INVOICE_DOWNLOAD,
  TIMEZONE_HELPER_TEXT
} from "../../constant/Constants";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import {
  CapitalizeLetter,
  currencyConvertToReadable,
  getDDMMYYYYDateFormat,
  timingFormat
} from "../../constant/Utils";
import { generateInvoice } from "../../services/user.services";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import SideLoader from "../loaders/sideLoader/SideLoader";

/**
 * Renders the UI for the Order receipt display and download.
 * @param {object} props - Props passed to recent order receipt Pop ui to display the Receipt data.
 * @returns {component} the Dialog Box UI for the order receipt and download the receipt link.
 */
const RecentOrderReceipt = (props) => {
  const { showInvoice, handleInvoiceClose, receiptDetail } = props;
  const {
    orderId,
    customerId,
    order_number,
    currencyCode,
    planName,
    finalAmount,
    subscriptionEndDateKSA,
    addedDateKSA,
    duration,
    period
  } = receiptDetail;
  const orderID = orderId || order_number;
  const [loader, setLoader] = useState(false);
  const [invoiceDownload, setInvoiceDownload] = useState("");
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Handles the invoice download operation.
   * @function handleInvoiceDownload - Performs the invoice download operation.
   * @param {number} cId - customer ID.
   * @param {string} oId - order ID.
   */
  const handleInvoiceDownload = (cId, oId) => {
    setInvoiceDownload("");
    setLoader(true);
    generateInvoice(config.server, cId, oId)
      .then((res) => {
        if (res && res.status !== 200) {
          setInvoiceDownload(SOMETHING_WENT_WRONG);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  /**
   * Handles the close operation on the Order receipt Dialog Box.
   * @function handleDialogClose - Handles the Closing of the Order receipt  Dialog box.
   */
  const handleDialogClose = () => {
    handleInvoiceClose();
  };

  return (
    <Dialog
      // onClose={handleDialogClose}
      aria-labelledby="customized-dialog-title"
      open={showInvoice}
      className="total_newsubscription_cart_wrapper"
      sx={{
        maxWidth: "100%",
        ".MuiPaper-root": {
          maxWidth: "100%",
          width: { xs: "100%", sm: "auto" }
        }
      }}
    >
      <Box>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: 0.5
          }}
        >
          <Typography
            sx={{
              color: "#464E5F",
              fontSize: { md: "18px", xs: "16px" },
              opacity: 1,
              letterSpacing: "0px",
              fontFamily: "BukraBold"
            }}
          >
            {RECENT_ORDER_RECEIPT}
          </Typography>
        </Toolbar>
        <Divider></Divider>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row"
            },
            justifyContent: "space-between",
            py: 3,
            px: "38px"
          }}
        >
          <Typography
            sx={{
              color: "#464E5F",
              fontSize: { xs: "14px", md: "16px" },
              opacity: 1,
              letterSpacing: "0px",
              fontFamily: "BukraRegular",
              pr: 2
            }}
          >
            {TRANSACTION_DATE} {getDDMMYYYYDateFormat(addedDateKSA)}{" "}
            {timingFormat(addedDateKSA)}

          </Typography>
          <Typography
            sx={{
              color: "#464E5F",
              fontSize: { xs: "14px", md: "16px" },
              opacity: 1,
              letterSpacing: "0px",
              fontFamily: "BukraRegular",
              pt: { xs: 1, md: 0 }
            }}
          >
            {ORDER} #{orderID}
          </Typography>
        </Box>
        <Divider
          textAlign="left"
          sx={{
            color: "#A8A8A8",
            fontFamily: "BukraRegular",
            fontSize: { md: "16px", xs: "14px" },
            letterSpacing: "0px"
          }}
        >
          {SUMMARY}
        </Divider>
        <Box sx={{ pt: 4, px: "38px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              sx={{
                color: "#464E5F",
                fontSize: "14px",
                opacity: 1,
                letterSpacing: "0px",
                fontFamily: "BukraMedium",
                pr: 2
              }}
            >
              {planName}
            </Typography>
            <Typography
              sx={{
                color: "#282828",
                fontSize: "14px",
                opacity: 1,
                letterSpacing: "0px",
                fontFamily: "BukraMedium"
              }}
            >
              {`${currencyCode}  ${currencyConvertToReadable(finalAmount)}`}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#282828",
              fontSize: "14px",
              opacity: 1,
              letterSpacing: "0px",
              fontFamily: "BukraRegular",
              pt: 0.5
            }}
          >
            {`${duration} ${CapitalizeLetter(
              period
            )} (Till ${getDDMMYYYYDateFormat(
              subscriptionEndDateKSA
            )} ${timingFormat(subscriptionEndDateKSA)})`}
          </Typography>
          <Box sx={{ display: "flex", alighItems: "center", mt: 3 }}>
            <ThemeProvider theme={ToolTipThemeWithBG}>
              <Tooltip arrow title={TOOLTIP_INVOICE_DOWNLOAD}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Button
                    disabled={loader}
                    data-testid="invoiceDownload"
                    sx={{
                      color: "#0F57CB",
                      fontFamily: "BukraMedium",
                      fontSize: "14px",
                      opacity: 1,
                      letterSpacing: "0px",
                      width: "fit-content",

                      p: 0,
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline"
                      }
                    }}
                    onClick={() => handleInvoiceDownload(customerId, orderId)}
                  >
                    {/* {hardwareModelName &&
                      `${getFirstWordInString(hardwareModelName)}`} */}
                    {`#${customerId} `}
                  </Button>
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        lg: "none",
                        fontSize: "11px"
                      }
                    }}
                  >
                    {TOOLTIP_INVOICE_DOWNLOAD}
                  </Box>
                </Box>
              </Tooltip>
            </ThemeProvider>
            {loader && <SideLoader size={20} />}
          </Box>
          <Box sx={{ mb: 4, height: "12px" }}>
            {invoiceDownload && (
              <span
                style={{
                  color: "red",
                  fontFamily: "BukraRegular",
                  fontSize: "12px"
                }}
              >
                {invoiceDownload}
              </span>
            )}
          </Box>
        </Box>

        <Divider></Divider>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            py: 3,
            px: "36px"
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "16px", xs: "14px" },
              color: "#464E5F",
              fontFamily: "BukraRegular",
              letterSpacing: "0px",
              mr: 2
            }}
          >
            {TOTAL}
          </Typography>{" "}
          -
          <Typography
            sx={{
              fontSize: "20px",
              color: "#464E5F",
              letterSpacing: "0px",
              fontFamily: "BukraBold",
              ml: 2
            }}
          >
            {`${currencyCode}  ${currencyConvertToReadable(finalAmount)}`}
          </Typography>
        </Box>
        <Divider></Divider>
        <Typography
          sx={{
            fontSize: "9px",
            px: 1,
            pt: 0.5,
            fontFamily: "BukraMedium",
            color: "#282828"
          }}
        >
          {TIMEZONE_HELPER_TEXT}
        </Typography>
        <Box sx={{ py: 3.4, display: "flex", justifyContent: "center" }}>
          <Button
            data-testid="closeTestButton"
            onClick={() => handleDialogClose()}
            variant="outlined"
            sx={{
              borderRadius: "20px",
              fontSize: "15px",
              textTransform: "none",
              fontFamily: "BukraMedium",
              letterSpacing: 0,
              background:
                "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
              color: "#FFFFFF",
              width: "200px",
              height: "40px"
            }}
          >
            {CLOSE}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RecentOrderReceipt;
