import { createSlice } from "@reduxjs/toolkit";
import { getLocationDetails } from "../../services/user.services";

const initialState = {};

/**
 * Saves the Country Details into the Store.
 */
const locationDetailsSlice = createSlice({
  name: "locationDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(getLocationDetails.fulfilled, (state, { payload }) => {
      return { ...state, ...payload.data };
    });
  }
});

/**
 * Fetches the Location Details from the Store.
 * @param {object} state - Store State.
 * @returns {object} - Location Details.
 */
export const getLocationDetailsFromStore = (state) => {
  return state.locationDetails;
};

export default locationDetailsSlice.reducer;
