import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, Link as RouterLink } from "react-router-dom";
import searchIcon from "./../../assets/images/search.svg";
import SubscribeNowIcon from "./../../assets/images/subscribe_now.svg";
import CustomerRegistrationIcon from "./../../assets/images/customer_registration.svg";
import {
  ACTION,
  APPLY,
  NO_CONTENT,
  NO_DATA,
  RECENT_SUBSCRIPTION_SALES,
  RESET,
  SCOPES,
  SUBSCRIPTIONLISTLIMIT,
  TOOLTIP_NINEGRID_IMG,
  TOOLTIP_RS_SALES_VIEWALL,
  VIEW_ALL,
  VIEW_INVOICE
} from "../../constant/Constants";
import RecentOrderReceipt from "../recentOrderReceipt/RecentOrderReceipt";
import {
  currencyConvertToReadable,
  getComparator,
  getDDMMYYYYDateFormat,
  getSearchItems,
  tableSorting,
  subscriptionSalesTableHeadCells,
  subscriptionSalesSearchOptions
} from "../../constant/Utils";
import { getSubscriptionList } from "../../services/user.services";
import { useSelector } from "react-redux";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import { Button, Checkbox, Menu, Stack, Tooltip } from "@mui/material";
import { getUserScope } from "../../stateManagement/reducers/LoginSlice";
import CustomPagination from "../customComponents/customPagination/CustomPagination";
import { muiMenuItemStyles, tableScrollStyles } from "../../style/CustomStyles";
import viewGrid from "./../../assets/images/view_grid.svg";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});
const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          marginTop: "4px !important",
          color: "#6a707c",
          backgroundColor: "transparent"
        }
      }
    }
  }
});
const options = ["Order ID", "Order Date"];

/**
 * Renders the Recent Subscription Sales Table UI component.
 * @param {{showNineGrid:*}}  - Prop element passed to the Recent Subscription table.
 * @returns the Recent Subscription Sales table UI component.
 */
const RecentSubscriptionSalesTable = ({ showNineGrid }) => {
  /**
   * @constant {string} selectedDateFilter - Fetch the date range from the store.
   */
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );

  const fetchedUserScope = useSelector((state) => getUserScope(state));
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("addedDateKSA");
  const [tableBodyDetails, setTableBodyDetails] = useState([]);
  const [searchTableBodyDetails, setSearchTableBodyDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectSearchItem, setSelectedSearchItem] = useState("finalAmount");
  const [search, setSearch] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [receiptDetail, setReceiptDetail] = useState({});
  const [selectedCheckBox, setselectedCheckBox] = useState(options);
  const [isFilterApply, setIsFilterApply] = useState({
    filterAppliedSelectedCheckBox: selectedCheckBox
  });
  const [loader, setLoader] = useState(false);
  const { filterAppliedSelectedCheckBox } = isFilterApply;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { startDate, endDate } = selectedDateFilter;
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Performs the Ascending and descending Operation on Table Items based on the Head cell selected.
   * @function handleRequestSort - Performs action to sort the table in ascending or descending order.
   * @param {event} _event
   * @param {string} property
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * Preloads the Recents Subscription Sales data based on the Start date and end date selected.
   */
  useEffect(() => {
    if (startDate && endDate && config.server) {
      setLoader(true);
      getSubscriptionList(config.server, startDate, endDate)
        .then((response) => {
          if (
            response &&
            response.status === 200 &&
            response.data &&
            response.data.status !== NO_CONTENT
          ) {
            const filterData = response.data.filter(
              (item) => item.purchased === true
            );
            const recentList =
              filterData && filterData.slice(0, SUBSCRIPTIONLISTLIMIT);
            setTableBodyDetails(recentList);
            setSearchTableBodyDetails(recentList);
          } else {
            setTableBodyDetails([]);
            setSearchTableBodyDetails([]);
          }
        })
        .finally(() => setLoader(false));
    }
  }, [startDate, endDate, config.server]);

  useEffect(() => {
    let filteredRows = searchTableBodyDetails;
    if (search) {
      filteredRows = getSearchItems(
        searchTableBodyDetails,
        selectSearchItem,
        search
      );
    }
    setPage(1);
    setTableBodyDetails(filteredRows);
  }, [searchTableBodyDetails, selectSearchItem, search]);

  const totalCount = Math.ceil(tableBodyDetails.length / pageSize);

  /**
   * Handles the page change Operation.
   * @function handlePageChange - Handle the Table content pagination change.
   */
  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  /**
   * Handles the page size change Operation.
   * @function handlePageSizeChange -  handles the Change in size of rows per table.
   */
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  /**
   * Sets the input value for the search operation.
   * @function handlerequestSearch - Performs operation on change of search input text.
   * @param {object} event - Search input event object.
   */
  const handlerequestSearch = (event) => {
    setSearch(event.target.value.trim());
  };

  /**
   * Handles the Search Category select option change.
   * @function handleSelectChange - Performs action on Search category change.
   * @param {object} event - Search category event object.
   */
  const handleSelectChange = (event) => {
    setSelectedSearchItem(event.target.value);
  };

  /**
   * Handles the display of the Invoice Dialog Box
   * @function handleViewInvoice - Performs action to view the invoice of the selected table row.
   * @param {object} row - Select record from the table.
   */
  const handleViewInvoice = (row) => {
    setReceiptDetail(row);
    setShowInvoice(true);
  };

  /**
   * Handles the close operation on the Invoice Dialog box.
   * @function handleInvoiceClose - closes the Invoice pop up.
   */
  const handleInvoiceClose = () => {
    setReceiptDetail({});
    setShowInvoice(false);
  };

  /**
   * Handles the click operation on grid
   * @param {object} event - Grid click event object.
   */
  const handleGridClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the uncheck operation on the checked item click
   * @function handleCheckClose - Handles the close opeartion.
   */
  const handleCheckClose = () => {
    setselectedCheckBox(filterAppliedSelectedCheckBox);
    setAnchorEl(null);
  };

  /**
   * Handles the filter operation based on the selected checkbox clicked.
   * @function handleApplyFilter - Performs the filter action based on the checkbox element selected.
   */
  const handleApplyFilter = () => {
    setIsFilterApply({
      filterAppliedSelectedCheckBox: selectedCheckBox
    });
    setAnchorEl(null);
  };

  /**
   * Handles the Change event in the Clicked checkbox items.
   * @function handleCheckBoxChange - performs action to change the checkbox filter based on checkbox element selected.
   * @param {object} event
   */
  const handleCheckBoxChange = (event) => {
    const changedValue = event.target.value;
    const list = [...selectedCheckBox];
    const index = list.indexOf(changedValue);
    index === -1 ? list.push(changedValue) : list.splice(index, 1);
    setselectedCheckBox(list);
  };

  /**
   * Handles the reset checkbox operation.
   * @function handleResetCheckBox - Resets the Filter for Recent Subscription Sales Table.
   */
  const handleResetCheckBox = () => {
    setselectedCheckBox([]);
  };

  /**
   * @constant {number} indexOfFilterCheckBox - Fetches the index of the filtered elements in Grid.
   */
  const indexOfFilterCheckBox = filterAppliedSelectedCheckBox.map((obj) => {
    return subscriptionSalesTableHeadCells.findIndex(
      (item) => item.label === obj
    );
  });

  /**
   * Handles the opearation of filteration of table based on the grid item selected
   * @function getSelectedCheckBoxStatus - Gets the Data based on the Selected checkbox.
   * @param {string} id - label of checkbox.
   * @param {number} index - index of checkbox.
   * @returns {boolean} true or false.
   */
  const getSelectedCheckBoxStatus = (id, index) => {
    switch (id) {
      case "Serial Number":
        return indexOfFilterCheckBox.includes(index);
      case "Order ID":
        return indexOfFilterCheckBox.includes(index);
      case "Order Date":
        return indexOfFilterCheckBox.includes(index);
      default:
        return true;
    }
  };

  return (
    <Paper
      data-testid="recentSubscriptionSalesTable"
      sx={{ width: "100%", mb: 2 }}
    >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: "flex",
          alignItems: { xs: "flex-start", sm: "center" },
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          minHeight: { sm: "84px" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
            width: { xs: "100%", md: "auto" }
          }}
        >
          <Typography
            variant="h6"
            id="tableTitle"
            sx={{
              fontFamily: "BukraMedium",
              fontSize: "16px",
              letterSpacing: "0px",
              color: "#464E5F",
              mr: 2,
              my: { xs: 1, lg: 0 }
            }}
          >
            {RECENT_SUBSCRIPTION_SALES}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "12px",
              backgroundColor: "#f3f6f9",
              height: "40px",
              my: { xs: 1, lg: 0 },
              width: { xs: "100%", sm: "auto" }
            }}
          >
            <img
              src={searchIcon}
              alt="search"
              style={{
                height: "11px",
                paddingLeft: "20px"
              }}
            ></img>
            <ThemeProvider theme={inputTheme}>
              <InputBase
                autoComplete="off"
                value={search}
                inputProps={{ "data-testid": "requestSearch" }}
                sx={{ ml: 1, flex: 1, width: { md: "160px", xs: "100%" } }}
                placeholder="Search"
                onChange={(event) => handlerequestSearch(event)}
              />
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Select
                id="filledoption"
                inputProps={{
                  "data-testid": "selectSearchInner"
                }}
                disableUnderline={true}
                sx={{
                  backgroundColor: "#7B7D801A",
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                  fontSize: "13px",
                  pl: 2,
                  pr: 2,
                  color: "#282828",
                  fontFamily: "BukraRegular",
                  height: "40px",
                  ".MuiSelect-icon": {
                    px: 1
                  }
                }}
                value={selectSearchItem}
                onChange={handleSelectChange}
                variant="standard"
              >
                {subscriptionSalesSearchOptions.map((option) => (
                  <MenuItem
                    data-testid="sitem"
                    sx={{ ...muiMenuItemStyles }}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </Box>
        </Box>
        <Stack
          sx={{
            pr: showNineGrid ? 0 : 5,
            alignItems: "center",
            my: { xs: 1, lg: 0 }
          }}
          direction={"row"}
          spacing={3}
        >
          <ThemeProvider theme={toolTipTheme}>
            {fetchedUserScope.includes(SCOPES.BUTTON_DIRECT_GRID) &&
              showNineGrid && (
                <Tooltip title={TOOLTIP_NINEGRID_IMG} className="tooltip_mweb">
                  <Box>
                    <img
                      src={viewGrid}
                      alt="grid"
                      height="36px"
                      data-testid="viewGrid"
                      onClick={handleGridClick}
                    ></img>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          lg: "none",
                          fontSize: "11px",
                          paddingTop: "5px"
                        }
                      }}
                    >
                      Columns
                    </Box>
                  </Box>
                </Tooltip>
              )}
            {showNineGrid && (
              <Tooltip
                title={TOOLTIP_RS_SALES_VIEWALL}
                classes={{ popper: "rc_viewall_tt" }}
                sx={{
                  padding: "5px",
                  ".MuiTooltip-tooltip": {
                    padding: "5px"
                  },
                  "&.MuiTooltip-tooltip": {
                    padding: "5px"
                  }
                }}
              >
                <Link
                  to="/customer"
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <Typography
                    data-testid="viewAll"
                    sx={{
                      mr: 2,
                      fontFamily: "BukraMedium",
                      cursor: "pointer",
                      color: "#0F57CB",
                      fontSize: "15px",
                      width: "66px",
                      ":hover": {
                        textDecoration: "underline"
                      }
                    }}
                  >
                    {VIEW_ALL}
                  </Typography>
                </Link>
              </Tooltip>
            )}
            {fetchedUserScope.includes(SCOPES.BUTTON_DIRECT_SUBSCRIBE_NOW) &&
              !showNineGrid && (
                <Tooltip title="Subscribe Now" className="tooltip_mweb">
                  <Box>
                    <RouterLink to="subscribenow">
                      <img
                        src={SubscribeNowIcon}
                        alt="Subscribe Now"
                        className="image_subscribe imgHover"
                      ></img>
                    </RouterLink>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          lg: "none",
                          fontSize: "11px",
                          paddingTop: "5px"
                        }
                      }}
                    >
                      Subscribe Now
                    </Box>
                  </Box>
                </Tooltip>
              )}
            {fetchedUserScope.includes(
              SCOPES.BUTTON_DIRECT_CUSTOMER_REGISTRATION
            ) &&
              !showNineGrid && (
                <Tooltip title="Customer Registration" className="tooltip_mweb">
                  <Box>
                    <RouterLink to="customerregistration">
                      <img
                        src={CustomerRegistrationIcon}
                        alt="Customer Registration"
                        className="image_subscribe imgHover"
                      ></img>
                    </RouterLink>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          lg: "none",
                          fontSize: "11px",
                          paddingTop: "5px"
                        }
                      }}
                    >
                      Add Customer
                    </Box>
                  </Box>
                </Tooltip>
              )}
          </ThemeProvider>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            data-testid="gridoption"
            open={open}
            onClose={handleCheckClose}
            PaperProps={{
              elevation: 0,
              sx: {
                minWidth: "150px",
                overflow: "visible",
                backgroundColor: "#f6f6f6",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                pl: 2.5,
                pr: 2.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: { sm: 14 },
                  width: 10,
                  height: 10,
                  bgcolor: "#f6f6f6",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {options.map((option, index) => {
              return (
                <Box key={`${option + index}`}>
                  <MenuItem
                    sx={{
                      p: 0,

                      fontSize: "13px",
                      fontFamily: "BukraRegular",
                      color: "#212121",
                      "&.Mui-selected": {
                        fontFamily: "BukraMedium",
                        backgroundColor: "inherit",
                        "&.Mui-selected:focus": {
                          backgroundColor: "inherit"
                        }
                      }
                    }}
                  >
                    <Checkbox
                      value={option}
                      inputProps={{
                        "data-testid": "checkbox"
                      }}
                      onChange={handleCheckBoxChange}
                      checked={selectedCheckBox.includes(option)}
                      sx={{ p: 0, height: "10px" }}
                      size="small"
                    />
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BukraRegular",
                        color: "#212121",
                        letterSpacing: "0px",
                        pl: 1
                      }}
                    >
                      {option}
                    </Typography>
                  </MenuItem>
                  {index !== options.length - 1 && (
                    <Divider
                      sx={{
                        p: 0,
                        m: "0px",
                        ml: "30px"
                      }}
                    ></Divider>
                  )}
                </Box>
              );
            })}
            <Box
              sx={{
                pt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
                color: "#0F57CB",
                borderRadius: "10px"
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  p: "0px",
                  fontSize: "10px",
                  color: "#0F57CB",
                  borderRadius: "10px",
                  textTransform: "none"
                }}
                data-testid="reset"
                onClick={() => handleResetCheckBox()}
              >
                {RESET}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  ml: 1,
                  p: "0px",
                  fontSize: "10px",
                  borderRadius: "10px",
                  textTransform: "none",
                  cursor: "pointer",
                  background:
                    "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                  color: "#FFFFFF"
                }}
                data-testid="apply"
                onClick={() => handleApplyFilter()}
              >
                {APPLY}
              </Button>
            </Box>
          </Menu>
        </Stack>
      </Toolbar>
      <Divider></Divider>
      <TableContainer sx={{ ...tableScrollStyles }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {subscriptionSalesTableHeadCells.map(
                (headCell, index) =>
                  getSelectedCheckBoxStatus(headCell.label, index) && (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{
                        borderBottom: "none",
                        px: index === 0 ? "16px" : "10px"
                      }}
                    >
                      <TableSortLabel
                        data-testid="sortLabel"
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleRequestSort("", headCell.id)}
                        sx={{
                          color: "#606060",
                          letterSpacing: "0px",
                          fontSize: "15px",
                          fontFamily: "BukraMedium"
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  )
              )}
              <TableCell
                align={"left"}
                sx={{
                  color: "#606060",
                  letterSpacing: "0px",
                  fontSize: "15px",
                  fontFamily: "BukraMedium",
                  borderBottom: "none",
                  px: "10px"
                }}
              >
                {ACTION}
              </TableCell>
            </TableRow>
          </TableHead>

          {!loader && tableBodyDetails && tableBodyDetails.length > 0 && (
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                 tableBodyDetails.slice().sort(getComparator(order, orderBy)) */}
              {tableSorting(tableBodyDetails, getComparator(order, orderBy))
                .slice((page - 1) * pageSize, page * pageSize)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      style={
                        index % 2
                          ? { background: "white" }
                          : { background: "#F7F9FC" }
                      }
                    >
                      <TableCell
                        component="th"
                        align="left"
                        scope="row"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          px: "16px"
                        }}
                      >
                        {row.customerId}
                      </TableCell>
                      {filterAppliedSelectedCheckBox.includes("Order ID") && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            px: "10px"
                          }}
                        >
                          {row.orderId}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          px: "10px"
                        }}
                      >
                        {row.planType}
                      </TableCell>
                      {filterAppliedSelectedCheckBox.includes("Order Date") && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            px: "10px"
                          }}
                        >
                          
                          {getDDMMYYYYDateFormat(row.addedDateKSA)}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          px: "10px"
                        }}
                      >
                        {`${row.currencyCode} 
                          ${currencyConvertToReadable(row.finalAmount)}`}
                      </TableCell>
                      {/* {filterAppliedSelectedCheckBox.includes(
                        "Serial Number"
                      ) && (
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#282828",
                            letterSpacing: "0px",
                            fontSize: "13px",
                            fontFamily: "BukraRegular",
                            px: "10px"
                          }}
                        >
                          {row.serialNumber ? row.serialNumber : "-"}
                        </TableCell>
                      )} */}
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          px: "10px"
                        }}
                      >
                        <Typography
                          data-testid="inVoice"
                          sx={{
                            color: "#0F57CB",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            fontFamily: "BukraRegular",
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline"
                            }
                          }}
                          onClick={() => handleViewInvoice(row)}
                        >
                          {VIEW_INVOICE}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!loader && tableBodyDetails.length === 0 && (
        <Typography
          textAlign={"center"}
          sx={{
            mt: 3,
            pb: 3
          }}
        >
          {NO_DATA}
        </Typography>
      )}

      {loader && <BodyLoader />}

      {!loader && tableBodyDetails && tableBodyDetails.length > 0 && (
        <CustomPagination
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          totalCount={totalCount}
          page={page}
          handlePageChange={handlePageChange}
        ></CustomPagination>
      )}
      {showInvoice && (
        <RecentOrderReceipt
          showInvoice={showInvoice}
          receiptDetail={receiptDetail}
          handleInvoiceClose={handleInvoiceClose}
        ></RecentOrderReceipt>
      )}
    </Paper>
  );
};
export default RecentSubscriptionSalesTable;
