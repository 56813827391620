import { createSlice } from "@reduxjs/toolkit";
import { getUserRoleName } from "../../constant/Utils";

import {
  getForgotPassword,
  getLoginDetails,
  getUserScopeDetails,
  userSignOut,
  updateChangePassword
} from "../../services/user.services";

const initialState = {
  userInfo: null
};

/**
 * Saves the User Information into the Store on login.
 */
const loginSlice = createSlice({
  name: "userLogin",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(getLoginDetails.fulfilled, (state, { payload }) => {
      if (payload.data && payload.status && payload.status === 200) {
        state.userInfo = payload.data;
        state.userToken = payload.data.accessToken;
        // store user's  accessToken in local storage
        // localStorage.setItem("userToken", payload.data.accessToken);
        sessionStorage.setItem("userToken", payload.data.accessToken);
      }
    });
    builder.addCase(getForgotPassword.fulfilled, (state, { payload }) => {
      if (payload.data && payload.status && payload.status === 200) {
        state.userInfo = payload.data;
        state.userToken = payload.data.accessToken;
        // store user's  accessToken in local storage
        // localStorage.setItem("userToken", payload.data.accessToken);
        sessionStorage.setItem("userToken", payload.data.accessToken);
      }
    });
    builder.addCase(userSignOut.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
      state.success = false;
      // localStorage.removeItem("userToken");
      sessionStorage.removeItem("userToken");
      // localStorage.removeItem("fcmToken");
      sessionStorage.removeItem("fcmToken");
    });
    builder.addCase(getUserScopeDetails.fulfilled, (state, { payload }) => {
      if (payload.data && payload.status && payload.status === 200) {
        state.userInfo = payload.data;
      }
    });
    builder.addCase(getUserScopeDetails.rejected, (state, action) => {
      state.userInfo = null;
    });

    builder.addCase(updateChangePassword.fulfilled, (state, { payload }) => {
      if (payload.data && payload.status && payload.status === 200) {
        state.userInfo.changePassword = false;
      }
    });
  }
});

/**
 * Fetches the User role Type from store.
 * @param {object} state - Store State.
 * @returns {number} - User Role type
 */
export const getUserRoleType = (state) => {
  const roleType = state.login.userInfo ? state.login.userInfo.roleType : 0;
  return roleType;
};

/**
 * Fetches the User role label String from store.
 * @param {object} state - Store State.
 * @returns {string} - User Role
 */
export const getUserRole = (state) => {
  const role = state.login.userInfo ? state.login.userInfo.roleType : "";
  return getUserRoleName(role);
};

/**
 * Fetches the User Email from store.
 * @param {object} state - Store State.
 * @returns {string} - User Email
 */
export const getUserEmail = (state) => {
  const emailId = state.login.userInfo ? state.login.userInfo.email : "";
  return emailId;
};

/**
 * Fetches the User  Comapny Id of user from store.
 * @param {object} state - Store State.
 * @returns {number} - Comapny Id of user
 */
export const getCompanyId = (state) => {
  const comapny_id = state.login.userInfo ? state.login.userInfo.companyId : "";
  return comapny_id;
};

/**
 * Fetches the User Name from store.
 * @param {object} state - Store State.
 * @returns {string} - Channel Partner Name
 */
export const getUserName = (state) => {
  const firstName =
    state.login.userInfo && state.login.userInfo.firstName
      ? state.login.userInfo.firstName
      : "";
  const lastName =
    state.login.userInfo && state.login.userInfo.lastName
      ? state.login.userInfo.lastName
      : "";
  const name = `${firstName} ${lastName}`.trim();
  return name;
};

/**
 * Fetches the User's visible Scopes from store eg. Menu items.
 * @param {object} state - Store State.
 * @returns {array} - Visible scopes for User
 */
export const getUserScope = (state) => {
  const scopes = state.login.userInfo ? state.login.userInfo.scopes : "";
  return scopes;
};

/**
 * Fetches the Change password status for the user on login.
 * @param {object} state - Store State.
 * @returns {boolean} - Change Password status on login.
 */

export const getChangePasswordStatus = (state) => {
  const changePassword =
    state && state.login && state.login.userInfo
      ? state.login.userInfo.changePassword
      : false;
  return changePassword;
};

/**
 * Fetches the User  Information from store.
 * @param {object} state - Store State.
 * @returns {object} - User information.
 */
export const getUserInfo = (state) => {
  const scopes = state.login.userInfo ? state.login.userInfo : null;
  return scopes;
};

/**
 * Fetches the Channel Partner code from store.
 * @param {object} state - Store State.
 * @returns {string} - Channel Partner code.
 */
export const getChannelPartnerCode = (state) => {
  const scopes = state.login.userInfo
    ? state.login.userInfo.channelPartnerCode
    : "";
  return scopes;
};
export default loginSlice.reducer;
