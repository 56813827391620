import { CircularProgress, Typography } from "@mui/material";
import React from "react";

/**
 * Renders the Center Body Loader Component.
 * @returns {JSX} Loader UI component at center of the Div
 */
const BodyLoader = () => {
  return (
    <Typography
      textAlign={"center"}
      sx={{
        width: "100%",
        mt: 3,
        pb: 3
      }}
    >
      <CircularProgress />
    </Typography>
  );
};

export default BodyLoader;
