import {
  PORTAL_TYPE,
  PORTAL_TYPE_ADMIN,
  PORTAL_TYPE_CRM,
  VERSION_API
} from "./Constants";

/**
 * @constant {string} adminBaseUrl - Admin base URL.
 */

/**
 * @constant {object} Url - Collection of All the URL endpoints.
 */

const Url = {
  login: `${PORTAL_TYPE}/${VERSION_API}/login`,
  signOut: `${PORTAL_TYPE}/${VERSION_API}/signout`,
  company: `${PORTAL_TYPE}/${VERSION_API}/company`,
  scope: `${PORTAL_TYPE}/${VERSION_API}/scope`,
  forgotPassword: `${PORTAL_TYPE}/${VERSION_API}/forgetPassword`,
  generateOTP: `${PORTAL_TYPE}/${VERSION_API}/generate-otp`,
  validateOTP: `${PORTAL_TYPE}/${VERSION_API}/validate-otp`,
  credits: `${PORTAL_TYPE}/${VERSION_API}/credits`,
  passcodeVerification: `${PORTAL_TYPE}/${VERSION_API}/passcode-verification`,
  changePassword: `${PORTAL_TYPE}/${VERSION_API}/changePassword`,
  resetChildPasscode: `${PORTAL_TYPE}/${VERSION_API}/resetChildPasscode`,
  resetChildPassword: `${PORTAL_TYPE}/${VERSION_API}/resetChildPassword`,
  userDetails: `${PORTAL_TYPE}/${VERSION_API}/userDetails`,
  creditSummary: `${PORTAL_TYPE}/${VERSION_API}/creditSummary`,
  geoLocation: `geoiplocation/${VERSION_API}/getlocation`,
  coutriesList: `${PORTAL_TYPE_ADMIN}/${VERSION_API}/country`,
  cityList: `${PORTAL_TYPE_ADMIN}/${VERSION_API}/getCityByCountry`,
  retailerOnboarding: `${PORTAL_TYPE}/${VERSION_API}/retailer-registration`,
  recentCredits: `${PORTAL_TYPE}/${VERSION_API}/recent-credits`,
  parentDetails: `${PORTAL_TYPE}/${VERSION_API}/parent-users?parentType=PARENT`,
  creditBalance: `${PORTAL_TYPE}/${VERSION_API}/creditbalance-childchannelpartner?channelPartnerCode=`,
  inventoryStock: `${PORTAL_TYPE}/${VERSION_API}/inventory-stock-details`,
  companies: `${PORTAL_TYPE}/${VERSION_API}/getCompany`,
  creditsTransfer: `${PORTAL_TYPE}/${VERSION_API}/transfer-credits`,
  // creditDetails: `${baseUrl}/${PORTAL_TYPE}/${VERSION_API}/credit-details`,
  unblockChildUserAccount: `${PORTAL_TYPE}/${VERSION_API}/unblock-childchannelpartner`,
  childDetails: `${PORTAL_TYPE}/${VERSION_API}/child-user?channelPartnerCode=`,
  updateChildProfile: `${PORTAL_TYPE}/${VERSION_API}/update-childprofile`,
  blockChildUserAccount: `${PORTAL_TYPE}/${VERSION_API}/block-childchannelpartner`,
  childChannelPartner: `${PORTAL_TYPE}/${VERSION_API}/getChildChannelPartnerList`,
  countryDetails: `${PORTAL_TYPE}/${VERSION_API}/getCountrydetailsOfChannelPartner`,

  subscriptionList: `${PORTAL_TYPE}/${VERSION_API}/list-subscription`,
  customerRegistration: `${PORTAL_TYPE}/${VERSION_API}/registerCustomerWithStb`,
  trackSTB: `${PORTAL_TYPE}/${VERSION_API}/track-stb`,
  getCustomer: `${PORTAL_TYPE}/${VERSION_API}/customer`,
  allocateSTB: `${PORTAL_TYPE}/${VERSION_API}/allocate-stb`,
  planList: `${PORTAL_TYPE}/${VERSION_API}/plan-list`,
  hardwareTransactions: `${PORTAL_TYPE}/${VERSION_API}/hardware-transactions`,
  inventoryStockCount: `${PORTAL_TYPE}/${VERSION_API}/inventory-stock-count`,
  addSubscription: `${PORTAL_TYPE}/${VERSION_API}/add-subscription`,
  childChannelPartnerRevenueList: `${PORTAL_TYPE}/${VERSION_API}/childchannelpartner-revenue-list`,
  childChannelPartnerRevenue: `${PORTAL_TYPE}/${VERSION_API}/childchannelpartner-revenue`,
  notifications: `${PORTAL_TYPE}/${VERSION_API}/notification`,
  replaceSTB: `${PORTAL_TYPE}/${VERSION_API}/replace-stb`,
  pushNotify: `${PORTAL_TYPE}/${VERSION_API}/notif`,
  invoiceDownload: `${PORTAL_TYPE}/${VERSION_API}/generateInvoice`,
  downloadCreditReport: `${PORTAL_TYPE}/${VERSION_API}/creditsReport`,
  downloadRevenueReport: `${PORTAL_TYPE}/${VERSION_API}/childchannelpartner-revenue-list-report`,
  downloadStockReport: `${PORTAL_TYPE}/${VERSION_API}/inventory-stock-details-report`,
  checkEligibility: `${PORTAL_TYPE}/${VERSION_API}/check-eligibility`,
  broadcastNotification: `${PORTAL_TYPE}/${VERSION_API}/broadcast-notification`,
  notificationRead: `${PORTAL_TYPE}/${VERSION_API}/notification-read`,
  summaryReport: `${PORTAL_TYPE}/${VERSION_API}/summaryReport`,
  refreshSTB: `${PORTAL_TYPE}/${VERSION_API}/refresh-stb`,
  transactionRead: `${PORTAL_TYPE}/${VERSION_API}/transaction-read`,
  addFcmToken: `${PORTAL_TYPE}/${VERSION_API}/addFcmToken`,
  //  Retailer registration
  generateUserOtp: `${PORTAL_TYPE}/${VERSION_API}/generate-user-otp`,
  validateUserOtp: `${PORTAL_TYPE}/${VERSION_API}/validate-user-otp`,
  //Hardware allocation
  generateCustomerHardwareOtp: `${PORTAL_TYPE_CRM}/${VERSION_API}/generateCustomerOtp`,
  validateCustomerHardWareOtp: `${PORTAL_TYPE_CRM}/${VERSION_API}/validateCustomerOtp`,
  //Customer registration
  verifyCustomerWithOTP: `${PORTAL_TYPE}/${VERSION_API}/verifyCustomerWithOTP`,
  validateCustomerWithOTP: `${PORTAL_TYPE}/${VERSION_API}/validateCustomerOTP`
};

export default Url;
