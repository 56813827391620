import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { PAY_NOW, TOTAL } from "../../constant/Constants";
import {
  capitalizeFirstLetter,
  currencyConvertToReadable
} from "../../constant/Utils";
import "./SubscriptionConfirmationPop.scss";
import { useSelector } from "react-redux";

/**
 * Renders the Added Plan Package for Subscription Popup.
 * @param {object} props - Props passed to the Subscription Popup UI.
 * @returns {component} Plan Package Subscription Popup UI component.
 */
export default function SubscriptionConfirmationPop(props) {
  const {
    showViewCart = true,
    handleViewCartClose,
    removeAllCartItems,
    paynowForSubscription,
    subscriptionDetails,
    tittle,
    clearLabel,
    subscriptionType
  } = props;

  /**
   * @constant {object} countryDetails - Contains the Country Details fetched from store.
   */
  const countryDetails = useSelector((state) => state.countryDetails);

  /**
   * @function handleClose - Closes the Subscription Popup UI.
   */
  const handleClose = () => {
    handleViewCartClose();
  };

  /**
   * @function handleClearCart - Removes all the Packages from the Subscription Popup UI.
   */
  const handleClearCart = () => {
    removeAllCartItems();
    handleViewCartClose();
  };

  /**
   * @function handlePayNow - Handles the Pay Now Activity on Click of Pay Now Button.
   */
  const handlePayNow = () => {
    handleViewCartClose();
    paynowForSubscription();
  };

  const totalPrice = () => {
    return subscriptionDetails.reduce(
      (accumulator, current) => accumulator + current.finalAmount,
      0
    );
  };
  return (
    <Dialog
      data-testid="totalNewSubscriptionCart"
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showViewCart}
      className="total_newsubscription_cart_wrapper"
    >
      <Box
        sx={{
          width: { lg: "539px", sm: "420px", xs: "100%" },
          minHeight: "313px"
        }}
      >
        <Box
          sx={{
            height: "46px",
            background:
              "transparent linear-gradient(90deg, #122157 0%, #12245C 100%) 0% 0% no-repeat padding-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Typography
            data-testid="cart"
            sx={{
              color: "#ffffff",
              fontSize: "15px",
              fontFamily: "BukraMedium",
              letterSpacing: 0,
              flex: 1,
              textAlign: "center"
            }}
          >
            {tittle}
          </Typography>
          <IconButton
            data-testid="closeIcon"
            sx={{ color: "#ffffff", fontSize: "16px", pr: 2.5 }}
            onClick={handleClose}
          >
            x
          </IconButton>
        </Box>
        <Box sx={{ px: "50px", pt: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: 1,
              pb: 1
            }}
          >
            <Typography
              data-testid="newSubscriptionTitle"
              className="headerTittle"
              sx={{
                fontFamily: "BukraMedium",
                color: "#212121",
                fontSize: { xs: "12px", lg: "15px" }
              }}
            >
              {subscriptionType}
            </Typography>
            <Typography
              data-testid="newSubscriptionValue"
              className="headerTittle"
              sx={{
                fontFamily: "BukraMedium",
                color: "#212121",
                fontSize: { xs: "12px", lg: "15px" }
              }}
            >
              {subscriptionDetails.length}
            </Typography>
          </Box>
          <Box sx={{ pb: 1 }}>
            {subscriptionDetails &&
              subscriptionDetails.map((cartDetails, index) => {
                return (
                  <Box key={cartDetails.skuCode}>
                    <Box
                      data-testid="cartDetails"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: 0.8,
                        pb: 1
                      }}
                    >
                      <Box>
                        <Typography
                          className="content"
                          sx={{
                            fontSize: { xs: "12px", lg: "15px" },
                            pr: { xs: 1, lg: 0 },
                            color: "#212121"
                          }}
                        >
                          {cartDetails.planName}
                        </Typography>
                        <Box
                          sx={{ display: { xs: "none", sm: "flex" }, pt: 0.2 }}
                        >
                          <Typography
                            sx={{ pr: 0.5, color: "#212121" }}
                            className="sublabel"
                          >
                            {`  ${cartDetails.duration} ${capitalizeFirstLetter(
                              cartDetails.period
                            )}`}
                          </Typography>
                          {/* <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ backgroundColor: "#212121" }}
                          />
                          <Typography
                            sx={{ pl: 0.5, color: "#212121" }}
                            className="sublabel"
                          >
                            {stbID}
                          </Typography> */}
                        </Box>
                      </Box>
                      <Typography
                        className="content"
                        sx={{
                          fontSize: { xs: "12px", lg: "15px" },
                          textAlign: "end",
                          color: "#212121"
                        }}
                      >
                        {cartDetails.currencyCode}{" "}
                        {currencyConvertToReadable(cartDetails.finalAmount)}
                      </Typography>
                    </Box>
                    <Divider></Divider>
                  </Box>
                );
              })}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}>
            <Typography
              data-testid="TotalTitle"
              className="headerTittle"
              sx={{
                fontSize: { xs: "12px", lg: "15px" }
              }}
            >
              {TOTAL}
            </Typography>
            <Typography
              data-testid="TotalValue"
              className="headerTittle"
              sx={{
                fontSize: { xs: "12px", lg: "15px" }
              }}
            >
              {countryDetails.countryCurrencyCode}{" "}
              {currencyConvertToReadable(totalPrice())}
            </Typography>
          </Box>
          <Stack
            spacing={2}
            direction={{ xs: "column", lg: "row" }}
            sx={{ pt: 5, pb: 2, justifyContent: "center" }}
          >
            <Button
              data-testid="clearCartButton"
              variant="outlined"
              sx={{
                borderRadius: "20px",
                borderColor: "#4079d5",
                fontSize: "15px",
                textTransform: "none",
                fontFamily: "BukraRegular",
                letterSpacing: 0,
                color: "#4079d5",
                minWidth: "175px"
              }}
              onClick={() => handleClearCart()}
            >
              {clearLabel}
            </Button>
            <Button
              data-testid="payNowButton"
              variant="outlined"
              sx={{
                borderRadius: "20px",
                fontSize: "15px",
                textTransform: "none",
                fontFamily: "BukraRegular",
                letterSpacing: 0,
                background:
                  "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                color: "#FFFFFF",
                minWidth: "175px"
              }}
              onClick={() => handlePayNow()}
            >
              {PAY_NOW}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
}
