import { Box, InputBase, MenuItem, Pagination, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import { onlyNumber } from "../../../constant/Utils";

/**
 * Renders the Custom Pagination UI component.
 * @param {objects} props - Props passed to the custom Pagination to Perform Particular actions
 * @returns {component} the UI component containing pagination Actions and functionality.
 */
const CustomPagination = (props) => {
  const pageSizes = [10, 15, 20];
  const { pageSize, handlePageSizeChange, totalCount, page, handlePageChange } =
    props;
  const [gotoPage, SetGotoPage] = useState("");
  const buttonRef = useRef(null);

  /**
   * Handles the Go to Page Operation on click of Input element.
   * @function handleChangeGotoPage - Performs Go to Page operation on click of input element.
   * @param {object} e - Input element pointer event.
   */
  const handleChangeGotoPage = (e) => {
    const { value } = e.target;
    if (value && onlyNumber(value)) {
      SetGotoPage(Number(value));
    } else {
      SetGotoPage("");
    }
    if (Number(value) <= totalCount) {
      setTimeout(() => {
        buttonRef.current.click();
      }, 1);
    }
  };

  /**
   * Handle the Click operation on Go to Page button.
   * @function handleClickGotoPage - Performs action on click of the Go to Page.
   * @param {object} e - Go To Page Button pointer event.
   */
  const handleClickGotoPage = (e) => {
    if (gotoPage) {
      handlePageChange(e, gotoPage);
    } else {
      handlePageChange(e, 1);
    }
  };

  /**
   * Handles the Page Change Operation.
   * @function handlePageChange - Performs the page change operation.
   * @param {object} e - Pointer event.
   * @param {numner} val - Page no. to which we are pointing.
   */
  const pageChange = (e, val) => {
    handlePageChange(e, val);
    SetGotoPage("");
  };

  /**
   * Handles the change of No. of items per page.
   * @function pageSizeChange - Performs the operation for change of No. of items per page.
   * @param {object} e - Pointer event
   */
  const pageSizeChange = (e) => {
    handlePageSizeChange(e);
    SetGotoPage("");
  };

  return (
    <Box
      data-testid="paginationWrapper"
      sx={{
        mt: { sm: 3, xs: 1 },
        pb: 2,
        display: "flex",
        justifyContent: { sm: "flex-end", xs: "space-between" },
        alignItems: { sm: "center", xs: "flex-start" },
        height: { sm: "50px", xs: "60px" },
        flexDirection: { xs: "row" }
      }}
    >
      <Select
        data-testid="pageSizeChange"
        inputProps={{
          "data-testid": "selectSearchInner"
        }}
        value={pageSize}
        onChange={(e) => pageSizeChange(e)}
        autoWidth
        sx={{
          fontFamily: "BukraMedium",
          fontSize: "13px",
          height: "35px",
          color: "#5C636F",
          letterSpacing: "0px",
          ml: 1
        }}
      >
        {pageSizes.map((size) => (
          <MenuItem key={size} value={size} data-testid="optionSize">
            {" "}
            {size}
          </MenuItem>
        ))}
      </Select>

      <Pagination
        data-testid="Pagination"
        className="my-3"
        count={totalCount}
        page={page}
        siblingCount={1}
        boundaryCount={1}
        shape="rounded"
        onChange={(e, value) => pageChange(e, value)}
        sx={{
          "& .MuiPaginationItem-root": {
            fontFamily: "BukraRegular",
            fontSize: "13px",
            color: "#777784",
            letterSpacing: "0px",
            "&.Mui-selected": {
              background: "#d4e1f6 0% 0% no-repeat padding-box",
              color: "#0F57CB"
            }
          }
        }}
      />
      <InputBase
        autoComplete="off"
        inputProps={{ "data-testid": "gotoPageElement" }}
        placeholder="Go to Page.."
        value={gotoPage}
        onChange={(e) => handleChangeGotoPage(e)}
        sx={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          border:
            gotoPage && gotoPage > totalCount
              ? "1px solid #DE0909"
              : "1px solid #c9cccf",
          borderRadius: "4px",
          width: "105px",
          height: "32px",
          color: "5B636F",
          fontFamily: "BukraRegular",
          fontSize: "13px",
          pl: 1,
          mr: 3,
          ml: 1
        }}
      ></InputBase>
      <button
        data-testid="buttonRef"
        ref={buttonRef}
        onClick={(e) => handleClickGotoPage(e)}
        style={{ display: "none" }}
      />
    </Box>
  );
};

export default CustomPagination;
