import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  ACCOUNTBlOCKED,
  ACCOUNTBLOCKED_SUB_HEADER_INITIAL,
  ACCOUNTLOCKED,
  ACCOUNTLOCKED_HEADER,
  ACCOUNTLOCKED_SUB_HEADER_INITIAL,
  RECOVER_YOUR_ACCOUNT
} from "./../../constant/Constants";
import arrow_back_black from "./../../assets/images/arrow_back_black.svg";
import Lock_Icon from "./../../assets/images/lock_icon_fill.svg";

/**
 * Renders the component for showing the Account locked Screen UI
 * @return {component} the Account locked screen UI Component
 */
const AccountLocked = () => {
  const location = useLocation();
  const parentEmail = location?.state?.parentUserEmail;
  const maxLoginRetryCountReached = location?.state?.maxLoginRetryCountReached;
  const navigate = useNavigate();
  return (
    <Paper
      elevation={2}
      data-testid="accountLocked"
      sx={{
        p: 2.1,
        height: "358px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: "4px",
        pb: 0,
        boxShadow: "0px 0px 14px #0000004D",
        maxWidth: { xs: "80%", sm: "384px" }
      }}
    >
      <Box>
        <img
          src={arrow_back_black}
          alt="left arrow"
          style={{
            height: "15px",
            width: "15px",
            cursor: "pointer"
          }}
          data-testid="backArrow"
          onClick={() => navigate(-1)}
        ></img>
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column"
          }}
        >
          <img src={Lock_Icon} alt="lock" height="50px"></img>
          <Typography
            sx={{
              fontFamily: "BukraMedium",
              textAlign: "center",
              fontSize: "16px",
              color: "#464E5F",
              pt: 3.3
            }}
          >
            {maxLoginRetryCountReached ? ACCOUNTLOCKED : ACCOUNTBlOCKED}
          </Typography>
          {maxLoginRetryCountReached && (
            <Typography
              sx={{
                fontFamily: "BukraMedium",
                textAlign: "center",
                fontSize: "16px",
                color: "#464E5F"
              }}
            >
              {ACCOUNTLOCKED_HEADER}
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: "12px",
              textAlign: "center",
              mt: 1,
              fontFamily: "BukraRegular",
              letterSpacing: "0px",
              color: "#000000"
            }}
          >
            {maxLoginRetryCountReached
              ? ACCOUNTLOCKED_SUB_HEADER_INITIAL
              : ACCOUNTBLOCKED_SUB_HEADER_INITIAL}{" "}
            {parentEmail ? parentEmail : ""} {RECOVER_YOUR_ACCOUNT}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default AccountLocked;
