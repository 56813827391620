import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Paper,
  Toolbar,
  Typography,
  Box,
  TextField,
  InputBase,
  FormHelperText,
  ListSubheader,
  Input,
  InputAdornment
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import {
  TRANSFER_INFORMATION,
  CONFIRM,
  NOTES,
  OPTIONAL,
  CREDITS,
  AMOUNT,
  REVERSE_FROM,
  REASON,
  ENTER_AMOUNT,
  REVERSE_CREDIT_KEY,
  TRANSACTION_REJECTED_STATUS,
  TRANSACTION_PENDING_STATUS,
  SELECT,
  TRANSACTION_FAILED,
  CREDIT_BALANCE_ERR_LABEL,
  REMAINING_BALANCE,
  MD_USER_ROLE_TYPE,
  SUB_DISTRIBUTOR,
  RETAILER,
  NO_RESULTS_FOUND,
  ACTIVE,
  REVERSE_TRANSFER_CREDIT_AWAITING_APPROVAL,
  MAX_NOTES_LENGTH
} from "./../../constant/Constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./ReverseTransferInformation.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getChildChannelPartners,
  getChildDetails,
  getCreditBalance,
  transferCredit
} from "../../services/user.services";
import {
  getChannelPartnerCode,
  getUserRoleType
} from "../../stateManagement/reducers/LoginSlice";
import {
  allowNumberandDecimals,
  convertStringToNumber,
  currencyConvertToReadable,
  failureToastMessage,
  handleMouseDownOnAndornment,
  awaitToastMessage,
  transferReasonForRetailer,
  transferReasonForSubDistributor,
  updatePageVisit
} from "../../constant/Utils";
import CustomPasscodeVerification from "../customPasscodeVerification/CustomPasscodeVerification";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { setCreditsTransferStatus } from "../../stateManagement/reducers/CreditsTransferStatusSlice";
import PasscodeError from "../passcodeError/PasscodeError";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import searchIcon from "../../assets/images/search.svg";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#F8F8F8 0% 0% no-repeat padding-box",
          "&.MuiMenu-paper": {
            marginTop: "5px"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#202020",
          fontSize: "14px",
          letterSpacing: "0px",
          ":focus": {
            background: "#EDF4F6 0% 0% no-repeat padding-box",
            color: "#202020",
            opacity: 1
          }
        }
      }
    }
  }
});
const multilineInputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "14px",
          minHeight: "80px",
          letterSpacing: "0px"
        }
      }
    }
  }
});

/**
 * Renders the Reverse trnasfer form Component to Perform the reverse transactions.
 * @returns {component} the Reverse Transfer Form Component UI.
 */
const ReverseTransferInformation = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const location = useLocation();

  /**
   * @constant {string} childChannelPartnerCode Returns the Child ChannelPartner code from the localstorage.
   */
  const childChannelPartnerCode = location?.state?.channelPartnerCode
    ? location.state.channelPartnerCode
    : "";

  const [reason, setReason] = useState("");
  const [creditAmount, setCreditAmount] = useState("");
  const [describeNote, setDescribeNote] = useState("");
  const [creditBalance, setCreditBalance] = useState("");
  const [noBalanceMsg, setNoBalanceMsg] = useState("");
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [showPasscodePop, setshowPasscodePop] = useState(false);
  const [showNoAttemptsModal, setShowNoAttemptsModal] = useState(false);
  const [creditAmountFocus, setCreditAmountFocus] = useState(false);
  const [childChannelPartnerList, setChildChannelPartnerList] = useState([]);
  const [parentEmail, setParentEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const [selectedChildChannelPartnerCode, setSelectedChildChannelPartnerCode] =
    useState("");
  const [childChannelPartnerUserGroup, setChildChannelPartnerUserGroup] =
    useState("");
  const [searchText, setSearchText] = useState("");
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const config = useSelector((state) => getConfigDetails(state));
  /**
   * @constant {string} childChannelPartnerLabel Fetches the child channel partner type label
   */
  const childChannelPartnerLabel =
    fetchUserRoleType === MD_USER_ROLE_TYPE ? SUB_DISTRIBUTOR : RETAILER;

  /**
   *@constant {string} transferReason  Fetches the child channel partner type.
   */
  const transferReason =
    fetchUserRoleType === MD_USER_ROLE_TYPE
      ? transferReasonForSubDistributor
      : transferReasonForRetailer;

  /**
   * Sets the ChildChannelPartner List for the Loddeg in user.
   */
  useEffect(() => {
    if (config.server) {
      getChildChannelPartners(config.server).then((res) => {
        if (res && res.status === 200) {
          setChildChannelPartnerList(res.data);
        } else {
          setChildChannelPartnerList([]);
        }
      });

      /**
       * Gets the Details of the Selected Child channel Partner. and sets the selected Child ChannelPartner code and it's user group.
       */
      childChannelPartnerCode &&
        getChildDetails(config.server, childChannelPartnerCode).then((res) => {
          if (res && res.status && res.status === 200) {
            setSelectedChildChannelPartnerCode(res.data.channelPartnerCode);
            setChildChannelPartnerUserGroup(res.data.channelPartnerType);
          } else {
            setSelectedChildChannelPartnerCode("");
            setChildChannelPartnerUserGroup("");
          }
        });
    }
  }, [childChannelPartnerCode, config.server]);

  /**
   * Gets the Credit Balance of the Selected child Channel Partner.
   */
  useEffect(() => {
    if (selectedChildChannelPartnerCode && config.server) {
      getCreditBalance(
        config.server,
        selectedChildChannelPartnerCode,
        REVERSE_CREDIT_KEY
      )
        .then((res) => {
          if (res && res.status === 200) {
            setCreditBalance(res.data);
            setNoBalanceMsg("");
          } else {
            setCreditBalance("");
            setNoBalanceMsg(res.data.message);
          }
        })
        .catch(() => {
          setCreditBalance("");
        });
    }
  }, [childChannelPartnerList, selectedChildChannelPartnerCode, config.server]);

  /**
   * Gets the remaining balance on typing the amount in Credit Amount input label.
   */
  useEffect(() => {
    const totalbalance =
      creditBalance && convertStringToNumber(creditBalance.balanceCredits);
    if (creditAmount) {
      setRemainingBalance(totalbalance - convertStringToNumber(creditAmount));
    } else {
      setRemainingBalance(totalbalance);
    }
  }, [creditBalance, creditAmount]);

  const channelPartnerCodeStore = useSelector((state) =>
    getChannelPartnerCode(state)
  );

  /**
   * Updates the Page visits info.
   */
  useEffect(() => {
    if (location.pathname && channelPartnerCodeStore) {
      updatePageVisit(channelPartnerCodeStore, location.pathname);
    }
  }, [location.pathname, channelPartnerCodeStore]);

  /**
   * Handle the Reason change operation.
   * @function handleReasonChange - Performs action on change of reason select option.
   * @param {object} event - Reason change select option event object.
   */
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  /**
   * Handles the Credit Amount change operation
   * @function handleCreditAmount - Performs action on credit Amount change.
   * @param {object} e  - Credit Amount input event object
   */
  const handleCreditAmount = (e) => {
    const { value } = e.target;
    if (allowNumberandDecimals(value) || value === "") {
      setCreditAmount(value);
    }
  };

  /**
   * Handles the Notes Change operation.
   * @function handleDescriptionChange - Performs action on Notes input field change.
   * @param {object} e - Notes input event object
   */
  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescribeNote(value);
  };

  /**
   * Handles the Status of Confirm button in Reverse credits Transfer page.
   * @function isConfirmDisabled - Performs action to enable or disable the confirm button in Reverse credits transfer.
   * @returns {boolean} - true or false to whether enable or disable the Confirm button
   */
  const isConfirmDisabled = () => {
    let buttonEnable = true;
    if (
      creditAmount &&
      remainingBalance >= 0 &&
      Number(creditAmount) > 0 &&
      reason
    ) {
      buttonEnable = false;
    }
    return buttonEnable;
  };

  /**
   * Habndles the Operation on CLicking the confirm button
   * @function handleSubmitTransfer - Opens up the Passcode Verification Modal.
   */
  const handleSubmitTransfer = () => {
    setshowPasscodePop(true);
  };

  /**
   * Handles the Popup Close operation.
   * @function handleClosePopup - Closes all the Popups.
   */
  const handleClosePopup = () => {
    setshowPasscodePop(false);
    setShowNoAttemptsModal(false);
  };

  /**
   * Handles the display of the Attempt exhause modal.
   * Open the the Passcode Attempt exhause Modal box.
   */
  const handleOpenNoAttemptsModal = (value) => {
    setParentEmail(value);
    setShowNoAttemptsModal(true);
  };

  /**
   * Handles the toast message when the Credits Transfer is success for the Reverse Transfer
   * @function handleCreditTransferSuccess - shows the Success Toast message
   */
  const handleCreditTransferSuccess = () => {
    let toastDetails = awaitToastMessage(
      REVERSE_TRANSFER_CREDIT_AWAITING_APPROVAL
    );
    let transferDetails = {
      transactionStatus: TRANSACTION_PENDING_STATUS,
      statusValue: 1
    };
    dispatch(updateToastMessage(toastDetails));
    dispatch(setCreditsTransferStatus(transferDetails));
    navigateTo("/credits");
  };

  /**
   * Handles the toast message when the Credits Transfer is failed for the Reverse Transfer
   * @function handleCreditTransferFail - shows the Failed Toast message
   */
  const handleCreditTransferFail = (message) => {
    let toastDetails = failureToastMessage(message, "");
    let transferDetails = {
      transactionStatus: TRANSACTION_REJECTED_STATUS,
      statusValue: 2
    };
    dispatch(updateToastMessage(toastDetails));
    dispatch(setCreditsTransferStatus(transferDetails));
    navigateTo("/credits");
  };

  /**
   * Handles the Reverse credit Transfer form submission
   * @function handleConfirmTransfer - Performs action after entering the Passcode input field.
   * @param {string} passCode - Passcode.
   */
  const handleConfirmTransfer = (passCode) => {
    setLoader(true);
    const reverseTranferDetails = {
      passcode: passCode,
      channelPartnerCreditTransfer: [
        {
          fromUser: selectedChildChannelPartnerCode,
          fromUserGroup: childChannelPartnerUserGroup,
          creditsAmount: creditAmount,
          currency: country.countryCurrencyCode,
          transferNotes: null,
          reverserCreditTransferReason: describeNote,
          transferType: REVERSE_CREDIT_KEY,
          reason: reason
        }
      ]
    };

    transferCredit(config.server, reverseTranferDetails)
      .then((res) => {
        if (res && res.status === 200) {
          handleCreditTransferSuccess();
        } else if (res && res.status === 207) {
          const errorResponse =
            res.data && res.data.creditFailureResponseList[0];
          handleCreditTransferFail(errorResponse.message);
        } else {
          const error = res.data && res.data.creditFailureResponseList[0];
          const message =
            error && error.message ? error.message : TRANSACTION_FAILED;
          handleCreditTransferFail(message);
        }
      })
      .catch(() => {
        handleCreditTransferFail(TRANSACTION_FAILED);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  /**
   * @function creditAmountBorderColor - Changes the Credits Amount input field border color based on the Validation.
   * @returns {style} border color for the Credits Amount input field based on the Credits Amount Validation.
   */
  const creditAmountBorderColor = () => {
    let borderColor = "#e8e8e8";
    if (remainingBalance < 0 && creditBalance.balanceCredits) {
      borderColor = "#DE0909";
    } else if (creditAmountFocus) {
      borderColor = "#0f57cb";
    }
    return `1px solid ${borderColor}`;
  };

  /**
   * Handles the focus on the Credit Amount input field.
   * @function handleFocusOnCreditAmount - Sets/Unsets the focus on credits Amount input field
   */
  const handleFocusOnCreditAmount = () => {
    setCreditAmountFocus(!creditAmountFocus);
  };
  const currencySymbol = country && country.countryCurrencyCode;

  const name = creditBalance.channelPartnerName
    ? `of ${creditBalance.channelPartnerName}`
    : "";
  const getCreditAmountHelpertext = () => {
    return remainingBalance < 0
      ? CREDIT_BALANCE_ERR_LABEL
      : `${REMAINING_BALANCE} ${name}: ${currencySymbol} 
${currencyConvertToReadable(remainingBalance)}`;
  };

  /**
   * Provides the Select Channel Partner Code's Label Name in Select options.
   * @function getLabel - Gets the formatted label for the Child channel partner select option.
   */
  const getLabel = useCallback(
    (value) => {
      const distributor =
        childChannelPartnerList &&
        childChannelPartnerList.length > 0 &&
        childChannelPartnerList.find(
          (channelPartnerDistributor) =>
            channelPartnerDistributor.channelPartnerCode === value
        );
      return distributor
        ? `${distributor.channelPartnerName} [${distributor.companyName}]`
        : "";
    },
    [childChannelPartnerList]
  );

  /**
   * Handles the Select Dropdown search functionality
   */
  const containsText = useCallback(
    (text, searchTextValue) =>
      getLabel(text).toLowerCase().includes(searchTextValue.toLowerCase()),
    [getLabel]
  );

  /**
   * Displays the options based on the result of the search Input string.
   */
  const displayedOptions = useMemo(
    () =>
      childChannelPartnerList &&
      childChannelPartnerList.length > 0 &&
      childChannelPartnerList.filter((option) =>
        containsText(option.channelPartnerCode, searchText)
      ),
    [childChannelPartnerList, searchText, containsText]
  );

  /**
   * Handle the Change of Child Channel Partner Code and it's User group based on the Selected input.
   * @function handleChildChannelPartnerChange - Performs action on Child channel partner select option change.
   * @param {object} e
   */
  const handleChildChannelPartnerChange = (e) => {
    setSelectedChildChannelPartnerCode(e.target.value);
    setChildChannelPartnerUserGroup(getUserGroup(e.target.value));
  };

  /**
   * Handles the Operation to get the User group of the Child channel Partner
   * @param {string} distributor - Child Channel Partner Code
   * @returns {number} user Group of the Child Channel Partner selected.
   */
  const getUserGroup = (distributor) => {
    const selectedDistributor =
      childChannelPartnerList &&
      childChannelPartnerList.length > 0 &&
      childChannelPartnerList.find(
        (selected) => selected.channelPartnerCode === distributor
      );
    return selectedDistributor.channelPartnerType;
  };
  return (
    <div
      style={{ paddingBottom: "80px" }}
      data-testid="reverseTransferWrapper"
      className="reverseTransferInformation"
    >
      <Paper elevation={2} sx={{ p: 1, pb: 5 }}>
        {loader && <OverlayLoader />}
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", sm: "center" },
            flexDirection: { xs: "column", sm: "row" }
          }}
        >
          <Typography
            sx={{
              color: "#464E5F",
              letterSpacing: "0px",
              fontSize: "16px",
              fontFamily: "BukraMedium"
            }}
          >
            {TRANSFER_INFORMATION}
          </Typography>
        </Toolbar>
        <Box
          sx={{
            ml: { sm: 7.8, xs: 2 },
            mt: { sm: 0, xs: 1 },
            mr: { sm: 3 }
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 16 }}
            sx={{
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{ minWidth: 120, display: "flex", flexDirection: "column" }}
              >
                <label className="inputLabel">{REVERSE_FROM}</label>

                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      mt: 1.5,
                      height: "44px",
                      border: "1px solid #e8e8e8",
                      borderRadius: "4px"
                    }}
                  >
                    <Select
                      MenuProps={{
                        autoFocus: false,
                        PaperProps: {
                          style: {
                            maxHeight: 155,
                            overflow: "auto",
                            scrollbarWidth: "thin"
                          }
                        },
                        MenuListProps: {
                          style: {
                            paddingTop: 0
                          }
                        }
                      }}
                      inputProps={{ "data-testid": "transferTo" }}
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="fromUser"
                      value={selectedChildChannelPartnerCode}
                      onChange={(e) => handleChildChannelPartnerChange(e)}
                      onClose={() => setSearchText("")}
                      displayEmpty
                      renderValue={(transferId) => {
                        if (!transferId) {
                          return (
                            <Typography
                              sx={{
                                letterSpacingpacing: "0px",
                                color: "#282828",
                                opacity: 0.5,
                                fontSize: "14px",
                                fontFamily: "BukraRegular"
                              }}
                            >
                              {`${SELECT} ${childChannelPartnerLabel}`}
                            </Typography>
                          );
                        }
                        return getLabel(transferId);
                      }}
                      sx={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#edf4f6",
                        fontFamily: "BukraRegular",
                        ".MuiSelect-select": {
                          fontSize: "14px",
                          color: "#0F0F10",
                          fontFamily: "BukraRegular"
                        }
                      }}
                    >
                      <ListSubheader sx={{ p: "4px " }}>
                        <Input
                          autoComplete="off"
                          placeholder="Type to search..."
                          autoFocus
                          sx={{
                            width: "100%",
                            maxWidth: "95%",
                            m: 1,
                            p: "4px"
                          }}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              sx={{ cursor: "default" }}
                            >
                              <img
                                src={searchIcon}
                                alt="Search Icon"
                                onMouseDown={handleMouseDownOnAndornment}
                              ></img>
                            </InputAdornment>
                          }
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        ></Input>
                      </ListSubheader>
                      {displayedOptions && displayedOptions.length > 0 ? (
                        displayedOptions.map((distributor) => {
                          return (
                            <MenuItem
                              key={distributor.channelPartnerCode}
                              value={distributor.channelPartnerCode}
                              sx={{
                                ...muiMenuItemStyles
                              }}
                            >
                              {getLabel(distributor.channelPartnerCode)}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <Typography
                          disabled
                          sx={{
                            background: "white",
                            px: 2,
                            fontSize: "12px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          {NO_RESULTS_FOUND}
                        </Typography>
                      )}
                    </Select>
                  </Box>
                </ThemeProvider>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <label className="inputLabel">
                  {CREDITS} {AMOUNT} <span className="asterisk">*</span>
                </label>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "4px",
                    background: " #EDF4F6 0% 0% no-repeat padding-box",
                    height: "40px",
                    width: { sm: "100%" },
                    mt: 1.5,
                    border: creditAmountBorderColor()
                  }}
                >
                  <Box
                    sx={{
                      height: "inherit",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80px",
                      color: "#282828",
                      fontSize: "13px",
                      fontFamily: "BukraRegular",
                      background: " #7B7D801A 0% 0% no-repeat padding-box"
                    }}
                  >
                    {currencySymbol}
                  </Box>
                  <ThemeProvider theme={inputTheme}>
                    <InputBase
                      sx={{ ml: 1, flex: 1, pt: 0.5 }}
                      autoComplete="off"
                      placeholder={ENTER_AMOUNT}
                      value={creditAmount}
                      onFocus={() => handleFocusOnCreditAmount()}
                      onBlur={() => handleFocusOnCreditAmount()}
                      onChange={(e) => handleCreditAmount(e)}
                      inputProps={{
                        "data-testid": "creditsAmount"
                      }}
                    />
                  </ThemeProvider>
                </Box>
                {creditBalance.balanceCredits >= 0 &&
                  creditBalance.userStatus === ACTIVE && (
                    <FormHelperText
                      data-testid="errorBalance"
                      sx={{
                        color: remainingBalance < 0 ? "#DE0909" : "#464E5F",
                        textAlign: "left"
                      }}
                      className="errorFormHelperText"
                    >
                      {getCreditAmountHelpertext()}
                    </FormHelperText>
                  )}
                {noBalanceMsg && (
                  <FormHelperText
                    data-testid="errorBalance"
                    sx={{
                      color: "#DE0909",
                      textAlign: "left"
                    }}
                    className="errorFormHelperText"
                  >
                    {noBalanceMsg}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <label className="inputLabel">
                {NOTES} ({OPTIONAL})
              </label>
              <ThemeProvider theme={multilineInputTheme}>
                <TextField
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  inputProps={{
                    "data-testid": "inputNote",
                    maxLength: MAX_NOTES_LENGTH
                  }}
                  size="medium"
                  multiline
                  name="text"
                  rows={3}
                  variant="outlined"
                  fullWidth
                  value={describeNote}
                  onChange={(e) => handleDescriptionChange(e)}
                  sx={{
                    mt: 1.5,
                    background: "#EDF4F6 0% 0% no-repeat padding-box",
                    fontSize: "14px"
                  }}
                ></TextField>
              </ThemeProvider>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mt: 2 }}>
              <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                <label htmlFor="demo-select-small" className="inputLabel">
                  {REASON} <span className="asterisk">*</span>
                </label>
                <ThemeProvider theme={theme}>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={reason}
                    displayEmpty
                    inputProps={{
                      "data-testid": "reason"
                    }}
                    onChange={handleReasonChange}
                    renderValue={(reasonValue) =>
                      reasonValue ? (
                        <Typography
                          sx={{
                            letterSpacingpacing: "0px",
                            color: "#0F0F10",
                            opacity: 1,
                            fontSize: "14px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          {reasonValue}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            letterSpacingpacing: "0px",
                            color: "#0F0F10",
                            opacity: 0.5,
                            fontSize: "14px",
                            fontFamily: "BukraRegular"
                          }}
                        >
                          {SELECT}
                        </Typography>
                      )
                    }
                    sx={{
                      height: "44px",
                      mt: 1.5,
                      background: "#EDF4F6 0% 0% no-repeat padding-box",
                      fontFamily: "BukraRegular",
                      ".MuiSelect-select": {
                        fontSize: "14px",
                        color: "#0F0F10",
                        fontFamily: "BukraRegular"
                      }
                    }}
                  >
                    {transferReason.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{
                          ...muiMenuItemStyles
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </ThemeProvider>
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5
            }}
          >
            <Button
              variant="outlined"
              disabled={isConfirmDisabled()}
              onClick={handleSubmitTransfer}
              data-testid="confirm"
              sx={{
                background:
                  "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                color: "#ffffff",
                "&.Mui-disabled": {
                  background:
                    "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                  color: "#ffffff",
                  opacity: 0.5
                },
                borderRadius: "20px",
                letterSpacing: "0px",
                fontFamily: "BukraMedium",
                fontSize: "15px",
                width: { sm: "160px", xs: "100%" },
                textTransform: "none",
                height: "40px",
                mt: { sm: 0, xs: 1 }
              }}
            >
              {CONFIRM}
            </Button>
          </Box>
        </Box>
      </Paper>
      {showPasscodePop && (
        <CustomPasscodeVerification
          showPasscodeModal={showPasscodePop}
          handleClosePasscodeVerification={handleClosePopup}
          handleConfirmTransfer={handleConfirmTransfer}
          handleOpenNoAttempts={handleOpenNoAttemptsModal}
        ></CustomPasscodeVerification>
      )}
      {showNoAttemptsModal && (
        <PasscodeError
          parentEmail={parentEmail}
          handleCloseNoAttempts={handleClosePopup}
        />
      )}
    </div>
  );
};

export default ReverseTransferInformation;
