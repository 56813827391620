import React from "react";

/**
 * Renders Child Component.
 * @param {object} props - Props for rendering child component.
 * @returns {component} - Child Component
 */
const RenderChildComponent = (props) => {
  const { children, isChildPresent } = props;
  return <>{!isChildPresent && children}</>;
};

export default RenderChildComponent;
