import { createSlice } from "@reduxjs/toolkit";
import { getCountryDetails } from "../../services/user.services";

const initialState = {};

/**
 * Saves the Country Details into the Store.
 */
const CountryDetailsSlice = createSlice({
  name: "countryDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(getCountryDetails.fulfilled, (state, { payload }) => {
      return { ...state, ...payload.data };
    });
  }
});

/**
 * Fetches the Country Details from the Store.
 * @param {object} state - Store State.
 * @returns {object} - Country Details.
 */
export const getCountryDetailsFromStore = (state) => {
  return state.countryDetails;
};

export default CountryDetailsSlice.reducer;
