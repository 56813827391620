import React, { memo, useEffect, useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import Box from "@mui/material/Box";
import calendarBlueIcon from "./../../assets/images/calendarBlueIcon.svg";
import { getThisMonth, getToday } from "../../constant/Utils";
import { CUSTOM_FILTER_MIN_DATE } from "../../constant/Constants";

/**
 * Renders the UI for the Date Range.
 * @param {object} props - Prop elements passed to the MultiRange Date Picker to set the UI based on start and end date.
 * @returns {component} - the UI for the Start date and End Date
 */
const MultiRangeDatePicker = (props) => {
  const today = getToday();
  const currentMonthStartDate = getThisMonth();
  const [value, setValue] = useState([currentMonthStartDate, today]);
  const datePickerRef = useRef();
  useEffect(() => {}, [value]);
  return (
    <Box
      data-testid="multiRangeDatePicker"
      sx={{
        display: "flex",
        border: "1px solid #464E5F",
        borderRadius: "4px",
        alignItems: "center",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        pr: 1,
        pl: 1,
        ml: { sm: 1, xs: 0 },
        justifyContent: "space-between",
        height: "30px",
        width: { xs: "100%", lg: "210px" }
      }}
    >
      <DatePicker
        ref={datePickerRef}
        data-testid="datePickerpack"
        placeholder="Select Start- End Date"
        range
        value={value}
        format="DD-MM-YYYY"
        onChange={(dateObjects) => {
          setValue(dateObjects);
          props.dateFromParent(dateObjects);
        }}
        style={{
          border: "none",
          paddingLeft: "3px",
          paddingRight: "3px",
          boxShadow: "none",
          color: "#1E1E1E",
          fontSize: "13px",
          fontFamily: "BukraRegular",
          width: "190px"
        }}
        minDate={CUSTOM_FILTER_MIN_DATE}
        maxDate={new Date()}
      ></DatePicker>
      <img
        data-testid="datePicker"
        src={calendarBlueIcon}
        alt="date"
        height="15px"
        style={{
          cursor: "pointer"
        }}
        onClick={() => datePickerRef.current.openCalendar()}
      ></img>
    </Box>
  );
};

export default memo(MultiRangeDatePicker);
