import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

/**
 * Renders the Overlay Loader UI Component.
 * @returns {JSX} the Overlay loader UI component.
 */
const OverlayLoader = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default OverlayLoader;
