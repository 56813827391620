import {
  Box,
  TextField,
  Typography,
  Stack,
  Button,
  FormHelperText,
  Dialog
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DatePicker from "react-multi-date-picker";
import {
  CANCEL,
  NOTES,
  NO_CONTENT,
  OPTIONAL,
  TOAST_ERROR,
  TOAST_SUCCESS,
  DATE_FORMAT,
  STB_ADDED_SUCCESSFULLY,
  SOMETHING_WENT_WRONG,
  STB,
  CONFIRM_STB,
  COFIRM_STB_MISMATCH,
  STB_MAX_LENGTH,
  SAVE,
  MAX_LENGTH_50,
  OTP_GENERATED_SUCCESS_DESCRIPTION,
  INVALID_OTP,
  RESEND_CODE_TIME
} from "../../constant/Constants";
import "./HardwareAllocation.scss";
import {
  allocateSTB,
  generateCustomerOtpDetails,
  validateCustomerOtpDetails
} from "../../services/user.services";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomMultiPopup from "../customMultiPopup/CustomMultiPopup";
import { useNavigate } from "react-router-dom";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import {
  convertMStoS,
  failureToastMessage,
  successToastMessage
} from "../../constant/Utils";
import VerificationBox from "../verificationBox/VerificationBox";
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#202020",
          fontSize: "14px",
          letterSpacing: "0px",
          background: "#EDF4F6 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          ":focus": {
            background: "#EDF4F6 0% 0% no-repeat padding-box",
            color: "#202020",
            opacity: 1
          }
        }
      }
    }
  }
});

/**
 * Hardware Allocation Component: renders the Hardware Allocation Tab
 * @param {{customerInfo:object, resetCustomer:function}} {customerInfo,resetCustomer} - Props Passed to Hardware Allocation component to perform the required operation.
 * @returns {component} the Hardware Allocation tab UI component containing the Form for Hardware Allocation.
 */
const HardwareAllocation = ({ customerInfo, resetCustomer }) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [newSTBValue, setNewSTBValue] = useState("");
  const [confirmNewSTBValue, setConfirmNewSTBValue] = useState("");
  const [date, setDate] = useState(new Date());
  const [notes, setNotes] = useState("");
  const [multiPopup, setMultiPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");
  const [showErrorPop, setShowErrorPop] = useState(false);
  const [expireTime, setExpireTime] = useState();
  const [isSaveBtnEnable, setIsSaveBtnEnable] = useState(true);
  const config = useSelector((state) => getConfigDetails(state));
  const [multiResponse, setMultiResponse] = useState({});

  const mobilePayload = {
    mobileNumber: customerInfo ? `${customerInfo.mobileNumber}`.trim() : ""
  };
  /**
   * Handles the STB Chips insertion operation.
   * @function handleSTBInputChange - Handle the input of STB chips.
   * @param {{target:object}} target - Targeted input value.
   */
  const handleSTBInputChange = (e) => {
    const { value } = e.target;
    const trimedInputValue = value.trim();
    setNewSTBValue(trimedInputValue);
  };
  /**
   * Handles the New STB input change.
   * @function handleConfirmNewSTBInputChange - Performs action one New STB input change.
   * @param {object} e - New STB input pointer event
   */
  const handleConfirmNewSTBInputChange = (e) => {
    const { value } = e.target;
    const trimedInputValue = value.trim();
    setConfirmNewSTBValue(trimedInputValue);
  };

  useEffect(() => {
    handleCancel();
  }, [customerInfo]);

  /**
   * Handles Resetting the Hardware Allocation form elements.
   * @function handleCancel - Cancel and reset the form element for hardware allocation.
   */
  const handleCancel = () => {
    setDate(new Date());
    setNewSTBValue("");
    setConfirmNewSTBValue("");
    setNotes("");
    setIsSaveBtnEnable(true);
  };

  /**
   * Handles the Save button's UI for enable or disable.
   * @function isSaveDisable - checks whether to Disable the 'Save' button based on the data filled.
   * @returns true or false
   */
  const isSaveDisable = () => {
    const isValid =
      isSaveBtnEnable &&
      customerInfo &&
      customerInfo.customerId &&
      date !== "" &&
      newSTBValue !== "" &&
      newSTBValue === confirmNewSTBValue
        ? false
        : true;
    return isValid;
  };

  /**
   * Handles the Allocation Operation of STB.
   * @function handleAllocateSTB - Allocate STB on click of the Save button.
   */
  const handleAllocateSTB = () => {
    const stbAllocationPayload = {
      customerId: customerInfo.customerId,
      serialNumberList: [newSTBValue],
      notes: notes.trim()
    };
    allocateSTB(config.server, stbAllocationPayload)
      .then((res) => {
        const defaultMessage = `${STB_ADDED_SUCCESSFULLY} ${customerInfo.customerId}`;
        if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
          const message = defaultMessage;
          let toastDetails = {
            toastTitle: message,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_SUCCESS,
            toastTextclr: "#238931",
            toastBgclr: "#E6FFEE ",
            toastBorderclr: "#50B768"
          };
          dispatch(updateToastMessage(toastDetails));
          resetCustomer();
          navigateTo("/customer/subscribenow");
        } else if (res && res.status === 207) {
          if (res.data.errors.length === 0 && res.data.success.length > 0) {
            const message = res.data.success
              ? res.data.success[0].message
              : defaultMessage;
            let toastDetails = {
              toastTitle: message,
              isToastOpen: true,
              toastDescription: "",
              toastStatus: TOAST_SUCCESS,
              toastTextclr: "#238931",
              toastBgclr: "#E6FFEE ",
              toastBorderclr: "#50B768"
            };
            dispatch(updateToastMessage(toastDetails));
            resetCustomer();
            navigateTo("/customer/subscribenow");
          } else if (
            res.data &&
            res.data.errors.length === 1 &&
            res.data.success.length === 0
          ) {
            const message = res.data.errors[0]
              ? res.data.errors[0].message
              : SOMETHING_WENT_WRONG;
            let toastDetails = failureToastMessage(message, "");
            dispatch(updateToastMessage(toastDetails));
          } else {
            setMultiResponse(res.data);
            setMultiPopup(true);
          }
        } else {
          const message = res.data.message
            ? res.data.message
            : SOMETHING_WENT_WRONG;
          let toastDetails = {
            toastTitle: message,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_ERROR,
            toastTextclr: "#FF2A2A",
            toastBgclr: "#FDE2E2 ",
            toastBorderclr: "#FF2A2A"
          };
          dispatch(updateToastMessage(toastDetails));
        }
      })
      .catch((err) => err)
      .finally(() => {
        setIsSaveBtnEnable(true);
        setLoader(false);
      });
  };

  /**
   * Close the Error Popup on STB allocation failure Message Close icon click.
   * @function closeMultiPopup - Performs the action to close the MultiPopup Error Dialog Box .
   */
  const closeMultiPopup = () => {
    setMultiPopup(false);
  };
  /**
   * Handles the mobile Verification.
   * @function handleVerifyMobileNumber - Verify the Entered Mobile Number
   */
  const handleVerifyMobileNumber = () => {
    setIsSaveBtnEnable(false);
    generateCustomerOtpDetails(config.server, mobilePayload).then(
      (response) => {
        if (response && response.status === 200) {
          let toastDetails = successToastMessage(
            OTP_GENERATED_SUCCESS_DESCRIPTION
          );
          dispatch(updateToastMessage(toastDetails));
          setExpireTime(convertMStoS(response.data.validFor));
          setOpenPopup(true);
        } else {
          const message =
            response && response.data.message
              ? response.data.message
              : SOMETHING_WENT_WRONG;
          let toastDetails = failureToastMessage(message, "");
          dispatch(updateToastMessage(toastDetails));
          setIsSaveBtnEnable(true);
        }
      }
    );
  };

  /**
   * Handles the Toast Message display
   * @function showToastCallback - Performs action to hide toast message.
   */
  const showToastCallback = () => {
    setShowErrorPop(false);
  };

  /**
   * Handles the display of the toast Message.
   * @function showPopup - Display the Popup element.
   * @param {string} message - Error nessage
   */
  const showPopup = (message) => {
    setShowErrorPop(true);
    setErrorInfo(message ? message : SOMETHING_WENT_WRONG);
  };

  /**
   * Handles the close operation of Dialog box.
   * @function closeDialog - Close the Dialog Box Popup.
   */
  const closeDialog = () => {
    setShowErrorPop(false);
    setOpenPopup(false);
    setIsSaveBtnEnable(true);
  };
  /**
   * Handles the OTP Verification
   * @function handleConfirmVerification - Handles the OTP Verification Operation.
   * @param {string} value - Mobile OTP
   */
  const handleConfirmVerification = (value) => {
    const userData = {
      mobileNumber: `${customerInfo.mobileNumber}`,
      otp: value.trim()
    };
    validateCustomerOtpDetails(config.server, userData).then((response) => {
      if (response.status === 200) {
        setOpenPopup((prev) => !prev);
        handleAllocateSTB();
      } else {
        const message =
          response && response.data.otpValidationCurrentAttempt
            ? INVALID_OTP
            : response.data.message;
        showPopup(message);
      }
    });
  };

  return (
    <Box
      className="hardwareAllocation_Wrapper"
      data-testid="hardwareAllocation"
    >
      {loader && <OverlayLoader />}

      <ThemeProvider theme={inputTheme}>
        <Box
          sx={{
            mx: { lg: 7.5, xs: 1.5 },
            pt: 4,
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
                width: { lg: "400px", xl: "500px" }
              }}
            >
              <label className="inputLabel">
                Date <span className="asterisk">*</span>
              </label>
              <DatePicker
                inputClass="allocation-date-input"
                value={date || ""}
                placeholder="Enter Date"
                format={DATE_FORMAT}
                disabled
                disableToolbar
                sx={{ height: "41px" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
                pt: { xs: 3 }
              }}
            >
              <label className="inputLabel">
                {STB} <span className="asterisk">*</span>
              </label>
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "stbInput",
                  height: "32px",
                  padding: "8px",
                  maxLength: STB_MAX_LENGTH
                }}
                variant="outlined"
                value={newSTBValue}
                onChange={(e) => handleSTBInputChange(e)}
                sx={{
                  width: { lg: "400px", xl: "500px" },
                  ".MuiInputBase-root": {
                    height: "44px",
                    border: "none",
                    flexWrap: "wrap",
                    ".MuiOutlinedInput-input": {
                      p: 0,
                      px: 1
                    }
                  }
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
                pt: { xs: 3 }
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#8F8F8F",
                  fontFamily: "BukraRegular",
                  letterSpacing: 0,
                  mb: "15px"
                }}
              >
                {CONFIRM_STB} <span className="asterisk">*</span>
              </Typography>
              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "stbconfirmInput",
                  height: "32px",
                  padding: "8px",
                  maxLength: STB_MAX_LENGTH
                }}
                variant="outlined"
                value={confirmNewSTBValue}
                onChange={(e) => handleConfirmNewSTBInputChange(e)}
                sx={{
                  width: { lg: "400px", xl: "500px" },
                  ".MuiInputBase-root": {
                    height: "44px",
                    border: "none",
                    flexWrap: "wrap",
                    ".MuiOutlinedInput-input": {
                      p: 0,
                      px: 1
                    }
                  }
                }}
              />

              {newSTBValue &&
                confirmNewSTBValue &&
                newSTBValue !== confirmNewSTBValue && (
                  <FormHelperText
                    className="errorFormHelperText"
                    sx={{ color: "#DE0909", textAlign: "left" }}
                  >
                    {COFIRM_STB_MISMATCH}
                  </FormHelperText>
                )}
            </Box>
          </Box>
          <Box
            sx={{
              pt: { xs: 3, lg: 0 },
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { lg: "400px", xl: "500px" }
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#8F8F8F",
                  fontFamily: "BukraRegular",
                  mb: "15px",
                  letterSpacing: 0
                }}
              >
                {NOTES} ({OPTIONAL})
              </Typography>

              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "setNotes",
                  maxLength: MAX_LENGTH_50
                }}
                size="medium"
                multiline
                name="multline"
                rows={3}
                variant="outlined"
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px",
                  minHeight: "80px",
                  ".MuiInputBase-root": {
                    minHeight: "80px",
                    width: "inherit"
                  }
                }}
              ></TextField>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="flex-end"
        sx={{ pt: 5, pb: 2, mx: { lg: 7.5, xs: 1.5 } }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: "20px",
            borderColor: "#4079d5",
            fontSize: "15px",
            textTransform: "none",
            fontFamily: "BukraRegular",
            letterSpacing: 0,
            color: "#4079d5",
            minWidth: "175px"
          }}
          onClick={handleCancel}
        >
          {CANCEL}
        </Button>
        <Button
          data-testid="saveButton"
          variant="outlined"
          sx={{
            borderRadius: "20px",
            fontSize: "15px",
            textTransform: "none",
            fontFamily: "BukraRegular",
            letterSpacing: 0,
            background:
              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
            color: "#ffffff",
            "&.Mui-disabled": {
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              opacity: 0.5
            },
            minWidth: "175px"
          }}
          disabled={isSaveDisable()}
          onClick={() => handleVerifyMobileNumber()}
        >
          {SAVE}
        </Button>
      </Stack>
      {multiPopup && (
        <CustomMultiPopup
          multiResponse={multiResponse}
          handleClosePopup={closeMultiPopup}
        ></CustomMultiPopup>
      )}
      {openPopup && (
        <Dialog open={openPopup}>
          <VerificationBox
            payload={mobilePayload}
            handleConfirmVerification={handleConfirmVerification}
            errorInfo={errorInfo}
            showErrorPop={showErrorPop}
            hidePopup={showToastCallback}
            showPopup={showPopup}
            isPopup={true}
            isOnlyMobileVerification={false}
            closeDialog={closeDialog}
            codeExpireTime={expireTime}
            resendCodeTime={RESEND_CODE_TIME}
            isFromHardWareAndReplacement={true}
          ></VerificationBox>
        </Dialog>
      )}
    </Box>
  );
};

export default HardwareAllocation;
