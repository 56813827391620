import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import signInBackground from "./../../assets/images/signin_background.png";

/**
 * Renders the Background section for the Public routes.
 * @returns {component} The Background Section for the Public Routes
 */
const MainSection = () => {
  return (
    <Box
      data-testid="mainSection"
      sx={{
        display: "flex",
        height: { sm: "100vh", xs: "90vh" },
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${signInBackground})`,
        backgroundSize: { xs: "inherit", lg: "100% auto" },
        backgroundRepeat: "no-repeat"
      }}
    >
      <Outlet></Outlet>
    </Box>
  );
};

export default MainSection;
