import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import notificationDefault from "./../../assets/images/notification_default.svg";
import { Divider, MenuList, ThemeProvider, Typography } from "@mui/material";
import {
  ALL,
  NOTIFICATION_CENTER,
  NO_CONTENT,
  TIMEZONE_HELPER_TEXT,
  VIEW_ALL
} from "../../constant/Constants";
import NotificationPanel from "../notificationPanel/NotificationPanel";
import { Link } from "react-router-dom";
import "./Notification.scss";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import {
  getNotifications,
  readTransactionNotification
} from "../../services/user.services";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  setNotificationSource
} from "../../stateManagement/reducers/NotificationSlice";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";

/**
 * Renders the Notification UI component.
 * @returns {component} the Notification UI component.
 */
export default function Notification() {
  const [anchorEl, setAnchorEl] = useState(null);
  const config = useSelector((state) => getConfigDetails(state));
  const notificationStoreMessage = useSelector((state) =>
    getNotification(state)
  );
  /**
   * @constant {string} channelPartnerCode - Channel Partner code.
   */
   const channelPartnerCode = useSelector((state) =>
   getChannelPartnerCode(state)
 );
  const latestNotification = notificationStoreMessage[0];
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  /**
   * Handles the Clicj functionality on the Notification icon.
   * @function handleClick - Perfroms Click action on Notification icon.
   * @param {event} event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    localStorage.setItem(`${channelPartnerCode}_notificationReadStatus`, "");
    const payLoad = {
      type: ALL,
      fromCount: 0,
      toCount: 50
    };
    const notificationID =
      latestNotification && latestNotification.id ? latestNotification.id : "";
    if (notificationID && latestNotification && !latestNotification.isRead) {
      readTransactionNotification(config.server, notificationID).then(() => {
        getNotifications(config.server, payLoad).then((res) => {
          if (
            res &&
            res.status === 200 &&
            res.data &&
            res.data.status !== NO_CONTENT
          ) {
            dispatch(setNotificationSource([...res.data.userNotifications]));
          }
        });
      });
    }
  };
  const isNotificationRead =
    (latestNotification && latestNotification.isRead === false) ||
    localStorage.getItem(`${channelPartnerCode}_notificationReadStatus`)
      ? true
      : false;
  /**
   * Handles the close operation on click of the notification icon.
   * @function handleClose - Performs the Close Operation of Notification Box.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        data-testid="notifications"
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <ThemeProvider theme={ToolTipThemeWithBG}>
          <Tooltip title="Notifictaions" arrow>
            <IconButton
              data-testid="clickButton"
              onClick={handleClick}
              sx={{
                border: anchorEl ? "1px solid #98999d" : "none",
                p: 0.5,
                backgroundColor: anchorEl ? "#d9d9d9" : "transparent",
                width: "30px",
                height: "30px",
                position: "relative"
              }}
            >
              <img
                style={{
                  width: "15px",
                  height: "18.5px"
                }}
                alt={"notify"}
                src={notificationDefault}
              />
              {isNotificationRead && (
                <span className="activeNotification"></span>
              )}
            </IconButton>
          </Tooltip>
        </ThemeProvider>
        <Menu
          data-testid="closeButton"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              background: "#F6F6F6",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,

              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                background: "#F6F6F6",
                right: { lg: "48%", xs: "24%" },
                width: 10,
                height: 10,
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0
              }
            }
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: 2,
              py: 1
            }}
          >
            {" "}
            <Typography
              sx={{
                fontSize: 14,
                fontFamily: "BukraRegular",
                color: "#181D25",
                letterSpacing: "0px"
              }}
            >
              {NOTIFICATION_CENTER}
            </Typography>
            <Link
              to={"/notification"}
              style={{
                textDecoration: "none"
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  fontFamily: "BukraMedium",
                  color: "#0F57CB",
                  letterSpacing: "0px",
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline"
                  }
                }}
                onClick={handleClose}
              >
                {VIEW_ALL}
              </Typography>
            </Link>
          </Box>
          <MenuList
            sx={{
              maxHeight: 300,
              maxWidth: { xs: 320, sm: 341 },
              width: { xs: 320, sm: 341 },
              overflowX: "hidden",
              overflowY: "auto"
            }}
          >
            <NotificationPanel></NotificationPanel>
          </MenuList>
          <Divider></Divider>
          <Typography
            sx={{
              fontSize: "9px",
              px: 1,
              pt: 0.5,
              fontFamily: "BukraMedium",
              color: "#282828"
            }}
          >
            {TIMEZONE_HELPER_TEXT}
          </Typography>
        </Menu>
      </Box>
    </React.Fragment>
  );
}
