import React, { useState, useEffect, useCallback } from "react";
import ProfileCard from "../profileCard/ProfileCard";
import subDistributorProfile from "./../../assets/images/sub_distributor_profile.svg";
import { Typography } from "@mui/material";
import {
  SUB_DISTRIBUTOR_PROFILE,
  SUB_DISTRIBUTOR,
  NO_DATA
} from "../../constant/Constants";
import { getChildDetails } from "../../services/user.services";
import { useParams } from "react-router-dom";
import { textWithComma } from "../../constant/Utils";
import { useSelector } from "react-redux";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import BodyLoader from "../loaders/bodyLoader/BodyLoader";

/**
 * Renders the child Subdistributor profile card UI from Master Distributor's login.
 * @returns {component} the child Subdistributor Profile card UI from Master Distributor's login.
 */

const SubDistributorViewProfile = () => {
  const [subDistributorViewDetails, setSubDistributorViewDetails] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [showNoData, setShowNoData] = useState(false);
  const params = useParams();
  const config = useSelector((state) => getConfigDetails(state));

  /**
   * Preloads the child subdistributpr Details based on the ID Selected.
   */
  const getChildDetailsApi = useCallback(() => {
    if (params.id) {
      setLoading(true);
      getChildDetails(config.server, params.id)
        .then((res) => {
          if (res && res.status === 200) {
            setSubDistributorViewDetails(res.data);
            setShowNoData(false);
          } else {
            setShowNoData(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.id, config.server]);
  useEffect(() => {
    getChildDetailsApi();
  }, [getChildDetailsApi]);

  const handleCallBackUpdateUserDetails = () => {
    getChildDetailsApi();
  };

  /**
   * Extract the required data from the child subdistributor details object.
   */
  const {
    channelPartnerMobileNumber,
    channelPartnerEmail,
    channelPartnerAddressInformation,
    channelPartnerCity,
    channelPartnerCode,
    channelPartnerFirstName,
    channelPartnerCountry,
    creditBalance,
    postalCode,
    channelPartnerArea,
    status,
    approvalStatus
  } = subDistributorViewDetails;
  const name = `${channelPartnerFirstName}`;
  const address = `${textWithComma(
    channelPartnerAddressInformation
  )}${textWithComma(channelPartnerArea)} ${textWithComma(
    channelPartnerCity
  )}${textWithComma(postalCode)}${channelPartnerCountry}`;

  /**
   * @constant {object} profileDetails - Create formatted details Object for the UI component.
   */

  let profileDetails = {
    profileTittle: SUB_DISTRIBUTOR_PROFILE,
    profileName: `${SUB_DISTRIBUTOR}: ${channelPartnerCode} [${name}]`,
    profileNumber: channelPartnerMobileNumber,
    profileEmail: channelPartnerEmail,
    profileAddress: address,
    image: subDistributorProfile,
    isChildProfile: true,
    creditBalance: creditBalance,
    userId: 1,
    routeToEditProfile: "/subdistributor/editprofile",
    profileStatus: SUB_DISTRIBUTOR,
    channelPartnerCode: channelPartnerCode,
    status: status,
    approvalStatus: approvalStatus,
    handleCallBackUpdateUserDetails: handleCallBackUpdateUserDetails
  };
  return (
    <>
      {!loading && showNoData && (
        <Typography data-testid="noData">{NO_DATA}</Typography>
      )}
      {loading && <BodyLoader />}
      {!loading && subDistributorViewDetails && (
        <ProfileCard profileDetails={profileDetails}></ProfileCard>
      )}
    </>
  );
};

export default SubDistributorViewProfile;
