import React, { memo } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import close from "./../../../assets/images/close.png";
import Toast_Succes_Icon from "./../../../assets/images/toast_success_icon.svg";
import Toast_Awaiting_Icon from "./../../../assets/images/toast_awaiting_icon.svg";
import toastErrorIcon from "./../../../assets/images/toast_error_icon.svg";
import {
  TOAST_SUCCESS,
  TOAST_AWAIT,
  SOMETHING_WENT_WRONG
} from "../../../constant/Constants";

/**
 * Renders the Toast component
 * @param {object} props - Props passed to the Toast Component
 * @returns {component} Toast UI component.
 */
const CustomToastMessage = (props) => {
  const { snackBarDetails } = props;
  const {
    toastMessage = SOMETHING_WENT_WRONG,
    toastBgclr = " #E6FFEE",
    toastBorderclr = "#50B768",
    toastTextclr = "#238931",
    toastSubTittle,
    toastStatus,
    openState = true,
    handleCloseSnackBar
  } = snackBarDetails;

  /**
   * Handles the Toast close operation.
   * @function handleClose Performs the Toast Close Operation.
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleCloseSnackBar();
  };

  /**
   * Renders the Close icon UI.
   * @property {component} action close icon UI.
   */
  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      data-testid="closeIcon"
      onClick={handleClose}
    >
      <img src={close} height="12px" alt="cls"></img>
    </IconButton>
  );

  /**
   * Renders the Toast icon based on the toast status.
   * @param {string} status - Toast Status.
   * @returns {component} Image component based on the toast status.
   */
  const renderStatusIcons = (status) => {
    if (status === TOAST_SUCCESS) {
      return <img src={Toast_Succes_Icon} alt="success" height="29px"></img>;
    } else if (status === TOAST_AWAIT) {
      return (
        <img
          src={Toast_Awaiting_Icon}
          alt="await"
          data-testid="awaitIcon"
          height="29px"
        ></img>
      );
    } else {
      return (
        <img
          src={toastErrorIcon}
          alt="error"
          data-testid="errorIcon"
          height="29px"
        ></img>
      );
    }
  };

  return (
    <Snackbar
      data-testid="snackbarComponent"
      open={openState}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
      sx={{
        minHeight: "62px",
        top: { sm: "60px", xs: "77px" }
      }}
    >
      <Box>
        <Alert
          action={action}
          icon={renderStatusIcons(toastStatus)}
          sx={{
            background: `${toastBgclr} 0% 0% no-repeat padding-box`,
            letterSpacing: "0px",
            border: `1px solid ${toastBorderclr}`,
            borderRadius: "8px",
            px: 3,
            ".MuiAlert-icon,.MuiAlert-action": {
              alignItems: "center",
              pt: 0,
              pb: 0
            }
          }}
        >
          <AlertTitle
            sx={{
              color: toastTextclr,
              fontFamily: "BukraMedium",
              letterSpacing: "0.4px",
              m: 0,
              fontSize: "16px"
            }}
          >
            {toastMessage}
          </AlertTitle>
          {toastSubTittle && (
            <Typography
              data-testid="toastSubTittle"
              sx={{
                fontSize: "13px",
                color: toastTextclr,
                fontFamily: "BukraRegular",
                letterSpacing: "0.33px"
              }}
            >
              {toastSubTittle}
            </Typography>
          )}
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default memo(CustomToastMessage);
