import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Tooltip
} from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SOMETHING_WENT_WRONG,
  TOOLTIP_DOWNLOAD,
  TOOLTIP_DOWNLOAD_IMG
} from "../../constant/Constants";
import { ToolTipTheme } from "../../constant/themes/ToolTipTheme";
import { download, failureToastMessage } from "../../constant/Utils";
import {
  getFilteredDateFromStore,
  getShowDateFilter
} from "../../stateManagement/reducers/DateFilterSlice";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import calendar_month_black from "./../../assets/images/calendar_month_black.svg";
import downloadIcon from "./../../assets/images/download_icon.svg";
import { ThemeProvider } from "@mui/material/styles";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Handles the Report Download operation.
 * @param {{reportType: function, status:string, fileName:string}}  {reportType,status,fileName} - Props for the Download operation.
 * @returns {component} the Download Report UI Component.
 */
const DownloadReport = ({ reportType, status, fileName }) => {
  const dispatch = useDispatch();
  const displayDateFilter = useSelector((state) => getShowDateFilter(state));
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );
  const { startDate, endDate } = selectedDateFilter;
  const config = useSelector((state) => getConfigDetails(state));

  const [loader, setLoader] = useState(false);
  /**
   * Download the Report in CSV format.
   * @function downloadReport - Performs the Report download operation.
   */
  const downloadReport = () => {
    setLoader(true);
    setTimeout(() => {
      reportType(config.server, startDate, endDate, status).then((response) => {
        setLoader(false);
        if (response) {
          if (response.status === 200) {
            download(response.data, fileName);
          } else {
            const toastDetails = failureToastMessage(SOMETHING_WENT_WRONG, "");
            dispatch(updateToastMessage(toastDetails));
          }
        }
      });
    }, 1000);
  };

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
      {displayDateFilter && (
        <Box
          sx={{
            border: "1px solid #CCCCCC",
            background: " #FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "8px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "space-evenly", xs: "space-between" },
            mr: 1.5,
            width: { md: "225px", xs: "100%" },
            px: "5px"
          }}
        >
          <Typography
            sx={{
              pr: "5px",
              fontSize: { xs: "11px", sm: "13px" },
              fontFamily: "BukraRegular",
              color: "#1E1E1E"
            }}
          >
            {startDate} - {endDate}
          </Typography>
          <img
            src={calendar_month_black}
            alt="notifcation"
            style={{
              width: "15px",
              height: "15px"
            }}
          ></img>
        </Box>
      )}
      <Button
        data-testid="downloadIcon"
        disabled={loader}
        onClick={() => downloadReport()}
        sx={{ cursor: "pointer", p: 0, m: 0, minWidth: "30px" }}
      >
        <Box sx={{ minHeight: "30px", width: "30px" }}>
          {loader ? (
            <Box
              sx={{
                height: "30px",
                width: "30px",
                background: "#467ae7",
                borderRadius: "8px",
                cursor: "default"
              }}
            >
              <CircularProgress size={20} sx={{ color: "#ffffff", p: "5px" }} />
            </Box>
          ) : (
            <ThemeProvider theme={ToolTipTheme}>
              <Tooltip title={TOOLTIP_DOWNLOAD_IMG}>
                <Box className="tooltip_mweb">
                  <img
                    className="imgHover"
                    src={downloadIcon}
                    alt="notifcation"
                    style={{ width: `30px`, height: `30px` }}
                  ></img>
                  <Typography
                    sx={{
                      display: {
                        xs: "block",
                        lg: "none",
                        fontSize: "11px",
                        paddingTop: "5px",
                        color: "#282828",
                        fontFamily: "BukraRegular",
                        textTransform: "capitalize"
                      }
                    }}
                  >
                    {TOOLTIP_DOWNLOAD}
                  </Typography>
                </Box>
              </Tooltip>
            </ThemeProvider>
          )}
        </Box>
      </Button>
    </Box>
  );
};

export default DownloadReport;
