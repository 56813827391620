import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NO_DATA } from "../../constant/Constants";
import { promotionTableHeader } from "../../constant/TableHeaderConstants";
import {
  CapitalizeLetter,
  getComparator,
  getDDMMYYYYDateFormat,
  tableSorting
} from "../../constant/Utils";
import { getBroadcastNotificationFromStore } from "../../stateManagement/reducers/BroadcastNotificationSlice";
import { tableScrollStyles } from "../../style/CustomStyles";
import CustomPagination from "../customComponents/customPagination/CustomPagination";
import PromotionsPopup from "../promotionsPopup/PromotionsPopup";

const CampaignDetailsTable = (props) => {
  const { selectedDateFilter, startDate, endDate } = props;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("publishStartDate");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [promotionsMessage, setPromotionsMessage] = useState([]);
  const [showModal, setShowModal] = useState({
    open: false,
    messageDetails: {}
  });

  const broadcastNotification = useSelector((state) =>
    getBroadcastNotificationFromStore(state)
  );

  const handleShowCompleteMessage = (data) => {
    setShowModal({
      open: true,
      messageDetails: data
    });
  };
  const handleClosePromotionsPopup = () => {
    setShowModal({ open: false, message: "" });
  };
  /**
   * Handle the Page Change based on the Page number Selected.
   * @function handlePageChange - Performs the Pagination change action.
   * @param {object} _event  - Pointer event
   * @param {number} value - Page number
   */
  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  /**
   * Handle the page size change based on the Number selected
   * @handlePageSizeChange - Perforns action to change the no. of rows length per page table.
   * @param {object} event - Pointer event
   */
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };
  /**
   * Performs the Ascending and descending Operation on Table Items based on the Head cell selected.
   * @function handleRequestSort - Performs the Ascending or descending sorting action.
   * @param {event} _event - event object
   * @param {string} property - Sorting category
   */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * @constant {number} totalCount - Total count.
   */
  const totalCount = Math.ceil(promotionsMessage.length / pageSize);

  const filteredData = useMemo(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const today = currentDate.getDate();

    return (
      broadcastNotification &&
      broadcastNotification.filter((item) => {
        const time = new Date(item.publishStartDate);
        if (selectedDateFilter === "all_time") {
          return true;
        } else if (selectedDateFilter === "this_year") {
          return time.getFullYear() === currentYear;
        } else if (selectedDateFilter === "to_day") {
          return (
            time.getFullYear() === currentYear &&
            time.getMonth() + 1 === currentMonth &&
            time.getDate() === today
          );
        } else if (
          selectedDateFilter === "custom_time" &&
          startDate &&
          endDate
        ) {
          const startofDay = new Date(startDate);
          const formatDate = new Date(endDate);
          const endOfDay = new Date(
            formatDate.getFullYear(),
            formatDate.getMonth(),
            formatDate.getDate(),
            23,
            59,
            59
          );
          return time >= startofDay && time <= endOfDay;
        } else {
          return (
            time.getFullYear() === currentYear &&
            time.getMonth() + 1 === currentMonth
          );
        }
      })
    );
  }, [selectedDateFilter, endDate, startDate, broadcastNotification]);

  useEffect(() => {
    const tableData = filteredData;
    setPage(1);
    setPromotionsMessage(tableData);
  }, [filteredData, setPromotionsMessage]);

  return (
    <Box data-testid="campaignNoticificationTable">
      <TableContainer sx={{ ...tableScrollStyles }}>
        <Table aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow
              sx={{
                display: "flex"
              }}
            >
              {promotionTableHeader.map((headCell, index) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding={"normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    borderBottom: "none",
                    paddingLeft: { lg: 5, xs: 2 },
                    width: { xs: headCell.width },
                    display: headCell.display
                  }}
                >
                  {index === 0 ? (
                    <TableSortLabel
                      data-testid="sorting"
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort("", headCell.id)}
                      sx={{
                        color: "#606060",
                        letterSpacing: "0px",
                        fontSize: "15px",
                        fontFamily: "BukraMedium"
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <Typography
                      sx={{
                        color: "#606060",
                        letterSpacing: "0px",
                        fontSize: "15px",
                        fontFamily: "BukraMedium"
                      }}
                    >
                      {headCell.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {promotionsMessage && promotionsMessage.length > 0 && (
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `tableSorting` call with:
                       rows.slice().sort(getComparator(order, orderBy)) */}
              {tableSorting(
                promotionsMessage,
                getComparator(order, orderBy, true)
              )
                .slice((page - 1) * pageSize, page * pageSize)
                .map((item, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`${index + 1}`}
                      style={
                        index % 2
                          ? { background: "#FFFFFF" }
                          : { background: "#F7F9FC" }
                      }
                      sx={{
                        display: "flex",
                        cursor: "pointer"
                      }}
                      data-testid="tableRow"
                      onClick={() => handleShowCompleteMessage(item)}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          width: "100px",
                          paddingLeft: { lg: 5, xs: 2 }
                        }}
                      >
                        {getDDMMYYYYDateFormat(item.publishStartDate)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",
                          paddingLeft: { lg: 5, xs: 2 },
                          display: "block",
                          width: "500px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textDecoration: { xs: "underline", md: "none" }
                        }}
                      >
                        {item.messageTitle}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          border: "none",
                          color: "#282828",
                          letterSpacing: "0px",
                          width: "100px",
                          fontSize: "13px",
                          fontFamily: "BukraRegular",

                          paddingLeft: { lg: 5, xs: 2 }
                        }}
                      >
                        {CapitalizeLetter(item.status)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {promotionsMessage.length === 0 && (
        <Typography
          data-testid="noContent"
          textAlign={"center"}
          sx={{
            mt: 3,
            pb: 3
          }}
        >
          {NO_DATA}
        </Typography>
      )}
      {promotionsMessage && promotionsMessage.length > 0 && (
        <CustomPagination
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          totalCount={totalCount}
          page={page}
          handlePageChange={handlePageChange}
        ></CustomPagination>
      )}
      {showModal.open && (
        <PromotionsPopup
          showAlertImg={false}
          showModal={showModal.open}
          messageDetails={showModal.messageDetails}
          handleClosePromotionsPopup={handleClosePromotionsPopup}
        />
      )}
    </Box>
  );
};

export default memo(CampaignDetailsTable);
