import {
  Box,
  TextField,
  Typography,
  Stack,
  Button,
  FormHelperText,
  Select,
  MenuItem,
  Dialog
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CANCEL,
  DATE_FORMAT,
  NOTES,
  NO_CONTENT,
  OPTIONAL,
  SAVE,
  SELECT_STB,
  TOAST_ERROR,
  TOAST_SUCCESS,
  WARRANTY_TILL,
  OLD_NEW_STB_SAME,
  BLACKLISTED,
  SELECTED_STB_BLACKLIST_MSG,
  STB,
  NEW_STB,
  STB_NUMBER,
  IS_OUT_WARRANTY,
  CANNOT_REPLACED,
  CONFIRM_STB,
  COFIRM_STB_MISMATCH,
  STB_MAX_LENGTH,
  OTP_GENERATED_SUCCESS_DESCRIPTION,
  SOMETHING_WENT_WRONG,
  RESEND_CODE_TIME,
  MAX_LENGTH_50,
  INVALID_OTP
} from "../../constant/Constants";
import DatePicker from "react-multi-date-picker";
import "./HardwareReplacement.scss";
import {
  convertMStoS,
  failureToastMessage,
  getDDMMYYYYDateFormat,
  successToastMessage
} from "../../constant/Utils";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import {
  generateCustomerOtpDetails,
  replaceSTB,
  validateCustomerOtpDetails
} from "../../services/user.services";
import { useDispatch, useSelector } from "react-redux";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import OverlayLoader from "../loaders/overlayLoader/OverlayLoader";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import VerificationBox from "../verificationBox/VerificationBox";

const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#202020",
          fontSize: "14px",
          letterSpacing: "0px",
          background: "#EDF4F6 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          ":focus": {
            background: "#EDF4F6 0% 0% no-repeat padding-box",
            color: "#202020",
            opacity: 1
          }
        }
      }
    }
  }
});

/**
 * Renders the Hardware UI form Component.
 * @param {{customerInfo:object,stbInfo:array<object>,resetCustomer:function}} {customerInfo,stbInfo,resetCustomer} Customer Object.
 * @returns {component} the Hardware Replacement Form UI Component
 */
const HardwareReplacement = ({ customerInfo, stbInfo, resetCustomer }) => {
  const dispatch = useDispatch();
  const [stbInputValue, setSTBInputValue] = useState("");
  const [newStbInputValue, setNewSTBInputValue] = useState("");
  const [confirmNewStbInputValue, setConfirmNewSTBInputValue] = useState("");
  const [date, setDate] = useState(new Date());
  const [notes, setNotes] = useState("");
  const [warrantyEndDate, setWarranyEndDate] = useState("");
  const [hardwareType, setHardwareType] = useState("");
  const [hardwareStatus, setHardwareStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");
  const [showErrorPop, setShowErrorPop] = useState(false);
  const [expireTime, setExpireTime] = useState();
  const [isSaveBtnEnable, setIsSaveBtnEnable] = useState(true);
  const config = useSelector((state) => getConfigDetails(state));

  const mobilePayload = {
    mobileNumber: customerInfo ? `${customerInfo.mobileNumber}` : ""
  };
  useEffect(() => {
    handleCancel();
  }, [stbInfo]);

  /**
   * Handle the Warranty End date, hardware type change based on the select change of STB.
   * @function handleStbNoChange - Performs action on change of STB no. Select option.
   * @param {object} e - Pointer event
   */
  const handleStbNoChange = (e) => {
    const value = e.target.value;
    const trimedInputValue = value.trim();
    const selectedSTB = stbInfo?.find((stb) => stb.serialNo === e.target.value);
    setWarranyEndDate(selectedSTB.inventoryWarrantEndDate);
    setHardwareType(selectedSTB.hardwareType);
    setHardwareStatus(selectedSTB.state);
    setSTBInputValue(trimedInputValue);
  };

  /**
   * Handles the New STB input change.
   * @function handleNewSTBInputChange - Performs action one New STB input change.
   * @param {object} e - New STB input pointer event
   */
  const handleNewSTBInputChange = (e) => {
    const value = e.target.value;
    const trimedInputValue = value.trim();
    setNewSTBInputValue(trimedInputValue);
  };
  /**
   * Handles the New STB input change.
   * @function handleConfirmNewSTBInputChange - Performs action one New STB input change.
   * @param {object} e - New STB input pointer event
   */
  const handleConfirmNewSTBInputChange = (e) => {
    const value = e.target.value;
    const trimedInputValue = value.trim();
    setConfirmNewSTBInputValue(trimedInputValue);
  };

  /**
   * Handle Resetting the Replacement Form details on click of Cancel Buton in the UI.
   * @function handleCancel - Performs action to reset the Hardware replacement form fields.
   */

  const handleCancel = () => {
    setSTBInputValue("");
    setNewSTBInputValue("");
    setConfirmNewSTBInputValue("");
    setNotes("");
    setWarranyEndDate("");
    setHardwareType("");
    setHardwareStatus("");
    setDate(new Date());
    setIsSaveBtnEnable(true);
  };

  /**
   * Checks whether the date is valid or not
   * @function isValidateDate - Performs action to check whether the date is valid or not based on expiry date of the STB.
   * @returns {boolean} true or false.
   */
  const isValidateDate = () => {
    let valid = false;
    const currentDate = new Date(date);
    const expiryDate = new Date(warrantyEndDate);
    if (currentDate.getTime() < expiryDate.getTime()) {
      valid = true;
    }
    return valid;
  };

  /**
   * Checks whether to disable the Save button or not.
   * @function isSaveDisable - Performs action to Disable or enable the Save Button.
   * @returns {boolean} the status in true or false to disable or enable the Save button in UI.
   */
  const isSaveDisable = () => {
    const isInvalid =
    isSaveBtnEnable && customerInfo &&
      customerInfo.customerId &&
      date !== "" &&
      stbInputValue !== "" &&
      newStbInputValue !== "" &&
      newStbInputValue !== stbInputValue &&
      newStbInputValue === confirmNewStbInputValue &&
      hardwareStatus !== BLACKLISTED &&
      isValidateDate()
        ? false
        : true;
    return isInvalid;
  };

  /**
   * Handles the mobile Verification.
   * @function handleVerifyMobileNumber - Verify the Entered Mobile Number
   */
  const handleVerifyMobileNumber = () => {
    setIsSaveBtnEnable(false);
    generateCustomerOtpDetails(config.server, mobilePayload).then(
      (response) => {
        if (response && response.status === 200) {
          let toastDetails = successToastMessage(
            OTP_GENERATED_SUCCESS_DESCRIPTION
          );
          dispatch(updateToastMessage(toastDetails));
          setExpireTime(convertMStoS(response.data.validFor));
          setOpenPopup(true);
        } else {
          const message =
            response && response.data.message
              ? response.data.message
              : SOMETHING_WENT_WRONG;
          let toastDetails = failureToastMessage(message, "");
          dispatch(updateToastMessage(toastDetails));
          setIsSaveBtnEnable(true);
        }
      }
    );
  };

  /**
   * Handles the Toast Message display
   * @function showToastCallback - Performs action to hide toast message.
   */
  const showToastCallback = () => {
    setShowErrorPop(false);
  };

  /**
   * Handles the display of the toast Message.
   * @function showPopup - Display the Popup element.
   * @param {string} message - Error nessage
   */
  const showPopup = (message) => {
    setShowErrorPop(true);
    setErrorInfo(message ? message : SOMETHING_WENT_WRONG);
  };

  /**
   * Handles the close operation of Dialog box.
   * @function closeDialog - Close the Dialog Box Popup.
   */
  const closeDialog = () => {
    setShowErrorPop(false);
    setOpenPopup(false);
    setIsSaveBtnEnable(true);
  };

  /**
   * Handles the STB Replacement Functionality.
   * @function handleReplaceSTB - Performs the action to Replace the STB.
   */
  const handleReplaceSTB = () => {
    setLoader(true);
    const payload = {
      serialNumber: stbInputValue.trim(),
      hardwareType: hardwareType.trim(),
      customerId: customerInfo.customerId,
      newSerialNumber: newStbInputValue.trim(),
      replacementReason: notes.trim()
    };
    replaceSTB(config.server, payload)
      .then((res) => {
        if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
          const replaceMessage = `STB number ${stbInputValue} has been replaced successfully with STB number ${newStbInputValue}.`;
          let toastDetails = {
            toastTitle: replaceMessage,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_SUCCESS,
            toastTextclr: "#238931",
            toastBgclr: "#E6FFEE ",
            toastBorderclr: "#50B768"
          };
          dispatch(updateToastMessage(toastDetails));
          resetCustomer();
        } else {
          let toastDetails = {
            toastTitle: res.data.message,
            isToastOpen: true,
            toastDescription: "",
            toastStatus: TOAST_ERROR,
            toastTextclr: "#FF2A2A",
            toastBgclr: "#FDE2E2 ",
            toastBorderclr: "#FF2A2A"
          };
          dispatch(updateToastMessage(toastDetails));
        }
      })
      .catch((err) => err)
      .finally(() => {
        setIsSaveBtnEnable(true);
        setLoader(false);
      });
  };

  /**
   * Handles the OTP Verification
   * @function handleConfirmVerification - Handles the OTP Verification Operation.
   * @param {string} value - Mobile OTP
   */
  const handleConfirmVerification = (value) => {
    const userData = {
      mobileNumber: `${customerInfo.mobileNumber}`,
      otp: value.trim()
    };
    validateCustomerOtpDetails(config.server, userData).then((response) => {
      if (response.status === 200) {
        setOpenPopup((prev) => !prev);
        handleReplaceSTB();
      } else {
        const message =
          response && response.data.otpValidationCurrentAttempt
            ? INVALID_OTP
            : response.data.message;
        showPopup(message);
      }
    });
  };

  return (
    <Box
      data-testid="hardwareReplacement"
      className="hardwareReplacement_Wrapper"
    >
      {loader && <OverlayLoader />}

      <ThemeProvider theme={inputTheme}>
        <Box sx={{ mx: { lg: 7.5, xs: 1.5 }, pt: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
                width: { lg: "400px", xl: "500px" }
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#8F8F8F",
                  fontFamily: "BukraRegular",
                  letterSpacing: 0,
                  mb: "15px"
                }}
              >
                Date <span className="asterisk">*</span>
              </Typography>
              <DatePicker
                inputClass="replacement-date-input"
                value={date || ""}
                placeholder="Enter Date"
                format={DATE_FORMAT}
                disabled
                disableToolbar
                sx={{ height: "41px" }}
              />
              {warrantyEndDate &&
                (isValidateDate() ? (
                  <FormHelperText
                    sx={{ color: "#038303", textAlign: "left" }}
                    className="errorFormHelperText"
                  >
                    {`${WARRANTY_TILL} ${getDDMMYYYYDateFormat(
                      warrantyEndDate
                    )}.`}
                  </FormHelperText>
                ) : (
                  <FormHelperText
                    sx={{ color: "#DE0909", textAlign: "left" }}
                    className="errorFormHelperText"
                  >
                    {`${STB_NUMBER} ${stbInputValue} ${IS_OUT_WARRANTY} ${getDDMMYYYYDateFormat(
                      warrantyEndDate
                    )} ${CANNOT_REPLACED}`}
                  </FormHelperText>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
                mt: { xs: 3, lg: 0 }
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#8F8F8F",
                  fontFamily: "BukraRegular"
                }}
              >
                {STB} <span className="asterisk">*</span>
              </Typography>
              <Select
                inputProps={{ "data-testid": "stbSelect" }}
                sx={{
                  mt: "15px",
                  height: "44px",
                  width: { lg: "400px", xl: "500px" }
                }}
                onChange={(e) => handleStbNoChange(e)}
                value={stbInputValue}
                displayEmpty
                renderValue={(stbNo) => {
                  if (!stbNo) {
                    return (
                      <Typography
                        sx={{
                          letterSpacingpacing: "0px",
                          color: "#282828",
                          opacity: 0.5,
                          fontSize: "13px",
                          fontFamily: "BukraRegular"
                        }}
                      >
                        {`${SELECT_STB}`}
                      </Typography>
                    );
                  }
                  return stbNo;
                }}
              >
                {stbInfo &&
                  stbInfo.length > 0 &&
                  stbInfo.map((stb) => {
                    return (
                      <MenuItem
                        key={stb.serialNo}
                        value={stb.serialNo}
                        sx={{
                          ...muiMenuItemStyles
                        }}
                      >
                        {stb.serialNo}
                      </MenuItem>
                    );
                  })}
              </Select>
              {hardwareStatus === BLACKLISTED && (
                <FormHelperText
                  sx={{ color: "#DE0909", textAlign: "left" }}
                  className="errorFormHelperText"
                >
                  {SELECTED_STB_BLACKLIST_MSG}
                </FormHelperText>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              pt: 2,
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "space-between"
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column" }
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#8F8F8F",
                    fontFamily: "BukraRegular",
                    letterSpacing: 0,
                    mb: "15px"
                  }}
                >
                  {NEW_STB} <span className="asterisk">*</span>
                </Typography>
                <TextField
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  inputProps={{
                    "data-testid": "stbInput",
                    height: "32px",
                    padding: "8px",
                    maxLength: STB_MAX_LENGTH
                  }}
                  variant="outlined"
                  value={newStbInputValue}
                  onChange={(e) => handleNewSTBInputChange(e)}
                  sx={{
                    width: { lg: "400px", xl: "500px" },
                    ".MuiInputBase-root": {
                      height: "44px",
                      border: "none",
                      flexWrap: "wrap",
                      ".MuiOutlinedInput-input": {
                        p: 0,
                        px: 1
                      }
                    }
                  }}
                />

                {newStbInputValue &&
                  stbInputValue &&
                  newStbInputValue === stbInputValue && (
                    <FormHelperText
                      sx={{ color: "#DE0909", textAlign: "left", mt: 1.5 }}
                      className="errorFormHelperText"
                    >
                      {OLD_NEW_STB_SAME}
                    </FormHelperText>
                  )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column" },
                  mt: "16px"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#8F8F8F",
                    fontFamily: "BukraRegular",
                    letterSpacing: 0,
                    mb: "15px"
                  }}
                >
                  {CONFIRM_STB} <span className="asterisk">*</span>
                </Typography>
                <TextField
                  autoComplete="off"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  inputProps={{
                    "data-testid": "stbconfirmInput",
                    height: "32px",
                    padding: "8px",
                    maxLength: STB_MAX_LENGTH
                  }}
                  variant="outlined"
                  value={confirmNewStbInputValue}
                  onChange={(e) => handleConfirmNewSTBInputChange(e)}
                  sx={{
                    width: { lg: "400px", xl: "500px" },
                    ".MuiInputBase-root": {
                      height: "44px",
                      border: "none",
                      flexWrap: "wrap",
                      ".MuiOutlinedInput-input": {
                        p: 0,
                        px: 1
                      }
                    }
                  }}
                />

                {newStbInputValue &&
                  confirmNewStbInputValue &&
                  newStbInputValue !== confirmNewStbInputValue && (
                    <FormHelperText
                      sx={{ color: "#DE0909", textAlign: "left", mt: 1.5 }}
                      className="errorFormHelperText"
                    >
                      {COFIRM_STB_MISMATCH}
                    </FormHelperText>
                  )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { lg: "400px", xl: "500px" },
                pt: { xs: 3, lg: 0 }
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#8F8F8F",
                  fontFamily: "BukraRegular",
                  letterSpacing: 0,
                  mb: "15px"
                }}
              >
                {NOTES} ({OPTIONAL})
              </Typography>

              <TextField
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputProps={{
                  "data-testid": "notesField",
                  maxLength: MAX_LENGTH_50
                }}
                size="medium"
                multiline
                name="multline"
                rows={3}
                variant="outlined"
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                sx={{
                  backgroundColor: "#edf4f6",
                  fontSize: "14px",
                  minHeight: "80px",
                  ".MuiInputBase-root": {
                    minHeight: "80px"
                  }
                }}
              ></TextField>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="flex-end"
        sx={{ pt: 5, pb: 2, mx: { lg: 7.5, xs: 1.5 } }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: "20px",
            borderColor: "#4079d5",
            fontSize: "15px",
            textTransform: "none",
            fontFamily: "BukraRegular",
            letterSpacing: 0,
            color: "#4079d5",
            minWidth: "175px"
          }}
          onClick={handleCancel}
        >
          {CANCEL}
        </Button>
        <Button
          data-testid="saveButton"
          variant="outlined"
          sx={{
            borderRadius: "20px",
            fontSize: "15px",
            textTransform: "none",
            fontFamily: "BukraRegular",
            letterSpacing: 0,
            background:
              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
            color: "#ffffff",
            "&.Mui-disabled": {
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              opacity: 0.5
            },
            minWidth: "175px"
          }}
          disabled={isSaveDisable()}
          onClick={() => handleVerifyMobileNumber()}
        >
          {SAVE}
        </Button>
      </Stack>
      {openPopup && (
        <Dialog open={openPopup} >
          <VerificationBox
            payload={mobilePayload}
            handleConfirmVerification={handleConfirmVerification}
            errorInfo={errorInfo}
            showErrorPop={showErrorPop}
            hidePopup={showToastCallback}
            showPopup={showPopup}
            isPopup={true}
            isOnlyMobileVerification={false}
            closeDialog={closeDialog}
            codeExpireTime={expireTime}
            resendCodeTime={RESEND_CODE_TIME}
            isFromHardWareAndReplacement={true}
          ></VerificationBox>
        </Dialog>
      )}
    </Box>
  );
};

export default HardwareReplacement;
