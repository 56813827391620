import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  FormHelperText,
  Input,
  MenuItem,
  Paper,
  Select,
  Snackbar
} from "@mui/material";
import Typography from "@mui/material/Typography";
import leftarrow from "./../../assets/images/arrow_back_black.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import toastErrorIcon from "./../../assets/images/toast_error_small.png";
import emailicon from "../../assets/images/email_Icon.svg";
import {
  CONFIRM,
  ENTER_EMAIL_ID,
  ENTER_MOBILE,
  OTP_SENT_REG_MN,
  SOMETHING_WENT_WRONG,
  TOAST_SUCCESS
} from "../../constant/Constants";
import {
  convertMStoS,
  onlyNumber,
  validateEmail,
  validateRegisterMobileNumber
} from "../../constant/Utils";
import { setUserResetPassword } from "./../../stateManagement/reducers/UserResetPasswordDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { getLocationDetailsFromStore } from "../../stateManagement/reducers/LocationSlice";
import { getCountry, generateOTP } from "../../services/user.services";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
/**
 * Renders the forgot passwod Page UI.
 * @returns {component} the Forgot Password Page UI
 */
const ForgotPassword = () => {
  const location = useSelector((state) => getLocationDetailsFromStore(state));
  const config = useSelector((state) => getConfigDetails(state));
  const intialCountryCode =
    location && location.countryCode ? location.countryCode : "";
  const [registerEmailValue, setRegisterEmailValue] = useState("");
  const [registerEmailError, setRegisterEmailError] = useState("");
  const [registerMobileNumber, setRegisterMobileNumber] = useState("");
  const [registerMobileNumberError, setRegisterMobileNumberError] =
    useState("");
  const [countryCode, setCountryCode] = useState(intialCountryCode);
  const [countries, setCountries] = useState([]);
  const [errorInfo, setErrorInfo] = useState("");
  const [showErrorPop, setShowErrorPop] = useState(false);
  const [selectedCountryPlace, setSelectedCountryPlace] = useState({});
  const dispatch = useDispatch();

  /**
   * Gets the Country List and the Location Details based on user login IP.
   */
  const getCountriesList = useCallback(() => {
    if (config.server) {
      getCountry(config.server).then((res) => {
        if (res && res.status === 200) {
          setCountries(res.data);
        }
      });
    }
  }, [config.server]);

  useEffect(() => {
    setCountryCode(intialCountryCode);
    getCountriesList();
  }, [intialCountryCode, getCountriesList]);

  /**
   * Gets the Logged in user's country object.
   */
  useEffect(() => {
    const countryObject = countries.find(
      (item) => item.countryCode === countryCode
    );
    setSelectedCountryPlace(countryObject);
  }, [countries, countryCode]);

  /**
   * Sets the country Lists.
   */

  /**
   * Handles the Mobile code Change operation.
   * @function handleChange - Performs action on Mobile Code Select option change.
   * @param {object} event Mobile country code Select option event object.
   */
  const handleChange = (event) => {
    setCountryCode(event.target.value);
    setRegisterMobileNumber("");
    setRegisterMobileNumberError("");
  };

  const navigate = useNavigate();

  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDF4F6",
            color: "#0F0F10",
            border: "1px solid #E8E8E8",
            borderRadius: "3px",
            p: "0px",
            paddingTop: "3px",
            paddingBottom: "3px",
            height: "40px",
            fontSize: "13px",
            fontFamily: "BukraRegular",
            "&.Mui-focused": {
              border: "1px solid #0F57CB"
            },
            "& ::placeholder": {
              opacity: 1,
              color: "#5B636F"
            }
          }
        }
      }
    }
  });
  const textFieldTheme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDF4F6",
            color: "#0F0F10",
            border: "1px solid #E8E8E8",
            borderRadius: "3px",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "10px",
            height: "40px",
            fontSize: "13px",
            fontFamily: "BukraRegular",
            "&.Mui-focused": {
              border: "1px solid #0F57CB"
            },
            "& ::placeholder": {
              opacity: 1,
              color: "#5B636F"
            },
            "& .MuiSelect-select": {
              fontSize: "13px",
              ":focus": {
                "&.Mui-focused": {
                  border: "1px solid #0F57CB"
                },
                backgroundColor: "#e7eaed"
              }
            }
          }
        }
      }
    }
  });

  /**
   * Handles the Email Change.
   * @function handleChangeRegisterEmail - Performs action on Email input change.
   * @param {object} e - User Email input event object
   */
  const handleChangeRegisterEmail = (e) => {
    const { value } = e.target;
    setRegisterEmailValue(value);
    setRegisterEmailError("");
  };

  /**
   * Handles the Mobile no. change.
   * @function handleChangeRegisterMobileNumber - Performs action on Mobile no. change.
   * @param {object} e - Mobile no. input event object
   */
  const handleChangeRegisterMobileNumber = (e) => {
    const { value } = e.target;
    if (onlyNumber(value)) {
      if (value.length <= selectedCountryPlace?.maxSizeOfMobileNumber) {
        setRegisterMobileNumberError(
          validateRegisterMobileNumber(value, selectedCountryPlace, true)
        );
        setRegisterMobileNumber(value);
      }
    }
  };

  /**
   * Handles Validation on user Email input.
   * @function handleRegisterEmailValidation - Performs validation on user Email input change.
   */
  const handleRegisterEmailValidation = () => {
    setRegisterEmailError(validateEmail(registerEmailValue, true));
  };

  /**
   * Checks whether to Enable or disable the button.
   * @function isConfirmDisabled - Checks whether to enable or disable the confirm button.
   * @returns {boolean} true or false to disable or Enable the Confirm button.
   */
  const isConfirmButtonDisable = () => {
    const valid =
      registerEmailError.length > 0 ||
      registerMobileNumberError.length > 0 ||
      registerEmailValue === "" ||
      registerMobileNumber === ""
        ? true
        : false;
    return valid;
  };

  /**
   * Handles the Snackbar close operation
   * @function showToastCallback - Performs the close action for Snackbar.
   */
  const showToastCallback = () => {
    setShowErrorPop(false);
  };

  /**
   * Handles the form submission with the values filled that are required in forgot password form.
   * @function handleConfirmForgotPassword - Performs the Submit action on Forgot Password confirm button click.
   */
  const handleConfirmForgotPassword = () => {
    const countryPhoneCode = selectedCountryPlace.countryPhoneCode;
    const userData = {
      email: registerEmailValue.trim(),
      mobileNumber: `+${countryPhoneCode}${registerMobileNumber}`.trim(),
      countryCode: countryCode
    };
    generateOTP(config.server, userData).then((response) => {
      if (response && response.status === 200) {
        let toastDetails = {
          toastTitle: OTP_SENT_REG_MN,
          isToastOpen: true,
          toastDescription: "",
          toastStatus: TOAST_SUCCESS,
          toastTextclr: "#238931",
          toastBgclr: "#E6FFEE ",
          toastBorderclr: "#50B768"
        };
        dispatch(updateToastMessage(toastDetails));
        dispatch(setUserResetPassword(userData));
        const expiryTime = convertMStoS(response.data?.validFor);
        navigate("/verificationpage", { state: expiryTime });
      } else {
        const message =
          response && response.data
            ? response.data.message
            : SOMETHING_WENT_WRONG;
        setErrorInfo(message);
        setShowErrorPop(true);
      }
    });
  };

  return (
    <Paper
      elevation={2}
      sx={{
        p: 2.1,
        pb: 0,
        width: { xs: "85%", sm: "384px" }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <img
          data-testid="arrowButton"
          src={leftarrow}
          alt="left arrow"
          style={{
            height: "15px",
            width: "15px",
            cursor: "pointer"
          }}
          onClick={() => navigate(-1)}
        ></img>{" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50px",
            width: "100%"
          }}
        >
          <Snackbar
            open={showErrorPop}
            onClose={showToastCallback}
            key={"top"}
            autoHideDuration={3000}
            sx={{
              "&.MuiSnackbar-anchorOriginBottomLeft ": {
                top: "0px",
                position: "relative",
                left: "0px"
              }
            }}
          >
            <Alert
              severity="error"
              icon={<img src={toastErrorIcon} alt="error" height="15px"></img>}
              sx={{
                width: "300px",
                color: "#FF2A2A",
                fontSize: "12px",
                border: "1px solid #FF2A2A",
                background: "#FDE2E2 0% 0% no-repeat padding-box",
                fontFamily: "BukraMedium",
                letterSpacing: "0.3px",
                pt: 0,
                pb: 0,
                ".MuiAlert-icon,.MuiAlert-action": {
                  alignItems: "center",
                  pt: 0,
                  pb: 0,
                  pr: 0.8,
                  mr: 0
                }
              }}
            >
              {errorInfo}
            </Alert>
          </Snackbar>
        </Box>
      </Box>

      <Box
        sx={{
          pb: 3,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 4.1
        }}
      >
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              width: "100%",
              mb: 3
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "BukraMedium",
                letterSpacing: "0px",
                color: "#464E5F",
                mb: 2
              }}
            >
              {ENTER_EMAIL_ID}
            </Typography>

            <Input
              autoComplete="off"
              onDrop={(e) => {
                e.preventDefault();
                return false;
              }}
              id="registerEmail"
              type="email"
              fullWidth
              required
              name="registerEmail"
              placeholder="Enter Email ID"
              disableUnderline={true}
              value={registerEmailValue}
              inputProps={{ "data-testid": "emailInput" }}
              onChange={(e) => handleChangeRegisterEmail(e)}
              onBlur={() => handleRegisterEmailValidation()}
              sx={{
                color: "#0F0F10",
                border: registerEmailError ? "1px solid #DE0909" : ""
              }}
              startAdornment={
                <InputAdornment position="start" sx={{ p: 1 }}>
                  <img
                    src={emailicon}
                    alt="emailicon"
                    style={{ height: "15px", width: "15px" }}
                  />
                </InputAdornment>
              }
            />
            {registerEmailError && (
              <FormHelperText
                sx={{ color: "#DE0909", textAlign: "left" }}
                className="errorFormHelperText"
                data-testid="error-msg"
              >
                {registerEmailError}{" "}
              </FormHelperText>
            )}
          </Box>
        </ThemeProvider>

        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={textFieldTheme}>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "BukraMedium",
                letterSpacing: "0px",
                pt: 3,
                mb: 2,
                color: "#464E5F"
              }}
            >
              {ENTER_MOBILE}
            </Typography>
            <Box
              sx={{
                display: "flex",
                height: "40px"
              }}
            >
              <Select
                id="filledoption"
                inputProps={{
                  "data-testid": "mobileCodes"
                }}
                sx={{
                  backgroundColor: "#EDF4F6",
                  width: "98px",
                  fontSize: "13px",
                  color: "#000000",
                  fontFamily: "BukraRegular"
                }}
                value={countryCode}
                onChange={(e) => handleChange(e)}
                disableUnderline={true}
                variant="standard"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      marginTop: "3px",
                    }
                  }
                }}
              >
                {countries &&
                  countries.length > 1 &&
                  countries.map((country, i) => (
                    <MenuItem
                      key={country.countryCode}
                      value={country.countryCode}
                      sx={{
                        ...muiMenuItemStyles
                      }}
                    >
                      {`+${country.countryPhoneCode} ${country.countryCode}`}
                    </MenuItem>
                  ))}
              </Select>
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onChange={(e) => handleChangeRegisterMobileNumber(e)}
                onBlur={(e) => handleChangeRegisterMobileNumber(e)}
                value={registerMobileNumber}
                id="registerMobileNumber"
                fullWidth
                required
                className="mobileNumber"
                name="registerMobileNumber"
                pattern={selectedCountryPlace && selectedCountryPlace.regEx}
                placeholder="Enter Mobile Number"
                disableUnderline={true}
                inputProps={{
                  maxLength:
                    selectedCountryPlace &&
                    selectedCountryPlace.maxSizeOfMobileNumber,
                  "data-testid": "mobileNo"
                }}
                sx={{ ml: 1, color: "#0F0F10" }}
              />
            </Box>
            {registerMobileNumberError && (
              <FormHelperText
                sx={{ color: "#DE0909", textAlign: "left" }}
                className="errorFormHelperText"
              >
                {registerMobileNumberError}{" "}
              </FormHelperText>
            )}
          </ThemeProvider>
        </Box>
        <Button
          type="submit"
          disabled={isConfirmButtonDisable()}
          data-testid="forgotButton"
          sx={{
            mt: 4,
            mb: 2,
            borderRadius: "20px",
            pl: 7,
            pr: 7,
            fontSize: "15px",
            width: "200px",
            height: "40px",
            textTransform: "none",
            fontFamily: "BukraMedium",
            background:
              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
            color: "#ffffff",
            "&.Mui-disabled": {
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              opacity: 0.5
            }
          }}
          onClick={() => handleConfirmForgotPassword()}
        >
          {CONFIRM}
        </Button>
      </Box>
    </Paper>
  );
};

export default ForgotPassword;
