import React, { useEffect, useState } from "react";

/**
 * Handles the UI for the Idle Screen popup time counter
 * @param {object} props - Prop elements passed to the Component
 * @returns {component} The counter for the remain time to logout
 */
const IdleRemainingTime = (props) => {
  const { getRemainingTime } = props;
  const [remaining, setRemaining] = useState(30);

  /**
   * Performs the Set Interval function and sets the remaining time after every 30 seconds.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });
  return (
    <>
      <b
        data-testid="timer"
        style={{
          paddingRight: "5px",
          paddingLeft: "5px"
        }}
      >
        {remaining}
      </b>
    </>
  );
};

export default IdleRemainingTime;
