import { CircularProgress } from "@mui/material";
import React from "react";

/**
 * Renders the Side Loader UI component.
 * @param {{size:number}} props - Size of the Loader.
 * @returns {JSX} the Side Loader UI component.
 */
const SideLoader = ({ size }) => {
  return (
    <>
      <CircularProgress size={size ? size : 30} sx={{ px: 1 }} />
    </>
  );
};

export default SideLoader;
