import Credits_Default from "./../assets/images/credits_default.png";
import Credits_Active from "./../assets/images/credits_active.png";
import Dashboard_Active from "./../assets/images/dashboard_active.png";
import Dashboard_Default from "./../assets/images/dashboard_default.png";
import Stock_Default from "./../assets/images/stock_default.png";
import Stocks_Active from "./../assets/images/stock_active.png";
import SubDistributor_Active from "./../assets/images/sub_distributor_active.png";
import SubDistributor_Default from "./../assets/images/sub_distributor_default.png";
import subscriptions_active from "./../assets/images/subscriptions_active.png";
import subscriptions_default from "./../assets/images/subscriptions_default.png";
import CryptoJS from "crypto-js";
import {
  ACCIDENTALLY_TRANSFERRED,
  ALLTIME_DATE,
  EMAIL,
  EMAIL_REQUIRED,
  PLEASE_ENTER_A_VALID,
  REACT_APP_ENCRYPT_STORAGE,
  THIS_MONTH,
  TRANSFERRED_EXTRA_CREDITS,
  TRANSFERRED_TO_WRONG_RETAILER,
  TRANSFERRED_TO_WRONG_SD,
  ONLY_ALPHA_CHARS,
  MOBILE_NUMBER,
  MOBILE_NO_LEFT_BLANK_ERR_LABEL,
  DIGITS,
  TOAST_SUCCESS,
  TOAST_ERROR,
  TOAST_AWAIT,
  FAULTY_TAB,
  ASSIGNED,
  ALLOCATED,
  EMAIL_LEFT_BLANK_ERR_LABEL,
  MASTER_DISTRIBUTOR,
  SUB_DISTRIBUTOR,
  RETAILER,
  MD_USER_ROLE_TYPE,
  SD_USER_ROLE_TYPE,
  FIRST_NAME_VALID,
  LAST_NAME_VALID,
  COMPANY_NAME_REGEX,
  DIRECT_KEY,
  REVERSE_CREDIT_KEY,
  REVERSE_DIRECT_KEY,
  CREDIT_KEY,
  MOBILE_NUMBER_REQUIRED
} from "./Constants";
import { getIntialLogHistory } from "./ScopeMenus";

const secretPass = REACT_APP_ENCRYPT_STORAGE;

/**
 * Capitalize the word.
 * @param {string} string - A Text string
 * @returns {string} string in capitalized format
 */
export const capitalizeFirstLetter = (string) => {
  return string
    ? string.charAt(0).toUpperCase() + string.toLocaleLowerCase().slice(1)
    : "";
};

/**
 * Format the time
 * @param {number} ResetInterval
 * @returns {string} the Formatted Time string
 */
export const formatTime = (ResetInterval) => {
  return `${String(Math.floor(ResetInterval / 60)).padStart(2, "0")}:${String(
    ResetInterval % 60
  ).padStart(2, "0")}`;
};

/**
 * Set a Timer.
 * @param {number} ResetInterval
 * @returns {string} formatted time.
 */
export const Timer = (ResetInterval) => {
  return formatTime(ResetInterval);
};

/**
 *@constant {array<object>} sideBarImage Sidebar Image Content containing the Image, Image width and Image height as an array of object.
 */
export const sideBarImage = [
  {
    id: "credits",
    defaultImage: Credits_Default,
    activeImage: Credits_Active,
    width: " 16px",
    height: "16px"
  },
  {
    id: "dashboard",
    defaultImage: Dashboard_Default,
    activeImage: Dashboard_Active,
    width: " 11px",
    height: "11px"
  },
  {
    id: "stb",
    defaultImage: Stock_Default,
    activeImage: Stocks_Active,
    width: " 12px",
    height: "12px"
  },
  {
    id: "trackstb",
    defaultImage: Stock_Default,
    activeImage: Stocks_Active,
    width: " 12px",
    height: "12px"
  },

  {
    id: "subdistributor",
    defaultImage: SubDistributor_Default,
    activeImage: SubDistributor_Active,
    width: " 13px",
    height: "12px"
  },
  {
    id: "retailers",
    defaultImage: SubDistributor_Default,
    activeImage: SubDistributor_Active,
    width: " 13px",
    height: "12px"
  },
  {
    id: "subscription",
    defaultImage: subscriptions_default,
    activeImage: subscriptions_active,
    width: " 12px",
    height: "12px"
  },
  {
    id: "hardware",
    defaultImage: Stock_Default,
    activeImage: Stocks_Active,
    width: " 12px",
    height: "12px"
  },
  {
    id: "customer",
    defaultImage: SubDistributor_Default,
    activeImage: SubDistributor_Active,
    width: " 13px",
    height: "12px"
  }
];

export const formatKsaTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};
export const formatRelativeKsaTime = (dateString) => {
  const ksaTime = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
  );
  let currentDateTime = new Date(
    new Date(
      ksaTime.getFullYear() +
        "-" +
        (ksaTime.getMonth() + 1) +
        "-" +
        ksaTime.getDate()
    )
  );

  let backendTimestamp = new Date(dateString);

  // Time difference in milliseconds
  let timeDifference = backendTimestamp.getTime() - currentDateTime.getTime();

  // Convert time difference to days
  let daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

  // Instantiate Intl.RelativeTimeFormat
  let rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  // Format the difference in a relative format
  let formattedRelativeDifference = rtf.format(daysDifference, "day");

  return formattedRelativeDifference;
};

export const ksaRelativeTime = (dateString) => {
  const formatedTime = `${CapitalizeLetter(
    formatRelativeKsaTime(dateString)
  )} at ${CapitalizeLetter(formatKsaTime(dateString)).toUpperCase()}`;
  return `${formatedTime}`;
};
/**
 * Gives the Message if Email is Valid and returns the Error Message.
 * @function validateEmail - Performs Email validation.
 * @param {string} values - Email string
 * @returns {string} string containing the Errors regarding email Validation.
 */
export const validateEmail = (values, isFromLogin) => {
  let errors = "";
  const regex = /^([a-zA-Z0-9_.]+)@([a-zA-Z0-9_.]+)\.([a-zA-Z]{2,5})$/;
  if (values === "") {
    errors = isFromLogin ? EMAIL_LEFT_BLANK_ERR_LABEL : EMAIL_REQUIRED;
  } else if (!regex.test(values)) {
    errors = `${PLEASE_ENTER_A_VALID} ${EMAIL}`;
  }
  return errors;
};

/**
 * Display Breadcrumb text based on the URL.
 * @function getBreadcrumbLabels - Provides breadcrumb string based on URL.
 * @param {string} url - URL of Application.
 * @returns {string} Breadcrumb Text based on the URL
 */
export const getBreadcrumbLabels = (url) => {
  const breadCrumbLabels = [
    { url: "companyprofile", label: "Company Profile" },
    { url: "subdistributorprofile", label: "Sub Distributor Profile" },
    { url: "subdistributorviewprofile", label: "Sub Distributor Profile" },
    { url: "editprofile", label: "Edit Profile" },
    { url: "subscribenow", label: "Subscribe Now" },
    { url: "customerregistration", label: "Customer Registration" },
    { url: "subdistributor", label: "Sub Distributor" },
    { url: "retaileronboarding", label: "Retailer Onboarding" },
    { url: "transfercredits", label: "Transfer Credits" },
    { url: "reversecredits", label: "Reverse Credits" },
    { url: "trackstb", label: "Track STB" },
    {
      url: "hardwareallocationandreplacement",
      label: "Allocation & Replacement"
    },
    { url: "retailerprofile", label: "Retailer Profile" },
    { url: "myprofile", label: "My Profile" },
    { url: "masterdistributorprofile", label: "Master Distributor Profile" },
    { url: "stb", label: "STB" }
  ];
  const urlLabelObj = breadCrumbLabels.find((item) => item.url === url);
  return urlLabelObj && urlLabelObj.label ? urlLabelObj.label : url;
};

/**
 * Converts current Date Obejct to DD-MM-YYYY format
 * @function getCurrentDateFormat - Gives current Date Obejct in DD-MM-YYYY format
 * @returns {string} date string on DD-MM-YYYY format.
 */
export const getCurrentDateFormat = () => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${day}-${month}-${year}.`;
};

/**
 * Converts Date String into DD-MM-YYYY format.
 * @function getDDMMYYYYDateFormat- gives date in DD-MM-YYYY format.
 * @param {string} current -  date in string format.
 * @returns date string into DD-MM-YYYY format.
 */
export const getDDMMYYYYDateFormat = (current) => {
  let date = new Date(current);
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

/**This method is created for cross-browser compatibility,
sorting the Array
if you don't need to support IE11, you can use Array.prototype.sort() directly
*/
export const tableSorting = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

/**
 * Converts STRING to its relative Value
 * @function helper - Gives the number equivalent ogf HOURS,DAY,MONTH etc.
 * @param {string} key - Capital String like HOUR, HOURS, DAY,DAYS,MONTH, MONTHS, YEAR, YEARS
 * @returns {numnber} Integer based on the time type.
 */
const helper = (key) => {
  switch (key) {
    case "HOUR":
      return 1;
    case "HOURS":
      return 2;
    case "DAY":
      return 3;
    case "DAYS":
      return 4;
    case "MONTH":
      return 5;
    case "MONTHS":
      return 6;
    case "YEAR":
      return 7;
    case "YEARS":
      return 8;
    default:
      return 9;
  }
};
/**
 *  Sort the Data based on the duration.
 * @param {string} a - Duration in String
 * @param {string} b - Duration in String
 * @returns the sorted Order based on the Duration
 */
const durationSort = (a, b) => {
  if (a.period !== b.period) {
    return helper(a.period) - helper(b.period);
  }
  return a.duration - b.duration;
};

/**
 * Provides the Ascending and descending Order of the Table.
 * @param {array} a - From Table Data in array
 * @param {array} b - To Table Data in Array
 * @param {string} orderBy
 * @returns {number} The Ascending or Descending order of the Table based on Type.
 */
function descendingComparator(a, b, orderBy, formatSorting) {
  if (
    (!formatSorting && orderBy === "createdDateTime") ||
    (!formatSorting && orderBy === "publishStartDate") ||
    orderBy === "trasferDate"
  ) {
    return (
      new Date(convertDateFormatToYYYYMMDD(b[orderBy])).valueOf() -
      new Date(convertDateFormatToYYYYMMDD(a[orderBy])).valueOf()
    );
  } else if (
    orderBy === "id" ||
    orderBy === "addedDateKSA" ||
    orderBy === "createdDateTime" ||
    orderBy === "updatedDate" ||
    orderBy === "modifiedDateTime"
  ) {
    return new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf();
  } else if (orderBy === "duration") {
    return durationSort(a, b);
  } else if (b[orderBy] < a[orderBy]) {
    return -1;
  } else if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Helper Function.
 * @param {array} order - From Table Data in array
 * @param {string} orderBy - To Table Data in Array
 * @returns {*} The Ascending or Descending order of the Table based on Type.
 */
export function getComparator(order, orderBy, formatSorting) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, formatSorting)
    : (a, b) => -descendingComparator(a, b, orderBy, formatSorting);
}

/**
 * encryptData function will return the encrypted data
 */
export const encryptData = (content) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(content),
    secretPass
  ).toString();
  return data;
};

/**
 *  The decryptData function will take encrypted data as an argument and return the decrypted value.
 * */
export const decryptData = (content) => {
  const bytes = CryptoJS.AES.decrypt(content, secretPass);
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return data;
};

// regexExpression support Minimum eight characters, at least one uppercase letter and one special character
export const regexExpression = () => {
  return /^(?=.*?[A-Z])(?=.*?[#?!@$%^&*]).{8,}$/gm;
};

/**
 * Removes the whiteSpaces from the string
 * @param {string} str - A text string.
 * @returns {string} string without whitespaces.
 */
export const removeWhiteSpaceInString = (str) => {
  return str ? str.replace(/\s/g, "") : "";
};

/**
 * Get current Date
 * @returns {string} current date in DD-MM-YYYY string format.
 */
export const getToday = () => {
  const date = new Date();
  let currentDate = getDDMMYYYYDateFormat(date);
  return currentDate;
};

/**
 * Gives the Current Year from the First day of the Year
 * @returns {string} current Year
 */
export const getThisYear = () => {
  const date = new Date(new Date().getFullYear(), 0, 1);
  let currentYearFirstDate = getDDMMYYYYDateFormat(date);
  return currentYearFirstDate;
};

/**
 * Gives the Current Month from the First day of the Month
 * @returns Current Month
 */
export const getThisMonth = () => {
  let date = new Date();
  let currentMonthFirstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  return getDDMMYYYYDateFormat(currentMonthFirstDate);
};

/**
 * Returns the Select TimeFrame from the Custom date filter
 * @param {string} label
 * @param {string} id
 * @returns {object} the Date range
 */
export const getStartDateAndEndDate = (label, id) => {
  const today = getToday();
  const currentMonthStartDate = getThisMonth();
  let date = {
    label: THIS_MONTH,
    startDate: currentMonthStartDate,
    endDate: today
  };
  switch (id) {
    case "this_year":
      return {
        ...date,
        label: label,
        startDate: getThisYear(),
        endDate: today
      };
    case "all_time":
      return { ...date, label: label, startDate: ALLTIME_DATE, endDate: today };
    case "to_day":
      return { ...date, label: label, startDate: today, endDate: today };
    default:
      return { ...date, label: label ? label : THIS_MONTH };
  }
};

/**
 * Blocks to type the Invalid Characters in the Number Format Input
 * @param {string} e
 *
 */
export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

/**
 * An array of string for the Reverse Transfer Reason for Subdistributor.
 */
export const transferReasonForRetailer = [
  ACCIDENTALLY_TRANSFERRED,
  TRANSFERRED_EXTRA_CREDITS,
  TRANSFERRED_TO_WRONG_RETAILER
];

/**
 * An array of string fpr the Reverse Transfer Reason for Retailer.
 */
export const transferReasonForSubDistributor = [
  ACCIDENTALLY_TRANSFERRED,
  TRANSFERRED_EXTRA_CREDITS,
  TRANSFERRED_TO_WRONG_SD
];

/**
 *
 * @param {string} amount - Amount in string format
 * @param {string} country - countryCode in String format
 * @returns {string} the Amount in string format with the Related Country Format.
 */
export const currencyConvertToReadable = (amount) => {
  return Number(amount).toLocaleString(`en-US`, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};

/**
 * Converts Amount in string format to Integer format
 * @param {string} amount - Amount in String Format
 * @returns {number} Amount in Integer format
 */
export const convertStringToNumber = (amount) => {
  return Number(amount);
};

/**
 * Convert Date String to the YYYY-MM-DD format.
 * @param {dateString} dateString
 * @returns {string} Date string in YYYY-MM-DD format.
 */
export const convertDateFormatToYYYYMMDD = (dateString) => {
  let parts = dateString.split("-");
  let dateObject = new Date(parts[2], parts[1] - 1, parts[0]);
  let year = dateObject.getFullYear();
  let month = String(dateObject.getMonth() + 1).padStart(2, "0");
  let day = String(dateObject.getDate()).padStart(2, "0");
  let newDateString = year + "-" + month + "-" + day;
  return newDateString;
};
export const timingFormat = (date) => {
  const time = new Date(date);
  const getHours = String(time.getHours()).padStart(2, "0");
  const getMinutes = String(time.getMinutes()).padStart(2, "0");
  const getSeconds = String(time.getSeconds()).padStart(2, "0");
  const formatTimeKSA = `${getHours}:${getMinutes}:${getSeconds}`;
  return formatTimeKSA;
};
/**
 * Check if the Name is Valid or not.
 * @param {string} name - Input String.
 * @returns {boolean} true or false whether its a valid string or not based on the string value
 */
export const validName = (name) => {
  const isValid = ONLY_ALPHA_CHARS.test(name);
  return isValid;
};
/**
 * Check if the Name is Valid or not.
 * @param {string} name - Input String.
 * @returns {boolean} true or false whether its a valid string or not based on the string value
 */
export const validFirstName = (name) => {
  const isValid = FIRST_NAME_VALID.test(name);
  return isValid;
};
/**
 * Check if the Name is Valid or not.
 * @param {string} name - Input String.
 * @returns {boolean} true or false whether its a valid string or not based on the string value
 */
export const validLastName = (name) => {
  const isValid = LAST_NAME_VALID.test(name);
  return isValid;
};
/**
 * Check if the comapany Name is Valid or not.
 * @param {string} name - Input String.
 * @returns {boolean} true or false whether its a valid string or not based on the string value
 */
export const validCompanyName = (name) => {
  const isValid = COMPANY_NAME_REGEX.test(name);
  return isValid;
};

/**
 * Validate if text is Alphanumeric
 * @param {string} input - Input String.
 * @returns {boolean} true or false based on the input string
 */
export const validateAlphanumeric = (input) => {
  const regex = /^[a-zA-Z0-9]+$/;
  return regex.test(input);
};

/**
 * Validate if the Mobile is Valid or not.
 * @param {string} values - Mobile Number in string format
 * @param {string} country - Country code of the Current Country.
 * @returns {string} the Error if the Mobile no. is not valid.
 */
export const validateRegisterMobileNumber = (
  values,
  country,
  isFormForgotPassword
) => {
  const validMobileNo = new RegExp(country.regEx);
  let errors;
  if (values === "") {
    errors = isFormForgotPassword
      ? MOBILE_NO_LEFT_BLANK_ERR_LABEL
      : MOBILE_NUMBER_REQUIRED;
  } else if (!validMobileNo.test(values)) {
    errors = `${PLEASE_ENTER_A_VALID} ${country.maxSizeOfMobileNumber} ${DIGITS} ${MOBILE_NUMBER}.`;
  } else {
    errors = "";
  }
  return errors;
};

/**
 The dateDifference function takes two arguments, the starting and ending dates, and returns the result in months, days, or hours. If months is less than 1 month, then return days; if day is less than 1, return hours. 
 */
/**
 * Calculate the date difference between the StartDate nad endDate.
 * @param {string} startingDate - Starting date
 * @param {string} endingDate  - Ending date.
 * @returns {string} the Remaining the days in Month , days or Hour in string format.
 */
export const dateDifference = (startingDate, endingDate) => {
  let startDate = new Date(startingDate);

  let endDate = new Date(endingDate);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();
  const february =
    (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
      ? 29
      : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  let yearDiff = endDate.getFullYear() - startYear;
  let monthDiff = endDate.getMonth() - startDate.getMonth();
  let hourDiff = endDate.getHours() - startDate.getHours();

  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  let dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }
  const totalMonths = yearDiff * 12 + monthDiff;
  if (totalMonths) {
    const displayMonth =
      totalMonths > 1 ? `${totalMonths} Months` : `${totalMonths} Month`;
    return displayMonth;
  } else if (dayDiff) {
    const displayDay = dayDiff > 1 ? `${dayDiff} Days` : `${dayDiff} Day`;
    return displayDay;
  } else {
    const displayHour = hourDiff > 1 ? `${hourDiff} Hours` : `${hourDiff} Hour`;
    return displayHour;
  }
};

/**
 * Add comma(,) at the end of the text.
 * @param {string} text - A text string.
 * @returns {string} the string with comma at the end
 */
export const textWithComma = (text) => {
  const isValid = (text) => text !== undefined && text !== null && text !== "" && text !== "undefined";
  // Add a comma only if the text is valid
  return isValid(text) ? `${text}, ` : "";
};

/**
 * Returns if the Inputis only number
 * @param {string} number - A String Input.
 * @returns {boolean} true if the input is only Number or else return false
 */
export const onlyNumber = (number) => {
  const validNumber = /^\d*$/;
  return validNumber.test(number);
};
/**
 * Returns if the Inputis only number
 * @param {string} number - A String Input. which have max 2 decimals
 * @returns {boolean} true if the input is only Number or else return false
 */
export const allowNumberandDecimals = (number) => {
  const validNumber = /^\d*(\.\d{0,2})?$/;
  return validNumber.test(number);
};

/**
 * Filter the Search Items based on the search Input
 * @param {Array} data - Array of Object
 * @param {string} searchType - Search type
 * @param {string} searchValue - Input String Value for Search
 * @returns {array} the Filtered Array of Object based on the the Search Input and Search Type.
 */
export const getSearchItems = (data, searchType, searchValue) => {
  const filteredData = data.filter((row) => {
    return row[searchType]
      ?.toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase());
  });
  return filteredData;
};

/**
 * Gives the First Word of the String
 * @param {string} text - String Text.
 * @returns {string} The first word of the string
 */
export const getFirstWordInString = (text) => {
  return text ? text.trim().split(" ")[0] : "";
};

/**
 * Gives the Transaction status coolr and Transaction string
 * @param {string} status - Transaction Status.
 * @returns {object} the transactionStatus object containing the transactions status color and text based on the staus.
 */
export const getTransactionStatus = (status) => {
  let transactionStatus = {};
  switch (status) {
    case "SUCCESS":
      transactionStatus.color = "#25B739";
      transactionStatus.completion = "is completed";
      break;
    case "PENDING":
      transactionStatus.color = "#F8B318";
      transactionStatus.completion = "is awaiting approval";
      break;
    case "REJECTED":
      transactionStatus.color = "red";
      transactionStatus.completion = "is rejected";
      break;
    case "FAILED":
      transactionStatus.color = "red";
      transactionStatus.completion = "is failed";
      break;
    default:
      break;
  }
  return transactionStatus;
};
export const getReceiverTransactionStatus = (transferType, status) => {
  let transactionStatus = {};
  if (transferType === CREDIT_KEY || transferType === DIRECT_KEY) {
    transactionStatus.color = "#25B739";
    transactionStatus.completion =
      "Credits to your account. Please check your wallet!";
  } else if (
    transferType === REVERSE_DIRECT_KEY ||
    transferType === REVERSE_CREDIT_KEY
  ) {
    if (status === "SUCCESS") {
      transactionStatus.color = "#25B739";
      transactionStatus.completion =
        "from your account is completed successfully. Please check your wallet!";
    } else if (status === "PENDING") {
      transactionStatus.color = "#F8B318";
      transactionStatus.completion =
        " Credits from your account and is awaiting approval";
    } else {
      transactionStatus.color = "red";
      transactionStatus.completion =
        "from your account has been rejected. Please check your wallet!";
    }
  } else {
    transactionStatus.color = "#25B739";
    transactionStatus.completion = "";
  }
  return transactionStatus;
};
/**
 * Capitalize the String
 * @param {string} str - A String variable.
 * @returns {string} the capitalized letter.
 */
export const CapitalizeLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";
};

/**
 * Change the Base URL, admin URL and adminLocationBase URL based on the SERVER string value
 * @param {string} server
 * @returns {object} the Base URL, admin URL and adminLocation based on the Server String set in Environment variable.
 */
export const getUrls = (server) => {
  switch (server) {
    case "121":
      return {
        b2bBaseUrl: "http://10.1.2.121:9998",
        adminLocationBaseUrl: "http://10.1.2.121:8199",
        adminBaseUrl: "http://10.1.2.121:8000",
        crmBaseUrl: "http://10.1.2.121:8888"
      };
    case "228":
      return {
        b2bBaseUrl: "http://10.1.2.228:9998",
        adminLocationBaseUrl: "http://10.1.2.228:8199",
        adminBaseUrl: "http://10.1.2.228:8000",
        crmBaseUrl: "http://10.1.2.228:8888"
      };
    case "dev":
      return {
        b2bBaseUrl: "https://smsd.mbcplus.net",
        adminLocationBaseUrl: "https://smsd.mbcplus.net",
        adminBaseUrl: "https://smsd.mbcplus.net",
        crmBaseUrl: "https://smsd.mbcplus.net"
      };
    case "preprod":
      return {
        b2bBaseUrl: "https://smsp.mbcplus.net",
        adminLocationBaseUrl: "https://smsp.mbcplus.net",
        adminBaseUrl: "https://smsp.mbcplus.net",
        crmBaseUrl: "https://smsp.mbcplus.net"
      };
    case "prod":
      return {
        b2bBaseUrl: "https://sms.mbc-now.net",
        adminLocationBaseUrl: "https://sms.mbc-now.net",
        adminBaseUrl: "https://sms.mbc-now.net",
        crmBaseUrl: "https://sms.mbc-now.net"
      };
    default:
      return {
        b2bBaseUrl: "https://smsp.mbcplus.net",
        adminLocationBaseUrl: "https://smsp.mbcplus.net",
        adminBaseUrl: "https://smsp.mbcplus.net",
        crmBaseUrl: "https://smsp.mbcplus.net",
        test: ""
      };
  }
};

/**
 * Provides the Expiry time Difference.
 * @param {*} expireTime - Expire Time in Number format.
 * @returns {number} the timediffetence in Number format.
 */
export const getExpiryTimeDifference = (expireTime) => {
  const currentTime = new Date();
  const expiryTime = new Date(expireTime);
  const timeDifference = Math.ceil((expiryTime - currentTime) / 1000);
  return timeDifference;
};

/**
 * Converts the Milliseconds to Seconds Format
 * @param {number} expireTimeinMilliseconds - Time in millisecond.
 * @returns {number} the Second value in number format.
 */
export const convertMStoS = (expireTimeinMilliseconds) => {
  const expireTimeInSeconds = expireTimeinMilliseconds / 1000;
  return expireTimeInSeconds;
};

/**
 * Gives a Captcha String
 * @param {numnber} code - captcha length
 * @returns {string} captcha value based on the given length in parameter.
 */
export const generateCaptcha = (code) => {
  let captchaChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";
  for (let i = 0; i < code; i++) {
    captcha += captchaChars.charAt(
      Math.floor(Math.random() * captchaChars.length)
    );
  }
  return captcha;
};

/**
 * Gives the Duration label in Capitalized format
 * @param {string} label
 * @returns {string} the Duration label in Capitalized format
 */
export const getDurationLabel = (label) => {
  switch (label) {
    case "HOUR":
      return "Hour";
    case "HOURS":
      return "Hours";
    case "DAY":
      return "Day";
    case "DAYS":
      return "Days";
    case "MONTH":
      return "Month";
    case "MONTHS":
      return "Months";
    case "YEAR":
      return "Year";
    case "YEARS":
      return "Years";
    default:
      return label;
  }
};

/**
 * Gives the Error text based on the Label
 * @param {string} text - label type.
 * @returns {string} the defined text based on the text label.
 */
export const getCustomerSearchErrorLabel = (text) => {
  let label = text;
  if (text === "id") {
    label = "Customer ID";
  } else if (text === "mobileNumber") {
    label = "Mobile Number";
  } else if (text === "serialNo") {
    label = "Serial Number";
  }

  return label;
};

/**
 * Updating the Localstorage with initial set of quick links data.
 * @param {string} key - Localstorage Key value
 * @param {number} roleType - User Role Type.
 */
export const setIntialLogHistory = (key, roleType) => {
  const intialValue = getIntialLogHistory(roleType);
  const userPresent = localStorage.getItem(`QL_${key}`);
  if (!userPresent) {
    updateLocalStorage(`QL_${key}`, intialValue);
  }
};

/**
 * Increments the count of the particular page Route
 * @param {string} path - URL path.
 * @param {string} routes - URL route.
 * @returns {number} the Incremented value of the visited page.
 */
export const incrementPathCount = (path, routes) => {
  const updatedRoutes = routes.map((route) => {
    if (route.path === path) {
      route.count += 1;
    }
    return route;
  });
  return updatedRoutes;
};

/**
 * Set the localstorage.
 * @param {string} key
 * @param {*} value
 */
export const updateLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Update the localstorage on PageVisits
 * @param {string} key - Quick links key.
 * @param {string} path - URL path.
 */
export const updatePageVisit = (key, path) => {
  let parseData = localStorage.getItem(`QL_${key}`);
  const routes = JSON.parse(parseData);
  if (routes && key) {
    const incrementRoutes = incrementPathCount(path, routes);
    const reachedLimit = incrementRoutes.some((route) => route.count >= 100);
    let updatedRoutes = incrementRoutes;
    if (reachedLimit) {
      updatedRoutes = incrementRoutes.map((route) => ({
        ...route,
        count: route.count / 2
      }));
    }
    updateLocalStorage(`QL_${key}`, updatedRoutes);
  }
};

/**
 * Give the toast Details Object for successful scenario
 * @param {string} message - Toast message
 * @param {string} description - toast description
 * @returns {object} the Object containing the toast Details
 */
export const successToastMessage = (message, description) => {
  return {
    toastTitle: message,
    isToastOpen: true,
    toastDescription: description,
    toastStatus: TOAST_SUCCESS,
    toastTextclr: "#238931",
    toastBgclr: "#E6FFEE ",
    toastBorderclr: "#50B768"
  };
};

/**
 * Give the toast Details Object for Error scenario
 * @param {string} message - Toast message
 * @param {string} description - toast description
 * @returns {object} the Object containing the toast Details
 */
export const failureToastMessage = (message, description) => {
  return {
    toastTitle: message,
    isToastOpen: true,
    toastDescription: description,
    toastStatus: TOAST_ERROR,
    toastTextclr: "#FF2A2A",
    toastBgclr: "#FDE2E2 ",
    toastBorderclr: "#FF2A2A"
  };
};

/**
 * Give the toast Details Object for pending scenario
 * @param {string} message - Toast message
 * @param {string} description - toast description
 * @returns the Object containing the toast Details
 */
export const awaitToastMessage = (message, description) => {
  return {
    toastTitle: message,
    isToastOpen: true,
    toastDescription: description,
    toastStatus: TOAST_AWAIT,
    toastTextclr: "#F49009",
    toastBgclr: "#FFF2DE ",
    toastBorderclr: "#F49009"
  };
};

/**
 * Handle the Mouse Down Event
 * @param {Event} event
 * prevents the default functionality on MouseDown event.
 */
export const handleMouseDownOnAndornment = (event) => {
  event.preventDefault();
};

/**
 * Creates a downloadable link
 * @param {string} fileDownload
 * @param {string} name
 * @return {string} download the String in CSV format
 */
export const download = (fileDownload, name) => {
  const blob = new Blob([fileDownload]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${name}.csv`);
  a.click();
};

/**
 * @function getSTBStatus - based on status function return a string  of stb position
 * @param {string} key  - stb status
 * @return {string} with whom stb will be their
 */
export const getSTBStatus = (key) => {
  switch (key) {
    case "ASSIGNED":
      return "MD";
    case "ALLOCATED":
      return "Customer";
    case "ACTIVE":
      return "Customer";
    default:
      return "HO";
  }
};

/**
 * @constant {array<object>} subscriptionSalesTableHeadCells - Recent Subscription Sales table head elements initialization.
 */
export const subscriptionSalesTableHeadCells = [
  {
    id: "customerId",
    numeric: true,
    disablePadding: false,
    label: "Customer ID"
  },
  {
    id: "orderId",
    numeric: false,
    disablePadding: false,
    label: "Order ID"
  },
  {
    id: "planType",
    numeric: false,
    disablePadding: false,
    label: "Package"
  },
  {
    id: "addedDateKSA",
    numeric: false,
    disablePadding: false,
    label: "Order Date"
  },
  {
    id: "finalAmount",
    numeric: false,
    disablePadding: false,
    label: "Price"
  }
  // {
  //   id: "serialNumber",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Serial Number"
  // }
];

/**
 * @constant {array<object>} subscriptionSalesSearchOptions  - Search Select options initialization.
 */
export const subscriptionSalesSearchOptions = [
  {
    value: "finalAmount",
    label: "Price"
  },
  {
    value: "orderId",
    label: "Order ID"
  },
  {
    value: "customerId",
    label: "Customer ID"
  }
];

/**
 * This regex matches any sequence of one or more whitespace characters at the beginning of the string, or a single whitespace character that is not followed by any non-whitespace characters.
 * @param {string} input - Input String.
 * @returns {string} remove multiple whitespace and return word
 */
export const whiteSpaceInWord = (str) => {
  const character = str.replace(/\s+/g, " ").trim();
  return character;
};

export const downloadStockTransferLabel = (key) => {
  switch (key) {
    case ASSIGNED:
      return "Available Stock Report";
    case ALLOCATED:
      return "Sold Stocks Report";
    case FAULTY_TAB:
      return "Faulty Stock Report";
    default:
      return "Received Stocks Report";
  }
};
export const subtractYears = (date, years) => {
  date.setFullYear(date.getFullYear() - years);
  return date;
};

export const getUserRoleName = (role) => {
  if (role === MD_USER_ROLE_TYPE) {
    return MASTER_DISTRIBUTOR;
  } else if (role === SD_USER_ROLE_TYPE) {
    return SUB_DISTRIBUTOR;
  } else {
    return RETAILER;
  }
};

export const transferTypeLabel = (type) => {
  switch (type) {
    case DIRECT_KEY:
      return "Direct Transfer";
    case REVERSE_DIRECT_KEY:
      return "Reverse Direct Transfer";
    case REVERSE_CREDIT_KEY:
      return "Reverse Credit Transfer";
    default:
      return "Credit Transfer";
  }
};
