import React, { memo } from "react";
import { Box, Button, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import closeicon from "./../../assets/images/close.png";
import { AGREE, TERMS_AND_CONDITIONS } from "../../constant/Constants";

/**
 * Renders the Terms and Conditions Popup UI.
 * @param {{handleClosePopup: function,checked:boolean,handleCheckBox:function}} props - Props passed to the Terms and Condition Page to perform Actions.
 * @returns The terms and Conditions Popup UI component.
 */
const TermsAndConditions = ({ handleClosePopup, checked, handleCheckBox }) => {
  /**
   * Handles the Agree terms and conditions toggle operation.
   * @function agreeTerms - handles the Checkbox toggle operation.
   * @param {object} e - pointer event
   */
  const agreeTerms = (e) => {
    e.preventDefault();
    handleCheckBox();
    handleClosePopup();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      // onClose={handleClosePopup}
      data-testid="termsAndConditionPopup"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #aaa",
          px: 2
        }}
      >
        <DialogTitle
          mx="auto"
          sx={{ fontFamily: "BukraMedium", fontSize: "16px" }}
        >
          {TERMS_AND_CONDITIONS}
        </DialogTitle>
        <Box sx={{ px: 2, py: 2 }}>
          <img
            src={closeicon}
            alt="close icon"
            style={{
              height: "16px",
              width: "16px",
              cursor: "pointer",
              float: "right"
            }}
            onClick={handleClosePopup}
            data-testid="closeIcon"
          ></img>
        </Box>
      </Box>
      <DialogContent
        sx={{ fontSize: "14px", fontFamily: "BukraRegular", lineHeight: 1.5,textAlign:"justify" }}
      >
        The following terms and conditions (the "Terms and Conditions") govern
        your use of this site,&nbsp;
        <a
          href="https://partners.gobx.com/"
          style={{
            color: "#60CDF1"
          }}
          target="_blank"
          rel="noreferrer"
        >
          https://b2b.mbc-now.net
        </a>
        , and any content made available from or through this site, including
        any sub domains thereof (the "Site"). The Site is made available by MBC
        FZ LLC, referred to as "MBC," "we" or "us". We may change these Terms
        and Conditions from time to time, at any time without notice to you, by
        posting such changes at&nbsp;
        <a
          href="https://partners.gobx.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#60CDF1"
          }}
        >
          https://b2b.mbc-now.net
        </a>
        . Your continued use of the Site and/or its services after any changes
        to these Terms and Conditions are posted will be considered your
        explicit acceptance of said changes. <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          {" "}
          BY USING THE SITE YOU ACCEPT AND AGREE TO THESE TERMS AND CONDITIONS.
          If you do not agree to these Terms and Conditions, do not access or
          use the Site.
        </Box>
        <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          1. Proprietary Rights.
        </Box>{" "}
        As between you and MBC, MBC owns or controls, solely and exclusively,
        all rights, title and interest in and to the Site, all content, code,
        data and materials of the site, the look, design and organization, and
        the collection of the content, code, data and materials on the Site.
        This includes but is not limited to any copyrights, trademark rights,
        patent rights, database rights, moral rights, and other intellectual
        property and proprietary rights therein. Your use of the Site does not
        grant to you ownership of any of the above details, including content,
        code, data or materials you may access on or through the Site.
        <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          2. Prohibited Use.
        </Box>{" "}
        You may not download, post, display, publish, copy, reproduce,
        distribute, modify, perform, broadcast, transfer, create derivative
        works from, sell or otherwise exploit any content, code, data or
        materials on or available through the Site. You may not alter, edit,
        delete, remove, change, or repurpose, any content, code, data, or other
        materials on or available through the Site, including, without
        limitation, the alteration or removal of any trademarks, trade names,
        logos, service marks, or any other proprietary content or proprietary
        rights notices. If you do not abide by these terms and conditions, you
        may be subject to liability for such unauthorized use.
        <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          3. Trademarks.
        </Box>{" "}
        The trademarks, logos, service marks and trade names (collectively the
        "Trademarks") displayed on, or content available through the Site are
        registered and unregistered Trademarks of MBC and or sub-licensed
        Trademarks of third parties. The Trademarks may not be used by You in
        any manner whatsoever. Nothing contained on the Site should be construed
        as granting any license or right to use any Trademark displayed on the
        Site. Your misuse of the Trademarks displayed on any part of the Site is
        strictly prohibited, and any alleged or actual misuse is grounds for
        immediate suspension, whether temporarily or permanently, access to and
        use of the Site in MBC's sole discretion.
        <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          4. User Information.
        </Box>{" "}
        You will be asked to provide certain personalized information to us
        ("User Information") during your use of the Site. Use of User
        Information is set forth in MBC's Privacy Policy available at{" "}
        <a
          href="https://www.gobx.com/en/privacy"
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#60CDF1"
          }}
        >
          http://www.mbc-now/en/privacy-policy
        </a>
        &nbsp; or{" "}
        <a
          href="https://www.mbc.net/mbc/en/privacy.htmly"
          target="_blank"
          rel="noreferrer"
          style={{
            wordBreak: "break-all",
            color: "#60CDF1"
          }}
        >
          http://www.mbc.net/mbc/en/privacy.html
        </a>
        &nbsp;which is incorporated herein by reference for all purposes. Your
        use acknowledges and agrees that you are solely responsible for the
        accuracy and content of User Information as gathered and input on the
        Site by you.
        <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          5. Prohibited User Conduct.
        </Box>{" "}
        You warrant and agree that, while using the Site and all related
        services and features, you shall not: (a) impersonate any person or
        entity or misrepresent your affiliation with any other person or entity;
        (b) insert your own or a third party's advertising, branding or other
        promotional content into any of the Site content, materials or services,
        or use, redistribute, republish or exploit such content or service for
        any outside commercial or promotional purposes; or (c) attempt to gain
        unauthorized access to the Site or other computer systems through the
        Site. You shall not: (i) engage in spidering, "screen scraping,"
        "database scraping," harvesting of e-mail addresses, wireless addresses
        or other contact or personal information, or any other automatic means
        of obtaining lists of users or other information from or through the
        Site or the services offered on or through the Site, including without
        limitation any information residing on any server or database connected
        to the Site or the services offered on or through the Site; (ii) obtain
        or attempt to obtain unauthorized access to systems, materials or
        information through any means; (iii) use the Site or the services made
        available on or through the Site in any manner with the intent to
        interrupt, damage, disable, overburden, or impair the Site or such
        services, including, without limitation, sending mass unsolicited
        messages or "flooding" servers with requests; (iv) use the Site or the
        services or features in violation of MBC's or any third party's
        intellectual property or other proprietary or legal rights; or (v) use
        the Site or the services in violation of any applicable law. You further
        agree that you shall not attempt (or encourage or support anyone else's
        attempt) to circumvent, reverse engineer, decrypt, or otherwise alter or
        interfere with the Site or its services, or any content thereof, or make
        any unauthorized use thereof. You agree that you shall not use the Site
        in any manner that could damage, disable, overburden, or impair the Site
        or interfere with any other party's use of the Site or any of its
        services. You shall not obtain or attempt to obtain any materials or
        information through any means not intentionally made publicly available
        or provided for through the Site.
        <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          6. Right to Monitor.
        </Box>{" "}
        MBC reserves the right, but does not have an obligation, to monitor
        and/or review all materials posted to the Site by any user. We reserve
        the right to disclose any information as necessary to satisfy any law,
        regulation or government request, or to edit, or remove any information
        or materials, in whole or in part, that in MBC's sole discretion are
        objectionable or in violation of this Terms of Use, MBC's general
        policies or applicable law. We may also impose limits on certain
        features or restrict your access to part or all of the Site without
        notice or penalty if we believe you are in breach of the guidelines set
        forth in this paragraph, these terms and conditions or applicable law,
        or for any other reason without notice or liability.<br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          7.&nbsp; Indemnification.
        </Box>{" "}
        You agree to defend, indemnify and hold any employee of MBC harmless
        from any and all claims, liabilities, costs and expenses, including
        attorneys' fees, arising in any way from your use of the Site your
        placement or transmission of any message, content, information, or other
        materials through the Site, or your breach or violation whether alleged
        or actual of the applicable law or of these Terms and Conditions. MBC
        reserves the right, at its own expense, to assume the exclusive defense
        and control of any matter otherwise subject to indemnification by you,
        and in such case, you agree to cooperate with MBC should it be necessary
        to file such a claim. <br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          8. Termination.
        </Box>{" "}
        We may terminate, change, suspend or discontinue any aspect of the Site
        or its services at any time. We may restrict, suspend or terminate your
        access to the Site and/or its services if we believe you are in breach
        of our terms and conditions or applicable law, or for any other reason
        without notice or liability.<br></br><br></br>
        <Box component={"span"} sx={{ fontWeight: "bold" }}>
          9. Applicable Laws.
        </Box>{" "}
        MBC is headquartered and operates from Dubai, United Arab Emirates. You
        agree to comply with all local, governorate, and national laws, statues,
        regulations, and ordinances that apply to your use of theSite.You
        expressly agree to the exclusive jurisdiction of the Dubai International
        Financial Centre Courts in Dubai, UAE for any claim or dispute with MBC,
        these terms and conditions, or for any claim or dispute related to your
        use of the Site.
        <Box sx={{ display: "block", textAlign: "center", mt: 3 }}>
          {!checked && (
            <Button
              data-testid="agreeButton"
              onClick={(e) => agreeTerms(e)}
              sx={{
                width: "200px",
                height: "40px",
                borderRadius: "20px",
                fontFamily: "BukraMedium",
                textTransform: "capitalize"
              }}
            >
              {AGREE}
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default memo(TermsAndConditions);
