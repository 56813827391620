import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  email: "",
  mobileNumber: "",
  countryCode: ""
};

/**
 * Saves the User info into store while resetting the password.
 */
const userResetPasswordDetailsSlice = createSlice({
  name: "userResetPasswordDetails",
  initialState,
  reducers: {
    setUserResetPassword: (state, action) => {
      state.email = action.payload.email;
      state.mobileNumber = action.payload.mobileNumber;
      state.countryCode = action.payload.countryCode;
    }
  }
});

/**
 * Fetches the User details from store.
 * @param {object} state - Store state.
 * @returns {object} - User details to reset password.
 */
export const getUserResetPasswordDetails = (state) => {
  return state.userResetPasswordDetails;
};
export const { setUserResetPassword } = userResetPasswordDetailsSlice.actions;
export default userResetPasswordDetailsSlice.reducer;
