import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  VIEW,
  PROFILE,
  CREDIT_TRANSFER,
  REVENUE,
  STATUS,
  ACTIVE,
  BLOCKED,
  AWAITING_APPROVAL,
  INACTIVE
} from "./../../constant/Constants";
import blue_clr_bg from "./../../assets/images/blue_clr_bg.svg";
import profile from "./../../assets/images/sub_distributor_profile.svg";
import mobileNumber from "./../../assets/images/mobile_number.svg";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import {
  CapitalizeLetter,
  currencyConvertToReadable
} from "../../constant/Utils";

/**
 * Renders the Revenue cars UI
 * @param {object} props viewChild,revenueCardDetails,userProfileStatus,routeToProfile
 * @returns the UI component for the Revenue card containing revenue details.
 */
const RevenueCard = ({
  viewChild,
  revenueCardDetails,
  userProfileStatus,
  routeToProfile,
  ...props
}) => {
  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const navigateTo = useNavigate();

  /**
   * Button action to navigate to Credits Trans page based on the Revenue card selected.
   * @function transferToChildChannelPartner - Performs action on click of credit transfer.
   * @param {string} childChannelPartnerCode - Child Channel Partner code.
   * @param {object} e - Credit transfer button event object.
   */
  const transferToChildChannelPartner = (childChannelPartnerCode, e) => {
    e.preventDefault();
    navigateTo("/credits/transfercredits", {
      state: { channelPartnerCode: childChannelPartnerCode }
    });
  };

  const renderStatuslabel = (status) => {
    return status === INACTIVE ? AWAITING_APPROVAL : CapitalizeLetter(status);
  };
  const disableViewProfile = (status) => {
    let isDisable = true;
    if (status === ACTIVE || status === BLOCKED) {
      isDisable = false;
    }
    return isDisable;
  };
  return (
    <Box data-testid="revenueCard">
      {revenueCardDetails && revenueCardDetails.length > 0 && (
        <Grid
          container
          spacing={{ xs: 2, md: 3, sm: 1 }}
          columns={{ xs: 2, sm: 8, lg: 12 }}
          sx={{ pt: 2 }}
        >
          {revenueCardDetails.map((item, index) => {
            const viewProfileStatus = disableViewProfile(item.status);
            return (
              <Grid
                item
                xs={2}
                sm={4}
                md={4}
                key={`${item.channelPartnerCode}`}
                sx={{
                  transition: "0.15s ease-in-out",
                  "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    border: " 1px solid #7070704",
                    borderRadius: "8px",
                    p: "4px"
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${blue_clr_bg})`,
                      borderRadius: "8px",
                      backgroundSize: "100%",
                      backgroundPosition: "top",
                      backgroundRepeat: "no-repeat"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        pt: 2
                      }}
                    >
                      <img
                        src={profile}
                        alt="profile"
                        style={{
                          height: "32px",
                          width: "32px"
                        }}
                      ></img>
                      <Typography
                        data-testid="name"
                        sx={{
                          fontFamily: "BukraMedium",
                          fontSize: "13px",
                          color: "#464E5F",
                          letterSpacing: "0px",
                          pt: 0.4,
                          px: 1,
                          wordBreak: "break-all"
                        }}
                      >
                        {`${item.channelPartnerName} [${item.companyName}]`}
                      </Typography>
                      <Typography
                        data-testid="number"
                        sx={{
                          fontFamily: "BukraRegular",
                          fontSize: "10px",
                          pt: 0.4,
                          color: "#465964",
                          letterSpacing: "0px"
                        }}
                      >
                        <img src={mobileNumber} height="8px" alt="m"></img>{" "}
                        {item.channelPartnerMobileNumber}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pt: 4,
                        pl: 2,
                        pr: 2,
                        pb: 3
                      }}
                    >
                      <Box>
                        <Typography
                          data-testid="title"
                          sx={{
                            fontFamily: "BukraRegular",
                            fontSize: "11px",
                            color: " #464E5F",
                            letterSpacing: "0px"
                          }}
                        >
                          {REVENUE}
                        </Typography>
                        <Typography
                          data-testid="count"
                          sx={{
                            fontFamily: "BukraMedium",
                            color: "#202020",
                            fontSize: "13px",
                            letterSpacing: "0px"
                          }}
                        >
                          {`${
                            country.countryCurrencyCode
                          } ${currencyConvertToReadable(item.totalRevenue)}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          data-testid="title"
                          sx={{
                            fontFamily: "BukraRegular",
                            fontSize: "11px",
                            color: " #464E5F",
                            letterSpacing: "0px",
                            textAlign: "end"
                          }}
                        >
                          {STATUS}
                        </Typography>
                        <Typography
                          data-testid="count"
                          sx={{
                            fontFamily: "BukraMedium",
                            color: "#202020",
                            fontSize: "13px",
                            letterSpacing: "0px"
                          }}
                        >
                          {renderStatuslabel(item.status)}
                        </Typography>
                      </Box>
                    </Box>
                    {viewChild && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          pb: 1
                        }}
                      >
                        <Button
                          data-testid="viewProfileButton"
                          variant="outlined"
                          disabled={viewProfileStatus}
                          sx={{
                            borderRadius: "20px",
                            borderColor: "#0F57CB",
                            textTransform: "none",
                            fontFamily: "BukraRegular",
                            letterSpacing: 0,
                            color: "#0F57CB",
                            height: "35px",
                            width: "129px",
                            p: 0,
                            fontSize: { lg: "15px", xs: "12px" }
                          }}
                        >
                          <Link
                            to={`${routeToProfile}/${item.channelPartnerCode}`}
                            style={{
                              fontSize: { lg: "15px", xs: "12px" },
                              fontFamily: "BukraRegular",
                              width: "inherit",
                              height: "inherit",
                              textDecoration: "none",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: viewProfileStatus ? "#C1C2C3 " : "#0F57CB",
                              cursor: viewProfileStatus ? "none" : "pointer"
                            }}
                          >
                            {VIEW} {PROFILE}
                          </Link>
                        </Button>

                        <Button
                          data-testid="creditTransferButton"
                          disabled={item.status !== "ACTIVE"}
                          onClick={(e) =>
                            transferToChildChannelPartner(
                              item.channelPartnerCode,
                              e
                            )
                          }
                          variant="outlined"
                          sx={{
                            borderRadius: "20px",
                            height: "35px",
                            width: "160px",
                            fontSize: { lg: "15px", xs: "12px" },
                            textTransform: "none",
                            fontFamily: "BukraMedium",
                            letterSpacing: 0,
                            color: "#ffffff",
                            background:
                              "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                            "&.Mui-disabled": {
                              background:
                                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                              opacity: 0.5,
                              color: "#ffffff"
                            }
                          }}
                        >
                          {CREDIT_TRANSFER}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default RevenueCard;
