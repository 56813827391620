import { Outlet, Navigate } from "react-router-dom";

/**
 * Performs action based on the Protected and Public routes.
 * @returns {component} the Protected Routes Operation
 */
const ProtectedRoutes = () => {
  return sessionStorage.getItem("userToken") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};
export default ProtectedRoutes;
