import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { readNotification } from "../../services/user.services";
import { getBroadcastNotificationFromStore } from "../../stateManagement/reducers/BroadcastNotificationSlice";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import PromotionsPopup from "../promotionsPopup/PromotionsPopup";

const WelcomeMessage = () => {
  const [data, setdata] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const notifactionMessage = useSelector((state) =>
    getBroadcastNotificationFromStore(state)
  );
  const config = useSelector((state) => getConfigDetails(state));
  const broadcastMessages = useMemo(
    () =>
      notifactionMessage.length > 0 &&
      notifactionMessage.filter((item) => item.popup),
    [notifactionMessage]
  );

  useEffect(() => {
    setdata(broadcastMessages);
    if (
      currentIndex < broadcastMessages.length &&
      broadcastMessages[currentIndex].popup
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [currentIndex, broadcastMessages]);

  const handleClosePromotionsPopup = (promotionsMsg) => {
    setShowModal(false);
    setCurrentIndex(currentIndex + 1);
    if (!promotionsMsg.isViewed) {
      const payload = {
        messageId: promotionsMsg.id
      };
      readNotification(config.server, payload);
    }
    if (currentIndex === broadcastMessages.length - 1) {
      sessionStorage.setItem("showInitialModal", "");
    }
  };
  const messageDetails = data[currentIndex];
  return (
    <>
      {data && data.length > 0 && showModal && (
        <PromotionsPopup
          showAlertImg={true}
          showModal={showModal}
          messageDetails={messageDetails}
          handleClosePromotionsPopup={handleClosePromotionsPopup}
        ></PromotionsPopup>
      )}
    </>
  );
};

export default memo(WelcomeMessage);
