export const RETAILER_USER_ROLE_TYPE = 4;
export const MD_USER_ROLE_TYPE = 2;
export const SD_USER_ROLE_TYPE = 3;
export const RESEND_CODE_TIME = 60;
export const CODE_EXPIRE_TIME = 300;
export const RECENT_CREDITS_LIMIT = 50;
export const LIMIT_ADD_TO_CART = 2;
export const ALLTIME_DATE = "01-01-1996";
export const CUSTOM_FILTER_MIN_DATE = "1996/01/01";
export const PASSCODE_LIMIT = 4;
export const LOADMOREDATE_LIMIT = 300;
export const MAX_OTP_LENGTH = 6;
export const STB_MAX_LENGTH = 50;
export const MAX_POSTAL_CODE_LENGTH = 9;
export const MAX_NATIONALITY_LENGTH = 20;
export const NAME_MAX_LENGTH = 50;
export const IDLE_LOGOUT_TIME = 1000 * 60 * 20;
export const PROMPT_BEFORE_IDLE_LOGOUT_TIME = 1000 * 30;
export const MAX_STB_ALLOCATE = 5;
export const MAX_COMPANY_LENGTH = 50;
export const MAX_LENGTH_50 = 50;
export const MAX_NOTES_LENGTH = 150;

export const REACT_APP_B2B_BUILD_VERSION = "v 1.0";
export const APP_NAME = "B2B App";
export const APP_VERSION = "V1";
export const ENTER_PASSWORD = "Enter Password";
export const ENTER_REGISTERED_EMAIL = "Enter Registered Email"; //remove
export const CHANGE_YOUR_PASSWORD = "Change Your Password";
export const VIEW_ALL = "View All";
export const SUB = "Sub";
export const TOP = "Top";
export const DISTRIBUTORS = "Distributors";
export const BASED_ON_REVENUE = "Based on Revenue";
export const ENTER_YOUR_NEW_PASSWORD = "Enter Your New Password";
export const CONFIRM_YOUR_NEW_PASSWORD = "Confirm Your New Password";
export const CONTINUE_TO_LOGIN = "Continue to Login";
export const CHANGE_PASSWORD_HINT =
  "Your password must include 1 uppercase letter, 1 special character, and should be a minimum of 8 characters in length.";
export const TRACKSTB = "Track STB";
export const TRANSFERCREDITS = "Transfer Credits";
export const STOCKBALANCE = "Stock Balance";
export const QUICKLINKS = "Quick Links:";
export const ACCOUNTLOCKED = "Account Locked";
export const ACCOUNTBlOCKED = "Account Blocked";
export const LOCK_ACCOUNT = "Lock Account";
export const ACCOUNTLOCKED_HEADER = "Due to 5 incorrect login attempts";
export const ACCOUNTLOCKED_SUB_HEADER_INITIAL =
  "Your account has been locked. Please contact MBC NOW partner support on";
export const ACCOUNTBLOCKED_SUB_HEADER_INITIAL =
  "Your account has been blocked.Please contact MBC NOW partner support on";
export const RECENT = "Recent ";
export const CREDIT = "Credit ";
export const CREDITS = "Credits ";
export const TRANSFER = "Transfers";
export const APPLY = "Apply";
export const RESET = "Reset";
export const TRANSFER_INFORMATION = "Transfer Information";
export const ADD_TRANSFER = "+ Add Transfer";
export const CONFIRM = "Confirm";
export const WELCOME = "Welcome!";
export const WELCOME_SUBTITTLE = "Please check your credits";
export const RESET_PASSWORD = "Reset Password";
export const CONTINUE = "Continue";
export const FORGOT_YOUR_PASSWORD = "Forgot Your Password ?";
export const ENTER_YOUR_REGISTERED_EMAIL = "Enter Registered Email ID";
export const PASSWORD_RESET_SUCESSFUL = "Password Reset Sucessful!";
export const PLEASE_AGREE_TO_THE_CONDITION =
  "Please agree to the Terms and condition";
export const ENTER_EMAIL_ID = "Please enter your registered Email Id";
export const ENTER_MOBILE = "Please enter your registered Mobile";
export const CONFIRM_BUTTON = "Confirm";
export const RECOVER_TEXT = "Recover Password";
export const CODE_EXPIRE_TEXT = "Code Expires in:";
export const ENTER_PASSCODE = "Please Enter Passcode";
export const VERIFICATION = "Verification";
export const ENTER_VERIFICATION_TEXT =
  "Please enter the verification code sent to your registered mobile number.";
export const ENTER_EMAIL_VERIFICATION_TEXT =
  "Please enter the verification code sent to your registered email.";
export const RECOVER_DATA =
  "A verification code has been sent to the registered Phone Number.Please Enter the verification code in the following screen.";

export const CREDIT_BALANCE = "Credit Balance";
export const SUB_DISTRIBUTOR_PROFILE = "Sub Distributor Profile";
export const SUB_DISTRIBUTOR = "Sub Distributor";
export const VIEW = "View";
export const TRANSFER_MODEL_INFO_TITLE =
  "Are you sure you want to repeat your transfer to same id?";
export const PROCEED = "Proceed";
export const CANCEL = "Cancel";
export const NOTES = "Notes";
export const OPTIONAL = "Optional";
export const TRANSFER_TO = "Transfer To";
export const AMOUNT = "Amount";
export const REVERSE_FROM = "Reverse From";
export const CREDIT_TRANSFER = "Credit Transfer";
export const PROFILE = "Profile";
export const REFRESH_STB = "Refresh STB";
export const STB_ID = "STB ID";
export const STOCK_TRANSFER = "Stock Transfer";
export const NOTIFICATION_CENTER = "Notification Center";
export const AWAITING_APPROVAL = "Awaiting Approval";
export const YOU_MAY_CONTINUE_WORKING = "You may continue working";
export const PASSWORD_SENT_TO_SD_SUCCESS =
  "Password sent to Sub Distributor Successfully";
export const TOAST_SUCCESS = "TOASTSUCCESS";
export const TOAST_AWAIT = "TOASTAWAIT";
export const REASON = "Reason";
export const STB_REFRESHED_SUCCESSFULLY = "STB Refreshed Successfully";
export const RECENT_SUBSCRIPTION_SALES = "Recent Subscription sales";
export const LIST_OF_SUB_DISTRIBUTOR = "List of Sub Distributor";
export const INCORRECT_EMAIL_OR_PASSWORD = "Incorrect Email Id or Password";
export const ATTEMPTS_REMAINING = "Attempts remaining.";
export const RETAILER_ONBOARDING = "Retailer Onboarding";
export const REGISTER = "Register";
export const COMPANY = "Company";
export const NAME = "Name";
export const FIRST = "First";
export const LAST = "Last";
export const EMAIL = "Email";
export const MOBILE = "Mobile";
export const ENTER = "Enter";
export const NUMBER = "Number";
export const COUNTRY = "Country";
export const CITY = "City";
export const AREA = "Area";
export const ADDRESS_1 = "Address 1";
export const ADDRESS_2 = "Address 2";
export const SELECT_CITY = "Select City";
export const SELECT_COUNTRY = "Select Country";
export const POSTAL_CODE = "Postal Code";
export const HARDWARE = "Hardware";
export const HARDWARE_TRANSACTIONS = "Hardware Transactions";
export const ALLOCATION = "Hardware Transactions";
export const PRESS_SPACE_TO_ADD_MORE_STB = "Press space to add STB";
export const SAVE = "Save";
export const SAVE_AND_SUBSCRIBE = "Save & Subscribe";
export const NEW_SUBSCRIPTION = "New Subscription";
export const CLEAR_CART = "Clear Cart";
export const PAY_NOW = "Pay Now";
export const SUBSCRIBE_NOW = "Subscribe Now";
export const LIST_OF_RETAILERS = "List of Retailers";
export const SUBSCRIPTION_RENEW_SUCEESFULLY =
  "Subscription renewed successfully.";
export const PACKAGES = "Packages";
export const PLAN_PERIOD = "Plan Period";
export const STB = "STB";
export const CLOSE = "Close";
export const TRANSACTION_DATE = "Transaction Date:";
export const ORDER = "Order:";
export const SUMMARY = "Summary";
export const TOTAL = "Total";
export const RECENT_ORDER_RECEIPT = "Order Receipt";
export const DATE_OF_BIRTH = "Date of Birth";
export const CUSTOMER_REGISTRATION = "Customer Registration";
export const STB_CUSTOMER_PLACEHOLDER =
  "(Your serial number is located on the reverse side of the box)";
export const CAPTCHA_ERROR_MSG = "Captcha mismatch. Please try again.";
export const CUSTOMER_REGISTERED_SUCCESSFULLY =
  "Customer Registered Successfully.";
export const RETAILER_REGISTERATION_AWAITING_APPROVAL =
  "Retailer account has been created successfully and pending for approval.";
export const WARRANTY_TILL = "Warranty till";
export const PASSCODE_ENTRY_ATTEMPT = "3 incorrect Passcode entry attempts";
export const PASSCODE_ERROR =
  "You cannot perform this action due to 3 incorrect passcode entry attempts. Please contact MBC NOW partner support on";
export const RECOVER_YOUR_ACCOUNT = "to recover your account.";
export const SELECT_PLAN_PERIOD = "Select Plan Period";
export const SELECT_STB = "Select STB";
export const SELECT_GENDER = "Select Gender";
export const BASE_URL = "http://10.1.2.228:9998";
export const BASE_PORT = "9998";
export const PORTAL_TYPE = "b2b";
export const PORTAL_TYPE_ADMIN = "admin";
export const PORTAL_TYPE_CRM = "crm";
export const VERSION_API = "v1";
export const MULTI_STATUS = "MULTI_STATUS";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const SIDE_NAVBAR_WIDTH = "222";
export const REMEMBER_ME = "Remember me";
export const GENERATE_NEW_CODE_TEXT = "Generate new code";
export const RESET_PASSWORD_SENT_TO_SD_SUCCESS =
  "Reset password sent to Sub Distributor Successfully";
export const ADD_NEW_TRANSFER = "Add new Transfer";
export const REPEAT_LAST_TRANSFER = "Repeat Last Transfer";
export const SCOPES = {
  LEFTMENU_DASHBOARD: "LEFTMENU_DASHBOARD",
  LEFTMENU_CREDITS: "LEFTMENU_CREDITS",
  LEFTMENU_STB: "LEFTMENU_STB",
  LEFTMENU_TRACK_STB: "LEFTMENU_TRACK_STB",
  LEFTMENU_SUB_DISTRIBUTOR: "LEFTMENU_SUB_DISTRIBUTOR",
  LEFTMENU_SUBSCRIPTION_SALES: "LEFTMENU_SUBSCRIPTION_SALES",
  LEFTMENU_RETAILER: "LEFTMENU_RETAILER",
  LEFTMENU_HARDWARE: "LEFTMENU_HARDWARE",
  LEFTMENU_CUSTOMER: "LEFTMENU_CUSTOMER",
  QUICKLINKS_TRANSFER_CREDITS: "QUICKLINKS_TRANSFER_CREDITS",
  QUICKLINKS_TRACK_STB: "QUICKLINKS_TRACK_STB",
  QUICKLINKS_STOCK_BALANCE: "QUICKLINKS_STOCK_BALANCE",
  QUICKLINKS_ADD_RETAILERS: "QUICKLINKS_ADD_RETAILERS",
  QUICKLINKS_HARDWAR_ALLOCATION: "QUICKLINKS_HARDWAR_ALLOCATION",
  QUICKLINKS_HARDWARE_REPLACEMENT: "QUICKLINKS_HARDWARE_REPLACEMENT",
  QUICKLINKS_SUBSCRIBE_NOW: "QUICKLINKS_SUBSCRIBE_NOW",
  QUICKLINKS_CUSTOMER_REGISTRATION: "QUICKLINKS_CUSTOMER_REGISTRATION",
  BUTTON_DIRECT_TRANSFER_CREDIT: "BUTTON_DIRECT_TRANSFER_CREDIT",
  BUTTON_DIRECT_GRID: "BUTTON_DIRECT_GRID",
  BUTTON_DIRECT_REVERSE_TRANSFER_CREDIT:
    "BUTTON_DIRECT_REVERSE_TRANSFER_CREDIT",
  MENU_MYPROFILE: "MENU_MYPROFILE",
  MENU_COMPANY_PROFILE: "MENU_COMPANY_PROFILE",
  MENU_CHANGE_PASSWORD: "MENU_CHANGE_PASSWORD",
  MENU_SIGN_OUT: "MENU_SIGN_OUT",
  MENU_MASTER_DISTRIBUTE_PROFILE: "MENU_MASTER_DISTRIBUTE_PROFILE",
  MENU_SUB_DISTRIBUTE_PROFILE: "MENU_SUB_DISTRIBUTE_PROFILE",
  BUTTON_DIRECT_SUBSCRIBE_NOW: "BUTTON_DIRECT_SUBSCRIBE_NOW",
  BUTTON_DIRECT_CUSTOMER_REGISTRATION: "BUTTON_DIRECT_CUSTOMER_REGISTRATION"
};
export const SELECT_SUB_DISTRIBUTOR = "Select Sub Distributor";
export const EDIT_SUB_DISTRIBUTOR_TITLE = "Edit Sub Distributor Profile";
export const UPDATE = "Update";
export const CONTACT = "Contact";
export const ADDRESS = "Address";
export const CREDIT_CARD = "CREDIT_CARD";
export const REVENUE_CARD = "REVENUE_CARD";
export const INVENTORY_CARD = "INVENTORY_CARD";
export const PASSCODE_IS_INCORRECT = "Passcode is Incorrect";
export const ATTEMPTS_LEFT = "Attempts left";
export const CREDIT_TRANSFER_SUCCESSFULL = "Credits transferred successfully.";
export const CODE_EXPIRED = "Code Expired";
export const TOAST_ERROR = "TOASTERROR ";

export const CONFIRM_PASSWORD_MISMATCH = "Confirm password mismatch.";
export const MOBILE_NUMBER_PATTERN_ERROR = "Enter valid mobile number";
export const PLEASE_ENTER_A_VALID = "Please enter a valid";
export const MOBILE_NUMBER = "Mobile Number";
export const DIGITS = "digits";
export const FORMAT = "format";
export const RETAILER = "Retailer";
export const PASSWORD_SENT_TO = "New password sent to";
export const SUCCESSFULLY = "Successfully";
export const PASSCODE_SENT_TO = "New Passcode sent to";
export const NO_DATA_FOUND = "No Data Found";
export const TRANSACTION_SUCCESS_STATUS = "SUCCESS";
export const TRANSACTION_PENDING_STATUS = "PENDING";
export const TRANSACTION_FAILURE_STATUS = "FAILED";
export const TRANSACTION_REJECTED_STATUS = "REJECTED";
export const IS_REQUIRED = "is required";
export const DIRECT_TRANSFER = "Direct Transfer";
export const REVERSE_TRANSFER = "Reverse Transfer";
export const DIRECT = "DIRECT";
export const OUT = "OUT";
export const MASTER_DISTRIBUTOR_PROFILE = "Master Distributor Profile";
export const MASTER_DISTRIBUTOR = "Master Distributor";
export const ALL_TIME = "All Time";
export const THIS_YEAR = "This Year";
export const THIS_MONTH = "This Month";
export const TO_DAY = "Today";
export const CUSTOM = "Custom";
export const BALANCE_CREDITS = "Balance Available: ";
export const NINE_GRID_TRANSACTION_ID = "Transaction ID";
export const NINE_GRID_TYPE = "Type";
export const NINE_GRID_DATE = "Date";
export const NINE_GRID_TRANSFER = "Transfer";
export const ASSIGNED = "ASSIGNED";
export const ALLOCATED = "ALLOCATED";
export const LOGIN = "Login";
export const ASSIGNED_LABEL = "Incoming";
export const ALLOCATED_LABEL = "Outgoing";
export const HISTORY_LABEL = "History";
export const ASSIGNED_ALLOCATED = "ASSIGNED,ALLOCATED";
export const ACCIDENTALLY_TRANSFERRED = "Accidentally transferred";
export const TRANSFERRED_EXTRA_CREDITS = "Transferred Extra Credits";
export const TRANSFERRED_TO_WRONG_SD = "Transferred to Wrong Subdistributor";
export const TRANSFERRED_TO_WRONG_RETAILER = "Transferred to Wrong Retailer";
export const ENTER_AMOUNT = "Enter Amount";
export const TRANSACTION_FAILED = "Transaction Failed";
export const ACTIVE = "ACTIVE";
export const ACCOUNT_UNLOCKED = "Unlock Account";
export const EDIT_PROFILE = "Edit Profile";
export const RESET_PASSCODE = "Reset Passcode";
export const CHILD_USER_CODE = "1234567890";
export const NO_DATA = "No Data";
export const UPDATE_PROFILE_SUCCESSFULLY = "profile updated successfully.";
export const REVENUE = "Revenue";
export const VERIFY_BUTTON = "Verify";
export const OTP_GENERATED_SUCCESS = "OTP Generated Successfully";
export const OTP_GENERATED_SUCCESS_DESCRIPTION =
  "OTP generated and sent to the customer's mobile number successfully.";
export const CONTINUE_TO_DASHBOARD = "Continue to Dashboard";
export const SUB_DISTRIBUTOR_REVENUE = "Sub Distributor revenue";
export const SUB_DISTRIBUTOR_COUNT = "Sub Distributor Count";
export const SUBSCRIPTIONLISTLIMIT = "15";
export const TRANSACTION_COMPLETED = "Transaction Completed";
export const ERROR = "Error";
export const ERROR_IN = "Error in";
export const TRANSACTION = "Transaction";
export const SUCCESS = "Success";
export const VIEW_INVOICE = "View Invoice";
export const CUSTOMER_ID = "Customer #";
export const ITEM_NAME = "Item Name";
export const ISSUE_DATE = "Issue Date";
export const WARRANT_EXPIRY_DATE = "Warranty Expiry Date";
export const STATUS = "Status";
export const REGISTRATION_STATUS = "Registration Status";
export const BLANK = "-";
export const ONLY_ALPHA_CHARS = /^[a-zA-Z]+(?: [a-zA-Z]+)?$/;
export const FIRST_NAME_VALID =
  /^(?=.{3,50}$)[a-zA-Z\u0600-\u06FF]+(?: [a-zA-Z\u0600-\u06FF]+)?$/;
export const LAST_NAME_VALID =
  /^(?=.{1,50}$)[a-zA-Z\u0600-\u06FF]+(?: [a-zA-Z\u0600-\u06FF]+)?$/;
export const PLEASE_ENTER_ONLY_ALPHABETS =
  "Please enter Alphabets only. Without any special chararacters";
export const NO_CONTENT = "NO_CONTENT";
export const STARTING_FROM = "starting from";
export const SEARCH = "Search";
export const HASH = "#";
export const SELECT = "Select";
export const REGISTERED = "Registered";
export const MONTH = "MONTH";
export const HOUR = "HOUR";
export const YEAR = "YEAR";
export const DAY = "DAY";
export const planPeriodOption = [
  {
    label: "None",
    value: ""
  },
  {
    label: "Day",
    value: "DAY"
  },
  {
    label: "Hour",
    value: "HOUR"
  },
  {
    label: "Monthly",
    value: "MONTH"
  },
  {
    label: "Yearly",
    value: "YEAR"
  }
];
export const NEW_SUBSCRIPTION_BOUGHT =
  "Customer subscribed to the packs successfully.";
export const NEW_SINGLE_SUBSCRIPTION_BOUGHT =
  "Customer Subscribed to the pack successfully.";
export const CREDIT_BALANCE_ERR_LABEL =
  "You have exceeded the credit balance in the wallet.";
export const REMAINING_BALANCE = "Remaining credit balance";
export const REMOVE = "Remove";
export const CART = "Cart";
export const SUPPLIED_DATE = "Supplied Date";
export const DATE_FORMAT = "DD-MM-YYYY";
export const BUY_NOW = "Buy Now";
export const HARDWARE_REPLACEMENT = "Hardware Replacement";
export const ALL = "ALL";
export const USER = "USER";
export const CUSTOMER_PRESENT_IN_THE_SYSTEM =
  "Customer is already registered. Please use 'Add STB' for the existing customer. ";
export const CUSTOMER_NOT_PRESENT_IN_THE_SYSTEM =
  "Mobile number successfully validated, please proceed with registration.";
export const VERIFY_MOBILE_NUMBER = "Verify Mobile Number";
export const GENDER = "Gender";
export const NATIONALITY = "Nationality";
export const CAPTCHA = "Captcha";
export const GENDERDATA = [
  {
    label: "Male",
    value: "Male"
  },
  {
    label: "Female",
    value: "Female"
  }
];
export const ADDEDCART = "ADDEDCART";
export const BUYNOW = "BUYNOW";
export const NO_NEW_NOTIFICATIONS = "No new notifications!";
export const ERRORS_FOUND = "Error(s) Found.";
export const STB_REGISTERED_SUCCESSFULLY = "STB Registered Successfully";
export const REACT_APP_ENCRYPT_STORAGE = "SecretpasscodeformbcAndbtwob";
export const BLOCK_ACCOUNT = "Block Account";
export const UNBLOCK_ACCOUNT = "Unblock Account";
export const PASSWORD_CHANGE_SUCCESSFUL =
  "Your password has been changed successfully.";
export const STB_ADDED_SUCCESSFULLY = "STB Added Successfully to";
export const STB_REPLACED_SUCCESSFULLY = "STB Replaced Successfully"; //remove
export const STB_REQUIRED = "STB serial no is required.";
export const FIRSTNAME_REQUIRED = "First Name is required.";
export const LASTNAME_REQUIRED = "Last Name is required.";
export const CITY_REQUIRED = "City is required.";
export const MOBILE_NUMBER_REQUIRED = "Mobile Number is required.";
export const LOGGED_OUT = "You have been logged out successfully.";
export const ACTIVE_LABEL = "Active";
export const LOADMOREDATE_HINT =
  "You've reached the maximum limit of 300 records. Please use the download option to view more.";
export const OTP_SENT_REG_MN =
  "OTP has been sent to customer's registered mobile number.";
export const NO_RESULTS_FOUND = "No results found";
export const CONFIRMATION = "Confirmation !";
export const RECENT_CREDITS_LIMIT_MSG =
  "You've reached the maximum limit of 300 records.";
export const ACTION = "Action";
export const AVAILABLE_LABEL = "Available";
export const AVAILABLE_TAB = "AVAILABLE";
export const FAULTY_LABEL = "Faulty";
export const FAULTY_TAB = "FAULTY";
export const BLOCKED = "BLOCKED";
export const INACTIVE = "INACTIVE";
export const OLD_NEW_STB_SAME = "Old and New STB cannot be the same.";
export const USER_ACCESS_STATUS = "User Access Status";
export const STB_HARDWARE_STATUS = "STB Hardware Status";
export const STB_ALLOCATION_STATUS = "STB Allocated to";
export const SOMETHING_WENT_WRONG =
  "Something went wrong. Please try after some time.";

export const TOOLTIP_DASHBOARD = "View Summary";
export const TOOLTIP_CREDITS = "Credit Transfer Details";
export const TOOLTIP_STB = "Track STB and Stock transfer details";
export const TOOLTIP_SUBDISTRIBUTOR = "Sub Distributor Management";
export const TOOLTIP_SUBSCRIPTION = "Subscription Sales & Revenue details";

export const TOOLTIP_TRACK_STB = "Check STB Status";
export const TOOLTIP_RETAILER = "Add/Update Retailers";
export const TOOLTIP_HARDWARE =
  "View all hardware transactions, Track STB, Allocate or Replace STB for the customer.";
export const TOOLTIP_CUSTOMER =
  "Customer Registration, Sell Subscription & View Sales";
export const TOOLTIP_DOWNLOAD_IMG = "Download report";
export const TOOLTIP_NINEGRID_IMG = "Hide/Unhide table columns";

export const TOOLTIP_TOP_SD_VIEWALL = "List of all Sub Distributor";
export const TOOLTIP_TOP_RT_VIEWALL = "List of all Retailer";
export const LIST_OF = "List of all";
export const TOOLTIP_RCT_VIEWALL = "View all credit transfers";

export const TOOLTIP_RS_SALES_VIEWALL =
  "View all subscription sales transactions";

export const TOOLTIP_CREDIT_BALANCE = "Credit wallet balance";
export const TOOLTIP_CREDIT_BLOCKED =
  "Credits blocked due to reverse transfer initiated by";
export const TOOLTIP_CREDIT_TRANSFERED = "Total credits transferred to";

export const TOOLTIP_SD_REVENUE = "Total revenue generated by Sub Distributors";
export const TOOLTIP_SD_COUNT = "Count of Sub Distributors";

export const TOOLTIP_RT_REVENUE = "Total revenue generated by Retailers";
export const TOOLTIP_RT_COUNT = "Total count of Retailers";
export const TOOLTIP_INVENTORY_STOCK =
  "Count of STBs available for sale in stock";
export const TOOLTIP_INVOICE_DOWNLOAD = "Download invoice";
export const BLACKLISTED = "BLACKLISTED";
export const NOT_ELIGIBLE = "Not Eligible!";
export const SELECTED_STB_BLACKLIST_MSG =
  "Selected STB is blacklisted. It cannot be replaced";
export const ALPHABETS_WITH_SPACE =
  "should include only alphabets, with a maximum of two words with a single space.";
export const ALPHABETS_ARABIC =
  "must be 3 to 50 character and should include only alphabets or Arabic characters, with a maximum of two words.";
export const LAST_NAME_ERROR =
  "must be 1 to 50 character and should include only alphabets or Arabic characters, with a maximum of two words.";
export const RESET_PASSCODE_SUCCESS =
  "Your passcode has been reset sucessfully";
export const BLOCK_ACCOUNT_SUCCESS = "Your account has been blocked";
export const UNBLOCK_ACCOUNT_SUCCESS = "Your account has been unblocked";
export const CREDIT_TRANSFER_KEY = "CREDIT_TRANSFER";
export const PASSCODE_RESET_KEY = "PASSCODE_RESET";
export const ACCOUNT_BLOCKED_KEY = "ACCOUNT_BLOCK";
export const ACCOUNT_UNBLOCKED_KEY = "ACCOUNT_UNBLOCK";
export const CREDIT_KEY = "CREDIT";
export const DIRECT_KEY = "DIRECT";
export const REVERSE_CREDIT_KEY = "REVERSE_CREDIT";
export const REVERSE_DIRECT_KEY = "REVERSE_DIRECT";
export const PENDING_KEY = "PENDING";
export const NOTIFICATION = "Notification";
export const NO_TRANSACTIONS = "No Transactions";
export const NO_CAMPAIGN = "No campaigns";
export const TRANSACTIONS_LABEL = "Transactions";
export const CAMPAIGN_LABEL = "Campaigns";
export const CREDITS_BLOCKED = "Credits Blocked";
export const SUBMIT = "Submit";
export const HARDWARE_ALLOCATION_AND_REPLACEMENT =
  "Hardware Allocation & Replacement";
export const NEW_STB = "New STB";
export const CONFIRM_STB = "Confirm STB";
export const PLAN_NAME = "Plan Name";
export const VIEW_CART = "View Cart";
export const TERMS_AND_CONDITIONS = "Terms and Conditions";
export const AGREE = "Agree";
export const I_AGREE_TO_THE = "I agree to the";
export const OF_THE_CHANNEL_PARTNER_AGREEMENT =
  "of the Channel Partner agreement";
export const STB_LOCATION = "Inventory Location";
export const EMAIL_LEFT_BLANK_ERR_LABEL = "Please enter a valid Email.";
export const PASSWORD_LEFT_BLANK_ERR_LABEL = "Please enter the password.";
export const MOBILE_NO_LEFT_BLANK_ERR_LABEL =
  "Please enter registered mobile number.";
export const EMAIL_REQUIRED = "Email is required.";
export const REGISTERED_MOBILE_NUMBER = "registered mobile number";
export const STB_NUMBER = "STB number";
export const IS_OUT_WARRANTY = "is out of warranty since";
export const CANNOT_REPLACED = "and cannot be replaced.";
export const REVERSE_TRANSFER_CREDIT_AWAITING_APPROVAL =
  "Reverse credit transfer initiated successfully and pending for approval.";
export const SUMMARY_REPORT = "Summary Report";
export const REFRESH_STB_KEY_VALUE = "Hybrid";
export const CHOOSE_THE_YEAR = "Choose the year";
export const APP_CONFIG_MISS_MSG = "App configuration missing!.";
export const CONFIRMATION_LOCK_USER_LABEL =
  "Are you sure you want to block the user?";
export const CONFIRMATION_UNLOCK_USER_LABEL =
  "Are you sure you want to unblock the user?";
export const CONFIRMATION_PASSCODE_LABEL =
  "Are you sure you want to reset passcode?";
export const CONFIRMATION_PASSWORD_LABEL =
  "Are you sure you want to reset password?";

export const KEY_AUTO_RENEWAL_ON = "ON";
export const KEY_ACTIVE = "ACTIVE";
export const KEY_CANCELED = "CANCELED";
export const KEY_EXPIRED = "EXPIRED";
export const KEY_BLOCK_USER = "BLOCK";
export const KEY_UNBLOCK_USER = "UNBLOCK";
export const KEY_RESET_PASSCODE_USER = "RESETPASSCODE";
export const KEY_RESET_PASSWORD_USER = "RESETPASSWORD";
export const TOOL_TIP_TRANSFER = "Transfer";
export const TOOL_TIP_REVERSE = "Reverse";
export const TOOLTIP_DOWNLOAD = "Download";
export const RENEW_SUBSCRIPTION = "Renew Subscription";
// export const GENERATED_FC_MESSAGING_KEY =
//   "BJfPJLsNKKCq7ePmUJ9dkA1Zp9xtIrucwXAGF2gLkqK5pak_5QehJMYWoOyzBJncdo7z8C46TyRcK6_Wb54DnEc";
export const GENERATED_FC_MESSAGING_KEY =
  "BIx5KO1rKcNdAP9HzBjH9Yi7oerzVjSSrIgQvuYWEqD3vxNS-eKLpUtzeVXpqoCGcIvoPPznHSbVAwQCGLTk13g";
export const INSUFFICENT_BALANCE_MSG =
  "Insufficient credit balance. Please reach out to SD to buy credits.";
export const NOTIFICATION_NOT_ALLOW_MSG =
  "Please enable browser notification to receive important updates.";
export const NOTIFICATION_ENABLE_PATH =
  "Site permissions > Notification > Allow";
export const STB_ALLOCATION_REPLACEMENT_LABEL = "STB Allocation/Replacement";
export const COFIRM_STB_MISMATCH = "Confirm STB mismatch";
export const COMPANY_NAME_REGEX = /^[\u0600-\u06FFA-Za-z0-9& -]{3,50}$/;
export const COMPANY_NAME_ERR_TXT =
  "Company Name must be 3 to 50 character and should include only alphabets or Arabic characters";
export const VERIFY_EMAIL_TXT = "Verify Email";
export const OTP_GENERATED_SUCCESS_DESCRIPTION_MOBILE_RT =
  "OTP generated and sent to the registered mobile number successfully.";
export const OTP_GENERATED_SUCCESS_DESCRIPTION_EMAIL_RT =
  "OTP generated and sent to the registered email successfully.";
export const TIMEZONE_HELPER_TEXT = "Note: Timezone format is Asia/Riyadh";
export const INVALID_OTP = "Invalid OTP";
export const REASON_BLOCK_CHILD_USER = [
  "Outstanding payments",
  "Unauthorized actions",
  "Malpractices on customer account"
];
export const PURCHASE_PLAN_DES =
  "You have the option to buy the identical pack once your current one expires, or you can proceed through selfcare or launcher for upgrade/downgrade";
export const PURCHASE_PLAN_TITTLE = "Subscription Restricted";
export const ACTIVE_TILL = "Active till:";
export const EXPIRED_ON = "Expired on:";
export const SAME_PASSWORD_HINT =
  "Current and new password cannot be the same.";
export const APLHA_NUMERIC_REGEX = /^[0-9a-zA-Z]*$/;
