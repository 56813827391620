import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {
  PROCEED,
  CANCEL,
  TRANSFER_MODEL_INFO_TITLE
} from "../../constant/Constants";
import close from "./../../assets/images/close.png";
import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";

/**
 * Handles the Credits Transfer Condfirmation Modal.
 * @param {object} props  - Props passed to Transfer Confirmation info modal.
 * @returns {component} Popup UI for Confirmation to Proceed with the Credits Transfer.
 */
const TransferInfoModal = (props) => {
  const {
    showTransferModal,
    transferToInfoDetails,
    handleTransferInfoModalclose,
    handleOpenPasscodeModal
  } = props;

  /**
   * Handles the Close operation for the Transfer Info Modal
   * @function handleClose - Performs the close operation for Transfer confirmation info modal.
   */
  const handleClose = () => {
    handleTransferInfoModalclose();
  };

  /**
   * Handles the Proceed Button operation
   * @function handleMultipleProceed - Proceed with the Credits Transfer Transaction
   */
  const handleMultipleProceed = () => {
    handleClose();
    handleOpenPasscodeModal();
  };

  /**
   * Handles the display of UI when Multiple Transaction with the same child channel Partner code.
   * @function getTransferToName - Gets the Child Channel Partner code name UI for the Multiple Transaction under same child channel partner code.
   * @returns {JSX} - Transfer Info if there are multiple Transaction under same name
   */
  const getTransferToName = () => {
    return (
      transferToInfoDetails &&
      transferToInfoDetails.map((user, index) => {
        return (
          <Typography
            data-testid="userName"
            key={`${index}`}
            sx={{
              pt: 0.5,
              pl: 4,
              pr: 4,
              textAlign: "center",
              fontFamily: "BukraMedium",
              letterSpacing: "0px",
              color: "#525868",
              fontSize: "13px"
            }}
          >
            {user.toUserName}
          </Typography>
        );
      })
    );
  };
  return (
    <Dialog
      data-testid="transferInfoModalDialog"
      aria-labelledby="customized-dialog-title"
      open={showTransferModal}
      sx={{ p: 1 }}
      // onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pt: 2,
          pr: 3
        }}
      >
        <img
          id="closest"
          src={close}
          alt="X"
          onClick={handleClose}
          height="12px"
          style={{
            cursor: "pointer"
          }}
          data-testid="closeIcon"
        ></img>
      </Box>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          data-testid="transferInfoTitle"
          sx={{
            textAlign: "center",
            fontFamily: "BukraMedium",
            letterSpacing: "0px",
            color: "#525868",
            fontSize: "13px",
            pl: 1.5
          }}
        >
          {TRANSFER_MODEL_INFO_TITLE}
        </Typography>
        {getTransferToName()}
        <Stack spacing={2} sx={{ pt: 6, pb: 3 }}>
          <Button
            data-testid="proceedButton"
            variant="outlined"
            sx={{
              borderRadius: "20px",
              fontSize: "15px",
              textTransform: "none",
              fontFamily: "BukraRegular",
              letterSpacing: 0,
              background:
                "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
              color: "#ffffff",
              minWidth: "250px"
            }}
            onClick={handleMultipleProceed}
          >
            {PROCEED}
          </Button>
          <Button
            data-testid="cancelButton"
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderRadius: "20px",
              borderColor: "#4079d5",
              fontSize: "15px",
              textTransform: "none",
              fontFamily: "BukraRegular",
              letterSpacing: 0,
              color: "#4079d5",
              minWidth: "250px"
            }}
          >
            {CANCEL}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TransferInfoModal;
