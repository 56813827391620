import React, { Fragment, memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import RevenueCount from "./../../assets/images/revenue_count.svg";
import { getChildChannelPartnerRevenue } from "../../services/user.services";
import { useSelector } from "react-redux";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import Tooltip from "@mui/material/Tooltip";
import { getUserRoleType } from "../../stateManagement/reducers/LoginSlice";
import {
  MD_USER_ROLE_TYPE,
  TOOLTIP_RT_COUNT,
  TOOLTIP_RT_REVENUE,
  TOOLTIP_SD_COUNT,
  TOOLTIP_SD_REVENUE
} from "../../constant/Constants";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import { ThemeProvider } from "@mui/material";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * Renders the UI for the Revenue with the Revenue count info.
 * @param {object} props - Props passed to Revenue count card to display the data.
 * @returns {component} Card with revenue count info.
 */
const RevenueCountCard = (props) => {
  const { displayFilterTime } = props;
  const [revenueCountDetails, setRevenueCountDetails] = useState("");
  const getdate = useSelector((state) => getFilteredDateFromStore(state));
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));
  const { startDate, endDate } = getdate;
  const config = useSelector((state) => getConfigDetails(state));

  useEffect(() => {
    if (startDate && endDate && config.server) {
      getChildChannelPartnerRevenue(config.server, startDate, endDate).then(
        (res) => {
          if (res && res.status === 200) {
            setRevenueCountDetails(res.data);
          } else {
            setRevenueCountDetails("");
          }
        }
      );
    }
  }, [startDate, endDate, config.server]);

  const revenueBy =
    fetchUserRoleType === MD_USER_ROLE_TYPE
      ? TOOLTIP_SD_REVENUE
      : TOOLTIP_RT_REVENUE;
  const totalChildCount =
    fetchUserRoleType === MD_USER_ROLE_TYPE
      ? TOOLTIP_SD_COUNT
      : TOOLTIP_RT_COUNT;
  return (
    <Fragment>
      {revenueCountDetails && (
        <Box
          data-testid="revenueCountCard"
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #7070704D",
            borderRadius: "8px",
            height: { sm: "80px", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: { xs: "100%", md: "200px" }
          }}
        >
          {displayFilterTime && (
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Box
                data-testid="filteredTime"
                component={"span"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: " #EDEEFE 0% 0% no-repeat padding-box",
                  borderBottomLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  fontFamily: "BukraRegular",
                  color: "#181D25",
                  flexGrow: 0.5,
                  fontSize: "12px",
                  p: "2px"
                }}
                className="filterName"
              >
                {displayFilterTime}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              p: 1,
              pl: 1.5,
              pb: 1.2
            }}
          >
            <ThemeProvider theme={ToolTipThemeWithBG}>
              <Box
                sx={{
                  display: "flex"
                }}
              >
                <img src={RevenueCount} alt="sub" height={"45px"}></img>
                <Tooltip title={revenueBy} placement="top" arrow>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      ml: 1
                    }}
                  >
                    <Box
                      data-testid="subDistributorRevenueValue"
                      component="div"
                      sx={{
                        pt: 0.5,
                        color: "#202020",
                        fontFamily: "BukraMedium",
                        fontSize: { lg: "13px", xs: "12px" },
                        letterSpacing: "0px"
                      }}
                    >
                      {revenueCountDetails.totalRevenue}
                    </Box>

                    <Box
                      data-testid="subDistributorRevenueHeading"
                      component="div"
                      sx={{
                        pt: "5px",
                        color: "#282828",
                        fontFamily: "BukraRegular",
                        fontSize: { lg: "12px", xs: "11px" },
                        letterSpacing: "0px"
                      }}
                    >
                      {revenueCountDetails.totalRevenueLabel}
                    </Box>
                  </Box>
                </Tooltip>
              </Box>

              {revenueCountDetails.childCountLabel && (
                <Box
                  sx={{
                    display: "flex",
                    pl: 2
                  }}
                >
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title={totalChildCount} placement="top" arrow>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: 2
                      }}
                    >
                      <Box
                        data-testid="subDistributorCountValue"
                        component="div"
                        sx={{
                          pt: 0.5,
                          fontFamily: "BukraMedium",
                          fontSize: { lg: "13px", xs: "12px" },
                          letterSpacing: "0px"
                        }}
                      >
                        {revenueCountDetails.childCount}
                      </Box>

                      <Box
                        data-testid="subDistributorCountHeading"
                        component="div"
                        sx={{
                          pt: "5px",
                          color: "#282828",
                          fontFamily: "BukraRegular",
                          fontSize: { lg: "12px", xs: "11px" },
                          letterSpacing: "0px"
                        }}
                      >
                        {revenueCountDetails.childCountLabel}
                      </Box>
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </ThemeProvider>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

export default memo(RevenueCountCard);
