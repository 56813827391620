import {
  Box,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import search from "./../../assets/images/search.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HardwareReplacement from "../hardwareReplacement/HardwareReplacement";
import HardwareAllocation from "../hardwareAllocation/HardwareAllocation";
import TabPanel from "../tabPanel/TabPanel";
import SideLoader from "./../loaders/sideLoader/SideLoader";
import {
  BLANK,
  CUSTOMER_ID,
  EMAIL,
  ENTER,
  MOBILE_NUMBER,
  NO_CONTENT,
  TOAST_ERROR,
  HASH,
  SOMETHING_WENT_WRONG,
  HARDWARE_ALLOCATION_AND_REPLACEMENT
} from "../../constant/Constants";
import { getCustomerInfo } from "../../services/user.services";
import { useDispatch, useSelector } from "react-redux";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { getCountryDetailsFromStore } from "../../stateManagement/reducers/CountryDetailsSlice";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import {
  getCustomerSearchErrorLabel,
  onlyNumber,
  updatePageVisit
} from "../../constant/Utils";
import { getChannelPartnerCode } from "../../stateManagement/reducers/LoginSlice";
import { useLocation } from "react-router-dom";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 * @constant {array<object>} stbSelectOption - The Select options Object for customer search in Hardware Allocation and replacement.
 */
const stbSelectOption = [
  {
    value: "mobileNumber",
    label: "Mobile Number"
  },
  {
    value: "id",
    label: "Customer ID"
  },
  {
    value: "serialNo",
    label: "Serial Number"
  }
];

const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "30px",
          marginLeft: "1px",
          paddingLeft: "30px",
          paddingRight: "30px",
          fontSize: "14px",
          textTransform: "capitalize",
          color: "#5c6473",
          width: "134px",
          display: "flex",
          alignItems: "center",
          fontFamily: "BukraMedium"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#282828",
          fontSize: "13px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#282828",
          fontFamily: "BukraRegular",
          "& .MuiSelect-select": {
            fontSize: "13px",
            ":focus": {
              backgroundColor: "#e7eaed"
            }
          },
          "& .MuiInputBase-root": {
            borderRadius: "5px"
          }
        }
      }
    }
  }
});

/**
 * UI Component for Hardware Allocation and replacement.
 * @returns The UI Component for Hardware Allocation and Replacement.
 */
const HardwareAllocationandReplacement = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sortingCredits, setSortingCredits] = useState("mobileNumber");
  const [param, setParam] = useState("");
  const [value, setValue] = useState(0);
  const [stbInfo, setStbInfo] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [loader, setLoader] = useState(false);
  const country = useSelector((state) => getCountryDetailsFromStore(state));
  const channelPartnerCode = useSelector((state) =>
    getChannelPartnerCode(state)
  );
  const config = useSelector((state) => getConfigDetails(state));
  useEffect(() => {
    if (location.pathname && channelPartnerCode) {
      updatePageVisit(channelPartnerCode, location.pathname);
    }
  }, [location.pathname, channelPartnerCode]);

  /**
   * Handles the Customer search category change
   * @function handleSelectChange - Performs the action of changing the select option values.
   * @param {object} event - Customer search select option pointer event
   */
  const handleSelectChange = (event) => {
    setSortingCredits(event.target.value);
    setParam('')
  };

  /**
   * Handles the Customer Search Input value change.
   * @function handleInputChange - Performs action on customer search input value change.
   * @param {string} inputValue - Customer search input string.
   */
  const handleInputChange = (inputValue) => {
    const searchedVal = inputValue.trim();
    if (sortingCredits !== "serialNo") {
      if (onlyNumber(searchedVal)) {
        setParam(searchedVal);
      }
    } else {
      setParam(searchedVal);
    }
   
  };

  /**
   * Handles the Tab change operation.
   * @function handleTabChange - Performs action on Tab change.
   * @param {object} event - Pointer event.
   * @param {number} newValue - id of the tab.
   */
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Handles the Customer Search Operation on Enter Click.
   * @function handleEnterDetails - Performs Customer Search Operation on Enter Click.
   * @param {string} category - Customer search category value eg. CustomerID, mobile No.
   * @param {string} input - Customer search input value.
   * @param {object} event - Pointer event.
   */
  const handleEnterDetails = (category, input, event) => {
    if (event.key === ENTER) {
      getCustomerDetails(category, input);
    }
  };

  /**
   * Handles the Search for Customer Details
   * @function getCustomerDetails - Performs action to fetch the customer details.
   * @param {string} category - Category based on which the Search will happen .
   * @param {string} input  - Search Input text.
   */
  const getCustomerDetails = (category, input) => {
    if (!input) {
      let toastDetails = {
        toastTitle: `${getCustomerSearchErrorLabel(
          category
        )} must not be blank`,
        isToastOpen: true,
        toastStatus: TOAST_ERROR,
        toastTextclr: "#FF2A2A",
        toastBgclr: "#FDE2E2 ",
        toastBorderclr: "#FF2A2A"
      };
      dispatch(updateToastMessage(toastDetails));
    }
    if (input) {
      setLoader(true);
      if (category === "mobileNumber") {
        input = `+${country.countryPhoneCode}${input}`;
      }
      getCustomerInfo(config.server, category, input.trim())
        .then((res) => {
          if (res && res.status === 200 && res.data.status !== NO_CONTENT) {
            setCustomerInfo(res.data.customerInfo);
            setStbInfo(res.data.stbInfo);
          } else {
            setCustomerInfo({});
            setStbInfo([]);
            const message =
              res && res.data &&res.data.message? res.data.message : SOMETHING_WENT_WRONG;
            let toastDetails = {
              toastTitle: message,
              isToastOpen: true,
              toastDescription: "",
              toastStatus: TOAST_ERROR,
              toastTextclr: "#FF2A2A",
              toastBgclr: "#FDE2E2 ",
              toastBorderclr: "#FF2A2A"
            };
            dispatch(updateToastMessage(toastDetails));
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoader(false);
        });
    }
  };

  /**
   * Reset the Customer Details
   * @function resetCustomer - performs action to reset the customer details.
   */
  const resetCustomer = () => {
    setCustomerInfo({});
    setStbInfo([]);
  };

  /**
   * Renders the customer details.
   * @returns {component} the Customer Details UI.
   */
  const renderCustomerDetails = () => {
    return (
      <Box
        data-testid="hardwareAllocationandReplacement"
        sx={{
          mx: { lg: 7.5, xs: 3 },
          pt: 2,
          pb: 3
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#465964",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                mr: "66px"
              }}
            >
              {" "}
              {CUSTOMER_ID}
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#0F0F10",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                lineBreak: "anywhere"
              }}
            >
              {customerInfo.customerId ? customerInfo.customerId : BLANK}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: { lg: "400px" },
              mt: { xs: 2, lg: 0 }
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                color: "#465964",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                mr: { xs: 3, lg: 5 }
              }}
            >
              {MOBILE_NUMBER} {HASH}
            </Typography>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#0F0F10",
                letterSpacing: 0,
                fontFamily: "BukraRegular",
                lineBreak: "anywhere"
              }}
            >
              {customerInfo.mobileNumber ? customerInfo.mobileNumber : BLANK}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", pt: 2 }}>
          <Typography
            sx={{
              fontSize: "15px",
              color: "#465964",
              letterSpacing: 0,
              fontFamily: "BukraRegular",
              mr: "119px"
            }}
          >
            {EMAIL}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              color: "#0F0F10",
              letterSpacing: 0,
              fontFamily: "BukraRegular",
              lineBreak: "anywhere"
            }}
          >
            {customerInfo.email ? customerInfo.email : BLANK}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Paper elevation={2}>
      <ThemeProvider theme={theme}>
        <Toolbar
          sx={{
            pt: { xs: 2, md: 0 },
            pr: { xs: 0.5, sm: 1 },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: { sm: "84px" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: { md: "center", xs: "flex-start" },
              flexDirection: { md: "row", xs: "column" },
              width: { xs: "100%", md: "auto" }
            }}
          >
            <Typography
              variant="h6"
              id="tableTitle"
              sx={{
                fontFamily: "BukraMedium",
                fontSize: "16px",
                letterSpacing: "0px",
                color: "#464E5F"
              }}
            >
              {HARDWARE_ALLOCATION_AND_REPLACEMENT}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "center" },
                flexDirection: { sm: "row", xs: "row" },
                width: { xs: "100%", md: "auto" }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "12px",
                  backgroundColor: "#f3f6f9",
                  height: "40px",
                  ml: { md: 2 },
                  width: { xs: "100%", md: "350px" },
                  mt: { xs: 1, md: 0 }
                }}
              >
                <img
                  src={search}
                  alt="search"
                  style={{
                    height: "11px",
                    paddingLeft: "20px",
                    cursor: "pointer"
                  }}
                  data-testid="searchIcon"
                  onClick={() => getCustomerDetails(sortingCredits, param)}
                ></img>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { xs: "100%", lg: "200px" }
                  }}
                >
                  {sortingCredits === "mobileNumber" &&
                    country &&
                    country.countryPhoneCode && (
                      <Typography
                        sx={{
                          pl: 1,
                          fontFamily: "BukraRegular",
                          color: "#464E5F",
                          fontSize: "15px"
                        }}
                      >
                        +{country.countryPhoneCode}
                      </Typography>
                    )}
                  <ThemeProvider theme={inputTheme}>
                    <InputBase
                      autoComplete="off"
                      sx={{
                        ml: 1,
                        flex: 1
                      }}
                      placeholder="Search"
                      value={param}
                      onChange={(e) => handleInputChange(e.target.value)}
                      onKeyDown={(e) =>
                        handleEnterDetails(sortingCredits, param, e)
                      }
                      inputProps={{ "data-testid": "searchInput" }}
                    />
                  </ThemeProvider>
                </Box>
                <Select
                  id="filledoption"
                  sx={{
                    backgroundColor: "#7B7D801A",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                    height: "40px",
                    pl: 2,
                    fontSize: "13px",
                    color: "#282828",
                    fontFamily: "BukraRegular"
                  }}
                  value={sortingCredits}
                  onChange={(e) => handleSelectChange(e)}
                  variant="standard"
                  disableUnderline={true}
                  inputProps={{ "data-testid": "selectCustomer" }}
                >
                  {stbSelectOption.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      name={option.value}
                      sx={{
                        ...muiMenuItemStyles
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {loader && <SideLoader size={20}></SideLoader>}
            </Box>
          </Box>
        </Toolbar>
        {renderCustomerDetails()}
        <Box
          sx={{
            borderBottom: 0,
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: { xs: 0, md: 2, lg: 4 },
            height: "45px",
            backgroundColor: "#EDF4F6"
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{
              display: "flex",
              alignItems: "center",
              minHeight: "45px"
            }}
            data-testid="stbTabs"
          >
            <Tab
              label="Allocation"
              data-testid="allocateSTB"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#398BE733",
                  borderRadius: "17px",
                  color: "#246bd3",
                  height: "30px",
                  border: "none"
                }
              }}
            />
            <Tab
              label="Replacement"
              data-testid="replaceSTB"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#398BE733",
                  borderRadius: "17px",
                  color: "#246bd3",
                  height: "30px",
                  border: "none"
                }
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <HardwareAllocation
            customerInfo={customerInfo}
            resetCustomer={resetCustomer}
          ></HardwareAllocation>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HardwareReplacement
            customerInfo={customerInfo}
            stbInfo={stbInfo}
            resetCustomer={resetCustomer}
          ></HardwareReplacement>
        </TabPanel>
      </ThemeProvider>
    </Paper>
  );
};

export default HardwareAllocationandReplacement;
