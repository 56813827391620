import React, { useState, memo } from "react";
import {
  MenuItem,
  Box,
  Tooltip,
  Menu,
  Typography,
  Divider,
  ThemeProvider,
  CircularProgress
} from "@mui/material";

import downloadIcon from "./../../assets/images/download_icon.svg";
import { downloadSummaryReport } from "../../services/user.services";
import { ToolTipThemeWithBG } from "../../constant/themes/ToolTipTheme";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  CHOOSE_THE_YEAR,
  SOMETHING_WENT_WRONG,
  SUMMARY,
  SUMMARY_REPORT,
  TOAST_ERROR
} from "../../constant/Constants";
import { getToday } from "../../constant/Utils";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

const currentYear = new Date().getFullYear();

const SummaryReport = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(false);
  const open = Boolean(anchorEl);
  const config = useSelector((state) => getConfigDetails(state));

  const handleYearChange = (event) => {
    setLoader(true);
    const year = event;
    let seletedStartDate = `01-01-${year}`;
    let seletedEndDate = `31-12-${year}`;
    if (year.toString() === currentYear.toString()) {
      seletedEndDate = getToday();
    }
    let toastDetails = {
      toastTitle: SOMETHING_WENT_WRONG,
      isToastOpen: true,
      toastDescription: "",
      toastStatus: TOAST_ERROR,
      toastTextclr: "#FF2A2A",
      toastBgclr: "#FDE2E2 ",
      toastBorderclr: "#FF2A2A"
    };
    downloadSummaryReport(config.server, seletedStartDate, seletedEndDate)
      .then((res) => {
        if (res && res.status !== 200) {
          dispatch(updateToastMessage(toastDetails));
        }
      })
      .catch(() => {
        dispatch(updateToastMessage(toastDetails));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // Generate an array of years from the current year to the last two years
  const years = Array.from({ length: 3 }, (_, index) => currentYear - index);

  /**
   * Handles the close opeation on Profile menu click.
   * @function handleClose - Peforms action to close the Profile menu items.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSummaryReportDownload = (e) => {
    setAnchorEl(e.currentTarget);
  };
  return (
    <Box
      data-testid="summaryReport"
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          {loader ? (
            <Box
              sx={{
                height: "30px",
                width: "30px",
                background: "#467ae7",
                borderRadius: "8px",
                cursor: "default"
              }}
            >
              <CircularProgress size={20} sx={{ color: "#ffffff", p: "5px" }} />
            </Box>
          ) : (
            <ThemeProvider theme={ToolTipThemeWithBG}>
              <Box>
                <Tooltip title={SUMMARY_REPORT} arrow>
                  <img
                    className="imgHover"
                    src={downloadIcon}
                    alt="report"
                    data-testid="summaryReportImg"
                    onClick={(e) => handleSummaryReportDownload(e)}
                    style={{ width: `30px`, height: `30px`, cursor: "pointer" }}
                  ></img>
                </Tooltip>
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      lg: "none",
                      fontSize: "11px",
                      paddingTop: "5px"
                    }
                  }}
                >
                  {SUMMARY}
                </Box>
              </Box>
            </ThemeProvider>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              minWidth: "150px",
              overflow: "visible",
              backgroundColor: "#f6f6f6",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: { md: 10, xs: "unset" },
                left: { md: "unset", xs: 25 },
                width: 10,
                height: 10,
                bgcolor: "#f6f6f6",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0
              }
            }
          }}
          sx={{
            ".MuiList-root": {
              p: 0
            }
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Typography
            sx={{
              py: 1,
              px: 0.5,
              textAlign: "center",
              fontSize: "14px",
              fontFamily: "BukraRegular"
            }}
          >
            {CHOOSE_THE_YEAR}
          </Typography>
          <Divider></Divider>
          {years &&
            years.map((year, index) => {
              return (
                <MenuItem
                  key={`${year}`}
                  data-testid="allMenus"
                  divider={index === years.length - 1 ? false : true}
                  sx={{
                    py: 0.5
                  }}
                  onClick={() => handleYearChange(year)}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "BukraRegular",
                      "&:hover": {
                        transition: "0.15s ease-in-out",
                        transform: "scale(1.05 ,1.05)"
                      }
                    }}
                  >
                    {year}
                  </Typography>
                </MenuItem>
              );
            })}
        </Menu>
      </React.Fragment>
    </Box>
  );
};

export default memo(SummaryReport);
