import {
  MD_USER_ROLE_TYPE,
  SCOPES,
  SD_USER_ROLE_TYPE,
  STOCKBALANCE,
  TRACKSTB,
  TRANSFERCREDITS
} from "./Constants";
import transferQuick from "./../assets/images/transfer_quick.svg";
import nounSetTopBoxfrom from "./../assets/images/noun_set_top_box.svg";
import nounstock from "./../assets/images/noun_stock.svg";

import customerRegistration from "./../assets/images/customer_registration_quick.svg";
import hardwareAllocation from "./../assets/images/hardware_allocation_quick.svg";
import subscribeNow from "./../assets/images/subscribe_now_quick.svg";
/**
 * @constant {array<object>} profileMenuList - List of Profile menu items.
 */
export const profileMenuList = [
  { title: "My Profile", id: "profile", scope: SCOPES.MENU_MYPROFILE },
  {
    title: "Master Distributor Profile",
    id: "masterDistributorProfile",
    scope: SCOPES.MENU_MASTER_DISTRIBUTE_PROFILE
  },
  {
    title: "Sub Distributor Profile",
    id: "subDistributorProfile",
    scope: SCOPES.MENU_SUB_DISTRIBUTE_PROFILE
  },

  // {
  //   title: "Company Profile",
  //   id: "companyProfile",
  //   scope: SCOPES.MENU_COMPANY_PROFILE,
  // },

  {
    title: "Change Password",
    id: "changePassword",
    scope: SCOPES.MENU_CHANGE_PASSWORD
  },
  { title: "Sign Out", id: "logout", scope: SCOPES.MENU_SIGN_OUT },
  { title: "Default", id: "", scope: "DEFAULT" }
];

/**
 * @constant {array<object>} sideNavBarDetails - List of Side Navbar menu items.
 */
export const sideNavBarDetails = [
  {
    label: "Dashboard",
    unique_id: "dashboard_1",
    scope: SCOPES.LEFTMENU_DASHBOARD,
    role: 1,
    id: 1
  },
  {
    label: "Credits",
    unique_id: "credits_1",
    scope: SCOPES.LEFTMENU_CREDITS,
    role: 1,
    id: 2
  },
  {
    label: "STB",
    unique_id: "stb",
    scope: SCOPES.LEFTMENU_STB,
    role: 1,
    id: 3
  },
  {
    label: "Sub Distributor",
    unique_id: "sub_distributor_1",
    role: 1,
    scope: SCOPES.LEFTMENU_SUB_DISTRIBUTOR,
    id: 4
  },
  {
    label: "Track STB",
    unique_id: "trackstb",
    scope: SCOPES.LEFTMENU_TRACK_STB,
    role: 2,
    id: 32
  },
  {
    label: "Retailers",
    unique_id: "retailers_1",
    scope: SCOPES.LEFTMENU_RETAILER,
    role: 2,
    id: 6
  },
  {
    label: "Subscription Sales",
    unique_id: "subscription_sales_1",
    scope: SCOPES.LEFTMENU_SUBSCRIPTION_SALES,
    role: 1,
    id: 5
  },

  {
    label: "Hardware",
    unique_id: "hardware_1",
    scope: SCOPES.LEFTMENU_HARDWARE,
    role: 3,
    id: 7
  },
  {
    label: "Customer",
    unique_id: "customer_1",
    scope: SCOPES.LEFTMENU_CUSTOMER,
    role: 3,
    id: 8
  }
];

/**
 * @constant {array<object>} quickLinksTab - List of Quick links card items.
 */
export const quickLinksTab = [
  {
    label: TRANSFERCREDITS,
    id: "QUICKLINKS_TRANSFER_CREDITS",
    scope: SCOPES.QUICKLINKS_TRANSFER_CREDITS,
    src: transferQuick,
    url: "/credits/transfercredits",
    subRoute: "credits"
  },
  {
    label: TRACKSTB,
    id: "QUICKLINKS_TRACK_STB",
    scope: SCOPES.QUICKLINKS_TRACK_STB,
    src: nounSetTopBoxfrom,
    url: "/stb/trackstb",
    subRoute: "stb"
  },
  {
    label: STOCKBALANCE,
    id: "QUICKLINKS_STOCK_BALANCE",
    scope: SCOPES.QUICKLINKS_STOCK_BALANCE,
    src: nounstock,
    url: "/stb",
    subRoute: "stb"
  },
  {
    label: "Add Retailers",
    id: "QUICKLINKS_ADD_RETAILERS",
    scope: SCOPES.QUICKLINKS_ADD_RETAILERS,
    src: customerRegistration,
    url: "/retailers/retaileronboarding",
    subRoute: "retailers"
  },
  {
    label: "Customer Registration",
    id: "QUICKLINKS_CUSTOMER_REGISTRATION",
    scope: SCOPES.QUICKLINKS_CUSTOMER_REGISTRATION,
    src: customerRegistration,
    url: "/customer/customerregistration",
    subRoute: "customer"
  },
  {
    label: "Subscribe now",
    id: "QUICKLINKS_SUBSCRIBE_NOW",
    scope: SCOPES.QUICKLINKS_SUBSCRIBE_NOW,
    src: subscribeNow,
    url: "/customer/subscribenow",
    subRoute: "customer"
  },
  {
    label: "Hardware Allocation",
    id: "QUICKLINKS_HARDWAR_ALLOCATION",
    scope: SCOPES.QUICKLINKS_HARDWAR_ALLOCATION,
    src: hardwareAllocation,
    url: "/hardware/hardwareallocationandreplacement",
    subRoute: "hardware"
  }
];

/**
 * @constant {array<object>} quickLinkCardWidgets - Quick links cards visibility status info based on type of user.
 */
export const quickLinkCardWidgets = [
  {
    roleType: 2,
    quickLinkCard: {
      showCreditCard: true,
      showRevenueCard: true,
      showInventoryCard: true
    }
  },
  {
    roleType: 3,
    quickLinkCard: {
      showCreditCard: true,
      showRevenueCard: true,
      showInventoryCard: false
    }
  },
  {
    roleType: 4,
    quickLinkCard: {
      showCreditCard: true,
      showRevenueCard: true,
      showInventoryCard: false
    }
  }
];

/**
 * @constant {object} urlPath - URL paths.
 */
export const urlPath = {
  credits: "/credits",
  retailer: "/retailers",
  transferCredits: "/credits/transfercredits"
};

/**
 * @constant {array<object>} intialMDLogHistory- Initial Page visits count for MD user.
 */
export const intialMDLogHistory = [
  {
    path: "/credits",
    count: 1,
    label: "Credits"
  },
  {
    path: "/credits/transfercredits",
    count: 0,
    label: "TransferCredits"
  },
  {
    path: "/credits/reversecredits",
    count: 0
  },
  {
    path: "/stb",
    count: 0
  },
  {
    path: "/stb/trackstb",
    count: 0
  },
  {
    path: "/subdistributor",
    count: 1
  },
  {
    path: "/subscription",
    count: 0
  }
];

/**
 * @constant {array<object>} intialSDLogHistory- Initial Page visits count for SD user.
 */
export const intialSDLogHistory = [
  {
    path: "/credits",
    count: 1
  },
  {
    path: "/credits/transfercredits",
    count: 0
  },
  {
    path: "/credits/reversecredits",
    count: 0
  },
  {
    path: "/trackstb",
    count: 0
  },
  {
    path: "/retailers",
    count: 1
  },
  {
    path: "/retailers/retaileronboarding",
    count: 0
  },
  {
    path: "/subscription",
    count: 0
  }
];

/**
 * @constant {array<object>} intialRTLogHistory- Initial Page visits count for Retailer.
 */
export const intialRTLogHistory = [
  {
    path: "/credits",
    count: 0
  },
  {
    path: "/hardware",
    count: 1
  },
  {
    path: "/hardware/trackstb",
    count: 0
  },
  {
    path: "/hardware/hardwareallocationandreplacement",
    count: 0
  },
  {
    path: "/customer",
    count: 0
  },
  {
    path: "/customer/subscribenow",
    count: 1
  },
  {
    path: "/customer/customerregistration",
    count: 1
  }
];

/**
 * Handles the display of Page visits data based on user type.
 * @param {number} roleType - Logged in user role type(MD,SD,Retailer)
 * @returns {array<object>} - Page visits info based on user type
 */
export const getIntialLogHistory = (roleType) => {
  if (roleType === MD_USER_ROLE_TYPE) {
    return intialMDLogHistory;
  } else if (roleType === SD_USER_ROLE_TYPE) {
    return intialSDLogHistory;
  } else {
    return intialRTLogHistory;
  }
};

/**
 * Handles the display of Quick links images.
 * @param {string} url - URL path.
 * @returns {object} image path and info.
 */
export const getQuickImages = (url) => {
  const images = [
    { url: "/credits", src: "", label: "Credits" },
    {
      url: "/credits/transfercredits",
      label: "Transfer Credits",
      src: transferQuick
    },
    {
      url: "/credits/reversecredits",
      label: "Reverse Credits",
      src: transferQuick
    },
    { url: "/stb", src: nounstock, label: "Stock Balance" },
    { url: "/stb/trackstb", src: nounSetTopBoxfrom, label: "Track STB" },

    {
      url: "/subdistributor",
      src: "",
      label: "Sub Distributor"
    },

    {
      url: "/subscription",
      src: "",
      label: "Subscription Sales"
    },

    { url: "/trackstb", src: nounSetTopBoxfrom, label: "Track STB" },
    { url: "/retailers", src: "", label: "Retailers" },
    {
      url: "/retailers/retaileronboarding",
      src: customerRegistration,
      label: "Add Retailer"
    },

    { url: "/hardware", src: hardwareAllocation, label: "Hardware" },
    {
      url: "/hardware/trackstb",
      src: nounSetTopBoxfrom,
      label: "Track STB"
    },
    {
      url: "/hardware/hardwareallocationandreplacement",
      src: hardwareAllocation,
      label: "Hardware Allocation"
    },
    { url: "/customer", src: "", label: "Customer" },
    {
      url: "/customer/subscribenow",
      src: subscribeNow,
      label: "Subscribe Now"
    },
    {
      url: "/customer/customerregistration",
      src: customerRegistration,
      label: "Add Customer"
    }
  ];
  const urlLabelObj = images.find((item) => item.url === url);
  return urlLabelObj ? urlLabelObj : "";
};
