import { Box, Button, Input, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { convertMStoS, onlyNumber, Timer } from "../../constant/Utils";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";
import refreshbutton from "../../assets/images/refresh_button.png";
import setting from "../../assets/images/setting.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import closeicon from "./../../assets/images/close.png";
import CustomSnackbar from "../customComponents/customSnackbar/CustomSnackbar";
import {
  CODE_EXPIRED,
  CODE_EXPIRE_TEXT,
  CONFIRM_BUTTON,
  GENERATE_NEW_CODE_TEXT,
  MAX_OTP_LENGTH,
  SOMETHING_WENT_WRONG
} from "../../constant/Constants";
import { generateUserOtpDetails } from "../../services/user.services";
import { useTimer } from "react-timer-hook";

const RetailerOtpVerificationPage = ({
  payload,
  handleConfirmVerification,
  errorInfo,
  showErrorPop,
  hidePopup,
  showPopup,
  isPopup,
  closeDialog,
  codeExpireTime,
  resendCodeTime,
  headerLabel
}) => {
  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDF4F6",
            color: "#0F0F10",
            border: "1px solid #E8E8E8",
            borderRadius: "3px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "13px",
            fontFamily: "BukraRegular",
            "&.Mui-focused": {
              border: "1px solid #0F57CB"
            },
            "& ::placeholder": {
              opacity: 0.8,
              color: "#5B636F"
            }
          }
        }
      }
    }
  });

  const [verificationCode, setVerificationCode] = useState("");
  const config = useSelector((state) => getConfigDetails(state));

  const finalcodeExpireTime = new Date();
  finalcodeExpireTime.setSeconds(
    finalcodeExpireTime.getSeconds() + codeExpireTime
  ); // Convert milliseconds to seconds

  const { totalSeconds: codeExpireTimer, restart: restartCodeExpire } =
    useTimer({ expiryTimestamp: finalcodeExpireTime, autoStart: true });

  const { totalSeconds: resendCodeTimer, restart: restartNewCodeExpire } =
    useTimer({
      expiryTimestamp: Date.now() + resendCodeTime * 1000,
      autoStart: true
    });

  const handleCodeExpireTime = (expireTime) => {
    const resetcodeExpireTime = new Date();
    resetcodeExpireTime.setSeconds(
      resetcodeExpireTime.getSeconds() + expireTime
    );
    restartCodeExpire(resetcodeExpireTime, true);
  };

  const handleGenerateNewCodeExpireTime = () => {
    const resetcodeExpireTime = Date.now() + resendCodeTime * 1000;
    restartNewCodeExpire(resetcodeExpireTime, true);
  };
  /**
   * Handles the Close operation on OTP verification box.
   * @function handleClose - Closes the OTP Verification Popup.
   */
  const handleClose = () => {
    closeDialog();
  };

  /**
   * Reset the Code Expiry and Generate new OTP timer.
   */

  /**
   * Handles the OTP code Operation.
   * @function handleVerificationCode - Checks and set the OTP input field value.
   * @param {object} e - OTP code input event object
   */
  const handleVerificationCode = (e) => {
    const { value } = e.target;
    if (onlyNumber(value)) {
      setVerificationCode(value);
    }
  };

  /**
   * Handles the Snackbar message
   * @function showToastCallback - renders the snackbar message on error input of OTP.
   */
  const showToastCallback = () => {
    hidePopup();
  };

  /**
   * Handles the Operation to send the Verification code.
   * @function sendVerificationCode - Performs action to send OTP code to mobile no.
   */
  const sendVerificationCode = () => {
    handleConfirmVerification(verificationCode);
    setVerificationCode("");
  };

  /**
   * Handles the Operation to resend the Verification code.
   * @function handleResendCode - Performs action to resend OTP code to mobile no.
   */
  const handleResendCode = (e) => {
    e.preventDefault();
    setVerificationCode("");
    generateUserOtpDetails(config.server, payload).then((response) => {
      if (response && response.status === 200) {
        handleGenerateNewCodeExpireTime();
        handleCodeExpireTime(convertMStoS(response.data?.validFor));
      } else {
        showPopup(response.data.message);
      }
    });
  };
  const isDisabled = resendCodeTimer !== 0;

  return (
    <ThemeProvider theme={theme}>
      <Paper
        data-testid="RetailerOtpVerificationPage"
        elevation={2}
        sx={{
          p: 2.1,
          pb: 0,
          maxWidth: { xs: "100%", sm: "370px" }
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: `${isPopup ? "row-reverse" : "row"}`,
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <img
              src={closeicon}
              alt="close icon"
              style={{
                height: "16px",
                width: "16px",
                cursor: "pointer",
                float: "right"
              }}
              onClick={handleClose}
              data-testid="closeIcon"
            ></img>

            <Box sx={{ margin: "0 auto" }}>
              <CustomSnackbar
                message={
                  errorInfo
                    ? errorInfo.toString()
                    : SOMETHING_WENT_WRONG.toString()
                }
                borderClr="#FF2A2A"
                bgClr="#FDE2E2 "
                toastTextclr="#FF2A2A"
                showToast={showErrorPop}
                showToastCallback={showToastCallback}
              ></CustomSnackbar>
            </Box>
          </Box>

          <Box
            sx={{
              pt: 3,
              px: "25px",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              flex: 1
            }}
          >
            <Typography
              sx={{
                pt: 3,
                fontFamily: "BukraMedium",
                textAlign: "center",
                fontSize: "14px",
                color: "#464e5f"
              }}
            >
              {headerLabel}
            </Typography>

            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                pt: 2,
                pl: { sm: 3 },
                pr: { sm: 3 },
                width: { xs: "100%", sm: "auto" }
              }}
            >
              <Input
                autoComplete="off"
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onChange={(e) => handleVerificationCode(e)}
                id="verificationCode"
                fullWidth
                required
                name="verificationCode"
                pattern="^-?[0-9]\d*\.?\d*$"
                placeholder="Enter Verification Code"
                disableUnderline={true}
                value={verificationCode}
                sx={{
                  mt: 3,
                  width: { xs: "100%", sm: "284px" }
                }}
                inputProps={{
                  "data-testid": "codeInput",
                  maxLength: MAX_OTP_LENGTH
                }}
                startAdornment={
                  <InputAdornment position="start" sx={{ p: 1 }}>
                    <img src={setting} alt="s"></img>
                  </InputAdornment>
                }
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                  mt: 3,
                  mb: 1
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                    fontFamily: "BukraBold",
                    letterSpacing: 0
                  }}
                >
                  {codeExpireTimer === 0 ? "" : CODE_EXPIRE_TEXT}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                    color: "#DE0909",
                    fontFamily: "BukraBold",
                    letterSpacing: 0,
                    ml: 0.2
                  }}
                >
                  {codeExpireTimer === 0
                    ? CODE_EXPIRED
                    : Timer(codeExpireTimer)}
                </Typography>
              </Box>

              <Button
                variant="outlined"
                sx={{
                  background:
                    "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                  color: "#FFFFFF",
                  borderRadius: "20px",
                  letterSpacing: "0px",
                  fontFamily: "BukraMedium",
                  fontSize: "15px",
                  width: "200px",
                  textTransform: "none",
                  height: "40px",
                  "&.Mui-disabled": {
                    background:
                      "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                    color: "#FFFFFF",
                    opacity: 0.5
                  }
                }}
                disabled={!verificationCode}
                onClick={() => sendVerificationCode()}
                data-testid="confirmBtn"
              >
                {CONFIRM_BUTTON}
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                  mb: 3
                }}
              >
                <img
                  src={refreshbutton}
                  alt="refreshbutton"
                  style={{
                    height: "11px",
                    width: "11px",
                    marginTop: "6px",
                    marginRight: "3px"
                  }}
                />

                <Typography
                  component="button"
                  sx={{
                    fontSize: "11px",
                    background: "none",
                    border: "none",
                    textAlign: "center",
                    letterSpacing: 0,
                    mt: 1,
                    color: `${isDisabled ? "#888888" : "#0f57cb"}`,
                    fontFamily: "BukraBold",
                    cursor: `${isDisabled ? "not-allowed" : "pointer"}`
                  }}
                  onClick={(e) => handleResendCode(e)}
                  disabled={isDisabled}
                  data-testid="handleResCodeBtn"
                >
                  {GENERATE_NEW_CODE_TEXT}
                  {isDisabled && `${" "}(in ${Timer(resendCodeTimer)})`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default RetailerOtpVerificationPage;
