import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import DashboardRevenueCard from "../../components/dashboardRevenueCard/DashboardRevenueCard";
import QuickLinks from "../../components/quickLinks/QuickLinks";

import WrapperComponent from "./WrapperComponent";
import { useSelector } from "react-redux";
import { getUserRoleType } from "../../stateManagement/reducers/LoginSlice";
import { quickLinkCardWidgets } from "../../constant/ScopeMenus";
import {
  CREDITS,
  RECENT,
  RETAILER_USER_ROLE_TYPE,
  TRANSFER
} from "../../constant/Constants";
import RecentCreditsTransferTable from "../../components/recentCreditsTransferTable/RecentCreditsTransferTable";
import RecentSubscriptionSalesTable from "../../components/recentSubscriptionSalesTable/RecentSubscriptionSalesTable";

/**
 * Renders the Dashboard Page UI.
 * @returns {component} the Dashboard Page UI component.
 */
const DashBoard = () => {
  /**
   * @constant {number} fetchUserRoleType - Fetches the User role type from store.
   */
  const fetchUserRoleType = useSelector((state) => getUserRoleType(state));

  /**
   * @constant {object} getQuickLinksCardsByRoleType - Gets the object content to display based on logged in user type.
   */
  const getQuickLinksCardsByRoleType = quickLinkCardWidgets.find(
    (item) => item.roleType === fetchUserRoleType
  );

  /**
   * @constant {boolean} showCreditCard - returns true or false to check whether to display Credits balance card
   */
  const showCreditCard =
    getQuickLinksCardsByRoleType && getQuickLinksCardsByRoleType.quickLinkCard
      ? getQuickLinksCardsByRoleType.quickLinkCard.showCreditCard
      : false;

  /**
   * @constant {boolean} showRevenueCard - returns true or false to check whether to display Revenue card
   */
  const showRevenueCard =
    getQuickLinksCardsByRoleType && getQuickLinksCardsByRoleType.quickLinkCard
      ? getQuickLinksCardsByRoleType.quickLinkCard.showRevenueCard
      : false;

  /**
   * @constant {boolean} showInventoryCard - returns true or false to check whether to display Inventory card
   */
  const showInventoryCard =
    getQuickLinksCardsByRoleType && getQuickLinksCardsByRoleType.quickLinkCard
      ? getQuickLinksCardsByRoleType.quickLinkCard.showInventoryCard
      : false;

  /**
   * @constant {boolean} isRetailerUser - Gives true if loffed in user is Retailer or else false.
   */
  const isRetailerUser =
    fetchUserRoleType === RETAILER_USER_ROLE_TYPE ? true : false;
  return (
    <>
      <Outlet />
      <WrapperComponent isProfile={false}>
        <QuickLinks
          childPath=""
          showCards={true}
          showFilter={true}
          showRevenueCard={showRevenueCard}
          showCreditCard={showCreditCard}
          showInventoryCard={showInventoryCard}
        ></QuickLinks>
        {fetchUserRoleType
          ? fetchUserRoleType !== RETAILER_USER_ROLE_TYPE && (
              <DashboardRevenueCard></DashboardRevenueCard>
            )
          : ""}
        <RecentCreditsTransferTable
          headerLabel={`${RECENT} ${CREDITS} ${TRANSFER}`}
          showViewAll={true}
          fromCredits={false}
          limit={50}
        ></RecentCreditsTransferTable>
        {fetchUserRoleType
          ? fetchUserRoleType === RETAILER_USER_ROLE_TYPE && (
              <RecentSubscriptionSalesTable
                showNineGrid={isRetailerUser}
              ></RecentSubscriptionSalesTable>
            )
          : ""}
      </WrapperComponent>
    </>
  );
};

export default memo(DashBoard);
