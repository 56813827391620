import React from "react";
import { Button, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import closeicon from "./../../assets/images/close.png";
import {
  REPEAT_LAST_TRANSFER,
  ADD_NEW_TRANSFER
} from "../../constant/Constants";

/**
 * Renders the Pop up for adding the Multiple Credits Transfer Scenario.
 * @param {object} props -Props passed to the Dialog Box UI on Click of Add Transfer button in Credits Transfer.
 * @returns {component} The Dialog Box UI component to Select Whether to Add new Transfer or Repeat last transfer.
 */
const MultipleCreditTransferPopup = (props) => {
  const { addNewForm, handleClosePopup, repeatLastTransfer } = props;

  /**
   * Handle the Operation for Closing the Popup.
   * @function handleClose - Closes the Pop up for Multiple Credits transfer Confirmation scenario.
   */
  const handleClose = () => {
    handleClosePopup();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      // onClose={handleClose}
      data-testid="multipleCreditTransferPopup"
      sx={{
        ".MuiPaper-root": {
          width: { xs: "100%", sm: "auto" }
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: { xs: "100%", sm: "350px" }
          }}
        >
          <img
            data-testid="closeIcon"
            src={closeicon}
            alt="X"
            onClick={handleClose}
            height="13px"
            style={{
              cursor: "pointer"
            }}
          ></img>
        </Box>
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Button
            data-testid="repeatLastTransfer"
            variant="outlined"
            onClick={repeatLastTransfer}
            sx={{
              background:
                "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
              color: "#FFFFFF",
              borderRadius: "20px",
              letterSpacing: "0px",
              fontFamily: "BukraMedium",
              fontSize: "10px",
              width: "150px",
              marginBottom: "10px",
              textTransform: "none",
              height: "30px",
              "&.Mui-disabled": {
                background:
                  "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                color: "#FFFFFF",
                opacity: 0.5
              }
            }}
          >
            {REPEAT_LAST_TRANSFER}
          </Button>
          <Button
            data-testid="addNewForm"
            variant="outlined"
            onClick={addNewForm}
            sx={{
              background:
                "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
              color: "#FFFFFF",
              borderRadius: "20px",
              letterSpacing: "0px",
              fontFamily: "BukraMedium",
              fontSize: "10px",
              width: "150px",
              textTransform: "none",
              height: "30px",
              "&.Mui-disabled": {
                background:
                  "transparent linear-gradient(180deg, #398be7 0%, #6963f8 100%) 0% 0% no-repeat padding-box",
                color: "#FFFFFF",
                opacity: 0.5
              }
            }}
          >
            {ADD_NEW_TRANSFER}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MultipleCreditTransferPopup;
