import React, { useCallback, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import { Button, Divider, Tooltip, Select } from "@mui/material";

import {
  CREDITS,
  TRANSFER,
  RESET,
  APPLY,
  TRANSACTION_SUCCESS_STATUS,
  TRANSACTION_PENDING_STATUS,
  TRANSACTION_REJECTED_STATUS,
  SCOPES,
  NINE_GRID_TRANSACTION_ID,
  NINE_GRID_TYPE,
  NINE_GRID_DATE,
  NINE_GRID_TRANSFER,
  LOADMOREDATE_HINT,
  LOADMOREDATE_LIMIT,
  TOOLTIP_NINEGRID_IMG,
  TOOL_TIP_TRANSFER,
  TOOL_TIP_REVERSE
} from "../../constant/Constants";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import searchIcon from "./../../assets/images/search.svg";

import viewGrid from "./../../assets/images/view_grid.svg";
import Credit_Transfer_Icon from "./../../assets/images/credit_transfer.svg";
import Reverse_Transfer_Icon from "./../../assets/images/reverse_transfer.svg";
import SuccessCreditsTransfersTable from "./../tabs/successCreditsTransfersTable/SuccessCreditsTransfersTable";

import PendingCreditsTransfersTable from "./../tabs/pendingCreditsTransfersTable/PendingCreditsTransfersTable";
import { ToolTipTheme } from "../../constant/themes/ToolTipTheme";
import { Link, useNavigate } from "react-router-dom";
import TabPanel from "../tabPanel/TabPanel";
import "./CreditsTransferTable.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditsTransfersDetails,
  setSelectedCreditsTransfersData
} from "../../stateManagement/reducers/CreditsTransfersSlice";
import {
  getCreditsTransferStatusDetails,
  setCreditsTransferStatus
} from "../../stateManagement/reducers/CreditsTransferStatusSlice";

import {
  downloadCreditReport,
  getCreditTransferDetails
} from "../../services/user.services";
import {
  awaitToastMessage,
  CapitalizeLetter,
  getSearchItems
} from "../../constant/Utils";
import { getUserScope } from "../../stateManagement/reducers/LoginSlice";
import { getFilteredDateFromStore } from "../../stateManagement/reducers/DateFilterSlice";
import RejectedCreditsTransfersTable from "../tabs/rejectedCreditsTransfersTable/RejectedCreditsTransfersTable";
import { muiMenuItemStyles } from "../../style/CustomStyles";
import DownloadReport from "../downloadReport/DownloadReport";
import { updateToastMessage } from "../../stateManagement/reducers/ToastMessageSlice";
import { getConfigDetails } from "../../stateManagement/reducers/ConfigSlice";

/**
 *@property {array<string>} successNineGridOptions - Array of string for Success tab grid.
 */
const successNineGridOptions = [
  NINE_GRID_TYPE,
  NINE_GRID_TRANSACTION_ID,
  NINE_GRID_DATE
];

/**
 *@property {array<string>} pendingNineGridOptions - Array of string for Pending tab grid.
 */
const pendingNineGridOptions = [NINE_GRID_TRANSFER, NINE_GRID_DATE];

/**
 *@property {array<string>} rejectNineGridOptions - Array of string for Rejected tab grid.
 */
const rejectNineGridOptions = [NINE_GRID_TRANSACTION_ID, NINE_GRID_DATE];

/**
 * @property {array<object>} searchSelectOption - Search Select options initialization.
 */
const searchSelectOption = [
  {
    value: "creditAmount",
    label: "Amount"
  },
  {
    value: "transactionId",
    label: NINE_GRID_TRANSACTION_ID
  }
];

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "30px",
          marginLeft: "1px",
          fontSize: "14px",
          textTransform: "capitalize",
          color: "#5c6473",
          display: "flex",
          alignItems: "center",
          fontFamily: "BukraMedium"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":focus": {
            backgroundColor: "#e7eaed"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ":focus": {
            backgroundColor: "#e7eaed"
          }
        }
      }
    }
  }
});
const inputTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "BukraRegular",
          color: "#464E5F",
          fontSize: "15px",
          letterSpacing: "0px"
        }
      }
    }
  }
});

/**
 * Renders the Credits Transfer Table UI.
 * @returns {component} the UI component for the Credits Transfer Table.
 */
export default function CreditsTransferTable() {
  const getNineGridOptios = (id) => {
    let status = successNineGridOptions;
    if (id === 1) {
      status = pendingNineGridOptions;
    } else if (id === 2) {
      status = rejectNineGridOptions;
    }
    return status;
  };
  const transferDetails = useSelector((state) =>
    getCreditsTransferStatusDetails(state)
  );
  const config = useSelector((state) => getConfigDetails(state));
  const fetchUserScopes = useSelector((state) => getUserScope(state));
  const selectedDateFilter = useSelector((state) =>
    getFilteredDateFromStore(state)
  );
  const { transactionStatus, statusValue } = transferDetails;
  const options = getNineGridOptios(statusValue);
  const [value, setValue] = useState(statusValue);

  const [selectedCheckBox, setselectedCheckBox] = useState(options);
  const [isFilterApply, setIsFilterApply] = useState({
    filterAppliedSelectedCheckBox: selectedCheckBox,
    isFilterApplied: false
  });
  const [tableBody, setTableBody] = useState([]);
  const [fixedTableBody, setFixedTableBody] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [searchOption, setSearchOption] = useState("creditAmount");
  const [toCount, setToCount] = useState(50);
  const [totalRecord, setTotalRecord] = useState(300);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const open = Boolean(anchorEl);
  const CreditsTransfersDetails = useSelector((state) =>
    getCreditsTransfersDetails(state)
  );

  /**
   * @function handleChange - Handles the Tab change Operation.
   * @param {object} event - Pointer event.
   * @param {number} newValue - number value of the Tab which we are navigating.
   */
  const handleChange = (event, newValue) => {
    setselectedCheckBox(getNineGridOptios(newValue));
    setIsFilterApply({
      ...isFilterApply,
      filterAppliedSelectedCheckBox: getNineGridOptios(newValue),
      isFilterApplied: false
    });
    setValue(newValue);

    let transferStatusDetails = {
      transactionStatus: getCreditTransferStatus(newValue),
      statusValue: newValue
    };
    dispatch(setCreditsTransferStatus(transferStatusDetails));
    dispatch(setSelectedCreditsTransfersData(null));
    setSearch("");
    setSearchOption("creditAmount");
  };

  /**
   * Handles the Operation to get the transaction status.
   * @param {number} id - id of the transaction type.
   * @returns {string} - Transaction status based on the id.
   */
  const getCreditTransferStatus = (id) => {
    let status = TRANSACTION_SUCCESS_STATUS;
    if (id === 1) {
      status = TRANSACTION_PENDING_STATUS;
    } else if (id === 2) {
      status = TRANSACTION_REJECTED_STATUS;
    }
    return status;
  };

  /**
   * Handles the Search Input operation.
   * @function handleSearchRequest - Handles the Search input value operation.
   * @param {object} event - Search input event.
   */
  const handleSearchRequest = (event) => {
    setSearch(event.target.value);
  };

  /**
   * Handles the Search Select option change operation.
   * @function handleSelectChange - Handles the Search Option change operation.
   * @param {object} event - Select option event
   */
  const handleSelectChange = (event) => {
    setSearchOption(event.target.value);
  };

  /**
   * Handles the grid click event.
   * @function handleGridClick - Handles the grid Click operation.
   * @param {object} event - Grid element event.
   */
  const handleGridClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the Close operation on checkbox click.
   * @function handleCheckClose - Handles the Close operation on click of checkbox.
   */
  const handleCheckClose = () => {
    if (isFilterApply.isFilterApplied) {
      setIsFilterApply({ ...isFilterApply, isFilterApplied: false });
    } else {
      setselectedCheckBox(isFilterApply.filterAppliedSelectedCheckBox);
    }
    setAnchorEl(null);
  };

  /**
   * Handles the Click operation on Checkbox.
   * @function handleCheckBoxChange - Performs checking and uncheking of elements on checkbox click.
   * @param {object} event - checkbox event.
   */
  const handleCheckBoxChange = (event) => {
    const changedValue = event.target.value;
    const list = [...selectedCheckBox];
    const index = list.indexOf(changedValue);
    index === -1 ? list.push(changedValue) : list.splice(index, 1);
    setselectedCheckBox(list);
  };

  /**
   * @function handleResetCheckBox - Handle the reset operation on checkbox click.
   */
  const handleResetCheckBox = () => {
    setselectedCheckBox([]);
  };

  /**
   * Handles the Operation to get the Credits transfer details in the Table.
   * @callback getTransferDetails - Handles the Credits transfer details based on the start date and end date.
   *
   */
  const getTransferDetails = useCallback(
    (from, to) => {
      setLoader(true);
      if (
        selectedDateFilter.endDate &&
        selectedDateFilter.startDate &&
        transactionStatus &&
        config.server
      ) {
        getCreditTransferDetails(
          config.server,
          transactionStatus,
          selectedDateFilter.startDate,
          selectedDateFilter.endDate,
          from,
          to
        )
          .then((res) => {
            if (
              res &&
              res.status === 200 &&
              res.data &&
              res.data.status !== "NO_CONTENT"
            ) {
              setFixedTableBody(res.data.data);
              setTableBody(res.data.data);
              setToCount(res.data.toCount);
              setTotalRecord(res.data.totalRecords);
            } else {
              setFixedTableBody([]);
              setTableBody([]);
            }
          })
          .catch(() => {
            setFixedTableBody([]);
            setTableBody([]);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    },
    [
      selectedDateFilter.startDate,
      selectedDateFilter.endDate,
      transactionStatus,
      config.server
    ]
  );

  useEffect(() => {
    getTransferDetails(0, 50);
  }, [getTransferDetails]);

  useEffect(() => {
    let filteredRows = fixedTableBody;
    if (search) {
      filteredRows = getSearchItems(fixedTableBody, searchOption, search);
    }
    setTableBody(filteredRows);
  }, [fixedTableBody, searchOption, search]);

  /**
   * Handles the Navigation to Reverse Credits Transfer page.
   * @function handleReverseTransfer - Handles the navigation to the reverse credit transfer Page
   */
  const handleReverseTransfer = () => {
    if (CreditsTransfersDetails) {
      const { toUser } = CreditsTransfersDetails;
      navigateTo(`reversecredits`, { state: { channelPartnerCode: toUser } });
      dispatch(setSelectedCreditsTransfersData(null));
    } else {
      navigateTo(`reversecredits`);
    }
  };

  /**
   * Handles the UI display of Reverse credits transfer Icon.
   * @returns {component} the Reverse Credits Transfer Icon UI.
   */
  const renderReverseTransferButton = () => {
    return (
      <Tooltip title="Reverse Credits">
        <Box className="tooltip_mweb">
          <img
            className="imgHover"
            data-testid="reverseTransfer"
            src={Reverse_Transfer_Icon}
            onClick={() => handleReverseTransfer()}
            alt="reverse"
            height="36px"
          ></img>
          <Box
            sx={{
              display: {
                xs: "block",
                lg: "none",
                fontSize: "11px",
                paddingTop: "5px"
              }
            }}
          >
            {TOOL_TIP_REVERSE}
          </Box>
        </Box>
      </Tooltip>
    );
  };

  /**
   * Handle the Loading of data.
   * @function handleLoadMoreData - Handle the Load of data after reaching particular limit.
   */
  const handleLoadMoreData = () => {
    if (
      totalRecord > LOADMOREDATE_LIMIT &&
      fixedTableBody.length >= LOADMOREDATE_LIMIT
    ) {
      const toastMessage = LOADMOREDATE_HINT;
      dispatch(updateToastMessage(awaitToastMessage(toastMessage)));
    }

    if (
      fixedTableBody.length !== totalRecord &&
      fixedTableBody.length < LOADMOREDATE_LIMIT
    ) {
      getCreditTransferDetails(
        config.server,
        transactionStatus,
        selectedDateFilter.startDate,
        selectedDateFilter.endDate,
        toCount,
        toCount + 50
      ).then((res) => {
        if (
          res &&
          res.status === 200 &&
          res.data &&
          res.data.status !== "NO_CONTENT"
        ) {
          setFixedTableBody([...fixedTableBody, ...res.data.data]);
          setTableBody([...fixedTableBody, ...res.data.data]);
          setToCount(res.data.toCount);
          setTotalRecord(res.data.totalRecords);
        } else {
          setFixedTableBody([...fixedTableBody]);
          setTableBody([...fixedTableBody]);
        }
      });
    }
  };

  /**
   * Handles the filter Operation.
   * @function handleApplyFilter - Applies the Filter based on the checkbox element clicked in grid.
   */
  const handleApplyFilter = () => {
    setIsFilterApply({
      filterAppliedSelectedCheckBox: selectedCheckBox,
      isFilterApplied: false
    });
    setAnchorEl(null);
  };
  return (
    <Paper
      elevation={2}
      className="creditsTransferTable_wrapper"
      data-testid="creditsTransferTable"
    >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: "flex",
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          minHeight: { sm: "84px" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
            width: { xs: "100%", sm: "auto" }
          }}
        >
          <Typography
            variant="h6"
            id="tableTitle"
            sx={{
              fontFamily: "BukraMedium",
              fontSize: "16px",
              letterSpacing: "0px",
              color: "#464E5F",
              mr: 2,
              my: { xs: 1, lg: 0 }
            }}
          >
            {CREDITS} {TRANSFER}
          </Typography>
          <Box
            data-testid="searchBox"
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "12px",
              backgroundColor: "#f3f6f9",
              height: "40px",
              mb: { xs: 1, lg: 0 },
              width: { xs: "100%", sm: "auto" }
            }}
          >
            <img
              src={searchIcon}
              alt="search"
              style={{
                height: "11px",
                paddingLeft: "20px"
              }}
            ></img>
            <ThemeProvider theme={inputTheme}>
              <InputBase
                autoComplete="off"
                value={search}
                inputProps={{ "data-testid": "requestSearch" }}
                sx={{ ml: 1, flex: 1, width: { md: "160px", xs: "100%" } }}
                placeholder="Search"
                onChange={(event) => handleSearchRequest(event)}
              />
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Select
                disabled={statusValue === 1}
                id="filledoption"
                disableUnderline={true}
                sx={{
                  backgroundColor: "#7B7D801A",
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                  fontSize: "13px",
                  pl: 2,
                  pr: 2,
                  color: "#282828",
                  fontFamily: "BukraRegular",
                  height: "40px",
                  ".MuiSelect-icon": {
                    px: 1,
                    display: statusValue === 1 ? "none" : "block"
                  },
                  ".MuiSelect-select": {
                    pr: statusValue === 1 ? "0 !important" : "3"
                  },
                  ".Mui-disabled": {
                    color: "#282828",
                    WebkitTextFillColor: "unset"
                  }
                }}
                value={searchOption}
                onChange={handleSelectChange}
                variant="standard"
              >
                {searchSelectOption.map((option) => (
                  <MenuItem
                    sx={{
                      ...muiMenuItemStyles
                    }}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start" },
            cursor: "pointer",
            mr: { xs: 0, md: 2 },
            my: { xs: 1, md: 0 }
          }}
        >
          <ThemeProvider theme={ToolTipTheme}>
            {fetchUserScopes.includes(SCOPES.BUTTON_DIRECT_GRID) && (
              <ThemeProvider theme={ToolTipTheme}>
                <Tooltip title={TOOLTIP_NINEGRID_IMG}>
                  <Box className="tooltip_mweb">
                    <img
                      data-testid="gridClick"
                      src={viewGrid}
                      alt="grid"
                      height="36px"
                      onClick={handleGridClick}
                    ></img>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          lg: "none",
                          fontSize: "11px",
                          paddingTop: "5px"
                        }
                      }}
                    >
                      Columns
                    </Box>
                  </Box>
                </Tooltip>
              </ThemeProvider>
            )}
            {fetchUserScopes.includes(SCOPES.BUTTON_DIRECT_TRANSFER_CREDIT) && (
              <Link
                to="transfercredits"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Tooltip title="Transfer Credits">
                  <Box className="tooltip_mweb">
                    <img
                      src={Credit_Transfer_Icon}
                      alt="credit"
                      height="36px"
                      className="credit_transfer_img imgHover"
                    ></img>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          lg: "none",
                          fontSize: "11px",
                          paddingTop: "5px"
                        }
                      }}
                    >
                      {TOOL_TIP_TRANSFER}
                    </Box>
                  </Box>
                </Tooltip>
              </Link>
            )}
            {renderReverseTransferButton()}
          </ThemeProvider>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCheckClose}
            PaperProps={{
              elevation: 0,
              sx: {
                minWidth: "150px",
                overflow: "visible",
                backgroundColor: "#f6f6f6",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                pl: 2.5,
                pr: 2.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: { sm: 14 },
                  width: 10,
                  height: 10,
                  bgcolor: "#f6f6f6",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {options.map((option, index) => {
              return (
                <Box key={`${option + index}`}>
                  <MenuItem
                    sx={{
                      p: 0,

                      fontSize: "13px",
                      fontFamily: "BukraRegular",
                      color: "#212121",
                      "&.Mui-selected": {
                        fontFamily: "BukraMedium",
                        backgroundColor: "inherit",
                        "&.Mui-selected:focus": {
                          backgroundColor: "inherit"
                        }
                      }
                    }}
                  >
                    <Checkbox
                      inputProps={{ "data-testid": "checkbox" }}
                      value={option}
                      onChange={handleCheckBoxChange}
                      checked={selectedCheckBox.includes(option)}
                      sx={{ p: 0, height: "10px" }}
                      size="small"
                    />
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontFamily: "BukraRegular",
                        color: "#212121",
                        letterSpacing: "0px",
                        pl: 1
                      }}
                    >
                      {option}
                    </Typography>
                  </MenuItem>
                  {index !== options.length - 1 && (
                    <Divider
                      sx={{
                        p: 0,
                        m: "0px",
                        ml: "30px"
                      }}
                    ></Divider>
                  )}
                </Box>
              );
            })}
            <Box
              sx={{
                pt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
                color: "#0F57CB",
                borderRadius: "10px"
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  p: "0px",
                  fontSize: "10px",
                  color: "#0F57CB",
                  borderRadius: "10px",
                  textTransform: "none"
                }}
                data-testid="resetButton"
                onClick={() => handleResetCheckBox()}
              >
                {RESET}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  ml: 1,
                  p: "0px",
                  fontSize: "10px",
                  borderRadius: "10px",
                  textTransform: "none",
                  cursor: "pointer",
                  background:
                    "transparent linear-gradient(180deg, #398BE7 0%, #6963F8 100%) 0% 0% no-repeat padding-box",
                  color: "#FFFFFF"
                }}
                data-testid="filterButton"
                onClick={() => handleApplyFilter()}
              >
                {APPLY}
              </Button>
            </Box>
          </Menu>
        </Box>
      </Toolbar>
      <Box sx={{ width: "100%", my: { xs: 1, lg: 0 } }}>
        <Box
          sx={{
            borderBottom: 0,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { sm: "center" },
            minHeight: "45px",
            backgroundColor: "#EDF4F6"
          }}
        >
          <ThemeProvider theme={theme}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                display: "flex",
                alignItems: "center",
                minHeight: "45px",
                my: { xs: 1, lg: 0 }
              }}
            >
              <Tab
                data-testid="successfulTab"
                label="Successful"
                sx={{
                  width: { xs: "fit-content", lg: "134px" },
                  "&.Mui-selected": {
                    background: "#cfebe0 0% 0% no-repeat padding-box",
                    borderRadius: "17px",
                    color: "#06B250",
                    height: "30px"
                  }
                }}
              />
              <Tab
                data-testid="pendingTab"
                label="Pending"
                sx={{
                  width: { xs: "fit-content", lg: "134px" },
                  "&.Mui-selected": {
                    backgroundColor: "#eee7d7",
                    borderRadius: "17px",
                    color: "#F49009",
                    height: "30px"
                  }
                }}
              />
              <Tab
                data-testid="rejectedTab"
                label="Rejected"
                sx={{
                  width: { xs: "fit-content", lg: "134px" },
                  "&.Mui-selected": {
                    backgroundColor: "#fde2e2",
                    borderRadius: "17px",
                    color: "#FF2A2A",
                    height: "30px"
                  }
                }}
              />
            </Tabs>
          </ThemeProvider>
          <Box sx={{ pr: 1, pb: { xs: 1, sm: 0 } }}>
            <DownloadReport
              reportType={downloadCreditReport}
              status={transactionStatus}
              fileName={`${CapitalizeLetter(transactionStatus)}CreditReport`}
            ></DownloadReport>
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <SuccessCreditsTransfersTable
            tableBody={tableBody}
            loader={loader}
            isFilterApply={isFilterApply}
            handleLoadMoreData={handleLoadMoreData}
          ></SuccessCreditsTransfersTable>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PendingCreditsTransfersTable
            tableBody={tableBody}
            loader={loader}
            isFilterApply={isFilterApply}
            handleLoadMoreData={handleLoadMoreData}
          ></PendingCreditsTransfersTable>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <RejectedCreditsTransfersTable
            tableBody={tableBody}
            loader={loader}
            isFilterApply={isFilterApply}
            handleLoadMoreData={handleLoadMoreData}
          ></RejectedCreditsTransfersTable>
        </TabPanel>
      </Box>
    </Paper>
  );
}
