import { createSlice } from "@reduxjs/toolkit";
import { NO_CONTENT } from "../../constant/Constants";
import { getBroadcastNotification } from "../../services/user.services";

const initialState = [];

/**
 * Saves the Country Details into the Store.
 */
const BroadcastNotificationSlice = createSlice({
  name: "broadcastNotification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
    builder.addCase(
      getBroadcastNotification.fulfilled,
      (state, { payload }) => {
        if (
          payload.status &&
          payload.status === 200 &&
          payload.data &&
          payload.data.status !== NO_CONTENT
        ) {
          return payload.data;
        } else {
          return [];
        }
      }
    );
  }
});

/**
 * Fetches the Country Details from the Store.
 * @param {object} state - Store State.
 * @returns {object} - Country Details.
 */
export const getBroadcastNotificationFromStore = (state) => {
  return state.broadcastNotification ? state.broadcastNotification : [];
};

export default BroadcastNotificationSlice.reducer;
