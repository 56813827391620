import React from "react";
import { useLocation } from "react-router-dom";
const WrapperComponent = (props) => {
  const { children } = props;

  const location = useLocation();

  const isProfile =
    location.pathname.split("/").includes("companyprofile") ||
    location.pathname.split("/").includes("subdistributorprofile") ||
    location.pathname.split("/").includes("subdistributorviewprofile") ||
    location.pathname.split("/").includes("editprofile") ||
    location.pathname.split("/").includes("myprofile") ||
    location.pathname.split("/").includes("masterdistributorprofile") ||
    false;

  return <div>{!isProfile && children}</div>;
};

export default WrapperComponent;
